import React from "react";
import QRCode from "react-qr-code";

function QRCodeOverlay(props) {
  return (
    <div className="qrcodeContainer">
      <QRCode value={props.value} size={256} />
    </div>
  );
}

export default QRCodeOverlay;

import React from "react";

import * as Constants from "../Constants";

class AddWidget extends React.Component {
  render = () => {
    return (
      <div className="dashboardWidget dbwAdd">
        <div className="dbwTitle">New Widget</div>
        <hr />
        <div className="dbwContent">
          <div>Select a widget to add to the dashboard:</div>
          <span
            data-testid="Tag List Button"
            className="action-button green-button"
            onClick={() => this.props.handleSetWidgetType(this.props.widget, Constants.WIDGET_TYPE_TAG_LIST)}
          >
            Tag List
          </span>
          <span
            data-testid="Message Button"
            className="action-button green-button"
            onClick={() => this.props.handleSetWidgetType(this.props.widget, Constants.WIDGET_TYPE_MESSAGE)}
          >
            Message
          </span>
          <span
            data-testid="Welcome Button"
            className="action-button green-button"
            onClick={() => this.props.handleSetWidgetType(this.props.widget, Constants.WIDGET_TYPE_WELCOME)}
          >
            Welcome
          </span>
          <span
            data-testid="Clock Button"
            className="action-button green-button"
            onClick={() => this.props.handleSetWidgetType(this.props.widget, Constants.WIDGET_TYPE_CLOCK)}
          >
            Clock
          </span>
          <span data-testid="Cancel Button" className="action-button red-button" onClick={() => this.props.handleCancel(this.props.widget)}>
            Cancel
          </span>
        </div>
      </div>
    );
  };
}
export default AddWidget;

function render(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.534"
      height="33.817"
      viewBox="0 0 52.534 33.817"
    >
      <g id="Group_678" data-name="Group 678" transform="translate(-663.137 -200.469)">
        <path
          id="Path_10"
          data-name="Path 10"
          d="M618.162,422.458h43.047a3.744,3.744,0,0,1,3.743,3.744v24.331a3.743,3.743,0,0,1-3.743,3.743H618.162a3.744,3.744,0,0,1-3.744-3.743V426.2A3.744,3.744,0,0,1,618.162,422.458Z"
          transform="translate(49.719 -220.989)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          width="10.615"
          height="1.872"
          transform="translate(667.943 206.615)"
          fill="#fff"
        />
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="5.615"
          height="1.872"
          transform="translate(667.943 210.615)"
          fill="#fff"
        />
        <rect
          id="Rectangle_6"
          data-name="Rectangle 6"
          width="5.615"
          height="1.872"
          transform="translate(667.943 214.615)"
          fill="#fff"
        />
        <rect
          id="Rectangle_348"
          data-name="Rectangle 348"
          width="12.615"
          height="5.34"
          transform="translate(697.439 213.211)"
          fill="#fff"
        />
        <rect
          id="Rectangle_351"
          data-name="Rectangle 351"
          width="42.615"
          height="0.872"
          transform="translate(667.943 229.615)"
          fill="#fff"
        />
      </g>
      <g id="Group_26" data-name="Group 26" transform="translate(-808.14 -390.045)">
        <g id="Group_21" data-name="Group 21" transform="translate(821.915 399.523)">
          <g id="Group_20" data-name="Group 20" transform="translate(0)">
            <path
              id="Path_13"
              data-name="Path 13"
              d="M836.282,406.2l-9.786,9.786,4.464,4.465,9.786-9.787Z"
              transform="translate(-826.496 -406.201)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_23" data-name="Group 23" transform="translate(818.235 410.798)">
          <g id="Group_22" data-name="Group 22" transform="translate(0 0)">
            <path
              id="Path_14"
              data-name="Path 14"
              d="M820.427,431.506l-.4.4a1.051,1.051,0,0,0-.254.412l-1.487,4.463a1.051,1.051,0,0,0,1.332,1.329l4.483-1.5a1.052,1.052,0,0,0,.409-.254l.376-.376Z"
              transform="translate(-818.235 -431.506)"
              fill="#fff"
            />
          </g>
        </g>
        <g id="Group_25" data-name="Group 25" transform="translate(833.189 394.158)">
          <g id="Group_24" data-name="Group 24" transform="translate(0)">
            <path
              id="Path_15"
              data-name="Path 15"
              d="M859.162,395.042a3.261,3.261,0,0,0-4.465,0l-2.9,2.993,4.465,4.464,2.9-2.994A3.161,3.161,0,0,0,859.162,395.042Z"
              transform="translate(-851.801 -394.158)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

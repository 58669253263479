function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 281.88 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 58">
            <path
              data-name="Path 57"
              d="M28,150.9A27.8,27.8,0,0,0,47.33,143l27.58,13.8a27.89,27.89,0,1,0,52.78-8.59l37.41-37.42A27.76,27.76,0,0,0,205,85.66a27.37,27.37,0,0,0-1.39-8.27L236.15,53a29.12,29.12,0,1,0-11.08-15L192.56,62.41a27.84,27.84,0,0,0-40.64,35.23l-37.41,37.42a27.5,27.5,0,0,0-31.34,5.06L55.6,126.32a27.9,27.9,0,1,0-31,24.38,27.58,27.58,0,0,0,3.4.2Z"
            />
            <path data-name="Path 58" d="M279.58,104.3a9.31,9.31,0,0,0-9.3-9.32H233a9.31,9.31,0,0,0-9.31,9.32h0V300h55.9Z" />
            <path data-name="Path 59" d="M205,160.21a9.31,9.31,0,0,0-9.31-9.31H158.44a9.31,9.31,0,0,0-9.31,9.31h0V300H205Z" />
            <path data-name="Path 60" d="M55.93,197.49a9.3,9.3,0,0,0-9.31-9.31H9.34A9.31,9.31,0,0,0,0,197.49H0V300h55.9Z" />
            <path data-name="Path 61" d="M130.48,234.76a9.31,9.31,0,0,0-9.31-9.31H83.89a9.31,9.31,0,0,0-9.31,9.31h0V300h55.9Z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

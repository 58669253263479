import React from "react";

import { faMagnifyingGlass, faPencil, faSpinner, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import CircleXmarkIcon from "./img/CircleXmarkIcon";
import FlexInput from "./FlexInput";
import ListHeaderView from "./ListHeaderView";
import ListItemView from "./ListItemView";
import numeral from "numeral";
import TagList from "./TagList";
import Tooltip from "./Tooltip";

class Invoice extends BaseDetailViewComponent {
  renderContactSearchResultsToColumns = item => {
    let fullname = "";
    let mobileviewphone = "";
    let companyname = "";
    fullname = item.firstname + " " + item.lastname;
    // Display mobilephone, then otherphone (if no mobile), then "-" if neither
    mobileviewphone = item.mobilephone ? item.mobilephone : item.otherphone ? item.otherphone : "";
    mobileviewphone = mobileviewphone ? mobileviewphone : item.email;
    companyname = item.companyname ? item.companyname : "";
    fullname = <span data-testid={"Customer Search Results: " + fullname}>{fullname}</span>;
    return [{ rowvalue: fullname }, { rowvalue: mobileviewphone }, { rowvalue: companyname, classes: "desktop" }];
  };

  renderProductList = () => {
    // if (!this.state.returnView) {
    if (!this.state.isReturn) {
      let quantityHeader = "Qty";
      if ([Constants.ORDER_STATUS_REFUNDED, Constants.ORDER_STATUS_PARTIALLY_REFUNDED].includes(this.state.order?.orderstatus)) {
        quantityHeader = (
          <div className="fiftyfifty">
            <div>
              Qty <span className="noPrint">Sold</span>
            </div>
            <div className="noPrint">Returned</div>
          </div>
        );
      } else if (this.state.showInventory) {
        quantityHeader = "Qty / Inv";
      }

      // True, if the invoice has not had any items refunded
      const notRefunded = ![Constants.ORDER_STATUS_REFUNDED, Constants.ORDER_STATUS_PARTIALLY_REFUNDED].includes(this.state.order?.orderstatus);

      // Regular (non-return) invoice product list
      const headers = [
        { classes: "header", columnheading: "Product" },
        { classes: "header desktop noPrint", columnheading: "Location" },
        { classes: "header desktop noPrint", columnheading: "Associate" },
        {
          classes: "header right-aligned",
          columnheading: quantityHeader,
          eyeball: notRefunded,
          eyeballSlashed: this.state.showInventory,
          handleToggleEyeball: this.handleToggleInventory,
        },
        { classes: "header right-aligned desktop", columnheading: this.state.showCost ? "Cost" : "Price" },
        { classes: "header right-aligned desktop", columnheading: this.renderDiscountColumnHeaderLabel() },
        {
          classes: "header right-aligned",
          columnheading: "Total",
          eyeball: true,
          eyeballSlashed: this.state.showCost,
          handleToggleEyeball: this.handleToggleCost,
        },
        { classes: "headerlast", columnheading: "" },
      ];
      return (
        <div className="areaProductList">
          <div className="invoiceproductlist">
            <ListHeaderView headerRowItems={headers} />
            <hr className="headerRule5" />
            <ListItemView
              listitems={this.state.order.orderitems}
              expandedListItems={null}
              selectedListItems={this.state.selectedOrderItems}
              renderItemToColumns={this.renderSelectedProductToColumns}
              toggleCollapsed={() => {}}
              selectListItem={this.selectOrderItem}
              handleEditItem={() => {}}
              handleTouchStart={this.handleTouchStart}
              handleTouchEnd={this.handleTouchEnd}
            />
          </div>
        </div>
      );
    } else {
      // Return invoice product list
      const headers = [
        { classes: "header", columnheading: "Product" },
        { classes: "header desktop noPrint", columnheading: "Location" },
        { classes: "header desktop noPrint", columnheading: "Associate" },
        { classes: "header right-aligned desktop", columnheading: "Qty Sold" },
        { classes: "header right-aligned desktop", columnheading: "Qty Eligible" },
        { classes: "header right-aligned desktop", columnheading: "Qty to Return" },
        { classes: "header right-aligned mobile", columnheading: "Qty" },
        {
          classes: "header right-aligned desktop",
          columnheading: this.state.showCost ? "Cost" : "Price",
        },
        { classes: "header right-aligned desktop", columnheading: this.renderDiscountColumnHeaderLabel() },
        { classes: "header right-aligned", columnheading: "Total", eyeball: true },
      ];
      return (
        <div className="areaProductList">
          <div className="returnproductlist">
            <ListHeaderView headerRowItems={headers} />
            <hr className="headerRule7" />
            <ListItemView
              listitems={this.state.order.orderitems}
              expandedListItems={null}
              selectedListItems={this.state.selectedOrderItems}
              renderItemToColumns={this.renderSelectedProductToColumnsReturn}
              toggleCollapsed={() => {}}
              selectListItem={this.selectOrderItem}
              handleEditItem={() => {}}
              handleTouchStart={this.handleTouchStart}
              handleTouchEnd={this.handleTouchEnd}
            />
          </div>
        </div>
      );
    }
  };

  renderSelectedProductToColumns = item => {
    let deleteButton = null;

    // Do not show the delete button if this order is paid in full or refunded or is an online order
    if (
      Constants.CLOSED_INVOICE_ORDER_STATUSES.includes(this.state.order?.orderstatus) ||
      Constants.ONLINE_ORDER_STATUSES.includes(this.state.order?.orderstatus)
    ) {
      deleteButton = "";
    } else {
      deleteButton = (
        <span
          data-testid="Line Item Delete Button"
          onClick={event => {
            if (event && event.stopPropagation) {
              event.stopPropagation();
            }
            this.handleDeleteOrderItem(item.orderitemuuid ?? item.uuid);
          }}
        >
          <CircleXmarkIcon />
        </span>
      );
    }

    let tooltip = "";
    if (item.tags?.length > 0) {
      const icon = <FontAwesomeIcon icon={faTag} />;
      let tagListElement = <TagList tags={item.tags} readonly={true} />;
      tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
    }

    let renderedValue = (
      <span>
        {item.productname} {tooltip}
      </span>
    );
    let productDescription = (
      <div className="areaInputItem" data-testid="Invoice Order Item">
        <FlexInput
          pencilEdit={true}
          type="text"
          textarea={true}
          name="productname"
          id="productname"
          datatestid="Line Item Product Name"
          disabled={item.isgiftcard || Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, this.state.order?.orderstatus) || item.protected}
          autoComplete="off"
          maxLength={255}
          onChange={event =>
            this.handleChange(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid
            )
          }
          onFocus={Helper.handleFocus}
          onBlur={event =>
            this.handleBlur(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid,
              null,
              "text" // datatype
            )
          }
          value={item.productname}
          renderedValue={renderedValue}
        />
      </div>
    );
    // Special case: If this item is an external order and it's not a system SKU, then the pencil icon is used to edit the associated ClerkHound product
    if (this.state.order?.externalid && !Constants.SYSTEM_STORE_SKUS.includes(item.storesku)) {
      let magnifyingGlass = "";
      if (this.state.order?.orderstatus === Constants.ORDER_STATUS_ONLINE_UNSHIPPED) {
        magnifyingGlass = (
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            onClick={event => {
              event.stopPropagation();
              this.handleUnmatchedProduct("");
            }}
            className="ghost"
          />
        );
      }
      productDescription = (
        <span>
          {item.productname} {magnifyingGlass}
        </span>
      );
    }
    let productQuantity = (
      <div className="areaInputItem">
        <FlexInput
          type="text"
          inputMode="numeric"
          name="quantity"
          id="quantity"
          datatestid="Line Item Quantity"
          disabled={
            Constants.ONLINE_ORDER_STATUSES.includes(this.state.order?.orderstatus) ||
            item.isgiftcard ||
            Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, this.state.order?.orderstatus)
          }
          maxLength={13}
          autoComplete="off"
          onChange={event =>
            this.handleChange(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid
            )
          }
          onFocus={Helper.handleFocus}
          onBlur={event =>
            this.handleBlur(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid,
              null,
              "float" // datatype
            )
          }
          value={item.quantity}
        />
      </div>
    );

    let productSellPrice;
    if (!this.state.showCost) {
      productSellPrice = (
        <div className="areaInputItem">
          <FlexInput
            type="text"
            inputMode="numeric"
            name="sellprice"
            id="sellprice"
            datatestid="Line Item Sell Price"
            disabled={
              Constants.ONLINE_ORDER_STATUSES.includes(this.state.order?.orderstatus) ||
              Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, this.state.order?.orderstatus)
            }
            autoComplete="off"
            maxLength={13}
            onChange={event =>
              this.handleChange(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid
              )
            }
            onFocus={Helper.handleFocus}
            onBlur={event =>
              this.handleBlur(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid,
                null,
                "float" // datatype
              )
            }
            value={item.sellprice}
          />
        </div>
      );
    } else {
      productSellPrice = <div className="areaInputItem"> {item.cost}</div>;
    }

    let productDiscount = (
      <div className="areaInputItem">
        <FlexInput
          type="text"
          inputMode="numeric"
          name="discount"
          id="discount"
          datatestid="Line Item Discount"
          disabled={
            Constants.ONLINE_ORDER_STATUSES.includes(this.state.order?.orderstatus) ||
            item.isgiftcard ||
            Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, this.state.order?.orderstatus)
          }
          autoComplete="off"
          maxLength={13}
          onChange={event =>
            this.handleChange(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid
            )
          }
          onFocus={Helper.handleFocus}
          onBlur={event =>
            this.handleBlur(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid,
              null,
              "float" // datatype
            )
          }
          value={item.discount}
        />
      </div>
    );

    // If the item is a gift card, then the description and quantity are not editable.
    if (item.isgiftcard) {
      productDescription = (
        <span>
          {item.productname + (item.isgiftcard ? " #" + item.giftCardNumber : "")} {tooltip}
        </span>
      );
      productQuantity = item.quantity;
      productDiscount = item.discount;
    }

    // If this order is paid-in-full or refunded, then the item is not editable
    if (Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, this.state.order?.orderstatus)) {
      productDescription = (
        <span>
          {item.productname + (item.isgiftcard ? " #" + item.giftCardNumber : "")} {tooltip}
        </span>
      );
      productQuantity = item.quantity;
      productSellPrice = this.state.showCost ? item.cost : item.sellprice;
      productDiscount = item.discount;
    }

    // Determine if this item is a subscription invoice payment
    const subscriptionInvoicePayment = item.uniqueidentifier && item.uniqueidentifier.startsWith(Constants.SUBSCRIPTION_PAYMENT + "|");

    // If this item is a gift card, then the quantity is not editable
    // If this order is paid-in-full or refunded, then the quantity is not editable
    // If this item is a subscription invoice payment, then the quantity is not editable
    if (
      item.isgiftcard ||
      Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, this.state.order?.orderstatus) ||
      subscriptionInvoicePayment ||
      this.state.showInventory
    ) {
      productQuantity = item.quantity;
      if ([Constants.ORDER_STATUS_REFUNDED, Constants.ORDER_STATUS_PARTIALLY_REFUNDED].includes(this.state.order?.orderstatus)) {
        const quantityreceived = item.quantityreceived || "";
        productQuantity = (
          <div className="fiftyfifty">
            <div>{item.quantity}</div>
            <div className="noPrint">{quantityreceived}</div>
          </div>
        );
      } else if (this.state.showInventory) {
        if (item.affectinventory) {
          productQuantity = item.quantity + " / " + item.inventory;
        } else {
          productQuantity = item.quantity + " / -";
        }
      }
    }
    const associateSpinner = item.associateUpdating ? (
      <span className="noOpacity">
        <FontAwesomeIcon icon={faSpinner} spin />
      </span>
    ) : (
      ""
    );
    const associate = (
      <span
        data-testid="Line Item Associate"
        className="associatePicker nowrap"
        onClick={event => {
          this.handleAssociateSelection(event, item.uuid);
        }}
      >
        {associateSpinner} {item.associate} <FontAwesomeIcon icon={faPencil} />
      </span>
    );

    return [
      { rowvalue: productDescription },
      { rowvalue: item.location, classes: "desktop noPrint" },
      { rowvalue: associate, classes: "desktop noPrint" },
      { rowvalue: productQuantity, classes: "right-aligned" },
      { rowvalue: productSellPrice, classes: "desktop right-aligned" },
      { rowvalue: productDiscount, classes: "desktop right-aligned" },
      {
        rowvalue: numeral(this.state.showCost ? item.totalcost : item.totalprice).format(Constants.CURRENCY),
        classes: "right-aligned",
      },
      { rowvalue: deleteButton, classes: "centerAligned circlebuttoncontainer" },
    ];
  };

  renderSelectedProductToColumnsReturn = item => {
    const quantityEligible = numeral(item.quantitySold ?? 0)
      .subtract(numeral(item.quantityreceived ?? 0).value())
      .format(Constants.DECIMAL_VALUE);
    const isClosedInvoice = Constants.CLOSED_INVOICE_ORDER_STATUSES.includes(this.state.order?.orderstatus);
    const noneEligible = numeral(quantityEligible ?? 0).value() === 0;
    let quantity =
      isClosedInvoice || noneEligible ? (
        <div className="areaInputItem readonlyQuantity">{item.quantity}</div>
      ) : (
        <div className="areaInputItem">
          <input
            type="text"
            inputMode="numeric"
            name="quantity"
            id="quantity"
            data-testid="Return Line Item Quantity"
            maxLength={13}
            autoComplete="off"
            onChange={event =>
              this.handleChange(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid
              )
            }
            onFocus={Helper.handleFocus}
            onBlur={event =>
              this.handleBlur(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid,
                null,
                "float" // datatype
              )
            }
            value={item.quantity}
          />
        </div>
      );
    const associateSpinner = item.associateUpdating ? (
      <span className="noOpacity">
        <FontAwesomeIcon icon={faSpinner} spin />
      </span>
    ) : (
      ""
    );
    const associate = (
      <span
        className="associatePicker nowrap"
        onClick={event => {
          this.handleAssociateSelection(event, item.uuid);
        }}
      >
        {associateSpinner} {item.associate} <FontAwesomeIcon icon={faPencil} />
      </span>
    );

    return [
      { rowvalue: item.productname },
      { rowvalue: item.location, classes: "desktop noPrint" },
      { rowvalue: associate, classes: "desktop noPrint" },
      { rowvalue: item.quantitySold, classes: "right-aligned desktop" },
      { rowvalue: quantityEligible, classes: "right-aligned desktop" },
      { rowvalue: quantity, classes: "right-aligned" },
      {
        rowvalue: this.state.showCost ? item.cost ?? 0 : item.sellprice ?? 0,
        classes: "desktop right-aligned",
      },
      { rowvalue: item.discount, classes: "desktop right-aligned" },
      {
        rowvalue: (
          <span data-testid={this.state.showCost ? "Line Item Total Cost" : "Line Item Total Price"}>
            {numeral(this.state.showCost ? item.totalcost : item.totalprice).format(Constants.CURRENCY)}
          </span>
        ),
        classes: "right-aligned",
      },
    ];
  };

  renderProductListNarrow = () => {
    const headers = [
      { classes: "header", columnheading: "Product" },
      { classes: "header right-aligned", columnheading: "Qty" },
      { classes: "header right-aligned", columnheading: "Each" },
      { classes: "header right-aligned", columnheading: "Total" },
    ];
    return (
      <div className="productListGrid invoiceproductlist">
        <ListHeaderView headerRowItems={headers} />
        <ListItemView
          listitems={this.state.order.orderitems}
          expandedListItems={null}
          selectedListItems={this.state.selectedOrderItems}
          renderItemToColumns={this.renderSelectedProductToColumnsNarrow}
          toggleCollapsed={() => {}}
          selectListItem={this.selectOrderItem}
          handleEditItem={() => {}}
          handleTouchStart={this.handleTouchStart}
          handleTouchEnd={this.handleTouchEnd}
        />
      </div>
    );
  };

  renderSelectedProductToColumnsNarrow = item => {
    const each = item.sellprice - item.discount;
    return [
      { rowvalue: item.productname },
      {
        rowvalue: numeral(item.quantity).format(Constants.DECIMAL_VALUE),
        classes: "right-aligned",
      },
      { rowvalue: numeral(each).format(Constants.CURRENCY), classes: "desktop right-aligned" },
      { rowvalue: numeral(item.totalprice).format(Constants.CURRENCY), classes: "right-aligned" },
    ];
  };

  handleChangeCustomer = (response, contact = null) => {
    if (response === Constants.OVERLAY_RESPONSE_CREATE) {
      // Show the "Create a New Contact" overlay
    } else if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      this.selectCustomerListItem(contact);
    }
  };

  getOrderType = () => {
    return Constants.ORDER;
  };

  getTotalPrice = () => {
    return this.state.order.totalprice;
  };
}
export default Invoice;

import React from "react";

import * as Helper from "./Helper";
import * as Constants from "./Constants";

import Autocomplete from "./Autocomplete";
import CircleXmarkIcon from "./img/CircleXmarkIcon.js";
import CirclePlusIcon from "./img/CirclePlusIcon.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import SaveCancelButtons from "./SaveCancelButtons";

function ProductCard(props) {
  // Make cost/SKU editable, even if a supplier has not been picked
  const isNewSupplier = false;

  const upcWarning =
    !props.product.upc || Helper.isValidUpc(props.product.upc) ? (
      ""
    ) : (
      <FontAwesomeIcon icon={faExclamationTriangle} title="Not a valid UPC" className="highlight" />
    );
  const eanWarning =
    !props.product.ean || Helper.isValidEan(props.product.ean) ? (
      ""
    ) : (
      <FontAwesomeIcon icon={faExclamationTriangle} title="Not a valid EAN" className="highlight" />
    );
  const saveCancelButtons = props.embedded ? (
    <SaveCancelButtons
      handleCancel={props.handleCancel}
      saveButtonClass={props.saveButtonClass}
      handleSave={props.handleSave}
      datatestidcancel="Embedded Product Card Cancel"
      datatestidsave="Embedded Product Card Save"
    />
  ) : (
    ""
  );
  return (
    <div id="product-view">
      <div className="product-details">
        <div className="product-item areaInputItem productname ">
          <span className="product-header">Short Product Description</span>
          {renderProductDescription()}
        </div>
        <div className="product-item areaInputItem longdescription">
          <span className="product-header">Full Product Description</span>
          <textarea
            name="longdescription"
            id="longdescription"
            data-testid="Full Product Description"
            placeholder="Full Product Description"
            onFocus={Helper.handleFocus}
            onChange={props.handleChange}
            onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
            value={props.product.longdescription}
            autoComplete="off"
            rows="10"
            maxLength={5000}
          />
        </div>
        <div className="product-item areaInputItem item-sellprice">
          <span className="product-header">Sell Price</span>
          {renderSellPrice()}
        </div>
        <div className="product-item areaInputItem item-inventory">
          <span className="product-header">Inventory</span>
          <input
            type="text"
            inputMode="numeric"
            name="inventory"
            id="inventory"
            data-testid="Inventory Count"
            placeholder="Inventory Count"
            autoComplete="off"
            maxLength={13}
            onFocus={Helper.handleFocus}
            onChange={props.handleChange}
            onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "float")}
            value={props.product.inventory}
          />
        </div>
        <div className="product-item areaInputItem">
          <span className="product-header">Location</span>
          <Autocomplete
            type="text"
            name="location"
            id="location"
            datatestid="Location"
            placeholder="Location"
            autoComplete="off"
            maxLength={255}
            handleChange={props.handleChange}
            handleBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
            suggestions={props.productSuggestions?.suggestions?.locations}
            value={props.product.location}
          />
        </div>
        <div className="product-item areaInputItem item-storesku">
          <span className="product-header">Store SKU</span>
          <input
            type="text"
            name="storesku"
            id="storesku"
            data-testid="Store SKU"
            placeholder="Store SKU"
            autoComplete="off"
            maxLength={32}
            onFocus={Helper.handleFocus}
            onChange={props.handleChange}
            onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
            value={props.product.storesku}
          />
        </div>
        <div className="product-item areaInputItem item-upc">
          <span className="product-header">UPC {upcWarning}</span>
          <input
            type="text"
            name="upc"
            id="upc"
            data-testid="UPC"
            placeholder="UPC"
            autoComplete="off"
            maxLength={20}
            onFocus={Helper.handleFocus}
            onChange={props.handleChange}
            onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
            value={props.product.upc}
          />
        </div>
        <div className="product-item areaInputItem item-ean">
          <span className="product-header">EAN {eanWarning}</span>
          <input
            type="text"
            name="ean"
            id="ean"
            data-testid="EAN"
            placeholder="EAN"
            autoComplete="off"
            maxLength={20}
            onFocus={Helper.handleFocus}
            onChange={props.handleChange}
            onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
            value={props.product.ean}
          />
        </div>
        <div className="product-item areaInputItem item-family">
          <span className="product-header">Family</span>
          <Autocomplete
            type="text"
            name="family"
            id="family"
            datatestid="Family"
            placeholder="Family"
            autoComplete="off"
            maxLength={100}
            handleChange={props.handleChange}
            handleBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
            suggestions={props.productSuggestions?.suggestions?.families}
            value={props.product.family}
          />
        </div>
        <div className="product-item areaInputItem item-brand">
          <span className="product-header">Brand</span>
          <Autocomplete
            type="text"
            name="make"
            id="make"
            datatestid="Brand"
            placeholder="Brand"
            autoComplete="off"
            maxLength={255}
            handleChange={props.handleChange}
            handleBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
            suggestions={props.productSuggestions?.suggestions?.makes}
            value={props.product.make}
          />
        </div>
        <div className="product-item areaInputItem item-model">
          <span className="product-header">Model</span>
          <input
            type="text"
            name="model"
            id="model"
            data-testid="Model"
            placeholder="Model"
            autoComplete="off"
            maxLength={255}
            onFocus={Helper.handleFocus}
            onChange={props.handleChange}
            onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
            value={props.product.model}
          />
        </div>
        <div className="supplier-card">
          <div key={"supplierwidget"} id="supplierwidget-container" className="border-radius">
            <div id="supplierwidget-grid" className="product-suppliers">
              <span className="supplierwidget-header">Supplier</span>
              <span className="supplierwidget-header desktop">Supplier SKU</span>
              <span className="supplierwidget-header currency">Cost</span>
              <span className="supplierwidget-header supplierwidget-item action-icon gridCenter">{renderAddSupplierButton(props)}</span>
              {/* <span className="supplierwidget-header"></span> */}

              {/* This row is pulled from the Products table */}
              <span className="supplierwidget-item">{renderSupplierName(props)}</span>
              <span className="supplierwidget-item desktop item-sku">{renderSKU()}</span>
              <span className="supplierwidget-item currency item-cost">{renderCost()}</span>
              <span className="supplierwidget-item action-icon gridCenter">{renderDeleteSupplierButton(props)}</span>
              {/* <span className="supplierwidget-item action-icon">
                {renderAddSupplierButton(props)}
              </span> */}
              {renderAdditionalSuppliers(props)}
            </div>
          </div>
        </div>
        {saveCancelButtons}
      </div>
    </div>
  );

  function renderCost() {
    return (
      <input
        type="text"
        inputMode="numeric"
        name="cost"
        id="cost"
        data-testid="Cost"
        className={isNewSupplier ? " save-disabled " : ""}
        autoComplete="off"
        placeholder="Cost"
        maxLength={13}
        onFocus={Helper.handleFocusSelect}
        onChange={props.handleChange}
        onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "float")}
        value={props.product.cost}
        disabled={isNewSupplier}
      />
    );
  }

  function renderSKU() {
    return (
      <input
        type="text"
        name="sku"
        id="sku"
        data-testid="SKU"
        className={isNewSupplier ? " save-disabled " : ""}
        ref={props.skuInput}
        autoComplete="off"
        placeholder="SKU"
        maxLength={32}
        onFocus={Helper.handleFocus}
        onChange={props.handleChange}
        onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
        value={props.product.sku}
        disabled={isNewSupplier}
      />
    );
  }

  function renderSupplierName(props) {
    return (
      <input
        type="text"
        name="companyname"
        id="companyname"
        data-testid="Supplier Name"
        ref={props.companyNameInput}
        placeholder="Supplier name"
        autoComplete="off"
        maxLength={50}
        onFocus={Helper.handleFocus}
        onChange={event => {
          props.handleChange(event);
        }}
        onBlur={event => props.handleBlurCompany(event)}
        value={props.product.companyname}
      />
    );
  }

  function renderSellPrice() {
    return (
      <input
        type="text"
        inputMode="numeric"
        name="sellprice"
        id="sellprice"
        data-testid="Sell Price"
        placeholder="Sell price"
        autoComplete="off"
        maxLength={13}
        onFocus={Helper.handleFocus}
        onChange={props.handleChange}
        onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "float")}
        value={props.product.sellprice}
      />
    );
  }

  function renderProductDescription() {
    return (
      <input
        type="text"
        name="productname"
        id="productname"
        autoFocus
        autoComplete="off"
        data-testid="Product Description"
        placeholder="Product description"
        maxLength={255}
        onFocus={Helper.handleFocus}
        onChange={props.handleChange}
        onBlur={event => props.handleBlur(event, Constants.PRODUCT, null, null, "text")}
        value={props.product.productname}
      />
    );
  }
}

function renderAdditionalSuppliers(props) {
  if (props.product.suppliers) {
    // These companies are pulled from the bridge tapropsble
    return props.product.suppliers.map((company, index) => {
      // Make cost/SKU editable, even if a supplier has not been picked
      // const isNewSupplier = company.supplieruuid === "new";
      const isNewSupplier = false;
      return (
        <React.Fragment key={"supplierwidget-" + index}>
          <span className="supplierwidget-item">
            <input
              type="text"
              name="companyname"
              id="companyname"
              data-testid="Additional Supplier Name"
              placeholder="Supplier name"
              maxLength={50}
              onFocus={Helper.handleFocus}
              onChange={event => {
                props.handleChangeBridge(event, index);
              }}
              onBlur={event => props.handleBlurCompanyBridge(event, index)}
              value={company.companyname}
              autoComplete="off"
            />
          </span>
          <span className="supplierwidget-item desktop">
            <input
              type="text"
              name="sku"
              id="sku"
              data-testid="Additional Supplier SKU"
              className={isNewSupplier ? " save-disabled " : " "}
              placeholder="SKU"
              maxLength={32}
              onFocus={Helper.handleFocus}
              onChange={event => {
                props.handleChangeBridge(event, index);
              }}
              onBlur={event => props.handleBlurBridge(event, index, "text")}
              value={company.sku}
              autoComplete="off"
              disabled={isNewSupplier}
            />
          </span>
          <span className="supplierwidget-item currency">
            <input
              type="text"
              inputMode="numeric"
              name="cost"
              id="cost"
              data-testid="Additional Supplier Cost"
              className={isNewSupplier ? " save-disabled " : ""}
              placeholder="Cost"
              maxLength={13}
              onFocus={Helper.handleFocusSelect}
              onChange={event => {
                props.handleChangeBridge(event, index);
              }}
              onBlur={event => props.handleBlurBridge(event, index, "float")}
              value={company.cost}
              autoComplete="off"
              disabled={isNewSupplier}
            />
          </span>
          <span className="supplierwidget-item action-icon gridCenter">
            <CircleXmarkIcon handleClick={() => props.handleClickDeleteSupplierBridge(index)} />
          </span>
          {/* <span className="supplierwidget-item action-icon gridCenter"></span> */}
        </React.Fragment>
      );
    });
  } else {
    return "";
  }
}

function renderDeleteSupplierButton(props) {
  if (props.product.companyuuid) {
    return <CircleXmarkIcon handleClick={props.handleClickDeleteSupplier} />;
  } else {
    return "";
  }
}

function renderAddSupplierButton(props) {
  if (props.product.companyuuid && props.product.productuuid) {
    return (
      <span onClick={props.handleClickAddSupplier}>
        <CirclePlusIcon />
      </span>
    );
  } else {
    return "";
  }
}

export default ProductCard;

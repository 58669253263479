import React from "react";

import { faBoxOpen, faCaretDown, faCaretRight, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import CircleXmarkIcon from "./img/CircleXmarkIcon";
import FlexInput from "./FlexInput";
import ListHeaderView from "./ListHeaderView";
import ListItemView from "./ListItemView";
import numeral from "numeral";
import TagList from "./TagList";
import Tooltip from "./Tooltip";

// View for editing a Order.
class Purchase extends BaseDetailViewComponent {
  renderContactSearchResultsToColumns = item => {
    let fullname = "";
    let mobileviewphone = "";
    fullname = item.firstname + " " + item.lastname;
    // Display mobilephone, then otherphone (if no mobile), then "-" if neither
    mobileviewphone = item.mobilephone ? item.mobilephone : item.otherphone ? item.otherphone : "-";
    fullname = <span data-testid={"Supplier Search Results: " + fullname}>{fullname}</span>;
    return [{ rowvalue: item.companyname }, { rowvalue: fullname }, { rowvalue: mobileviewphone, classes: "desktop" }];
  };

  renderProductList = () => {
    let classes = "noPrint";
    if (this.state.order?.orderitems.length === 0) {
      classes += " save-disabled";
    } else if (!Helper.orderContainsUnreceivedItems(this.state.order)) {
      classes += " save-disabled";
    }

    let received = this.state.isNew ? (
      ""
    ) : (
      <span>
        Rec'd <FontAwesomeIcon icon={faBoxOpen} className={classes} onClick={this.handleReceiveAll} />
      </span>
    );
    const headers = [
      { classes: "header desktop purchaseCustomer", columnheading: "Customer" },
      { classes: "header desktop", columnheading: "SKU" },
      { classes: "header", columnheading: "Product" },
      { classes: "header right-aligned desktop", columnheading: "Unit Price" },
      {
        classes: "header right-aligned",
        columnheading: this.state.showInventory ? "Qty / Inv" : "Qty",
        eyeball: true,
        eyeballSlashed: this.state.showInventory,
        handleToggleEyeball: this.handleToggleInventory,
      },
      { classes: "header right-aligned desktop", columnheading: "Cost" },
      { classes: "header right-aligned desktop", columnheading: "Total" },
      { classes: "header right-aligned purchaseReceived", columnheading: received },
      { classes: "header desktop centerAligned purchaseStatus", columnheading: "Status" },
      { classes: "headerlast", columnheading: "" },
    ];
    return (
      <div className="areaProductList">
        <div className="purchaseproductlist">
          <ListHeaderView headerRowItems={headers} />
          <hr className="headerRule6" />
          <ListItemView
            listitems={this.state.order.orderitems}
            expandedListItems={null}
            selectedListItems={this.state.selectedOrderItems}
            renderItemToColumns={this.renderSelectedProductToColumns}
            toggleCollapsed={() => {}}
            selectListItem={this.selectOrderItem}
            handleEditItem={() => {}}
            handleTouchStart={this.handleTouchStart}
            handleTouchEnd={this.handleTouchEnd}
          />
        </div>
      </div>
    );
  };

  renderSelectedProductToColumns = item => {
    let contactname = "";
    const index = this.state.order.orderitems.indexOf(item);
    if (item.parent_data && item.parent_data.length > 0) {
      let displayPhone = item.parent_data[0].mobilephone ? item.parent_data[0].mobilephone : item.parent_data[0].otherphone;
      if (displayPhone) {
        displayPhone = " - " + displayPhone;
      }
      contactname = (
        <span className="clickablePhone" data-testid="Line Item Contact Name">
          &nbsp;&nbsp;&nbsp;{item.parent_data[0].contactname} <a href={"tel:" + displayPhone}>{displayPhone}</a>
        </span>
      );
    }
    // If there are multiple contact names
    if (item.parent_data && item.parent_data.length > 1) {
      // Build a collapsible list
      contactname = this.renderMultipleContacts(item);
    }
    //const lineitemstatus = Helper.renderStatus(item.lineitemstatus);
    // let quantity = "";
    // if (lineitemstatus === Constants.ORDER_STATUS_OPEN) {
    // }
    const lineitemstatus = (
      <span className="popup">
        <span
          data-testid="Line Item Status"
          onClick={event => {
            if (event && event.stopPropagation) {
              event.stopPropagation();
            }
            this.setState({ showPurchaseItemStatus: true, selectPurchaseItemStatusIndex: index });
          }}
        >
          {Helper.renderStatus(item.lineitemstatus)}
        </span>
        {this.renderSelectStatus(index)}
      </span>
    );
    let renderedValue = item.productname;
    if (item.tags?.length > 0) {
      const icon = <FontAwesomeIcon icon={faTag} />;
      let tagListElement = <TagList tags={item.tags} readonly={true} />;
      let tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
      renderedValue = (
        <span>
          {item.productname} {tooltip}
        </span>
      );
    }
    const productname = (
      <div className="areaInputItem" data-testid="Purchase Order Item">
        <FlexInput
          pencilEdit={true}
          type="text"
          textarea={true}
          name="productname"
          id="productname"
          datatestid="Line Item Product Name"
          autoComplete="off"
          rows="4"
          maxLength={255}
          onChange={event =>
            this.handleChange(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid
            )
          }
          onFocus={Helper.handleFocus}
          onBlur={event =>
            this.handleBlur(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid,
              null,
              "text" // datatype
            )
          }
          value={item.productname}
          renderedValue={renderedValue}
        />
      </div>
    );

    let productQuantity = (
      <div className="areaInputItem">
        <FlexInput
          type="text"
          inputMode="numeric"
          name="quantity"
          id="quantity"
          datatestid="Line Item Quantity"
          autoComplete="off"
          maxLength={13}
          onChange={event =>
            this.handleChange(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid
            )
          }
          onFocus={Helper.handleFocus}
          onBlur={event =>
            this.handleBlur(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid,
              null,
              "float" // datatype
            )
          }
          value={item.quantity}
        />
      </div>
    );
    if (this.state.showInventory) {
      if (item.affectinventory) {
        productQuantity = item.quantity + " / " + item.inventory;
      } else {
        productQuantity = item.quantity + " / -";
      }
    }

    const sku = <span data-testid="Line Item SKU">{item.sku ? item.sku : ""}</span>;

    return [
      { rowvalue: contactname, classes: "desktop purchaseCustomer" },
      // TODO: ADD SKU of the selected supplier (supplier at the top of the purchase)
      { rowvalue: sku, classes: "desktop" },
      { rowvalue: productname },
      {
        rowvalue: (
          <div className="areaInputItem">
            <FlexInput
              type="text"
              inputMode="numeric"
              name="sellprice"
              id="sellprice"
              datatestid="Line Item Sell Price"
              autoComplete="off"
              maxLength={13}
              onChange={event => this.handleChange(event, Constants.ORDER_ITEM, item.orderitemuuid ? item.orderitemuuid : item.uuid)}
              onFocus={Helper.handleFocus}
              onBlur={event =>
                this.handleBlur(
                  event,
                  Constants.ORDER_ITEM,
                  item.orderitemuuid ? item.orderitemuuid : item.uuid,
                  null,
                  "float" // datatype
                )
              }
              value={item.sellprice}
            />
          </div>
        ),
        classes: "desktop right-aligned",
      },
      {
        rowvalue: productQuantity,
        classes: "right-aligned",
      },
      {
        rowvalue: (
          <div className="areaInputItem">
            <FlexInput
              type="text"
              inputMode="numeric"
              name="cost"
              id="cost"
              datatestid="Line Item Cost"
              autoComplete="off"
              maxLength={13}
              onChange={event => this.handleChange(event, Constants.ORDER_ITEM, item.orderitemuuid ? item.orderitemuuid : item.uuid)}
              onFocus={Helper.handleFocus}
              onBlur={event =>
                this.handleBlur(
                  event,
                  Constants.ORDER_ITEM,
                  item.orderitemuuid ? item.orderitemuuid : item.uuid,
                  null,
                  "float" // datatype
                )
              }
              value={item.cost}
            />
          </div>
        ),
        classes: "desktop right-aligned",
      },
      {
        rowvalue: <span data-testid="Line Item Total Cost">{numeral(item.totalcost).format(Constants.CURRENCY)}</span>,
        classes: "desktop right-aligned",
      },
      {
        rowvalue: this.renderQuantityReceivedInput(item),
        classes: "right-aligned purchaseReceived",
      },
      {
        rowvalue: lineitemstatus,
        classes: "desktop purchaseStatus",
      },
      {
        rowvalue: (
          <span
            data-testid="Line Item Delete Button"
            onClick={event => {
              if (event && event.stopPropagation) {
                event.stopPropagation();
              }
              this.handleDeleteOrderItem(item.orderitemuuid ?? item.uuid);
            }}
          >
            <CircleXmarkIcon />
          </span>
        ),
        classes: "right-aligned circlebuttoncontainer",
      },
    ];
  };

  renderProductListNarrow = () => {
    const headers = [
      { classes: "header", columnheading: "Product" },
      { classes: "header right-aligned", columnheading: "Qty" },
      { classes: "header right-aligned", columnheading: "Total" },
      { classes: "header right-aligned", columnheading: "Rec'd" },
    ];
    return (
      <div className="productListGrid purchaseproductlist">
        <ListHeaderView headerRowItems={headers} />
        <ListItemView
          listitems={this.state.order.orderitems}
          expandedListItems={null}
          selectedListItems={this.state.selectedOrderItems}
          renderItemToColumns={this.renderSelectedProductToColumnsNarrow}
          toggleCollapsed={() => {}}
          selectListItem={this.selectOrderItem}
          handleEditItem={() => {}}
          handleTouchStart={this.handleTouchStart}
          handleTouchEnd={this.handleTouchEnd}
        />
      </div>
    );
  };

  renderSelectedProductToColumnsNarrow = item => {
    return [
      { rowvalue: item.productname },
      { rowvalue: item.quantity, classes: "right-aligned" },
      { rowvalue: numeral(item.totalcost).format(Constants.CURRENCY), classes: "right-aligned" },
      { rowvalue: item.quantityreceived, classes: "right-aligned" },
    ];
  };

  renderQuantityReceivedInput = item => {
    // If the line item has not been ordered, then you cannot edit the quantity received
    if (this.state.isNew) {
      return "";
    } else if (item.lineitemstatus === Constants.ORDER_STATUS_OPEN) {
      return <span data-testid="Line Item Quantity Received">{item.quantityreceived}</span>;
    } else {
      return (
        <div className="areaInputItem">
          <input
            type="text"
            inputMode="numeric"
            name="quantityreceived"
            id="quantityreceived"
            data-testid="Line Item Quantity Received"
            autoComplete="off"
            maxLength={13}
            onChange={event =>
              this.handleChange(
                event,
                Constants.ORDER_ITEM,
                // TODO: Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid
              )
            }
            onFocus={Helper.handleFocus}
            onBlur={event =>
              this.handleBlur(
                event,
                Constants.ORDER_ITEM,
                // TODO: Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid,
                null,
                "float" // datatype
              )
            }
            onClick={event => {
              event.stopPropagation();
            }}
            value={item.quantityreceived}
          />
        </div>
      );
    }
  };

  renderMultipleContacts = item => {
    let first = true;
    const expanded = Helper.inList(this.state.expandedListItems, item.uuid);
    // Render expanded/collapsed caret
    let caret = <FontAwesomeIcon icon={faCaretRight} />;
    if (expanded) {
      caret = <FontAwesomeIcon icon={faCaretDown} />;
    }
    const contacts = [];
    item.parent_data.forEach(refitem => {
      let displayPhone = refitem.mobilephone ? refitem.mobilephone : refitem.otherphone;
      if (displayPhone) {
        displayPhone = " - " + displayPhone;
      }
      if (first) {
        first = false;
        contacts.push(
          <div key={refitem.orderitemuuid} data-testid="Line Item Contact Name">
            <span
              onClick={event => {
                this.toggleCollapsed(event, item.uuid);
              }}
            >
              {caret}
            </span>
            &nbsp;
            {refitem.contactname}
            <a href={"tel:" + displayPhone}>{displayPhone}</a>
          </div>
        );
      } else if (expanded) {
        contacts.push(
          <div key={refitem.orderitemuuid}>
            &nbsp;&nbsp;&nbsp;{refitem.contactname}
            <a href={"tel:" + displayPhone}>{displayPhone}</a>
          </div>
        );
      }
    });
    return <div>{contacts}</div>;
  };

  renderSelectStatus = index => {
    if (this.state.showPurchaseItemStatus && this.state.selectPurchaseItemStatusIndex === index) {
      const statuses = [
        { uuid: Constants.ORDER_STATUS_OPEN, statusname: "Open" },
        { uuid: Constants.ORDER_STATUS_ORDERED, statusname: "Ordered" },
        { uuid: Constants.ORDER_STATUS_FULLY_RECEIVED, statusname: "Received" },
        { uuid: Constants.ORDER_STATUS_PARTIALLY_RECEIVED, statusname: "Partially Received" },
        { uuid: Constants.ORDER_STATUS_BACKORDERED, statusname: "Backordered" },
      ];

      const statuslist = statuses.map(status => {
        return (
          <div
            key={status.uuid}
            className="list-item "
            onClick={event => {
              if (event && event.stopPropagation) {
                event.stopPropagation();
              }
              this.handleSelectStatus(status.uuid, index);
              this.setState({ showPurchaseItemStatus: false, selectPurchaseItemStatusIndex: -1 });
            }}
          >
            {status.statusname}
          </div>
        );
      });

      return (
        <span className="popuplist" ref={this.purchaseItemStatusRef}>
          {statuslist}
        </span>
      );
    }
  };

  handleChangeSupplier = (response, contact = null) => {
    if (response === Constants.OVERLAY_RESPONSE_CREATE) {
      // Show the "Create a New Contact" overlay
    } else if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      // this.selectCustomerListItem(contact, { companyname: contact.companyname });
      this.selectCustomerListItem(contact);
    }
  };

  getOrderType = () => {
    return Constants.PURCHASE;
  };
}
export default Purchase;

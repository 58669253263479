import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import * as Constants from "./Constants";
import * as Helper from "./Helper";

class NavButtons extends React.Component {
  render = () => {
    let menuItems = Helper.getMenuItems(this.props.appState, this.props.handleMenuClick, 0);
    const classes = "nav-icon" + (this.props.appState.currentMenu === Constants.SETTINGS ? " current-menu" : "");
    const salespersonFirst = this.props.appState?.salesperson?.split(" ")[0];
    // Variable for Client name
    const name =
      this.props.appState.peers?.find(peer => peer.clientuuid === this.props.appState.clientuuid)?.name ?? this.props.appState?.clientSettings?.NAME;
    const clientname = (
      <span className="nav-client" data-testid="Users Client Name">
        {name}
      </span>
    );

    let navBarContainerClasses = "nav-bar-container";
    let bellClasses = "bell nav-icon desktop-inline ";
    let gearTitle = `${this.props.appState?.salesperson} (${this.props.appState?.username})`;
    return (
      <div className={navBarContainerClasses}>
        {this.renderMenuItems(menuItems)}
        <span className={bellClasses}>{/* <FontAwesomeIcon icon={faBell} /> */}</span>
        <span className={classes} id="gear" onClick={this.props.handleGearClick} data-testid="Gear" title={gearTitle}>
          <span className="gridCenter nav-svg">
            <FontAwesomeIcon icon={faGear} />
          </span>
          <span className="nav-label" title="Logged in User Name">
            Hello, {salespersonFirst}
          </span>
          {clientname}
        </span>
      </div>
    );
  };

  renderMenuItems = menuItems => {
    const output = menuItems.map(menuitem => {
      let iconClassName = "nav-icon ";
      let labelClassName = "nav-label ";
      let svgClassName = "gridCenter nav-svg " + menuitem.submenu.toLowerCase();
      if (this.props.appState.currentMenu === menuitem.menu || this.props.appState.currentView === menuitem.submenu) {
        svgClassName += " current-menu ";
        labelClassName += " current-menu ";
      }
      // The word "Billing" is a gerund and should not be pluralized
      // const label = menuitem.menu === Constants.BILLINGS ? "Billing" : menuitem.menu;
      let label = menuitem.menu;
      if (menuitem.menu === Constants.BILLINGS) {
        label = "Billing";
      } else if (menuitem.menu === Constants.REPAIRS) {
        label = "Service";
      }

      let notification = "";
      if (menuitem.notification) {
        // Only display a number 1-9 or 9+ for the notification count
        const count = menuitem.notification > 9 ? "9+" : Math.max(0, menuitem.notification);
        if (count > 0) {
          notification = (
            <span className="menuItemNotification" title={menuitem.notificationtitle}>
              {count}
            </span>
          );
        }
      }
      return (
        <span key={menuitem.menu} id={menuitem.menu} className={iconClassName} onClick={menuitem.handler}>
          <span className={svgClassName}>
            {menuitem.svg} {notification}
          </span>
          <span className={labelClassName} data-testid={label}>
            {label}
          </span>
        </span>
      );
    });
    return <div className="nav-button-container">{output}</div>;
  };
}

export default NavButtons;

import React from "react";

// Constants
import * as Constants from "./Constants";
import * as Helper from "./Helper";

// Components
import BaseDetailViewComponent from "./BaseDetailViewComponent";

// View for editing a supplier.
class Supplier extends BaseDetailViewComponent {
  constructor(props) {
    super(props);

    // creates cursor focus on Supplier name
    this.baseDetailNameInput = React.createRef();

    let company;
    let contactuuid = null;
    if (!props.selectedItem) {
      const contact = Helper.getBlankContact(props.appState.salesperson, Constants.SUPPLIER_COMPANY);
      company = Helper.getBlankCompany(Constants.SUPPLIER_COMPANY, [contact], props.appState.salesperson);
    } else if (props.selectedItem.type === Constants.COMPANY) {
      company = Helper.deepCopy(props.selectedItem);
      if (company.contacts.length === 0) {
        const contact = Helper.getBlankContact(props.appState.salesperson, Constants.SUPPLIER_COMPANY);
        company.contacts.push(contact);
        contactuuid = contact.contactuuid;
      }
    } else if (props.selectedItem.type === Constants.CONTACT) {
      const contact = Helper.deepCopy(props.selectedItem);
      contactuuid = contact.contactuuid;
      company = Helper.getBlankCompany(Constants.SUPPLIER_COMPANY, [contact], props.appState.salesperson);
      company.companyname = contact.companyname;
      company.companyuuid = contact.companyuuid;
      company.uuid = contact.companyuuid;
    } else {
      console.log(Helper.clTimestamp(), "Unknown type: " + props.selectedItem.type);
    }
    this.state = {
      ...this.state,
      company: company,
      contactuuid: contactuuid, // Selected contactuuid from list
      viewName: Constants.SUPPLIER,
      isEditingCompanyName: company?.companyuuid === null,
      enableActionButtons: company?.companyuuid !== null,
    };
  }

  // Once component is loaded, fire GET request
  componentDidMount() {
    super.componentDidMount();
    if (this.state.company?.companyuuid) {
      this.setState({ isNew: false });
      this.getCompany(this.state.company.companyuuid);
    } else if (this.state.isEditingCompanyName) {
      this.baseDetailNameInput.current.focus();
    }
  }

  handleTouchStart = event => {
    const touchEvent = {
      time: Date.now(),
      event: event,
    };
    this.touchEvent = touchEvent;
    setTimeout(() => {
      if (this.touchEvent) {
        this.touchEvent = null;
        this.enableSupplierNameEdit();
      }
    }, Constants.SEARCH_DELAY);
  };

  handleTouchEnd = event => {
    if (event.cancelable) {
      event.preventDefault();
    }
    this.touchEvent = null;
  };

  handleEnterPressed = event => {
    if (event.target.id === "companyname") {
      this.handleBlurCompany(
        {
          target: { id: "companyname", type: "text", value: this.state.company.companyname },
        },
        null,
        () => {
          this.setState({ isEditingCompanyName: false, enableActionButtons: true });
        }
      );
    }
  };

  // Delete all of the contacts and the company.
  handleDelete = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete?",
      callback: this.maybeDeleteCompany,
      key: this.state.company.companyuuid,
    });
  };

  maybeDeleteCompany = (response, companyuuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteCompany(companyuuid);
    }
  };

  isReadyToSubmit = () => {
    // If we have a company name, we must also have a companyuuid,
    return (
      this.state.company?.companyname &&
      this.state.company.companyname.trim().length > 0 &&
      (this.state.company.contacts[0].firstname ||
        this.state.company.contacts[0].lastname ||
        this.state.company.contacts[0].email ||
        this.state.company.contacts[0].mobilephone ||
        this.state.company.contacts[0].otherphone ||
        this.state.company.contacts[0].website ||
        this.state.company.contacts[0].title ||
        this.state.company.contacts[0].address1 ||
        this.state.company.contacts[0].address2 ||
        this.state.company.contacts[0].city ||
        this.state.company.contacts[0].state ||
        this.state.company.contacts[0].postalcode)
    );
  };

  save = () => {
    if (this.isReadyToSubmit()) {
      if (!this.state.company.companyuuid) {
        this.postCompany();
      }
    }
  };

  enableSupplierNameEdit = () => {
    this.setState({ isEditingCompanyName: true, enableActionButtons: false }, () => {
      this.baseDetailNameInput.current.focus();
    });
  };

  maybeDisableSupplierNameEdit = (value, event) => {
    // Turn Off Edit Mode for the Supplier when you leave the field, but only if there is a value
    // And this is not a new supplier
    if (value && !this.state.isNew) {
      // If the event source is the input field, the blur event has already fired
      // No need to fire it again here
      if (event.target.id === "companyname") {
        this.setState({ isEditingCompanyName: false, enableActionButtons: true });
      } else {
        // If event target is a mouse click, fire the blur event
        this.handleBlurCompany(
          {
            target: { id: "companyname", type: "text", value: this.state.company.companyname },
          },
          null,
          () => {
            this.setState({ isEditingCompanyName: false, enableActionButtons: true });
          }
        );
      }
    }
  };

  setStateContacts = (contacts, callback = null) => {
    this.setState(
      prevState => ({
        company: {
          ...prevState.company,
          contacts: contacts,
        },
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  updateCompanyState = (id, value) => {
    this.setState(prevState => ({
      company: {
        ...prevState.company,
        [id]: value,
      },
    }));
  };

  getTotalPrice = () => {
    return null;
  };

  getContactsContainerRef = () => {
    return this.state.company;
  };

  getCompany = companyuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const params = { companyuuid: companyuuid };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        let company = response.body;
        // Make sure we have at least one contact in the list
        // If not, add a blank one
        if (company.contacts.length === 0) {
          company.contacts.push(Helper.getBlankContact(this.props.appState.salesperson, Constants.SUPPLIER_COMPANY));
        }

        // Move the selected contact to front of list in company, if necessary
        company = Helper.moveContactToFront(this.state.contactuuid, company);

        //Update state with response, turn off downloading
        this.setState(
          {
            company: company,
            error: null,
            downloading: false,
            isNew: false,
          },
          () => {
            //Hide overlay after database action is complete
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 404) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the data.",
          });
        });
      }
    });
  };

  // Create a new supplier record in the database
  postCompany = () => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const data = {
      companyuuid: this.state.company.companyuuid,
      companytype: Constants.SUPPLIER_COMPANY,
      companyname: this.state.company.companyname.trim(),
      active: this.state.company.active,
      taxable: false,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        //Update state with response, turn off downloading
        response.body.contacts = [this.state.company.contacts[0]];
        this.setState(
          {
            company: { ...response.body, type: Constants.COMPANY },
            downloading: false,
            isNew: false,
            isEditingCompanyName: false,
            enableActionButtons: true,
          },
          () => {
            this.postContact(this.state.company.contacts[0]);
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the supplier.",
          });
        });
      }
    });
  };

  postContact = contact => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CONTACTS;
    const companyuuid = this.state.company.companyuuid;
    contact = {
      ...contact,
      companyuuid: companyuuid,
      taxable: false, // Not applicable to salespersons
      companytype: Constants.SUPPLIER_COMPANY,
    };
    Helper.postData(url, contact).then(response => {
      if (response.status === 200 && response.body) {
        // Set the edit mode to keep the new contact's card open
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.contactuuid === "new") {
            response.body.editmode = true;
            return response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          //this.props.updateBreadcrumb({ selectedItem: this.state.company });
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the contact.",
          });
        });
      }
    });
  };

  postShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = "/addresses";
    const address = {
      ...Helper.getContactByUUID(this.state.company, contactuuid).shippingaddress,
      contactuuid: contactuuid,
    };
    Helper.postData(url, address).then(response => {
      if (response.status === 200 && response.body) {
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "POST" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the shipping address.",
          });
        });
      }
    });
  };

  putCompany = (companyuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const data = {
      companyuuid: companyuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        //Update state to turn off downloading,
        this.setState({ downloading: false });
        //Hide overlay after database action is complete
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the supplier.",
          });
        });
      }
    });
  };

  putShipAddress = (addressuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });
    //set up to make database call
    const url = "/addresses";
    const data = {
      addressuuid: addressuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Replace contact with data from web service but keep editing flag
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.shippingaddress.addressuuid === addressuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "PUT" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the shipping address.",
          });
        });
      }
    });
  };

  deleteContact = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // If new contact, just remove from list
    if (contactuuid === "new") {
      const contacts = this.state.company.contacts.filter(contact => contact.contactuuid !== "new");
      this.setStateContacts(contacts, () => {
        this.setState({ downloading: false });
        this.props.hideOverlay();
      });
    } else {
      //set up to make database call
      const url = Constants.URL_CONTACTS;
      const params = { contactuuid: contactuuid };
      Helper.deleteData(url, params).then(response => {
        if (response.status === 200 && response.body) {
          // Remove the deleted contact from the list and redisplay
          const contacts = this.state.company.contacts.filter(contact => contact.contactuuid !== contactuuid);
          // Add a blank contact if the last contact was deleted.
          if (contacts.length === 0) {
            contacts.push(Helper.getBlankContact(this.props.appState.salesperson, Constants.SUPPLIER_COMPANY));
          }
          // Update state with response, turn off downloading and hide the overlay
          this.setStateContacts(contacts, () => {
            this.setState({ downloading: false });
            this.props.hideOverlay();
          });
        } else if (response.status === 409) {
          this.setState({ error: null, downloading: false }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Cannot delete supplier with open purchases.",
            });
          });
        } else if (response.status === 503) {
          this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
          this.props.hideOverlay();
        } else {
          this.setState({ error: "DELETE", downloading: false }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "There was an error deleting the contact.",
            });
          });
        }
      });
    }
  };

  deleteCompany = companyuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const params = { companyuuid: companyuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Update state to turn off downloading, hide the overlay, and follow breadcrumbs
        this.setState({ downloading: false, error: null }, () => {
          this.props.hideOverlay();
          this.props.followBreadcrumb();
        });
      } else if (response.status === 409 || response.status === 428) {
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting.",
          });
        });
      }
    });
  };

  deleteShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    const contact = Helper.getContactByUUID(this.state.company, contactuuid);
    const addressuuid = contact.shippingaddress.addressuuid;
    //set up to make database call
    const url = "/addresses";
    const params = { addressuuid: addressuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Turn off the shipping address switch
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.hasshippingaddress = false;
            contact.shippingaddress = Helper.getBlankShippingAddress();
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "DELETE" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting.",
          });
        });
      }
    });
  };
}
export default Supplier;

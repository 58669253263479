import React from "react";

import { animated, useSpring } from "@react-spring/web";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Capo from "../img/Capo.js";

function NewsWidget(props) {
  const [expanded, setExpanded] = React.useState(false);
  const openAnimation = useSpring({
    from: { maxHeight: "6em" },
    to: { maxHeight: expanded ? "100em" : "6em" },
    config: { duration: "400" },
  });
  let title = "Latest Updates";
  if (props.appState?.clientSettings?.NAME) {
    title = "Updates for " + props.appState?.clientSettings?.NAME;
  }
  const expanderIcon = expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />;
  const expander = (
    <div
      className="dbwExpander"
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      {expanderIcon}
    </div>
  );
  return (
    <animated.div
      className="dashboardWidget dbwNews"
      onClick={() => {
        setExpanded(!expanded);
      }}
      style={openAnimation}
    >
      <div className="dbwTitle">
        <span className="newsLogo">
          <Capo />
        </span>
        <span data-testid="Updates for">{title}</span>
      </div>
      <hr />
      <h3 className="white">Latest Enhancements</h3>
      <ul>
        <li>
          <span className="highlight">Pro tip:</span> To save time with quicker product scans, add all your items to an invoice before you hit save!
        </li>
        <li>Clicking the "Pending" badge next to a credit authorization response will now attempt to retrieve the latest status. [10/8/2024]</li>
        <li>
          The subscription transaction list in folders now displays additional status information, such as "Settled", "Voided," and "Funded."
          [10/3/2024]
        </li>
      </ul>
      <h3 className="white">Bug fixes</h3>
      <ul>
        <li>Gracefully handle errors when order item attributes (such as sales associate) cannot be updated due to a data conflict. [12/4/2024]</li>
        <li>Correctly setting cost on order items when scanning a barcode to add a product to an existing order/invoice. [11/8/2024]</li>
        <li>Purchase orders can now be emailed to supplier contacts with just a first OR last name (not both). [11/1/2024]</li>
        <li>Custom fields are now immediately available after creating a new subscription. [10/17/2024]</li>
        <li>We now correctly handle additional (infrequent) failure responses from our credit card terminal vendor, Handpoint. [10/8/2024]</li>
      </ul>
      {expander}
    </animated.div>
  );
}

export default NewsWidget;

import React from "react";

// Style
import "./css/searchbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { getTargetValue } from "./Helper";

function SearchBar(props) {
  return (
    <div className="search-wrapper">
      <span id="list-search-input-container">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        <input
          type="text"
          name={props.id || "searchkey"}
          id={props.id || "searchkey"}
          placeholder={props.searchPlaceholder}
          autoComplete="off"
          disabled={props.disabled}
          maxLength={50}
          autoFocus={props.autoFocus}
          onFocus={e => e.currentTarget.select()}
          onChange={event => {
            props.handleSearchChange(event.target.id, getTargetValue(event));
          }}
          onKeyDown={event => {
            if (props.handleSearchKeyDown) {
              props.handleSearchKeyDown(event);
            }
          }}
          // Cannot switch this to props.appState.searchkey for performance reasons
          value={props.searchkey}
          ref={props.fieldref}
        />
        <span id="clear-search" onClick={props.handleClearSearch} data-testid="Clear Search Value">
          <FontAwesomeIcon icon={faCircleXmark} />
        </span>
      </span>
    </div>
  );
}

export default SearchBar;

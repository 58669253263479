// ----BEGIN----
export const ACH_FILTER_ENABLED = "achfilterenabled";
export const ACK_WEBSOCKET = "ack-websocket";
export const ACTION_ADD_ENTERPRISE = "actionaddenterprise";
export const ACTION_CREATE_CLIENT = "actioncreateclient";
export const ACTION_CREATE_PRODUCT = "actioncreateproduct";
export const ACTION_DELETE_CLOSED_ORDER = "actiondeleteclosedorder";
export const ACTION_DELETE_QA = "actiondeleteqa";
export const ACTION_DEMO = "actiondemo";
export const ACTION_EDIT_CAMPAIGNS = "actioneditcampaigns";
export const ACTION_EDIT_COMMISSION = "actioneditcommission";
export const ACTION_EDIT_GLOBAL_SETTINGS = "actioneditglobalsettings";
export const ACTION_EDIT_ORDER_DATE = "actioneditorderdate";
export const ACTION_EDIT_SETTINGS = "actioneditsettings";
export const ACTION_EDIT_TIMESHEET = "actionedittimesheet";
export const ACTION_GET_CLERKHOUND_PRODUCTS = "actiongetclerkhoundproducts";
export const ACTION_GET_CLIENTS = "actiongetclients";
export const ACTION_GET_ENTERPRISES = "actiongetenterprises";
export const ACTION_GET_METRICS = "actiongetmetrics";
export const ACTION_GET_ORDERS = "actiongetorders";
export const ACTION_GET_UPCS = "actiongetupcs";
export const ACTION_GET_USER_TYPE = "actiongetusertype";
export const ACTION_IMPORT_EXPORT = "actionimportexport";
export const ACTION_INVENTORY = "actioninventory";
export const ACTION_INVENTORY_BEGIN = "actioninventorybegin";
export const ACTION_INVENTORY_CANCEL = "actioninventorycancel";
export const ACTION_INVENTORY_END = "actioninventoryend";
export const ACTION_INVENTORY_PAUSE = "actioninventorypause";
export const ACTION_INVENTORY_RESUME = "actioninventoryresume";
export const ACTION_INVENTORY_SAVE = "actioninventorysave";
export const ACTION_INVENTORY_STATS = "actioninventorystats";
export const ACTION_MARK_AS_PAID = "actionmarkaspaid";
export const ACTION_PAY_BY_VAULT = "actionpaybyvault";
export const ACTION_POST_QA = "actionpostqa";
export const ACTION_POST_REFUND_TRANSACTION = "actionpostrefundtransaction";
export const ACTION_PUBLISH = "actionpublish";
export const ACTION_REGISTER = "actionregister";
export const ACTION_REQUEST_INFO = "actionrequestinfo";
export const ACTION_REQUEST_PAYMENT_METHOD = "actionrequestpaymentmethod";
export const ACTION_SEND_MFA = "actionsendmfa";
export const ACTION_SEND_CARTLOOM_PRODUCT_UPDATE = "actionsendcartloomproductupdate";
export const ACTION_SUGGESTIONS = "actionsuggestions";
export const ACTION_SWITCH_CLIENT = "actionswitchclient";
export const ACTION_SWITCH_STORE = "actionswitchstore";
export const ACTION_TEST = "actiontest";
export const ACTION_UNPUBLISH = "actionunpublish";
export const ACTION_UPDATE_CLIENT = "actionupdateclient";
export const ACTION_UPDATE_ORDER_STATUS = "actionupdateorderstatus";
export const ACTION_UPDATE_PRODUCT = "actionupdateproduct";
export const ACTION_VIEW_BILLING = "actionviewbilling";
export const ACTION_VIEW_MERCHANT_MANAGER = "actionviewmerchantmanager";
export const ACTIVE = "active";
export const ALL = "all";
export const ALTUPC_SUBSCRIPTION_PAYMENT = "400000000015";
export const AMOUNT_COLUMN_WIDTH = 12;
export const APPROVED = "approved";
export const ATTR_DATA_VALUE = "data-value";
export const AUTH_APPROVED = "authapproved";
export const AUTH_CANCELLED = "authcancelled";
export const AUTH_DECLINED = "authdeclined";
export const AUTH_FAILED = "authfailed";
export const AUTH_NEW = "authnew";
export const AUTH_PARTIAL_APPROVAL = "authpartialapproval";
export const AUTH_PENDING = "authpending";
export const AUTH_TOKEN = "authtoken";
export const AUTHORIZE_REFUND = "authorize-refund";
export const AUTHORIZE_RESPONSE = "authorize-response";
export const AUTHORIZE_ORDER_PAYMENT = "authorize-order-payment";
export const AUTHORIZE_SALE = "authorize-sale";
export const AUTHORIZE_SALE_VAULT = "authorize-sale-vault";
export const BILLING = "Billing";
export const BILLING_ADD_PAYMENT_TO_INVOICE = "addpaymenttoinvoice";
export const BILLING_DELETE_CANCEL_INVOICE = "deletecancelinvoice";
export const BILLING_DELETE_PLAN = "deleteplan";
export const BILLING_GET_ALL_INVOICES = "getallinvoices";
export const BILLING_GET_CUSTOM_FIELDS = "getcustomfields";
export const BILLING_GET_CUSTOMER = "getcustomer";
export const BILLING_GET_INVOICES = "getinvoices";
export const BILLING_GET_PLAN = "getplan";
export const BILLING_GET_PLANS = "getplans";
export const BILLING_GET_SUBSCRIPTION = "getsubscription";
export const BILLING_GET_TRANSACTIONS = "gettransactions";
export const BILLING_INVOICE = "BillingInvoice";
export const BILLING_PLAN = "BillingPlan";
export const BILLING_POST_APPLY_INVOICE_PAYMENT = "postapplyinvoicepayment";
export const BILLING_POST_CREATE_APPLICATION = "postcreateapplication";
export const BILLING_POST_CUSTOM_FIELD = "postcustomfield";
export const BILLING_POST_CUSTOMER = "postcustomer";
export const BILLING_POST_EMAIL_APPLICATION = "postemailapplication";
export const BILLING_POST_PLAN = "postplan";
export const BILLING_POST_PLAN_ARCHIVE = "postplanarchive";
export const BILLING_POST_REFUND_TRANSACTION = "postrefundtransaction";
export const BILLING_POST_RESEND_INVOICE = "postresendinvoice";
export const BILLING_POST_SEND_INVOICE = "postsendinvoice";
export const BILLING_POST_STORED_PAYMENT = "poststoredpayment";
export const BILLING_POST_SUBSCRIPTION = "postsubscription";
export const BILLING_POST_SUBSCRIPTION_STATUS = "postsubscriptionstatus";
export const BILLING_POST_UPDATE_APPLICATION = "postupdateapplication";
export const BILLING_PUT_CUSTOMER = "putcustomer";
export const BILLING_PUT_DELETE_STORED_PAYMENT = "putdeletestoredpayment";
export const BILLING_PUT_PLAN = "putplan";
export const BILLING_PUT_STORED_PAYMENT = "putstoredpayment";
export const BILLING_PUT_SUBSCRIPTION = "putsubscription";
export const BILLING_SUBSCRIPTION = "BillingSubscription";
export const BILLINGS = "Billings";
export const CALLBACK = "callback";
export const CAMPAIGN = "Campaign";
export const CAMPAIGN_STATUS_ARCHIVED = "archived";
export const CAMPAIGN_STATUS_DRAFT = "draft";
export const CAMPAIGN_STATUS_PUBLISHED = "published";
export const CAMPAIGNS = "Campaigns";
export const CARD_READER_CANCELLING = "cardreadercancelling";
export const CARD_READER_INITIALIZING = "cardreaderinitializing";
export const CARD_READER_MANUAL_RETRIEVAL = "cardreadermanualretrieval";
export const CARD_READER_READY = "cardreaderready";
export const CARD_READER_REQUEST_SALE_AUTH = "cardreaderrequestsaleauth";
export const CARD_READER_UNINITIALIZED = "cardreaderuninitialized";
export const CARD_READER_WAITING = "cardreaderwaiting";
export const CARD_TYPE_ACH = "AP";
export const CARTLOOM = "cartloom";
export const CASH = "cash";
export const CHANGE = "change";
export const CHECK = "check";
export const CHECK_EMAIL_VERIFICATION = "checkemailverification";
export const CLIENT = "Client";
export const COLOR_MODE_DARK = "dark";
export const COLOR_MODE_LIGHT = "light";
export const COMMAND_DELETE_QA = "command_delete_qa";
export const COMMAND_DEMO_QA = "command_demo_qa";
export const COMMAND_MAINTENANCE_MODE = "command_maintenance_mode";
export const COMMAND_ORDER_QA = "command_order_qa";
export const COMPANY = "Company";
export const CONTACT = "Contact";
export const CONTENT_TYPE_FORM = "application/x-www-form-urlencoded";
export const CONTENT_TYPE_JSON = "application/json";
export const CREATE_ACCOUNT = "createaccount";
export const CREDIT = "credit";
export const CREDIT_REFUND = "creditrefund";
export const CURRENCY = "0,0.00";
export const CURRENCY_NO_COMMA = "0.00";
export const CURRENCY_WITH_SYMBOL = "$0,0.00";
export const CUSTOM_FIELD = "customfield";
export const CUSTOMER = "Customer";
export const CUSTOMER_COMPANY = 2;
export const CUSTOMER_EXTERNAL = 4;
export const CUSTOMER_FAMILY = 3;
export const CUSTOMER_PROSPECT = 5;
export const CUSTOMER_SEARCH = "CustomerSearch";
export const CUSTOMERS = "Customers";
export const DASHBOARD = "Dashboard";
export const DATE_FILTER_CLOSED = "closedate";
export const DATE_FILTER_CREATION = "creationdatetime";
export const DATE_FILTER_DUE_DATE = "duedatetime";
export const DATE_FILTER_UPDATED = "lastupdated";
export const DEBUG = "debug";
export const DECIMAL_VALUE = "0,0.[00]";
export const DECLINED = "declined";
export const DELETE = "DELETE";
export const DEPOSIT_APPLIED = "depositapplied";
export const DISPOSITION_ALL = "dispositionall";
export const DISPOSITION_INVENTORIED = "dispositioninventoried";
export const DISPOSITION_MATCHED = "dispositionmatched";
export const DISPOSITION_MISSING = "dispositionmissing";
export const DISPOSITION_OVERAGE = "dispositionoverage";
export const DISPOSITION_PENDING = "dispositionpending";
export const DISPOSITION_UNKNOWN = "dispositionunknown";
export const DOWN_ARROW = 40;
export const DOWNLOAD_STATE_CANCELLED = "cancelled";
export const DOWNLOAD_STATE_DOWNLOADING = "downloading";
export const DUPLICATE_EMAIL = "Duplicate email";
export const EDIT_DELAY = 500;
export const EMAIL = "email";
export const EMAIL_ACTION_GET_CUSTOMERS = "emailgetcustomers";
export const EMAIL_ACTION_GET_MESSAGES = "emailgetmessages";
export const END = 35;
export const ENTER = 13;
export const ERROR_API_NETWORK = "NETWORK";
export const ESC = 27;
export const EVENT_KEYDOWN = "keydown";
export const EVENT_RESIZE = "resize";
export const EVENT_SCROLL = "scroll";
export const EXPORT_STATE_CANCELLED = "cancelled";
export const EXPORT_STATE_EXPORTING = "exporting";
export const FAIL = "fail";
export const FALSE = "false";
export const FEATURE_BILLING = "billing";
export const FEATURE_CARTLOOM = "cartloom";
export const FEATURE_EMAIL = "email";
export const FEATURE_HANDPOINT = "handpoint";
export const FEATURE_INVOICES = "invoices";
export const FEATURE_ORDERS = "orders";
export const FEATURE_PRODUCTS = "products";
export const FEATURE_PURCHASES = "purchases";
export const FEATURE_QUOTES = "quotes";
export const FEATURE_REPAIRS = "repairs";
export const FEATURE_REVERB = "reverb";
export const FEATURE_ROSTERHOUND = "rosterhound";
export const FEATURE_SUPPLIERS = "suppliers";
export const FEATURE_TEXT = "text";
export const FEATURE_TIMESHEETS = "timesheets";
export const FILTER_ACTIVE = "active";
export const FILTER_DATE_ALL = "all";
export const FILTER_DATE_CUSTOM = "custom";
export const FILTER_DATE_LAST_MONTH = "lastmonth";
export const FILTER_DATE_LAST_QUARTER = "lastquarter";
export const FILTER_DATE_LAST_WEEK = "lastweek";
export const FILTER_DATE_LAST_YEAR = "lastyear";
export const FILTER_DATE_THIS_MONTH = "thismonth";
export const FILTER_DATE_THIS_QUARTER = "thisquarter";
export const FILTER_DATE_THIS_WEEK = "thisweek";
export const FILTER_DATE_THIS_YEAR = "thisyear";
export const FILTER_DATE_TODAY = "today";
export const FILTER_DATE_YESTERDAY = "yesterday";
export const FILTER_INACTIVE = "inactive";
export const FIND_MATCHES = "findmatches";
export const FOLDER_AGREEMENTS = "agreements";
export const FOLDER_AUTHORIZATIONS = "authorizations";
export const FOLDER_CARTLOOM = "cartloom";
export const FOLDER_EMAIL = "email";
export const FOLDER_INTERNAL_NOTES = "internalnotes";
export const FOLDER_INVOICES = "invoices";
export const FOLDER_LINKED_ITEMS = "linkeditems";
export const FOLDER_MAAST_CARDS = "maastcards";
export const FOLDER_MAAST_CUSTOM = "maastcustom";
export const FOLDER_MAAST_INVOICES = "maastinvoices";
export const FOLDER_MAAST_TRANSACTIONS = "maasttransactions";
export const FOLDER_NOTES = "notes";
export const FOLDER_ORDERS = "orders";
export const FOLDER_PHOTOS = "photos";
export const FOLDER_PRINTED_NOTES = "printednotes";
export const FOLDER_PROSPECT_MATCHES = "prospectmatches";
export const FOLDER_PURCHASES = "puirchases";
export const FOLDER_QUOTES = "quotes";
export const FOLDER_REPAIRS = "repairs";
export const FOLDER_REVERB = "reverb";
export const FOLDER_SHIPPING = "shipping";
export const FOLDER_TEXT = "text";
export const FOLDER_UNPAID_INVOICES = "unpaidinvoices";
export const FORGOT_EMAIL = "forgotemail";
export const GET = "GET";
export const GET_APPLIED_DEPOSITS = "getapplieddeposits";
export const GET_AUTH_TOKEN = "getauthtoken";
export const GET_EMPLOYEES = "getemployees";
export const GET_LOGO_PNG = "getlogopng";
export const GET_LOGO_SVG = "getlogosvg";
export const GET_SPAWNED_DEPOSITS = "getspawneddeposits";
export const GIFTCARD = "giftcard";
export const GOOD = "good";
export const HANDPOINT = "Handpoint";
export const HANDPOINT_AUTHORISED = "AUTHORISED";
export const HANDPOINT_CANCELLED = "CANCELLED";
export const HANDPOINT_DECLINED = "DECLINED";
export const HANDPOINT_FAILED = "FAILED";
export const HANDPOINT_IN_PROGRESS = "IN_PROGRESS";
export const HANDPOINT_PARTIAL_APPROVAL = "PARTIAL_APPROVAL";
export const HANDPOINT_POLLING_COUNT = 71;
export const HANDPOINT_POLLING_INTERVAL = 1000;
export const HANDPOINT_PROCESSED = "PROCESSED";
export const HANDPOINT_REFUNDED = "REFUNDED";
export const HANDPOINT_SELECTED_TERMINAL = "handpointselectedterminal";
export const HANDPOINT_TYPE_MOTO_REFUND = "MOTO_REFUND";
export const HANDPOINT_TYPE_MOTO_REVERSAL = "MOTO_REVERSAL";
export const HANDPOINT_TYPE_REFUND = "REFUND";
export const HANDPOINT_TYPE_SALE = "SALE";
export const HANDPOINT_TYPE_VOID_SALE = "VOID_SALE";
export const HANDPOINT_UNDEFINED = "UNDEFINED";
export const HOME = 36;
export const HTML_INPUT_TYPE_CHECKBOX = "checkbox";
export const HTML_INPUT_TYPE_SELECT_ONE = "select-one";
export const IMPORT = "Import";
export const INACTIVE = "inactive";
export const INFO_LINE_ITEM_DIRECT_CHECKOUT_FEE = "Direct Checkout Fee";
export const INFO_LINE_ITEM_SALES_TAX_COLLECTED = "Sales Tax Collected";
export const INFO_LINE_ITEM_SALES_TAX_REMITTED = "Sales Tax Remitted";
export const INFO_LINE_ITEM_SELLING_FEE = "Selling Fee";
export const INFO_LINE_ITEM_SHIPPING = "Shipping Fee Collected";
export const INFO_LINE_ITEM_SKU = "INFO_LINE_ITEM";
export const INFO_LINE_ITEM_TAX_ON_FEES = "Tax on Fees";
export const INITIALIZE = "initialize";
export const INTEGER_NO_COMMA = "0";
export const INTEGER_WITH_COMMA = "0,0";
export const INVENTORY = "Inventory";
export const INVENTORY_STATUS_DELETED = "deleted";
export const INVENTORY_STATUS_MATCHED = "matched";
export const INVENTORY_STATUS_NEW = "new";
export const INVENTORY_STATUS_NOT_INVENTORIED = "notinventoried";
export const INVENTORY_STATUS_NOT_MATCHED = "notmatched";
export const INVENTORY_STATUS_PENDING = "pending";
export const INVENTORY_STATUS_SAVED = "saved";
export const INVOICE = "Invoice";
export const INVOICES = "Invoices";
export const KEY_METRIC_CUSTOMER_ACQUISITION = "customer_acquisition";
export const KEY_METRIC_GROSS_MARGIN = "gross_margin";
export const KEY_METRIC_INVENTORY_VALUE = "inventory_value";
export const KEY_METRIC_ORDER_VALUE = "order_value";
export const KEY_METRIC_REPEAT_CUSTOMERS = "repeat_customers";
export const KEYED = "Keyed";
export const KILL_SWITCH = "killswitch";
export const LABEL_COLUMN_WIDTH = 75;
export const LIST_DOWNLOAD_BLOCK_SIZE = 100;
export const LIST_DOWNLOAD_BLOCK_SIZE_LARGE = 1000;
export const LIST_DOWNLOAD_BLOCK_SIZE_XLARGE = 5000;
export const LOCAL_STORAGE_COLOR_MODE = "colormode";
export const LOCAL_STORAGE_INVENTORY = "inventory_item_";
export const LOCAL_STORAGE_PRODUCTS = "products";
export const LOCAL_STORAGE_REMEMBER_ME = "rememberme";
export const LOCAL_STORAGE_SESSION = "session";
export const LOCAL_STORAGE_STOCK_ORDER_CUSTOMER = "stockordercustomer";
export const LOCAL_STORAGE_UPC_LIST = "upclist";
export const LOCAL_STORAGE_UPC_CONFIG = "upcconfig";
export const LOCAL_STORAGE_VERSION = "version";
export const LOCAL_STORAGE_WALKIN_CUSTOMER = "walkincustomer";
export const LOGIN = "Login";
export const LONG_DECIMAL_VALUE = "0,0.000";
export const LOOKUP_STATES = "lookupstates";
export const MAAST = "Maast";
export const MAAST_INVOICE_STATUS_CANCELED = "CANCELED";
export const MAAST_INVOICE_STATUS_OUTSTANDING = "OUTSTANDING";
export const MAAST_INVOICE_STATUS_PAID = "PAID";
export const MAAST_INVOICE_STATUS_SAVED = "SAVED";
export const MAAST_RETRIES = 5;
export const MARKETING_PROSPECT = "MarketingProspect";
export const MAYBE = "maybe";
export const MERGED = "merged";
export const MESSAGE = "Message";
export const MESSAGE_ABUSE = "messagereportabuse";
export const MESSAGE_ABUSE_EXTERNAL = "messagereportabuseexternal";
export const MESSAGE_ATTEMPTING = "messageattempting";
export const MESSAGE_DO_NOT_EMAIL = "messagedonotemail";
export const MESSAGE_DO_NOT_EMAIL_EXTERNAL = "messagedonotemailexternal";
export const MESSAGE_NOT_SENT = "emailnotsent";
export const MESSAGE_READ = "messageread";
export const MESSAGE_SENT = "emailsent";
export const MESSAGE_UNREAD = "messageunread";
export const MESSAGES = "Messages";
export const METRIC_CREDIT_AUTH_APPROVALS = "creditauthapprovals";
export const METRIC_INVOICE_COUNT = "invoicecount";
export const METRIC_PAYMENTS = "payments";
export const METRIC_SALES_AMOUNT = "salesamount";
export const METRIC_USER_COUNT = "usercount";
export const MFA_EMAIL = "email";
export const MFA_MOBILE = "mobile";
export const MFA_OTHER = "other";
export const NAV = "Nav";
export const NEW_CUSTOMER = "NewCustomer";
export const NEW_USER_EMAIL = "newuseremail";
export const NEXT = "next";
export const NEW = "New";
export const NOTIFICATION_SALES_TAX_UPDATED = "notificationsalestaxupdated";
export const ONE_DAY = 24 * 60 * 60 * 1000;
export const ONLINE_SALES_TAX_COLLECTED_SKU = "ONLINE_SALES_TAX_COLLECTED";
export const ONLINE_SALES_TAX_REMITTED_SKU = "ONLINE_SALES_TAX_REMITTED";
export const ONLINE_SELLING_FEE_SKU = "ONLINE_SELLING_FEE";
export const ONLINE_SHIPPING_FEE_COLLECTED_SKU = "ONLINE_SHIPPING_FEE_COLLECTED";
export const ORDER = "Order";
export const ORDER_ITEM = "OrderItem";
export const ORDER_STATUS_ALL = "all";
export const ORDER_STATUS_BACKORDERED = "backordered";
export const ORDER_STATUS_CANCELLED = "cancelled";
export const ORDER_STATUS_CANCELLED_RETURN = "cancelledreturn";
export const ORDER_STATUS_CONTACTED = "contacted";
export const ORDER_STATUS_DIAGNOSED = "diagnosed";
export const ORDER_STATUS_FULLY_RECEIVED = "fullyreceived";
export const ORDER_STATUS_INVOICED = "invoiced";
export const ORDER_STATUS_ON_BENCH = "onbench";
export const ORDER_STATUS_ON_HOLD = "onhold";
export const ORDER_STATUS_ONLINE_PARTIALLY_SHIPPED = "onlinepartiallyshipped";
export const ORDER_STATUS_ONLINE_PICKED_UP = "onlinepickedup";
export const ORDER_STATUS_ONLINE_RECEIVED = "onlinereceived";
export const ORDER_STATUS_ONLINE_SHIPPED = "onlineshipped";
export const ORDER_STATUS_ONLINE_UNSHIPPED = "onlineunshipped";
export const ORDER_STATUS_OPEN = "open";
export const ORDER_STATUS_ORDERED = "ordered";
export const ORDER_STATUS_PAID_IN_FULL = "paidinfull";
export const ORDER_STATUS_PARTIAL_PAYMENT = "partialpayment";
export const ORDER_STATUS_PARTIALLY_INVOICED = "partiallyinvoiced";
export const ORDER_STATUS_PARTIALLY_ORDERED = "partiallyordered";
export const ORDER_STATUS_PARTIALLY_RECEIVED = "partiallyreceived";
export const ORDER_STATUS_PARTIALLY_REFUNDED = "partiallyrefunded";
export const ORDER_STATUS_PARTS_ORDERED = "partsordered";
export const ORDER_STATUS_PROCESSED = "processed";
export const ORDER_STATUS_PURCHASE_CREATED = "purchasecreated";
export const ORDER_STATUS_REFUNDED = "refunded";
export const ORDER_STATUS_SENT = "sent";
export const ORDER_STATUS_SPECIALS = "specials";
export const ORDER_STATUS_TODAY = "today";
export const ORDERS = "Orders";
export const ORPHAN = "Orphan";
export const OTHER = "other";
export const OVERLAY_AUTH_PROMPT = "authprompt";
export const OVERLAY_CHANGE_PASSWORD = "changepassword";
export const OVERLAY_DATE_FILTER = "datefilter";
export const OVERLAY_DATE_PICKER = "datepicker";
export const OVERLAY_GEAR = "gear";
export const OVERLAY_IMPORT = "import";
export const OVERLAY_INPUT_BOX = "inputbox";
export const OVERLAY_MESSAGE = "message";
export const OVERLAY_PICKER = "pick";
export const OVERLAY_PROGRESS = "progress";
export const OVERLAY_QR_CODE = "qrcode";
export const OVERLAY_QUESTION = "question";
export const OVERLAY_RESPONSE_APPLY = "apply";
export const OVERLAY_RESPONSE_CANCEL = "cancel";
export const OVERLAY_RESPONSE_CREATE = "create";
export const OVERLAY_RESPONSE_NO = "no";
export const OVERLAY_RESPONSE_OK = "ok";
export const OVERLAY_RESPONSE_REMOVE = "remove";
export const OVERLAY_RESPONSE_SELECT = "select";
export const OVERLAY_RESPONSE_YES = "yes";
export const OVERLAY_SEARCH_CONTACTS = "searchcontacts";
export const OVERLAY_SEARCH_PRODUCTS = "searchproducts";
export const OVERLAY_TOKENIZE = "tokenize";
export const OVERLAY_WAITING = "waiting";
export const OVERLAY_WHEEL = "wheel";
export const PAGE_CAMPAIGN = "campaign";
export const PAGE_CHECKOUT = "checkout";
export const PAGE_ERROR = "error";
export const PAGE_PRIVACY = "privacy";
export const PAGE_PROSPECT = "prospect";
export const PAGE_SIZE = 20;
export const PAGE_TERMS = "terms";
export const PAGE_VAULT = "vault";
export const PASSWORD_RECOVERY = "passwordrecovery";
export const PASSWORD_RESET = "passwordreset";
export const PASSWORD_TOKEN = "passwordtoken";
export const PAY = "Pay";
export const PAYMENT_BUTTONS = "paymentbuttons";
export const PAYPAL = "paypal";
export const PGDN = 34;
export const PGUP = 33;
export const PING_DEVICE = "pingdevice";
export const PLAN_CODE_DEMO = "CHDEMO";
export const PLAN_TYPE_BH = "BH";
export const PLAN_TYPE_CH = "CH";
export const PLAN_TYPE_GROUP = "G";
export const PLAN_TYPE_INDIVIDUAL = "I";
export const POST = "POST";
export const POST_PAYMENT = "post-payment";
export const PREEMPT_GET_LIST_ITEMS = "getListItems";
export const PREEMPT_GET_REPORT = "getReport";
export const PRINT_VIEW = "printview";
export const PROCESS_CARTLOOM_ORDER = "processcartloomorder";
export const PROCESS_REVERB_ORDER = "processreverborder";
export const PROD = "prod";
export const PRODUCT = "Product";
export const PRODUCT_SEARCH = "ProductSearch";
export const PRODUCT_SEARCH_PAGES = 5;
export const PRODUCTS = "Products";
export const PROSPECT = "prospect";
export const PROSPECT_STATUS_CANCELLED = "Cancelled";
export const PROSPECT_STATUS_NEW = "New";
export const PROSPECT_STATUS_PENDING = "Pending";
export const PROSPECT_STATUS_PREMATCHED = "PreMatched";
export const PROSPECT_STATUS_PROCESSED_CREATED = "ProcessedCreated";
export const PROSPECT_STATUS_PROCESSED_MATCHED = "ProcessedMatched";
export const PURCHASE = "Purchase";
export const PURCHASES = "Purchases";
export const PUT = "PUT";
export const QA = "qa";
export const QUOTE = "Quote";
export const QUOTES = "Quotes";
export const READER = "Reader";
export const RECAPTCHA_FAILED = "recaptchafailed";
export const RECAPTCHA_THRESHOLD = 0.5;
export const RECURRING_PLAN_FREQUENCY_ANNUALLY = 6;
export const RECURRING_PLAN_FREQUENCY_DAILY = 7;
export const RECURRING_PLAN_FREQUENCY_MONTHLY = 3;
export const RECURRING_PLAN_FREQUENCY_WEEKLY = 0;
export const REG_BUS_INFO_PROVIDED = "regbusinfoprovided";
export const REG_CHARGE_FIRST_MONTH = "regchargefirstmonth";
export const REG_CREATE_CLIENT = "regcreateclient";
export const REG_CREATE_CUSTOMER = "regcreatecustomer";
export const REG_CREATE_GIFT_CARD = "regcreategiftcard";
export const REG_CREATE_INVOICE = "regcreateinvoice";
export const REG_CREATE_ONLINE_PRODUCTS = "regcreateonlineproducts";
export const REG_CREATE_SETTINGS = "regcreatesettings";
export const REG_CREATE_STOCK_ORDER = "regcreatestockorder";
export const REG_CREATE_SUBSCRIPTION = "regcreatesubscription";
export const REG_CREATE_USER = "regcreateuser";
export const REG_CREATE_WALKIN = "regcreatewalkin";
export const REG_EMAIL_MERCHANT_APP = "regemailmerchantapp";
export const REG_EMAIL_PROVIDED = "regemailprovided";
export const REG_EMAIL_RECIEPT = "regemailreciept";
export const REG_MERCH_APPROVED = "regmerchapproved";
export const REG_NEW_CUSTOMER = "regnewcustomer";
export const REG_PASSWORD_SET = "regpasswordset";
export const REG_PAYMENT_FAILED = "regpaymentfailed";
export const REG_PAYMENT_PROVIDED = "regpaymentprovided";
export const REG_PLAN_SELECTED = "regplanselected";
export const REG_PROCESSED = "regprocessed";
export const REG_SUBSCRIBED = "regsubscribed";
export const REG_UPDATE_MERCHANT_APP = "regupdatemerchantapp";
export const REPAIR = "Repair";
export const REPAIR_ITEM = "RepairItem";
export const REPAIRS = "Repairs";
export const SERVICE = "Service";
export const REPORT = "Report";
export const REPORT_BILLING_INVOICES = "billinginvoicesreport";
export const REPORT_BILLING_TRANSACTIONS = "billingtransactionsreport";
export const REPORT_CUSTOMER_ACQUISITION = "reportcustomeracquisition";
export const REPORT_DEPOSIT_DETAIL = "reportdepositdetail";
export const REPORT_DOWNLOAD_BLOCK_SIZE_MAX = 500;
export const REPORT_DOWNLOAD_BLOCK_SIZE_MIN = 100;
export const REPORT_DOWNLOAD_IDEAL_BLOCK_COUNT = 4;
export const REPORT_GROSS_MARGIN = "reportgrossmargin";
export const REPORT_INVENTORY_VALUE = "reportinventoryvalue";
export const REPORT_KEY_METRICS = "keymetricsreport";
export const REPORT_NET_PROFIT = "reportnetprofit";
export const REPORT_NET_SALES = "reportnetsales";
export const REPORT_ORDER_ITEMS = "reportorderitems";
export const REPORT_ORDER_VALUE = "reportordervalue";
export const REPORT_PAYMENT_DETAIL = "reportpaymentdetail";
export const REPORT_PAYMENT_ORDER_DETAIL = "reportpaymentorderdetail";
export const REPORT_REPEAT_CUSTOMERS = "reportrepeatcustomers";
export const REPORT_SALES = "salesreport";
export const REPORT_SALES_DATA = "reportsalesdata";
export const REPORT_TAX_DETAIL = "reporttaxdetail";
export const REPORT_TAX_SUMMARY = "reporttaxsummary";
export const REPORT_TIMESHEET_EXCEPTIONS = "reporttimesheetexceptions";
export const REPORT_TIMESHEET_TOTALS = "reporttimesheettotals";
export const REPORT_TIMESHEETS = "timesheetsreport";
export const REPORTS = "Reports";
export const REQUEST_GET_BALANCE = "get-balance";
export const REQUEST_TRANSIENT_KEY = "request-transient-key";
export const RESEND = "resend";
export const RETRIEVE_RESULT = "retrieve-result";
export const RETURN = "Return";
export const REVERB_CHANGE_CATEGORY = "changecategory";
export const REVERB_CHANGE_CONDITION = "changecondition";
export const REVERB_CHANGE_SHIPPING = "changeshipping";
export const REVERB_CHANGE_SUBCAT1 = "changesubcat1";
export const REVERB_CHANGE_SUBCAT2 = "changesubcat2";
export const REVERB_ORDER_STATUS_BLOCKED = "blocked";
export const REVERB_ORDER_STATUS_CANCELLED = "cancelled";
export const REVERB_ORDER_STATUS_PAID = "paid";
export const REVERB_ORDER_STATUS_PAYMENT_PENDING = "payment_pending";
export const REVERB_ORDER_STATUS_PENDING_REVIEW = "pending_review";
export const REVERB_ORDER_STATUS_PICKED_UP = "picked_up";
export const REVERB_ORDER_STATUS_RECEIVED = "received";
export const REVERB_ORDER_STATUS_REFUNDED = "refunded";
export const REVERB_ORDER_STATUS_SHIPPED = "shipped";
export const REVERB_ORDER_STATUS_UNPAID = "unpaid";
export const ROSTERHOUND = "Rosterhound";
export const SALESPERSON_CARTLOOM = "Cartloom";
export const SALESPERSON_REVERB = "Reverb";
export const SEARCH_DELAY = 500;
export const SEND_EMAIL = "sendemail";
export const SEND_ORDER = "sendorder";
export const SESSION_STORAGE_STATE = "state";
export const SET_ALL_FALSE = "{set all false}";
export const SET_ALL_TRUE = "{set all true}";
export const SETTING = "Setting";
export const SETTINGS = "Settings";
export const SETTINGS_API_KEY = "API_KEY";
export const SETTINGS_API_KEY_CLOUD = "API_KEY_CLOUD";
export const SETTINGS_API_KEY_WEBHOOK = "API_KEY_WEBHOOK";
export const SETTINGS_API_TRANSACTION_URL = "API_TRANSACTION_URL";
export const SETTINGS_API_URL = "API_URL";
export const SETTINGS_API_URL_PROD = "API_URL_PROD";
export const SETTINGS_API_URL_QA = "API_URL_QA";
export const SETTINGS_API_URL_TEST = "API_URL_TEST";
export const SETTINGS_AUTH_TOKEN = "AUTH_TOKEN";
export const SETTINGS_AUTH_TOKEN_QA = "AUTH_TOKEN_QA";
export const SETTINGS_AUTH_TOKEN_TEST = "AUTH_TOKEN_TEST";
export const SETTINGS_BCC_ADDRESS = "BCC_ADDRESS";
export const SETTINGS_CATEGORY_APP = "APP";
export const SETTINGS_CATEGORY_CARTLOOM = "CARTLOOM";
export const SETTINGS_CATEGORY_CLERKHOUND = "clerkhound";
export const SETTINGS_CATEGORY_CLIENT = "CLIENT";
export const SETTINGS_CATEGORY_CUSTOM_FIELDS = "CUSTOM_FIELDS";
export const SETTINGS_CATEGORY_DISPLAY = "DISPLAY";
export const SETTINGS_CATEGORY_GIFT_CARD = "GIFT_CARD";
export const SETTINGS_CATEGORY_GLOBAL = "GLOBAL";
export const SETTINGS_CATEGORY_HANDPOINT = "HANDPOINT";
export const SETTINGS_CATEGORY_INVENTORY = "INVENTORY";
export const SETTINGS_CATEGORY_MAAST = "MAAST";
export const SETTINGS_CATEGORY_MAIL = "MAIL";
export const SETTINGS_CATEGORY_REPAIR = "REPAIR";
export const SETTINGS_CATEGORY_REVERB = "REVERB";
export const SETTINGS_CATEGORY_SALES_TAXES = "SALES_TAXES";
export const SETTINGS_CATEGORY_SUBSCRIPTIONS = "SUBSCRIPTIONS";
export const SETTINGS_CATEGORY_SYSTEM = "SYSTEM";
export const SETTINGS_CATEGORY_TWILIO = "TWILIO";
export const SETTINGS_CATEGORY_UPLOADS = "UPLOADS";
export const SETTINGS_CATEGORY_VALOR = "VALOR";
export const SETTINGS_CATEGORY_VALOR_SECURITY = "VALOR_SECURITY";
export const SETTINGS_CITY_ST_ZIP = "CITY_STATE_ZIP";
export const SETTINGS_CLERKHOUND_CLIENT_ID = "clientid";
export const SETTINGS_CLERKHOUND_MERCHANT_ID = "merchantid";
export const SETTINGS_CLERKHOUND_TEMPLATE_CLIENT_ID = "templateclientid";
export const SETTINGS_CLERKHOUND_USER_ID = "userid";
export const SETTINGS_DEFAULT_PARTIAL_MANUAL = "DEFAULT_PARTIAL_MANUAL";
export const SETTINGS_DEFAULT_PARTIAL_VAULT = "DEFAULT_PARTIAL_VAULT";
export const SETTINGS_EMAIL = "EMAIL";
export const SETTINGS_ENABLED = "ENABLED";
export const SETTINGS_FAILURE_MESSAGE = "FAILURE_MESSAGE";
export const SETTINGS_FAMILIES = "FAMILIES";
export const SETTINGS_FOLDER_ROWS = "FOLDER_ROWS";
export const SETTINGS_FROM_ADDRESS = "FROM_ADDRESS";
export const SETTINGS_FROM_NAME = "FROM_NAME";
export const SETTINGS_FROM_VERIFIED = "FROM_VERIFIED";
export const SETTINGS_IN_PROGRESS = "IN_PROGRESS";
export const SETTINGS_LAST_RETRIEVED = "LAST_RETRIEVED";
export const SETTINGS_LIST_ROWS = "LIST_ROWS";
export const SETTINGS_LOGO = "LOGO";
export const SETTINGS_MAAST_AUTH_TOKEN = "AUTH_TOKEN";
export const SETTINGS_MAAST_AUTH_TOKEN_QA = "AUTH_TOKEN_QA";
export const SETTINGS_MAAST_AUTH_TOKEN_TEST = "AUTH_TOKEN_TEST";
export const SETTINGS_MAAST_CHANNEL_ID = "CHANNEL_ID";
export const SETTINGS_MAAST_CLERKHOUND_SUPPORT_REP = "CH_SUPPORT_REP";
export const SETTINGS_MAAST_MERCHANT_ID = "MERCHANT_ID";
export const SETTINGS_MAAST_MERCHANT_ID_QA = "MERCHANT_ID_QA";
export const SETTINGS_MAAST_MERCHANT_ID_TEST = "MERCHANT_ID_TEST";
export const SETTINGS_MAAST_SALES_REP = "SALES_REP";
export const SETTINGS_MAAST_SUPPORT_REP = "SUPPORT_REP";
export const SETTINGS_MAAST_VENDOR_ID = "VENDOR_ID";
export const SETTINGS_MAAST_VENDOR_ID_QA = "VENDOR_ID_QA";
export const SETTINGS_MAAST_VENDOR_ID_TEST = "VENDOR_ID_TEST";
export const SETTINGS_MODE = "MODE";
export const SETTINGS_NAME = "NAME";
export const SETTINGS_PLAN_CODE = "PLAN_CODE";
export const SETTINGS_PHONE = "PHONE";
export const SETTINGS_REFERRAL_LINK = "REFERRAL_LINK";
export const SETTINGS_REGION = "REGION";
export const SETTINGS_REPLY_TO_ALLOWED = "REPLY_TO_ALLOWED";
export const SETTINGS_SELLERID = "SELLERID";
export const SETTINGS_SKU = "SKU";
export const SETTINGS_STOCK_ORDER_CUSTOMER = "STOCK_ORDER_CUSTOMER";
export const SETTINGS_STREET_ADDRESS = "STREET_ADDRESS";
export const SETTINGS_SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const SETTINGS_SYSTEM_BANNER = "BANNER";
export const SETTINGS_SYSTEM_BANNER_TYPE = "BANNER_TYPE";
export const SETTINGS_SYSTEM_MAINTENANCE = "MAINTENANCE";
export const SETTINGS_TAX_ID = "TAX_ID";
export const SETTINGS_TECHNICIAN = "TECHNICIAN";
export const SETTINGS_TIMEZONE = "TIMEZONE";
export const SETTINGS_TWILIO_ACCOUNT_SID = "AccountSid";
export const SETTINGS_TWILIO_AUTH_TOKEN = "AuthToken";
export const SETTINGS_TWILIO_LEGAL_NAME = "LegalName";
export const SETTINGS_TWILIO_MESG_SERV_SID = "MessagingServiceSid";
export const SETTINGS_TWILIO_MESSAGING_SERVICE_SID = "MessagingServiceSid";
export const SETTINGS_TWILIO_PHONE_NUMBER = "PhoneNumber";
export const SETTINGS_TWILIO_UNREAD_COUNT = "unread_count";
export const SETTINGS_URL = "URL";
export const SETTINGS_URL_PROD = "URL_PROD";
export const SETTINGS_URL_QA = "URL_QA";
export const SETTINGS_URL_TEST = "URL_TEST";
export const SETTINGS_VALOR_APP_ID = "APP_ID";
export const SETTINGS_VALOR_CHANNEL_ID = "CHANNEL_ID";
export const SETTINGS_VALOR_EPI = "EPI";
export const SETTINGS_VALOR_MAX_SUB_TIMEOUT = "MAX_SUB_TIMEOUT";
export const SETTINGS_VALOR_MQTT_BROKER = "MQTT_BROKER";
export const SETTINGS_VALOR_MQTT_PORT = "MQTT_PORT";
export const SETTINGS_VENDOR_ID = "VENDOR_ID";
export const SETTINGS_VENDOR_ID_QA = "VENDOR_ID_QA";
export const SETTINGS_VENDOR_ID_TEST = "VENDOR_ID_TEST";
export const SETTINGS_WALKIN_CUSTOMER = "WALKIN_CUSTOMER";
export const SETTINGS_WEBSITE = "WEBSITE";
export const SHIPPING = "Shipping";
export const SHIPPING_SWITCH = "ShippingSwitch";
export const SMS = "sms";
export const SMS_ACTION_GET_CONTACTS = "smsgetcontacts";
export const SMS_ACTION_GET_MESSAGES = "smsgetmessages";
export const SMS_ACTION_GET_UNREAD_COUNT = "smsgetunreadcount";
export const SMS_MESSAGE_RECEIVED = "smsmessagereceived";
export const SMS_OPT_IN_KEYWORD = "start";
export const SMS_OPT_OUT_KEYWORDS = ["cancel", "end", "quit", "unsubscribe", "stop", "stopall"];
export const SMS_UNREAD_CLEAR_DELAY = 3000;
export const SORT_NEWEST_FIRST = "newestfirst";
export const SORT_OLDEST_FIRST = "oldestfirst";
export const SQL_TZ = "(SELECT value FROM settings WHERE clientid = %s AND category = 'CLIENT' AND description = 'TIMEZONE')";
export const STOP_CURRENT_TRANSACTION = "stop-current-transaction";
export const STORECREDIT = "storecredit";
export const STORED_PAYMENT = "storedpayment";
export const SUBSCRIPTION = "Subscription";
export const SUBSCRIPTION_ACTIVE = "A";
export const SUBSCRIPTION_CANCELLED = "C";
export const SUBSCRIPTION_COMPLETE = "D";
export const SUBSCRIPTION_LOADING = "L";
export const SUBSCRIPTION_NEW = "N";
export const SUBSCRIPTION_PAUSED = "P";
export const SUBSCRIPTION_PAYMENT = "subscriptionpayment";
export const SUBSCRIPTION_SUSPENDED = "S";
export const SUBSCRIPTION_VAULTED = "V";
export const SUPPLIER = "Supplier";
export const SUPPLIER_COMPANY = 1;
export const SUPPLIER_SEARCH = "SupplierSearch";
export const SUPPLIERS = "Suppliers";
export const TAB_ACTIVE = "tabactive";
export const TAB_CUSTOMER = "tabcustomer";
export const TAB_INACTIVE = "tabinactive";
export const TAB_INVENTORY = "tabinventory";
export const TAB_NONE = "tabnone";
export const TAB_OWING = "tabowing";
export const TAB_PLANS = "tabplans";
export const TAB_PROSPECTS = "tabprospects";
export const TAB_PRODUCTS = "tabproducts";
export const TAB_SUMMARY = "tabsummary";
export const TAG_COLOR_DEFAULT = "#467bb0";
export const TAG_COLOR_SUBSCRIPTION_PAYMENT_FAILURE = "#a21924";
export const TAG_COLOR_SUBSCRIPTION_SUSPENDED = "#a21924";
export const TAG_NAME_SUBSCRIPTION_PAYMENT_FAILURE = "Subscription Payment Failure";
export const TAG_NAME_SUBSCRIPTION_SUSPENDED = "Subscription Suspended";
export const TEST = "test";
export const TEXTS = "Texts";
export const TIMEFRAME_ALL_TIME = "alltime";
export const TIMEFRAME_MONTH = "month";
export const TIMEFRAME_QUARTER = "quarter";
export const TIMEFRAME_DAY = "day";
export const TIMEFRAME_TODAY = "today";
export const TIMEFRAME_WEEK = "week";
export const TIMEFRAME_YEAR = "year";
export const TIMESHEET = "Timesheet";
export const TIMESHEETS = "Timesheets";
export const TIMESHEETS_COMPLETED = "timesheetscompleted";
export const TIMESHEETS_CURRENT = "timesheetscurrent";
export const TRANSACTION_APPROVED = "A";
export const TRANSACTION_CANCELLED = "K";
export const TRANSACTION_CAPTURED = "C";
export const TRANSACTION_DECLINED_BY_ISSUER = "D";
export const TRANSACTION_DEPOSIT_SENT = "P";
export const TRANSACTION_FAILURE_OTHER = "F";
export const TRANSACTION_FUNDED_BY_ISSUER = "N";
export const TRANSACTION_HELD = "H";
export const TRANSACTION_REJECTED = "R";
export const TRANSACTION_SETTLED = "S";
export const TRANSACTION_VOIDED_BY_MERCHANT = "V";
export const TRANSACTION_VOIDED_BY_SYSTEM = "v";
export const TRUE = "true";
export const UNCHAIN_CONTACT = "UnchainContact";
export const UP_ARROW = 38;
export const UPC_CONFIG_STATUS_COMPLETE = "complete";
export const UPC_CONFIG_STATUS_ERROR = "upcerror";
export const UPC_CONFIG_STATUS_GETTING_UPCS = "gettingupcs";
export const UPC_CONFIG_STATUS_NOT_STARTED = "notstarted";
export const UPDATE = "Update";
export const UPDATE_CUSTOMER_IN_VAULT = "updatecustomerinvault";
export const URL_ADDRESSES = "/addresses";
export const URL_BILLING = "/billing";
export const URL_CAMPAIGNS = "/campaigns";
export const URL_CARTLOOM = "/cartloom";
export const URL_CLIENTS = "/clients";
export const URL_COMMAND = "/command";
export const URL_COMPANIES = "/companies";
export const URL_CONTACTS = "/contacts";
export const URL_ECOMMERCE = "/ecommerce";
export const URL_MAIL = "/mail";
export const URL_NOTES = "/notes";
export const URL_ORDER_ITEMS = "/orderitems";
export const URL_ORDERS = "/orders";
export const URL_PAYMENTS = "/payments";
export const URL_PRODUCTS = "/products";
export const URL_PUBLIC = "/public";
export const URL_RECURRINGS = "/recurrings";
export const URL_REPAIR_ITEMS = "/repairitems";
export const URL_REPORTS = "/reports";
export const URL_REVERB = "/reverb";
export const URL_SESSIONS = "/sessions";
export const URL_SESSIONS_RO = "/sessions_ro";
export const URL_SETTINGS = "/settings";
export const URL_SMS = "/sms";
export const URL_SUPPLIERS = "/suppliers";
export const URL_TAGS = "/tags";
export const URL_TIMESHEETS = "/timesheets";
export const URL_UPLOADS = "/uploads";
export const URL_USERS = "/users";
export const URL_VERSION_TXT = "/version.txt";
export const URL_WIDGETS = "/widgets";
export const USER_TYPE_ADMIN = 9;
export const USER_TYPE_CLERK = 1;
export const USER_TYPE_MANAGER = 2;
export const USER_TYPE_NONE = 0;
export const USER_TYPE_OWNER = 3;
export const UTF8 = "UTF-8";
export const VALOR = "Valor";
export const VALOR_PARTIAL_APPROVAL_NORMAL = "0";
export const VALOR_PARTIAL_APPROVAL_PARTIAL = "1";
export const VALOR_STATE_FAILED = "-1";
export const VALOR_STATE_SUCCESS = "0";
export const VALOR_TRAN_CODE_REFUND = "5";
export const VALOR_TRAN_CODE_SALE = "1";
export const VALOR_TRAN_CODE_VOID = "2";
export const VALOR_TRAN_MODE_CREDIT = "1";
export const VAULT = "Vault";
export const WORKFLOW_STATUS_FAILED = "workflowstatusfailed";
export const WORKFLOW_STATUS_NEW = "workflowstatusnew";
export const WORKFLOW_STATUS_PROCESSED = "workflowstatusprocessed";
export const WORKFLOW_STATUS_PROCESSING = "workflowstatusprocessing";
export const WORKFLOW_STATUS_READY = "workflowstatusready";
export const VERIFY_AUTH_TOKEN = "verifyauthtoken";
export const VERIFY_EMAIL = "verifyemail";
export const WH_APPLICATION_APPROVED = "application_approved";
export const WH_SENDER_CARTLOOM = "cartloom";
export const WH_SENDER_MAAST = "maast";
export const WH_SENDER_TWILIO = "twilio";
export const WH_SENDER_UNKNOWN = "unknown";
export const WH_SUBSCRIPTION_PAYMENT_FAILURE = "subscription_payment_failure";
export const WH_SUBSCRIPTION_PAYMENT_SUCCESS = "subscription_payment_success";
export const WH_SUBSCRIPTION_SUSPENDED = "subscription_suspended";
export const WH_VALIDATE_URL = "validate_url";
export const WHEEL_MENU_ITEMS_MINIMUM = 12;
export const WHEEL_ROTATION_OFFSET = 8;
export const WHEEL_SPINNER_THRESHOLD = 50;
export const WIDGET_TYPE_ADD = "widgetadd";
export const WIDGET_TYPE_CLOCK = "widgetclock";
export const WIDGET_TYPE_DEMO = "widgetdemo";
export const WIDGET_TYPE_MESSAGE = "widgetmessage";
export const WIDGET_TYPE_NEWS = "widgetnews";
export const WIDGET_TYPE_TAG_LIST = "widgettaglist";
export const WIDGET_TYPE_WELCOME = "widgetwelcome";

export const ACTIVE_CAMPAIGN_STATUSES = [CAMPAIGN_STATUS_DRAFT, CAMPAIGN_STATUS_PUBLISHED];
export const ADMIN_ONLY_SETTINGS = [SETTINGS_CATEGORY_VALOR_SECURITY, SETTINGS_CATEGORY_TWILIO];
export const AUTHORIZATION_VIEWS = [INVOICE, ORDER, QUOTE, REPAIR, PAY];
export const BILLING_IMPACTS_VAULTED_FLAG = [BILLING_POST_CUSTOMER, BILLING_POST_STORED_PAYMENT, BILLING_POST_SUBSCRIPTION];
export const CAMPAIGN_STATUSES = [CAMPAIGN_STATUS_ARCHIVED, CAMPAIGN_STATUS_DRAFT, CAMPAIGN_STATUS_PUBLISHED];
export const CARTLOOM_FIELDS_COMMENT =
  "If you update the CARTLOOM_FIELDS field list below, you must also update the does_put_impact_cartloom() function in products.py";
export const CARTLOOM_FIELDS = ["longdescription", "affectinventory", "inventory", "make", "model", "sellprice", "sku", "productname", "upc"];
export const CLOSED_INVOICE_ORDER_STATUSES = [ORDER_STATUS_PAID_IN_FULL, ORDER_STATUS_REFUNDED, ORDER_STATUS_PARTIALLY_REFUNDED];
export const CLOSED_REPAIR_ORDER_STATUSES = [ORDER_STATUS_CANCELLED, ORDER_STATUS_CANCELLED_RETURN, ORDER_STATUS_INVOICED, ORDER_STATUS_PAID_IN_FULL];
export const COLOR_MODES = [COLOR_MODE_DARK, COLOR_MODE_LIGHT];
export const COMPANY_TYPES = [SUPPLIER_COMPANY, CUSTOMER_COMPANY, CUSTOMER_FAMILY, CUSTOMER_EXTERNAL, CUSTOMER_PROSPECT];
export const COMPLETE_REGISTRATION_STATUSES = [
  REG_CREATE_CLIENT,
  REG_CREATE_USER,
  REG_CREATE_WALKIN,
  REG_CREATE_STOCK_ORDER,
  REG_CREATE_SETTINGS,
  REG_CREATE_GIFT_CARD,
  REG_CREATE_ONLINE_PRODUCTS,
  REG_CREATE_CUSTOMER,
  REG_CREATE_INVOICE,
  REG_CHARGE_FIRST_MONTH,
  REG_CREATE_SUBSCRIPTION,
  REG_EMAIL_RECIEPT,
  REG_UPDATE_MERCHANT_APP,
  REG_EMAIL_MERCHANT_APP,
  REG_PROCESSED,
];
export const CUSTOMER_COMPANY_TYPES = [CUSTOMER_COMPANY, CUSTOMER_FAMILY];
export const CUSTOMER_COMPANY_TYPES_EXTERNAL = [CUSTOMER_EXTERNAL, CUSTOMER_PROSPECT];
export const DELETE_ENABLED_VIEWS = [CAMPAIGN, CUSTOMER, INVOICE, ORDER, PRODUCT, PURCHASE, QUOTE, REPAIR, SUPPLIER, BILLING, CAMPAIGN];
export const DEPOSIT_ENABLED_ORDER_TYPES = [ORDER, REPAIR, QUOTE];
export const EMAIL_VIEWS = [CUSTOMER, INVOICE, ORDER, REPAIR, QUOTE, SUPPLIER, PURCHASE, BILLING];
export const EXPORT_VIEWS = [PRODUCTS, CUSTOMERS, SUPPLIERS, ORDERS, INVOICES, QUOTES, REPAIRS, PURCHASES, REPORT];
export const HANDPOINT_REFUND_TYPES = [HANDPOINT_TYPE_MOTO_REFUND, HANDPOINT_TYPE_MOTO_REVERSAL, HANDPOINT_TYPE_REFUND, HANDPOINT_TYPE_VOID_SALE];
export const INACTIVE_CAMPAIGN_STATUSES = [CAMPAIGN_STATUS_ARCHIVED];
export const INVENTORY_DECREASE_STATUSES = [ORDER_STATUS_PAID_IN_FULL, ORDER_STATUS_ONLINE_SHIPPED, ORDER_STATUS_ONLINE_PICKED_UP];
export const INVENTORY_INCREASE_STATUSES = [ORDER_STATUS_OPEN, ORDER_STATUS_ONLINE_UNSHIPPED];
export const INVOICE_ORDER_STATUSES = [
  ORDER_STATUS_INVOICED,
  ORDER_STATUS_PARTIAL_PAYMENT,
  ORDER_STATUS_PARTIALLY_INVOICED,
  ORDER_STATUS_PAID_IN_FULL,
  ORDER_STATUS_REFUNDED,
  ORDER_STATUS_PARTIALLY_REFUNDED,
];
export const MAAST_INVOICE_STATUSES = [
  MAAST_INVOICE_STATUS_SAVED,
  MAAST_INVOICE_STATUS_OUTSTANDING,
  MAAST_INVOICE_STATUS_PAID,
  MAAST_INVOICE_STATUS_CANCELED,
];
export const MAAST_INVOICE_STATUSES_CLOSED = [MAAST_INVOICE_STATUS_PAID, MAAST_INVOICE_STATUS_CANCELED];
export const MAAST_INVOICE_STATUSES_OPEN = [MAAST_INVOICE_STATUS_SAVED, MAAST_INVOICE_STATUS_OUTSTANDING];
export const MANUAL_PROSPECT_STATUSES = [PROSPECT_STATUS_CANCELLED, PROSPECT_STATUS_NEW, PROSPECT_STATUS_PENDING];
export const MASKED_SETTINGS = [SETTINGS_CATEGORY_VALOR_SECURITY];
export const MASKED_SETTINGS_DESCRIPTIONS = [
  SETTINGS_API_KEY_CLOUD,
  SETTINGS_TWILIO_AUTH_TOKEN,
  SETTINGS_TWILIO_ACCOUNT_SID,
  SETTINGS_TWILIO_MESSAGING_SERVICE_SID,
];
export const METRICS = [METRIC_CREDIT_AUTH_APPROVALS, METRIC_INVOICE_COUNT, METRIC_PAYMENTS, METRIC_SALES_AMOUNT, METRIC_USER_COUNT];
export const MFA_CHANNELS = [MFA_EMAIL, MFA_MOBILE, MFA_OTHER];
export const ONLINE_ORDER_STATUSES = [
  ORDER_STATUS_ONLINE_SHIPPED,
  ORDER_STATUS_ONLINE_PARTIALLY_SHIPPED,
  ORDER_STATUS_ONLINE_UNSHIPPED,
  ORDER_STATUS_ONLINE_PICKED_UP,
];
export const ONLINE_ORDER_STATUSES_OPEN = [ORDER_STATUS_ONLINE_PARTIALLY_SHIPPED, ORDER_STATUS_ONLINE_UNSHIPPED];
export const ORDER_STATUSES_OPEN = [
  ORDER_STATUS_OPEN,
  ORDER_STATUS_PURCHASE_CREATED,
  ORDER_STATUS_CANCELLED_RETURN,
  ORDER_STATUS_CONTACTED,
  ORDER_STATUS_DIAGNOSED,
  ORDER_STATUS_ON_BENCH,
  ORDER_STATUS_ON_HOLD,
  ORDER_STATUS_PARTS_ORDERED,
  ORDER_STATUS_PARTIAL_PAYMENT,
];
export const ORDER_TYPES = [INVOICE, ORDER, PURCHASE, QUOTE, REPAIR];
export const OVERLAY_KEY_INPUTS = [
  OVERLAY_AUTH_PROMPT,
  OVERLAY_CHANGE_PASSWORD,
  OVERLAY_IMPORT,
  OVERLAY_INPUT_BOX,
  OVERLAY_PICKER,
  OVERLAY_QUESTION,
  OVERLAY_SEARCH_CONTACTS,
  OVERLAY_SEARCH_PRODUCTS,
  OVERLAY_TOKENIZE,
];
export const PROSPECT_REQUIRED_FIELDS = [
  "additionaldatavalues",
  "agreedatetime",
  "agreement",
  "billingfirst",
  "billinglast",
  "billingzip",
  "cardid",
  "email",
  "prospectstatus",
  "tokenizationresponse",
];
export const PURCHASE_ORDER_STATUSES = [
  ORDER_STATUS_BACKORDERED,
  ORDER_STATUS_FULLY_RECEIVED,
  ORDER_STATUS_ORDERED,
  ORDER_STATUS_PARTIALLY_RECEIVED,
  ORDER_STATUS_PURCHASE_CREATED,
  ORDER_STATUS_PARTIALLY_ORDERED,
];
export const QA_COMPANIES = ["Playwright "];
export const REFUND_ENABLED_TRANSACTION_STATUSES = [
  TRANSACTION_APPROVED,
  TRANSACTION_CAPTURED,
  TRANSACTION_DEPOSIT_SENT,
  TRANSACTION_FUNDED_BY_ISSUER,
  TRANSACTION_SETTLED,
];
export const REPAIR_ORDER_STATUSES = [
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_CANCELLED_RETURN,
  ORDER_STATUS_CONTACTED,
  ORDER_STATUS_DIAGNOSED,
  ORDER_STATUS_ON_BENCH,
  ORDER_STATUS_ON_HOLD,
  ORDER_STATUS_PARTS_ORDERED,
];
export const REVERB_ORDER_STATUSES = [
  REVERB_ORDER_STATUS_BLOCKED,
  REVERB_ORDER_STATUS_CANCELLED,
  REVERB_ORDER_STATUS_PAID,
  REVERB_ORDER_STATUS_PAYMENT_PENDING,
  REVERB_ORDER_STATUS_PENDING_REVIEW,
  REVERB_ORDER_STATUS_PICKED_UP,
  REVERB_ORDER_STATUS_RECEIVED,
  REVERB_ORDER_STATUS_REFUNDED,
  REVERB_ORDER_STATUS_SHIPPED,
  REVERB_ORDER_STATUS_UNPAID,
];
export const REVERB_ORDER_STATUSES_FOR_CREATE = [
  REVERB_ORDER_STATUS_PAID,
  REVERB_ORDER_STATUS_PICKED_UP,
  REVERB_ORDER_STATUS_RECEIVED,
  REVERB_ORDER_STATUS_SHIPPED,
];
export const REVERB_ORDER_STATUSES_FOR_REVERSAL = [REVERB_ORDER_STATUS_CANCELLED, REVERB_ORDER_STATUS_REFUNDED];
export const REVERB_ORDER_STATUSES_FOR_UPDATE = [REVERB_ORDER_STATUS_PICKED_UP, REVERB_ORDER_STATUS_RECEIVED, REVERB_ORDER_STATUS_SHIPPED];
export const SAVABLE_INVENTORY_STATUSES = [INVENTORY_STATUS_MATCHED, INVENTORY_STATUS_PENDING];
export const SENSITIVE_CLIENT = ["password", "passwordconfirm", "pass", "user", "username", "Authorization", "session"];
export const SENSITIVE = [
  "addressid",
  "authid",
  "authuserid",
  "campaignid",
  "clientid",
  "campaignid",
  "companyid",
  "contactid",
  "createdbyuserid",
  "dashboardwidgetid",
  "dashboardwidgetid",
  "enterpriseid",
  "giftcardid",
  "giftcardledgerid",
  "marketingprospectid",
  "messageid",
  "mfacode",
  "mfarequestid",
  "noteid",
  "orderid",
  "orderitemid",
  "parentorderid",
  "parentorderitemid",
  "passwordhash",
  "paymentid",
  "photoid",
  "productid",
  "prospectid",
  "refid",
  "repairitemid",
  "reportid",
  "rootorderid",
  "rootorderitemid",
  "sessionid",
  "settingid",
  "smscontactid",
  "smsmessageid",
  "spawnorderitemid",
  "supplierid",
  "tagid",
  "taxid",
  "timesheetid",
  "userid",
  "vaultrequestid",
  SETTINGS_MAAST_AUTH_TOKEN,
  SETTINGS_MAAST_VENDOR_ID,
];
export const SENSITIVE_SETTINGS = [
  (SETTINGS_CATEGORY_TWILIO, SETTINGS_TWILIO_AUTH_TOKEN),
  (SETTINGS_CATEGORY_TWILIO, SETTINGS_TWILIO_ACCOUNT_SID),
  (SETTINGS_CATEGORY_TWILIO, SETTINGS_TWILIO_MESSAGING_SERVICE_SID),
  (SETTINGS_CATEGORY_CARTLOOM, SETTINGS_API_KEY),
  (SETTINGS_CATEGORY_CARTLOOM, SETTINGS_API_URL),
];
export const SETTINGS_DISALLOW_BLANK = [];
export const SETTINGS_YOU_CAN_DELETE = [
  SETTINGS_CATEGORY_SALES_TAXES,
  SETTINGS_CATEGORY_REPAIR,
  SETTINGS_CATEGORY_GIFT_CARD,
  SETTINGS_CATEGORY_CUSTOM_FIELDS,
  SETTINGS_CATEGORY_MAIL,
  SETTINGS_CATEGORY_INVENTORY,
  SETTINGS_CATEGORY_VALOR,
  SETTINGS_CATEGORY_VALOR_SECURITY,
];
export const SETTINGS_YOU_CANT_DELETE = [(SETTINGS_CATEGORY_VALOR, SETTINGS_ENABLED), (SETTINGS_CATEGORY_VALOR_SECURITY, SETTINGS_VALOR_APP_ID)];
export const SUBSCRIPTION_ACTIVE_STATUSES = [SUBSCRIPTION_ACTIVE, SUBSCRIPTION_NEW, SUBSCRIPTION_PAUSED, SUBSCRIPTION_SUSPENDED];
export const SUPER_SENSITIVE = ["password", "passwordhash"];
export const SYSTEM_TAGS_COMPANY = [TAG_NAME_SUBSCRIPTION_PAYMENT_FAILURE, TAG_NAME_SUBSCRIPTION_SUSPENDED];
export const SYSTEM_STORE_SKUS = [
  INFO_LINE_ITEM_SKU,
  ONLINE_SALES_TAX_COLLECTED_SKU,
  ONLINE_SALES_TAX_REMITTED_SKU,
  ONLINE_SELLING_FEE_SKU,
  ONLINE_SHIPPING_FEE_COLLECTED_SKU,
];
export const TEXT_VIEWS = [CUSTOMER, SUPPLIER, BILLING];
export const TIMEFRAMES = [TIMEFRAME_ALL_TIME, TIMEFRAME_MONTH, TIMEFRAME_QUARTER, TIMEFRAME_DAY, TIMEFRAME_WEEK, TIMEFRAME_YEAR];
export const TOP_LEVEL_VIEWS = [
  DASHBOARD,
  CUSTOMERS,
  PRODUCTS,
  INVOICES,
  ORDERS,
  QUOTES,
  REPAIRS,
  SUPPLIERS,
  PURCHASES,
  REPORTS,
  TIMESHEETS,
  BILLINGS,
  MESSAGES,
];
export const VALOR_REFUND_TYPES = [VALOR_TRAN_CODE_VOID, VALOR_TRAN_CODE_REFUND];

// ----END----

// Javascript-only constants
export const REGEX_EMAIL = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
export const REGEX_ORDER_NUMBER = /^[Rr]?[IiOoPpSsQq]-[0-9]+$/;

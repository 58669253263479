import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

// Functions
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import numeral from "numeral";

class PurchaseList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      hideFilter: true,
    };
  }

  getHeaderRowItems = () => {
    return [
      { classes: "header firstLeft", sortable: true, columnheading: "Date", sortkey: "orders.creationdatetime" },
      { classes: "header desktop", sortable: true, columnheading: "Purchase", sortkey: "orders.ordernumber" },
      { classes: "header", sortable: true, columnheading: "Supplier", sortkey: "companies.companyname" },
      { classes: "header desktop", sortable: true, columnheading: "Product", sortkey: "first_item.productname" },
      { classes: "header desktop right-aligned", sortable: true, columnheading: "Total", sortkey: "orders.totalcost" },
      { classes: "header centerAligned lastRight", sortable: true, columnheading: "Status", sortkey: "orders.orderstatus" },
    ];
  };

  renderItemToColumns = item => {
    const mobileCreationDate = <span data-testid="Mobile Creation Date"> {Helper.formatDate(item.creationdatetime, true)}</span>;
    const desktopCreationDate = <span data-testid="Desktop Creation Date"> {Helper.formatDate(item.creationdatetime, false)}</span>;
    const orderNumber = Helper.renderOrderNumber(item?.ordertype, item?.ordersubtype, item?.ordernumber, item?.externalid, this.props.filtertype);
    const companyname = <span data-testid="Supplier Name">{item?.companyname}</span>;
    const productname = Helper.renderProductName(item.productname, item.itemcount);
    const orderstatus = Helper.renderStatus(item.orderstatus, item.ordersubtype);

    let totalcost = numeral(item.totalcost).format(Constants.CURRENCY);
    totalcost = <span data-testid="Total Cost">{totalcost}</span>;

    return [
      { rowvalue: mobileCreationDate, classes: "mobile" },
      { rowvalue: desktopCreationDate, classes: "desktop" },
      { rowvalue: orderNumber, classes: "desktop" },
      { rowvalue: companyname, classes: "" },
      { rowvalue: productname, classes: "desktop" },
      { rowvalue: totalcost, classes: "desktop right-aligned" },
      { rowvalue: orderstatus, classes: "centerAligned" },
    ];
  };

  renderTopControlButtons = () => {
    let openTabClassName = "action-tab";
    let processedTabClassName = "action-tab";
    let allTabClassName = "action-tab";
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      openTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      processedTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      allTabClassName += " selected ";
    }

    return (
      <React.Fragment>
        <div className="orderlisttopcontrols">
          <span
            className={openTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_OPEN });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_OPEN });
            }}
          >
            Open
          </span>
          <span
            className={processedTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_PROCESSED });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_PROCESSED });
            }}
          >
            Processed
          </span>
          <span
            className={allTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_ALL });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_ALL });
            }}
          >
            All
          </span>
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance">
          <span></span>
          {this.renderCalendarFilter()}
        </span>
      </React.Fragment>
    );
  };

  getListGridClassName = () => {
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      return "purchaselist open";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      return "purchaselist processed";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      return "purchaselist all";
    }
  };

  getOrderType = () => {
    return Constants.PURCHASE;
  };
}
export default PurchaseList;

import React from "react";

import { faPencil, faSpinner, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import CircleXmarkIcon from "./img/CircleXmarkIcon";
import FlexInput from "./FlexInput";
import ListHeaderView from "./ListHeaderView";
import ListItemView from "./ListItemView";
import numeral from "numeral";
import TagList from "./TagList";
import Tooltip from "./Tooltip";

// View for editing a Prospect.
class Prospect extends BaseDetailViewComponent {
  renderContactSearchResultsToColumns = item => {
    let fullname = "";
    let mobileviewphone = "";
    let companyname = "";
    fullname = item.firstname + " " + item.lastname;
    // Display mobilephone, then otherphone (if no mobile), then "-" if neither
    mobileviewphone = item.mobilephone ? item.mobilephone : item.otherphone ? item.otherphone : "";
    mobileviewphone = mobileviewphone ? mobileviewphone : item.email;
    companyname = item.companyname ? item.companyname : "";
    fullname = <span data-testid={"Customer Search Results: " + fullname}>{fullname}</span>;
    return [{ rowvalue: fullname }, { rowvalue: mobileviewphone }, { rowvalue: companyname, classes: "desktop" }];
  };

  renderProductList = () => {
    const headers = [
      { classes: "header", columnheading: "Product" },
      { classes: "header desktop noPrint", columnheading: "Associate" },
      {
        classes: "header right-aligned",
        columnheading: this.state.showInventory ? "Qty / Inv" : "Qty",
        eyeball: true,
        eyeballSlashed: this.state.showInventory,
        handleToggleEyeball: this.handleToggleInventory,
      },
      {
        classes: "header right-aligned desktop",
        columnheading: this.state.showCost ? "Cost" : "Price",
      },
      { classes: "header right-aligned desktop", columnheading: this.renderDiscountColumnHeaderLabel() },
      {
        classes: "header right-aligned",
        columnheading: "Total",
        eyeball: true,
        eyeballSlashed: this.state.showCost,
        handleToggleEyeball: this.handleToggleCost,
      },
      { classes: "header desktop noPrint", columnheading: "Status" },
      { classes: "headerlast", columnheading: "" },
    ];
    return (
      <div className="areaProductList">
        <div className="quoteproductlist">
          <ListHeaderView headerRowItems={headers} />
          <hr className="headerRule5" />
          <ListItemView
            listitems={this.state.order.orderitems}
            expandedListItems={null}
            selectedListItems={this.state.selectedOrderItems}
            renderItemToColumns={this.renderSelectedProductToColumns}
            toggleCollapsed={() => {}}
            selectListItem={this.selectOrderItem}
            handleEditItem={() => {}}
            handleTouchStart={this.handleTouchStart}
            handleTouchEnd={this.handleTouchEnd}
          />
        </div>
      </div>
    );
  };

  renderSelectedProductToColumns = item => {
    let renderedValue = item.productname;
    if (item.tags?.length > 0) {
      const icon = <FontAwesomeIcon icon={faTag} />;
      let tagListElement = <TagList tags={item.tags} readonly={true} />;
      let tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
      renderedValue = (
        <span>
          {item.productname} {tooltip}
        </span>
      );
    }
    const productDescription = (
      <div className="areaInputItem" data-testid="Prospect Order Item">
        <FlexInput
          pencilEdit={true}
          type="text"
          textarea={true}
          name="productname"
          id="productname"
          datatestid="Line Item Product Name"
          autoComplete="off"
          maxLength={255}
          onChange={event =>
            this.handleChange(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid
            )
          }
          onFocus={Helper.handleFocus}
          onBlur={event =>
            this.handleBlur(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid,
              null,
              "text" // datatype
            )
          }
          value={item.productname}
          renderedValue={renderedValue}
        />
      </div>
    );

    let productSellPrice;
    if (!this.state.showCost) {
      productSellPrice = (
        <div className="areaInputItem">
          <FlexInput
            type="text"
            inputMode="numeric"
            name="sellprice"
            id="sellprice"
            datatestid="Line Item Sell Price"
            autoComplete="off"
            maxLength={13}
            onChange={event =>
              this.handleChange(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid
              )
            }
            onFocus={Helper.handleFocus}
            onBlur={event =>
              this.handleBlur(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid,
                null,
                "float" // datatype
              )
            }
            value={item.sellprice}
          />
        </div>
      );
    } else {
      productSellPrice = <div className="areaInputItem"> {numeral(item.cost).format(Constants.CURRENCY)}</div>;
    }
    let productQuantity = (
      <div className="areaInputItem">
        <FlexInput
          type="text"
          inputMode="numeric"
          name="quantity"
          id="quantity"
          datatestid="Line Item Quantity"
          autoComplete="off"
          maxLength={13}
          onChange={event =>
            this.handleChange(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid
            )
          }
          onFocus={Helper.handleFocus}
          onBlur={event =>
            this.handleBlur(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid,
              null,
              "float" // datatype
            )
          }
          value={item.quantity}
        />
      </div>
    );
    if (this.state.showInventory) {
      if (item.affectinventory) {
        productQuantity = item.quantity + " / " + item.inventory;
      } else {
        productQuantity = item.quantity + " / -";
      }
    }
    const associateSpinner = item.associateUpdating ? (
      <span className="noOpacity">
        <FontAwesomeIcon icon={faSpinner} spin />
      </span>
    ) : (
      ""
    );
    const associate = (
      <span
        className="associatePicker nowrap"
        data-testid="Line Item Associate"
        onClick={event => {
          this.handleAssociateSelection(event, item.uuid);
        }}
      >
        {associateSpinner} {item.associate} <FontAwesomeIcon icon={faPencil} />
      </span>
    );

    return [
      { rowvalue: productDescription },
      { rowvalue: associate, classes: "desktop noPrint" },
      {
        rowvalue: productQuantity,
        classes: "right-aligned",
      },
      {
        rowvalue: productSellPrice,
        classes: "desktop right-aligned",
      },
      {
        rowvalue: (
          <div className="areaInputItem">
            <FlexInput
              type="text"
              inputMode="numeric"
              name="discount"
              id="discount"
              datatestid="Line Item Discount"
              autoComplete="off"
              maxLength={13}
              onChange={event =>
                this.handleChange(
                  event,
                  Constants.ORDER_ITEM,
                  // Pass either the orderitemuuid (existing) or productuuid (new order)
                  item.orderitemuuid ? item.orderitemuuid : item.uuid
                )
              }
              onFocus={Helper.handleFocus}
              onBlur={event =>
                this.handleBlur(
                  event,
                  Constants.ORDER_ITEM,
                  // Pass either the orderitemuuid (existing) or productuuid (new order)
                  item.orderitemuuid ? item.orderitemuuid : item.uuid,
                  null,
                  "float" // datatype
                )
              }
              value={item.discount}
            />
          </div>
        ),
        classes: "desktop right-aligned",
      },
      {
        rowvalue: (
          <span data-testid={this.state.showCost ? "Line Item Total Cost" : "Line Item Total Price"}>
            {numeral(this.state.showCost ? item.totalcost : item.totalprice).format(Constants.CURRENCY)}
          </span>
        ),
        classes: "right-aligned",
      },
      {
        rowvalue: <span data-testid="Line Item Status">{Helper.renderStatus(item.lineitemstatus)}</span>,
        classes: "desktop right-aligned noPrint",
      },
      {
        rowvalue: (
          <span
            data-testid="Line Item Delete Button"
            onClick={event => {
              if (event && event.stopPropagation) {
                event.stopPropagation();
              }
              this.handleDeleteOrderItem(item.orderitemuuid ?? item.uuid);
            }}
          >
            <CircleXmarkIcon />
          </span>
        ),
        classes: "right-aligned circlebuttoncontainer",
      },
    ];
  };

  renderProductListNarrow = () => {
    const headers = [
      { classes: "header", columnheading: "Product" },
      { classes: "header right-aligned", columnheading: "Qty" },
      { classes: "header right-aligned", columnheading: "Each" },
      { classes: "header right-aligned", columnheading: "Total" },
    ];
    return (
      <div className="areaProductListNarrow">
        <div className="productListGrid quoteproductlist">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={this.state.order.orderitems}
            expandedListItems={null}
            selectedListItems={this.state.selectedOrderItems}
            renderItemToColumns={this.renderSelectedProductToColumnsNarrow}
            toggleCollapsed={() => {}}
            selectListItem={this.selectOrderItem}
            handleEditItem={() => {}}
            handleTouchStart={this.handleTouchStart}
            handleTouchEnd={this.handleTouchEnd}
          />
        </div>
      </div>
    );
  };

  renderSelectedProductToColumnsNarrow = item => {
    const each = item.sellprice - item.discount;
    return [
      { rowvalue: item.productname },
      {
        rowvalue: numeral(item.quantity).format(Constants.DECIMAL_VALUE),
        classes: "right-aligned",
      },
      { rowvalue: numeral(each).format(Constants.CURRENCY), classes: "desktop right-aligned" },
      { rowvalue: numeral(item.totalprice).format(Constants.CURRENCY), classes: "right-aligned" },
    ];
  };

  handleChangeCustomer = (response, contact = null) => {
    if (response === Constants.OVERLAY_RESPONSE_CREATE) {
      // Show the "Create a New Contact" overlay
    } else if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      this.selectCustomerListItem(contact);
    }
  };

  handleChange = (event, type, uuid = null, index = null) => {
    if (event.target.type === "checkbox") {
      this.setState(
        prevState => ({
          company: {
            ...prevState.company,
            contacts: [{ ...prevState.prospect, [event.target.id]: !prevState.prospect[event.target.id] }],
          },
          prospect: { ...prevState.prospect, [event.target.id]: !prevState.prospect[event.target.id] },
        }),
        () => {
          this.putProspect({ target: { name: event.target.id, value: this.state.prospect[event.target.id] } });
        }
      );
    } else {
      this.setState(prevState => ({
        company: {
          ...prevState.company,
          contacts: [{ ...prevState.prospect, [event.target.id]: event.target.value }],
        },
        prospect: { ...prevState.prospect, [event.target.id]: event.target.value },
      }));
    }
  };

  handleBlur = (event, type, uuid = null, callback = null, datatype = null, index = null) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    // Check for required fields
    if (Constants.PROSPECT_REQUIRED_FIELDS.includes(event.target.id) && !event.target.value) {
      this.setState(
        prevState => ({
          prospect: { ...prevState.prospect, [event.target.id]: prev },
        }),
        () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Previous value restored, field is required.",
          });
        }
      );
      return;
    }
    // Check for valid email
    if (event.target.id === "email" && event.target.value && !Helper.isValidEmail(event.target.value)) {
      this.setState(
        prevState => ({
          prospect: { ...prevState.prospect, [event.target.id]: prev },
        }),
        () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Previous value restored, email is not valid.",
          });
        }
      );
      return;
    }
    this.putProspect(event);
  };

  handleDelete = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete?",
      callback: this.maybeDeleteProspect,
      key: this.state.prospect.marketingprospectuuid,
    });
  };

  maybeDeleteProspect = (response, marketingprospectuuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteProspect(marketingprospectuuid);
    }
  };

  deleteProspect = marketingprospectuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CAMPAIGNS;
    const params = { marketingprospectuuid: marketingprospectuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        //Update state to turn off downloading
        this.setState({ downloading: false, error: null });
        //Hide overlay after database action is complete
        this.props.hideOverlay();
        //Return to the List screen
        this.props.followBreadcrumb();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the campaign.",
          });
        });
      }
    });
  };

  getContactsContainerRef = () => {
    return { ...this.state.company, contacts: [this.state.prospect] };
  };

  setStateContacts = (contacts, callback = null) => {
    this.setState(
      prevState => ({
        company: {
          ...prevState.company,
          contacts: contacts,
        },
        prospect: contacts[0],
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };
}
export default Prospect;

import React from "react";

// Style
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

class ListHeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortkey: props.defaultSortKey ? props.defaultSortKey : null,
      sortdirection: props.defaultSortKey ? "A" : null,
    };
  }

  render = () => {
    if (this.props.headerRowItems) {
      return this.props.headerRowItems.map((item, index) => {
        let caret = "";
        let eyeball = "";
        if (item.sortable) {
          if (this.state.sortkey === item.sortkey) {
            if (this.state.sortdirection === "A") {
              caret = (
                <span className="desktop-inline sorticon" onClick={event => this.handleSetSortKey(item)}>
                  <FontAwesomeIcon icon={faSortDown} />
                </span>
              );
            } else {
              caret = (
                <span className="desktop-inline sorticon" onClick={event => this.handleSetSortKey(item)}>
                  <FontAwesomeIcon icon={faSortUp} />
                </span>
              );
            }
          } else {
            caret = (
              <span className="desktop-inline sorticon" onClick={event => this.handleSetSortKey(item)}>
                <FontAwesomeIcon icon={faSort} />
              </span>
            );
          }
        }

        if (item.eyeball) {
          let eyeballIcon = <FontAwesomeIcon icon={faEye} />;
          if (item.eyeballSlashed) {
            eyeballIcon = <FontAwesomeIcon icon={faEyeSlash} />;
          }
          eyeball = (
            <span
              data-testid={item.eyeballTitle ?? "Click to toggle"}
              className="eyeball gridCenter"
              onClick={item.handleToggleEyeball}
              title={item.eyeballTitle ?? "Click to toggle"}
            >
              {eyeballIcon}
            </span>
          );
        }
        return (
          <div className={item.classes} key={item.columnheading + "-" + index}>
            <span onClick={event => this.handleSetSortKey(item)}>{item.columnheading}</span>
            {eyeball}
            {caret}
          </div>
        );
      });
    } else {
      return "";
    }
  };

  handleSetSortKey = item => {
    if (item.sortable) {
      // If there is already a sort key and it's the same sort key,
      //   switch the sort direction
      let sortdirection;
      if (this.state.sortkey && this.state.sortkey === item.sortkey) {
        sortdirection = this.state.sortdirection === "A" ? "D" : "A";
        // Update the sort locally in state, then apply the sort
        //    (i.e. refresh the data from the web service)
      } else {
        // If this is a new sort key, then sort ascending on it
        sortdirection = "A";
      }
      this.setState({ sortkey: item.sortkey, sortdirection: sortdirection }, () => {
        this.props.handleApplySort(item.sortkey, this.state.sortdirection);
      });
    }
  };
}

export default ListHeaderView;

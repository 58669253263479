import React from "react";
import * as Constants from "./Constants";

import "./css/switch.css";

function Switch(props) {
  let labelClassName = "toggle-label " + props.disabledClass ? props.disabledClass : "";
  return (
    <div className="switch-wrapper" id={props.elementid}>
      <label htmlFor={props.fieldname} className={labelClassName} title={props.title}>
        {props.label}
      </label>
      <span className={(props.switchClass ?? "") + " toggle-switch"}>
        <label className={"switch " + (props.fielddisabled ? "save-disabled" : "")}>
          <input
            type={Constants.HTML_INPUT_TYPE_CHECKBOX}
            checked={props.checked}
            name={props.fieldname}
            id={props.fieldname}
            data-testid={"switch-" + props.fieldname}
            disabled={props.fielddisabled}
            className={props.disabledClass}
            onChange={props.handleChange}
          />
          <span className="slider round">
            <span className={Constants.OVERLAY_RESPONSE_YES}>{props.yes ?? "yes"}</span>
            <span className={Constants.OVERLAY_RESPONSE_NO}>{props.no ?? "no"}</span>
          </span>
        </label>
      </span>
    </div>
  );
}

export default Switch;

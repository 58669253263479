import React from "react";

// import { ApiClient, AccountUpdaterApi, AusRequest } from "ap_is";

import * as Helper from "./Helper.jsx";
import * as Constants from "./Constants";
import numeral from "numeral";

import ArchiveIcon from "./img/ArchiveIcon";
import CustomersIcon from "./img/CustomersIcon";
import PurchasesIcon from "./img/PurchasesIcon.js";
import QuotesIcon from "./img/QuotesIcon.js";
import OrdersIcon from "./img/OrdersIcon.js";
import RepairsIcon from "./img/RepairsIcon.js";
import InvoicesIcon from "./img/InvoicesIcon.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faPaperPlane, faPenRuler, faPrint, faTrashCan, faUpload } from "@fortawesome/free-solid-svg-icons";

function ActionButtons(props) {
  return (
    <React.Fragment>
      <div className="desktop buttonContainer gridCenter">{renderActionButtonsDesktop(props)}</div>
      <div className="mobile gridCenter">{renderActionButtonsMobile(props)}</div>
    </React.Fragment>
  );
}

function renderActionButtonsDesktop(props) {
  return (
    <div className="action-buttons ">
      <div className="center-buttons">
        {renderDelete(props)}
        {renderText(props)}
        {renderEmail(props)}
        {renderPrint(props)}
        {renderArchive(props)}
        {renderPurchase(props)}
        {renderOrder(props)}
        {renderQuote(props)}
        {renderRepair(props)}
        {renderDeposit(props)}
        {renderReturn(props)}
        {renderPay(props)}
        {renderRefund(props)}
        {renderInvoice(props)}
        {renderUnpublish(props)}
        {renderPublish(props)}
        {renderPreview(props)}
        {renderProspectToCustomer(props)}
        {renderProspectResend(props)}
      </div>
    </div>
  );
}

function renderActionButtonsMobile(props) {
  return (
    <div className="action-buttons ">
      <div className="action-row-two ">
        {renderText(props)}
        {renderEmail(props)}
        {renderPrint(props)}
      </div>
      <div className="action-row-three ">
        {renderOrder(props)}
        {renderRepair(props)}
        {renderQuote(props)}
        {renderPurchase(props)}
      </div>
      <div className="action-row-four ">
        {renderDelete(props)}
        {renderDeposit(props)}
        {renderInvoice(props)}
        {renderPay(props)}
        {renderProspectResend(props)}
      </div>
    </div>
  );
}

function renderDelete(props) {
  // Delete is available most everywhere, except paid-in-full orders and protected customers
  if (
    // Delete is available only on certains views
    Constants.DELETE_ENABLED_VIEWS.includes(props.appState.currentView) &&
    // Do not show delete button for paid-in-full orders on the Order view unless owner/admin/manager
    (!Constants.CLOSED_INVOICE_ORDER_STATUSES.includes(props.order?.orderstatus) ||
      Helper.authorize(Constants.ACTION_DELETE_CLOSED_ORDER, props.appState.usertype)) &&
    // Do not show delete button for protected customers on the Customer view
    !(props.isProtectedCustomer && props.appState.currentView === Constants.CUSTOMER) &&
    // Only show delete button for Billing Plans view
    (props.appState.currentView !== Constants.BILLING || props.filtertype?.tab === Constants.TAB_PLANS)
  ) {
    const classnames = "action-button red-button" + (!props.enableActionButtons ? " save-disabled" : "");
    let onclick = props.enableActionButtons ? props.handleDelete : () => {};
    if (props.appState.currentView === Constants.BILLING) {
      onclick = props.enableActionButtons ? props.handleDeleteBillingPlan : () => {};
    }
    return (
      <span className="action-button-cell delete">
        <span className={classnames} onClick={onclick} data-testid="Delete From Other">
          <FontAwesomeIcon icon={faTrashCan} /> Delete
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderArchive(props) {
  const isPlans = props.appState.currentView === Constants.BILLING && props.filtertype?.tab === Constants.TAB_PLANS;
  const isCampaign = props.appState.currentView === Constants.CAMPAIGN && props.campaign?.campaignstatus !== Constants.CAMPAIGN_STATUS_ARCHIVED;
  if (isPlans || isCampaign) {
    const classnames = "action-button brown-button" + (!props.enableActionButtons ? " save-disabled" : "");
    const onclick = props.enableActionButtons ? props.handleArchive : () => {};
    return (
      <span className="action-button-cell archive">
        <span className={classnames} onClick={onclick} data-testid="Archive From Other">
          <ArchiveIcon /> Archive
        </span>
      </span>
    );
  } else if (props.appState.currentView === Constants.CAMPAIGN && props.campaign?.campaignstatus === Constants.CAMPAIGN_STATUS_ARCHIVED) {
    return (
      <span className="action-button-cell draft">
        <span className="action-button brown-button" onClick={props.handleDraft} data-testid="Draft From Other">
          <FontAwesomeIcon icon={faPenRuler} /> Unarchive
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderText(props) {
  if (
    props.appState.features?.includes(Constants.FEATURE_TEXT) &&
    Helper.inList(
      [
        Constants.BILLING,
        Constants.CUSTOMER,
        Constants.INVOICE,
        Constants.ORDER,
        Constants.PAY,
        Constants.PURCHASE,
        Constants.QUOTE,
        Constants.REPAIR,
        Constants.SUPPLIER,
      ],
      props.appState.currentView
    ) &&
    // Do not show text button for protected customers
    !props.isProtectedCustomer &&
    // Do not show text button for prospects
    props.filtertype?.tab !== Constants.TAB_PROSPECTS &&
    // Do not show the button on Billing Plans view
    props.filtertype?.tab !== Constants.TAB_PLANS
  ) {
    const classnames = "action-button brown-button" + (!props.enableActionButtons ? " save-disabled" : "");
    const onclick = props.enableActionButtons
      ? () => {
          props.handleSMS();
        }
      : () => {};
    return (
      <span className="action-button-cell text">
        <span className={classnames} onClick={onclick} data-testid="Text From Other">
          <FontAwesomeIcon icon={faComment} /> Text
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderEmail(props) {
  if (
    props.appState.features?.includes(Constants.FEATURE_EMAIL) &&
    Helper.inList(
      [
        Constants.BILLING,
        Constants.CUSTOMER,
        Constants.INVOICE,
        Constants.ORDER,
        Constants.PAY,
        Constants.PURCHASE,
        Constants.QUOTE,
        Constants.REPAIR,
        Constants.SUPPLIER,
        // Constants.REPORT,
      ],
      props.appState.currentView
    ) &&
    // Do not show email button for protected customers on the Customer view
    !(props.isProtectedCustomer && props.appState.currentView === Constants.CUSTOMER) &&
    // Do not show the button on Billing Plans view
    props.filtertype?.tab !== Constants.TAB_PLANS
  ) {
    const classnames = "action-button brown-button" + (!props.enableActionButtons ? " save-disabled" : "");
    const onclick = props.enableActionButtons
      ? () => {
          props.handleEmail();
        }
      : () => {};
    return (
      <span className="action-button-cell email">
        <span className={classnames} onClick={onclick} data-testid="Email From Other">
          <FontAwesomeIcon icon={faPaperPlane} /> Email
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderPrint(props) {
  if (
    Helper.inList(
      [
        Constants.BILLING,
        Constants.CUSTOMER,
        Constants.INVOICE,
        Constants.ORDER,
        Constants.PAY,
        Constants.PRODUCT,
        Constants.PURCHASE,
        Constants.QUOTE,
        Constants.REPAIR,
        Constants.SUPPLIER,
        Constants.CAMPAIGN,
        // Constants.REPORT,
      ],
      props.appState.currentView
    )
  ) {
    const classnames = "action-button brown-button" + (!props.enableActionButtons ? " save-disabled" : "");
    const onclick = props.enableActionButtons ? props.handlePrint : () => {};
    return (
      <span className="action-button-cell print">
        <span className={classnames} onClick={onclick} data-testid="Print From Other">
          <FontAwesomeIcon icon={faPrint} /> Print
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderPurchase(props) {
  if (
    props.appState.features?.includes(Constants.FEATURE_PURCHASES) &&
    Helper.inList([Constants.PRODUCT, Constants.ORDER, Constants.REPAIR, Constants.QUOTE, Constants.SUPPLIER], props.appState.currentView) &&
    // Do not show the button on Billing Plans view
    props.filtertype?.tab !== Constants.TAB_PLANS
  ) {
    let title = "";
    let disabled = !props.enableActionButtons;
    // Do not enable order button for gift cards
    if (props.appState.currentView === Constants.PRODUCT && props.product?.isgiftcard) {
      disabled = true;
      title = "Not valid for gift card";
    }
    const classnames = "action-button brown-button" + (disabled ? " save-disabled " : "");
    const onclick = !disabled ? () => props.handleCreateGenericOrder(Constants.PURCHASE, Constants.PURCHASES) : () => {};
    return (
      <span className="action-button-cell purchase">
        <span title={title} onClick={onclick} className={classnames} data-testid="Create Purchase From Other">
          <PurchasesIcon /> Purchase
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderOrder(props) {
  if (
    props.appState.features?.includes(Constants.FEATURE_ORDERS) &&
    Helper.inList([Constants.BILLING, Constants.CUSTOMER, Constants.PRODUCT, Constants.REPAIR, Constants.QUOTE], props.appState.currentView) &&
    // Do not show the button on Billing Plans view
    ![Constants.TAB_PLANS, Constants.TAB_PROSPECTS].includes(props.filtertype?.tab)
  ) {
    let title = "";
    let disabled = !props.enableActionButtons;
    // Do not enable order button for gift cards
    if (props.appState.currentView === Constants.PRODUCT && props.product?.isgiftcard) {
      disabled = true;
      title = "Not valid for gift card";
    }
    const classnames = "action-button brown-button" + (disabled ? " save-disabled " : "");
    const onclick = !disabled ? () => props.handleCreateGenericOrder(Constants.ORDER, Constants.ORDERS) : () => {};
    return (
      <span className="action-button-cell order">
        <span title={title} onClick={onclick} className={classnames} data-testid="Create Order From Other">
          <OrdersIcon /> Order
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderQuote(props) {
  // Never show the Quote button for External Online orders
  if (Constants.ONLINE_ORDER_STATUSES.includes(props.order?.orderstatus) || props.filtertype?.tab === Constants.TAB_PROSPECTS) {
    return "";
  }
  if (
    props.appState.features?.includes(Constants.FEATURE_QUOTES) &&
    Helper.inList(
      [Constants.BILLING, Constants.CUSTOMER, Constants.PRODUCT, Constants.INVOICE, Constants.ORDER, Constants.REPAIR],
      props.appState.currentView
    ) &&
    !Helper.isReturn(props.order) &&
    // Do not show the button on Billing Plans view
    props.filtertype?.tab !== Constants.TAB_PLANS
  ) {
    let title = "";
    let disabled = !props.enableActionButtons;
    // Do not enable order button for gift cards
    if (props.appState.currentView === Constants.PRODUCT && props.product?.isgiftcard) {
      disabled = true;
      title = "Not valid for gift card";
    }
    const classnames = "action-button brown-button" + (disabled ? " save-disabled " : "");
    const onclick = !disabled ? () => props.handleCreateGenericOrder(Constants.QUOTE, Constants.QUOTES) : () => {};
    return (
      <span className="action-button-cell quote">
        <span title={title} onClick={onclick} className={classnames} data-testid="Create Quote From Other">
          <QuotesIcon /> Quote
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderRepair(props) {
  // Never show the Repair button for External Online orders
  if (Constants.ONLINE_ORDER_STATUSES.includes(props.order?.orderstatus) || props.filtertype?.tab === Constants.TAB_PROSPECTS) {
    return "";
  }
  if (
    props.appState.features?.includes(Constants.FEATURE_REPAIRS) &&
    Helper.inList(
      [Constants.BILLING, Constants.CUSTOMER, Constants.PRODUCT, Constants.INVOICE, Constants.ORDER, Constants.QUOTE],
      props.appState.currentView
    ) &&
    !Helper.isReturn(props.order) &&
    // Do not show the button on Billing Plans view
    props.filtertype?.tab !== Constants.TAB_PLANS
  ) {
    let title = "";
    let disabled = !props.enableActionButtons;
    // Do not enable order button for gift cards
    if (props.appState.currentView === Constants.PRODUCT && props.product?.isgiftcard) {
      disabled = true;
      title = "Not valid for gift card";
    }
    const classnames = "action-button brown-button" + (disabled ? " save-disabled " : "");
    const onclick = !disabled ? () => props.handleCreateGenericOrder(Constants.REPAIR, Constants.REPAIRS) : () => {};
    return (
      <span className="action-button-cell repair">
        <span title={title} onClick={onclick} className={classnames} data-testid="Create Service From Other">
          <RepairsIcon /> Service
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderInvoice(props) {
  if (
    props.appState.features?.includes(Constants.FEATURE_INVOICES) &&
    Helper.inList(
      [Constants.BILLING, Constants.CUSTOMER, Constants.PRODUCT, Constants.ORDER, Constants.REPAIR, Constants.QUOTE],
      props.appState.currentView
    ) &&
    // Do not show the button on Billing Plans view
    ![Constants.TAB_PLANS, Constants.TAB_PROSPECTS].includes(props.filtertype?.tab)
  ) {
    const disabled = !props.enableActionButtons;
    const classnames = "action-button green-button" + (disabled ? " save-disabled " : "");
    const onclick = !disabled ? () => props.handleCreateGenericOrder(Constants.INVOICE, Constants.INVOICES) : () => {};
    return (
      <span className="action-button-cell invoice">
        <span onClick={onclick} className={classnames} data-testid="Create Invoice From Other">
          <InvoicesIcon /> Invoice
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderReturn(props) {
  // If this is the Invoice Screen, it's a SALE invoice (not a trade-in w/ negative totalprice)
  // and it is paid-in-full or partially refunded and has eligible items,
  // then display the return button
  if (
    props.appState.features?.includes(Constants.FEATURE_INVOICES) &&
    props.appState.currentView === Constants.INVOICE &&
    !Helper.isReturn(props.order) &&
    numeral(props?.order?.totalprice ?? 0).value() > 0 &&
    Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, props.order?.orderstatus) &&
    Helper.hasReturnEligibleItems(props.order) &&
    props.order?.payments?.length > 0
  ) {
    const disabled = !props.enableActionButtons;
    const classnames = "action-button green-button" + (disabled ? " save-disabled " : "");
    const onclick = !disabled ? props.handleReturn : () => {};
    return (
      <span className="action-button-cell return">
        <span onClick={onclick} className={classnames} title="Return one or more items">
          <InvoicesIcon /> Return
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderPay(props) {
  // Never show the Pay button for External Online orders
  if (Constants.ONLINE_ORDER_STATUSES.includes(props.order?.orderstatus)) {
    return "";
  }
  // If this is the Invoice Screen, it's not a return,
  // it's not paid in full or refunded,
  if (
    props.appState.features?.includes(Constants.FEATURE_INVOICES) &&
    props.appState.currentView === Constants.INVOICE &&
    !Helper.isReturn(props.order) &&
    !Helper.inList(Constants.CLOSED_INVOICE_ORDER_STATUSES, props.order?.orderstatus)
  ) {
    // If order has not yet loaded from the database/API then display disabled Pay button
    if (props.order.balancedue === undefined) {
      return (
        <span className="action-button-cell pay">
          <span onClick={() => {}} className="action-button green-button save-disabled " data-testid="Pay From Other">
            <InvoicesIcon /> Pay
          </span>
        </span>
      );
    }
    // If there is a balance due then display the Pay button
    else if (numeral(props.order.balancedue).value() !== 0) {
      const disabled = !props.enableActionButtons;
      const classnames = "action-button green-button" + (disabled ? " save-disabled " : "");
      const onclick = !disabled ? props.handlePay : () => {};
      return (
        <span className="action-button-cell pay">
          <span onClick={onclick} className={classnames} data-testid="Pay From Other">
            <InvoicesIcon /> Pay
          </span>
        </span>
      );
    }
    // If zero balance due then display the Mark as Paid button
    else if (numeral(props.order.balancedue).value() === 0) {
      const disabled = !props.enableActionButtons;
      const classnames = "action-button green-button" + (disabled ? " save-disabled " : "");
      const onclick = !disabled ? props.handleMarkAsPaid : () => {};
      return (
        <span className="action-button-cell pay">
          <span onClick={onclick} className={classnames} data-testid="Mark as Paid From Other">
            <span className="desktop">
              <InvoicesIcon /> Mark as Paid
            </span>
            <span className="mobile">
              <InvoicesIcon /> Mark Paid
            </span>
          </span>
        </span>
      );
    }
  } else {
    return "";
  }
}

function renderRefund(props) {
  // If this is the Invoice Screen and it is a return, and not paid in full or refunded, display the "Payment" Refund button
  if (
    props.appState.features?.includes(Constants.FEATURE_INVOICES) &&
    props.appState.currentView === Constants.INVOICE &&
    Helper.isReturn(props.order) &&
    ![Constants.ORDER_STATUS_PAID_IN_FULL, Constants.ORDER_STATUS_REFUNDED].includes(props.order?.orderstatus)
  ) {
    const disabled = !props.enableActionButtons;
    const classnames = "action-button green-button" + (disabled ? " save-disabled " : "");
    const onclick = !disabled ? props.handleRefund : () => {};
    return (
      <span className="action-button-cell pay">
        <span onClick={onclick} className={classnames} data-testid="Create Refund From Other">
          <InvoicesIcon /> Refund
        </span>
      </span>
    );
  }
  // If this is the order, quote or repair screen and it has a payment, display the "Deposit" Refund button.
  // else if (
  //   props.appState.features?.includes(Constants.FEATURE_INVOICES) &&
  //   Constants.DEPOSIT_ENABLED_ORDER_TYPES.includes(props.appState.currentView) &&
  //   props.order.payments.length > 0 &&
  //   // Filter for payments where the parentpaymentuuid is null which means that the payment was not taken on this order.
  //   props.order.payments.filter(payment => !payment.parentpaymentuuid).length > 0 &&
  //   ![Constants.ORDER_STATUS_PAID_IN_FULL, Constants.ORDER_STATUS_REFUNDED, Constants.ORDER_STATUS_INVOICED].includes(props.order?.orderstatus)
  // ) {
  //   const disabled = !props.enableActionButtons;
  //   const classnames = "action-button green-button" + (disabled ? " save-disabled " : "");
  //   const onclick = !disabled ? props.handleRefund : () => {};
  //   return (
  //     <span className="action-button-cell pay">
  //       <span onClick={onclick} className={classnames} data-testid="Create Deposit Refund From Other">
  //         <InvoicesIcon /> Refund
  //       </span>
  //     </span>
  //   );
  // }
  // If this is not the invoice, order, quote, or repair screen, do not display the refund button at all.
  else {
    return "";
  }
}

function renderDeposit(props) {
  if (Helper.inList([Constants.ORDER, Constants.REPAIR, Constants.QUOTE], props.appState.currentView)) {
    const disabled = !props.enableActionButtons || numeral(props.order?.balancedue ?? "0").value() <= 0;
    const classnames = "action-button brown-button" + (disabled ? " save-disabled " : "");
    const onclick = !disabled ? props.handlePay : () => {};
    return (
      <span className="action-button-cell pay">
        <span onClick={onclick} className={classnames} data-testid="Create Deposit From Other">
          <InvoicesIcon /> Deposit
        </span>
      </span>
    );
  } else {
    return "";
  }
}

function renderProspectResend(props) {
  if (props.prospect?.prospectstatus === Constants.PROSPECT_STATUS_NEW || props.prospect?.prospectstatus === Constants.PROSPECT_STATUS_PREMATCHED) {
    const classes = "action-button blue-button";
    return (
      <span className="action-button-cell resend-prospect">
        <span className={classes} title="Resend Campaign Link" onClick={props.handleResendCampaignLinkToProspect}>
          <FontAwesomeIcon icon={faPaperPlane} /> Resend
        </span>
      </span>
    );
  }
  return "";
}

function renderProspectToCustomer(props) {
  if (
    props.appState?.currentView === Constants.CUSTOMER &&
    props.filtertype?.tab === Constants.TAB_PROSPECTS &&
    !props.prospect?.contactuuid &&
    props.prospect?.prospectstatus !== Constants.PROSPECT_STATUS_NEW
  ) {
    return (
      <span className="action-button-cell prospect-to-customer">
        <span className="action-button green-button" onClick={props.handleProspectToCustomer} data-testid="Prospect To Customer">
          <CustomersIcon /> Convert to Customer
        </span>
      </span>
    );
  }
}

function renderPreview(props) {
  if (props.appState.currentView === Constants.CAMPAIGN) {
    return (
      <span className="action-button-cell preview">
        <span className="action-button green-button" onClick={props.handlePreview} data-testid="Preview From Other">
          <FontAwesomeIcon icon={faPrint} /> Preview
        </span>
      </span>
    );
  }
}

function renderPublish(props) {
  if (props.appState.currentView === Constants.CAMPAIGN && props.campaign?.campaignstatus === Constants.CAMPAIGN_STATUS_DRAFT) {
    let classes = "action-button green-button";
    let handler = props.handlePublish;
    let title = "Make the campaign public";
    if (!props.campaign?.description) {
      classes += " save-disabled";
      handler = () => {};
      title = "Please add a description to publish";
    }
    return (
      <span className="action-button-cell publish">
        <span className={classes} onClick={handler} data-testid="Publish From Other" title={title}>
          <FontAwesomeIcon icon={faUpload} /> Publish
        </span>
      </span>
    );
  }
}

function renderUnpublish(props) {
  if (props.appState.currentView === Constants.CAMPAIGN && props.campaign?.campaignstatus === Constants.CAMPAIGN_STATUS_PUBLISHED) {
    return (
      <span className="action-button-cell unpublish">
        <span className="action-button brown-button" onClick={props.handleDraft} data-testid="Unpublish From Other">
          <FontAwesomeIcon icon={faPenRuler} /> Unpublish
        </span>
      </span>
    );
  }
}

export default ActionButtons;

import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

// Functions
import * as Helper from "./Helper";
import * as Constants from "./Constants";
import numeral from "numeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

class TimesheetList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      hideFilter: true,
      hideFilterDates: false,
    };
  }

  getHeaderRowItems = () => {
    if (this.props.filtertype.tab === Constants.TIMESHEETS_COMPLETED) {
      return this.getHeaderRowItemsCompleted();
    } else if (this.props.filtertype.tab === Constants.TIMESHEETS_CURRENT) {
      return this.getHeaderRowItemsCurrent();
    }
  };

  getHeaderRowItemsCompleted = () => {
    return [
      {
        classes: "header desktop firstLeft",
        sortable: false,
        columnheading: "Date",
      },
      {
        classes: "header",
        sortable: false,
        columnheading: "Employee",
      },
      {
        classes: "header centerAligned",
        sortable: false,
        columnheading: "Clock-in",
      },
      {
        classes: "header centerAligned",
        sortable: false,
        columnheading: "Clock-out",
      },
      {
        classes: "header right-aligned desktop",
        sortable: false,
        columnheading: "Total",
      },
      {
        classes: "header right-aligned desktop lastRight",
        sortable: false,
        columnheading: "",
      },
    ];
  };

  getHeaderRowItemsCurrent = () => {
    return [
      {
        classes: "header desktop firstLeft",
        sortable: false,
        columnheading: "Date",
      },
      {
        classes: "header",
        sortable: false,
        columnheading: "Employee",
      },
      {
        classes: "header centerAligned",
        sortable: false,
        columnheading: "Clock-in",
      },
      {
        classes: "header centerAligned",
        sortable: false,
        columnheading: "Clock-out",
      },
      {
        classes: "header right-aligned desktop",
        sortable: false,
        columnheading: "Total",
      },
      {
        classes: "header right-aligned desktop lastRight",
        sortable: false,
        columnheading: "",
      },
    ];
  };

  getOrderType = () => {
    return Constants.TIMESHEET;
  };

  renderItemToColumns = item => {
    if (this.props.filtertype.tab === Constants.TIMESHEETS_CURRENT) {
      return this.renderItemToColumnsCurrent(item);
    } else if (this.props.filtertype.tab === Constants.TIMESHEETS_COMPLETED) {
      return this.renderItemToColumnsCompleted(item);
    }
  };

  renderItemToColumnsCurrent = item => {
    let totalTime = numeral((new Date() - new Date(item.clockin)) / 1000 / 60 / 60).format(Constants.LONG_DECIMAL_VALUE);
    if (totalTime === "NaN") {
      totalTime = "0.000";
    }
    let clockin = Helper.formatDateTime(item.clockin, false, true);
    if (Helper.authorize(Constants.ACTION_EDIT_TIMESHEET, this.props.appState.usertype) && item.editingIn) {
      clockin = (
        <input
          type="datetime-local"
          id="clockin"
          autoFocus={item.editingIn}
          onFocus={event => {
            Helper.handleFocus(event, false);
          }}
          onChange={event => {
            this.handleChange(event, Constants.TIMESHEET, item.uuid);
          }}
          onBlur={event => {
            this.handleBlur(event, Constants.TIMESHEET, item.uuid);
          }}
          value={item.clockin}
        />
      );
    } else if (Helper.authorize(Constants.ACTION_EDIT_TIMESHEET, this.props.appState.usertype)) {
      clockin = (
        <div
          onClick={() => {
            this.setState(prevState => ({
              listItems: prevState.listItems.map(i => (i.uuid === item.uuid ? { ...i, editingIn: true } : { ...i, editingIn: false })),
            }));
          }}
        >
          {Helper.formatDateTime(item.clockin, false, true)}
        </div>
      );
    }

    const clockout = (
      <div
        onClick={() => {
          this.props.handleEditTimesheet(item);
        }}
      >
        Clock out
      </div>
    );
    const notes = item.notes ? (
      <span onClick={() => this.handleShowNotes(item.notes)}>
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      </span>
    ) : null;
    let fullname = item.fullname;
    if (item.notes) {
      fullname = (
        <span className="timesheetNote" onTouchStart={() => this.handleShowNotes(item.notes)}>
          {item.fullname} <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </span>
      );
    }

    let rows = [
      // Mobile date view will not be shown on mobile due to space issues
      // { rowvalue: Helper.formatDate(item.creationdatetime, true), classes: "mobile" },
      // Desktop date view:
      { rowvalue: Helper.formatDate(item.creationdatetime, false), classes: "desktop" },
      { rowvalue: fullname, classes: "mobile" },
      { rowvalue: item.fullname, classes: "desktop" },
      { rowvalue: clockin, classes: "gridCenter" },
      { rowvalue: clockout, classes: "red-button clockout-button gridCenter" },
      { rowvalue: totalTime, classes: "desktop right-aligned" },
      { rowvalue: notes, classes: "desktop right-aligned" },
    ];
    if (item.notes) {
      rows.push({ rowvalue: item.notes, classes: "printOnly headerRule6 printedTimesheetNote" });
    }
    return rows;
  };

  renderItemToColumnsCompleted = item => {
    const totalTime = numeral(item.totalTime).format(Constants.LONG_DECIMAL_VALUE);
    let clockin = Helper.formatDateTime(item.clockin, false, true);
    if (Helper.authorize(Constants.ACTION_EDIT_TIMESHEET, this.props.appState.usertype) && item.editingIn) {
      clockin = (
        <input
          type="datetime-local"
          id="clockin"
          autoFocus={item.editingIn}
          onFocus={event => {
            Helper.handleFocus(event, false);
          }}
          onChange={event => {
            this.handleChange(event, Constants.TIMESHEET, item.uuid);
          }}
          onBlur={event => {
            this.handleBlur(event, Constants.TIMESHEET, item.uuid);
          }}
          value={item.clockin}
        />
      );
    } else if (Helper.authorize(Constants.ACTION_EDIT_TIMESHEET, this.props.appState.usertype)) {
      clockin = (
        <div
          onClick={() => {
            this.setState(prevState => ({
              listItems: prevState.listItems.map(i => (i.uuid === item.uuid ? { ...i, editingIn: true } : { ...i, editingIn: false })),
            }));
          }}
        >
          {Helper.formatDateTime(item.clockin, false, true)}
        </div>
      );
    }
    let clockout = Helper.formatDateTime(item.clockout, false, true);
    if (Helper.authorize(Constants.ACTION_EDIT_TIMESHEET, this.props.appState.usertype) && item.editingOut) {
      clockout = (
        <input
          type="datetime-local"
          id="clockout"
          autoFocus={item.editingOut}
          onFocus={event => {
            Helper.handleFocus(event, false);
          }}
          onChange={event => {
            this.handleChange(event, Constants.TIMESHEET, item.uuid);
          }}
          onBlur={event => {
            this.handleBlur(event, Constants.TIMESHEET, item.uuid);
          }}
          value={item.clockout}
        />
      );
    } else if (Helper.authorize(Constants.ACTION_EDIT_TIMESHEET, this.props.appState.usertype)) {
      clockout = (
        <div
          onClick={() => {
            this.setState(prevState => ({
              listItems: prevState.listItems.map(i => (i.uuid === item.uuid ? { ...i, editingOut: true } : { ...i, editingOut: false })),
            }));
          }}
        >
          {Helper.formatDateTime(item.clockout, false, true)}
        </div>
      );
    }
    const notes = item.notes ? (
      <span className="timesheetNote" onClick={() => this.handleShowNotes(item.notes)}>
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
      </span>
    ) : null;
    let fullname = item.fullname;
    if (item.notes) {
      fullname = (
        <span onTouchStart={() => this.handleShowNotes(item.notes)} className="mobile italic">
          {item.fullname} <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="desktop" />
        </span>
      );
    }
    let rows = [
      // Mobile date view will not be shown on mobile due to space issues
      // { rowvalue: Helper.formatDate(item.creationdatetime, true), classes: "mobile" },
      // Desktop date view:
      { rowvalue: Helper.formatDate(item.creationdatetime, false), classes: "desktop" },
      { rowvalue: fullname, classes: "mobile" },
      { rowvalue: item.fullname, classes: "desktop" },
      { rowvalue: clockin, classes: "gridCenter" },
      { rowvalue: clockout, classes: "gridCenter" },
      { rowvalue: totalTime, classes: "desktop right-aligned" },
      { rowvalue: notes, classes: "desktop right-aligned" },
      { rowvalue: item.notes, classes: "noReceipt headerRule6 printedTimesheetNote" },
    ];
    // if (item.notes) {
    //   rows.push({
    //     rowvalue: item.notes,
    //     classes: "printOnly headerRule5 printedTimesheetNote noReceipt",
    //   });
    //   rows.push({
    //     rowvalue: item.notes,
    //     classes: "printOnly headerRule3 printedTimesheetNote noPage",
    //   });
    // }
    return rows;
  };

  renderTopControlButtons = () => {
    let processedTabClassName = "action-tab";
    let todayTabClassName = "action-tab";

    if (this.props.filtertype.tab === Constants.TIMESHEETS_CURRENT) {
      todayTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.TIMESHEETS_COMPLETED) {
      processedTabClassName += " selected ";
    }

    return (
      <React.Fragment>
        <div className="orderlisttopcontrols">
          <span
            className={todayTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TIMESHEETS_CURRENT });
              this.handleFilterList({ tab: Constants.TIMESHEETS_CURRENT });
            }}
          >
            Current
          </span>
          <span
            className={processedTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TIMESHEETS_COMPLETED });
              this.handleFilterList({ tab: Constants.TIMESHEETS_COMPLETED });
            }}
          >
            Completed
          </span>
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance"></span>
      </React.Fragment>
    );
  };

  handleShowNotes = notes => {
    notes = notes.trim();
    if (notes.indexOf("\n") > 0) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Changes made to this timesheet:",
        bullets: notes,
      });
    } else {
      this.props.showOverlay({ type: Constants.OVERLAY_MESSAGE, text: notes });
    }
  };

  getListGridClassName = () => {
    if (this.props.filtertype.tab === Constants.TIMESHEETS_CURRENT) {
      return "timesheetlist open";
    } else if (this.props.filtertype.tab === Constants.TIMESHEETS_COMPLETED) {
      return "timesheetlist processed";
    }
  };

  // Prevent list item selection on Timesheet
  selectListItem = item => {};
}
export default TimesheetList;

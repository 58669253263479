import BaseListViewComponent from "./BaseListViewComponent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Functions
import { faFileImport, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import numeral from "numeral";
import Papa from "papaparse";
import Tooltip from "./Tooltip";
import React from "react";

class Import extends BaseListViewComponent {
  constructor(props) {
    super(props);

    // Override default state here
    this.state = {
      ...this.state,
      listItems: [],
      hideFilter: true,
      stats: { progress: 0, imported: 0, failed: 0 },
      imported: [],
      failed: [],
      data: this.props.import?.data || [],
      updateExisting: this.props.import?.updateExisting || false,
      showpagination: false,
      importComplete: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.created !== this.props.created ||
      prevProps.import?.headerFields !== this.props.import?.headerFields ||
      prevProps.import?.data !== this.props.import?.data
    ) {
      this.setState(
        {
          data: this.props.import?.data,
          updateExisting: this.props.import?.updateExisting || false,
          imported: [],
          failed: [],
        },
        () => {
          this.getListItems();
        }
      );
    }
    if (prevProps.import && !this.props.import) {
      this.setState({ data: [] });
    }
  }

  getHeaderRowItems = () => {
    return [
      {
        classes: "header",
        columnheading: this.state.updateExisting ? (
          <div className="fieldsToUpdate">
            <div>Select Fields to Update</div>
            <div>
              <span
                className="hoverPointer"
                onClick={() => {
                  this.setState(prevState => ({
                    listItems: prevState.listItems.map(item => {
                      // Only select "enabled" fields
                      if (!item.disable) {
                        item.match = item.name;
                      }
                      return item;
                    }),
                  }));
                }}
              >
                [Select all]
              </span>

              <span
                className="hoverPointer"
                onClick={() => {
                  this.setState(prevState => ({
                    listItems: prevState.listItems.map(item => {
                      item.match = "";
                      return item;
                    }),
                  }));
                }}
              >
                [Unselect all]
              </span>
            </div>
          </div>
        ) : (
          "Field Mapping"
        ),
      },
      {
        classes: "header",
        columnheading: "Instructions",
      },
    ];
  };

  getFieldOptions = item => {
    let fields = [];
    if (item.required) {
      fields = fields.concat([{ label: "--Please select--", value: "" }]);
    } else {
      fields = fields.concat([{ label: "--Optional field--", value: "" }]);
    }

    if (this.props.import?.headerFields) {
      let fieldNames = this.props.import?.headerFields
        .map(f => {
          return { label: f.name, value: f.name };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

      fields = fields.concat(fieldNames);
    }
    if (item.boolean) {
      fields = fields.concat([
        { label: "[Mark all true]", value: Constants.SET_ALL_TRUE },
        { label: "[Mark all false]", value: Constants.SET_ALL_FALSE },
      ]);
    }
    return fields;
  };

  renderItemToColumns = item => {
    if (this.state.updateExisting) {
      return this.renderItemToColumnsUpdate(item);
    } else {
      return this.renderItemToColumnsImport(item);
    }
  };

  renderItemToColumnsUpdate = item => {
    if (item.heading) {
      let label = (
        <span>
          <span className="white underline">{item.label}</span>
          <span className="footnote white"> 2</span>
        </span>
      );
      return [{ rowvalue: label }, { rowvalue: "" }, { rowvalue: "" }];
    }

    // Format a sample of the data for the field (first row of the CSV file)
    let sample = "";
    if (this.state.data?.length > 0) {
      sample = this.state.data[0][item.name];
    }
    if (item.boolean === true) {
      sample = Helper.isTrue(sample) ? "true" : "false";
    }
    if (item.percent) {
      sample = Helper.formatPercent(sample);
    }
    if (item.commaSeparated && sample) {
      sample = sample.split(",").map((tag, index) => {
        const myStyle = {
          borderColor: Constants.TAG_COLOR_DEFAULT,
        };
        const key = index + "-" + tag;
        return (
          <span className="tag" style={myStyle} key={key} data-key={key}>
            <span className="tagText">{tag}</span>
          </span>
        );
      });
      sample = <div className="tagElementContainer">{sample}</div>;
    }

    let multirequiredNote = "";
    let footnoteMark = "";
    if (item.multirequired) {
      multirequiredNote = <span className="footnote">&dagger;</span>;
    }
    if (item.boolean) {
      footnoteMark = <span className="footnote">1</span>;
    }
    if (item.percent) {
      footnoteMark = <span className="footnote">3</span>;
    }

    // Check for double-mapped fields (except TRUE/FALSE mappings)
    const mappedFields = this.state.listItems.filter(
      f => f.match === item.match && item.match && ![Constants.SET_ALL_TRUE, Constants.SET_ALL_FALSE].includes(item.match)
    );
    if (mappedFields.length > 1) {
      let tooltip = "";
      const icon = <FontAwesomeIcon icon={faExclamationTriangle} className="highlight" />;
      const tip = (
        <div>
          <span className="importWarning">Field is mapped more than once</span>
        </div>
      );
      tooltip = <Tooltip text={icon} tooltip={tip} wrapperClasses="tagListParent" noGhost={true} />;
      sample = (
        <span>
          {tooltip}&nbsp;&nbsp;
          {sample}
        </span>
      );
    }

    // Render the label, input element, and sample
    // May be disabled if the field is not in the file to be imported
    const fieldNameClasses = "importFieldname " + (item.disable ? " save-disabled" : "");
    const notPresentMessage = `Field '${item.name}' not present in file`;
    let label = (
      <span title={item.disable ? notPresentMessage : ""}>
        {item.label} {multirequiredNote} {footnoteMark}
      </span>
    );
    let inputElement = (
      <div className="areaInputItem" title={item.disable ? notPresentMessage : ""}>
        <input
          type="checkbox"
          id={item.name}
          checked={item.match === item.name}
          disabled={item.disable}
          onChange={event => {
            if (event.target.checked) {
              event.target.value = item.name;
            } else {
              event.target.value = "";
            }
            this.handleChange(event, item);
          }}
        />
      </div>
    );

    return [
      { rowvalue: label, classes: fieldNameClasses },
      { rowvalue: inputElement, classes: "mapto" },
      { rowvalue: item.disable ? "" : sample, classes: "importFieldname italic" },
    ];
  };

  renderItemToColumnsImport = item => {
    if (item.heading) {
      let label = (
        <span>
          <span className="white underline">{item.label}</span>
          <span className="footnote white"> 2</span>
        </span>
      );
      return [{ rowvalue: label }, { rowvalue: "" }, { rowvalue: "" }];
    }
    let inputElement = "";
    if (item.textfield) {
      inputElement = (
        <div className="areaInputItem">
          <input
            type="text"
            id={item.name}
            value={item.match}
            onChange={event => {
              this.handleChange(event, item);
            }}
          />
        </div>
      );
    } else {
      const fieldOptions = this.getFieldOptions(item).map(opt => {
        return (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        );
      });
      inputElement = (
        <select
          id={item.name}
          value={item.match}
          onChange={event => {
            this.handleChange(event, item);
          }}
        >
          {fieldOptions}
        </select>
      );
    }
    let sample = "";
    if (this.state.data?.length > 0 && this.state.data[0][item.match]) {
      sample = this.state.data[0][item.match];
    }
    if (item.boolean === true) {
      sample = item.match === Constants.SET_ALL_TRUE ? "true" : item.match === Constants.SET_ALL_FALSE ? "false" : "";
    }
    if (item.percent) {
      sample = Helper.formatPercent(sample);
    }
    if (item.commaSeparated && sample) {
      sample = "'" + sample.split(",").join("', '") + "'";
    }
    let multirequiredNote = "";
    let footnoteMark = "";
    if (item.multirequired) {
      multirequiredNote = <span className="footnote">&dagger;</span>;
    }
    if (item.boolean) {
      footnoteMark = <span className="footnote">1</span>;
    }
    if (item.percent) {
      footnoteMark = <span className="footnote">3</span>;
    }
    let label = (
      <span>
        {item.label} {multirequiredNote} {footnoteMark}
      </span>
    );

    // Check for double-mapped fields
    const mappedFields = this.state.listItems.filter(
      f => f.match === item.match && item.match && ![Constants.SET_ALL_TRUE, Constants.SET_ALL_FALSE].includes(item.match)
    );
    if (mappedFields.length > 1) {
      let tooltip = "";
      const icon = <FontAwesomeIcon icon={faExclamationTriangle} className="highlight" />;
      const tip = (
        <div>
          <span className="importWarning">Field is mapped more than once</span>
        </div>
      );
      tooltip = <Tooltip text={icon} tooltip={tip} wrapperClasses="tagListParent" noGhost={true} />;
      sample = (
        <span>
          {tooltip}&nbsp;&nbsp;
          {sample}
        </span>
      );
    }

    return [
      { rowvalue: label, classes: "importFieldname" },
      { rowvalue: inputElement, classes: "mapto" },
      { rowvalue: sample, classes: "importFieldname italic" },
    ];
  };

  renderDetailPanel = () => {
    if (!this.state.data || this.state.data.length === 0) {
      return "";
    }

    const progressStyle = {
      width: `${Math.round((this.state.stats?.progress || 0) * 100)}%`,
    };
    let classnames = "action-button green-button ";
    if (!this.isReadyToImport()) {
      classnames += "save-disabled";
    }
    let importedLabel = "Imported";
    let failedLabel = "Failed";
    if (this.state.imported?.length > 0) {
      let importedData = Papa.unparse(this.state.imported);
      importedData = encodeURIComponent(importedData);
      let encodedUri = `data:text/csv;charset=utf-8,${importedData}`;
      importedLabel = (
        <a href={encodedUri} download="data.csv" className="plainLink">
          Imported
        </a>
      );
    }
    if (this.state.failed?.length > 0) {
      let fields = Object.keys(this.state.failed[0]);
      if (fields.includes("message")) {
        // Move "message" to the front of the list
        fields = ["message"].concat(fields.filter(f => f !== "message"));
      }
      let failedData = Papa.unparse(this.state.failed, { columns: fields });
      failedData = encodeURIComponent(failedData);
      let encodedUri = `data:text/csv;charset=utf-8,${failedData}`;
      failedLabel = (
        <a href={encodedUri} download="data.csv" className="plainLink">
          Failed
        </a>
      );
    }
    let multirequiredNote = "";
    if (this.state.listItems?.some(i => i.multirequired)) {
      multirequiredNote = (
        <div className="importInstructions">
          <span className="footnote">&dagger;</span> One or more of these fields must be mapped.
        </div>
      );
    }
    let importButtonLabel = (this.state.updateExisting ? "Update " : "Import ") + this.props.import?.type;
    if (this.state.cancelImport) {
      importButtonLabel = "Resume " + this.props.import?.type + (this.state.updateExisting ? " Update" : " Import");
    }
    const buttons = this.state.importComplete ? (
      ""
    ) : (
      <div className="importInstructions action-buttons center-buttons">
        <span className="action-button red-button" onClick={this.handleCancel}>
          Cancel
        </span>
        <span className={classnames} onClick={this.handleDoImport}>
          <FontAwesomeIcon icon={faFileImport} /> {importButtonLabel}
        </span>
      </div>
    );
    return (
      <div className="importDetails">
        {this.renderInstructions(multirequiredNote)}
        <div className="importInstructions">
          <div className="progessBorder">
            <div className="progessBar" style={progressStyle}>
              {Math.floor((this.state.stats?.progress || 0) * 100)}%
            </div>
          </div>
        </div>
        {buttons}
        <div className="importInstructions">
          <div>Records: {this.state.data?.length || 0}</div>
          <div>
            {importedLabel}: {this.state.stats?.imported || "0"}
          </div>
          <div>
            {failedLabel}: {this.state.stats?.failed || "0"}
          </div>
        </div>
      </div>
    );
  };

  renderInstructions = multirequiredNote => {
    if (this.state.updateExisting) {
      return this.renderUpdateInstructions();
    } else {
      return this.renderImportInstructions(multirequiredNote);
    }
  };

  renderDecimalNote = () => {
    if ([Constants.CUSTOMERS, Constants.PRODUCTS].includes(this.props.import?.type)) {
      return (
        <div className="importInstructions">
          <span className="footnote">3</span> Percentage fields, such as discount, should be represented in your file as a decimal value. For example,
          10% should be represented as 0.1.
        </div>
      );
    } else {
      return "";
    }
  };

  renderHeadingNote = () => {
    if (this.state.listItems.some(f => f.heading)) {
      return (
        <div className="importInstructions">
          <span className="footnote">2</span> Company-level field(s) will update all contacts associated with the company/family. Setting the same
          field with different values for multiple contacts in the same family/company will cause inconsistent results. Contact-level fields will
          update only the individual contact.
        </div>
      );
    } else {
      return "";
    }
  };

  renderSupplierNote = () => {
    if (this.props.import?.type === Constants.PRODUCTS) {
      return (
        <div className="importInstructions">
          <span className="white">Important note regarding suppliers:</span> If you are importing a product with a supplier, that supplier must
          already exist in the ClerkHound database. Suppliers will be located by name and an exact match is required. If you are importing a product
          with a supplier that does not exist in the ClerkHound database, the import will fail.
        </div>
      );
    } else {
      return "";
    }
  };

  renderImportInstructions = multirequiredNote => {
    let supplierNote = this.renderSupplierNote();
    const decimalNote = this.renderDecimalNote();
    const headingNote = this.renderHeadingNote();

    return (
      <React.Fragment>
        <div className="importInstructions highlight">
          IMPORTANT! This import feature will add new records to the ClerkHound database. It will not update existing records.
        </div>
        <div className="importInstructions">
          For each of the ClerkHound fields on the left, select the field name from your file to import into the {this.props.import?.type} database.
          Required fields are marked with "Please select" in the dropdown. Optional fields are marked with "Optional field" in the dropdown. Please
          note, you are not required to map every field from your source file.
        </div>
        <div className="importInstructions">If a record does not have a value for a required field, the record will fail to import.</div>
        <div className="importInstructions">
          Comma-separated values must be enclosed in double-quotes in the CSV file. For example, the <span className="italic">tags</span> field should
          be formatted as "tag1,tag2,tag3".
        </div>
        {supplierNote}
        <div className="importInstructions">
          After the import is complete, the <span className="italic white">Imported</span> and <span className="italic white">Failed</span> labels
          below will link to a downloadable CSV file containing the records that were successfully imported or failed to import.
        </div>
        <div className="importInstructions">
          The note field will accept any data type. This is a good location to place data from your file that does not map directly to a ClerkHound
          field. For example, if you have a field called "ClerkHound Import Date" (with today's date) in your file, you can map that to the note field
          and it will be imported into the database.
        </div>
        {multirequiredNote}
        <div className="importInstructions">
          <span className="footnote">1</span> True/false fields, such as active and taxable, should be represented in your file with a value of
          "true", "false", "1" (true), or "0" (false). Invalid values in records will be treated as True. You may select a True or False value to
          apply to all records.
        </div>
        {headingNote}
        {decimalNote}
      </React.Fragment>
    );
  };

  renderUpdateInstructions = () => {
    const decimalNote = this.renderDecimalNote();
    const headingNote = this.renderHeadingNote();

    return (
      <React.Fragment>
        <div className="importInstructions highlight">IMPORTANT! This import feature will update existing records to the ClerkHound database.</div>
        <div className="importInstructions">
          For each of the ClerkHound fields on the left, indicate whether or not you want to update the matching {this.props.import?.type} record with
          the values from the file.
        </div>
        <div className="importInstructions">
          Comma-separated values must be enclosed in double-quotes in the CSV file. For example, the <span className="italic">tags</span> field should
          be formatted as "tag1,tag2,tag3".
        </div>
        <div className="importInstructions">
          After the update is complete, the <span className="italic white">Updated</span> and <span className="italic white">Failed</span> labels
          below will link to a downloadable CSV file containing the records that were successfully updated or failed to update.
        </div>
        <div className="importInstructions">
          <span className="footnote">1</span> True/false fields, such as active and taxable, should be represented in your file with a value of
          "true", "false", "1" (true), or "0" (false). Invalid values in records will be treated as True.
        </div>
        {headingNote}
        {decimalNote}
      </React.Fragment>
    );
  };

  renderTopControlButtons = () => {
    return "";
  };

  getListGridClassName = () => {
    return "importlist";
  };

  getListItems = () => {
    if (!this.state.data) {
      return [];
    }
    let items = Helper.getImportFieldList(this.props.import?.type, this.state.updateExisting);
    // If updateExisting is true, then we need to cross-reference the headers from the file with the field names in headerFields
    if (this.state.updateExisting) {
      items = items.map(item => {
        if (!this.props.import?.headerFields?.find(hf => hf.name === item.name)) {
          item.disable = true;
        }
        return item;
      });
    }
    this.setState({ listItems: items });
    return items;
  };

  handleCancel = () => {
    if (this.state.importing) {
      this.setState({ cancelImport: true });
    } else {
      this.props.handleResetImport();
    }
  };

  handleChange = (event, item) => {
    const listItems = this.state.listItems.map(i => {
      if (i.name === item.name) {
        i.match = event.target.value;
      }
      return i;
    });
    this.setState({ listItems });
  };

  isReadyToImport = () => {
    if (this.state.importing) {
      return false;
    }
    if (!this.state.data) {
      return false;
    }
    if (!this.state.listItems) {
      return false;
    }
    if (this.state.updateExisting) {
      const selected = this.state.listItems.filter(f => f.match !== "");
      if (selected.length === 0) {
        return false;
      }
    } else {
      // Check required fields
      const required = this.state.listItems.filter(f => f.required && f.match === "");
      if (required.length > 0) {
        return false;
      }
      // Check multi-value required fields
      if (this.state.listItems.filter(f => f.multirequired).length > 0) {
        const multirequired = this.state.listItems.filter(f => f.multirequired && f.match !== "");
        if (multirequired.length === 0) {
          return false;
        }
      }
    }
    return true;
  };

  buildObject = (record, type) => {
    if (this.state.updateExisting) {
      return this.buildObjectUpdate(record);
    } else {
      return this.buildObjectImport(record, type);
    }
  };

  buildObjectUpdate = record => {
    let data = {};
    if (this.props.import?.type === Constants.PRODUCTS) {
      data.productuuid = record.productuuid;
    } else if ([Constants.CUSTOMERS, Constants.SUPPLIERS].includes(this.props.import?.type)) {
      data.contactuuid = record.contactuuid;
    }
    // Go through each field mapping and build the product
    this.state.listItems.forEach(item => {
      if (item.match !== "") {
        // Format numbers and booleans
        if (item.boolean) {
          data[item.match] = Helper.isTrue(record[item.match]);
        } else if ((item.numeric || item.percent) && record[item.match] !== "" && record[item.match] !== null && record[item.match] !== undefined) {
          data[item.match] = numeral(record[item.match]).value() ?? 0;
        } else if (item.numeric || item.percent) {
          data[item.match] = null;
        } else {
          data[item.match] = record[item.match];
        }
      }
    });

    // Store the raw CSV record data in the object
    data.record = record;

    return data;
  };

  buildObjectImport = (record, type) => {
    let data = {};
    // Go through each field mapping and build the product
    this.state.listItems.forEach(item => {
      // Set boolean values to true or false for all records if "Mark all true" or "Mark all false" is selected
      if (item.boolean === true && [Constants.SET_ALL_TRUE, Constants.SET_ALL_FALSE].includes(item.match)) {
        if (item.match === Constants.SET_ALL_TRUE) {
          data[item.name] = true;
        } else if (item.match === Constants.SET_ALL_FALSE) {
          data[item.name] = false;
        }
      }
      // Numeralize numbers
      else if ((item.numeric || item.percent) && record[item.match] !== "" && record[item.match] !== null && record[item.match] !== undefined) {
        data[item.name] = numeral(record[item.match]).value() ?? 0;
      }
      // Handle blank numeric and percent fields
      else if (item.numeric || item.percent) {
        data[item.name] = null;
      }
      // Copy text values directly for all records if filled in
      else if (item.textfield === true && item.match !== "") {
        data[item.name] = item.match;
      }
      // Everything else gets the value directly copied from the imported record if a field is mapped
      else if (item.match !== "") {
        data[item.name] = record[item.match];
      }
    });

    // Assign a company type to customers and suppliers
    // Translate booleans and numbers, which may have come the source file as strings
    if (type === Constants.CUSTOMERS) {
      data.companytype = Constants.CUSTOMER_FAMILY;
      data.taxable = data.taxable !== undefined && data.taxable !== "" ? Helper.isTrue(data.taxable) : true;
    } else if (type === Constants.SUPPLIERS) {
      data.companytype = Constants.SUPPLIER_COMPANY;
    } else if (type === Constants.PRODUCTS) {
      // Clean up numbers and booleans
      data.sellprice = numeral(data.sellprice).value() ?? 0;
      data.inventory = numeral(data.inventory).value() ?? 0;
      data.taxable = data.taxable !== undefined && data.taxable !== "" ? Helper.isTrue(data.taxable) : true;
      data.affectinventory = data.affectinventory !== undefined && data.affectinventory !== "" ? Helper.isTrue(data.affectinventory) : true;
      data.cost = numeral(data.cost).value() ?? 0;
    }
    // All records have an active flag to set (maybe)
    data.active = data.active !== undefined && data.active !== "" ? Helper.isTrue(data.active) : true;

    // Store the raw CSV record data in the object
    data.record = record;

    return data;
  };

  handleDoImport = () => {
    if (!this.isReadyToImport()) {
      return;
    }
    // Resume import if it was cancelled
    if (!this.state.cancelImport) {
      this.setState(
        {
          cancelImport: false,
          imported: [],
          failed: [],
          stats: { progress: 0, imported: 0, failed: 0 },
          importComplete: false,
        },
        () => {
          this.doImport();
        }
      );
    } else {
      this.setState({ cancelImport: false }, () => {
        this.doImport();
      });
    }
  };

  doImport = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Building " + (this.props.import?.type || "objects") + "...",
    });

    // Build a list of products to send to the API
    let objects = this.state.data.map(record => {
      return this.buildObject(record, this.props.import?.type);
    });

    // Switch to blank overlay
    this.props.hideOverlay();

    // Call the API
    this.setState({ importing: true }, () => {
      if (this.state.updateExisting) {
        if (this.props.import?.type === Constants.PRODUCTS) {
          this.putObjects(objects, Constants.URL_PRODUCTS);
        } else if ([Constants.CUSTOMERS, Constants.SUPPLIERS].includes(this.props.import?.type)) {
          this.putObjects(objects, Constants.URL_CONTACTS);
        }
      } else {
        if (this.props.import?.type === Constants.PRODUCTS) {
          this.postObjects(objects, Helper.getBlankProduct, Constants.URL_PRODUCTS);
        } else if ([Constants.CUSTOMERS, Constants.SUPPLIERS].includes(this.props.import?.type)) {
          const companytype = this.props.import?.type === Constants.CUSTOMERS ? Constants.CUSTOMER_FAMILY : Constants.SUPPLIER_COMPANY;
          this.postObjects(objects, Helper.getBlankContact, Constants.URL_CONTACTS, companytype);
        }
      }
    });
  };

  putObjects = (objects, url) => {
    if (objects.length === 0 || !objects[0]) {
      this.setState({ importing: false, importComplete: true }, () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Update complete",
        });
        return;
      });
      return;
    }
    if (this.state.cancelImport) {
      this.setState({ importing: false }, () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Update cancelled",
        });
        return;
      });
      return;
    }
    // Check if the object already imported
    const object = objects[0];
    if (
      this.state.imported.find(f => f.CLERKHOUND_IMPORT_INDEX === object.record.CLERKHOUND_IMPORT_INDEX) ||
      this.state.failed.find(f => f.CLERKHOUND_IMPORT_INDEX === object.record.CLERKHOUND_IMPORT_INDEX)
    ) {
      console.log("Skipping previously updated object");
      objects.shift();
      this.putObjects(objects, url);
      return;
    }
    const record = object.record;
    Helper.putData(url, object).then(response => {
      let imported = 0;
      let failed = 0;
      if (response.status === 200 && response.body) {
        imported = 1;
      } else {
        failed = 1;
      }
      // Add a message to the record on failure
      if (response.status !== 200) {
        record.message = response.body?.message || "Bad request";
      }
      this.setState(
        prevState => ({
          stats: {
            ...prevState.stats,
            imported: prevState.stats.imported + imported,
            failed: prevState.stats.failed + failed,
            progress: (this.state.imported.length + imported + this.state.failed.length + failed) / this.state.data?.length,
          },
        }),
        () => {
          if (imported === 1) {
            this.setState(prevState => ({
              imported: [...prevState.imported, record],
            }));
          } else if (failed === 1) {
            this.setState(prevState => ({
              failed: [...prevState.failed, record],
            }));
          }
          objects.shift();
          this.putObjects(objects, url);
        }
      );
    });
  };

  postObjects = (objects, getBlank, url, companytype = null) => {
    if (objects.length === 0 || !objects[0]) {
      this.setState({ importing: false, importComplete: true }, () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Import complete",
        });
        return;
      });
      return;
    }
    if (this.state.cancelImport) {
      this.setState({ importing: false }, () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Import cancelled",
        });
        return;
      });
      return;
    }
    // Check if the object already imported
    const object = objects[0];
    if (
      this.state.imported.find(f => f.CLERKHOUND_IMPORT_INDEX === object.record.CLERKHOUND_IMPORT_INDEX) ||
      this.state.failed.find(f => f.CLERKHOUND_IMPORT_INDEX === object.record.CLERKHOUND_IMPORT_INDEX)
    ) {
      console.log("Skipping previously imported object");
      objects.shift();
      this.postObjects(objects, getBlank, url, companytype);
      return;
    }
    const record = object.record;
    let data = {};
    if (companytype !== null) {
      data = { ...getBlank(this.props.appState.salesperson, companytype), ...object };
    } else {
      data = { ...getBlank(this.props.appState.salesperson), ...object };
    }
    Helper.postData(url, data).then(response => {
      let imported = 0;
      let failed = 0;
      if (response.status === 200 && response.body) {
        imported = 1;
      } else {
        failed = 1;
      }
      // Add a message to the record on failure
      if (response.status !== 200) {
        record.message = response.body?.message || "Bad request";
      }
      this.setState(
        prevState => ({
          stats: {
            ...prevState.stats,
            imported: prevState.stats.imported + imported,
            failed: prevState.stats.failed + failed,
            progress: (this.state.imported.length + imported + this.state.failed.length + failed) / this.state.data?.length,
          },
        }),
        () => {
          if (imported === 1) {
            this.setState(prevState => ({
              imported: [...prevState.imported, record],
            }));
          } else if (failed === 1) {
            this.setState(prevState => ({
              failed: [...prevState.failed, record],
            }));
          }
          objects.shift();
          this.postObjects(objects, getBlank, url);
        }
      );
    });
  };
}

export default Import;

import React from "react";
import { handleFocus, renderStatus } from "./Helper.jsx";
import TextareaAutosize from "react-textarea-autosize";
import CircleXmarkIcon from "./img/CircleXmarkIcon.js";
import CirclePlusIcon from "./img/CirclePlusIcon.js";
import * as Constants from "./Constants";
import * as Helper from "./Helper";

class RepairItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.technicianRef = React.createRef();
    this.familyRef = React.createRef();
    this.statusRef = React.createRef();
    this.descriptionRef = React.createRef();
    this.state = {
      collapsed: props.collapsed,
      showselecttechnician: false,
      showselectfamily: false,
      showselectstatus: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render = () => {
    return (
      <React.Fragment>
        <div className="areaRepairItemCard">
          <div className="areaRepairItemInputContainer model">
            <label>Model:</label>
            <input
              name="model"
              id="model"
              data-testid="Service Item Model"
              type="text"
              value={this.props.item.model}
              maxLength={100}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              onFocus={event => {
                handleFocus(event);
                this.setState({ collapsed: false });
              }}
            />
          </div>
          <div
            className="areaRepairItemInputContainer description"
            onClick={() => {
              this.handleSetFocus(this.descriptionRef);
            }}
          >
            <label>Statement of Work:</label>
            <span className="inputAsSpan">{this.props.item.description}</span>
            <TextareaAutosize
              name="description"
              id="description"
              data-testid="Service Item Description"
              ref={this.descriptionRef}
              className="repairItemDescription"
              autoComplete="off"
              rows="4"
              maxLength={5000}
              value={this.props.item.description}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              onFocus={handleFocus}
            />
          </div>

          <div className="areaRepairItemInputContainer serial">
            <label>Serial #:</label>
            <input
              name="serialnumber"
              id="serialnumber"
              data-testid="Service Item Serial Number"
              type="text"
              maxLength={100}
              value={this.props.item.serialnumber}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              onFocus={event => {
                handleFocus(event);
                this.setState({ collapsed: false });
              }}
            />
          </div>
          {this.renderCircleIconsDesktop()}
          {this.renderCollapsibles()}
          {this.renderCircleIconsMobile()}
        </div>
      </React.Fragment>
    );
  };

  renderCollapsibles = () => {
    if (this.state.collapsed) {
      return "";
    }
    let technicianuuid = this.props.item.technician ? this.props.item.technician : "";
    let technicianname = this.props.technicians.find(technician => technician.value === technicianuuid)?.fullname ?? "Please select";
    let techniciannamePrint = this.props.technicians.find(technician => technician.value === technicianuuid)?.fullname ?? "";

    let familyname = this.props.item.family ? this.props.item.family : "Please select";
    let statusname = this.props.item.repairitemstatus;

    return (
      <React.Fragment>
        <div className="areaRepairItemInputContainer technician">
          <label>Technician:</label>
          <span className="popup">
            <input
              name="technician"
              id="technician"
              data-testid="Service Item Technician"
              type="text"
              className="noPrint"
              maxLength={100}
              value={technicianname}
              onChange={() => {}}
              onClick={event => {
                this.setState({ showselecttechnician: true });
              }}
            />
            <span className="printOnly">{techniciannamePrint}</span>
            {this.renderSelectTechnician()}
          </span>
        </div>

        <div className="areaRepairItemInputContainer family">
          <label className="repairlabel"> Family:</label>
          {/* <span className="repairlabel">Family:</span> */}
          <span className="popup">
            <span
              onClick={event => {
                this.setState({ showselectfamily: true });
              }}
            >
              <div data-testid="Service Item Family" className="statusbadge family">
                {familyname}
              </div>
            </span>
            {this.renderSelectFamily()}
          </span>
        </div>

        <div className="areaRepairItemInputContainer status">
          <label className="repairlabel"> Status:</label>

          <span className="popup">
            <span
              data-testid="Service Item Status"
              onClick={event => {
                this.setState({ showselectstatus: true });
              }}
            >
              {renderStatus(statusname)}
            </span>
            {this.renderSelectStatus()}
          </span>
        </div>
      </React.Fragment>
    );
  };

  renderSelectTechnician = () => {
    if (this.state.showselecttechnician) {
      // Copy and sort the technicians list
      let techlist = Helper.deepCopy(this.props.technicians);
      techlist.sort((a, b) => {
        return (a.fullname ?? "").localeCompare(b.fullname ?? "");
      });
      // Render the list
      techlist = techlist.map(technician => {
        return (
          <div
            key={technician.value}
            className="list-item"
            onClick={event => {
              this.props.handleSelectTechnician(technician.value, this.props.index);
              this.setState({ showselecttechnician: false });
            }}
          >
            {technician.fullname ?? "Unknown"}
          </div>
        );
      });
      return (
        <span className="popuplist" ref={this.technicianRef}>
          {techlist}
        </span>
      );
    }
  };

  renderSelectFamily = () => {
    if (this.state.showselectfamily) {
      const familylist = this.props.families.map(family => {
        return (
          <div
            key={family.uuid}
            className="list-item"
            onClick={event => {
              this.props.handleSelectFamily(family.value, this.props.index);
              this.setState({ showselectfamily: false });
            }}
          >
            {family.value}
          </div>
        );
      });

      return (
        <span className="popuplist" ref={this.familyRef}>
          {familylist}
        </span>
      );
    }
  };

  renderSelectStatus = () => {
    if (this.state.showselectstatus) {
      const statuses = [
        { uuid: Constants.ORDER_STATUS_OPEN, statusname: "Open" },
        { uuid: Constants.ORDER_STATUS_DIAGNOSED, statusname: "Diagnosed" },
        { uuid: Constants.ORDER_STATUS_PARTS_ORDERED, statusname: "Parts Ordered" },
        { uuid: Constants.ORDER_STATUS_ON_BENCH, statusname: "On Bench" },
        { uuid: Constants.ORDER_STATUS_CONTACTED, statusname: "Contacted" },
        { uuid: Constants.ORDER_STATUS_CANCELLED, statusname: "Cancelled" },
        { uuid: Constants.ORDER_STATUS_CANCELLED_RETURN, statusname: "Cancelled/Return" },
        { uuid: Constants.ORDER_STATUS_INVOICED, statusname: "Invoiced" },
        // { uuid: Constants.ORDER_STATUS_BACKORDERED, statusname: "Back Ordered" },
      ];

      const statuslist = statuses.map(status => {
        return (
          <div
            key={status.uuid}
            className="list-item "
            onClick={event => {
              this.props.handleSelectStatus(status.uuid, this.props.index);
              this.setState({ showselectstatus: false });
            }}
          >
            {status.statusname}
          </div>
        );
      });

      return (
        <span className="popuplist" ref={this.statusRef}>
          {statuslist}
        </span>
      );
    }
  };

  renderCircleIconsDesktop() {
    if (this.props.appState.currentView === Constants.REPAIR) {
      const add = this.props.showadd ? (
        <span onClick={this.props.handleAdd}>
          <CirclePlusIcon />
        </span>
      ) : (
        <div></div>
      );
      return (
        <React.Fragment>
          <div className="repairdelete desktop">
            <CircleXmarkIcon handleClick={this.props.handleDelete} />
          </div>
          <div className="repairadd desktop">{add}</div>
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  renderCircleIconsMobile() {
    if (this.props.appState.currentView === Constants.REPAIR) {
      const add = this.props.showadd ? <CirclePlusIcon handleClick={this.props.handleAdd} /> : <div></div>;
      return (
        <div className="circle-icons">
          <div className="repairdelete mobile">
            <CircleXmarkIcon handleClick={this.props.handleDelete} />
          </div>
          <div className="repairadd mobile">{add}</div>
        </div>
      );
    } else {
      return "";
    }
  }

  handleSetFocus = ref => {
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  handleClickOutside = event => {
    if (this.technicianRef && this.technicianRef.current && !this.technicianRef.current.contains(event.target)) {
      this.setState({ showselecttechnician: false });
    }
    if (this.familyRef && this.familyRef.current && !this.familyRef.current.contains(event.target)) {
      this.setState({ showselectfamily: false });
    }
    if (this.statusRef && this.statusRef.current && !this.statusRef.current.contains(event.target)) {
      this.setState({ showselectstatus: false });
    }
  };
}

export default RepairItemCard;

import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "../Constants";
import * as Helper from "../Helper";
import React from "react";
import SaveCancelButtons from "../SaveCancelButtons";

class BaseWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widget: { ...props.widget },
      doubleWidth: false,
    };
  }

  render = () => {
    if (this.state.widget?.editing) {
      return this.renderEdit();
    } else {
      return this.renderView();
    }
  };

  renderEdit = () => {
    let saveButtonClass = "action-button green-button";
    if (!this.isReadyToSubmit(this.state.widget)) {
      saveButtonClass += " save-disabled";
    }
    let deleteButton = "";
    if (this.state.widget?.uuid !== "new") {
      deleteButton = (
        <span
          title="Delete Widget Button"
          className="action-button red-button"
          onClick={() => {
            this.props.handleDelete(this.state.widget);
          }}
        >
          Delete
        </span>
      );
    }
    const widgetstyle = {
      borderColor: this.state.widget.widgetcolor,
    };

    let classes = "dashboardWidget";
    if (this.state.doubleWidth) {
      classes += " doubleWidth";
    }

    return (
      <div className={classes} style={widgetstyle}>
        <div className="dbwTitle dbwContent">
          <div className="areaInputItem">
            <input
              type="text"
              id="widgettitle"
              name="widgettitle"
              placeholder="Widget title"
              value={this.state.widget.widgettitle}
              onChange={event => {
                this.handleChange(event, this.state.widget, false);
              }}
            />
          </div>
        </div>
        <hr />
        <div className="dbwContent">
          <div className="areaInputItem">
            <input
              type="color"
              id="widgetcolor"
              name="widgetcolor"
              value={this.state.widget.widgetcolor}
              onChange={event => {
                this.handleChange(event, this.state.widget, false);
              }}
            />
          </div>
          {this.renderWidgetEdit()}
          <div>
            <SaveCancelButtons
              handleCancel={() => {
                this.handleCancel(this.state.widget);
              }}
              handleSave={() => {
                this.handleSave(this.state.widget);
              }}
              saveButtonClass={saveButtonClass}
              datatestidcancel="Widget Cancel"
              datatestidsave="Widget Save"
            />
            {deleteButton}
          </div>
        </div>
      </div>
    );
  };

  renderView = () => {
    const widgetstyle = {
      borderColor: this.props.widget.widgetcolor,
    };
    let classes = "dashboardWidget";
    if (this.state.doubleWidth) {
      classes += " doubleWidth";
    }
    return (
      <div className={classes} style={widgetstyle}>
        <div className="dbwTitle">
          {this.props.widget?.widgettitle}
          <span className="editWidget" title="Edit Widget Button">
            <FontAwesomeIcon
              icon={faPencil}
              onClick={() => {
                this.handleEdit(this.props.widget);
              }}
            />
          </span>
        </div>
        <hr />
        <div data-testid="Widget Content" className="dbwContent">
          {this.renderWidgetView()}
        </div>
      </div>
    );
  };

  handleChange = (event, widget, isConfig = false, callback = null) => {
    const value = event.target.value;
    if (isConfig) {
      this.setState(
        prevState => ({
          widget: {
            ...prevState.widget,
            widgetconfig: { ...prevState.widget.widgetconfig, [event.target.id]: value },
          },
        }),
        () => {
          if (callback) {
            callback();
          }
        }
      );
    } else {
      this.setState(
        prevState => ({
          widget: { ...prevState.widget, [event.target.id]: value },
        }),
        () => {
          if (callback) {
            callback();
          }
        }
      );
    }
  };

  handleEdit = widget => {
    this.setState({ widget: { ...Helper.deepCopy(widget), editing: true } });
  };

  handleCancel = widget => {
    if (widget.uuid !== "new" && widget.widgettype !== Constants.WIDGET_TYPE_ADD) {
      this.setState(prevState => ({
        widget: { ...prevState.widget, editing: false },
      }));
    } else {
      this.props.handleCancel(widget);
    }
  };

  isReadyToSubmit = widget => {
    if (widget?.widgettype === Constants.WIDGET_TYPE_TAG_LIST) {
      return widget?.widgettitle && widget?.widgetconfig?.tag;
    } else if (widget?.widgettype === Constants.WIDGET_TYPE_MESSAGE) {
      return widget?.widgettitle && widget?.widgetconfig?.message;
    } else if (widget?.widgettype === Constants.WIDGET_TYPE_WELCOME) {
      return widget?.widgettitle;
    } else if (widget?.widgettype === Constants.WIDGET_TYPE_CLOCK) {
      return widget?.widgettitle;
    }
    return false;
  };

  handleSave = widget => {
    if (this.isReadyToSubmit(widget)) {
      if (widget.uuid === "new") {
        this.props.postWidget(widget, () => {
          this.setState(prevState => ({
            widget: { ...prevState.widget, editing: false },
          }));
        });
      } else {
        this.props.putWidget(widget, () => {
          this.setState(prevState => ({
            widget: { ...prevState.widget, editing: false },
          }));
        });
      }
    }
  };
}
export default BaseWidget;

function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432 432">
      <g>
        <path
          className="st0"
          d="M234.2,408.8c-6.5,0-11.3-4.5-11.9-5l-0.3-0.3l-0.2-0.3c-3.1-4.1-5.8-6.7-10.2-7.5c-1.9-0.3-3.6-1.1-5.1-2.3
		c-1.2-1-2.3-2.2-3.3-3.7c-1.5-2.4-2.3-5.1-3-7.5l-0.1-0.2c-2-6.7-4.7-10.9-8.8-14c-1.3-0.9-2.4-2-3.3-3.1l-2.4-2.9l0.4,3.8
		c0.3,3.3,0.4,6.3,0.2,9.2c-0.4,6.5-1.9,10.6-4.8,13.8c-2.8,3-6.5,4.7-11.3,6.8c-0.8,0.4-1.6,0.7-2.4,1.1c-3.7,1.7-7.5,2.5-11.5,2.5
		c-4.5,0-8.5-1-12.4-2c-3.1-0.8-6-1.5-8.8-1.7c-1.9-0.1-3.7-0.6-5.4-1.6c-1.5-0.9-2.9-2-4.1-3.5c-3.9-4.7-5.5-12.2-4.4-19.9
		c0.6-4.5,2.5-8.5,4.2-12c0.9-2,1.8-3.8,2.4-5.6l0.6-1.8l-1.9,0.3c-6.5,1.1-8.2,3.6-8.8,7.1c-1.4,8-7.5,12.2-11.9,14.7
		c-2.9,1.6-5.1,3.4-6.6,5.3c-1.6,2-2.6,4.4-3.1,7.2c-0.6,4.2-2.4,7.5-5.3,9.8c-2.6,2.1-6,3.3-10.2,3.6c-1.5,0.1-3.1,0.2-4.6,0.2
		c-6.5,0-13.3-1-20.5-3.2c-4.9-1.4-8.9-4.2-12.2-8.4c-2.7-3.4-4.6-7.3-6.4-11.1c-0.4-0.9-0.9-1.8-1.3-2.7c-0.9-1.8-1.2-3.7-0.8-5.6
		c0.3-1.5,0.9-3,2-4.4c1.3-1.7,3.2-3.3,5.8-4.9c3.6-2.2,7.6-3.3,11.3-4.3l0.6-0.2c2.6-0.7,4.7-1.9,6.8-3.8c2.1-1.9,4-4.2,6.2-6.9
		l0.9-1.1c4.9-6,8-12.8,11.2-20.1c3.3-7.4,6.7-15.1,12.3-21.9c11.5-14.1,20.2-26.6,26.7-38.2c5.6-9.9,9.2-22.2,10-33.8
		c0.6-9.2,4.5-16.1,8.7-22.3l0.7-1.1c3.7-5.6,7.6-11.3,10.5-17.3c3.2-6.6,4.9-12.5,5.3-18.6c0.2-3.7,1.2-7.2,3.1-10.8
		c1.4-2.8,3.4-5.6,6-8.7c2.7-3.2,5-6.4,6.7-9.7c1.8-3.3,3.1-6.6,3.9-10c0.8-3.4,1.2-7,1.1-10.6c-0.1-3.7-0.8-7.5-2-11.4
		c-0.7-2.2-0.9-4.4-0.5-6.3c0.4-2.5,1.6-4.6,3.6-6.1c2.3-1.8,3.8-3.9,4.9-6.7c0.9-2.4,1.4-5.2,1.7-9.1c0.5-6.5,1.9-12.6,4.2-18.2
		c2.1-5.2,5.1-10.2,8.8-14.8c3.4-4.2,7.3-8.1,12.1-12c4-3.3,8.7-6.5,14.2-10c0.5-0.3,3.2-1.9,7.2-1.9c2.4,0,4.7,0.5,7,1.6
		c6.2,2.9,11.6,6.5,15.9,10.8c4.4,4.3,7.8,9.2,10.2,14.7c4.8,10.9,5.4,23.9,2,38.6c-0.8,3.3-1.3,5.8-1.6,7.6c-0.1,1.2-0.1,2,0.1,2.4
		c0.2,0.5,0.8,1.1,1.8,1.8c1.5,1,3.6,2.2,6.7,4c10,5.6,18.3,11.6,25.5,18.5c1.4,1.3,2.6,1.9,4.2,1.9c0.8,0,1.7-0.1,2.8-0.4
		c12.3-3.1,23.1-4.6,33.2-4.6c6.1,0,12,0.6,17.7,1.7c4.6,0.9,8.5,2.1,11.9,3.6c3.8,1.6,6.9,3.7,9.3,6.1c2.6,2.5,4.3,5.4,5.3,8.5
		c1,3.2,1.1,6.6,0.4,10.2c-0.7,3.4-2.2,6.9-4.4,10.4c-2.3,3.7-5.1,7.2-8.1,10.4c-2.8,3-6.1,5.9-9.5,8.5c-6.3,4.7-13.6,8.7-22.2,12.1
		c-4.5,1.8-7.5,3.5-9.5,6.7c-1.9,3.1-4.6,5.2-8.2,6.5c-2.8,1-5.8,1.3-8.5,1.6c0,0-0.8,0.1-1,0.1l-1.6,0.2l0.9,1.6
		c4.1,7.3,4.6,15.6,4.8,22.8c0.3,11.6,1.9,23.1,3.5,33.8c1.7,11.5,1.4,23.5-0.8,36.7c-2.1,12.5-5.7,24.6-9,34.8
		c-1.7,5.3-2.4,9.9-2,14c0.4,4.3,1.9,8.4,4.6,12.5c3,4.5,4.7,8.5,5.3,12.4c0.4,2.5,0.3,4.9-0.3,7.2c-0.6,2.5-1.9,4.9-3.7,7
		c-3.2,3.9-7.1,6.9-11.4,8.8c-4.3,1.9-9.2,2.9-14.6,2.9l-0.4,0c-5.5,0-10.3-1.6-14.8-3c-3.2-1-6.1-2-9.2-2.4
		c-1.9-0.3-3.7-0.9-5.3-1.9c-0.9-0.6-1.7-1.3-2.5-2.1l-1.3-1.5l-0.6,1.9c-0.3,0.9-0.6,1.9-1,2.8c-1.2,2.9-2.9,5.6-5,7.9
		C241.3,407.9,237,408.8,234.2,408.8z"
        />
        <path
          className="st1"
          d="M381.7,140.1c-1-3.3-2.9-6.4-5.6-9c-2.5-2.5-5.8-4.6-9.7-6.3l0,0c-3.5-1.5-7.4-2.7-12.1-3.6l0,0
		c-5.7-1.1-11.8-1.7-17.9-1.7c-10.1,0-21.1,1.5-33.4,4.7c-1,0.3-1.9,0.4-2.6,0.4c-1.1,0-2-0.3-3.4-1.6l0,0
		c-7.3-7-15.7-13.1-25.7-18.7c-3-1.7-5.1-2.9-6.5-3.9c-1.3-0.9-1.4-1.3-1.4-1.3c0,0-0.2-0.4,0-1.9c0.2-1.7,0.8-4.2,1.5-7.3l0-0.1
		c3.5-15,2.8-28.2-2.1-39.3c-2.5-5.6-6-10.7-10.5-15.1c-4.4-4.3-9.9-8-16.2-11c-2.4-1.2-4.9-1.7-7.5-1.7c-4.2,0-7.1,1.6-7.8,2.1
		c-5.6,3.5-10.2,6.8-14.3,10c-4.9,3.9-8.9,7.9-12.3,12.1c-3.8,4.7-6.8,9.8-9,15.1c-2.3,5.7-3.8,12-4.3,18.5l0,0
		c-0.3,3.8-0.8,6.5-1.6,8.8c-1,2.6-2.4,4.6-4.5,6.2c-2.1,1.7-3.5,4-4,6.8c-0.4,2.1-0.2,4.4,0.6,6.8c1.2,3.8,1.8,7.5,2,11.1
		c0.1,3.5-0.2,6.9-1,10.3c-0.8,3.3-2.1,6.6-3.8,9.8c-1.7,3.2-3.9,6.4-6.6,9.5l0,0c-2.7,3.1-4.7,6-6.1,8.9c-1.9,3.7-3,7.4-3.2,11.2
		c-0.4,6-2,11.7-5.2,18.1c-2.9,5.9-6.7,11.6-10.5,17.2l-0.7,1.1c-4.2,6.3-8.2,13.5-8.9,22.9c-0.8,11.5-4.4,23.6-9.8,33.4
		c-6.5,11.6-15.2,24-26.6,38.1c-5.7,7-9.1,14.7-12.4,22.2c-3.2,7.2-6.2,14-11,19.8l0,0c-0.3,0.4-0.6,0.7-0.9,1.1
		c-2.2,2.7-4,4.9-6.1,6.7c-2,1.8-4,2.9-6.4,3.5l-0.6,0.2c-3.7,1-7.9,2.2-11.5,4.4c-2.7,1.6-4.7,3.3-6.1,5.2
		c-1.2,1.5-1.9,3.2-2.2,4.9c-0.4,2.1-0.1,4.3,0.9,6.3c0.4,0.9,0.8,1.7,1.3,2.6c1.8,3.8,3.7,7.8,6.5,11.3c3.5,4.4,7.6,7.2,12.7,8.7
		c7.4,2.2,14.2,3.2,20.9,3.2c1.6,0,3.1-0.1,4.7-0.2c4.4-0.3,8.1-1.6,10.8-3.9c3.1-2.5,5-6.1,5.7-10.5c0.4-2.6,1.3-4.8,2.8-6.7
		c1.4-1.8,3.5-3.4,6.3-5c4.6-2.6,11-7,12.5-15.5c0.6-3.2,2.1-5.3,7.9-6.2c-0.6,1.8-1.5,3.6-2.4,5.5c-1.7,3.6-3.6,7.6-4.3,12.3
		c-1.1,8,0.6,15.7,4.7,20.7c1.3,1.6,2.8,2.8,4.4,3.8c1.8,1,3.8,1.6,5.8,1.7l0,0c2.8,0.2,5.6,0.9,8.6,1.6c4,1,8.1,2,12.7,2
		c4.2,0,8.1-0.9,12-2.6c0.8-0.4,1.6-0.7,2.4-1.1c4.9-2.2,8.7-3.9,11.6-7.1c3.1-3.4,4.6-7.7,5.1-14.5c0.2-3.2,0.1-6.3-0.2-9.4
		c1,1.2,2.1,2.3,3.5,3.3c4,2.9,6.5,6.9,8.4,13.4l0.1,0.2c0.7,2.5,1.6,5.3,3.1,7.8c1,1.6,2.2,3,3.5,4c1.6,1.3,3.5,2.1,5.6,2.5l0,0
		c4,0.7,6.5,3.1,9.5,7l0.3,0.4l0.3,0.3c0.6,0.5,5.7,5.3,12.7,5.3c3.1,0,7.6-1,11.8-5.6l0,0c2.2-2.4,3.9-5.2,5.2-8.2
		c0.4-0.9,0.8-1.9,1.1-2.9c0.8,0.9,1.7,1.7,2.7,2.3c1.7,1.1,3.6,1.8,5.7,2.1l0,0c2.9,0.4,5.9,1.3,9,2.3c4.7,1.5,9.5,3,15.2,3.1
		c0.1,0,0.3,0,0.4,0c5.6,0,10.7-1,15.1-3c4.5-2,8.5-5.1,11.8-9.1c1.9-2.3,3.2-4.8,3.9-7.5c0.6-2.4,0.7-5,0.3-7.7
		c-0.6-4-2.4-8.2-5.5-12.8c-2.6-3.9-4.1-7.9-4.4-12c-0.3-4,0.3-8.4,2-13.6c3.3-10.2,6.9-22.4,9-34.9c2.3-13.3,2.5-25.5,0.8-37
		c-1.6-10.7-3.1-22.2-3.5-33.7c-0.2-7.3-0.8-15.8-4.9-23.3c0-0.1-0.1-0.1-0.1-0.2c0.2,0,0.4-0.1,0.6-0.1l0.4,0
		c2.7-0.3,5.8-0.7,8.7-1.7c3.8-1.4,6.8-3.7,8.7-7c1.9-3,4.7-4.6,9-6.3c8.8-3.5,16.1-7.5,22.5-12.2c3.5-2.6,6.8-5.5,9.7-8.6
		c3.1-3.3,5.8-6.8,8.2-10.6c2.3-3.6,3.8-7.3,4.5-10.8C382.8,147.1,382.7,143.5,381.7,140.1z M372.8,158.7
		c-9,14.4-22.4,23.2-37.7,29.3c-4.7,1.9-8.9,4-11.6,8.6c-3.1,5-8.5,5.5-13.7,6.1c-5.5,0.6-8.1,2.5-4.7,8.5
		c3.5,6.4,4.1,13.7,4.3,20.8c0.3,11.6,1.9,22.9,3.5,34.3c3.5,24-2.5,47-9.7,69.4c-3.6,11.3-3.3,20.7,3.1,30.4
		c4.5,6.7,7.4,14.4,1.6,21.4c-5.7,6.9-13.2,10.2-23,10.1c-8.5-0.1-15.4-4.3-23.4-5.3c-7.6-1-9.8-10.6-6.6-21.4
		c0.9-3,3.8-7.2,0.4-8.9c-3.5-1.8-7.9,0.8-11.6,2.7c-2.8,1.4-1,3.4-0.1,4.8c7,10.9,5.3,23.3-1.8,31.3c-7.7,8.4-16.5,0-16.5,0
		c-3.3-4.4-6.9-8.1-12.9-9.2c-5.2-0.9-6.7-6.2-8-10.6c-1.9-6.5-4.8-12.1-10.5-16.3c-4.2-3.1-5.7-7.2-3.3-12.3c0.7-1.5,3-3.2,0.1-4.8
		c-3.3-1.8-6.9-1.7-10.4-0.4c-2.4,0.9-1.8,2.9-1.4,4.9c1.7,7.5,3.3,15,2.8,22.8c-0.8,12.1-5.1,13-15.9,17.9
		c-11.6,5.3-20.6-0.9-30.7-1.5c-7.2-0.4-11.2-9.9-9.8-19.9c0.9-6.1,4.5-11.2,6.5-16.9c2.5-7.2,1.5-8.4-5.9-7.3
		c-6.5,1-11.5,3.6-12.8,10.8c-1,5.7-5.1,9-9.7,11.6c-6.3,3.5-10.8,8.2-11.9,15.7c-0.9,6.3-5,9.2-11.4,9.7
		c-8.1,0.6-15.8-0.6-23.5-2.8c-10-2.9-13.2-11.9-17.1-19.8c-1.9-3.9,2.1-7.2,5.3-9.1c3.2-1.9,7-3,10.7-4c7.4-2.1,11.2-7.2,16.1-13.2
		c10.5-12.8,13.1-29.2,23.5-42c9.9-12.2,19.6-25.4,27.2-38.9c5.7-10,9.7-23,10.5-35.7c0.5-7.6,3.6-13.7,7.9-20.1
		c8-11.8,16.3-23.9,17.3-39.1c0.4-6.5,3.8-11.9,8-16.8c11.5-13.5,15.9-28.6,10.6-45.8c-0.9-3-0.7-5.9,1.5-7.6
		c6.3-4.9,7.7-11.8,8.2-19c1.8-24.9,17.8-39.4,37.3-51.5c0,0,1.9-1.2,4.8-1.2c1.5,0,3.2,0.3,5.1,1.2c23.2,10.9,32.2,31.4,25.8,59.1
		c-3.2,13.6-3.2,13.7,9.1,20.6c8.9,5,17.2,10.8,24.6,17.8c3.3,3.2,6.7,3.7,11.2,2.6c16.1-4.1,32.4-6.2,48.9-2.9
		c3.7,0.7,7.5,1.7,11,3.3C377.5,135.7,380.6,146.4,372.8,158.7z"
        />
        <g>
          <path
            className="st1"
            d="M228,356.7c-2.6-3.3-6.5-3.7-10.5-4c-3.7,0.4-7.3,1.5-10.7,3.4c-2.5,1.4-4.1,2.8-4.4,4.4
			c-0.4,1.6,0.6,3.3,3.3,5.3c4.4,3.2,5.1,8,5.3,13.1c0.2,4.3,1.6,7.9,7.2,5.4c4.5-2,5.1,1.7,6.3,4.3c1,2.1,0.9,5.3,4.3,5
			c1-0.1,1.9-0.3,2.9-0.6c0.7-0.2,1.4-0.4,2-0.7c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0,0,0.1,0c2.7-1.3,5-3.4,6.6-6.1
			c0.9-1.4,1.6-2.8,2-4.2c0.6-2.4,0.3-4.9-1.5-7.3C236.8,368.5,232.5,362.6,228,356.7z"
          />
          <path
            className="st1"
            d="M349.6,134.3c-15.6-6.2-29.2,1.8-42.9,4.7c-7.1,1.5-11.1,0.7-15.8-3.5c-12.1-10.7-23.8-22.1-40.3-26.2
			c-3.4-0.8-4.8-2.5-4.3-5.4c0.2-0.9,0.5-2,1.1-3.2c1.6-3.5,2.8-7.1,3.6-10.7c2.4-10.8,1-21.7-3.1-32.5c-0.3-0.8-0.7-1.6-1-2.4
			c-0.1-0.2-0.2-0.3-0.2-0.5c-1.3-2.7-2.8-5.2-4.9-7.3c-7.8-8.9-14.4-8.9-18.5-7.5c-0.8,0.2-1.6,0.6-2.6,1.3
			c-0.4,0.2-0.7,0.5-1.1,0.8c-0.2,0.2-0.5,0.3-0.7,0.5c-12.4,9.6-20.4,21.4-22.9,35.6c-0.8,4.7-1,9.8-0.5,15.1
			c0.2,1.8,0.2,3.3,0,4.6c-0.4,2.1-1.7,3.7-4.8,4.9c-2.3,0.9-4,2-4.2,3.3c-0.3,1.3,0.8,2.7,4.1,4c1.8,0.7,3.3,1.9,3.1,3.3
			c-0.1,0.6-0.6,1.3-1.5,2c-3.2,2.4-4.4,5.4-5,8.7c-0.3,2-0.4,4-0.5,6.1c0,0.1,0,0.2,0,0.4c-0.3,4.4-1,8.6-2.3,12.7
			c-1.1,3.4-2.7,6.6-4.6,9.6c-0.3,0.4-0.5,0.8-0.8,1.2c-3.7,5.3-7.4,10.6-8.7,16.8c-0.6,2.8-0.7,5.8,0,9c0.1,0.4,0.1,0.7,0,1.1
			c-0.2,1-0.9,1.7-1.6,2.5c-9.8,10.7-14.5,24.5-21.8,36.6c-2.4,4-3.9,8.3-4.7,12.7c-0.8,4.5-0.8,9.1,0.1,13.6c0.2,1.1,1,2.8,0.8,3.9
			c-0.1,0.5-0.5,0.9-1.2,1.1c-3.1,0.8-4.5,2.3-5.2,4.2c-0.9,2.4-0.5,5.3-0.7,7.8c-1.7,18.4-1.6,36.6,2.8,54.8
			c3,12.5,5.5,25.1,2.3,37.3c-0.3,1-0.6,2-0.9,3c0,0,0,0,0,0c-1,3.1-2.5,6.1-4.4,9.1c0,0-0.1,0.1-0.1,0.2c-0.6,1-4.2,7.7,3.2,9
			c0,0.2-0.1,0.3-0.1,0.5c-0.2,0.9-0.5,1.8-0.6,2.6c0,0,0,0.1,0,0.1c0,0.3-0.1,0.6-0.1,0.9c-0.2,1.9-0.5,3.8,2,4.4
			c0.1,0,0.1,0.1,0.1,0.1c3,1.4,7.6,2.7,8.6-1c0.5-1.8-0.4,1.3,1.7-7.7c1.2,4.3-2,12.2,6.9,11.5c3.6-0.3,6.6-1.2,7.6-4.1
			c0.5-1.3,0.6-3,0.2-5.3c3,2,2.2,5.8,5,5.2c1.2-0.3,2.2-2.2,2.9-3.8c0.1-0.2,0.2-0.5,0.3-0.7c1.4-3.3,2-6.6,1.8-9.9
			c-0.1-2-0.4-3.9-1-5.8c-0.1-0.4-0.2-0.8-0.3-1.2c-1.9-5.8-2.5-11.7-1.6-17.9c2.2-14.9,2.3-29.4-8.8-41.7c-0.9-1-2.3-2.5-1.7-3.9
			c0.2-0.5,0.6-0.9,1.3-1.3c2.6-1.5,11.8,3.3,12.8,6.4c7.7,23.7,21.8,33,46.6,30.3c4.9-0.5,9.8-1.4,14.7-2.1
			c12.6-1.8,21.3-7.2,24.5-19c0.5-1.7,0.8-3.5,1-5.4c0-0.4,0.1-0.8,0.2-1.2c0.1-0.3,0.1-0.6,0.2-1c0,0,0-0.1,0-0.1
			c1.4-5,5.8-8.1,12.6-10.6c-3.3,5.1-5.5,10.2-6.8,15.3c-3,11.2-2,22.4-0.7,33.7c0.2,2.2-0.1,4.5-0.6,6.6c-0.3,1.2-0.8,2.5-1.2,3.7
			c-1.5,4-2.9,8-3.9,12.1c-0.6,2.4-0.9,4.9-1.1,7.4l-0.1,0c0,0-0.4,7.4,0.9,9.2l0,0c0.8,1.7,2.7,3.6,6.9,3.8c0,0,2.6,0.3,3.4-1.5
			c2.1,3.3,7.4,9.8,11.1,2.2c0.9,1.2,2.1,2.1,3.5,2.7c3.2,1.3,6.8,0.3,9-2.3c1.1-2,1.9-4.7,0-7.2c1.6,1,2.7,2.6,3.8,4
			c0.1,0.1,0.2,0.2,0.3,0.3c1.8,1.4,3.2,0.4,3.5-1.1c0,0,0,0,0,0c0,0,0,0,0,0c1.7-6.8-1.2-12.5-5.2-17.5c-4.8-5.8-5.8-11.9-3.9-19.2
			c3.2-12.5,5.9-25.1,8.5-37.7c3.3-15.7,5.7-31.6,3.1-48c-4,1.1-7.5,5.4-10.3,2.4c-1-1.1-1.2-2.3-0.8-3.4c0.6-2,2.5-4,3.6-5.7
			c1.9-3,3.2-6,4.1-9.1c2.6-9,0.7-18.1-3.8-27c-2.4-4.8-3.7-9.1-2.3-13.6c0.5-1.5,1.2-3,2.4-4.6c0.1-0.1,0.1-0.3,0.2-0.4
			c1.5-2.2,2.4-4.6,2.9-7.1c0.6-2.7,0.7-5.5,0.3-8.2c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.4c-0.3-2.9-0.3-5.8,0-8.7
			c0.2-2.5,0.7-4.9,1.2-7.4c0.1-0.2,0.1-0.5,0.2-0.7c0.7-3,1.3-6,1.3-9c0-3-0.4-5.9-1.7-8.9c-0.5-1.2-0.7-2.3-0.6-3.3
			c0.2-1.7,1.2-3,3.1-3.3c11.8-2,22.1-10.2,34.9-8.1c1.6,0.4,3.3,0.5,5,0.2c0.2,0,0.3-0.1,0.5-0.1c0,0,0.1,0,0.1,0
			c0.7-0.1,1.4-0.3,2-0.6C348.9,134.7,349.2,134.5,349.6,134.3C349.5,134.3,349.5,134.3,349.6,134.3
			C349.5,134.3,349.5,134.3,349.6,134.3z M272.2,383c0,0,0.1-0.1,0.1-0.1c0.1,0.1,0.2,0.3,0.2,0.4L272.2,383z M272.8,384
			c0-0.1,0-0.3,0-0.5c0.1,0.2,0.2,0.3,0.4,0.5L272.8,384z M250.3,184.2L250.3,184.2c2-1,3.6,0.8,4.5,2.6c4.3,8.8,3.5,17.6-0.4,26.4
			c0.5-8.4-0.9-16.9-4.1-24.7C249.5,187,248.5,185,250.3,184.2z M246.7,165.9L246.7,165.9c-2.9,7.2-5.9,11.1-11.8,9.9
			c-8.4-1.7-16.7-5-25.3,0.1C219.8,163.3,234.6,175.3,246.7,165.9z M205.8,65.9c1.7-3.3,3.8-6.5,6.4-9.6c2.8-3.3,6.2-6.5,10-9.5
			c0.8-0.6,1.7-1.3,2.2-1.6c0.1,0,0.5-0.2,0.9-0.3c0.7-0.2,2.4-0.6,3.6,0c0,0,1.5,0.8,0.9,1.5c-5.4,6.8-15.9,8.9-17.8,18.9
			c-0.5,2.5-6.4,4.7-6.9,4.3C204.7,69.2,205.2,67.4,205.8,65.9z M232.7,88.6c-6.7,7.3-10.4,15.7-19.1,19.2c-2.1,0.9-4.6,2.5-7,0.5
			c-1.8-1.4-1.3-3.4-0.9-5.3c0.6-3.3,3-3.6,5.6-3.2c4,0.6,5-2.5,6.7-4.9C223,88.1,224,87.6,232.7,88.6z M198.9,171.2L198.9,171.2
			c2.8-3.2,4.2-6.1-0.5-8.4c-7.6-3.8-4.4-10.4-4.2-16c0.2-3.9,4.4-3.9,7.2-3.8c5.7,0.2,11.4,0.6,13.2,8.2c1.3,5.6,6.7,7.6,12,10.5
			c-14.6-2.5-21.7,7.3-29.6,16.2c-4.6,5.2-8.2,3.9-10.2-2.9c-0.6-2-0.9-4.2-1.3-6.3C189.9,172.1,192.6,178.5,198.9,171.2z
			 M285,231.1L285,231.1c-11,17.7-27.7,25.6-47.9,26.4c-1,0-1.9,0-2.9-0.2c-5.4-0.8-9-4.8-9.5-11.4c0-2.7-0.4-5.5-1-8.1
			c-3.4-11.6-13.8-17.2-25.6-14.4c-3.9,0.9-4.1,3.8-3.1,6.2c2.3,5.5,4.5,11.3,11.9,11.9c1.9,0.2,3.7,0.9,5.3,1.8
			c1.7,0.9,3.3,2.4,2.3,4.6c-0.9,1.9-2.8,1-4.4,1.1c-14.2,0.8-21.3-9-25.1-18.4c-1.1-2.7-1.8-5.3-4.9-5.9
			c-13.1-2.7-13.9-12.9-14.8-24.4c3.8,2.7,3.8,5.9,4.8,8.7c3.2,8.9,6.5,11,16.1,10.1c7.9-0.7,15.6-3.3,23.7-2.7
			c3.6,0.3,5.2-2.2,5.1-5.1c-0.2-5.9,1.3-11.8-0.8-17.8c-2.5-7.1,0.7-11.1,8.1-11.5c10.8-0.6,22.3,6.7,24.6,15.5
			c2.2,8.5-1,13.8-9.6,15.6c-3.8,0.8-7.7,1.1-11.3,2.2c-6.1,1.9-8,6.1-3.8,11.3c4.9,6,8.1,12,7.3,20.1c-0.5,3.1,1.7,5.9,4.7,6.4
			c0.1,0,0.1,0,0.2,0c1.2,0.2,2.5,0.1,3.7-0.3c8.5-3.1,17.1-5.3,25.8-7.8c5.2-1.5,7.4-7,8.7-12.3c0.9-3.6,2.5-6.5,6.2-8
			c1.9-0.8,3.8-2.9,5.8-0.5C286.5,226.1,286.6,229,285,231.1z M274.2,210.5L274.2,210.5c-3.3,0-7.9-2-7.8,3.8c0,4.9,4.6,3.4,7.4,4.1
			c1.1,0.2,2.1,0.4,4.2,0.7c-5.5,4.1-11,2.9-15.2,4.9c-4.7,2.2-1.2,9.6-6.7,12.9c-2.3-5.8-0.6-11.1,1.8-15.6
			c7.1-13.3,8-26.6,0.6-39.9c-1.7-3.2-0.5-4.3,2.1-5.4c3-1.2,5.7-3.1,11.8-1.9c-3.2,2.2-5.4,3.3-7,5c-2.3,2.5-2.7,6.3-0.9,9.2
			c2.1,4.3,5.2,1.3,7.9,1c8.1-1,13.5,2.4,13.9,8.9C286.7,205.5,282,210.4,274.2,210.5z M294.6,197.5c-0.5,0.7-0.9,1.3-1.3,2
			c-0.7,0.8-1.4,1.5-2.2,2.1v0c5.7-10.7,3.8-19.4-7.5-26.4c5.6-1.8,10.1-2.2,13.3,1c-0.2,3.2-0.2,6.2,0.1,9.3l0,0
			C297.6,190.5,296.8,194.5,294.6,197.5z"
          />
          <path
            className="st1"
            d="M129.1,308.6C129.1,308.6,129.1,308.6,129.1,308.6c-1.6-7.9-3.6-15.6-5.4-23.4c-0.3-1-0.9-1.5-1.4-1.4
			c-0.1,0-0.3,0.1-0.5,0.5c-7.1,16-23.2,25.5-29.7,41.3c-3.9,9.5-9.1,17.9-13.8,26.8c-3.6,6.9-8.6,10-15.7,11.5
			c-5.3,1.1-10.8,2.2-14.5,6.5c-0.3,0.3-0.6,0.7-0.9,1.1c-0.1,0.1-0.1,0.5,0.1,1c1.1,2.1,4.2,1.7,4.3,1.7c-0.2,1-0.3,1.9-0.2,2.6
			c0.3,3.2,3.3,3.7,6.2,3.7c5.6-0.1,10.4,7.3,16.5,1.5c0.2-0.2,1.4,0.5,2,0.9c7.5,5.1,9.5,4.5,13-4.1c2.4-5.9,2.5-12.8,7.6-17.6
			c0,0,0.1-0.1,0.1,0c0.7,0.2,1,0.7,1.1,1.2c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0.1,0.8-0.1,1.7,0,2.3c0,0,0,0.1,0,0.1
			c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0c0.2,0.5,0.7,0.8,1.7,0.7c2.5-0.2,4.3-2.5,5.2-4.8c0.5-1,0.7-2.1,0.8-3.2
			c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0-0.3c-1.6-11.1,3.3-18.4,12.1-24.9c4.7-3.5,10.5-11.4,11.1-15.7
			C129.7,312.4,129.1,308.6,129.1,308.6z"
          />
        </g>
      </g>
    </svg>
  );
}
export default render;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";

class FlexInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isediting: false };
    // creates cursor focus on first name
    this.inputRef = React.createRef();
    this.textAreaRef = React.createRef();
  }

  render = () => {
    if (this.state.isediting) {
      const classes = this.props.inline ? " displayInline" : "";
      return (
        <React.Fragment>
          <span className={"desktop" + classes}>{this.renderInput(this.props)}</span>
          <span className={"mobile" + classes}>{this.props.textarea ? this.renderTextArea(this.props) : this.renderInput(this.props)}</span>
        </React.Fragment>
      );
    } else {
      let pencil = "";
      let onClick = () => {};
      let onTouchStart = () => {};
      if (this.props.pencilEdit && !this.props.disabled) {
        pencil = <FontAwesomeIcon icon={faPencil} onClick={this.handleClick} className="editPencil" />;
      } else if (!this.props.disabled) {
        onClick = event => {
          this.handleClick(event);
        };
        onTouchStart = event => this.handleTouchStart(event);
      }
      const renderedText = this.props.renderedValue ?? this.props.value ?? this.props.placeholder ?? "";
      const hasValue = this.props.value || this.props.value === 0;
      let flexDivClassName = "flexInputDiv " + (hasValue ? "" : "flexPlaceholder");
      if (this.props.inline) {
        flexDivClassName += " displayInline";
      }
      return (
        <div className={flexDivClassName} onClick={onClick} onTouchStart={onTouchStart} data-testid={this.props.datatestid}>
          {renderedText} {pencil}
        </div>
      );
    }
  };

  renderTextArea = () => {
    return (
      <TextareaAutosize
        name={this.props.name}
        id={this.props.id}
        ref={this.textAreaRef}
        data-testid={this.props.datatestid}
        autoFocus
        autoComplete={this.props.autoComplete}
        rows={this.props.rows}
        maxLength={this.props.maxLength}
        onChange={this.props.onChange ?? (() => {})} /* prevent error if onChange is not passed in */
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onClick={event => {
          if (event && event.stopPropagation) {
            // Do not toggle item selection when clicking inside the input
            event.stopPropagation();
          }
        }}
        value={this.props.value}
      />
    );
  };

  renderInput = () => {
    return (
      <input
        type={this.props.type}
        name={this.props.name}
        id={this.props.id}
        data-testid={this.props.datatestid}
        maxLength={this.props.maxLength}
        ref={this.inputRef}
        autoFocus
        autoComplete={this.props.autoComplete}
        onChange={this.props.onChange ?? (() => {})} /* prevent error if onChange is not passed in */
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onClick={event => {
          if (event && event.stopPropagation) {
            // Do not toggle item selection when clicking inside the input
            event.stopPropagation();
          }
        }}
        value={this.props.value}
      />
    );
  };

  handleClick = event => {
    if (event && event.stopPropagation) {
      // Do not toggle item selection when toggling edit mode
      event.stopPropagation();
    }
    this.setState({ isediting: true });
  };

  handleFocus = event => {
    event.target.select();
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  handleBlur = event => {
    this.setState({ isediting: false }, () => {
      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    });
  };

  handleTouchStart = event => {
    this.handleClick(event);
  };
}

export default FlexInput;

import React from "react";

import BaseWidget from "./BaseWidget";
import * as Helper from "../Helper";

class ClockWidget extends BaseWidget {
  constructor(props) {
    super(props);

    // const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.state = {
      // timezone: this.props.timezone || currentTimeZone,
      currentTime: Helper.formatTime(new Date()),
      currentDate: Helper.getHumanDateString(),
      widget: { ...props.widget },
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => {
      this.setState({
        currentTime: Helper.formatTime(new Date()),
        currentDate: Helper.getHumanDateString(),
      });
    }, 1000); // Update every 10 seconds
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  renderWidgetEdit = () => {
    // const options = Intl.supportedValuesOf("timeZone").map(timeZone => {
    //   return (
    //     <option key={timeZone} value={timeZone}>
    //       {timeZone}
    //     </option>
    //   );
    // });
    // return (
    //   <div>
    //     <label>Time Zone</label>
    //     <select
    //       id="timezone"
    //       name="timezone"
    //       value={this.state.widget.widgetconfig.timezone}
    //       onChange={event => {
    //         this.handleChange(event, this.state.widget, true);
    //       }}
    //     >
    //       {options}
    //     </select>
    //   </div>
    // );
    return "";
  };

  renderWidgetView = () => {
    return (
      <div>
        <div className="dbwClock">{this.state.currentTime}</div>
        <div className="dbwDate">{this.state.currentDate}</div>
      </div>
    );
  };
}
export default ClockWidget;

import React from "react";
import FaqAnswer from "./FaqAnswer";

function FaqSection(props) {
  if (!props.category) {
    return "";
  }
  return (
    <div className="faqSectionContainer">
      <div className="faqSection">
        <div className="faqCategories">
          {props.category.questions.map((question, index) => (
            <FaqAnswer
              question={question.question}
              answer={question.answer}
              key={props.category.title + "question" + index}
              data-key={props.category.title + "question" + index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FaqSection;

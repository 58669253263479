import * as Constants from "./Constants";
import * as Helper from "./Helper";
import BaseDetailViewComponent from "./BaseDetailViewComponent";

class Campaign extends BaseDetailViewComponent {
  constructor(props) {
    super(props);
    if (this.props.selectedItem === null) {
      this.state = {
        ...this.state,
        campaign: Helper.getBlankCampaign(""),
      };
    }
  }

  getOrderType = () => {
    return Constants.CAMPAIGN;
  };

  handleDelete = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete?",
      callback: this.maybeDeleteCampaign,
      key: this.state.campaign.campaignuuid,
    });
  };

  maybeDeleteCampaign = (response, campaignuuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteCampaign(campaignuuid);
    }
  };

  deleteCampaign = campaignuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CAMPAIGNS;
    const params = { campaignuuid: campaignuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        //Update state to turn off downloading
        this.setState({ downloading: false, error: null });
        //Hide overlay after database action is complete
        this.props.hideOverlay();
        //Return to the List screen
        this.props.followBreadcrumb();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the campaign.",
          });
        });
      }
    });
  };
}
export default Campaign;

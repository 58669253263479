import React from "react";

// Dynamic imports
// https://www.npmjs.com/package/babel-plugin-dynamic-import-node
import * as ApiBase from "./ApiBase";

// Components
import Autocomplete from "./Autocomplete";
import BillingList from "./BillingList";
import BillingPlan from "./BillingPlan";
import BillingsIcon from "./img/BillingsIcon";
import BillingSubscription from "./BillingSubscription";
import Campaign from "./Campaign.jsx";
import CampaignList from "./CampaignList.jsx";
import ChangePassword from "./ChangePassword";
import ClientPage from "./ClientPage";
import CreateAccount from "./CreateAccount";
import Customer from "./Customer";
import CustomerList from "./CustomerList";
import CustomersIcon from "./img/CustomersIcon";
import Dashboard from "./Dashboard";
import ErrorBoundary from "./ErrorBoundary";
import Faq from "./Faq";
import GearMenu from "./GearMenu";
import Import from "./Import";
import Invoice from "./Invoice";
import InvoiceList from "./InvoiceList";
import InvoicesIcon from "./img/InvoicesIcon";
import ListItemView from "./ListItemView";
import Login from "./Login";
import LogoIcon from "./img/LogoIcon";
import MessageList from "./MessageList";
import MessagesIcon from "./img/MessagesIcon";
import NavButtons from "./NavButtons";
import Order from "./Order";
import OrderList from "./OrderList";
import OrdersIcon from "./img/OrdersIcon";
import Pay from "./Pay";
import Product from "./Product";
import ProductList from "./ProductList";
import ProductsIcon from "./img/ProductsIcon";
import Prospect from "./Prospect.jsx";
import Purchase from "./Purchase";
import PurchaseList from "./PurchaseList";
import PurchasesIcon from "./img/PurchasesIcon";
import Quote from "./Quote";
import QuoteList from "./QuoteList";
import QuotesIcon from "./img/QuotesIcon.js";
import Repair from "./Repair";
import RepairList from "./RepairList";
import RepairsIcon from "./img/RepairsIcon.js";
import Report from "./Report";
import ReportList from "./ReportList";
import ReportsIcon from "./img/ReportsIcon";
import SearchBar from "./SearchBar";
import Settings from "./Settings";
import Supplier from "./Supplier";
import SupplierList from "./SupplierList";
import SuppliersIcon from "./img/SuppliersIcon";
import TimesheetList from "./TimesheetList";
import TimesheetsIcon from "./img/TimesheetsIcon";
import WheelMenu from "./WheelMenu";

// Functions
import * as Helper from "./Helper";
import numeral from "numeral";
import TextareaAutosize from "react-textarea-autosize";
import Papa from "papaparse";

// Constants
import * as Constants from "./Constants";

// Style
import "./css/globals.css";
import "./css/app.css";
import "./css/desktop.css";
import "./css/areas.css";
import "./css/grids.css";
import "./css/hide.css";
import "./css/printpage.css";
import "./css/printreceipt.css";
import "./css/printmedia.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars, faEnvelope, faGear, faLock, faPaw, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import QRCodeOverlay from "./QRCodeOverlay.jsx";
import Monitoring from "./Monitoring.jsx";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.listUpdateCallback = null;
    this.contactSearchInput = React.createRef();
    this.productSearchInput = React.createRef();
    this.formRef = React.createRef();
    this.overlayDisabled = false;
    this.inputboxRef = React.createRef();
    this.importFileInputRef = React.createRef();

    let isBillingHound = window.location.hostname.endsWith("billinghound.com") && window.location.hostname.split(".").length === 3;
    let hasSubdomainParam =
      ["localhost", "app-test.clerkhound.com"].includes(window.location.hostname) && window.location.search.includes("subdomain");

    // Check for query string parameters
    const urlParams = new URLSearchParams(window.location.search);
    let action = urlParams.get("action");
    const resetuuid = urlParams.get("reset");
    let block = urlParams.get("block");
    let complaint = urlParams.get("complaint");
    const source = urlParams.get("source");
    const puuid = urlParams.get("puuid");
    const prospectuuid = urlParams.get("prospectuuid");
    const contactuuid = urlParams.get("contactuuid");
    const clientuuid = urlParams.get("clientuuid");
    // If an action is provided but not block or complaint, then set the appropriate values
    if (action && !block && !complaint) {
      if (action === Constants.MESSAGE_DO_NOT_EMAIL || action === Constants.MESSAGE_DO_NOT_EMAIL_EXTERNAL) {
        block = true;
        if (prospectuuid && action === Constants.MESSAGE_DO_NOT_EMAIL) {
          action = Constants.MESSAGE_DO_NOT_EMAIL_EXTERNAL;
        }
      } else if (action === Constants.MESSAGE_ABUSE || action === Constants.MESSAGE_ABUSE_EXTERNAL) {
        complaint = true;
        if (prospectuuid && action === Constants.MESSAGE_ABUSE) {
          action = Constants.MESSAGE_ABUSE_EXTERNAL;
        }
      }
    }
    // If an action was not provided for the complaint or block, then set the action to the appropriate value
    if (block && !action) {
      action = source === Constants.PROSPECT ? Constants.MESSAGE_DO_NOT_EMAIL_EXTERNAL : Constants.MESSAGE_DO_NOT_EMAIL;
    }
    if (complaint && !action) {
      action = source === Constants.PROSPECT ? Constants.MESSAGE_ABUSE_EXTERNAL : Constants.MESSAGE_ABUSE;
    }

    let colorMode = sessionStorage.getItem(Constants.LOCAL_STORAGE_COLOR_MODE);
    if (isBillingHound || hasSubdomainParam) {
      colorMode = Constants.COLOR_MODE_LIGHT;
    } else if (!colorMode || !Constants.COLOR_MODES.includes(colorMode)) {
      colorMode = Constants.COLOR_MODE_DARK;
    }
    if (puuid || block || complaint) {
      colorMode = Constants.COLOR_MODE_LIGHT;
    }
    if (colorMode === Constants.COLOR_MODE_LIGHT) {
      document.getElementById("bodyElement").classList.add("light");
    }
    sessionStorage.setItem(Constants.LOCAL_STORAGE_COLOR_MODE, colorMode);

    // If the calculated color mode does not match the stored color mode, then update the stored color mode
    // This will have the side effect of switching the logged in user's color mode to the calculated color mode
    // but at least the Maast card tokenization fields will display correctly
    let stateColorMode = sessionStorage.getItem(Constants.SESSION_STORAGE_STATE);
    if (stateColorMode) {
      let fullState = JSON.parse(stateColorMode);
      let mode = fullState.colorMode;
      if (mode !== colorMode) {
        fullState.colorMode = colorMode;
        sessionStorage.setItem(Constants.SESSION_STORAGE_STATE, JSON.stringify(fullState));
      }
    }

    // currentMenu is the wheel menu selection
    // whereas currentView is the page being shown
    this.state = {
      action: action,
      block: block,
      breadcrumbs: [],
      clientuuid: clientuuid,
      colorMode: colorMode,
      complaint: complaint,
      contactuuid: contactuuid,
      currentMenu: Constants.DASHBOARD,
      currentView: Constants.DASHBOARD,
      error: null,
      import: { file: null, type: Constants.CUSTOMERS },
      isLoggedIn: false,
      loggedInError: false,
      overlay: null,
      page: 1,
      prospectuuid: prospectuuid,
      puuid: puuid,
      resetuuid: resetuuid,
      searchkey: "",
      showTestLabel: true,
      uploadsUrl: null,
      userActionError: false,
      userActionSuccess: false,
      userMessage: "",
      waiting: false,
    };
  }

  componentDidMount() {
    const state = sessionStorage.getItem(Constants.SESSION_STORAGE_STATE);
    if (state) {
      const savedState = JSON.parse(state);
      savedState.breadcrumbs.pop();
      // Remove "overlay" from saved state
      delete savedState.overlay;
      delete savedState.puuid;
      this.setState(savedState);
    }
    document.addEventListener(Constants.EVENT_KEYDOWN, this.handleKeyDown);
    // window.addEventListener(Constants.EVENT_RESIZE, this.handleResize);

    const session = localStorage.getItem(Constants.LOCAL_STORAGE_SESSION);
    if (session) {
      // this.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Validating session..." }, () => {
      // Call checkLogin() after showing overlay
      this.checkLogin();
      // });
    }
  }

  componentWillUnmount() {
    document.removeEventListener(Constants.EVENT_KEYDOWN, this.handleKeyDown);
    // window.removeEventListener(Constants.EVENT_RESIZE, this.handleResize);
  }

  render = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const action = this.state.action;
    const resetuuid = this.state.resetuuid;
    const block = this.state.block;
    const complaint = this.state.complaint;
    const puuid = this.state.puuid;

    if (urlParams.get("monitoring") !== null) {
      return <Monitoring getClientList={this.getClientList} />;
    }

    let testBadge = "";
    if (window.location.href.indexOf("://app.clerkhound.com") === -1 && this.state.showTestLabel === true) {
      testBadge = (
        <span className="testBadge" onClick={this.handleClickTestLabel}>
          Test Region
        </span>
      );
    }
    // Web browser tab title:
    const storename = this.state.clientSettings?.NAME || "";
    let title = storename + " " + this.state.currentMenu;
    // Check for gerunds
    if (title.endsWith("Billings")) {
      title = title.replace("Billings", "Billing");
    }
    if (this.state.currentView === Constants.SETTING) {
      title = storename + " Settings";
    }
    document.title = title;

    let subdomain = null;
    // Look for billinghound client pages on billinghound.com
    if (window.location.hostname.endsWith("billinghound.com") && window.location.hostname.split(".").length === 3) {
      subdomain = window.location.hostname.split(".")[0];
    }
    // Look for billinghound client pages on localhost/app-test
    if (["localhost", "app-test.clerkhound.com"].includes(window.location.hostname) && urlParams.get("subdomain")) {
      subdomain = urlParams.get("subdomain");
    }
    // If the domain is faq.clerkhound.com or localhost with a subdomain query string, then render the FAQ page
    if (
      window.location.hostname === "faq.clerkhound.com" ||
      (["localhost", "app-test.clerkhound.com"].includes(window.location.hostname) &&
        urlParams.get("subdomain") &&
        urlParams.get("subdomain") === "faq")
    ) {
      document.title = "ClerkHound FAQ";
      return <Faq />;
    }
    // Only show the ChangePassword view if "resetuuid" is present in the query string
    if (resetuuid) {
      // If a password reset is requested, then render reset page
      return (
        <React.Fragment>
          {this.renderOverlay()}
          <ChangePassword
            handlePasswordChange={this.handlePasswordChange}
            showOverlay={this.showOverlay}
            waiting={this.state.waiting}
            resetuuid={resetuuid}
          />
        </React.Fragment>
      );
    } else if (puuid) {
      // TODO: Make this the store name
      document.title = "Loading...";
      return (
        <React.Fragment>
          {this.renderOverlay()}
          <ClientPage subdomain={subdomain} prospectuuid={puuid} showOverlay={this.showOverlay} hideOverlay={this.hideOverlay} />
        </React.Fragment>
      );
    } else if (complaint) {
      document.title = "Report Abuse";
      let message = "";
      let abuseinput = "";
      if (this.state.userActionSuccess) {
        message = "Thank you for reporting abuse. We will investigate this matter.";
      } else if (this.state.userActionError) {
        message = "There was an error reporting abuse. Please try again later.";
      } else {
        abuseinput = (
          <React.Fragment>
            <div className="areaInputItem">
              <textarea
                id="abuse"
                name="abuse"
                rows={5}
                cols={50}
                value={this.state.abuse}
                placeholder="Please describe the abuse of our messaging you experienced..."
                onChange={event => {
                  this.setState({ abuse: event.target.value });
                }}
              ></textarea>
            </div>
            <div>
              <button className="action-button green-button" onClick={() => this.handleReportAbuse(this.state.abuse)}>
                Submit Complaint
              </button>
            </div>
          </React.Fragment>
        );
      }
      return (
        <div className="userActions">
          <h2>Report Abuse</h2>
          {abuseinput}
          {message}
        </div>
      );
    } else if (block) {
      document.title = "Unsubscribe";
      let message = "Click the button below to unsubscribe from all emails.";
      let unsubinput = "";
      if (this.state.userActionSuccess) {
        message = "You have been unsubscribed from our email list.";
      } else if (this.state.userActionError) {
        message = "There was an error unsubscribing you from our email list. Please try again later.";
      } else {
        unsubinput = (
          <React.Fragment>
            <div>
              <button className="action-button green-button" onClick={this.handleUnsubscribe}>
                Unsubscribe Me
              </button>
            </div>
          </React.Fragment>
        );
      }
      return (
        <div className="userActions">
          <h2>Unsubscribe</h2>
          {message}
          {unsubinput}
        </div>
      );
    } else if (action === Constants.CREATE_ACCOUNT) {
      document.title = "Join the ClerkHound family";
      const prospectuuid = urlParams.get("q");
      return (
        <CreateAccount prospectuuid={prospectuuid} showOverlay={this.showOverlay} hideOverlay={this.hideOverlay} colorMode={this.state.colorMode} />
      );
    } else if (action === Constants.ACTION_REQUEST_PAYMENT_METHOD) {
      return (
        <React.Fragment>
          {this.renderOverlay()}
          <ClientPage subdomain={subdomain} requestuuid={urlParams.get("uuid")} showOverlay={this.showOverlay} hideOverlay={this.hideOverlay} />
        </React.Fragment>
      );
    } else if (subdomain) {
      // If a subdomain is present, then render the Client's public page
      const orderuuid = urlParams.get("orderuuid");
      const campaignuuid = urlParams.get("campaignuuid");
      const preview = urlParams.get("preview");
      return (
        <React.Fragment>
          {this.renderOverlay()}
          <ClientPage
            subdomain={subdomain}
            orderuuid={orderuuid}
            campaignuuid={campaignuuid}
            preview={preview}
            puuid={puuid}
            showOverlay={this.showOverlay}
            hideOverlay={this.hideOverlay}
            handleSendCampaignLink={this.handleSendCampaignLink}
          />
        </React.Fragment>
      );
    } else if (this.state.isLoggedIn) {
      const header = this.renderHeader();
      return (
        <div onKeyDown={this.handleKeyDown}>
          {/* Only display this overlay if waiting or prompting user */}
          {this.renderOverlay()}
          {header}
          {this.renderAppBody()}
          {testBadge}
        </div>
      );
    } else {
      document.title = "ClerkHound";
      // If user is not logged in, render Login
      return (
        <React.Fragment>
          {this.renderOverlay()}
          <Login
            error={this.state.loggedInError}
            handleLogin={this.handleLogin}
            handleRecover={this.handleRecover}
            isLoggedIn={this.state.isLoggedIn}
            showOverlay={this.showOverlay}
            waiting={this.state.waiting}
          />
        </React.Fragment>
      );
    }
  };

  renderGlobalMessage = classes => {
    if (this.state.banner?.text) {
      return <div className={classes + " " + (this.state.banner?.type ?? "info")}>{this.state.banner.text}</div>;
    } else {
      return "";
    }
  };

  renderHeader = () => {
    const gearspan = this.renderGear();
    const titlespan = this.renderTitle();
    return (
      <div className="applicationHeader">
        {this.renderGlobalMessage("mobile")}
        <div className="mobile mobile-header">
          <span className="header-icon gridCenter bell">{/* <FontAwesomeIcon icon={faBell} /> */}</span>
          {titlespan}
          {gearspan}
        </div>
        <div className="desktop">
          <div className="header-container">
            {this.renderGlobalMessage("desktop")}
            <NavButtons
              // Pass in the top-level functions that the views may need to invoke
              appState={this.getAppState()}
              checkLogin={this.checkLogin}
              handleMenuClick={this.handleMenuClick}
              handleClearSearch={this.handleClearSearch}
              handleExport={this.handleExport}
              handleGearClick={this.handleGearClick}
              handleLogout={this.handleLogout}
              handleSearchChange={this.handleSearchChange}
              showOverlay={this.showOverlay}
              hideOverlay={this.hideOverlay}
            />
          </div>
        </div>
      </div>
    );
  };

  renderGear = () => {
    if (this.state.currentView === Constants.NAV) {
      const classes = "gear header-icon gridCenter " + (this.state.currentMenu === Constants.SETTINGS ? " current-menu" : "");
      return (
        <span className={classes} id="gear" onClick={this.handleGearClick}>
          <FontAwesomeIcon icon={faGear} />
        </span>
      );
    } else {
      return (
        <span data-testid="Wheel Menu" className="header-icon gridCenter" id="bars" onClick={this.handleBarsClick}>
          <FontAwesomeIcon icon={faBars} />
        </span>
      );
    }
  };

  renderTitle = () => {
    if (this.state.currentView === Constants.NAV) {
      return (
        <span className="nav-logo gridCenter">
          <LogoIcon />
        </span>
      );
    } else if (Constants.BILLING === this.state.currentView) {
      let title = this.state.filtertype?.tab === Constants.TAB_PLANS ? "Plan" : "Subscription";
      if (!this.state.selectedItem) {
        title = "New " + title;
      }
      return <h1 className="mobile-screen-title gridCenter">{title}</h1>;
    } else if (Helper.inList([Constants.CUSTOMER, Constants.PRODUCT, Constants.SUPPLIER], this.state.currentView)) {
      let title = "New " + this.state.currentView;
      if (this.state.selectedItem) {
        title = this.state.currentView;
      }
      return <h1 className="mobile-screen-title gridCenter">{title}</h1>;
    } else if (Constants.REPAIR === this.state.currentView) {
      let title = "Service Request";
      if (!this.state.selectedItem) {
        title = "New " + title;
      }
      if ((this.state.selectedItem && this.state.selectedItem.ordernumber) || (this.state.order && this.state.order.ordernumber)) {
        title = "Service #" + this.state.selectedItem.ordernumber;
      }
      return <h1 className="mobile-screen-title gridCenter">{title}</h1>;
    } else if (Helper.isOrderView(this.state.currentView) && !Helper.isReturn(this.state.selectedItem)) {
      let title = "New " + this.state.currentView;
      if ((this.state.selectedItem && this.state.selectedItem.ordernumber) || (this.state.order && this.state.order.ordernumber)) {
        title = this.state.currentView + " #" + this.state.selectedItem.ordernumber;
      }
      return <h1 className="mobile-screen-title gridCenter">{title}</h1>;
    } else if (Helper.inList([Constants.INVOICE], this.state.currentView)) {
      return <h1 className="mobile-screen-title gridCenter">Return #{this.state.selectedItem.ordernumber}</h1>;
    } else if (Helper.inList([Constants.PAY], this.state.currentView)) {
      return <h1 className="mobile-screen-title gridCenter">Payment</h1>;
    } else if (Helper.inList([Constants.REPORT], this.state.currentView)) {
      return <h1 className="mobile-screen-title gridCenter">Reporting</h1>;
    } else {
      return <span></span>;
    }
  };

  renderOverlay = () => {
    if (this.state.overlay) {
      if (this.state.overlay.type === Constants.OVERLAY_WAITING) {
        return this.renderOverlayBlank();
      } else if (this.state.overlay.type === Constants.OVERLAY_AUTH_PROMPT) {
        return this.renderOverlayAuthPrompt();
      } else if (this.state.overlay.type === Constants.OVERLAY_CHANGE_PASSWORD) {
        return this.renderOverlayChangePassword();
      } else if (this.state.overlay.type === Constants.OVERLAY_MESSAGE) {
        return this.renderOverlayMessage();
      } else if (this.state.overlay.type === Constants.OVERLAY_QUESTION) {
        return this.renderOverlayQuestion();
      } else if (this.state.overlay.type === Constants.OVERLAY_DATE_PICKER) {
        return this.renderOverlayDatePicker();
      } else if (this.state.overlay.type === Constants.OVERLAY_PICKER) {
        return this.renderOverlayPickOrCreate();
      } else if (this.state.overlay.type === Constants.OVERLAY_SEARCH_CONTACTS) {
        return this.renderOverlaySearchContacts();
      } else if (this.state.overlay.type === Constants.OVERLAY_SEARCH_PRODUCTS) {
        return this.renderOverlaySearchProducts();
      } else if (this.state.overlay.type === Constants.OVERLAY_INPUT_BOX) {
        return this.renderOverlayInputBox();
      } else if (this.state.overlay.type === Constants.OVERLAY_IMPORT) {
        return this.renderOverlayImport();
      } else if (this.state.overlay.type === Constants.OVERLAY_PROGRESS) {
        return this.renderOverlayProgress();
      } else if (this.state.overlay.type === Constants.OVERLAY_WHEEL) {
        return this.renderOverlayWheelMenu();
      } else if (this.state.overlay.type === Constants.OVERLAY_GEAR) {
        return this.renderOverlayGearMenu();
      } else if (this.state.overlay.type === Constants.OVERLAY_TOKENIZE && this.state.overlay.loading) {
        return this.renderOverlayProgress();
      } else if (this.state.overlay.type === Constants.OVERLAY_TOKENIZE) {
        return this.renderOverlayTokenize();
      } else if (this.state.overlay.type === Constants.OVERLAY_DATE_FILTER) {
        return this.renderOverlayDateFilter();
      } else if (this.state.overlay.type === Constants.OVERLAY_QR_CODE) {
        return this.renderOverlayQRCode();
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  renderImportOptions = () => {
    let options = ["Please select", Constants.CUSTOMERS];
    if (this.state.features?.includes(Constants.FEATURE_PRODUCTS)) {
      options.push(Constants.PRODUCTS);
      options.push(Constants.SUPPLIERS);
    } else {
      options = [Constants.CUSTOMERS];
    }
    return options.map(option => {
      return (
        <option key={option} value={option} id={option}>
          {option}
        </option>
      );
    });
  };

  renderOverlayGearMenu = () => {
    // Type = Constants.OVERLAY_GEAR will prevent user from clicking on anything other than the gear menu
    // TODO#325 = handleNoInvoiceReturn
    return (
      <div id="overlay" className="overlay" onClick={event => this.maybeHideOverlay(event)}>
        <GearMenu
          appState={this.getAppState()}
          handleChangePassword={this.handleChangePassword}
          handleCheckGiftCardBalance={this.handleCheckGiftCardBalance}
          handleEditMaintenanceMode={this.handleEditMaintenanceMode}
          handleExport={this.handleExport}
          handleInventory={this.handleInventory}
          handleLogout={this.handleLogout}
          handleNoInvoiceReturn={this.handleNoInvoiceReturn}
          handleQAAction={this.handleQAAction}
          handleShowImportPrompt={this.handleShowImportPrompt}
          handleShowSettings={this.handleShowSettings}
          handleSwitchClient={this.handleSwitchClient}
          handleSwitchPeer={this.handleSwitchPeer}
          handleToggleColorMode={this.handleToggleColorMode}
          hideOverlay={this.hideOverlay}
          setGearCloseFunctionCallback={this.setGearCloseFunctionCallback}
          showOverlay={this.showOverlay}
        />
      </div>
    );
  };

  renderOverlayWheelMenu = () => {
    // Type = Constants.OVERLAY_WHEEL will prevent user from clicking on anything other than the wheel menu
    let wheelMenuItems = [];
    let index = 1;
    // Ensure we have the minimum number of menu items
    while (wheelMenuItems.length < Constants.WHEEL_MENU_ITEMS_MINIMUM) {
      wheelMenuItems = wheelMenuItems.concat(Helper.getMenuItems(this.state, this.handleMenuClick, index++));
    }
    wheelMenuItems = [...wheelMenuItems].reverse();
    return (
      <div id="overlay" className="overlay" onClick={event => this.maybeHideOverlay(event)}>
        <WheelMenu
          appState={this.getAppState()}
          currentMenu={this.state.currentMenu}
          handleMenuClick={this.handleMenuClick}
          menuItems={wheelMenuItems}
          hideOverlay={this.hideOverlay}
        />
      </div>
    );
  };

  renderOverlayQRCode = () => {
    // Type = Constants.OVERLAY_QR_CODE will display a QR Code
    return (
      <div id="overlay" className={"overlay progress " + (this.state.overlay?.opaque ? "opaque" : "")}>
        <div className="overlayDialog">
          <div className="progressContainer overlayQRCode">
            <QRCodeOverlay value={this.state.overlay.value} />
            <div className="span2">
              <span className="action-button red-button " onClick={this.hideOverlay}>
                Close
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderOverlayProgress = () => {
    // Type = Constants.OVERLAY_PROGRESS will display the progress of an operation
    const cancel = this.state.overlay.cancelCallback ? (
      <div className="span2">
        <span className="action-button red-button " onClick={this.state.overlay.cancelCallback}>
          {this.state.overlay?.cancelLabel || "Cancel"}
        </span>
      </div>
    ) : (
      ""
    );
    const spinner = this.state.overlay?.spinner || <FontAwesomeIcon icon={faSpinner} spin />;
    return (
      <div id="overlay" className={"overlay progress " + (this.state.overlay?.opaque ? "opaque" : "")}>
        <div className="overlayDialog">
          <div className="progressContainer overlayMessage">
            <div className="progressSpinner">{spinner}</div>
            <div>{this.state.overlay.loading || this.state.overlay.text}</div>
            {cancel}
          </div>
        </div>
      </div>
    );
  };

  renderOverlayImport = () => {
    // Type = Constants.OVERLAY_IMPORT will display the import dialog that will allow user to import data to the database
    const importoptions = this.renderImportOptions();
    let importButtonClassNames = "action-button green-button ";
    if (!this.state.import?.file?.name || !this.state.import?.type) {
      importButtonClassNames += "save-disabled";
    }
    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          <div className="overlayTitle centerAligned">{this.state.overlay.text}</div>
          <div className="gridCenter">
            <label htmlFor="fileUpload" className="action-button brown-button">
              Select File to Import
              <input id="fileUpload" type="file" onChange={this.handleFileChange} ref={this.importFileInput} />
            </label>
          </div>
          <div className="gridCenter labelHeader">{this.state.import?.file?.name ? this.state.import?.file.name : "No file selected"}</div>
          <div className="gridCenter">
            Select file type: &nbsp;
            <select
              name="filetype"
              onChange={event =>
                this.setState(prevState => ({
                  import: { ...prevState.import, type: event.target.value },
                }))
              }
              value={this.state.import?.type || ""}
            >
              {importoptions}
            </select>
          </div>
          <div className="control-buttons controls-two-buttons gridCenter">
            <span className="action-button red-button" onClick={this.hideOverlay}>
              Cancel
            </span>
            <span className={importButtonClassNames} onClick={this.handleConfigureImport}>
              Next
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderOverlayPickOrCreate = () => {
    // Type = OVERLAY_PICK_OR_CREATE will display the overlay-message with a CREATE, YES, and NO buttons
    // The items in overlay.items must have "id" and "text" attributes
    const items = this.state.overlay.items.map((item, idx) => {
      return (
        <option value={item.id} key={idx + "-" + item.id}>
          {item.text}
        </option>
      );
    });
    let readyToSelect = false;
    if (this.state.overlay.id) {
      readyToSelect = true;
    }
    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          <div className="overlayMessage">
            {this.state.overlay.text.split("\n").map(str => (
              <div key={str}>{str}</div>
            ))}
          </div>
          <div className="overlayList">
            <select
              size="5"
              value={this.state.overlay.id}
              onChange={this.handleChangePickOrCreate}
              onDoubleClick={() => this.handlePickerCallback(Constants.OVERLAY_RESPONSE_SELECT, this.state.overlay.id, this.state.overlay.event)}
            >
              {items}
            </select>
          </div>
          <div className="controls-two-buttons control-buttons gridCenter">
            <span
              data-testid="Overlay Cancel Button"
              className="action-button red-button"
              onClick={() => this.handlePickerCallback(Constants.OVERLAY_RESPONSE_CANCEL, null, this.state.overlay.event)}
            >
              Cancel
            </span>
            <span
              data-testid="Overlay Select Button"
              className={"action-button green-button" + (readyToSelect ? "" : " save-disabled")}
              onClick={() => {
                this.handlePickerCallback(Constants.OVERLAY_RESPONSE_SELECT, this.state.overlay.id, this.state.overlay.event);
              }}
            >
              Select
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderOverlayInputBox = () => {
    // Type = Constants.OVERLAY_INPUT_BOX will display in input box with the specified prompt and OK/CANCEL buttons
    // TODO #326: An additional button can be passed in as a widget to be displayed between the OK/CANCEL
    // const overlay = {
    //   type: Constants.OVERLAY_INPUT_BOX,
    //   text: "Gift Card Number",
    //   placeholder: "Scan or enter card number",
    //   maxLength: 255,
    //   key: "{orderitemuuid}",
    //   callback: (response, key, value) => {},
    // };
    // Response is stored in overlay definition: {...overlay, input: "12345"} until callback can be invoked:
    //   this.state.overlay.callback(response, this.state.overlay.key, this.state.overlay.value);
    // Where "response" is OK/CANCEL, key is the key from the definition, and "value" is the user's input.
    const okButtonLabel = this.state.overlay.okButtonLabel || "Ok";
    const icon = this.state.overlay.icon || "";
    const message_div = this.state.overlay.text.split("\n").map(str => <div key={str}>{Helper.renderMarkup(str)}&nbsp;</div>);
    let ok_classes = "action-button green-button ";
    if ((!this.state.overlay.input || this.state.overlay.input.trim().length === 0) && this.state.overlay.required) {
      ok_classes += " save-disabled";
    } else if (this.state.overlay.input_type === "number" && isNaN(this.state.overlay.input)) {
      ok_classes += " save-disabled";
    } else if (this.state.overlay.input_type === "percent" && !Helper.isValidPercent(this.state.overlay.input)) {
      ok_classes += " save-disabled";
    } else if (
      this.state.overlay.input_type === "positivenumber" &&
      !isNaN(this.state.overlay.input) &&
      numeral(this.state.overlay.input).value() <= 0
    ) {
      ok_classes += " save-disabled";
    }
    const title = this.state.overlay.title ? <div className="overlayTitle centerAligned">{this.state.overlay.title}</div> : "";
    let checkbox = "";
    if (this.state.overlay.checkbox) {
      checkbox = (
        <div className="gridCenter">
          <label className="checkboxContainer" htmlFor="overlaycheckbox">
            <span>{this.state.overlay.checkbox}</span>
            <input
              type="checkbox"
              name="overlaycheckbox"
              id="overlaycheckbox"
              data-testid="Input Overlay Checkbox"
              onChange={event => {
                this.setState(prevState => ({
                  overlay: { ...prevState.overlay, checked: !prevState.overlay.checked },
                }));
              }}
              checked={this.state.overlay.checked}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      );
    }

    let input = (
      <TextareaAutosize
        type="text"
        name="inputbox"
        id="inputbox"
        data-testid="Overlay Input Box"
        ref={this.inputboxRef}
        autoFocus={true}
        placeholder={this.state.overlay.placeholder}
        maxLength={this.state.overlay.maxLength}
        autoComplete="off"
        onChange={event => {
          this.handleChangeOverlayInput(event);
        }}
        value={this.state.overlay.input}
      />
    );
    if (this.state.overlay.suggestions?.length > 0) {
      input = (
        <Autocomplete
          type="text"
          name="inputbox"
          id="inputbox"
          datatestid="Overlay Input Box"
          placeholder={this.state.overlay.placeholder}
          autoComplete="off"
          handleChange={event => {
            this.handleChangeOverlayInput(event);
          }}
          value={this.state.overlay.input}
          suggestions={this.state.overlay.suggestions}
        />
      );
    }

    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          {title}
          <div>
            <div className="overlayMessage">
              {icon}
              {message_div}
            </div>
            <div className={this.state.overlay.inputcontainerClass ?? ""}>
              <div className="gridCenter areaInputItem">{input}</div>
            </div>
            {checkbox}
            <div className="controls-two-buttons control-buttons gridCenter">
              <span
                data-testid="Overlay Cancel Button"
                className="action-button red-button"
                onClick={() => this.handleInputCallback(Constants.OVERLAY_RESPONSE_CANCEL)}
              >
                Cancel
              </span>
              <span data-testid="Overlay Ok Button" className={ok_classes} onClick={() => this.handleInputCallback(Constants.OVERLAY_RESPONSE_OK)}>
                {okButtonLabel}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderOverlaySearchContacts = () => {
    // Type = Constants.OVERLAY_SEARCH_CONTACTS will display the overlay-message with a CANCEL button
    // and the search widget passed in

    // Set the placeholder text based on the current view
    let placeholder = "Search Customers";
    if (this.state.currentView === Constants.PURCHASE) {
      placeholder = "Search Suppliers";
    } else if (this.state.currentView === Constants.MESSAGES) {
      placeholder = "Search Contacts";
    }

    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          <div>
            <div className="overlayMessage">
              {this.state.overlay.text.split("\n").map(str => (
                <div key={str}>{str}</div>
              ))}
            </div>
            <div>
              <div className="gridCenter">
                <SearchBar
                  appState={this.state}
                  handleSearchChange={(id, value) => {
                    const logicallyDifferent = value.trim() !== this.state.overlay.searchkey.trim();
                    this.setState(
                      prevState => ({
                        overlay: {
                          ...prevState.overlay,
                          searchkey: value,
                          // Clear the search results if the search key has changed
                          searchresults: logicallyDifferent ? [] : prevState.overlay.searchresults,
                        },
                      }),
                      () => {
                        // If the search key has actually changed, then run a search (after a delay)
                        if (logicallyDifferent) {
                          // Run a search for the specified contact(s)
                          const searchkey = this.state.overlay.searchkey;
                          setTimeout(() => {
                            if (searchkey === this.state.overlay.searchkey) {
                              // Set the companytypes based on the current view
                              let companytypes = Constants.CUSTOMER_COMPANY + "," + Constants.CUSTOMER_FAMILY;
                              if (this.state.currentView === Constants.PURCHASE) {
                                companytypes = String(Constants.SUPPLIER_COMPANY);
                              } else if (this.state.currentView === Constants.MESSAGES) {
                                companytypes = Constants.SUPPLIER_COMPANY + "," + Constants.CUSTOMER_COMPANY + "," + Constants.CUSTOMER_FAMILY;
                              }
                              this.getContactList(this.state.overlay.searchkey, companytypes);
                            }
                          }, Constants.SEARCH_DELAY);
                        }
                      }
                    );
                  }}
                  searchkey={this.state.overlay.searchkey}
                  handleClearSearch={event => {
                    this.setState(prevState => ({
                      overlay: { ...prevState.overlay, searchkey: "", searchresults: [] },
                    }));
                  }}
                  searchPlaceholder={placeholder}
                  fieldref={this.contactSearchInput}
                  autoFocus={true}
                />
              </div>
              <div className="areaContactSearchResults popuplist">
                <ListItemView
                  expandedListItems={null}
                  handleEditItem={() => {}}
                  handleTouchEnd={() => {}}
                  handleTouchStart={() => {}}
                  listitems={this.state.overlay.searchresults}
                  renderItemToColumns={this.renderContactSearchResultsToColumns}
                  selectedListItems={[]}
                  selectListItem={item => {
                    this.state.overlay.callback(Constants.OVERLAY_RESPONSE_SELECT, item);
                  }}
                  toggleCollapsed={() => {}}
                />
              </div>
            </div>
            <div className="controls-one-button control-buttons gridCenter">
              <span className="action-button red-button" onClick={() => this.handleSearchCallback(Constants.OVERLAY_RESPONSE_CANCEL)}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderOverlaySearchProducts = () => {
    // Type = Constants.OVERLAY_SEARCH_PRODUCTS will display the overlay-message with a CANCEL button
    // and the search widget passed in
    let processingMessage = "";
    if (this.state.overlay.searchresults.length === 0 && this.state.overlay.searchkey.length > 0 && !this.state.overlay.processing) {
      processingMessage = <div className="gridCenter overlayMessage">No products found</div>;
    } else if (this.state.overlay.searchresults.length === 0 && this.state.overlay.searchkey.length > 0 && this.state.overlay.processing) {
      processingMessage = <div className="gridCenter overlayMessage">Searching...</div>;
    }

    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          <div>
            <div className="overlayMessage">
              {this.state.overlay.text.split("\n").map(str => (
                <div key={str}>{str}</div>
              ))}
            </div>
            <div>
              <div className="gridCenter">
                <SearchBar
                  appState={this.state}
                  handleSearchChange={(id, value) => {
                    const logicallyDifferent = value.trim() !== this.state.overlay.searchkey.trim();
                    this.setState(
                      prevState => ({
                        overlay: {
                          ...prevState.overlay,
                          searchkey: value,
                          searchresults: logicallyDifferent ? [] : prevState.overlay.searchresults,
                          processing: true,
                        },
                      }),
                      () => {
                        // If the search key has actually changed, then run a search (after a delay)
                        if (logicallyDifferent) {
                          // Run a search for the specified product(s)
                          const searchkey = this.state.overlay.searchkey;
                          setTimeout(() => {
                            if (searchkey === this.state.overlay.searchkey) {
                              this.getProductList(this.state.overlay.searchkey);
                            }
                          }, Constants.SEARCH_DELAY);
                        }
                      }
                    );
                  }}
                  searchkey={this.state.overlay.searchkey}
                  handleClearSearch={event => {
                    this.setState(prevState => ({
                      overlay: { ...prevState.overlay, searchkey: "", searchresults: [] },
                    }));
                  }}
                  searchPlaceholder={"Search Products"}
                  fieldref={this.productSearchInput}
                  autoFocus={true}
                />
              </div>
              {processingMessage}
              <div className="orderproductsearchresults popuplist">
                <ListItemView
                  expandedListItems={null}
                  handleEditItem={() => {}}
                  handleTouchEnd={() => {}}
                  handleTouchStart={() => {}}
                  listitems={this.state.overlay.searchresults}
                  renderItemToColumns={this.renderProductSearchResultsToColumns}
                  selectedListItems={[]}
                  selectListItem={item => {
                    this.state.overlay.callback(Constants.OVERLAY_RESPONSE_SELECT, item);
                  }}
                  toggleCollapsed={() => {}}
                />
              </div>
            </div>
            <div className="controls-one-button control-buttons gridCenter">
              <span className="action-button red-button" onClick={() => this.handleSearchCallback(Constants.OVERLAY_RESPONSE_CANCEL)}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderProductSearchResultsToColumns = item => {
    let productname = item.productname;
    if (item.companyname) {
      productname += " (" + item.companyname + ")";
    }
    if (item.location) {
      productname += " - " + item.location;
    }
    return [
      { rowvalue: productname },
      { rowvalue: item.affectinventory ? item.inventory : "-" },
      {
        rowvalue: item.sellprice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        }),
        classes: "right-aligned",
      },
    ];
  };

  renderContactSearchResultsToColumns = item => {
    let fullname = "";
    let mobileviewphone = "";
    let companyname = "";
    fullname = item.firstname + " " + item.lastname;
    // If this is the messages view, then display the company type icon
    if (this.state.currentView === Constants.MESSAGES) {
      let companytypeicon = "";
      if (item.companytype === Constants.CUSTOMER_COMPANY || item.companytype === Constants.CUSTOMER_FAMILY) {
        companytypeicon = (
          <span className="searchResultIcon">
            <CustomersIcon />
          </span>
        );
      } else if (item.companytype === Constants.SUPPLIER_COMPANY) {
        companytypeicon = (
          <span className="searchResultIcon">
            <SuppliersIcon />
          </span>
        );
      }
      fullname = (
        <div className="gridCenter">
          {companytypeicon}
          {fullname}
        </div>
      );
    }
    // Display mobilephone, then otherphone (if no mobile), then "-" if neither
    mobileviewphone = item.mobilephone ? item.mobilephone : item.otherphone ? item.otherphone : "";
    mobileviewphone = mobileviewphone ? mobileviewphone : item.email;
    companyname = item.companyname ? item.companyname : "";
    return [{ rowvalue: fullname }, { rowvalue: mobileviewphone }, { rowvalue: companyname, classes: "desktop" }];
  };

  renderOverlayAuthPrompt = () => {
    // Type = Constants.OVERLAY_AUTH_PROMPT will prompt for username/password with Auth/CANCEL buttons
    let message = <div className="centerAligned overlayMessage"> </div>;
    if (this.state.overlay?.error) {
      message = <div className="centerAligned overlayError">{this.state.overlay?.error ?? ""}</div>;
    } else if (this.state.overlay?.message) {
      message = <div className="centerAligned overlayMessage">{this.state.overlay?.message ?? ""}</div>;
    }
    let saveButtonClass = "action-button green-button ";
    let cancelButtonClass = "action-button red-button ";
    if (!this.state.overlay.isReadyToSubmit || this.overlayDisabled) {
      saveButtonClass += " save-disabled";
    }
    if (this.overlayDisabled) {
      cancelButtonClass += " save-disabled";
    }
    let eyeball = <FontAwesomeIcon icon={faEyeSlash} />;
    if (this.state.overlay.passtype === "password") {
      eyeball = <FontAwesomeIcon icon={faEye} />;
    }
    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          <div className="centerAligned">
            {this.state.overlay.prompt.split("\n").map(str => (
              <div key={str}>{str}</div>
            ))}
          </div>
          <div className="input-container svgGridCenter">
            <FontAwesomeIcon icon={faEnvelope} />
            <input
              type="text"
              name="user"
              id="user"
              disabled={this.overlayDisabled}
              autoFocus={this.state.overlay.user === ""}
              maxLength={100}
              placeholder="email"
              autoComplete="off"
              onChange={this.handleOverlayAuthChange}
              onKeyDown={this.handleKeyDown}
              required
              value={this.state.overlay.user}
              ref={this.usernameInput}
            />
          </div>
          <div className="input-container svgGridCenter">
            <FontAwesomeIcon icon={faLock} />
            <input
              type={this.state.overlay.passtype}
              name="pass"
              id="pass"
              disabled={this.overlayDisabled}
              autoFocus={this.state.overlay.user !== ""}
              placeholder="password"
              maxLength={50}
              autoComplete="off"
              onChange={this.handleOverlayAuthChange}
              onKeyDown={this.handleKeyDown}
              required
              value={this.state.overlay.pass}
              ref={this.passwordInput}
            />
            <span className="eyeball gridCenter" onClick={this.handleToggleOverlayPassword}>
              {eyeball}
            </span>
          </div>
          {message}
          <div className="controls-two-buttons control-buttons gridCenter">
            <span
              data-testid="Overlay Cancel Button"
              className={cancelButtonClass}
              onClick={() => this.handleAuthPromptCallback(Constants.OVERLAY_RESPONSE_NO)}
            >
              Cancel
            </span>
            <span
              data-testid="Overlay Auth Button"
              className={saveButtonClass}
              onClick={() => {
                if (this.state.overlay.isReadyToSubmit) {
                  this.handleAuthPromptCallback(Constants.OVERLAY_RESPONSE_YES);
                }
              }}
            >
              Auth
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderOverlayChangePassword = () => {
    // Type = Constants.OVERLAY_CHANGE_PASSWORD will prompt for old and new password with OK/CANCEL buttons
    let message = <div className="centerAligned overlayMessage"> </div>;
    if (this.state.overlay?.error) {
      message = <div className="centerAligned overlayError">{this.state.overlay?.error ?? ""}</div>;
    } else if (this.state.overlay?.message) {
      message = <div className="centerAligned overlayMessage">{this.state.overlay?.message ?? ""}</div>;
    }
    let saveButtonClass = "action-button green-button ";
    let cancelButtonClass = "action-button red-button ";
    if (!this.state.overlay.isReadyToSubmit || this.overlayDisabled) {
      saveButtonClass += " save-disabled";
    }
    if (this.overlayDisabled) {
      cancelButtonClass += " save-disabled";
    }
    let eyeball_old = <FontAwesomeIcon icon={faEyeSlash} />;
    let eyeball_new = <FontAwesomeIcon icon={faEyeSlash} />;
    if (this.state.overlay.old_passtype === "password") {
      eyeball_old = <FontAwesomeIcon icon={faEye} />;
    }
    if (this.state.overlay.new_passtype === "password") {
      eyeball_new = <FontAwesomeIcon icon={faEye} />;
    }
    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          <div className="centerAligned">
            {this.state.overlay.prompt.split("\n").map(str => (
              <div key={str}>{str}</div>
            ))}
          </div>
          <div className="input-container svgGridCenter">
            <FontAwesomeIcon icon={faLock} />
            <input
              type={this.state.overlay.old_passtype}
              name="old_password"
              id="old_password"
              disabled={this.overlayDisabled}
              autoFocus
              maxLength={50}
              placeholder="Current password"
              autoComplete="off"
              onChange={this.handleOverlayPassChange}
              onKeyDown={this.handleKeyDown}
              required
              value={this.state.overlay.old_password}
              ref={this.usernameInput}
            />
            <span className="eyeball gridCenter" onClick={this.handleToggleOverlayOldPassword}>
              {eyeball_old}
            </span>
          </div>
          <div className="input-container svgGridCenter">
            <FontAwesomeIcon icon={faLock} />
            <input
              type={this.state.overlay.new_passtype}
              name="new_password"
              id="new_password"
              disabled={this.overlayDisabled}
              placeholder="New password"
              maxLength={50}
              autoComplete="off"
              onChange={this.handleOverlayPassChange}
              onKeyDown={this.handleKeyDown}
              required
              value={this.state.overlay.new_password}
              ref={this.passwordInput}
            />
            <span className="eyeball gridCenter" onClick={this.handleToggleOverlayNewPassword}>
              {eyeball_new}
            </span>
          </div>
          {message}
          <div className="controls-two-buttons control-buttons gridCenter">
            <span
              data-testid="Overlay Cancel Button"
              className={cancelButtonClass}
              onClick={() => this.handlePasswordChangeCallback(Constants.OVERLAY_RESPONSE_NO)}
            >
              Cancel
            </span>
            <span
              data-testid="Overlay Change Button"
              className={saveButtonClass}
              onClick={() => {
                if (this.state.overlay.isReadyToSubmit) {
                  this.handlePasswordChangeCallback(Constants.OVERLAY_RESPONSE_YES);
                }
              }}
            >
              Change
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderOverlayTokenize = () => {
    // Type = Constants.OVERLAY_TOKENIZE will prompt for credit card information to tokenize a card
    let message = <div className="centerAligned overlayMessage"> </div>;
    if (this.state.overlay?.error) {
      message = <div className="centerAligned overlayError">{this.state.overlay?.error ?? ""}</div>;
    } else if (this.state.overlay?.message) {
      message = <div className="centerAligned overlayMessage">{this.state.overlay?.message ?? ""}</div>;
    }
    let saveButtonClass = "action-button green-button ";
    let cancelButtonClass = "action-button red-button ";
    if (this.overlayDisabled || !this.state.overlay.isReadyToSubmit) {
      saveButtonClass += " save-disabled";
    }
    if (this.overlayDisabled) {
      cancelButtonClass += " save-disabled";
    }
    let embeddedFields = this.state.overlay.tokenize ? (
      <React.Fragment>
        <div id="card_number" className={this.state.embeddedFieldCardNumber ? "" : "errorBorder"}></div>
        <div id="exp_date" className={this.state.embeddedFieldExpiration ? "" : "errorBorder"}></div>
        <div id="cvv2" className={this.state.embeddedFieldCVV ? "" : "errorBorder"}></div>
      </React.Fragment>
    ) : (
      <input
        type="text"
        name="exp_date"
        id="exp_date"
        placeholder="Expires [MM|YY]"
        maxLength={5}
        className="exp_date"
        autoComplete="off"
        value={this.state.overlay.exp_date}
        onChange={event => {
          this.handleChangeBillingExp(event, "exp_date");
        }}
      />
    );
    // If this is a new payment method, we need to tokenize the card.
    // If this is an edit payment method, we just need to update the card but not tokenize it.
    let handleOK = this.state.overlay.tokenize
      ? () => {
          this.handleSubmitTokenize();
        }
      : () => {
          this.state.overlay.callback(
            Constants.OVERLAY_RESPONSE_OK,
            this.state.overlay.billing_first,
            this.state.overlay.billing_last,
            this.state.overlay.billing_zip,
            this.state.overlay.card_id,
            this.state.overlay.exp_date,
            this.state.overlay.card_number
          );
        };

    const recaptchaMessage = this.state.overlay.recaptcha ? (
      <div className="recaptchaDisclaimer centerAligned">
        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    ) : (
      ""
    );

    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          <div className="overlayMessage">{this.state.overlay.text}</div>
          <form className="paymentForm" id="paymentform" method="post" action="/" ref={this.formRef}>
            <input
              type="text"
              name="billing_first"
              id="billing_first"
              placeholder="First Name"
              maxLength={32}
              className="billing_first"
              autoComplete="off"
              value={this.state.overlay.billing_first}
              onChange={event => {
                this.handleChangeBilling(event, "billing_first");
              }}
            />
            <input
              type="text"
              name="billing_last"
              id="billing_last"
              placeholder="Last Name"
              maxLength={32}
              className="billing_last"
              autoComplete="off"
              value={this.state.overlay.billing_last}
              onChange={event => {
                this.handleChangeBilling(event, "billing_last");
              }}
            />
            <input
              type="text"
              name="avs-zip"
              id="avs-zip"
              placeholder="Zip Code"
              maxLength={10}
              className="avs-zip"
              autoComplete="off"
              value={this.state.overlay.billing_zip}
              onChange={event => {
                this.handleChangeBillingZip(event, "billing_zip");
              }}
              onBlur={event => {
                // Remove trailing dashes
                let zip = event.target.value.replace(/-+$/, "");
                this.setState({ overlay: { ...this.state.overlay, billing_zip: zip } });
              }}
            />
            {embeddedFields}
            {message}
            <div className="controls-two-buttons control-buttons gridCenter">
              <span
                data-testid="Overlay Cancel Button"
                className={cancelButtonClass}
                onClick={() => {
                  this.state.overlay.callback(Constants.OVERLAY_RESPONSE_CANCEL);
                  this.hideOverlay();
                }}
              >
                Cancel
              </span>
              <span data-testid="Overlay Save Button" className={saveButtonClass} onClick={handleOK}>
                {this.state.overlay.saveButtonLabel || "Save"}
              </span>
            </div>
            {recaptchaMessage}
          </form>
        </div>
      </div>
    );
  };

  renderFilterOnDropdown = () => {
    // Invoices-Processed: closedate, creationdate, lastupdated
    // Invoices-All:       closedate, creationdate, lastupdated

    // Repairs:            closedate, creationdate, duedate, lastupdated

    // Invoices-Open:      creationdate, lastupdated
    // Orders:             creationdate, lastupdated
    // Quotes:             creationdate, lastupdated

    // Purchases:         closedate, creationdate, lastupdated

    const closedate =
      [Constants.INVOICES, Constants.REPAIRS, Constants.PURCHASES].includes(this.state.currentView) &&
      [Constants.ORDER_STATUS_PROCESSED, Constants.ORDER_STATUS_ALL].includes(this.state.filtertype.tab) ? (
        <option value={Constants.DATE_FILTER_CLOSED}>Close Date</option>
      ) : (
        ""
      );
    const duedate = this.state.currentView === Constants.REPAIRS ? <option value={Constants.DATE_FILTER_DUE_DATE}>Due Date</option> : "";
    const lastupdated = <option value={Constants.DATE_FILTER_UPDATED}>Last Updated Date</option>;
    const creationdate = <option value={Constants.DATE_FILTER_CREATION}>Creation Date</option>;
    return (
      <select
        name="filteron"
        id="filteron"
        onChange={event => {
          this.setState(prevState => ({
            overlay: {
              ...prevState.overlay,
              dateFilterOn: event.target.value,
            },
          }));
        }}
        value={this.state.overlay.dateFilterOn}
      >
        {closedate}
        {creationdate}
        {duedate}
        {lastupdated}
      </select>
    );
  };

  renderOverlayDateFilter = () => {
    // Type = Constants.OVERLAY_DATE_FILTER will prompt to apply or remove a date filter
    let applyButtonClass = "action-button green-button ";
    let removeButtonClass = "action-button red-button ";
    let cancelButtonClass = "action-button brown-button ";
    let filterLabel = Helper.renderFilterLabel(this.state.overlay.dateFilterType);
    const filterListClasses = this.state.overlay.showFilterOptions ? " open " : "";

    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          <div className="gridCenter">
            <div className="overlayTitle centerAligned dateFilterTitle">Choose your filter options</div>
            <div className="dateFilterGrid marginBottom1em">
              <label htmlFor="filter-on" className="dateFilterType">
                Filter On:
              </label>
              {this.renderFilterOnDropdown()}
              <label htmlFor="filter-list" className="dateFilterType">
                Filter by:
              </label>
              <div
                className=" dateFilterFilterType filterReport"
                title="Select a filter by option"
                onClick={() => {
                  this.setState(prevState => ({
                    overlay: {
                      ...prevState.overlay,
                      showFilterOptions: !prevState.overlay.showFilterOptions,
                    },
                  }));
                }}
              >
                {filterLabel} <FontAwesomeIcon icon={faAngleDown} />
                <div id="filter-list" ref={this.filterOptionsRef} className={filterListClasses}>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_ALL)}>All</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_TODAY)}>Today</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_YESTERDAY)}>Yesterday</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_THIS_WEEK)}>This Week</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_THIS_MONTH)}>This Month</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_THIS_QUARTER)}>This Quarter</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_THIS_YEAR)}>This Year</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_LAST_WEEK)}>Last Week</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_LAST_MONTH)}>Last Month</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_LAST_QUARTER)}>Last Quarter</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_LAST_YEAR)}>Last Year</div>
                  <div onClick={() => this.handleFilterOverlaySelection(Constants.FILTER_DATE_CUSTOM)}>Custom</div>
                </div>
              </div>

              <label htmlFor="start" className="dateFilterType">
                Begin Date:
              </label>
              <input type="date" id="start" onChange={this.handleOrderFilterChangeStart} value={this.state.overlay.start} />
              <label htmlFor="end" className="dateFilterType">
                End Date:
              </label>
              <input type="date" id="end" onChange={this.handleOrderFilterChangeEnd} value={this.state.overlay.end} />
            </div>
            <div className="dateFilterButtons controls-three-buttons control-buttons">
              <span
                className={removeButtonClass}
                title="Remove the filter from the List"
                onClick={() => {
                  this.setState(
                    prevState => ({
                      filtertype: {
                        ...prevState.filtertype,
                        orderDateFilter: {
                          ...prevState.filtertype.orderDateFilter,
                          dateFilterType: Constants.FILTER_DATE_ALL,
                          start: null,
                          end: null,
                          filterOn: null,
                        },
                      },
                    }),
                    () => {
                      this.state.overlay.callback(Constants.OVERLAY_RESPONSE_REMOVE);
                      this.hideOverlay();
                    }
                  );
                }}
              >
                Remove
              </span>
              <span
                className={cancelButtonClass}
                title="Close Filter Selection Window"
                onClick={() => {
                  this.hideOverlay();
                }}
              >
                Cancel
              </span>
              <span
                className={applyButtonClass}
                title="Apply Filter Selection"
                onClick={() => {
                  this.setState(
                    prevState => ({
                      filtertype: {
                        ...prevState.filtertype,
                        orderDateFilter: {
                          ...prevState.filtertype.orderDateFilter,
                          dateFilterType: this.state.overlay.dateFilterType,
                          start: this.state.overlay.start,
                          end: this.state.overlay.end,
                          filterOn: this.state.overlay.dateFilterOn,
                        },
                      },
                    }),
                    () => {
                      this.state.overlay.callback(Constants.OVERLAY_RESPONSE_APPLY);
                      this.hideOverlay();
                    }
                  );
                }}
              >
                Apply
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderOverlayDatePicker = () => {
    // Type = Constants.OVERLAY_DATE_PICKER will display the overlay-date-picker with OK and CANCEL buttons
    let title = "";
    if (this.state.overlay.title) {
      title = <div className="overlayTitle centerAligned">{this.state.overlay.title}</div>;
    }
    const textClasses = this.state.overlay?.textClasses ?? "";
    let yesButtonClasses = "action-button green-button ";
    let text = this.state.overlay.text
      .trim()
      .split("\n")
      .map((str, index) => (
        <div key={index} className={textClasses}>
          {str}
        </div>
      ));

    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          {title}
          <div className="overlayMessage">{text}</div>
          <div className="gridCenter">
            <input
              type="datetime-local"
              id="pickdate"
              onChange={event => {
                this.setState(prevState => ({
                  overlay: {
                    ...prevState.overlay,
                    date: event.target.value,
                  },
                }));
              }}
              value={this.state.overlay.date}
              autoFocus={true}
            />
          </div>
          <div className="controls-two-buttons control-buttons gridCenter">
            <span
              data-testid="Overlay Cancel Button"
              className="action-button red-button"
              onClick={() => this.handleDatePickerCallback(Constants.OVERLAY_RESPONSE_CANCEL)}
            >
              {this.state.overlay.cancel ?? "Cancel"}
            </span>
            <span
              data-testid="Overlay Ok Button"
              className={yesButtonClasses}
              onClick={() => this.handleDatePickerCallback(Constants.OVERLAY_RESPONSE_OK)}
            >
              {this.state.overlay.ok ?? "Ok"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderOverlayQuestion = () => {
    // Type = Constants.OVERLAY_QUESTION will display the overlay-message with an YES and NO button
    let title = "";
    let bullets = "";
    if (this.state.overlay.title) {
      title = <div className="overlayTitle centerAligned">{this.state.overlay.title}</div>;
    }
    const textClasses = this.state.overlay?.textClasses ?? "";
    let yesButtonClasses = "action-button green-button ";
    let text = this.state.overlay.text
      .trim()
      .split("\n")
      .map((str, index) => (
        <div key={index} className={textClasses}>
          {str}
        </div>
      ));
    if (this.state.overlay.bullets) {
      bullets = (
        <ul>
          {this.state.overlay.bullets
            .trim()
            .split("\n")
            .map(str => (
              <li key={str}>{str}</li>
            ))}
        </ul>
      );
    }
    let confirmationTextElement = "";
    if (this.state.overlay.confirmationText) {
      // If confirmation text is required and does not match, then disable the YES button
      if (this.state.overlay.confirmationText !== this.state.overlay.input) {
        yesButtonClasses += " save-disabled";
      }
      confirmationTextElement = (
        <div>
          <div className="gridCenter areaInputItem">
            <TextareaAutosize
              type="text"
              name="confirmationText"
              id="confirmationText"
              ref={this.inputboxRef}
              autoFocus={true}
              placeholder={this.state.overlay.placeholder}
              maxLength={this.state.overlay.maxLength}
              autoComplete="off"
              onChange={event => {
                this.handleChangeOverlayInput(event);
              }}
              value={this.state.overlay.input}
            />
          </div>
        </div>
      );
    }

    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          {title}
          <div className="overlayMessage">{text}</div>
          {bullets}
          {confirmationTextElement}
          <div className="controls-two-buttons control-buttons gridCenter">
            <span
              data-testid="Overlay No Button"
              className="action-button red-button"
              onClick={() => this.handleQuestionCallback(Constants.OVERLAY_RESPONSE_NO)}
            >
              {this.state.overlay.no ?? "No"}
            </span>
            <span
              data-testid="Overlay Yes Button"
              className={yesButtonClasses}
              onClick={() => this.handleQuestionCallback(Constants.OVERLAY_RESPONSE_YES)}
            >
              {this.state.overlay.yes ?? "Yes"}
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderOverlayMessage = () => {
    // Type = Constants.OVERLAY_MESSAGE will display the overlay-message with an OK button
    let bullets = "";
    const icon = this.state.overlay.icon || "";
    let text = this.state.overlay.text
      .trim()
      .split("\n")
      .map((str, index) => <div key={index}>{str}</div>);
    if (this.state.overlay.bullets) {
      bullets = (
        <ul>
          {this.state.overlay.bullets
            .trim()
            .split("\n")
            .map(str => (
              <li key={str}>{str}</li>
            ))}
        </ul>
      );
    }
    let title = "";
    if (this.state.overlay.title) {
      title = <div className="overlayTitle centerAligned">{this.state.overlay.title}</div>;
    }
    return (
      <div id="overlay" className="overlay">
        <div className="overlayDialog">
          {title}
          <div className="overlayMessage">
            {icon}
            {text}
          </div>
          {bullets}
          <div className="control-buttons controls-one-button gridCenter">
            <span className="action-button green-button" onClick={this.handleMessageCallback} data-testid="Overlay Message Ok">
              Ok
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderOverlayBlank = () => {
    return <div id="overlay" className="overlay"></div>;
  };

  renderAppBody = () => {
    let navcontents;
    let pagecontents;
    // (1) Render the requested view
    if (this.state.currentView === Constants.LOGIN) {
      // Blank app state defaults to login view. If we get here somehow, just show the Dashboard
      pagecontents = this.renderDashboard();
    } else if (this.state.currentView === Constants.IMPORT) {
      pagecontents = this.renderImport();
    } else if (this.state.currentView === Constants.DASHBOARD) {
      pagecontents = this.renderDashboard();
    } else if (this.state.currentView === Constants.CUSTOMERS) {
      pagecontents = this.renderCustomerList();
    } else if (this.state.currentView === Constants.PRODUCTS) {
      pagecontents = this.renderProductList();
    } else if (this.state.currentView === Constants.INVOICES) {
      pagecontents = this.renderInvoiceList();
    } else if (this.state.currentView === Constants.REPAIRS) {
      pagecontents = this.renderRepairList();
    } else if (this.state.currentView === Constants.QUOTES) {
      pagecontents = this.renderQuoteList();
    } else if (this.state.currentView === Constants.ORDERS) {
      pagecontents = this.renderOrderList();
    } else if (this.state.currentView === Constants.SUPPLIERS) {
      pagecontents = this.renderSupplierList();
    } else if (this.state.currentView === Constants.PURCHASES) {
      pagecontents = this.renderPurchaseList();
    } else if (this.state.currentView === Constants.MESSAGES) {
      pagecontents = this.renderMessageList();
    } else if (this.state.currentView === Constants.TIMESHEETS) {
      pagecontents = this.renderTimesheetList();
    } else if (this.state.currentView === Constants.REPORTS) {
      pagecontents = this.renderReportList();
    } else if (this.state.currentView === Constants.BILLINGS) {
      pagecontents = this.renderBillingList();
    } else if (this.state.currentView === Constants.CAMPAIGNS) {
      pagecontents = this.renderCampaignList();
    } else if (this.state.currentView === Constants.CUSTOMER && this.state.filtertype?.tab === Constants.TAB_PROSPECTS) {
      // Keep this here, ahead of the renderCustomer() view logic (next line)
      pagecontents = this.renderProspect();
    } else if (this.state.currentView === Constants.CUSTOMER) {
      pagecontents = this.renderCustomer();
    } else if (this.state.currentView === Constants.PRODUCT) {
      pagecontents = this.renderProduct();
    } else if (this.state.currentView === Constants.ORDER) {
      pagecontents = this.renderOrder();
    } else if (this.state.currentView === Constants.REPAIR) {
      pagecontents = this.renderRepair();
    } else if (this.state.currentView === Constants.REPORT) {
      pagecontents = this.renderReport();
    } else if (this.state.currentView === Constants.QUOTE) {
      pagecontents = this.renderQuote();
    } else if (this.state.currentView === Constants.INVOICE) {
      pagecontents = this.renderInvoice();
    } else if (this.state.currentView === Constants.PAY) {
      pagecontents = this.renderPay();
    } else if (this.state.currentView === Constants.PURCHASE) {
      pagecontents = this.renderPurchase();
    } else if (this.state.currentView === Constants.SUPPLIER) {
      pagecontents = this.renderSupplier();
    } else if (this.state.currentView === Constants.BILLING) {
      if (this.state.filtertype?.tab !== Constants.TAB_PLANS) {
        pagecontents = this.renderBillingSubscription();
      } else {
        pagecontents = this.renderBillingPlan();
      }
    } else if (this.state.currentView === Constants.SETTING) {
      pagecontents = this.renderSettings();
    } else if (this.state.currentView === Constants.CAMPAIGN) {
      pagecontents = this.renderCampaign();
    } else {
      console.log(Helper.clTimestamp(), this.state.currentView);
      pagecontents = <div>Unknown page</div>;
    }

    return (
      <div className="app-body">
        <div className="mobile">{navcontents}</div>
        <div>{pagecontents}</div>
      </div>
    );
  };

  renderSupplierList = () => {
    const params = { companytypes: 1 };
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <SupplierList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={"companies.companyname"}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleEditItem={this.handleEditSupplier}
          hideOverlay={this.hideOverlay}
          newIcon={<SuppliersIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={Constants.SUPPLIERS}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={Constants.URL_COMPANIES}
        />
      </ErrorBoundary>
    );
  };

  renderCustomerList = () => {
    let params = { companytypes: Constants.CUSTOMER_COMPANY + "," + Constants.CUSTOMER_FAMILY };
    let url = Constants.URL_COMPANIES;
    if (this.state.searchkey.trim()) {
      url = Constants.URL_CONTACTS;
    }
    if (this.state.filtertype.tab === Constants.TAB_PROSPECTS) {
      params = {};
      url = Constants.URL_COMPANIES;
    }
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <CustomerList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={this.handleEditContact}
          hideOverlay={this.hideOverlay}
          hideNew={this.state.filtertype?.tab === Constants.TAB_PROSPECTS}
          newIcon={<CustomersIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          sortUrl={Constants.URL_CONTACTS}
          title={Constants.CUSTOMERS}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={url}
        />
      </ErrorBoundary>
    );
  };

  renderProductList = () => {
    const params = {};
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <ProductList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={"products.productname"}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={this.handleEditProduct}
          handleInventory={this.handleInventory}
          hideOverlay={this.hideOverlay}
          newIcon={<ProductsIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={Constants.PRODUCTS}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={Constants.URL_PRODUCTS}
        />
      </ErrorBoundary>
    );
  };

  renderInvoiceList = () => {
    const params = {
      ordertype: Constants.INVOICE,
    };
    let url = Constants.URL_ORDERS;
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <InvoiceList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleEditItem={this.handleEditInvoice}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          hideOverlay={this.hideOverlay}
          newIcon={<InvoicesIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={Constants.INVOICES}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={url}
        />
      </ErrorBoundary>
    );
  };

  renderRepairList = () => {
    const params = {
      ordertype: Constants.REPAIR,
    };
    let url = Constants.URL_ORDERS;
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <RepairList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={this.handleEditRepair}
          hideOverlay={this.hideOverlay}
          newIcon={<RepairsIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={Constants.SERVICE}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={url}
        />
      </ErrorBoundary>
    );
  };

  renderReportList = () => {
    const params = {
      ordertype: Constants.REPORT,
    };
    let url = Constants.URL_REPORTS;
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <ReportList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleEditItem={this.handleEditReport}
          hideNew={true}
          hideOverlay={this.hideOverlay}
          newIcon={<ReportsIcon />}
          page={this.state.page}
          preventSelection={true}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={Constants.REPORTS}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={url}
        />
      </ErrorBoundary>
    );
  };

  renderBillingList = () => {
    if (this.state.maast.merchant_id === "" || this.state.maast.merchant_id === null) {
      return (
        <div id="list-container">
          <div className="white">Have you completed your MAAST merchant account application?</div>
          <ul>
            <li>
              <span className="white">No.</span> Check your email for the link to your merchant application. If you can't find it, contact support for
              assistance.
            </li>
            <li>
              <span className="white">Yes!</span> Your ClerkHound account will be updated with your merchant information as soon as your application
              is approved.
            </li>
          </ul>
        </div>
      );
    }
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <BillingList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={{}}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={this.handleEditBilling}
          hideOverlay={this.hideOverlay}
          newIcon={<BillingsIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={"Recurring Billing"}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={Constants.URL_BILLING}
        />
      </ErrorBoundary>
    );
  };

  renderCampaignList = () => {
    let url = Constants.URL_CAMPAIGNS;
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <CampaignList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={{}}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={this.handleEditCampaign}
          hideOverlay={this.hideOverlay}
          newIcon={<QuotesIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          single_selection={true}
          title={Constants.CAMPAIGNS}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={url}
        />
      </ErrorBoundary>
    );
  };

  renderQuoteList = () => {
    const params = {
      ordertype: Constants.QUOTE,
    };
    let url = Constants.URL_ORDERS;
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <QuoteList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={this.handleEditQuote}
          hideOverlay={this.hideOverlay}
          newIcon={<QuotesIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={Constants.QUOTES}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={url}
        />
      </ErrorBoundary>
    );
  };

  renderOrderList = () => {
    const params = {
      ordertype: Constants.ORDER,
    };
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <OrderList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={this.handleEditOrder}
          handleEditPurchase={this.handleEditPurchase}
          hideOverlay={this.hideOverlay}
          newIcon={<OrdersIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={Constants.ORDERS}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={Constants.URL_ORDERS}
        />
      </ErrorBoundary>
    );
  };

  renderPurchaseList = () => {
    const params = {
      ordertype: Constants.PURCHASE,
    };
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <PurchaseList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={this.handleEditPurchase}
          handleEditPurchase={this.handleEditPurchase}
          hideOverlay={this.hideOverlay}
          newIcon={<PurchasesIcon />}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={Constants.PURCHASES}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={Constants.URL_ORDERS}
        />
      </ErrorBoundary>
    );
  };

  renderTimesheetList = () => {
    const params = {};
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <TimesheetList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          badBreadcrumb={this.badBreadcrumb}
          checkLogin={this.checkLogin}
          defaultSortKey={null}
          disableDetailView={true}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={this.handleNewTimesheetEntry}
          handleEditTimesheet={this.handleEditTimesheet}
          hideOverlay={this.hideOverlay}
          newIcon={<TimesheetsIcon />}
          preventSelection={true}
          putTimesheet={this.putTimesheet}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={Constants.TIMESHEETS}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={Constants.URL_TIMESHEETS}
        />
      </ErrorBoundary>
    );
  };

  renderMessageList = () => {
    let params = {};
    let url = Constants.URL_MAIL;
    if (this.state.filtertype?.tab === Constants.TEXTS) {
      url = Constants.URL_SMS;
      params.action = Constants.SMS_ACTION_GET_CONTACTS;
      params.messagesearchlimit = 7;
      // params.messagesearchlimit = this.state.displaySettings.FOLDER_ROWS;
      // TODO: Make this dynamic
      params.includeblocked = true;
    } else if (this.state.filtertype?.tab === Constants.EMAIL) {
      params.action = Constants.EMAIL_ACTION_GET_CUSTOMERS;
    }
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <MessageList
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          badBreadcrumb={this.badBreadcrumb}
          checkLogin={this.checkLogin}
          defaultSortKey={null}
          disableDetailView={true}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getEmail={this.getEmail}
          getText={this.getText}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleChangeTabView={this.handleChangeTabView}
          handleEditItem={() => {}}
          handleEditMessage={() => {}}
          handleReduceTwilioUnreadCount={this.handleReduceTwilioUnreadCount}
          hideNew={true}
          hideOverlay={this.hideOverlay}
          newIcon={<MessagesIcon />}
          putMessage={this.putMessage}
          page={this.state.page}
          searchkey={this.state.searchkey}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          single_selection={true}
          title={Constants.MESSAGES}
          two_panel={true}
          updateBreadcrumb={this.updateBreadcrumb}
          updateFilterType={this.updateFilterType}
          url={url}
        />
      </ErrorBoundary>
    );
  };

  renderSettings = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Settings
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          getProduct={this.getProduct}
          getUsers={this.getUsers}
          handleChangePageNumber={this.handleChangePageNumber}
          handleEditItem={() => {}}
          handleSettingsUpdated={this.handleSettingsUpdated}
          handleVerifyEmail={this.handleVerifyEmail}
          hideNew={true}
          hideOverlay={this.hideOverlay}
          postPhotoBinary={this.postPhotoBinary}
          showOverlay={this.showOverlay}
          single_selection={true}
          title={Constants.SETTINGS}
          two_panel={true}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderImport = () => {
    const params = {};
    let title = Constants.IMPORT;
    if (this.state.import?.updateExisting) {
      title = Constants.UPDATE;
    }
    if (this.state.import?.type) {
      title += " " + this.state.import?.type;
    }

    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Import
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          defaultSortKey={null}
          disableDetailView={true}
          error={this.state.error}
          filtertype={this.state.filtertype}
          getParameters={params}
          handleChangePageNumber={this.handleChangePageNumber}
          handleEditItem={this.handleNewImport}
          handleResetImport={this.handleResetImport}
          hideOverlay={this.hideOverlay}
          import={this.state.import}
          newIcon={<ReportsIcon />}
          preventSelection={true}
          setExportCallback={this.setExportCallback}
          setListUpdateCallback={this.setListUpdateCallback}
          showOverlay={this.showOverlay}
          title={title}
          two_panel={true}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderDashboard = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Dashboard
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          handleEditItem={this.handleEditItem}
          handleShowList={this.handleShowList}
          hideOverlay={this.hideOverlay}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderCustomer = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Customer
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          getEmail={this.getEmail}
          getText={this.getText}
          handleEditItem={this.handleEditItem}
          hideOverlay={this.hideOverlay}
          isReadyToSubmitTokenize={this.isReadyToSubmitTokenize}
          params={{}}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderProspect = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Prospect
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          getEmail={this.getEmail}
          getText={this.getText}
          handleEditItem={this.handleEditItem}
          hideOverlay={this.hideOverlay}
          isReadyToSubmitTokenize={this.isReadyToSubmitTokenize}
          params={{}}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderProduct = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Product
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          handleEditItem={this.handleEditItem}
          handleEditPurchase={this.handleEditPurchase}
          hideOverlay={this.hideOverlay}
          postPhotoBinary={this.postPhotoBinary}
          product={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderInvoice = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Invoice
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          getEmail={this.getEmail}
          getText={this.getText}
          getPayment={this.getPayment}
          handleEditItem={this.handleEditItem}
          hideOverlay={this.hideOverlay}
          ordertype={Constants.INVOICE}
          persistState={this.persistState}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderPay = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Pay
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          getPayment={this.getPayment}
          handleEditItem={this.handleEditItem}
          hideOverlay={this.hideOverlay}
          ordertype={Constants.PAY}
          selectedItem={this.state.selectedItem}
          setCurrentView={this.setCurrentView}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderRepair = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Repair
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          getEmail={this.getEmail}
          getText={this.getText}
          handleEditItem={this.handleEditItem}
          hideOverlay={this.hideOverlay}
          ordertype={Constants.REPAIR}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderReport = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Report
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          handleEditItem={() => {}}
          hideOverlay={this.hideOverlay}
          ordertype={Constants.REPORT}
          selectedItem={this.state.selectedItem}
          setExportCallback={this.setExportCallback}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderQuote = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Quote
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          getEmail={this.getEmail}
          getText={this.getText}
          handleEditItem={this.handleEditItem}
          hideOverlay={this.hideOverlay}
          ordertype={Constants.QUOTE}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderCampaign = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Campaign
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          getEmail={this.getEmail}
          getText={this.getText}
          handleEditItem={this.handleEditItem}
          hideOverlay={this.hideOverlay}
          ordertype={Constants.QUOTE}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderOrder = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Order
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          handleEditItem={this.handleEditItem}
          getEmail={this.getEmail}
          getText={this.getText}
          hideOverlay={this.hideOverlay}
          ordertype={Constants.ORDER}
          persistState={this.persistState}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderPurchase = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Purchase
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          handleEditItem={this.handleEditItem}
          getEmail={this.getEmail}
          getText={this.getText}
          hideOverlay={this.hideOverlay}
          ordertype={Constants.PURCHASE}
          persistState={this.persistState}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderSupplier = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <Supplier
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          getEmail={this.getEmail}
          getText={this.getText}
          handleEditItem={this.handleEditItem}
          handleEditPurchase={this.handleEditPurchase}
          hideOverlay={this.hideOverlay}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
        />
      </ErrorBoundary>
    );
  };

  renderBillingPlan = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <BillingPlan
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          handleEditItem={this.handleEditItem}
          hideOverlay={this.hideOverlay}
          params={{}}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
          url={Constants.URL_BILLING}
        />
      </ErrorBoundary>
    );
  };

  renderBillingSubscription = () => {
    return (
      <ErrorBoundary hideOverlay={this.hideOverlay} resetState={this.resetState}>
        <BillingSubscription
          addBreadcrumb={this.addBreadcrumb}
          appState={this.getAppState()}
          checkLogin={this.checkLogin}
          badBreadcrumb={this.badBreadcrumb}
          error={this.state.error}
          filtertype={this.state.filtertype}
          followBreadcrumb={this.followBreadcrumb}
          getEmail={this.getEmail}
          getText={this.getText}
          getProduct={this.getProduct}
          handleEditItem={this.handleEditItem}
          hideOverlay={this.hideOverlay}
          isReadyToSubmitTokenize={this.isReadyToSubmitTokenize}
          params={{}}
          selectedItem={this.state.selectedItem}
          showOverlay={this.showOverlay}
          updateBreadcrumb={this.updateBreadcrumb}
          url={Constants.URL_BILLING}
        />
      </ErrorBoundary>
    );
  };

  handleSendCampaignLink = (email, firstname, lastname, mobilephone, campaignuuid, callback) => {
    this.setState({ overlay: { type: Constants.OVERLAY_PROGRESS, text: "Requesting a link..." } });
    this.postCampaignLink(email, firstname, lastname, mobilephone, campaignuuid, callback);
  };

  handleFilterOverlaySelection = dateFilterType => {
    const { start, end } = Helper.getDatesForFilter(dateFilterType);
    this.setState(prevState => ({
      overlay: { ...prevState.overlay, dateFilterType: dateFilterType, start: start, end: end },
    }));
  };

  handleOrderFilterChangeStart = event => {
    this.setState(prevState => ({
      overlay: { ...prevState.overlay, start: event.target.value, dateFilterType: Constants.FILTER_DATE_CUSTOM },
    }));
  };
  handleOrderFilterChangeEnd = event => {
    this.setState(prevState => ({
      overlay: { ...prevState.overlay, end: event.target.value, dateFilterType: Constants.FILTER_DATE_CUSTOM },
    }));
  };

  handleClickTestLabel = () => {
    this.setState({ showTestLabel: false });
  };

  // This function causes the overlay to close on mobile when the keyboard disappears
  // handleResize = () => {
  //   if (this.state.overlay?.type === Constants.OVERLAY_TOKENIZE) {
  //     this.hideOverlay();
  //   }
  // };

  handleKeyDown = event => {
    if (this.state.overlay && this.state.overlay.type) {
      // Disable typing except for the specified overlay types
      if (
        this.state.overlay.type !== Constants.OVERLAY_SEARCH_CONTACTS &&
        this.state.overlay.type !== Constants.OVERLAY_SEARCH_PRODUCTS &&
        this.state.overlay.type !== Constants.OVERLAY_INPUT_BOX &&
        this.state.overlay.type !== Constants.OVERLAY_CHANGE_PASSWORD &&
        this.state.overlay.type !== Constants.OVERLAY_AUTH_PROMPT &&
        this.state.overlay.type !== Constants.OVERLAY_TOKENIZE &&
        this.state.overlay.type !== Constants.OVERLAY_QUESTION
      ) {
        if (this.state.overlay.type) {
          event.preventDefault();
        }
        if (event.keyCode === Constants.ESC || event.keyCode === Constants.ENTER) {
          if (this.state.overlay.type === Constants.OVERLAY_MESSAGE) {
            this.handleMessageCallback();
          } else if (this.state.overlay.type === Constants.OVERLAY_IMPORT) {
            if (event.keyCode === Constants.ESC) {
              this.hideOverlay();
            } else if (event.keyCode === Constants.ENTER) {
              this.handleConfigureImport();
            }
          }
        }
      } else if (this.state.overlay.type === Constants.OVERLAY_QUESTION) {
        if (event.keyCode === Constants.ESC) {
          this.handleQuestionCallback(Constants.OVERLAY_RESPONSE_NO);
        } else if (event.keyCode === Constants.ENTER) {
          event.preventDefault();
          this.handleQuestionCallback(Constants.OVERLAY_RESPONSE_YES);
        }
      } else if (this.state.overlay.type === Constants.OVERLAY_AUTH_PROMPT) {
        if (event.keyCode === Constants.ESC) {
          this.handleAuthPromptCallback(Constants.OVERLAY_RESPONSE_NO);
        } else if (event.keyCode === Constants.ENTER) {
          if (event.target.id === "user") {
            this.passwordInput.focus();
          } else if (event.target.id === "pass") {
            this.handleAuthPromptCallback(Constants.OVERLAY_RESPONSE_YES);
          }
        }
      }
    }
  };

  handleMenuClick = menuItemName => {
    switch (menuItemName) {
      case Constants.BILLINGS:
        this.handleBillings();
        break;
      case Constants.DASHBOARD:
        this.handleDashboard();
        break;
      case Constants.CUSTOMERS:
        this.handleCustomers();
        break;
      case Constants.PRODUCTS:
        this.handleProducts();
        break;
      case Constants.INVOICES:
        this.handleInvoices();
        break;
      case Constants.ORDERS:
        this.handleOrders();
        break;
      case Constants.REPAIRS:
        this.handleRepairs();
        break;
      case Constants.QUOTES:
        this.handleQuotes();
        break;
      case Constants.SUPPLIERS:
        this.handleSuppliers();
        break;
      case Constants.PURCHASES:
        this.handlePurchases();
        break;
      case Constants.MESSAGES:
        this.handleMessages();
        break;
      case Constants.TIMESHEETS:
        this.handleTimesheets();
        break;
      case Constants.REPORTS:
        this.handleReports();
        break;
      case Constants.ROSTERHOUND:
        this.handleRosterhound();
        break;
      case Constants.CAMPAIGNS:
        this.handleCampaigns();
        break;
      case "Gear":
        if (this.state.overlay.type === Constants.OVERLAY_GEAR) {
          this.hideOverlay();
        } else {
          this.showOverlay({ type: Constants.OVERLAY_GEAR });
        }

        break;
      default:
        console.log(Helper.clTimestamp(), "Unknown menu option " + menuItemName);
    }
  };

  // Update the state based on the new filter type values
  handleChangeTabView = filtertype => {
    this.setState(
      prevState => ({
        filtertype: { ...prevState.filtertype, ...filtertype },
        page: 1,
      }),
      () => {
        this.updateBreadcrumb({ filtertype: this.state.filtertype });
      }
    );
  };

  handleChangePageNumber = page => {
    this.setState({ page: page }, () => {
      this.updateBreadcrumb({ page: this.state.page });
    });
  };

  handleChangePickOrCreate = event => {
    let overlay = this.state.overlay;
    overlay.id = event.target.value;
    this.setState({ overlay: overlay });
  };

  handleChangeOverlayInput = event => {
    this.setState(prevState => ({
      overlay: { ...prevState.overlay, input: event.target.value },
    }));
  };

  handleToggleOverlayPassword = () => {
    this.setState(prevState => ({
      overlay: {
        ...prevState.overlay,
        passtype: prevState.overlay.passtype === "password" ? "text" : "password",
      },
    }));
  };

  handleToggleOverlayOldPassword = () => {
    this.setState(prevState => ({
      overlay: {
        ...prevState.overlay,
        old_passtype: prevState.overlay.old_passtype === "password" ? "text" : "password",
      },
    }));
  };

  handleToggleOverlayNewPassword = () => {
    this.setState(prevState => ({
      overlay: {
        ...prevState.overlay,
        new_passtype: prevState.overlay.new_passtype === "password" ? "text" : "password",
      },
    }));
  };

  handleSearchCallback = response => {
    if (this.state.overlay.callback) {
      this.state.overlay.callback(response);
    }
    this.hideOverlay();
  };

  handlePickerCallback = (response, id, event) => {
    this.hideOverlay(true);
    const item = this.state.overlay.items.filter(item => item.id === id)[0];
    this.state.overlay.callback(response, item, event);
  };

  handleVerifyEmail = callback => {
    // Verify the email address with Amazon
    this.postVerifyEmail(callback);
  };

  handleSettingsUpdated = settings => {
    let families;
    let technicians;
    let displaySettings;
    let handpoint;
    let valor;
    let maast;
    let thirdparty;
    let clientSettings;
    let customFields;
    let inventory;
    let twilio;
    ({ families, technicians, displaySettings, clientSettings, handpoint, valor, maast, thirdparty, customFields, inventory, twilio } =
      this.extractClientSettings(settings));
    const replyToAllowed = settings.find(
      setting =>
        setting.category === Constants.SETTINGS_CATEGORY_MAIL &&
        setting.description === Constants.SETTINGS_REPLY_TO_ALLOWED &&
        setting.value === this.state.useruuid
    )
      ? true
      : false;
    this.setState(
      {
        clientSettings: clientSettings,
        customFields: customFields,
        displaySettings: displaySettings,
        families: families,
        handpoint: handpoint,
        inventory: inventory,
        maast: maast,
        replyToAllowed: replyToAllowed,
        technicians: technicians,
        thirdparty: thirdparty,
        twilio: twilio,
        valor: valor,
      },
      this.persistState
    );
  };

  handleConfigureImport = () => {
    const file = this.state.import.file;
    this.showOverlay(
      {
        type: Constants.OVERLAY_PROGRESS,
        text: "Reading file...",
      },
      () => {
        Papa.parse(file, {
          header: true,
          complete: results => {
            const headers = results.meta.fields
              .filter(field => field.trim() !== "")
              .map(field => {
                return { name: field, match: "" };
              });
            // Check header fields for productuuid/contactuuid
            // The appropriate field must match the data being imported
            const hasProductuuid =
              headers.filter(header => header.name === "productuuid").length === 1 && this.state.import.type === Constants.PRODUCTS;
            const hasContactuuid =
              headers.filter(header => header.name === "contactuuid").length === 1 &&
              [Constants.CUSTOMERS, Constants.SUPPLIERS].includes(this.state.import.type);
            if (hasProductuuid || hasContactuuid) {
              // Ask if this is an update file (versus an import)
              this.showOverlay({
                type: Constants.OVERLAY_QUESTION,
                title: `Update existing ${hasProductuuid ? "products" : "contacts"}?`,
                text: `${hasProductuuid ? "Product" : "Contact"} key detected. Do you want to update existing ${
                  hasProductuuid ? "products" : "contacts"
                }?`,
                callback: response => {
                  if (response === Constants.OVERLAY_RESPONSE_YES) {
                    this.handleSetDataInState(true, headers, results);
                  } else {
                    // Import
                    this.handleSetDataInState(false, headers, results);
                  }
                },
              });
            } else {
              this.handleSetDataInState(false, headers, results);
            }
          },
        });
      }
    );
  };

  handleSetDataInState = (updateExisting, headers, results) => {
    let index = 0;
    this.setState(
      prevState => ({
        import: {
          ...prevState.import,
          created: new Date(),
          updateExisting: updateExisting,
          headerFields: headers,
          data: results.data
            .filter(row => {
              // Filter out rows with no data
              for (var key in row) {
                if (row.hasOwnProperty(key)) {
                  var value = row[key];
                  if (value !== null && value !== undefined && value !== "") {
                    return true; // Found a non-empty attribute
                  }
                }
              }
              return false; // All attributes are empty
            })
            .map(row => {
              row.CLERKHOUND_IMPORT_INDEX = index++;
              return row;
            }),
        },
      }),
      () => {
        this.hideOverlay();
      }
    );
  };

  handleFileChange = event => {
    // If products are not enabled, then this can only be a Customer import
    if (!this.state.features.includes(Constants.FEATURE_PRODUCTS)) {
      this.setState(prevState => ({
        import: { ...prevState.import, file: event.target.files[0], type: Constants.CUSTOMERS },
      }));
    } else {
      this.setState(prevState => ({
        import: { ...prevState.import, file: event.target.files[0] },
      }));
    }
  };

  handleRecover = (user, callback) => {
    this.postUser(user, null, callback);
  };

  handleLogin = (user, pass, remember) => {
    this.setState({ isLoggedIn: false, waiting: true, loggedInError: false }, this.persistState);
    if (window.grecaptcha) {
      try {
        const handleDoLogin = this.handleDoLogin;
        window.grecaptcha.ready(function () {
          window.grecaptcha.execute("6LfKkocpAAAAADZU8RE6ZR0A2qME3z3I2ZqrRMOW", { action: "submit" }).then(function (token) {
            handleDoLogin(user, pass, remember, token);
          });
        });
      } catch (e) {
        console.log(e);
        this.handleDoLogin(user, pass, remember, "");
      }
    } else {
      this.handleDoLogin(user, pass, remember, "");
    }
  };

  handleDoLogin = (user, pass, remember, recaptcha) => {
    Helper.clearLocalStorage();
    this.showOverlay();
    const data = {
      user: user,
      pass: pass,
      recaptcha: recaptcha,
    };
    Helper.postData(Constants.URL_SESSIONS, data).then(response => {
      if (response.status === 200 && response.body.sessiontoken) {
        localStorage.setItem(Constants.LOCAL_STORAGE_SESSION, response.body.sessiontoken);
        this.setState(
          {
            currentView: Constants.DASHBOARD,
            isLoggedIn: true,
            loggedInError: false,
            salesperson: response.body.fullname,
            subdomain: response.body.subdomain,
            username: user,
            usertype: response.body.usertype,
            waiting: false,
          },
          this.persistState
        );
        if (remember) {
          localStorage.setItem(Constants.LOCAL_STORAGE_REMEMBER_ME, user);
        } else {
          localStorage.setItem(Constants.LOCAL_STORAGE_REMEMBER_ME, "");
        }
      } else if (response.status === 501) {
        // Hard stop the user from doing anything while the system is undergoing maintenance
        this.renderMaintenanceMode(response);
      } else {
        this.setState(
          {
            currentView: Constants.LOGIN,
            usertype: null,
            isLoggedIn: false,
            loggedInError: true,
            waiting: false,
          },
          this.persistState
        );
      }
      this.hideOverlay();
    });
  };

  handleMessageCallback = () => {
    const callback = this.state.overlay.callback;
    this.hideOverlay();
    if (callback) {
      callback();
    }
  };

  handleOverlayPassChange = event => {
    if (event.target.id === "old_password") {
      this.setState(prevState => ({
        overlay: {
          ...prevState.overlay,
          old_password: event.target.value,
          isReadyToSubmit: event.target.value && prevState.overlay.new_password,
        },
      }));
    } else if (event.target.id === "new_password") {
      this.setState(prevState => ({
        overlay: {
          ...prevState.overlay,
          new_password: event.target.value,
          isReadyToSubmit: event.target.value && prevState.overlay.old_password,
        },
      }));
    }
  };

  handleOverlayAuthChange = event => {
    if (event.target.id === "user") {
      this.setState(prevState => ({
        overlay: {
          ...prevState.overlay,
          user: event.target.value,
          isReadyToSubmit: event.target.value && prevState.overlay.pass,
        },
      }));
    } else if (event.target.id === "pass") {
      this.setState(prevState => ({
        overlay: {
          ...prevState.overlay,
          pass: event.target.value,
          isReadyToSubmit: event.target.value && prevState.overlay.user,
        },
      }));
    }
  };

  handlePasswordChangeCallback = response => {
    if (this.overlayDisabled) {
      return;
    } else {
      this.overlayDisabled = true;
    }

    // If the user has clicked Yes, then try to change the password in the database
    // const callback = this.state.overlay.callback;
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      // Lock out this overlay
      this.setState(
        prevState => ({
          overlay: { ...prevState.overlay, error: "", message: "Authenticating..." },
        }),
        () => {
          this.putUser(this.state.overlay.old_password, this.state.overlay.new_password);
        }
      );
    } else {
      this.overlayDisabled = false;
      this.hideOverlay();
    }
  };

  handleUnsubscribe = () => {
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_MAIL;
    const data = {
      action: this.state.action,
      contactuuid: this.state.contactuuid,
      uuid: this.state.prospectuuid,
      clientuuid: this.state.clientuuid,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Good response
        this.setState({
          downloading: false,
          userActionSuccess: true,
        });
      } else {
        this.setState({ downloading: false, userActionError: true });
      }
    });
  };

  handleReportAbuse = userMessage => {
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_MAIL;
    const data = {
      action: this.state.action,
      contactuuid: this.state.contactuuid,
      clientuuid: this.state.clientuuid,
      intro_paragraph: userMessage,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Good response
        this.setState({
          downloading: false,
          userActionSuccess: true,
        });
      } else {
        this.setState({ downloading: false, userActionError: true });
      }
    });
  };

  putSession = sessiontoken => {
    const data = {
      session: sessiontoken,
    };
    const url = Constants.URL_SESSIONS;
    Helper.putData(url, data).then(response => {
      if (response.status !== 200) {
        // Anything other than 200 would indicate the session is invalid/expired
        // TODO: Switch to Login screen?
        console.log(Helper.clTimestamp(), "Error updating session");
      }
    });
  };

  // Updates the user's password in the database
  putUser = (old_password, new_password) => {
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_USERS;
    const user = {
      old_password: old_password,
      new_password: new_password,
    };
    Helper.putData(url, user).then(response => {
      this.overlayDisabled = false;
      if (response.status === 200 && response.body) {
        // Good response
        this.showOverlay({
          text: "Password updated",
          type: Constants.OVERLAY_MESSAGE,
        });
      } else if (response.status === 400 && response.body?.message) {
        this.setState({ error: "POST", downloading: false }, () => {
          this.showOverlay({
            text: response.body.message,
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      } else if (response.status === 401) {
        this.setState({ error: "POST", downloading: false }, () => {
          this.showOverlay({
            text: "Current password is incorrect.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.showOverlay({
            text: "There was an error adding the setting.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  handleAuthPromptCallback = response => {
    if (this.overlayDisabled) {
      return;
    } else {
      this.overlayDisabled = true;
    }

    // If the user has clicked Yes, then try to get an auth token from the server
    const callback = this.state.overlay.callback;
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      // Lock out this overlay
      this.setState(
        prevState => ({
          overlay: { ...prevState.overlay, error: "", message: "Authenticating...", passtype: "password" },
        }),
        () => {
          this.getAuthToken(this.state.overlay.user, this.state.overlay.pass, authtoken => {
            if (authtoken) {
              if (this.state.overlay?.hideOnSuccess) {
                this.hideOverlay();
              } else {
                if (this.state.overlay?.progressMessage) {
                  this.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: this.state.overlay.progressMessage });
                } else {
                  this.showOverlay();
                }
              }
              callback(response, authtoken);
              this.overlayDisabled = false;
            } else {
              this.overlayDisabled = false;
              this.setState(prevState => ({
                overlay: { ...prevState.overlay, error: "Authentication failed", message: "" },
              }));
            }
          });
        }
      );
    } else {
      callback(response);
      this.overlayDisabled = false;
      this.hideOverlay();
    }
  };

  handleSubmitTokenize = () => {
    const isReadyToSubmit =
      this.state.overlay.billing_first &&
      this.state.overlay.billing_last &&
      this.state.overlay.billing_zip &&
      // Zip must be at least 5 digits
      Helper.stripNonNumerics(this.state.overlay.billing_zip).length >= 5 &&
      this.state.overlay.embeddedFieldCardNumber &&
      this.state.overlay.embeddedFieldExpiration &&
      this.state.overlay.embeddedFieldCVV;
    if (isReadyToSubmit) {
      this.setState(
        prevState => ({
          overlay: { ...prevState.overlay, error: prevState.overlay.error || "Encrypting payment information..." },
        }),
        () => {
          this.formRef.current.requestSubmit();
        }
      );
    } else {
      this.setState(prevState => ({
        overlay: { ...prevState.overlay, error: "Please fill out all fields" },
      }));
    }
  };

  handleGetTransientToken = recaptcha => {
    //Protect screen during downloading data
    this.setState(prevState => ({
      downloading: true,
      overlay: { ...prevState.overlay, message: "Contacting Maast..." },
    }));

    // If the recaptcha flag is set, then we need to get a recaptcha token
    const handler = this.doHandleGetTransientToken;
    if (recaptcha) {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute("6LfKkocpAAAAADZU8RE6ZR0A2qME3z3I2ZqrRMOW", { action: "submit" }).then(function (token) {
          // handler({ recaptcha: token });
          handler({ recaptcha: token });
        });
      });
    } else {
      handler();
    }
  };

  doHandleGetTransientToken = (extraParams = {}) => {
    //set up to make database call
    const url = Constants.URL_PAYMENTS;
    const params = { action: Constants.REQUEST_TRANSIENT_KEY, ...extraParams };
    // For non session-based tokenization (payment method vaulting), we need to pass the requestuuid
    // If the url params contain a requestuuid, then add it to the params
    const urlParams = new URLSearchParams(window.location.search);
    const requestuuid = urlParams.get("uuid");
    const orderuuid = urlParams.get("orderuuid");
    const prospectuuid = urlParams.get("puuid");
    if (requestuuid) {
      params.requestuuid = requestuuid;
    }
    if (orderuuid) {
      params.orderuuid = orderuuid;
    }
    if (prospectuuid) {
      params.prospectuuid = prospectuuid;
    }
    if (this.state.overlay?.mfaCode && this.state.overlay?.mfaRequestuuid) {
      params.mfacode = this.state.overlay.mfaCode;
      params.mfarequestuuid = this.state.overlay.mfaRequestuuid;
    }
    // Uncomment this to test recaptcha failures
    // else {
    //   params.action = "deny";
    // }
    Helper.getData(url, params).then(response => {
      this.submitted = false;
      if (response.status === 200 && response.body.message.data?.transient_key) {
        //Update state with response, turn off downloading
        this.setState(
          prevState => ({
            transientkey: response.body.message.data.transient_key,
            error: null,
            downloading: false,
            isNew: false,
            overlay: { ...prevState.overlay, loading: null },
            displaycardreaderstate: "Enter card information",
            maast: {
              // Replace the existing maast object with the new one, if provided
              ...prevState.maast,
              mode: response.body?.mode || prevState.maast.mode,
              merchant_id: response.body.merchant_id || prevState.maast.merchant_id,
            },
          }),
          () => {
            this.handleLoadEmbeddedFields();
          }
        );
      } else if ([401, 404, 429].includes(response.status) && this.state.overlay?.recaptchaFailureCallback) {
        this.state.overlay.recaptchaFailureCallback(response.status);
      } else if ([401, 404, 429].includes(response.status)) {
        this.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Our security system has detected a problem with your request. Please contact the store.",
        });
      } else if (response.status === 503) {
        this.setState(prevState => ({
          downloading: false,
          overlay: { ...prevState.overlay, message: "Network error" },
        }));
      } else {
        this.setState(prevState => ({
          downloading: false,
          overlay: { ...prevState.overlay, message: "BOOM!" },
        }));
      }
    });
  };

  handleLoadEmbeddedFields = () => {
    const fontSize = Helper.getFontSize("billing_first");
    const settings = {
      formId: "paymentform",
      mode: this.state.maast.mode,
      fields: {
        card_number: {
          id: "card_number",
          onblur: this.handleBlur,
          attributes: { required: true },
        },
        exp_date: {
          id: "exp_date",
          onblur: this.handleBlur,
          attributes: { required: true },
        },
        cvv2: {
          id: "cvv2",
          onblur: this.handleBlur,
          attributes: { required: true },
        },
      },
      transientKey: this.state.transientkey,
      tokenize: true, // set to true to make card_id permanent
      onSuccess: data => {
        // Store the payment method in the customer vault
        if (!this.submitted) {
          this.submitted = true;
          // console.log(Helper.clTimestamp(), "onSuccess()", data);
          const card_id = data.card_id;
          const exp_date = data.exp_date;
          const card_number = data.card_number;
          this.state.overlay.callback(
            Constants.OVERLAY_RESPONSE_OK,
            this.state.overlay.billing_first,
            this.state.overlay.billing_last,
            this.state.overlay.billing_zip,
            card_id,
            exp_date,
            card_number,
            data
          );
          this.hideOverlay();
        } else {
          console.log(Helper.clTimestamp(), "ALERT! Received multiple onSuccess() messages!");
        }
      },
      onError: error => {
        if (error.detail) {
          for (let key in error.detail) {
            this.setState(prevState => ({
              overlay: { ...prevState.overlay, error: error.detail[key] },
            }));
          }
        }
      },
      cardConfig: {
        enabled: true,
      },
      achConfig: {
        enabled: true,
        onPaymentTypeChange: function (data) {
          // console.log(Helper.clTimestamp(), "Display", data);
        },
      },
      formFields: {
        cvv2: {
          required: true,
        },
      },
      style: Helper.getMaastEmbeddedStyle(Constants.VAULT, this.state?.colorMode, fontSize),
    };
    if (window.qpEmbeddedForm?.loadFrame) {
      window.qpEmbeddedForm.loadFrame(this.state.maast.merchant_id, settings);
    } else {
      console.log(Helper.clTimestamp(), "Error loading embedded fields");
      this.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Manual card entry is not available at this time. Please try again later.",
      });
    }
  };

  handleBlur = event => {
    // Note which fields, if any, passed/failed validation
    if (event.fieldName === "card_number") {
      this.setState(
        prevState => ({
          overlay: { ...prevState.overlay, embeddedFieldCardNumber: event.isValid },
        }),
        () => {
          this.setState(prevState => ({
            overlay: { ...prevState.overlay, isReadyToSubmit: this.isReadyToSubmitTokenize() },
          }));
        }
      );
    } else if (event.fieldName === "exp_date") {
      this.setState(
        prevState => ({
          overlay: { ...prevState.overlay, embeddedFieldExpiration: event.isValid },
        }),
        () => {
          this.setState(prevState => ({
            overlay: { ...prevState.overlay, isReadyToSubmit: this.isReadyToSubmitTokenize() },
          }));
        }
      );
    } else if (event.fieldName === "cvv2") {
      this.setState(
        prevState => ({
          overlay: { ...prevState.overlay, embeddedFieldCVV: event.isValid },
        }),
        () => {
          this.setState(prevState => ({
            overlay: { ...prevState.overlay, isReadyToSubmit: this.isReadyToSubmitTokenize() },
          }));
        }
      );
    }
  };

  handleChangeBilling = (event, field) => {
    this.setState(
      prevState => ({
        overlay: { ...prevState.overlay, [field]: event.target.value },
      }),
      () => {
        this.setState(prevState => ({
          overlay: { ...prevState.overlay, isReadyToSubmit: this.isReadyToSubmitTokenize() },
        }));
      }
    );
  };

  isReadyToSubmitTokenize = () => {
    if (this.state.overlay.tokenize) {
      return true;
      // this.state.overlay.billing_first &&
      // this.state.overlay.billing_last &&
      // this.state.overlay.billing_zip &&
      // // Zip must be at least 5 digits
      // Helper.stripNonNumerics(this.state.overlay.billing_zip).length >= 5 &&
      // this.state.overlay.embeddedFieldCardNumber &&
      // this.state.overlay.embeddedFieldExpiration &&
      // this.state.overlay.embeddedFieldCVV
    } else {
      return (
        this.state.overlay.billing_first &&
        this.state.overlay.billing_last &&
        this.state.overlay.billing_zip &&
        // Zip must be at least 5 digits
        Helper.stripNonNumerics(this.state.overlay.billing_zip).length >= 5 &&
        this.state.overlay.exp_date
      );
    }
  };

  handleChangeBillingExp = (event, field) => {
    const { value: inputValue, selectionStart } = event.target;
    const inputLength = inputValue.length;

    // Remove any non-numeric characters
    let cleanedValue = inputValue.replace(/[^\d]/g, "");

    // Insert pipe character after the second digit if needed
    if (inputLength >= 2) {
      cleanedValue = cleanedValue.slice(0, 2) + "|" + cleanedValue.slice(2);
    }

    // Truncate the value to 'MM|YY' format if needed
    cleanedValue = cleanedValue.slice(0, 5);

    // Handle backspace key (remove the pipe symbol)
    if (event.nativeEvent.inputType === "deleteContentBackward" && selectionStart === 3) {
      cleanedValue = inputValue.replace(/\|/g, "");
    }

    // Update the state with the modified value
    this.setState(
      prevState => ({
        overlay: { ...prevState.overlay, [field]: cleanedValue },
      }),
      () => {
        this.setState(prevState => ({
          overlay: { ...prevState.overlay, isReadyToSubmit: this.isReadyToSubmitTokenize() },
        }));
      }
    );
  };

  handleChangeBillingZip = (event, field) => {
    const { value: inputValue, selectionStart } = event.target;
    const inputLength = inputValue.length;

    // Remove any non-numeric characters
    let cleanedValue = inputValue.replace(/[^\d]/g, "");

    // Insert dash character after the fifth digit if needed
    if (inputLength > 5) {
      cleanedValue = cleanedValue.slice(0, 5) + "-" + cleanedValue.slice(5);
    }

    // Truncate the value to '12345-6789' format if needed
    cleanedValue = cleanedValue.slice(0, 10);

    // Handle backspace key (remove the pipe symbol)
    if (event.nativeEvent.inputType === "deleteContentBackward" && selectionStart === 6) {
      cleanedValue = inputValue.replace(/-/g, "");
    }

    // Update the state with the modified value
    this.setState(
      prevState => ({
        overlay: { ...prevState.overlay, [field]: cleanedValue },
      }),
      () => {
        this.setState(prevState => ({
          overlay: { ...prevState.overlay, isReadyToSubmit: this.isReadyToSubmitTokenize() },
        }));
      }
    );
  };

  handleEditBilling = item => {
    this.setCurrentView(Constants.BILLING, Constants.BILLINGS, {
      selectedItem: item,
      filtertype: this.state.filtertype,
    });
  };

  handleEditCampaign = item => {
    this.setCurrentView(Constants.CAMPAIGN, Constants.CAMPAIGNS, {
      selectedItem: item,
      filtertype: this.state.filtertype,
    });
  };

  handleQuestionCallback = response => {
    // If Yes is clicked, confirmation text is required, and does not match, then do not process click
    if (
      response === Constants.OVERLAY_RESPONSE_YES &&
      this.state.overlay.confirmationText &&
      this.state.overlay.confirmationText !== this.state.overlay.input
    ) {
      return;
    }
    this.hideOverlay();
    this.state.overlay.callback(response, this.state.overlay.key, this.state.overlay.value);
  };

  handleDatePickerCallback = response => {
    this.hideOverlay();
    this.state.overlay.callback(response, this.state.overlay.date);
  };

  handleInputCallback = response => {
    if (response === Constants.OVERLAY_RESPONSE_OK && !this.state.overlay.input && this.state.overlay.required) {
      return;
    }
    if (response === Constants.OVERLAY_RESPONSE_OK && this.state.overlay.input_type === "number" && isNaN(this.state.overlay.input)) {
      return;
    }
    if (response === Constants.OVERLAY_RESPONSE_OK && this.state.overlay.input_type === "percent") {
      if (!Helper.isValidPercent(this.state.overlay.input)) {
        return;
      }
    }
    if (
      response === Constants.OVERLAY_RESPONSE_OK &&
      this.state.overlay.input_type === "positivenumber" &&
      !isNaN(this.state.overlay.input) &&
      numeral(this.state.overlay.input).value() <= 0
    ) {
      return;
    }
    this.state.overlay.callback(response, this.state.overlay.key, this.state.overlay.input, this.state.overlay.extra, this.state.overlay.checked);
  };

  handleBarsClick = () => {
    this.showOverlay({ type: Constants.OVERLAY_WHEEL });
  };

  handleGearClick = () => {
    if (!this.state.overlay || this.state.overlay.type !== Constants.OVERLAY_GEAR) {
      this.showOverlay({ type: Constants.OVERLAY_GEAR });
    }
  };

  handleChangePassword = () => {
    this.showOverlay({
      type: Constants.OVERLAY_CHANGE_PASSWORD,
      prompt: "Enter your current and new passwords",
    });
  };

  handleExport = () => {
    if (this.exportCallback && Constants.EXPORT_VIEWS.includes(this.state.currentView)) {
      this.exportCallback();
    }
  };

  handleLogout = () => {
    this.showOverlay();
    // If we have a local session, delete it on the server and from local stor
    const session = localStorage.getItem(Constants.LOCAL_STORAGE_SESSION);
    if (session) {
      const url = Constants.URL_SESSIONS;
      Helper.deleteData(url, { session: session }).then(() => {
        localStorage.setItem(Constants.LOCAL_STORAGE_SESSION, "");
        this.hideOverlay();
      });
    }
    // Clear state and persist to local storage
    const newState = Helper.getEmptyState();
    this.setState(newState, () => {
      this.persistState();
    });
    // Clear local storage
    Helper.clearLocalStorage();
  };

  handleEditItem = (view, menu, item, tabView = Constants.TAB_NONE) => {
    this.setCurrentView(view, menu, {
      selectedItem: item,
      filtertype: { ...this.state.filtertype, tab: tabView },
    });
  };

  handleShowList = (view, menu, additionalState) => {
    this.setCurrentView(view, menu, additionalState);
  };

  handleEditContact = item => {
    this.setCurrentView(Constants.CUSTOMER, Constants.CUSTOMERS, {
      selectedItem: item,
    });
  };

  handleEditProduct = item => {
    this.setCurrentView(Constants.PRODUCT, Constants.PRODUCTS, {
      selectedItem: item,
    });
  };

  handleEditInvoice = item => {
    this.setCurrentView(Constants.INVOICE, Constants.INVOICES, {
      selectedItem: item,
    });
  };

  handleEditPay = item => {
    this.setCurrentView(Constants.PAY, Constants.INVOICES, {
      selectedItem: item,
    });
  };

  handleEditRepair = item => {
    this.setCurrentView(Constants.REPAIR, Constants.REPAIRS, {
      selectedItem: item,
    });
  };

  handleEditReport = item => {
    this.setCurrentView(Constants.REPORT, Constants.REPORTS, {
      selectedItem: item,
    });
  };

  handleEditQuote = item => {
    this.setCurrentView(Constants.QUOTE, Constants.QUOTES, {
      selectedItem: item,
    });
  };

  handleEditOrder = item => {
    this.setCurrentView(Constants.ORDER, Constants.ORDERS, {
      selectedItem: item,
    });
  };

  handleEditPurchase = item => {
    this.setCurrentView(Constants.PURCHASE, Constants.PURCHASES, {
      selectedItem: item,
    });
  };

  handleEditSupplier = item => {
    this.setCurrentView(Constants.SUPPLIER, Constants.SUPPLIERS, {
      selectedItem: item,
    });
  };

  handleNewTimesheetEntry = () => {
    this.showOverlay({
      type: Constants.OVERLAY_AUTH_PROMPT,
      prompt: "Enter your credentials\nto clock in",
      user: "",
      callback: (response, authtoken = null) => {
        if (response === Constants.OVERLAY_RESPONSE_YES) {
          this.postTimesheet(authtoken);
        }
      },
    });
  };

  handleNewImport = () => {
    this.showOverlay({
      text: "Import",
      type: Constants.OVERLAY_IMPORT,
    });
  };

  handleResetImport = () => {
    // Clear the file
    this.setState({
      import: null,
    });
    // Clear the file input element
    if (this.importFileInputRef.current) {
      this.importFileInputRef.current.value = "";
    }
  };

  handleEditTimesheet = timesheet => {
    this.showOverlay({
      type: Constants.OVERLAY_AUTH_PROMPT,
      prompt: "Enter your credentials\nto clock out",
      user: "",
      callback: (response, authtoken = null) => {
        if (response === Constants.OVERLAY_RESPONSE_YES) {
          this.putTimesheet(authtoken, timesheet);
        }
      },
    });
  };

  handleShowNav = () => {
    this.updateBreadcrumb(this.initialStateValues());
    this.setCurrentView(Constants.NAV, Constants.NAV);
  };

  handleDashboard = () => {
    this.updateBreadcrumb(this.initialStateValues());
    this.setCurrentView(Constants.DASHBOARD, Constants.DASHBOARD);
  };

  handleCustomers = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.CUSTOMERS, Constants.CUSTOMERS, { filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.TAB_CUSTOMER } });
  };

  // This function has a callback because it is called during the inventory completion steps in order to facilitate an export
  handleProducts = (callback = null) => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("", callback);
    }
    this.setCurrentView(Constants.PRODUCTS, Constants.PRODUCTS, { filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.TAB_PRODUCTS } });
  };

  handleInvoices = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.INVOICES, Constants.INVOICES, {
      filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.ORDER_STATUS_TODAY },
    });
  };

  handleOrders = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.ORDERS, Constants.ORDERS, { filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.ORDER_STATUS_OPEN } });
  };

  handleRepairs = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.REPAIRS, Constants.REPAIRS, { filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.ORDER_STATUS_OPEN } });
  };

  handleQuotes = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.QUOTES, Constants.QUOTES, { filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.ORDER_STATUS_OPEN } });
  };

  handleSuppliers = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.SUPPLIERS, Constants.SUPPLIERS, {
      filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.TAB_NONE },
    });
  };

  handlePurchases = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.PURCHASES, Constants.PURCHASES, {
      filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.ORDER_STATUS_OPEN },
    });
  };

  handleMessages = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.MESSAGES, Constants.MESSAGES, { filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.EMAIL } });
  };

  handleTimesheets = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.TIMESHEETS, Constants.TIMESHEETS, {
      filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.TIMESHEETS_CURRENT },
    });
  };

  handleReports = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.REPORTS, Constants.REPORTS, { filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.TAB_NONE } });
  };

  handleRosterhound = () => {
    // Open Rosterhound in a new tab
    let url = ApiBase.ROSTERHOUND_URL + "?clientuuid=" + this.state.clientuuid;
    // Concatenate the user's clientuuid
    window.open(url, "_blank");
  };

  handleCampaigns = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.CAMPAIGNS, Constants.CAMPAIGNS, { filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.TAB_ACTIVE } });
  };

  handleBillings = () => {
    this.updateBreadcrumb(this.initialStateValues());
    if (this.listUpdateCallback) {
      this.listUpdateCallback("");
    }
    this.setCurrentView(Constants.BILLINGS, Constants.BILLINGS, { filtertype: { active: Constants.FILTER_ACTIVE, tab: Constants.TAB_ACTIVE } });
  };

  handleSearchChange = (id, value) => {
    this.setState({ [id]: value });
  };

  handleClearSearch = () => {
    this.setState({ searchkey: "" });
  };

  handleShowSettings = () => {
    this.setCurrentView(Constants.SETTING, Constants.SETTINGS);
    // this.setState({ currentView: Constants.SETTING });
  };

  handleToggleColorMode = () => {
    this.setState(
      prevState => ({
        colorMode: prevState.colorMode === Constants.COLOR_MODE_LIGHT ? Constants.COLOR_MODE_DARK : Constants.COLOR_MODE_LIGHT,
      }),
      () => {
        sessionStorage.setItem(Constants.LOCAL_STORAGE_COLOR_MODE, this.state.colorMode);
        this.persistState();
        window.location.reload();
      }
    );
  };

  handleSwitchPeer = () => {
    // Show the store picker
    const stores = this.state.peers.map(peer => {
      return { ...peer, id: peer.clientuuid, text: peer.name };
    });
    this.showOverlay({
      type: Constants.OVERLAY_PICKER,
      items: stores,
      text: "Pick a Store",
      id: stores[0].clientuuid,
      event: {},
      hideCreate: true,
      callback: this.handlePickClient,
    });
  };

  handleSwitchClient = () => {
    // Get the client list and show a client picker
    this.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: "Loading client list...",
    });
    // Show the picker
    this.getClientList(clients => {
      clients = clients
        .map(client => {
          return { ...client, id: client.clientuuid, text: client.name };
        })
        .sort((a, b) => {
          // Sort Playwright clients to the bottom
          if (a.text.startsWith("Playwright") && b.text.startsWith("Playwright")) {
            return a.text.localeCompare(b.text);
          } else if (a.text.startsWith("ClerkHound")) {
            return -1;
          } else if (b.text.startsWith("ClerkHound")) {
            return 1;
          } else if (a.text.startsWith("Pladd")) {
            return -1;
          } else if (b.text.startsWith("Pladd")) {
            return 1;
          } else if (a.text.startsWith("Playwright")) {
            return 1;
          } else if (a.text.startsWith("Demo ")) {
            return 1;
          } else if (b.text.startsWith("Playwright")) {
            return -1;
          } else if (b.text.startsWith("Demo ")) {
            return -1;
          } else {
            return a.text.localeCompare(b.text);
          }
        });
      const clientuuid = (clients.filter(client => client.current) ?? clients[0])?.clientuuid;
      // Show a prompt asking which client to switch to
      this.showOverlay({
        type: Constants.OVERLAY_PICKER,
        items: clients,
        text: "Pick a Client",
        id: clientuuid,
        event: {},
        hideCreate: true,
        callback: this.handlePickClient,
      });
    });
  };

  handlePickClient = (response, item, event) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      this.putUserClientID(
        item.clientuuid,
        () => {
          Helper.clearLocalStorage();
          if (this.state.currentView === Constants.DASHBOARD) {
            this.checkLogin();
          } else {
            this.setCurrentView(Constants.DASHBOARD, Constants.DASHBOARD);
          }
        },
        message => {
          this.showOverlay({ type: Constants.OVERLAY_MESSAGE, text: "Error switching store: " + message });
        }
      );
    } else if (response === Constants.OVERLAY_RESPONSE_CANCEL) {
      // Nothing to do
      this.hideOverlay();
    }
  };

  handleNoInvoiceReturn = () => {
    return;
  };

  handleQAAction = (authtoken, command) => {
    this.setState({ downloading: true }, () => {
      this.showOverlay({
        type: Constants.OVERLAY_PROGRESS,
        text: "Resetting QA data...",
      });
    });
    const url = Constants.URL_COMMAND;
    let params = { authtoken: authtoken, command: command };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        this.setState({ downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "QA data reset",
          });
        });
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error resetting QA data",
          });
        });
      }
    });
  };

  handleCheckGiftCardBalance = () => {
    const overlay = {
      type: Constants.OVERLAY_INPUT_BOX,
      title: "Check Gift Card Balance",
      text: "Enter gift card number",
      placeholder: "Scan or enter card number",
      maxLength: 255,
      key: "",
      callback: this.maybeGetGiftCardBalance,
      inputcontainerClass: "giftCardNumberInputContainer",
      required: true,
    };
    this.showOverlay(overlay);
  };

  handleEditMaintenanceMode = () => {
    this.showOverlay({
      type: Constants.OVERLAY_INPUT_BOX,
      text: "Maintenance mode message? (Leave blank to disable)",
      maxLength: 255,
      callback: (response, key, message) => {
        if (response === Constants.OVERLAY_RESPONSE_OK) {
          this.putMaintenanceMode(message);
        } else {
          this.hideOverlay();
        }
      },
    });
  };

  maybeGetGiftCardBalance = (response, key, input) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      this.getPayment(
        input,
        giftcard => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Gift Card Balance: " + numeral(giftcard.balance).format(Constants.CURRENCY_WITH_SYMBOL),
          });
        },
        message => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: message,
          });
        }
      );
    } else {
      this.hideOverlay();
    }
  };

  handlePasswordChange = (resetuuid, pass, callback = null) => {
    if (resetuuid) {
      const passwordrecovery = { resetuuid: resetuuid, pass: pass };
      this.postUser(null, passwordrecovery, callback);
    }
  };

  handleShowImportPrompt = () => {
    this.hideOverlay();
    this.updateBreadcrumb(this.initialStateValues());
    this.setCurrentView(Constants.IMPORT, Constants.IMPORT);
  };

  handleInventory = (action, updateuninventoried = null) => {
    if (action === Constants.ACTION_INVENTORY_BEGIN) {
      this.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Begin inventory reconciliation?",
        callback: response => {
          if (response === Constants.OVERLAY_RESPONSE_YES) {
            this.putProduct(action, null, () => {
              this.setCurrentView(Constants.PRODUCTS, Constants.PRODUCTS, {
                filtertype: { ...this.state.filtertype, tab: Constants.TAB_SUMMARY, disposition: Constants.DISPOSITION_INVENTORIED },
              });
            });
          }
        },
      });
    } else if (action === Constants.ACTION_INVENTORY_RESUME) {
      this.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Resume inventory reconciliation?",
        callback: response => {
          if (response === Constants.OVERLAY_RESPONSE_YES) {
            this.putProduct(action, null, () => {
              this.setCurrentView(Constants.PRODUCTS, Constants.PRODUCTS, {
                filtertype: { ...this.state.filtertype, tab: Constants.TAB_SUMMARY, disposition: Constants.DISPOSITION_INVENTORIED },
              });
            });
          }
        },
      });
    } else if (action === Constants.ACTION_INVENTORY_END) {
      this.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Before finalizing your inventory, we strongly recommend you export your full product list for audit and recovery purposes.",
        yes: "Continue",
        no: "Export",
        bullets:
          "Choose 'Export' to postpone completion of inventory and initiate an export of your product information.\n" +
          "Choose 'Continue' if you have already exported your full product list and you will be prompted to finalize your inventory.",
        callback: response => {
          if (response === Constants.OVERLAY_RESPONSE_YES) {
            // this.handleExport();
            this.showOverlay({
              type: Constants.OVERLAY_QUESTION,
              text: "Complete inventory reconciliation?",
              callback: response => {
                if (response === Constants.OVERLAY_RESPONSE_YES) {
                  this.putProduct(Constants.ACTION_INVENTORY_END, updateuninventoried);
                }
              },
            });
          } else {
            this.handleProducts(() => {
              this.handleExport();
            });
          }
        },
      });
    } else if (action === Constants.ACTION_INVENTORY_CANCEL) {
      this.putProduct(Constants.ACTION_INVENTORY_CANCEL);
    } else if (action === Constants.ACTION_INVENTORY_PAUSE) {
      this.putProduct(Constants.ACTION_INVENTORY_PAUSE);
    }
  };

  // Reduce the unread count for the Twilio messages so they show correctly in the Nav Bar
  handleReduceTwilioUnreadCount = diff => {
    if (diff !== 0) {
      this.setState(prevState => ({
        twilio: { ...prevState.twilio, unread_count: prevState.twilio.unread_count - diff },
      }));
    }
  };

  postPhotoBinary = (refuuid, reftype, photo, name, size, filetype, callback) => {
    //Protect screen during downloading data
    this.setState({ downloading: true });
    this.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      text: `Uploading photo(s)...`,
    });
    if (size > 5000000) {
      this.showOverlay(
        {
          type: Constants.OVERLAY_MESSAGE,
          text: `The file '${name}' is too large. Please upload version smaller than 5MB.`,
        },
        () => {
          this.hideOverlay();
          callback(false, null);
        }
      );
      return;
    }

    //set up to make database call
    const url = Constants.URL_UPLOADS;
    const data = {
      refuuid: refuuid,
      reftype: reftype,
      upload: photo,
      name: name,
      size: size,
      filetype: filetype,
      debug: 1,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body?.uploaduuid) {
        this.hideOverlay();
        callback(true, response.body);
      } else {
        this.showOverlay(
          {
            type: Constants.OVERLAY_MESSAGE,
            text: `The file '${name}' could not be uploaded.`,
          },
          () => {
            callback(false, null);
          }
        );
      }
      this.setState({ downloading: false });
    });
  };

  persistState = () => {
    // Pass this function to every setState() function call, which will write a
    //   copy of the current state and breadcrumbs to local storage.
    //   Refreshing the page will load the state from local storage.
    if (this.state) {
      // Remove certain elements from the state before saving: overlay, url params, error, etc
      let pstate = Helper.deepCopy({
        ...this.state,
        overlay: null,
        action: null,
        block: null,
        complaint: null,
        error: null,
        puuid: null,
        resetuuid: null,
      });
      sessionStorage.setItem(Constants.SESSION_STORAGE_STATE, JSON.stringify(pstate));
    }
  };

  initialStateValues = () => {
    return {
      breadcrumbs: [],
      contactSearchKey: null,
      import: null,
      productSearchKey: null,
      searchkey: "",
      selectedItem: null,
    };
  };

  resetState = () => {
    this.setState(
      {
        breadcrumbs: [],
        currentMenu: Constants.DASHBOARD,
        currentView: Constants.DASHBOARD,
        page: 1,
        error: null,
        overlay: null,
      },
      this.persistState
    );
  };

  addBreadcrumb = () => {
    // Copy the current state
    const current = {
      currentMenu: this.state.currentMenu,
      currentView: this.state.currentView,
      selectedItem: this.state.selectedItem,
      filtertype: this.state.filtertype,
      page: this.state.page,
    };

    // Copy each breadcrumb from the current breadcrumb stack to a new list of breadcrumbs
    let newBreadcrumbs = this.state.breadcrumbs.map(crumb => {
      const copy = Helper.deepCopy(crumb);
      return copy;
    });

    // Check if an order is being added that is the same type as the previous order on the breadcrumb stack.
    // This should only happen when an order is saved (new -> saved) so order type will be the same.
    if (
      newBreadcrumbs.length > 0 &&
      newBreadcrumbs[newBreadcrumbs.length - 1].currentView === this.state.currentView &&
      newBreadcrumbs[newBreadcrumbs.length - 1].selectedItem &&
      newBreadcrumbs[newBreadcrumbs.length - 1].selectedItem.ordertype &&
      newBreadcrumbs[newBreadcrumbs.length - 1].selectedItem.ordertype === current.selectedItem.ordertype &&
      newBreadcrumbs[newBreadcrumbs.length - 1].selectedItem?.ordersubtype === current.selectedItem?.ordersubtype
    ) {
      console.log(Helper.clTimestamp(), "Saved new order. Not adding to breadbrumb stack.");
    } else {
      newBreadcrumbs.push(current);
      this.setState({ breadcrumbs: newBreadcrumbs });
    }
  };

  // The "changes" parameter defines any additional state values that need to be included
  //    such as an update/clearing of the "searchkey"
  updateBreadcrumb = changes => {
    if (this.state.breadcrumbs.length > 0) {
      // Pop the last state, update it, and push it back
      const prev = this.state.breadcrumbs.pop();
      const current = Object.assign({}, prev, changes ? changes : {});
      this.state.breadcrumbs.push(current);
      this.setState(current, this.persistState);
    }
  };

  updateFilterType = (filtertype, callback = null) => {
    this.setState(
      prevState => ({ filtertype: { ...prevState.filtertype, ...filtertype } }),
      () => {
        this.updateBreadcrumb({ filtertype: this.state.filtertype });
        this.persistState();
        if (callback) {
          callback();
        }
      }
    );
  };

  badBreadcrumb = () => {
    this.followBreadcrumb();
  };

  followBreadcrumb = () => {
    if (this.state.breadcrumbs.length > 1) {
      // Pop the last state as well as the new "current" state
      // When we setState() the new "current" state, it will get pushed onto the stack
      this.state.breadcrumbs.pop();
      const current = this.state.breadcrumbs.pop();
      this.setState(current, this.persistState);
    } else {
      console.log(Helper.clTimestamp(), "Breadcrumb issue. Resolving...");
      console.log(Helper.clTimestamp(), this.state);
      // Reset the breadcrumb list and move up to the top-level state
      this.updateBreadcrumb(this.initialStateValues());
      if (this.listUpdateCallback) {
        this.listUpdateCallback("");
      }
      this.setCurrentView(this.state.currentView + "s", this.state.currentView + "s");
    }
  };

  // Shows an overlay
  // The "after_render_callback" will be invoked after the overlay is rendered
  showOverlay = (overlay = null, after_render_callback = null) => {
    // Shows/hides an overlay based on the "overlay" object params
    //   No overlay object == Invisible overlay being shown
    //   Overlay object with type Constants.OVERLAY_WAITING will show/hide invisible overlay based on Constants.OVERLAY_WAITING param
    //   Overlay object with type Constants.OVERLAY_MESSAGE will show a message with an Ok button
    //   Overlay object with type Constants.OVERLAY_QUESTION will show the question with Ok/Cancel buttons and
    //       the callback function from the overlay object will get called and passed "ok" or "cancel"
    //       along with an optional "key" value (which might be required by the calling code)
    //   Overlay object with type Constants.OVERLAY_IMPORT will show and import dialog with Import/Cancel options
    //   Overlay object with type Constants.OVERLAY_PROGRESS will show a progress dialog with a message
    //   Overlay object with type Constants.OVERLAY_INPUT_BOX will prompt the user to input a value
    //   *************************************************************************************************
    //   Don't forget to modify handleKeyDown() to handle any new overlay types that accept keyboard input
    //   *************************************************************************************************

    // No overlay object and no overlay already showing
    if (!overlay && !this.state.overlay) {
      this.setState({ overlay: { type: Constants.OVERLAY_WAITING, waiting: true } });
    } else {
      if (overlay?.type === Constants.OVERLAY_INPUT_BOX) {
        overlay.input = (overlay.input || "").trim();
        overlay.submitOnEnter = overlay.submitOnEnter !== undefined ? overlay.submitOnEnter : true;
        overlay.required = overlay.required || false;
      } else if (overlay?.type === Constants.OVERLAY_SEARCH_PRODUCTS) {
        overlay.processing = false;
      } else if (overlay?.type === Constants.OVERLAY_CHANGE_PASSWORD) {
        overlay.old_password = "";
        overlay.new_password = "";
        overlay.old_passtype = "password";
        overlay.new_passtype = "password";
      } else if (overlay?.type === Constants.OVERLAY_AUTH_PROMPT) {
        this.overlayDisabled = false;
        overlay.user = overlay.user || "";
        overlay.pass = "";
        overlay.passtype = "password";
        overlay.isReadyToSubmit = false;
        overlay.message = "";
        overlay.error = "";
        overlay.hideOnSuccess = overlay.hideOnSuccess ?? true;
      } else if (overlay?.type === Constants.OVERLAY_DATE_FILTER) {
        overlay.showFilterOptions = false;
        overlay.dateFilterOn = this.state.filtertype?.orderDateFilter?.filterOn || Constants.DATE_FILTER_CREATION;
        overlay.start = this.state.filtertype?.orderDateFilter?.start || Helper.dateAsString(new Date(2023, 0, 1));
        overlay.end = this.state.filtertype?.orderDateFilter?.end || Helper.dateAsString(new Date());
        overlay.dateFilterType = this.state.filtertype?.orderDateFilter?.dateFilterType || Constants.FILTER_DATE_ALL;
      } else if (overlay?.type === Constants.OVERLAY_TOKENIZE) {
        if (overlay.tokenize) {
          overlay.billing_first = "";
          overlay.billing_last = "";
          overlay.billing_zip = "";
          overlay.embeddedFieldCardNumber = false;
          overlay.embeddedFieldExpiration = false;
          overlay.embeddedFieldCVV = false;
          overlay.isReadyToSubmit = true;
          this.handleGetTransientToken(overlay.recaptcha || false);
        } else {
          overlay.isReadyToSubmit = true;
        }
      } else if (overlay?.type === Constants.OVERLAY_IMPORT) {
        this.handleResetImport();
      }
      this.setState({ overlay: overlay }, () => {
        // Add appropriate key listeners
        if (Constants.OVERLAY_KEY_INPUTS.includes(overlay?.type)) {
          document.addEventListener("keydown", this.handleOverlayKeypress);
        }

        if (overlay?.type === Constants.OVERLAY_SEARCH_CONTACTS && this.state.overlay.inputFieldRef && this.state.overlay.inputFieldRef.current) {
          this.state.overlay.inputFieldRef.current.focus();
        }
        if (overlay?.type === Constants.OVERLAY_SEARCH_PRODUCTS && this.state.overlay.inputFieldRef && this.state.overlay.inputFieldRef.current) {
          this.state.overlay.inputFieldRef.current.focus();
        }
        if (overlay?.type === Constants.OVERLAY_INPUT_BOX && this.inputboxRef.current) {
          this.inputboxRef.current.focus();
        }
        if (overlay?.type === Constants.OVERLAY_QUESTION && overlay?.confirmationText && this.inputboxRef.current) {
          this.inputboxRef.current.focus();
        }

        if (after_render_callback) {
          after_render_callback();
        }
      });
    }
  };

  handleOverlayKeypress = event => {
    const isLetter = event.keyCode >= 65 && event.keyCode <= 90;
    const isNumber = (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode >= 48 && event.keyCode <= 57);
    const isBackspace = event.keyCode === 8;
    // Ignore Ctrl+? and Alt+? events
    if (event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }
    if (isLetter || isNumber || isBackspace) {
      // If an overlay is showing, then pass the keypress to it
      // Only pass key press event to the question overlay if a confirmation text is required
      if (Constants.OVERLAY_KEY_INPUTS.includes(this.state?.overlay?.type)) {
        // Let the overlay handle the keypress
        if (this.state?.overlay?.type === Constants.OVERLAY_SEARCH_CONTACTS && this.state.overlay?.inputFieldRef?.current) {
          this.state.overlay.inputFieldRef.current.focus();
        } else if (this.state?.overlay?.type === Constants.OVERLAY_SEARCH_PRODUCTS && this.state.overlay?.inputFieldRef?.current) {
          this.state.overlay.inputFieldRef.current.focus();
        } else if (this.state?.overlay?.type === Constants.OVERLAY_QUESTION || this.state?.overlay?.type === Constants.OVERLAY_INPUT_BOX) {
          if (this.inputboxRef?.current) {
            this.inputboxRef.current.focus();
          }
        }
        // TODO: Add other overlays that need keypress handling here
      }
    } else if (event.keyCode === Constants.ESC) {
      if (this.state?.overlay?.type === Constants.OVERLAY_SEARCH_CONTACTS) {
        this.handleSearchCallback(Constants.OVERLAY_RESPONSE_CANCEL);
      } else if (this.state?.overlay?.type === Constants.OVERLAY_SEARCH_PRODUCTS) {
        this.handleSearchCallback(Constants.OVERLAY_RESPONSE_CANCEL);
      } else if (this.state?.overlay?.type === Constants.OVERLAY_INPUT_BOX) {
        this.handleInputCallback(Constants.OVERLAY_RESPONSE_CANCEL);
      }
    } else if (this.state.overlay?.submitOnEnter && event.keyCode === Constants.ENTER) {
      if (this.state?.overlay?.type === Constants.OVERLAY_INPUT_BOX) {
        event.preventDefault();
        this.handleInputCallback(Constants.OVERLAY_RESPONSE_OK);
      }
    }
    // Handle arrow keys for picker
    else if (this.state?.overlay?.type === Constants.OVERLAY_PICKER) {
      if (event.keyCode === Constants.UP_ARROW) {
        console.log("UP");
      } else if (event.keyCode === Constants.DOWN_ARROW) {
        console.log("DN");
      }
    }
  };

  maybeHideOverlay = event => {
    if (event.target.id === "overlay") {
      if (this.state.overlay && this.state.overlay.type === Constants.OVERLAY_GEAR && this.gearCloseFunctionCallback) {
        this.gearCloseFunctionCallback();
      } else {
        this.hideOverlay();
      }
    }
  };

  hideOverlay = (forcehide = false) => {
    // If hideOverlay is getting called incorrectly, uncomment the line below to trace
    // console.trace();
    // Hides the current overlay if it's not interactive (i.e. waiting, progress)
    //   or if the passed forcehide flag is true
    if (!this.isInteractiveOverlay() || forcehide) {
      this.setState({ overlay: null }, () => {
        document.removeEventListener("keydown", this.handleOverlayKeypress);
      });
    }
  };

  isInteractiveOverlay = () => {
    if (!this.state || !this.state.overlay || this.state.overlay === null) {
      return false;
    }
    return this.state.overlay.type === Constants.OVERLAY_PICKER;
  };

  checkLogin = (callback = null) => {
    const session = localStorage.getItem(Constants.LOCAL_STORAGE_SESSION);
    // If there is no session token in local storage, use an empty token
    // so this function will execute and update the screen asynchronously
    if (!session) {
      this.setState({ isLoggedIn: false }, () => {
        this.persistState();
        this.hideOverlay();
        Helper.clearLocalStorage();
      });
      return;
    }
    // const url = Constants.URL_SESSIONS;
    const url = Constants.URL_SESSIONS_RO;
    Helper.getData(url).then(response => {
      if (response.status === 200 && response.body.settings) {
        let clientSettings;
        let customFields;
        let displaySettings;
        let families;
        let handpoint;
        let valor;
        let inventory;
        let maast;
        let technicians;
        let thirdparty;
        let twilio;
        let banner = response.body.banner;
        ({ families, technicians, displaySettings, clientSettings, handpoint, valor, maast, thirdparty, customFields, inventory, twilio } =
          this.extractClientSettings(response.body.settings));
        const replyToAllowed = response.body.settings.find(
          setting =>
            setting.category === Constants.SETTINGS_CATEGORY_MAIL &&
            setting.description === Constants.SETTINGS_REPLY_TO_ALLOWED &&
            setting.value === response.body.useruuid
        )
          ? true
          : false;
        let uploadsUrl = response.body.settings.find(
          setting => setting.category === Constants.SETTINGS_CATEGORY_UPLOADS && setting.description === Constants.SETTINGS_API_URL
        );
        uploadsUrl = uploadsUrl ? uploadsUrl.value : null;
        this.setState(
          {
            banner: banner,
            clientuuid: response.body.clientuuid,
            clientSettings: clientSettings,
            customFields: customFields,
            displaySettings: displaySettings,
            error: null,
            families: families,
            features: response.body.features ?? [],
            handpoint: handpoint,
            inventory: inventory,
            isLoggedIn: true,
            maast: maast,
            peers: response.body.peers ?? [],
            pendingprospectcount: response.body.pendingprospectcount,
            replyToAllowed: replyToAllowed,
            salesperson: response.body.fullname,
            subdomain: response.body.subdomain,
            technicians: technicians,
            thirdparty: thirdparty,
            twilio: twilio,
            uploadsUrl: uploadsUrl,
            username: response.body.username,
            usertype: response.body.usertype,
            useruuid: response.body.useruuid,
            valor: valor,
          },
          () => {
            this.persistState();
            if (callback) {
              callback();
            }
            // Background process to get the Walk-in & Stock Order customers' details
            this.getProtectedCustomers();
            // Background process to get UPC list
            this.getUPCList();
          }
        );
        this.checkVersion();
      } else if (response.status === 501) {
        // Hard stop the user from doing anything while the system is undergoing maintenance
        this.renderMaintenanceMode(response);
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false, usertype: null }, () => {
          this.hideOverlay();
        });
      } else {
        this.setState({ isLoggedIn: false, error: null, usertype: null }, () => {
          this.persistState();
          this.hideOverlay();
          Helper.clearLocalStorage();
        });
        this.checkVersion();
      }
    });
  };

  checkVersion = () => {
    // Check the version of the software to see if a page refresh is needed
    let version = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_VERSION));
    Helper.getResource(Constants.URL_VERSION_TXT).then(response => {
      if (response.status === 200) {
        const parts = response.body.split("\n");
        const runid = parts[0];
        const runnumber = parts[1];
        const runattempt = parts[2];
        if (!version) {
          localStorage.setItem(
            Constants.LOCAL_STORAGE_VERSION,
            JSON.stringify({
              id: runid,
              number: runnumber,
              attempt: runattempt,
            })
          );
        } else if (version.id !== runid || version.number !== runnumber || version.id !== runid) {
          localStorage.setItem(
            Constants.LOCAL_STORAGE_VERSION,
            JSON.stringify({
              id: runid,
              number: runnumber,
              attempt: runattempt,
            })
          );
          window.location.reload(false);
        }
      }
      //this.hideOverlay();
    });
  };

  getAppState = () => {
    return {
      clientuuid: this.state.clientuuid,
      currentMenu: this.state.currentMenu,
      currentView: this.state.currentView,
      displaySettings: this.state.displaySettings,
      clientSettings: this.state.clientSettings,
      colorMode: this.state.colorMode,
      customFields: this.state.customFields,
      families: this.state.families,
      features: this.state.features,
      handpoint: this.state.handpoint,
      inventory: this.state.inventory,
      isqa: Helper.isQaAdmin(this.state),
      maast: this.state.maast,
      overlay: this.state.overlay,
      peers: this.state.peers,
      pendingprospectcount: this.state.pendingprospectcount,
      replyToAllowed: this.state.replyToAllowed,
      salesperson: this.state.salesperson,
      subdomain: this.state.subdomain,
      technicians: this.state.technicians,
      thirdparty: this.state.thirdparty,
      twilio: this.state.twilio,
      uploadsUrl: this.state.uploadsUrl,
      username: this.state.username || "",
      usertype: this.state.usertype,
      valor: this.state.valor,
    };
  };

  setListUpdateCallback = callback => {
    // Store the list component's callback function for forcing a list update
    this.listUpdateCallback = callback;
  };

  setExportCallback = callback => {
    this.exportCallback = callback;
  };

  setGearCloseFunctionCallback = callback => {
    this.gearCloseFunctionCallback = callback;
  };

  setCurrentView = (currentView, currentMenu, additionalState = {}) => {
    this.setState(
      {
        ...additionalState,
        currentMenu: currentMenu,
        currentView: currentView,
        page: 1,
      },
      () => {
        this.persistState();
        // If this a top-level view, then extend the user session
        if (Constants.TOP_LEVEL_VIEWS.includes(currentView)) {
          const sessionToken = localStorage.getItem(Constants.LOCAL_STORAGE_SESSION);
          this.putSession(sessionToken);
        }
      }
    );
  };

  getClientList = callback => {
    this.setState({
      downloading: true,
    });
    const url = Constants.URL_CLIENTS;
    const params = {
      q: Constants.ALL,
    };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        callback(response.body.records);
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error retrieving the client list.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getContactList = (searchkey, companytypes) => {
    this.setState({
      downloading: true,
    });
    const params = { companytypes: companytypes };
    const url = Constants.URL_CONTACTS;
    if (searchkey) {
      params.searchkey = encodeURIComponent(searchkey.trim());
    }
    params.active = true;
    // Append the start and limit values
    params.searchstart = 0;
    params.searchlimit = 5;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        this.setState(prevState => ({
          overlay: { ...prevState.overlay, searchresults: response.body.records },
        }));
      } else {
        console.log(Helper.clTimestamp(), "Error");
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getPayment = (giftCardNumber, callbackFound, callbackNotFound) => {
    //Protect screen during downloading data
    this.showOverlay();
    this.setState({ downloading: true });

    // Build the request
    const request = {
      action: Constants.REQUEST_GET_BALANCE,
      giftCardNumber: giftCardNumber,
    };

    //set up to make database call
    const url = Constants.URL_PAYMENTS;
    Helper.getData(url, request).then(response => {
      if (response.status === 200 && response.body) {
        // The entire gift card comes back in the request
        const giftcard = response.body;
        callbackFound(giftcard);
      } else if (response.status === 404) {
        this.setState({ error: null, downloading: false }, () => {
          callbackNotFound(response.body.message);
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error checking the balance.",
          });
        });
      }
    });
  };

  // Queries the API for a product with the specified Store SKU or UPC value
  getProduct = (sku_upc, callback) => {
    //Protect screen during downloading data
    this.showOverlay();
    this.setState({ downloading: true });

    // Build the request
    const request = {
      sku: sku_upc,
    };

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    Helper.getData(url, request).then(response => {
      callback(response);
    });
  };

  getProductList = searchkey => {
    if (!searchkey.trim()) {
      return;
    }
    this.setState({
      downloading: true,
    });
    const params = {
      searchkey: encodeURIComponent(searchkey.trim()),
      active: true,
      searchstart: 0,
      searchlimit: this.state.displaySettings.FOLDER_ROWS,
      exclude_gift_cards: true,
    };
    const url = Constants.URL_PRODUCTS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        this.setState(prevState => ({
          overlay: { ...prevState.overlay, searchresults: response.body.records, processing: false },
        }));
      } else {
        this.setState({ error: "GET" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error searching the products.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  getEmail = (email, item, url, callback) => {
    let params = {
      action: Constants.EMAIL_ACTION_GET_MESSAGES,
    };
    if (item?.orderuuid) {
      params.orderuuid = item.orderuuid;
    } else if (email) {
      params.email = email;
    } else {
      // Handle the very specific case of a prospect or company with a UUID
      // Then default to the companyuuid or contactuuid (generically)
      if (item.type === Constants.PROSPECT && item.uuid) {
        params.prospectuuid = item.uuid;
      } else if (item.type === Constants.COMPANY && item.uuid) {
        params.companyuuid = item.uuid;
      } else if (item?.companyuuid) {
        params.companyuuid = item.companyuuid;
      } else if (item?.contactuuid) {
        params.contactuuid = item.contactuuid;
      }
    }
    Helper.getData(url, params).then(response => {
      if (response.status === 200 || response.status === 404) {
        callback(response);
      } else {
        this.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error loading the messages.",
        });
      }
    });
  };

  getText = (item, markread, url, searchlimit, callback) => {
    let params = {
      action: Constants.SMS_ACTION_GET_MESSAGES,
      markread: markread,
      searchstart: 0,
      searchlimit: searchlimit,
    };

    // From the Messages view, we have the SMS contact already. Add their UUID
    if (item.smscontactuuid) {
      params.smscontactuuid = item.smscontactuuid;
    } else if (item.companyuuid) {
      params.companyuuid = item.companyuuid;
    }

    // Including a new/old messageuuid in the request will look for newer/older messages since the last time we checked
    if (item?.newmessageuuid) {
      params.newmessageuuid = item.newmessageuuid;
    } else if (item?.oldmessageuuid) {
      params.oldmessageuuid = item.oldmessageuuid;
    }

    Helper.getData(url, params).then(response => {
      if (response.status === 200 || response.status === 404) {
        callback(response);
      } else {
        this.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error loading the messages.",
        });
      }
    });
  };

  // This function will run quietly in the background and download the walk-in customer
  // to be stored in local storage for quick access
  // but only if it's not there already
  getProtectedCustomers = () => {
    if (this.state.clientSettings?.WALKIN_CUSTOMER && !localStorage.getItem(Constants.LOCAL_STORAGE_WALKIN_CUSTOMER)) {
      const url = Constants.URL_COMPANIES;
      const params = {
        contactuuid: this.state.clientSettings.WALKIN_CUSTOMER,
      };
      Helper.getData(url, params).then(response => {
        if (response.status === 200 && response.body) {
          // Store the walk-in customer in local storage
          localStorage.setItem(Constants.LOCAL_STORAGE_WALKIN_CUSTOMER, JSON.stringify(response.body));
        }
      });
    }
    if (this.state.clientSettings?.STOCK_ORDER_CUSTOMER && !localStorage.getItem(Constants.LOCAL_STORAGE_STOCK_ORDER_CUSTOMER)) {
      const url = Constants.URL_COMPANIES;
      const params = {
        contactuuid: this.state.clientSettings.STOCK_ORDER_CUSTOMER,
      };
      Helper.getData(url, params).then(response => {
        if (response.status === 200 && response.body) {
          // Store the walk-in customer in local storage
          localStorage.setItem(Constants.LOCAL_STORAGE_STOCK_ORDER_CUSTOMER, JSON.stringify(response.body));
        }
      });
    }
  };

  // This function will run quietly in the background and download all products (minimal attributes) with UPCs
  // to be stored in local storage for quick access
  // but only if it's not there already
  getUPCList = () => {
    // Check for "Get UPCs" config data in local storage
    let config = {
      searchstart: 0,
      record_count: 0,
      status: Constants.UPC_CONFIG_STATUS_NOT_STARTED,
    };
    const configString = localStorage.getItem(Constants.LOCAL_STORAGE_UPC_CONFIG);
    if (configString) {
      config = JSON.parse(configString);
    }
    // If the process has not been started, then put the default "Get UPCs" config data in local storage
    if (config.status === Constants.UPC_CONFIG_STATUS_NOT_STARTED || (config.status === Constants.UPC_CONFIG_STATUS_ERROR && config.errorCount < 2)) {
      config.status = Constants.UPC_CONFIG_STATUS_GETTING_UPCS;
      localStorage.setItem(Constants.LOCAL_STORAGE_UPC_CONFIG, JSON.stringify(config));
      // Put an empty list in local storage to start with
      localStorage.setItem(Constants.LOCAL_STORAGE_UPC_LIST, "[]");
      this.doGetUPCList(config, Constants.LIST_DOWNLOAD_BLOCK_SIZE_XLARGE);
    }
  };

  doGetUPCList = (config, searchLimit) => {
    const url = Constants.URL_PRODUCTS;
    const params = {
      action: Constants.ACTION_GET_UPCS,
      searchstart: config.searchstart,
      searchlimit: searchLimit,
    };
    Helper.getData(url, params).then(response => {
      if (response.status === 200) {
        // Append the UPC list in local storage
        let upcListFromLocalStorage = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_UPC_LIST));
        upcListFromLocalStorage = upcListFromLocalStorage.concat(response.body.records);
        let storageError = false;
        try {
          localStorage.setItem(Constants.LOCAL_STORAGE_UPC_LIST, JSON.stringify(upcListFromLocalStorage));
        } catch (e) {
          console.log(Helper.clTimestamp(), "Local storage limit reached");
          storageError = true;
        }
        // Update the config data in local storage
        config.searchstart += searchLimit;
        localStorage.setItem(Constants.LOCAL_STORAGE_UPC_CONFIG, JSON.stringify(config));

        // If there are more UPCs to download, then do it
        const upcListLength = upcListFromLocalStorage.length;
        if (!storageError && upcListLength < response.body.record_count && response.body.records.length !== 0) {
          this.doGetUPCList(config, Constants.LIST_DOWNLOAD_BLOCK_SIZE_LARGE);
        } else {
          // Update the config data in local storage
          config.status = Constants.UPC_CONFIG_STATUS_COMPLETE;
          localStorage.setItem(Constants.LOCAL_STORAGE_UPC_CONFIG, JSON.stringify(config));
        }
      } else {
        // Update the config data in local storage
        config.status = Constants.UPC_CONFIG_STATUS_ERROR;
        config.errorCount = config.errorCount ? config.errorCount + 1 : 1;
        console.log(Helper.clTimestamp(), "Error getting UPCs");
        localStorage.setItem(Constants.LOCAL_STORAGE_UPC_CONFIG, JSON.stringify(config));
      }
    });
  };

  /**
   * Send a password recovery request to the server
   * to request an email be sent to the user.
   * Or, send a password reset request to the server.
   */
  postUser = (user = null, recovery = null, callback = null) => {
    this.setState({
      downloading: true,
    });
    let params = {};
    if (user) {
      params = { action: Constants.PASSWORD_RECOVERY, username: user };
    } else if (recovery) {
      params = {
        action: Constants.PASSWORD_RESET,
        resetuuid: recovery.resetuuid,
        password: recovery.pass,
      };
    }
    const url = Constants.URL_USERS;
    Helper.postData(url, params).then(response => {
      if (response.status === 200) {
        if (callback) {
          callback();
        }
      } else if (response.status === 400) {
        this.setState({ downloading: false });
        this.setState({ error: "POST" }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "POST" }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error submitting the request.",
          });
        });
      }
      this.setState({
        downloading: false,
      });
    });
  };

  // Assumed to be a clock in action
  postTimesheet = authtoken => {
    this.setState({
      downloading: true,
    });
    let params = {
      authtoken: authtoken,
    };
    const url = Constants.URL_TIMESHEETS;
    Helper.postData(url, params).then(response => {
      if (response.status === 200) {
        this.setState(
          {
            downloading: false,
          },
          () => {
            this.listUpdateCallback("");
          }
        );
      } else if (response.status === 400) {
        this.setState({ downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "You are already clocked in.",
          });
        });
      } else if (response.status === 401) {
        this.setState({ downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Invalid user. Wrong company.",
          });
        });
      } else {
        this.setState({ downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      }
    });
  };

  postVerifyEmail = callback => {
    //Protect screen during downloading data
    this.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_MAIL;
    let data = {
      action: Constants.VERIFY_EMAIL,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200) {
        if ([Constants.TRUE, Constants.FALSE, Constants.MAYBE].includes(response?.body?.verified)) {
          let verified = response.body.verified;
          this.setState(
            prevState => ({
              downloading: false,
              clientSettings: { ...prevState.clientSettings, FROM_VERIFIED: verified },
            }),
            () => {
              if (callback) {
                callback(verified);
              }
              this.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: response.body?.message || "Email verification request submitted. You should receive a verification email within five minutes.",
              });
            }
          );
        } else {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body?.message || "There was an error sending the verification email.",
          });
        }
      } else {
        this.setState({ downloading: false }, () => {
          if (callback) {
            callback(false);
          }
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body?.message || "There was an error sending the verification email.",
          });
        });
      }
    });
  };

  postCampaignLink = (email, firstname, lastname, mobilephone, campaignuuid, callback) => {
    let params = {
      email: email,
      firstname: firstname,
      lastname: lastname,
      mobilephone: mobilephone,
      campaignuuid: campaignuuid,
    };
    const url = Constants.URL_CAMPAIGNS;
    Helper.postData(url, params).then(response => {
      if (response.status === 200) {
        this.setState({ downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            title: "You're not done yet!",
            text: "Please check your email for a link to the requested form.\nYou will need to click the link to complete the process.",
          });
          if (callback) {
            callback();
          }
        });
      } else {
        this.setState({ downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response?.body?.message ?? "There was an error sending the link.",
          });
        });
      }
    });
  };

  putUserClientID = (clientuuid, callback, failureCallback) => {
    this.setState({
      downloading: true,
    });
    let params = {
      clientuuid: clientuuid,
    };
    const url = Constants.URL_USERS;
    Helper.putData(url, params).then(response => {
      if (response.status === 200) {
        this.setState(
          {
            downloading: false,
          },
          () => {
            callback();
          }
        );
      } else {
        this.setState({ downloading: false }, () => {
          if (failureCallback) {
            failureCallback(response.body.message);
          }
        });
      }
    });
  };

  putProduct = (action, updateuninventoried, callback = null) => {
    this.setState({ downloading: true });
    let params = {
      action: action,
    };
    if (updateuninventoried) {
      params.updateuninventoried = updateuninventoried;
    }
    const url = Constants.URL_PRODUCTS;
    Helper.putData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        const inventoryState = response.body.inventorystate === "" ? null : response.body.inventorystate;
        this.setState({ downloading: false, inventory: inventoryState }, () => {
          // Clear local storage
          if ([Constants.ACTION_INVENTORY_CANCEL, Constants.ACTION_INVENTORY_END, Constants.ACTION_INVENTORY_BEGIN].includes(action)) {
            Helper.clearLocalStorageInventory();
          }
          // If we're ending inventory reconciliation and we're on the product list view, reset back to the products tab view
          if (
            this.state.currentView === Constants.PRODUCTS &&
            [Constants.ACTION_INVENTORY_CANCEL, Constants.ACTION_INVENTORY_END, Constants.ACTION_INVENTORY_PAUSE].includes(action)
          ) {
            this.updateFilterType({ tab: Constants.TAB_PRODUCTS }, () => {
              // Refresh the product list
              if (this.listUpdateCallback) {
                this.listUpdateCallback("");
              }
            });
          }
          if (callback) {
            callback();
          }
        });
      } else {
        this.setState({ downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the inventory state.",
          });
        });
      }
    });
  };

  // Assumed to be a clock out action if no clockin or clockout params
  putTimesheet = (authtoken, timesheet, clockin = null, clockout = null, failureCallback = null) => {
    this.setState({
      downloading: true,
    });
    let params = {
      authtoken: authtoken,
      timesheetuuid: timesheet.timesheetuuid,
    };
    if (clockin) {
      params.clockin = Helper.formatDateTimeForWebService(clockin);
    }
    if (clockout) {
      params.clockout = Helper.formatDateTimeForWebService(clockout);
    }
    const url = Constants.URL_TIMESHEETS;
    Helper.putData(url, params).then(response => {
      if (response.status === 200) {
        this.setState(
          {
            downloading: false,
          },
          () => {
            this.listUpdateCallback("");
          }
        );
      } else {
        this.setState({ downloading: false }, () => {
          if (failureCallback) {
            failureCallback();
          }
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      }
    });
  };

  putMaintenanceMode = message => {
    this.setState({
      downloading: true,
    });
    let params = {
      command: Constants.COMMAND_MAINTENANCE_MODE,
      message: message,
    };
    const url = Constants.URL_COMMAND;
    Helper.putData(url, params).then(response => {
      if (response.status === 200) {
        this.setState({ downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "The maintenance mode has been updated.",
          });
        });
      } else {
        this.setState({ downloading: false }, () => {
          this.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the maintenance mode.",
          });
        });
      }
    });
  };

  getAuthToken = (username, password, callback) => {
    this.setState({
      downloading: true,
    });
    let params = {
      username: username,
      password: password,
      action: Constants.GET_AUTH_TOKEN,
    };
    const url = Constants.URL_USERS;
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.authtoken) {
        if (callback) {
          callback(response.body.authtoken);
        }
      } else {
        if (callback) {
          callback();
        }
      }
      this.setState({
        downloading: false,
      });
    });
  };

  renderMaintenanceMode(response) {
    this.showOverlay({
      type: Constants.OVERLAY_PROGRESS,
      spinner: <FontAwesomeIcon icon={faPaw} />,
      cancelLabel: "Try Again",
      opaque: true,
      cancelCallback: () => {
        this.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Retrying...", opaque: true });
        this.checkLogin(() => {
          this.hideOverlay();
        });
      },
      text: response.body?.message || "System ungoing maintenance. Please try again later.",
    });
  }

  extractClientSettings(settings) {
    let displaySettings = {};
    let clientSettings = {};

    // Get repair families
    let families = settings.filter(item => item.description === Constants.SETTINGS_FAMILIES && item.value);
    families.sort((a, b) => a.value.localeCompare(b.value));

    // Get repair techs
    let technicians = settings.filter(item => item.description === Constants.SETTINGS_TECHNICIAN && item.value);
    technicians.sort((a, b) => a.value.localeCompare(b.value));

    // Get the store settings
    settings.forEach(item => {
      if (item.category === Constants.SETTINGS_CATEGORY_DISPLAY) {
        displaySettings[item.description] = item.value;
      }
      if (item.category === Constants.SETTINGS_CATEGORY_CLIENT) {
        clientSettings[item.description] = item.value;
      }
    });

    // HandPoint settings
    let handpoint = {};
    handpoint.enabled = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_HANDPOINT, Constants.SETTINGS_ENABLED);

    // MAAST settings
    let maast = {};
    maast.merchant_id = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_MAAST_MERCHANT_ID);
    maast.mode = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_MODE);
    maast.enabled = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_ENABLED);
    maast.referral_link = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_REFERRAL_LINK);
    maast.partialPaymentManual = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_DEFAULT_PARTIAL_MANUAL);
    maast.partialPaymentVault = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_MAAST, Constants.SETTINGS_DEFAULT_PARTIAL_VAULT);

    // Get Valor settings
    let valor = {};
    valor.enabled = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_VALOR, Constants.SETTINGS_ENABLED);
    valor.appid = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_VALOR_SECURITY, Constants.SETTINGS_VALOR_APP_ID);
    valor.epis = [];
    settings.forEach(item => {
      if (item.category === Constants.SETTINGS_CATEGORY_VALOR && item.description === Constants.SETTINGS_VALOR_EPI) {
        let epi = Helper.deepCopy(item);
        let setting = settings.find(setting => setting.category === Constants.SETTINGS_CATEGORY_VALOR && setting.description === epi.uuid);
        epi.fullname = setting?.value ? setting.value : "";
        setting = settings.find(setting => setting.category === Constants.SETTINGS_CATEGORY_VALOR_SECURITY && setting.description === epi.uuid);
        epi.appkey = setting?.value ? setting.value : "";
        valor.epis.push(epi);
      }
    });

    // Twilio settings
    let twilio = {};
    twilio.legalname = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_LEGAL_NAME);
    twilio.phonenumber = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_PHONE_NUMBER);
    twilio.unread_count = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_TWILIO, Constants.SETTINGS_TWILIO_UNREAD_COUNT);

    // Third-party settings
    let thirdparty = {};
    thirdparty.reverbapikey = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_REVERB, Constants.SETTINGS_API_KEY);
    thirdparty.reverbapiurl = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_REVERB, Constants.SETTINGS_API_URL);
    thirdparty.reverblastretrieved = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_REVERB, Constants.SETTINGS_LAST_RETRIEVED);
    thirdparty.reverblastretrieveduuid = Helper.getSettingUuidFromRecord(
      settings,
      Constants.SETTINGS_CATEGORY_REVERB,
      Constants.SETTINGS_LAST_RETRIEVED
    );
    thirdparty.cartloomsellerid = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_CARTLOOM, Constants.SETTINGS_SELLERID);

    let customFields = settings.filter(item => item.category === Constants.SETTINGS_CATEGORY_CUSTOM_FIELDS);
    // Sort the custom fields
    customFields.sort((a, b) => a.value.localeCompare(b.value));

    // Check for inventory flag
    let inventory = Helper.getSettingFromRecord(settings, Constants.SETTINGS_CATEGORY_INVENTORY, Constants.SETTINGS_IN_PROGRESS);
    if (inventory && Helper.isTrue(inventory)) {
      inventory = true;
    } else if (inventory && Helper.isFalse(inventory)) {
      inventory = false;
    }

    return {
      families,
      technicians,
      displaySettings,
      clientSettings,
      handpoint,
      valor,
      maast,
      thirdparty,
      customFields,
      inventory,
      twilio,
    };
  }
}

export default App;

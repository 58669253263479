import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPaw, faLock } from "@fortawesome/free-solid-svg-icons";
import { faCircle, faCircleDot, faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import * as Constants from "./Constants.jsx";
import * as Helper from "./Helper.jsx";
import numeral from "numeral";
import Capo from "./img/Capo.js";
import LogoIcon from "./img/LogoIcon";

/****************************************************************************************************************
 * IF YOU ARE CREATING A NEW BILLING PLAN, YOU MUST ADD IT ClerkHound's products table, as well as              *
 * Maast's billing plans. Be sure to set the PLANCODE to the same value as the storesku in the products table.  *
 * Be sure to set the plan name and description to the same values as the productname.                          *
 ****************************************************************************************************************/

class CreateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.waiting = false;
    this.waitingForTokenization = false;
    this.state = {
      email: "",
      message: "",
      prospect: null,
      emailPromptDisabled: false,
      messageOnly: this.props.prospectuuid ? true : false,
      embeddedFieldCardNumber: false,
      embeddedFieldExpiration: false,
      embeddedFieldCVV: false,
      plans: Constants.PLAN_TYPE_CH, // Default to showing ClerkHound plans (vs. BillingHound plans)
      products: null,
      type: "password",
      useOwner: false,
      completing: false,
      states: [],
      retrying: false,
    };
  }

  componentDidMount = () => {
    this.setState({ message: "Loading..." });
    // Load the list of valid US states
    this.getStates(() => {
      // Load a list of products to get their prices
      this.getProducts(() => {
        // System reads the prospect UUID and retrieves the prospect from the database
        if (this.props.prospectuuid) {
          this.setState({ message: "Retrieving your data..." });
          this.getProspect(this.props.prospectuuid, prospect => {
            // Store the prospect in the state so we can use it later
            this.setState({ prospect: prospect, message: "", messageOnly: false }, () => {
              if (this.state.prospect?.status === Constants.REG_PASSWORD_SET || this.state.prospect?.status === Constants.REG_PAYMENT_FAILED) {
                this.getTransientKey("");
              }
            });
          });
        } else {
          this.setState({ message: "" });
        }
      });
    });
  };

  render() {
    let wrapperClass = "login-wrapper gridCenter";
    let progress = this.renderProgress();
    let content = "";
    if (this.state.messageOnly) {
      content = this.renderMessageOnly();
    } else if (Constants.COMPLETE_REGISTRATION_STATUSES.includes(this.state.prospect?.status)) {
      content = this.renderResumeRegistration();
    } else if (this.state.prospect?.status === Constants.REG_MERCH_APPROVED) {
      content = this.renderMerchantAccountApproved();
    } else if (this.state.prospect?.status === Constants.REG_SUBSCRIBED) {
      content = this.renderSubscribed();
    } else if (this.state.prospect?.status === Constants.REG_PASSWORD_SET || this.state.prospect?.status === Constants.REG_PAYMENT_FAILED) {
      wrapperClass = "businessInfoWrapper gridCenter";
      content = this.renderPaymentInfo();
    } else if (this.state.prospect?.status === Constants.REG_BUS_INFO_PROVIDED) {
      content = this.renderPasswordPrompt();
    } else if (this.state.prospect?.status === Constants.REG_PLAN_SELECTED) {
      wrapperClass = "businessInfoWrapper gridCenter";
      content = this.renderBusinessInfo();
    } else if (this.state.prospect?.status === Constants.REG_EMAIL_PROVIDED) {
      wrapperClass = "choosePlanWrapper gridCenter";
      content = this.renderPlanSelection();
    } else if (this.state.prospect?.status === Constants.REG_NEW_CUSTOMER) {
      content = this.renderEmailPrompt();
    } else {
      content = this.renderEmailPrompt();
    }
    return (
      <div className="app-body">
        <div className="register">
          <div className="registrationLogo">
            <span className="registrationLogo">
              <Capo />
            </span>
            <span className="registrationTitle">
              <LogoIcon />
            </span>
          </div>
          {progress}
          <div className={wrapperClass}>{content}</div>
        </div>
      </div>
    );
  }

  renderProgress = () => {
    let step = 0;
    let handlePlan = () => {};
    let handleBusiness = () => {};
    let handlePassword = () => {};
    // conditionally set the progress bar to active based on the status of the prospect
    // If status is REG_EMAIL_PROVIDED, then Select Plan is highlighted
    // If status is REG_PLAN_SELECTED, then Tell us about your Business is highlighted
    // If status is REG_BUS_INFO_PROVIDED, then Select your Password is is highlighted
    // If status is REG_PASSWORD_SET, then Provide Payment Details info is highlighted
    if (this.state.prospect?.status === Constants.REG_EMAIL_PROVIDED) {
      step = 1;
    } else if (this.state.prospect?.status === Constants.REG_PLAN_SELECTED) {
      step = 2;
    } else if (this.state.prospect?.status === Constants.REG_BUS_INFO_PROVIDED) {
      step = 3;
    } else if (this.state.prospect?.status === Constants.REG_PASSWORD_SET) {
      step = 4;
    } else if (this.state.prospect?.status === Constants.REG_PAYMENT_FAILED) {
      step = 4;
    }

    // Set up the handlers
    if (step > 3) {
      handlePassword = () => {
        this.setState(prevState => ({ prospect: { ...prevState.prospect, status: Constants.REG_BUS_INFO_PROVIDED, password: "" } }));
      };
    }
    if (step > 2) {
      handleBusiness = () => {
        this.setState(prevState => ({ prospect: { ...prevState.prospect, status: Constants.REG_PLAN_SELECTED } }));
      };
    }
    if (step > 1) {
      handlePlan = () => {
        this.setState(prevState => ({ prospect: { ...prevState.prospect, status: Constants.REG_EMAIL_PROVIDED } }));
      };
    }

    if (
      [
        Constants.REG_EMAIL_PROVIDED,
        Constants.REG_PLAN_SELECTED,
        Constants.REG_BUS_INFO_PROVIDED,
        Constants.REG_PASSWORD_SET,
        Constants.REG_PAYMENT_FAILED,
      ].includes(this.state.prospect?.status)
    ) {
      return (
        <div className="progressBar">
          <span className={step === 1 ? "progressStepActive" : "progressStep"} onClick={handlePlan}>
            Plan
          </span>
          <span className="progressStep">&rarr;</span>
          <span className={step === 2 ? "progressStepActive" : "progressStep"} onClick={handleBusiness}>
            Business
          </span>
          <span className="progressStep">&rarr;</span>
          <span className={step === 3 ? "progressStepActive" : "progressStep"} onClick={handlePassword}>
            Password
          </span>
          <span className="progressStep">&rarr;</span>
          <span className={step === 4 ? "progressStepActive" : "progressStep"}>Payment</span>
        </div>
      );
    } else {
      return "";
    }
  };

  renderMerchantAccountApproved = () => {
    return (
      <React.Fragment>
        <div className="registerTitles">Welcome back!</div>
        <div className="login-container">
          <div className="instructions">You already have a ClerkHound account with an approved merchant account.</div>
          <div className="instructions">
            Click the button below to login to ClerkHound using your email and the password you provided during onboarding.
          </div>
          <div className="instructions highlight">Be sure to bookmark the Login page for all your future visits!</div>
          <div>
            <FontAwesomeIcon icon={faPaw} />
          </div>
          <span id="login-button" className="action-button" onClick={this.handleGoToClerkHound}>
            Login
          </span>
        </div>
      </React.Fragment>
    );
  };

  renderSubscribed = () => {
    return (
      <React.Fragment>
        <div className="registerTitles">Welcome to the ClerkHound family!</div>
        <div className="login-container">
          <div className="instructions">Your card has been charged for the first month and an account has been created for you.</div>
          <div className="instructions">Click the button below to login to ClerkHound. Be sure to bookmark the page!</div>
          <div>
            <FontAwesomeIcon icon={faPaw} />
          </div>
          <span id="login-button" className="action-button" onClick={this.handleGoToClerkHound}>
            Next
          </span>
        </div>
      </React.Fragment>
    );
  };

  renderPaymentInfo = () => {
    const buttonclass = this.isReadyToSubmitPaymentInfo() && !this.props.waiting ? "action-button" : "action-button save-disabled";
    const message = this.state.message ? <div className="error registrationError">{this.state.message}</div> : <div>&nbsp;</div>;
    const instructions = "Please provide the information below and press Next.";
    const buttonLabel = this.state.completing ? "Processing..." : "Complete Purchase";
    let embeddedFields = (
      <React.Fragment>
        <div id="card_number" className={this.state.embeddedFieldCardNumber ? "" : "errorBorder"}></div>
        <div id="exp_date" className={this.state.embeddedFieldExpiration ? "" : "errorBorder"}></div>
        <div id="cvv2" className={this.state.embeddedFieldCVV ? "" : "errorBorder"}></div>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <div className="registerTitles">
          Almost done! <br />
          How would you like to pay for your subscription?
        </div>
        <div className="login-container">
          <div className="instructions">{instructions}</div>
          <form className="paymentForm" id="paymentform" method="post" action="/" ref={this.formRef}>
            <div className="checkboxGrid">
              <input type="checkbox" name="useOwner" id="useOwner" onChange={this.handleChange} checked={this.state.useOwner} />
              <label htmlFor="useOwner">Would you like to use info already entered in the previous page? Click Here.</label>
            </div>
            <input
              type="text"
              name="billingfirstname"
              id="billingfirstname"
              placeholder="First Name"
              maxLength={32}
              className="billingfirstname"
              autoComplete="off"
              value={this.state.prospect?.billingfirstname}
              onChange={event => {
                this.handleChange(event);
              }}
            />
            <input
              type="text"
              name="billinglastname"
              id="billinglastname"
              placeholder="Last Name"
              maxLength={32}
              className="billinglastname"
              autoComplete="off"
              value={this.state.prospect?.billinglastname}
              onChange={event => {
                this.handleChange(event);
              }}
            />
            <input
              type="text"
              name="billingzipcode"
              id="billingzipcode"
              placeholder="Zip Code"
              maxLength={10}
              className="billingzipcode"
              autoComplete="off"
              value={this.state.prospect.billingzipcode}
              onChange={event => {
                this.handleChange(event);
              }}
              onBlur={event => {
                // Remove trailing dashes
                let zip = event.target.value.replace(/-+$/, "");
                this.setState({ prospect: { ...this.state.prospect, billingzipcode: zip } });
              }}
            />
            {embeddedFields}
            <div className="checkboxGrid">
              <input
                type="checkbox"
                name="agreeterms"
                id="agreeterms"
                onChange={this.handleChange}
                checked={this.state.prospect?.agreeterms !== ""}
              />
              <label htmlFor="agreeterms">
                I accept the{" "}
                <a href="https://clerkhound.com/terms/" target="_blank" rel="noreferrer" className="white">
                  Terms and Conditions
                </a>{" "}
                for this service.
              </label>
            </div>
            <div className="checkboxGrid">
              <input
                type="checkbox"
                name="agreepayment"
                id="agreepayment"
                onChange={this.handleChange}
                checked={this.state.prospect?.agreepayment !== ""}
              />
              <label htmlFor="agreepayment">
                I acknowledge that by clicking "Complete Purchase," my credit card will be charged ${this.state.prospect?.planAmount} each month,
                starting from today.
              </label>
            </div>
            {message}
            <span id="login-button" className={buttonclass} onClick={this.handleSubmitPaymentInfo}>
              {buttonLabel}
            </span>
          </form>
        </div>
      </React.Fragment>
    );
  };

  renderPasswordPrompt = () => {
    const buttonclass = this.isReadyToSubmitPassword() && !this.props.waiting ? "action-button" : "action-button save-disabled";
    const message = this.state.message ? <div className="error">{this.state.message}</div> : <div>&nbsp;</div>;
    let eyeball = <FontAwesomeIcon icon={faEyeSlash} />;
    if (this.state.type === "password") {
      eyeball = <FontAwesomeIcon icon={faEye} />;
    }
    return (
      <React.Fragment>
        <div className="registerTitles">Provide a Password</div>
        <div className="login-container">
          <div className="instructions">
            Please create a password for logging into the system with the email address: {this.state.prospect.email}.
          </div>
          <div className="instructions">Ensure your password is at least 10 characters in length and has not been used for other websites.</div>
          <div>
            <FontAwesomeIcon icon={faPaw} />
          </div>
          <div className="input-container svgGridCenter">
            <FontAwesomeIcon icon={faLock} />
            <input
              type={this.state.type}
              name="password"
              id="password"
              placeholder="password"
              maxLength={50}
              autoComplete="off"
              onChange={this.handleChange}
              required
              value={this.state.prospect?.password}
            />
            <span className="eyeball gridCenter" onClick={this.handleTogglePassword}>
              {eyeball}
            </span>
          </div>
          {message}
          <span id="login-button" className={buttonclass} onClick={this.handleSubmitPassword}>
            Next
          </span>
        </div>
      </React.Fragment>
    );
  };

  renderBusinessInfo = () => {
    const buttonclass = this.isReadyToSubmitBusinessInfo() && !this.props.waiting ? "action-button" : "action-button save-disabled";
    const message = this.state.message ? <div className="error">{this.state.message}</div> : <div>&nbsp;</div>;
    return (
      <React.Fragment>
        <div className="registerTitles">Tell Us About Your Business</div>
        <div className="login-container">
          <div className="instructions">Please enter the information below and press Next.</div>

          <div className="businessInfoContainer">
            <input
              type="text"
              name="firstname"
              id="firstname"
              maxLength={50}
              placeholder="Owner's First Name"
              autoComplete="off"
              autoFocus={true}
              onChange={this.handleChange}
              required
              value={this.state.prospect.firstname}
            />
          </div>

          <div className="businessInfoContainer">
            <input
              type="text"
              name="lastname"
              id="lastname"
              maxLength={50}
              placeholder="Owner's Last Name"
              autoComplete="off"
              onChange={this.handleChange}
              required
              value={this.state.prospect.lastname}
            />
          </div>
          <div className="businessInfoContainer">
            <input
              type="text"
              name="companyname"
              id="companyname"
              maxLength={50}
              placeholder="Company's Name"
              autoComplete="off"
              onChange={this.handleChange}
              required
              value={this.state.prospect.companyname}
            />
          </div>
          <div className="businessInfoContainer">
            <input
              type="text"
              name="businessphone"
              id="businessphone"
              maxLength={15}
              placeholder="Company's Phone Number"
              autoComplete="off"
              onChange={this.handleChange}
              onBlur={this.handlePhoneBlur}
              required
              value={this.state.prospect.businessphone}
            />
          </div>
          <div className="businessInfoContainer">
            <input
              type="text"
              name="website"
              id="website"
              maxLength={255}
              placeholder="Company's Website"
              autoComplete="off"
              onChange={this.handleChange}
              required
              value={this.state.prospect.website}
            />
          </div>
          <div className="businessInfoContainer">
            <input
              type="text"
              name="streetaddress"
              id="streetaddress"
              maxLength={50}
              placeholder="Company's Street Address"
              autoComplete="off"
              onChange={this.handleChange}
              required
              value={this.state.prospect.streetaddress}
            />
          </div>
          <div className="businessInfoContainer">
            <input
              type="text"
              name="city"
              id="city"
              maxLength={50}
              placeholder="Company's City"
              autoComplete="off"
              onChange={this.handleChange}
              required
              value={this.state.prospect.city}
            />
          </div>
          <div className="businessInfoContainer">
            <input
              type="text"
              name="state"
              id="state"
              maxLength={2}
              placeholder="Company's State"
              autoComplete="off"
              onChange={this.handleChange}
              onBlur={this.handleBlurState}
              required
              value={this.state.prospect.state}
            />
          </div>
          <div className="businessInfoContainer">
            <input
              type="text"
              name="postalcode"
              id="postalcode"
              maxLength={12}
              placeholder="Company's Postal Code"
              autoComplete="off"
              onChange={this.handleChange}
              required
              value={this.state.prospect.postalcode}
            />
          </div>
          <div className="businessInfoContainer">
            <input
              type="text"
              name="federaltaxid"
              id="federaltaxid"
              maxLength={10}
              placeholder="Federal Tax ID (xx-xxxxxxx)"
              autoComplete="off"
              onChange={this.handleChange}
              required
              value={this.state.prospect.federaltaxid}
            />
          </div>
          <div className="settingInputItem">
            <label htmlFor="timezone">Timezone where the company is located</label>
            <select name="timezone" id="timezone" onChange={this.handleChange} value={this.state.prospect.timezone}>
              <option value="US/Alaska">Alaska</option>
              <option value="US/Central">Central</option>
              <option value="US/Eastern">Eastern</option>
              <option value="US/Hawaii">Hawaii</option>
              <option value="US/Mountain">Mountain</option>
              <option value="US/Pacific">Pacific</option>
            </select>
          </div>
          {message}
          <span id="login-button" className={buttonclass} onClick={this.handleSubmitBusinessInfo}>
            Next
          </span>
        </div>
      </React.Fragment>
    );
  };

  renderCHPlans = () => {
    const products = this.state.products ?? [];
    const chplusname = products ? products.find(product => product.storesku === "CHPLUS")?.productname : "";
    const chproname = products ? products.find(product => product.storesku === "CHPRO")?.productname : "";
    let chplusprice = products ? products.find(product => product.storesku === "CHPLUS")?.sellprice : "";
    let chproprice = products ? products.find(product => product.storesku === "CHPRO")?.sellprice : "";
    if (chplusprice) {
      chplusprice = "$" + numeral(chplusprice).format(Constants.INTEGER_WITH_COMMA) + "/mo";
    }
    if (chproprice) {
      chproprice = "$" + numeral(chproprice).format(Constants.INTEGER_WITH_COMMA) + "/mo";
    }

    return (
      <div className="selectPlan">
        <div className="dashboardWidget dbwPlan">
          <div className="centerContents">
            <span
              className="action-button green-button gridCenter planButton"
              onClick={() => {
                this.handleSelectPlan("CHPLUS");
              }}
            >
              {chplusname} {chplusprice}
            </span>
          </div>
          <div>
            The ROBUST point-of-sale platform and merchant account with free checking.
            <span className="footnote">&dagger;</span>
          </div>
          <ul>
            <li>Unlimited Customers</li>
            <li>Unlimited Products</li>
            <li>Unlimited Employee Logins</li>
            <li>Accepts all major credit and debit cards</li>
            <li>Accept ApplePay and GooglePay</li>
            <li>Monitor customers, products, suppliers, & invoices</li>
            <li>Monitor your service orders throughout their entire lifecycle</li>
            <li>Gain rapid insights into customer purchase activity</li>
            <li>Easily convert quotes into customer orders and invoices</li>
            <li>Build, send, and track purchase orders with ease</li>
            <li>Track and report sales tax collection accurately</li>
            <li>Integrated employee timesheets</li>
            <li>Integrate products and inventory with Reverb</li>
            <li>Email customers using your store's official email address</li>
            <li>Keep your inventory numbers accurate</li>
            <li>Enjoy free checking with fast deposits</li>
            <li>Send and receive money through our mobile banking app</li>
            <li>Easily import your existing data or export it for analysis</li>
            <li>PCI compliance assurance</li>
          </ul>
          <div>Processing Fees:</div>
          <ul>
            <li>2.75% per successful authorization</li>
            <li>2.75% + .4% Card not present transaction (manual entry)</li>
            <li>25 cents per authorization request</li>
            <li>$25 Monthly Service Fee (Waived for first 3 months)</li>
          </ul>
        </div>
        <div className="dashboardWidget dbwPlan">
          <div className="centerContents">
            <span
              className="action-button green-button gridCenter planButton"
              onClick={() => {
                this.handleSelectPlan("CHPRO");
              }}
            >
              {chproname} {chproprice}
            </span>
          </div>
          <div>
            The IDEAL platform for stores with lessons and/or rental programs.<span className="footnote">&dagger;</span>
          </div>
          <div>All "{chplusname}" retail functions AND:</div>
          <ul>
            <li>Automated monthly billing</li>
            <li>Quick insights into your customers and their payment status</li>
            <li>Save time with automated receipts and late notices</li>
            <li>Offer Second Chance Payments to delinquent customers</li>
            <li>Minimize chargeback risks with our user-friendly refund feature</li>
            <li>Text Customers from within the app</li>
            <li>
              Safeguard your privacy by texting customers from a dedicated business phone number
              <span className="footnote">&dagger;&dagger;</span>
            </li>
          </ul>
          <div>Processing Fees:</div>
          <ul>
            <li>2.75% per successful authorization</li>
            <li>2.75% + .4% Card not present transaction (manual entry)</li>
            <li>25 cents per authorization request</li>
            <li>$25 Monthly Service Fee (Waived for first 3 months)</li>
          </ul>
        </div>
      </div>
    );
  };

  renderBHPlans = () => {
    const products = this.state.products ?? [];
    const bhbasicname = products ? products.find(product => product.storesku === "BHBASIC")?.productname : "";
    const bhplusname = products ? products.find(product => product.storesku === "BHPLUS")?.productname : "";
    let bhbasicprice = products ? products.find(product => product.storesku === "BHBASIC")?.sellprice : "";
    let bhplusprice = products ? products.find(product => product.storesku === "BHPLUS")?.sellprice : "";
    if (bhbasicprice) {
      bhbasicprice = "$" + numeral(bhbasicprice).format(Constants.INTEGER_WITH_COMMA) + "/mo";
    }
    if (bhplusprice) {
      bhplusprice = "$" + numeral(bhplusprice).format(Constants.INTEGER_WITH_COMMA) + "/mo";
    }

    return (
      <div className="selectPlan">
        <div className="dashboardWidget dbwPlan">
          <div className="centerContents">
            <span
              className="action-button green-button gridCenter planButton"
              onClick={() => {
                this.handleSelectPlan("BHBASIC");
              }}
            >
              {bhbasicname} {bhbasicprice}
            </span>
          </div>
          <div>
            The PERFECT platform for businesses that need to automatically bill their customers on a regular basis.
            <span className="footnote">&dagger;</span>
          </div>
          <ul>
            <li>Unlimited Customers</li>
            <li>Unlimited Employee Logins</li>
            <li>Accepts all major credit and debit cards</li>
            <li>Accept one-off payments anywhere with our Virtual Terminal</li>
            <li>Quick insights into your customers and their payment status</li>
            <li>Save time with automated receipts and late notices</li>
            <li>Offer Second Chance Payments to delinquent customers</li>
            <li>Minimize chargeback risks with our user-friendly refund feature</li>
            <li>Enjoy free checking with fast deposits</li>
            <li>Send and receive money through our mobile banking app</li>
            <li>PCI compliance assurance</li>
          </ul>
          <div>Credit/Debit Card Processing Fees:</div>
          <ul>
            <li>3.15% per successful authorization</li>
            <li>25 cents per authorization request</li>
            <li>$25 Monthly Service Fee (Waived for first 3 months)</li>
          </ul>
        </div>
        <div className="dashboardWidget dbwPlan">
          <div className="centerContents">
            <span
              className="action-button green-button gridCenter planButton"
              onClick={() => {
                this.handleSelectPlan("BHPLUS");
              }}
            >
              {bhplusname} {bhplusprice}
            </span>
          </div>
          <div>
            The IDEAL platform for businesses who want to add a personal touch to their customer experience.
            <span className="footnote">&dagger;</span>
          </div>
          <div>All "{bhbasicname}" billing functions plus:</div>
          <ul>
            <li>Email customers using your store email address</li>
            <li>
              Text Customers from within the app<span className="footnote">&dagger;&dagger;</span>
            </li>
            <li>Safeguard your privacy by texting customers from a dedicated business phone number</li>
          </ul>
          <div>Processing Fees:</div>
          <ul>
            <li>3.15% per successful authorization</li>
            <li>25 cents per authorization request</li>
            <li>$25 Monthly Service Fee (Waived for first 3 months)</li>
          </ul>
        </div>
      </div>
    );
  };

  /****************************************************************************************************************
   * IF YOU ARE CREATING A NEW BILLING PLAN, YOU MUST ADD IT ClerkHound's products table, as well as              *
   * Maast's billing plans. Be sure to set the PLANCODE to the same value as the storesku in the products table.  *
   * Be sure to set the plan name and description to the same values as the productname.                          *
   ****************************************************************************************************************/
  renderPlanSelection = () => {
    const chtoggle = this.state.plans === Constants.PLAN_TYPE_CH ? <FontAwesomeIcon icon={faCircleDot} /> : <FontAwesomeIcon icon={faCircle} />;
    const bhtoggle = this.state.plans === Constants.PLAN_TYPE_BH ? <FontAwesomeIcon icon={faCircleDot} /> : <FontAwesomeIcon icon={faCircle} />;
    const plans = this.state.plans === Constants.PLAN_TYPE_CH ? this.renderCHPlans() : this.renderBHPlans();
    return (
      <React.Fragment>
        <div className="registerTitles">Choose the plan that best meets your needs:</div>
        <div
          className="togglePlans"
          onClick={() => {
            this.setState({ plans: Constants.PLAN_TYPE_CH });
          }}
        >
          {chtoggle} <span className="mobile">POS</span>
          <span className="desktop-inline">Point of Sale</span> with opt<span className="desktop-inline">ional</span> Recurring Billing
        </div>
        <div
          className="togglePlans"
          onClick={() => {
            this.setState({ plans: Constants.PLAN_TYPE_BH });
          }}
        >
          {bhtoggle} Recurring Billing Solution Only
        </div>
        <br />
        {plans}
        <div className="registrationFootnote">
          <div>
            <span className="footnote">&dagger;</span> <span className="footnoteText">With approved credit.</span>
          </div>
          <div>
            <span className="footnote">&dagger;&dagger;</span> <span className="footnoteText">With approved SMS campaign.</span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderEmailPrompt = () => {
    const buttonclass =
      this.isReadyToSubmitEmail() && !this.props.waiting && !this.state.emailPromptDisabled ? "action-button" : "action-button save-disabled";
    const message = this.state.message ? <div className="error">{this.state.message}</div> : <div>&nbsp;</div>;
    return (
      <React.Fragment>
        <div className="registerTitles">Create an Account</div>
        <div className="login-container">
          <div className="instructions">
            Enter your email below and <br />
            we will send you an invitation to join <br />
            ClerkHound.
          </div>
          <div>
            <FontAwesomeIcon icon={faPaw} />
          </div>
          <div className="input-container svgGridCenter">
            <FontAwesomeIcon icon={faEnvelope} />
            <input
              type="text"
              name="email"
              id="email"
              maxLength={100}
              placeholder="email"
              autoComplete="off"
              autoFocus={true}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDownEmail}
              required
              value={this.state.email}
            />
          </div>
          {message}
          <span id="login-button" className={buttonclass} onClick={this.handleSubmitEmail}>
            Send Invitation
          </span>
        </div>
      </React.Fragment>
    );
  };

  renderResumeRegistration = () => {
    let message = "Click Retry to complete your registration";
    let button = (
      <span id="login-button" className="action-button" onClick={this.handleSubmitRetry}>
        Retry
      </span>
    );
    if (this.state.retrying) {
      message = "Retrying. Please wait..";
      button = "";
    }
    return (
      <React.Fragment>
        <div className="login-container">
          <div>
            <FontAwesomeIcon icon={faPaw} />
          </div>
          <div className="error">{message}</div>
          {button}
        </div>
      </React.Fragment>
    );
  };

  renderMessageOnly = () => {
    const message = this.state.message ? <div className="error">{this.state.message}</div> : <div>&nbsp;</div>;
    return (
      <React.Fragment>
        <div className="login-container">
          <div>
            <FontAwesomeIcon icon={faPaw} />
          </div>
          {message}
        </div>
      </React.Fragment>
    );
  };

  isReadyToSubmitPassword = () => {
    return this.state.prospect?.password && this.state.prospect?.password.length >= 10;
  };

  isReadyToSubmitPaymentInfo = () => {
    // Check for required fields
    return (
      this.state.prospect.billingfirstname &&
      this.state.prospect.billinglastname &&
      this.state.prospect.billingzipcode &&
      this.state.embeddedFieldCardNumber &&
      this.state.embeddedFieldExpiration &&
      this.state.embeddedFieldCVV &&
      this.state.prospect.agreeterms !== "" &&
      this.state.prospect.agreepayment !== ""
    );
  };

  isReadyToSubmitBusinessInfo = () => {
    // Check state entry
    if (this.state.prospect.state.length === 2) {
      const state = this.state.states.find(state => state.abbreviation.toUpperCase() === this.state.prospect.state.toUpperCase());
      if (!state) {
        return false;
      }
    }
    // Check that federal tax ID is 9 digits without the dash
    const taxid = this.state.prospect.federaltaxid.replace(/-/g, "");
    if (taxid.length !== 9) {
      return false;
    }

    // Check for required fields
    return (
      this.state.prospect.firstname &&
      this.state.prospect.lastname &&
      this.state.prospect.companyname &&
      this.state.prospect.streetaddress &&
      this.state.prospect.city &&
      this.state.prospect.state &&
      this.state.prospect.postalcode &&
      this.state.prospect.federaltaxid &&
      this.state.prospect.businessphone &&
      this.state.prospect.website &&
      this.state.prospect.timezone
    );
  };

  isReadyToSubmitEmail = () => {
    // Check email requirements like @ sign, etc.
    return Helper.isValidEmail(this.state.email);
  };

  handlePhoneBlur = event => {
    const phone = Helper.formatPhoneNumber(event.target.value);
    this.setState(prevState => ({
      prospect: { ...prevState.prospect, businessphone: phone },
    }));
  };

  handleChange = event => {
    if (event.target.id === "useOwner") {
      this.setState(
        prevState => ({
          useOwner: !prevState.useOwner,
        }),
        () => {
          if (this.state.useOwner) {
            this.setState(prevState => ({
              prospect: {
                ...prevState.prospect,
                billingfirstname: this.state.prospect.firstname,
                billinglastname: this.state.prospect.lastname,
                billingzipcode: this.state.prospect.postalcode,
              },
            }));
          } else {
            this.setState(prevState => ({
              prospect: { ...prevState.prospect, billingfirstname: "", billinglastname: "", billingzipcode: "" },
            }));
          }
        }
      );
    } else if (event.target.id === "agreepayment" || event.target.id === "agreeterms") {
      this.setState(prevState => ({
        prospect: { ...prevState.prospect, [event.target.id]: event.target.checked ? new Date() : "" },
      }));
    } else if (event.target.id === "federaltaxid") {
      // Remove all non-numeric characters
      const value = event.target.value.replace(/\D/g, "");
      // Insert a dash after the second character
      const taxid = value.replace(/^(.{2})/, "$1-");
      this.setState(prevState => ({
        prospect: { ...prevState.prospect, [event.target.id]: taxid },
      }));
    } else {
      const status = this.state.prospect?.status;
      if (
        status === Constants.REG_BUS_INFO_PROVIDED ||
        status === Constants.REG_PLAN_SELECTED ||
        status === Constants.REG_PASSWORD_SET ||
        status === Constants.REG_PAYMENT_FAILED
      ) {
        this.setState(prevState => ({
          prospect: { ...prevState.prospect, [event.target.id]: Helper.getTargetValue(event) },
        }));
      } else {
        this.setState({
          [event.target.id]: Helper.getTargetValue(event),
        });
      }
    }
  };

  handleTogglePassword = () => {
    this.setState(prevState => ({ type: prevState.type === "password" ? "text" : "password" }));
  };

  handleKeyDownEmail = event => {
    if (event.keyCode === 13) {
      this.handleSubmitEmail();
    }
  };

  handleGoToClerkHound = () => {
    if (window.location.href.indexOf("://app.clerkhound.com") !== -1) {
      window.location.href = "https://app.clerkhound.com";
    } else {
      window.location.href = "http://app-test.clerkhound.com";
    }
  };

  handleSubmitRetry = () => {
    // Disable the Retry button
    this.setState({ retrying: true }, () => {
      let prospect = this.state.prospect;
      this.putProspect(prospect, prospect => {
        this.setState({ prospect: prospect, message: "", retrying: false });
      });
    });
  };

  handleSubmitPaymentInfo = () => {
    if (!this.isReadyToSubmitPaymentInfo() || this.waiting) {
      return;
    }
    this.waiting = true;
    const message = <span className="highlight">Completing your purchase. Do not refresh the page.</span>;
    this.setState({ message: message, completing: true });
    this.formRef.current.requestSubmit();
  };

  handleSubmitPassword = () => {
    if (!this.isReadyToSubmitPassword() || this.waiting) {
      return;
    }
    this.waiting = true;
    this.setState({ message: "Encypting your credentials..." });
    // Save the business info to the database
    const data = {
      prospectuuid: this.state.prospect.prospectuuid,
      password: this.state.prospect?.password,
    };
    this.putProspect(data, prospect => {
      if (prospect.haspassword) {
        this.setState({ prospect: { ...prospect, password: null }, message: "" }, () => {
          this.getTransientKey("");
        });
      } else {
        this.setState({ message: "An unexpected error has occurred. Please try again later." });
      }
      this.waiting = false;
    });
  };

  handleSubmitBusinessInfo = () => {
    if (!this.isReadyToSubmitBusinessInfo() || this.waiting) {
      return;
    }
    this.waiting = true;
    this.setState({ message: "Sending business info..." });
    // Save the business info to the database
    const data = {
      prospectuuid: this.state.prospect.prospectuuid,
      businessphone: this.state.prospect.businessphone,
      city: this.state.prospect.city,
      companyname: this.state.prospect.companyname,
      federaltaxid: this.state.prospect.federaltaxid,
      firstname: this.state.prospect.firstname,
      lastname: this.state.prospect.lastname,
      postalcode: this.state.prospect.postalcode,
      state: this.state.prospect.state,
      streetaddress: this.state.prospect.streetaddress,
      website: this.state.prospect.website,
      timezone: this.state.prospect.timezone,
    };
    this.putProspect(data, prospect => {
      this.setState({ prospect: prospect, message: "" }, () => {
        this.waiting = false;
      });
    });
  };

  handleSelectPlan = plan => {
    if (this.waiting) {
      return;
    }
    this.waiting = true;
    this.setState({ message: "Selecting Plan..." });
    // Send a request to the server to either create a new prospect and email them,
    // or find the existing prospect and email them.
    // Existing users will get an email with a link to the login/password reset page.
    const data = { prospectuuid: this.props.prospectuuid, plancode: plan };
    this.putProspect(data, prospect => {
      this.setState({ prospect: prospect, message: "" });
      this.waiting = false;
    });
  };

  handleSubmitEmail = () => {
    if (!this.isReadyToSubmitEmail() || this.waiting || this.state.emailPromptDisabled) {
      return;
    }
    this.waiting = true;
    this.setState({ message: "Sending invitation..." });
    // Send a request to the server to either create a new prospect and email them,
    // or find the existing prospect and email them.
    // Existing users will get an email with a link to the login/password reset page.
    this.postProspect(this.state.email, () => {
      const message = (
        <span>
          Invitation sent! <br />
          Please check your email.
        </span>
      );
      this.setState({ message: message, emailPromptDisabled: true });
      this.waiting = false;
    });
  };

  handleLoadEmbeddedFields = () => {
    const fontSize = Helper.getFontSize("billing_first");
    const settings = {
      formId: "paymentform",
      mode: this.state.mode,
      fields: {
        card_number: {
          id: "card_number",
          onblur: this.handleBlurPaymentField,
          attributes: { required: true },
        },
        exp_date: {
          id: "exp_date",
          onblur: this.handleBlurPaymentField,
          attributes: { required: true },
        },
        cvv2: {
          id: "cvv2",
          onblur: this.handleBlurPaymentField,
          attributes: { required: true },
        },
      },
      transientKey: this.state.transientkey,
      tokenize: true, // set to true to make card_id permanent
      onSuccess: data => {
        // Tokenization suceeded, create the new client
        if (!this.waitingForTokenization) {
          this.waitingForTokenization = true;
          console.log(Helper.clTimestamp(), "onSuccess()", data);
          const card_id = data.card_id;
          const prospect = {
            prospectuuid: this.state.prospect.prospectuuid,
            card_id: card_id,
            billingfirstname: this.state.prospect.billingfirstname,
            billinglastname: this.state.prospect.billinglastname,
            billingzipcode: this.state.prospect.billingzipcode,
            tokenization_response: JSON.stringify(data),
            agreeterms: new Date(),
            agreepayment: new Date(),
          };
          this.putProspect(prospect, prospect => {
            let message = "";
            if (prospect.status === Constants.REG_PAYMENT_FAILED) {
              const tokenization_response = JSON.parse(prospect.tokenization_response);
              message = (tokenization_response.rmsg ?? "Your payment information was not accepted.") + " - Please try a different card.";
              this.getTransientKey(message);
            }
            this.setState({ prospect: prospect, message: message, completing: false }, () => {
              this.waitingForTokenization = false;
            });
          });
        } else {
          console.log(Helper.clTimestamp(), "ALERT! Received multiple onSuccess() messages!");
        }
      },
      onError: error => {
        if (error.detail) {
          // Concatenate all the error messages into one string
          let message = "";
          for (let key in error.detail) {
            message += error.detail[key] + " ";
          }
          this.setState({ message: message });
        } else {
          this.setState({ message: "An unexpected error occurred. Please try again later." });
        }
      },
      cardConfig: {
        enabled: true,
      },
      achConfig: {
        enabled: true,
        onPaymentTypeChange: function (data) {
          // console.log(Helper.clTimestamp(), "Display", data);
        },
      },
      formFields: {
        cvv2: {
          required: true,
        },
      },
      style: Helper.getMaastEmbeddedStyle(Constants.CREATE_ACCOUNT, this.props.colorMode, fontSize),
    };
    if (window.qpEmbeddedForm?.loadFrame) {
      window.qpEmbeddedForm.loadFrame(this.state.merchant_id, settings);
    } else {
      console.log(Helper.clTimestamp(), "Error loading embedded fields");
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Manual card entry is not available at this time. Please try again later.",
      });
    }
  };

  handleBlurState = event => {
    // Validate the state
    if (event.target.value.length === 2) {
      const state = this.state.states.find(state => state.abbreviation.toUpperCase() === event.target.value.toUpperCase());
      if (!state) {
        this.setState({ message: "Invalid state" });
      } else {
        this.setState(prevState => ({ message: "", prospect: { ...prevState.prospect, state: state.abbreviation } }));
      }
    } else if (event.target.value.length === 0) {
      this.setState({ message: "" });
    }
  };

  handleBlurPaymentField = event => {
    // Note which fields, if any, passed/failed validation
    if (event.fieldName === "card_number") {
      this.setState({ embeddedFieldCardNumber: event.isValid });
    } else if (event.fieldName === "exp_date") {
      this.setState({ embeddedFieldExpiration: event.isValid });
    } else if (event.fieldName === "cvv2") {
      this.setState({ embeddedFieldCVV: event.isValid });
    }
  };

  getTransientKey = message => {
    this.waiting = true;
    const url = Constants.URL_PUBLIC;
    const data = {
      action: Constants.REQUEST_TRANSIENT_KEY,
    };
    Helper.getData(url, data).then(response => {
      console.log(Helper.clTimestamp(), "getTransientKey()", response);
      if (response.status === 200 && response.body?.transient_key) {
        this.setState(
          {
            transientkey: response.body.transient_key,
            mode: response.body?.mode ?? Constants.TEST,
            merchant_id: response.body?.merchant_id,
            message: message,
          },
          () => {
            this.waiting = false;
            this.handleLoadEmbeddedFields();
          }
        );
      } else {
        this.setState({ message: "Something went wrong. Please try again later." });
        this.waiting = false;
      }
    });
  };

  getProspect = (prospectuuid, callback) => {
    const url = Constants.URL_PUBLIC;
    const data = {
      token: prospectuuid,
    };
    Helper.getData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        callback(response.body);
      } else {
        this.setState({
          message: "Invitation not found. Provide your email address and we will send you a new invitation.",
          messageOnly: false,
          prospect: { status: Constants.REG_NEW_CUSTOMER },
        });
      }
    });
  };

  getStates = callback => {
    const url = Constants.URL_PUBLIC;
    const data = {
      lookup: Constants.LOOKUP_STATES,
    };
    Helper.getData(url, data).then(response => {
      if (response.status === 200 && response.body?.records) {
        this.setState({ states: response.body.records });
      }
      callback();
    });
  };

  getProducts = callback => {
    const url = Constants.URL_PUBLIC;
    const data = {
      action: Constants.ACTION_GET_CLERKHOUND_PRODUCTS,
    };
    Helper.getData(url, data).then(response => {
      if (response.status === 200 && response.body?.records) {
        this.setState({ products: response.body.records });
      }
      callback();
    });
  };

  postProspect = (email, callback) => {
    const url = Constants.URL_PUBLIC;
    const data = {
      email: email,
      action: Constants.CREATE_ACCOUNT,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        callback();
      } else {
        this.setState({ message: "Something went wrong. Please try again later." });
        this.waiting = false;
      }
    });
  };

  // This API call requires a prospect object with only the fields being updated
  putProspect = (prospect, callback) => {
    const url = Constants.URL_PUBLIC;
    const data = { prospect: prospect };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        callback(response.body);
      } else {
        if (response.body?.message) {
          this.setState({ message: response.body.message });
        } else {
          this.setState({ message: "Something went wrong. Please try again later." });
        }
        this.waiting = false;
      }
    });
  };
}
export default CreateAccount;

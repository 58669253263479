function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.2 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 54">
            <path
              data-name="Path 46"
              d="M165.58,143.8a24.86,24.86,0,0,1,28.82,4.53l10.1-10.1a71.13,71.13,0,0,0,93.77-78.55,6.48,6.48,0,0,0-11-3.55l-15,15A31.27,31.27,0,1,1,228.08,26.9h0l15-15a6.49,6.49,0,0,0-3.55-11,71.12,71.12,0,0,0-78.14,94.83l-21.9,21.9Z"
            />
            <path
              data-name="Path 47"
              d="M125.66,183.48l-26-26L8.86,248.34A30.24,30.24,0,1,0,51.63,291.1l78.3-78.29A24.94,24.94,0,0,1,125.66,183.48Z"
            />
            <path
              data-name="Path 48"
              d="M183.55,159.23a9.53,9.53,0,0,0-13.46,0l-5.41,5.42L68.08,68.07l4.52-4.52a9.52,9.52,0,0,0,0-13.47,10.38,10.38,0,0,0-1.07-.92l-39.41-29a9.5,9.5,0,0,0-12.37,1L3,37.83A9.54,9.54,0,0,0,2,50.2l29,39.41a9.53,9.53,0,0,0,13.32,2,9.43,9.43,0,0,0,1.07-.92L50,86.18l96.58,96.59-5.8,5.81a9.51,9.51,0,0,0,0,13.46l89.11,89.1a30.24,30.24,0,0,0,42.77-42.76Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

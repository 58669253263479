import BaseDetailViewAPI from "./BaseDetailViewAPI";

// Constants
import * as Constants from "./Constants";

// Functions
import * as Helper from "./Helper";
import numeral from "numeral";
import he from "he";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode, faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";

class BaseDetailViewHandler extends BaseDetailViewAPI {
  handleViewCompany = company => {
    company.type = Constants.COMPANY;
    if (company?.uuid) {
      if (company.companytype === Constants.SUPPLIER_COMPANY) {
        this.props.handleEditItem(Constants.SUPPLIER, Constants.SUPPLIERS, company);
      } else {
        this.props.handleEditItem(Constants.CUSTOMER, Constants.CUSTOMERS, company, Constants.TAB_CUSTOMER);
      }
    }
  };

  handleViewProspect = company => {
    this.props.handleEditItem(Constants.CUSTOMER, Constants.CUSTOMERS, company, Constants.TAB_PROSPECTS);
  };

  handleViewCampaign = campaign => {
    this.props.handleEditItem(Constants.CAMPAIGN, Constants.CAMPAIGNS, campaign);
  };

  handleTouchStart = () => {
    // TODO #254: Implement this function for item-level selection on detail pages
  };

  handleTouchEnd = () => {
    // TODO #254: Implement this function for item-level selection on detail pages
  };

  handleToggleHints = () => {
    this.setState(prevState => ({ showHints: !prevState.showHints }));
  };

  handleChange = (event, type, uuid = null, index = null) => {
    if (type === Constants.CONTACT) {
      this.handleChangeContact(event, uuid);
    } else if (type === Constants.UNCHAIN_CONTACT) {
      this.handleUnchainContact(uuid);
    } else if (type === Constants.COMPANY) {
      this.handleChangeCompany(event);
    } else if (type === Constants.SHIPPING) {
      this.handleChangeShipping(event, uuid);
    } else if (type === Constants.SHIPPING_SWITCH) {
      this.handleChangeShippingSwitch(event, uuid);
    } else if (type === Constants.CUSTOMER_SEARCH) {
      this.handleChangeContactSearch(event, Constants.CUSTOMER_COMPANY + "," + Constants.CUSTOMER_FAMILY);
    } else if (type === Constants.PRODUCT_SEARCH) {
      this.handleChangeProductSearch(event, uuid);
    } else if (type === Constants.SUPPLIER_SEARCH) {
      this.handleChangeContactSearch(event, Constants.SUPPLIER_COMPANY);
    } else if (type === Constants.ORDER) {
      this.handleChangeOrder(event);
    } else if (type === Constants.PRODUCT) {
      this.handleChangeProduct(event);
    } else if (type === Constants.SUPPLIER) {
      this.handleChangeCompany(event);
    } else if (type === Constants.ORDER_ITEM) {
      this.handleChangeOrderItem(event, uuid);
    } else if (type === Constants.REPAIR_ITEM) {
      this.handleChangeRepairItem(event, uuid, index);
    } else if (type === Constants.BILLING_PLAN) {
      this.handleChangeBillingPlan(event);
    } else if (type === Constants.BILLING_INVOICE) {
      this.handleChangeBillingInvoice(event, uuid);
    } else if (type === Constants.CAMPAIGN) {
      this.handleChangeCampaign(event);
    } else {
      console.log(Helper.clTimestamp(), "Unhandled change event", event, type, uuid, index);
    }
  };

  handleUnmatchedProduct = productname => {
    // Ask which product to use for this Reverb order
    const text = `${productname}\n\nSearch for a product to use for this order.`.trim();
    const overlay = {
      type: Constants.OVERLAY_SEARCH_PRODUCTS,
      text: text,
      searchkey: "",
      searchresults: [],
      buttonText: "Select Product",
      callback: this.handleChangeOnlineOrderItem,
      inputFieldRef: this.productSearchRef,
    };
    this.props.showOverlay(overlay);
  };

  handleChangeOnlineOrderItem = (response, product) => {
    console.log(Helper.clTimestamp(), "Product selected", response, product);
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      // Update orderitem's productuuid, productname, and storesku
      const orderitem = this.state.order.orderitems[0];
      this.setState(
        prevState => ({
          order: {
            ...prevState.order,
            orderitems: prevState.order.orderitems.map(item => {
              if (item.uuid === orderitem.uuid) {
                item.productuuid = product.productuuid;
                item.productname = product.productname;
                item.storesku = product.storesku;
              }
              return item;
            }),
          },
        }),
        () => {
          // Update the orderitem in the database
          this.putOrderItem(this.state.order, orderitem.uuid, "productuuid", product.productuuid, null, false);
        }
      );
    } else {
      console.log(Helper.clTimestamp(), "No product selected");
      this.props.hideOverlay();
    }
  };

  handleChangeBillingInvoice = (event, uuid) => {
    this.setState(
      prevState => ({
        maastInvoices: prevState.maastInvoices.map(i => {
          if (i.invoice_id === uuid) {
            if (event.target.id === "map_date_payment") {
              i.map_date_payment = event.target.value;
            } else if (event.target.id === "map_payment_type") {
              i.map_payment_type = event.target.value;
            } else if (event.target.id === "map_payment_description") {
              i.map_payment_description = event.target.value;
            }
          }
          return i;
        }),
      }),
      () => {
        if (event.target.id === "map_payment_type") {
          this.maybeUpdateAllFields(event, uuid);
        }
      }
    );
  };

  maybeUpdateAllFields = (event, uuid) => {
    // Don't try to update every field if the user hasn't entered anything
    if (!event.target.value || this.state.selectedInvoices?.length < 2) {
      return;
    }
    const fieldName = event.target.id;
    const unfilled = this.state.maastInvoices.filter(i => {
      return (!i[fieldName] || i[fieldName] === "") && i.invoice_id !== uuid;
    });
    if (unfilled.length === this.state.maastInvoices.length - 1) {
      // Offer to fill in all fields
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Use '" + event.target.value + "' for all payments?",
        callback: response => {
          if (response === Constants.OVERLAY_RESPONSE_YES) {
            this.setState(prevState => ({
              maastInvoices: prevState.maastInvoices.map(i => {
                i[fieldName] = event.target.value;
                return i;
              }),
            }));
          }
        },
      });
    }
  };

  handleChangeContact = (event, uuid) => {
    // Copy the contact and update that field
    const contacts = this.getContactsContainerRef().contacts.map(contact => {
      if (contact.contactuuid === uuid) {
        contact[event.target.id] = Helper.getTargetValue(event);
      }
      return contact;
    });
    // Update the state with the updated contact.
    this.setStateContacts(contacts);

    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
      this.handleBlurContact(event, uuid);
    }
  };

  handleUnchainContact = uuid => {
    const event = {
      target: {
        id: "companyuuid",
        value: null,
      },
    };
    this.handleBlurContact(event, uuid);
    const contacts = this.getContactsContainerRef().contacts.filter(contact => contact.contactuuid !== uuid);
    this.setStateContacts(contacts);
  };

  handleChangeCompany = event => {
    this.updateCompanyState(event.target.id, Helper.getTargetValue(event));

    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
      this.handleBlurCompany(event);
    }
  };

  handleChangeShipping = (event, uuid) => {
    let shippingaddress = Helper.getContactByUUID(this.getContactsContainerRef(), uuid).shippingaddress;
    shippingaddress[event.target.id] = Helper.getTargetValue(event);
    const contacts = this.getContactsContainerRef().contacts.map(contact => {
      if (contact.contactuuid === uuid) {
        contact.shippingaddress = shippingaddress;
      }
      return contact;
    });
    this.setStateContacts(contacts);
    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
      this.handleBlurShipping(event);
    }
  };

  handleChangeShippingSwitch = (event, uuid) => {
    // confirm if the shipping address should be deleted
    const contact = Helper.getContactByUUID(this.getContactsContainerRef(), uuid);
    if (contact.shippingaddress.addressuuid) {
      //confirm delete
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Are you sure you want to delete the shipping address?",
        callback: this.maybeDeleteShipAddress,
        key: uuid,
      });
    } else {
      // display shipping address to user.
      const contacts = this.getContactsContainerRef().contacts.map(contact => {
        if (contact.contactuuid === uuid) {
          contact.hasshippingaddress = !contact.hasshippingaddress;
        }
        return contact;
      });
      this.setStateContacts(contacts);
    }
  };

  handleChangeContactSearch = (event, companytypes) => {
    const value = Helper.getTargetValue(event);
    const logicallyDifferent = value.trim() !== this.state.contactSearchKey.trim();
    this.setState({ contactSearchKey: value }, () => {
      // If the value has not changed, run a search
      if (logicallyDifferent) {
        // Wait half a second
        setTimeout(() => {
          // If the value has not changed, run a search
          if (value === this.state.contactSearchKey) {
            this.props.updateBreadcrumb({ contactSearchKey: this.state.contactSearchKey });
            this.setState({ contactSearchResults: null, pagenumber: 1, pagenumberentry: 1 }, () => {
              this.getContactList(companytypes, "contactSearchResults", this.state.contactSearchKey);
            });
          }
        }, Constants.SEARCH_DELAY);
      }
    });
  };

  handleChangeProductSearch = event => {
    const value = Helper.getTargetValue(event);
    const logicallyDifferent = value.trim() !== this.state.productSearchKey.trim();
    this.setState({ productSearchKey: value }, () => {
      // If the value has not changed, run a search
      if (logicallyDifferent) {
        // Wait half a second
        setTimeout(() => {
          // If the value has not changed, run a search
          if (value === this.state.productSearchKey) {
            this.props.updateBreadcrumb({ productSearchKey: this.state.productSearchKey });
            this.setState({ productSearchResults: null, productSearchCount: 0, pagenumber: 1, pagenumberentry: 1 }, () => {
              this.getProductList(this.state.productSearchKey);
            });
          }
        }, Constants.SEARCH_DELAY);
      }
    });
  };

  handleChangeBillingPlanSearch = (id, value) => {
    this.setState({ billingPlanSearchKey: value });
    // Filter maastBillingPlans list for matching billing plans
    const billingPlanSearchResults = this.state.maastBillingPlans.filter(billingPlan => {
      return billingPlan.plan_desc.toLowerCase().includes(value.toLowerCase());
    });
    this.setState({
      billingPlanSearchResults: billingPlanSearchResults,
      billingPlanSearchCount: billingPlanSearchResults.length,
      pagenumber: 1,
      pagenumberentry: 1,
    });
  };

  handleChangeOrder = event => {
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        [event.target.id]: Helper.getTargetValue(event),
      },
    }));
    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
      this.handleBlurOrder(event);
    }
  };

  handleChangeCampaign = event => {
    this.setState(prevState => ({
      campaign: {
        ...prevState.campaign,
        [event.target.id]: Helper.getTargetValue(event),
      },
    }));
    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
      // Previous value for a checkbox is the opposite of the new value
      let prev = !Helper.getTargetValue(event);
      this.handleBlurCampaign(event, prev);
    }
  };

  handleChangeProduct = (event, callback = null) => {
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        [event.target.id]: Helper.getTargetValue(event),
      },
    }));
    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
      // Previous value for a checkbox is the opposite of the new value
      let prev = !Helper.getTargetValue(event);
      this.handleBlurProduct(event, prev, null, callback);
    } else {
      if (callback) {
        callback();
      }
    }
  };

  handleChangeOrderItem = (event, uuid) => {
    // Copy the order items and update the specified field
    let orderitems = this.state.order?.orderitems.map(item => {
      if (item.uuid === uuid) {
        item[event.target.id] = Helper.getTargetValue(event);
      }
      return item;
    });
    // Update the state with the updated item
    if (this.state.isNew) {
      this.setState(prevState => ({
        order: {
          ...Helper.newOrderRecalculate({
            ...prevState.order,
            orderitems: orderitems,
          }),
        },
      }));
    } else {
      this.setState(
        prevState => ({
          order: {
            ...prevState.order,
            orderitems: orderitems,
          },
        }),
        () => {
          // Checkboxes don't blur, so write to database immediately
          if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
            this.handleBlurOrderItem(event, uuid);
          }
        }
      );
    }
  };

  handleChangeRepairItem = (event, uuid, index) => {
    // Copy the repair items and update the specified field
    let repairitems = this.state.order?.repairitems.map((item, idx) => {
      // Look for an existing item with the specified uuid
      //   or a new/unsaved item with the specified index
      if ((uuid !== "new" && item.uuid === uuid) || (uuid === "new" && index === idx)) {
        item[event.target.id] = Helper.getTargetValue(event);
      }
      return item;
    });

    // Update the state with the updated item
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        repairitems: repairitems,
      },
    }));

    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
      this.handleBlurOrderItem(event, uuid);
    }
  };

  handleChangeBillingPlan = event => {
    this.setState(prevState => ({
      plan: {
        ...prevState.plan,
        [event.target.id]: Helper.getTargetValue(event),
      },
    }));
    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX || event.target.type === Constants.HTML_INPUT_TYPE_SELECT_ONE) {
      this.handleBlurBillingPlan(event, "boolean");
    }
  };

  handleBlurBillingPlan = (event, datatype) => {
    // Update the plan with Maast
    if (!this.state.isNew) {
      this.putBillingPlan(datatype, event.target.id, event.target.value);
    }
  };

  handleChangeBridge = (event, index) => {
    const suppliers = this.state.product.suppliers.map((supplier, supplierindex) => {
      // Only change the specified supplier
      if (index === supplierindex) {
        supplier[event.target.id] = Helper.getTargetValue(event);
      }
      return supplier;
    });
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        suppliers: suppliers,
      },
    }));
    // Checkboxes don't blur, so write to database immediately
    if (event.target.type === Constants.HTML_INPUT_TYPE_CHECKBOX) {
      this.handleBlurBridge(event, index, Constants.HTML_INPUT_TYPE_CHECKBOX);
    }
  };

  handleBlur = (event, type, uuid = null, callback = null, datatype = null, index = null) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);

    if (type === Constants.CONTACT) {
      this.handleBlurContact(event, uuid, prev);
    } else if (type === Constants.COMPANY) {
      this.handleBlurCompany(event, prev, callback, datatype);
    } else if (type === Constants.SHIPPING) {
      this.handleBlurShipping(event, uuid, prev);
    } else if (type === Constants.ORDER) {
      this.handleBlurOrder(event, uuid, prev);
    } else if (type === Constants.ORDER_ITEM) {
      this.handleBlurOrderItem(event, uuid, prev, datatype);
    } else if (type === Constants.REPAIR_ITEM) {
      this.handleBlurRepairItem(event, uuid, prev, index);
    } else if (type === Constants.PRODUCT) {
      this.handleBlurProduct(event, prev, datatype);
    } else if (type === Constants.BILLING_PLAN) {
      this.handleBlurBillingPlan(event, datatype);
    } else if (type === Constants.BILLING_INVOICE) {
      this.handleBlurBillingInvoice(event, uuid);
    } else if (type === Constants.CAMPAIGN) {
      this.handleBlurCampaign(event, prev, null, callback);
    }
  };

  handleBlurBillingInvoice = (event, uuid) => {
    // Maybe offer to fill in all fields, if this is the first selection made for this attribute
    this.maybeUpdateAllFields(event, uuid);
  };

  // When the user leaves a field on an existing contact, save the changes
  handleBlurContact = (event, uuid, prev) => {
    // Live editing is not enabled until the company is saved to the DB
    //   (i.e. when a first & last name has been entered)
    if (this.getContactsContainerRef().companyuuid === null) {
      return;
    } else {
      const fieldname = event.target.id;
      let value = Helper.getTargetValue(event);
      const contact = Helper.getContactByUUID(this.getContactsContainerRef(), uuid);

      // Do not email flag is reversed
      if (fieldname === "donotemail") {
        value = !value;
      }
      // Postal code is uppercased (Thanks, Canada!)
      if (fieldname === "postalcode") {
        value = value.toUpperCase();
      }

      if (!Helper.isValidValue(fieldname, value, prev, this.isRequiredFieldContacts(fieldname))) {
        // Put back the previous value
        if (this.isRequiredFieldContacts(fieldname)) {
          contact[fieldname] = prev;
          const contacts = Helper.replaceItemInList(this.getContactsContainerRef().contacts, contact);
          this.setStateContacts(contacts, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Previous value restored, field is required.",
            });
          });
        } else {
          const contacts = Helper.replaceItemInList(this.getContactsContainerRef().contacts, contact);
          this.setStateContacts(contacts, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Invalid value entered for " + fieldname + ", will not be saved.",
            });
          });
        }
      } else {
        // Only update contact if the contact is already in the database
        if (uuid === "new" && value) {
          //This is a new contact, so first and last name must be present before we can inline edit
          if (this.props.appState.currentView === Constants.SUPPLIER || this.props.appState.currentView === Constants.PURCHASE) {
            this.postContact(contact);
          } else if (contact.firstname && contact.lastname) {
            this.postContact(contact);
          }
        } else if (prev !== value) {
          // Make sure the field value changed
          this.putContact(uuid, fieldname, value, prev);
        }
      }
    }
  };

  handleBlurCompany = (event, prev, callback = null, datatype = null) => {
    const fieldname = event.target.id;
    let value = Helper.getTargetValue(event);
    const companyuuid = this.getContactsContainerRef().companyuuid;
    // Only update if the company is already in the database
    if (companyuuid) {
      // Make sure the field value changed
      if (prev !== value) {
        // Check for valid input
        if (!Helper.isValidValue(fieldname, value, prev, false, this.props.appState.currentView, datatype)) {
          // Put back the previous value
          this.setState(
            prevState => ({ company: { ...prevState.company, [fieldname]: prev } }),
            () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "Invalid input",
              });
            }
          );
          return;
        }

        // Currently, customer discount is the only percent field
        if (datatype === "percent") {
          if (value && value.trim().endsWith("%")) {
            value = numeral(value).value();
          } else if (value) {
            value = numeral(value).divide(100).value();
          } else if (!value) {
            value = 0;
          }
          // Round to 2 decimal places (i.e. a whole percent like 5%, not 4.9%)
          value = Math.round(value * 100) / 100;
        }

        // Supplier: If this is a supplier, then prevent blank company name
        if (fieldname === "companyname" && !value && this.props.appState.currentView === Constants.SUPPLIER) {
          this.setState(
            prevState => ({ company: { ...prevState.company, companyname: prev } }),
            () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "Company name is required.",
              });
            }
          );
          return;
        } else if (
          // Purchase: If this is a supplier, then prevent blank company name
          fieldname === "companyname" &&
          !value &&
          this.props.appState.currentView === Constants.PURCHASE
        ) {
          this.setState(
            prevState => ({
              order: {
                ...prevState.order,
                company: {
                  ...prevState.order.company,
                  companyname: prev,
                },
              },
            }),
            () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "Company name is required.",
              });
            }
          );
          return;
        } else if (fieldname === "companyname" && !value) {
          // If this is a customer and a blank company name is provided, switch it to null for the web API call
          value = null;
        }
        // Update the company name on all contacts in the order and/or company
        if (fieldname === "companyname") {
          if (Helper.inList([Constants.CUSTOMER, Constants.SUPPLIER], this.props.appState.currentView)) {
            this.setState(prevState => ({
              company: {
                ...prevState.company,
                contacts: prevState.company.contacts.map(contact => {
                  contact.companyname = value;
                  return contact;
                }),
              },
            }));
          }
          if (Helper.isOrderView(this.props.appState.currentView)) {
            this.setState(prevState => ({
              order: {
                ...prevState.order,
                company: {
                  ...prevState.order.company,
                  contacts: prevState.order.company.contacts.map(contact => {
                    contact.companyname = value;
                    return contact;
                  }),
                },
              },
            }));
          }
        }
        // Update the company field in the database
        this.putCompany(companyuuid, fieldname, value);
      }
    } else {
      // Validate discount
      if (!Helper.isValidValue(fieldname, value, prev, false, this.props.appState.currentView, datatype)) {
        // Put back the previous value
        this.setState(
          prevState => ({ company: { ...prevState.company, [fieldname]: prev } }),
          () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Invalid input",
            });
          }
        );
        return;
      }
    }
    if (callback) {
      callback(value, event);
    }
  };

  // When the user leaves a field on an existing shipping, save the changes
  handleBlurShipping = (event, uuid, prev) => {
    const fieldname = event.target.id;
    const value = Helper.getTargetValue(event);
    const addressuuid = Helper.getContactByUUID(this.getContactsContainerRef(), uuid).shippingaddress.addressuuid;
    // Make sure the field value changed
    if (prev !== value) {
      // Only update company if the company is already in the database
      if (addressuuid) {
        this.putShipAddress(addressuuid, fieldname, value);
      } else {
        this.postShipAddress(uuid);
      }
    }
  };

  handleBlurOrder = (event, uuid, prev) => {
    // Make sure the field value changed
    const value = Helper.getTargetValue(event);
    if (prev !== value) {
      // Only update order if the order is already in the database
      if (uuid) {
        let callback = null;

        // After the update, if this is an existing, external order and a tracking number has been entered, then offer to notify Reverb/Cartloom of the shipment and mark the order as shipped
        if (uuid && this.state.order?.externalid && event.target.id === "trackingnumber") {
          if (!value) {
            callback = this.maybeMarkOrderUnshipped;
          } else if (this.state.order?.salesperson === Constants.SALESPERSON_REVERB) {
            callback = this.maybeNotifyReverb;
            // } else if (this.state.order?.salesperson === Constants.SALESPERSON_CARTLOOM) {
            //   callback = this.maybeNotifyCartloom;
          }
        } else if (
          uuid &&
          this.state.order?.externalid &&
          event.target.id === "orderstatus" &&
          value === Constants.ORDER_STATUS_ONLINE_SHIPPED &&
          [Constants.ORDER_STATUS_ONLINE_UNSHIPPED, Constants.ORDER_STATUS_ONLINE_PARTIALLY_SHIPPED].includes(this.state.order?.orderstatus)
        ) {
          if (this.state.order?.trackingnumber) {
            if (this.state.order?.salesperson === Constants.SALESPERSON_REVERB) {
              callback = this.maybeNotifyReverb;
              // } else if (this.state.order?.salesperson === Constants.SALESPERSON_CARTLOOM) {
              //   callback = this.maybeNotifyCartloom;
            }
          } else {
            callback = this.maybeGetTrackingNumber;
          }
        }

        this.putOrder(uuid, event.target.id, value, callback);
      }
    }
  };

  maybeGetTrackingNumber = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_INPUT_BOX,
      text: "Enter the tracking number",
      callback: this.handleMaybeGetTrackingNumberResponse,
    });
  };

  handleMaybeGetTrackingNumberResponse = (response, key, trackingNumber, extra) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      this.setState(
        prevState => ({
          order: {
            ...prevState.order,
            trackingnumber: trackingNumber,
          },
        }),
        () => {
          this.putOrder(this.state.order.orderuuid, "trackingnumber", this.state.order.trackingnumber, this.maybeNotifyReverb);
        }
      );
    } else {
      this.props.hideOverlay();
    }
  };

  maybeMarkOrderUnshipped = () => {
    if (this.state.order?.orderstatus === Constants.ORDER_STATUS_ONLINE_SHIPPED) {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Mark order as not shipped?",
        callback: this.handleMaybeMarkOrderUnshippedResponse,
      });
    }
  };

  handleMaybeMarkOrderUnshippedResponse = response => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.setState(
        prevState => ({
          order: {
            ...prevState.order,
            orderstatus: Constants.ORDER_STATUS_ONLINE_UNSHIPPED,
          },
        }),
        () => {
          this.putOrder(this.state.order.orderuuid, "orderstatus", Constants.ORDER_STATUS_ONLINE_UNSHIPPED);
        }
      );
    }
  };

  maybeNotifyReverb = () => {
    let text = "Notify Reverb of shipment and mark order as shipped?";
    if (this.state.order?.orderstatus === Constants.ORDER_STATUS_ONLINE_SHIPPED) {
      text = "Notify Reverb of shipment?";
    }
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: text,
      callback: this.handleMaybeNotifyReverbResponse,
    });
  };

  handleMaybeNotifyReverbResponse = response => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      // Look for the last tracking number in the format "123456 (shipper)" and extract the tracking number and shipper (optional)
      let trackingNumber = this.state.order.trackingnumber;
      // Get the last line of the tracking number (in case there are multiple lines of tracking numbers)
      if (trackingNumber.includes("\n")) {
        trackingNumber = trackingNumber.slice(trackingNumber.lastIndexOf("\n") + 1);
      }
      let shipper = null;
      if (trackingNumber.includes("(") && trackingNumber.includes(")")) {
        shipper = trackingNumber.slice(trackingNumber.lastIndexOf("(") + 1, trackingNumber.lastIndexOf(")"));
        trackingNumber = trackingNumber.slice(0, trackingNumber.lastIndexOf("(")).trim();
      }
      // If no shipper was found, then show a picker to select the shipper
      if (!shipper || shipper === "undefined" || shipper === null) {
        // Show overlay while we get the shipper list
        this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Retrieving shipping provider list..." });
        this.getReverbShippingProviderList(this.handleSelectReverbShipper);
      } else {
        // Go directly to the notify Reverb function
        this.handleNotifyReverb(shipper, trackingNumber);
      }
    }
  };

  handleSelectReverbShipper = () => {
    // Show a picker with the shipper options
    this.props.showOverlay({
      type: Constants.OVERLAY_PICKER,
      text: "Select the shipping provider",
      items: this.state.reverbShippingProviders,
      callback: this.handleShipperSelected,
    });
  };

  handleShipperSelected = (response, selection, event) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      let newTrackingNumber = this.state.order.trackingnumber;
      // Look for the shipper at the end of the tracking number in the format "123456 (shipper)"
      if (!newTrackingNumber.endsWith(` (${selection.id})`)) {
        newTrackingNumber = `${this.state.order.trackingnumber} (${selection.id})`;
      }

      // Append the shipping provider to the tracking number, then update the order's tracking number and status in state,
      // then update the order's tracking number and status in the database
      this.setState(
        prevState => ({
          order: {
            ...prevState.order,
            trackingnumber: newTrackingNumber,
            orderstatus: Constants.ORDER_STATUS_ONLINE_SHIPPED,
          },
        }),
        () => {
          // Show a progress overlay
          this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Updating invoice..." });
          this.putOrder(this.state.order.orderuuid, "trackingnumber", this.state.order.trackingnumber, () => {
            if (this.state.order.orderstatus === Constants.ORDER_STATUS_ONLINE_SHIPPED) {
              this.handleNotifyReverb(selection.id, newTrackingNumber);
            } else {
              this.putOrder(this.state.order.orderuuid, "orderstatus", Constants.ORDER_STATUS_ONLINE_SHIPPED, () => {
                this.handleNotifyReverb(selection.id, newTrackingNumber);
              });
            }
          });
        }
      );
    }
  };

  handleNotifyReverb = (provider, tracking_number) => {
    this.postReverbShipment(provider, tracking_number);
  };

  handlePublishCartloom = () => {
    this.putCartloomProduct(this.state.product);
  };

  // The "override" param is used to indicate a special case where the handleBlur failed with a prompt
  // and the prompt has been satisfied, so the handleBlur should be called again without the prompt showing.
  // This may need to be converted to an array if the handleBlur needs to support multiple override possibilities.
  handleBlurOrderItem = (event, uuid, prev, datatype = null, override = false) => {
    const fieldname = event.target.id;
    let value = Helper.getTargetValue(event);
    let discountError = null;

    // Perform basic edits and rollback if bad input
    if (
      !Helper.isValidValue(
        fieldname,
        value,
        prev,
        Helper.isRequiredFieldOrderItem(this.props.ordertype, fieldname),
        this.props.appState.currentView,
        datatype
      )
    ) {
      // Put back the previous value, then exit
      this.revertToPreviousValue(uuid, fieldname, prev, "Invalid input");
      return;
    }

    // If this is a return, check the input against the "return eligible quantity"
    if (this.isReturn()) {
      // Get the line item being edited and calculate the eligible quantity
      const lineitem = this.state.order?.orderitems.filter(item => item.uuid === uuid);
      if (lineitem.length > 0) {
        const eligible = numeral(lineitem[0].quantitySold).subtract(numeral(lineitem[0].quantityreceived).value()).value();
        if (numeral(value).value() > eligible || numeral(value).value() < 0) {
          // Put back the previous value
          const orderitems = this.state.order?.orderitems.map(item => {
            if (item.uuid === uuid) {
              item[fieldname] = prev;
            }
            return item;
          });
          this.setState(
            prevState => ({
              order: { ...prevState.order, orderitems: orderitems },
            }),
            () => {
              const text = numeral(value).value() < 0 ? "Quantity must be greater than zero." : "Quantity to return cannot exceed quantity eligible.";
              this.props.showOverlay({ type: Constants.OVERLAY_MESSAGE, text: text });
            }
          );
          // If not a valid input value, then exit
          return;
        }
      }
    }

    // Check for a discount value in a percent format
    if (fieldname === "discount" && value && typeof value === "string" && Helper.inString(value, "%")) {
      // Get the percentage from the input string
      let percentDiscount = numeral(value.slice(0, value.indexOf("%")));
      // If the input is not valid, then reset the field value and show an error message
      if (percentDiscount.value() === null) {
        const orderitems = this.state.order?.orderitems.map(item => {
          if (item.uuid === uuid) {
            item[fieldname] = prev;
          }
          return item;
        });
        this.setState(
          prevState => ({
            order: { ...prevState.order, orderitems: orderitems },
          }),
          () => {
            this.props.showOverlay({ type: Constants.OVERLAY_MESSAGE, text: "Invalid input" });
          }
        );
        return;
      } else {
        // Calculate the dollar discount on the matching order item
        percentDiscount = percentDiscount.multiply(0.01);
        const match = this.state.order?.orderitems.filter(item => item.uuid === uuid);
        if (match && match.length > 0) {
          value = percentDiscount.multiply(numeral(match[0].sellprice).value()).format(Constants.CURRENCY);
        }
        if (this.state.isNew) {
          event.target.value = value;
          this.handleChangeOrderItem(event, uuid);
        }
      }
    } else if (datatype === "float") {
      // Check for special handling based on the data type
      value = numeral(value).format(Helper.suppressTrailingZeros(fieldname) ? Constants.DECIMAL_VALUE : Constants.CURRENCY);
      this.handleChangeOrderItem(event, uuid);
    }

    // If the quantity is set to zero, then offer to delete the item
    if (fieldname === "quantity" && value === 0) {
      this.handleDeleteOrderItem(uuid, prev);
      return;
    }

    // Discount edits
    if (fieldname === "discount") {
      // Check for discount that exceeds the maximum discount allowed (only for unsaved orders)
      if (this.state.isNew) {
        const match = this.state.order?.orderitems.filter(item => item.uuid === uuid);
        if (match && match.length > 0 && match[0].maxdiscount !== null && match[0].maxdiscount !== "") {
          // Convert the max discount to a dollar amount for this item
          let maxDiscount = numeral(match[0].maxdiscount).multiply(numeral(match[0].sellprice).value()).value();
          // Round maxDiscount to two decimal places
          maxDiscount = Math.round(maxDiscount * 100) / 100;
          const numeralValue = numeral(value).value();
          if (numeralValue > 0 && numeralValue > maxDiscount) {
            discountError = `Discount was reduced to the maximum allowed for this product: ${numeral(match[0].maxdiscount).format("%")}`;
            if (match[0].maxdiscount === 0) {
              discountError = "Discount is not allowed for this product.";
            }
            event.target.value = numeral(maxDiscount).format(Constants.CURRENCY);
            this.handleChangeOrderItem(event, uuid);
            this.handleBlurOrderItem(event, uuid, prev, datatype);
          }
        }
      }

      // Discount cannot be greater than sell price
      const match = this.state.order?.orderitems.filter(item => item.uuid === uuid);
      const numeralSellPrice = numeral(match[0].sellprice).value();
      if (match && match.length > 0 && numeralSellPrice > 0 && value > numeralSellPrice) {
        this.revertToPreviousValue(uuid, fieldname, prev, "Discount cannot be greater than sell price");
        return;
      }
      // Warn the cashier when the discount would increase the price
      if (value < 0 && !override) {
        this.props.showOverlay({
          type: Constants.OVERLAY_QUESTION,
          text: "Are you sure you want to\nINCREASE the price of the product?",
          callback: (response, uuid) => {
            if (response === Constants.OVERLAY_RESPONSE_YES) {
              this.handleBlurOrderItem(event, uuid, prev, datatype, true);
            } else {
              this.revertToPreviousValue(uuid, fieldname, prev);
            }
            return;
          },
          key: uuid,
        });
        return;
      }
    }

    // Sell price edits
    if (fieldname === "sellprice") {
      // Changing the sell price to a negative value will trigger this becoming a trade-in invoice
      if (numeral(value).value() < 0) {
        // Disallow negative amount for SALE invoices containing a gift card line item
        if (this.state.order?.orderitems.filter(item => item.isgiftcard).length > 0) {
          // Put back the previous value, then exit
          const message =
            "Gift card invoices cannot include a trade-in item.\n-\nComplete the trade-in invoice first using store credit or cash,\nthen create a new sale invoice for the gift card.";
          this.revertToPreviousValue(uuid, fieldname, prev, message);
          return;
        }
      }

      // The sell price field values are limited for subscription invoice payments
      const item = this.state.order?.orderitems.find(item => item.uuid === uuid);
      // Only subscription invoice payments have a uniqueidentifier starting with "SUBSCRIPTION_PAYMENT|"
      if (item?.uniqueidentifier?.startsWith(Constants.SUBSCRIPTION_PAYMENT + "|")) {
        const values = item.uniqueidentifier.split("|");
        if (values.length === 3) {
          const amount = numeral(values[2]).value();
          if (numeral(value).value() > amount) {
            // Put back the previous value, then exit
            this.revertToPreviousValue(uuid, fieldname, prev, "Sell price cannot be greater than amount owed on invoice");
            return;
          } else if (numeral(value).value() <= 0) {
            // Put back the previous value, then exit
            this.revertToPreviousValue(uuid, fieldname, prev, "Sell price is invalid");
            return;
          }
        }
      }

      // Check to make sure the new sell price did not cause the discount to exceed the max discount
      const match = this.state.order?.orderitems.filter(item => item.uuid === uuid);
      if (match && match.length > 0 && match[0].maxdiscount !== null && match[0].maxdiscount !== "") {
        // Convert the max discount to a dollar amount for this item
        let maxDiscount = numeral(match[0].maxdiscount).multiply(numeral(value).value()).value();
        // Round maxDiscount to two decimal places
        maxDiscount = Math.round(maxDiscount * 100) / 100;
        const numeralDiscount = numeral(match[0].discount).value();
        if (numeralDiscount > 0 && numeralDiscount > maxDiscount) {
          discountError = `Discount was reduced to the maximum allowed for this product: ${numeral(match[0].maxdiscount).format("%")}`;
          if (match[0].maxdiscount === 0) {
            discountError = "Discount is not allowed for this product.";
          }
          const discountEvent = {
            target: {
              id: "discount",
              value: numeral(maxDiscount).format(Constants.CURRENCY),
              type: "text",
            },
          };
          const discountPrev = match[0].discount;
          this.handleChangeOrderItem(discountEvent, uuid);
          this.handleBlurOrderItem(discountEvent, uuid, discountPrev, datatype);
        }
      }
    }

    // Only update order item if the item is already in the database
    const maybePromptUpdateProduct = discountError => {
      // If the field is productname
      if (
        ["productname", "cost", "sellprice"].includes(fieldname) &&
        Helper.authorize(Constants.ACTION_UPDATE_PRODUCT, this.props.appState.usertype)
      ) {
        const orderitem = this.state.order?.orderitems.filter(item => item.uuid === uuid)[0];
        this.props.showOverlay({
          type: Constants.OVERLAY_QUESTION,
          text: "Would you like to also update this product in the database?",
          callback: (response, responseObject) => {
            this.maybeUpdateProduct(response, responseObject, () => {
              // Display any warnings
              if (discountError) {
                this.props.showOverlay({ type: Constants.OVERLAY_MESSAGE, text: discountError });
              }
            });
          },
          key: { fieldname: fieldname, value: value, orderitem: orderitem },
        });
      } else {
        // Display any warnings
        if (discountError) {
          this.props.showOverlay({ type: Constants.OVERLAY_MESSAGE, text: discountError });
        }
      }
    };
    if (!this.state.isNew && uuid) {
      this.putOrderItem(this.state.order, uuid, fieldname, value, () => {
        maybePromptUpdateProduct(discountError);
      });
    } else {
      maybePromptUpdateProduct(discountError);
    }
  };

  handleBlurRepairItem = (event, uuid, prev, index) => {
    const fieldname = event.target.id;
    let value = Helper.getTargetValue(event);
    if (value === prev) {
      return;
    }
    let repairitem;

    let repairitems = this.state.order?.repairitems.map((item, idx) => {
      if (item.repairitemuuid !== "new" && item.repairitemuuid === uuid) {
        item[fieldname] = value;
        repairitem = item;
      } else if (item.repairitemuuid === "new" && index === idx) {
        item[fieldname] = value;
        repairitem = item;
      }
      return item;
    });

    this.setState(prevState => ({
      order: {
        ...prevState.order,
        repairitems: repairitems,
      },
    }));

    // Do nothing if the order has not been saved
    if (!this.state.isNew) {
      if (uuid !== "new") {
        // Repair item if the item is already in the database
        this.putRepairItem(uuid, fieldname, value);
      } else {
        // New repair item
        this.postRepairItem(this.state.order, repairitem, index);
      }
    }
  };

  handleBaseDetailNameEdit = () => {
    this.setState({ isEditingBaseDetailName: true, enableActionButtons: false }, () => {
      if (this.baseDetailNameInput?.current) {
        this.baseDetailNameInput.current.focus();
      }
    });
  };

  maybeDisableBaseDetailNameEdit = e => {
    // If event target is a mouse click, fire the blur event
    const event = e ?? { target: { id: "campaignname", type: "text", value: this.state.campaign.campaignname } };
    const callback = () => {
      this.setState({ isEditingBaseDetailName: false, enableActionButtons: true });
    };
    this.handleBlurCampaign(event, null, null, callback);
  };

  handleBlurCampaign = (event, prev, datatype = null, callback = null) => {
    const fieldname = event.target.id;
    let value = Helper.getTargetValue(event);
    const required = fieldname === "campaignname" ? true : false;

    if (!Helper.isValidValue(fieldname, value, prev, required, this.props.appState.currentView, datatype)) {
      // Put back the previous value
      this.setState(
        prevState => ({
          product: {
            ...prevState.product,
            [fieldname]: prev,
          },
        }),
        () => {
          this.props.showOverlay({ type: Constants.OVERLAY_MESSAGE, text: "Invalid input" });
        }
      );
    } else {
      // Only update campaign if the campaign is already in the database
      if (this.state.campaign.campaignuuid) {
        // Make sure the field value changed
        if (prev !== value) {
          value = this.putCampaign(fieldname, value, callback);
        }
      }
    }
  };

  // When the user leaves a field on an existing product, save the changes
  // The "type" parameter describes the data type of the field (int, float) for parsing purposes
  handleBlurProduct = (event, prev, datatype = null, callback = null) => {
    const fieldname = event.target.id;
    let value = Helper.getTargetValue(event);
    const required = this.isRequiredFieldProducts(this.props.appState.currentView, fieldname);

    if (!Helper.isValidValue(fieldname, value, prev, required, this.props.appState.currentView, datatype)) {
      // Put back the previous value
      this.setState(
        prevState => ({
          product: {
            ...prevState.product,
            [fieldname]: prev,
          },
        }),
        () => {
          this.props.showOverlay({ type: Constants.OVERLAY_MESSAGE, text: "Invalid input" });
        }
      );
    } else {
      // Check for special handling based on the data type
      if (datatype === "percent") {
        // We take the absolute value of the percentage because you can't have a negative max discount
        if (value && value.trim().endsWith("%")) {
          value = Math.abs(numeral(value).value());
        } else if (value) {
          value = Math.abs(numeral(value).divide(100).value());
        }
        // Put the updated/formatted value back into state
        event.target.value = Helper.formatPercent(value);
        this.handleChangeProduct(event);
      } else if (datatype === "float" && value.trim() !== "") {
        event.target.value = numeral(event.target.value).format(
          Helper.suppressTrailingZeros(fieldname) ? Constants.DECIMAL_VALUE : Constants.CURRENCY
        );
        this.handleChangeProduct(event);
      }
      // Only update product if the product is already in the database
      if (this.state.product.productuuid) {
        // Make sure the field value changed
        if (prev !== value) {
          value = this.putProduct(datatype, fieldname, value, prev, callback);
        }
      }
    }
  };

  // This blur event handler only handles the "default" supplier
  // Additional supplier are handled elsewhere
  // When the user leaves the field, find the correct company and uuid
  handleBlurSupplier = event => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    // Don't clear the company name from the field,
    //    just in case the company picker dialog is cancelled

    const companyname = event.target.value;
    // Nothing to do if the value wasn't changed
    if (prev === companyname) {
      // Clear the original company name from the DOM
      event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
      return;
    }

    // If the company name fields is cleared, then clear the companyuuid and write to the database
    if (!companyname) {
      // Clear the original company name from the DOM
      event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
      this.setState(
        prevState => ({
          product: {
            ...prevState.product,
            companyuuid: "",
            companyname: "",
          },
        }),
        () => {
          if (this.state.product.productuuid) {
            this.putProduct("text", "companyuuid", "");
          }
        }
      );
      return;
    }

    // When the user changes the supplier name, look up the supplier companyuuid
    this.props.showOverlay();
    const url = Constants.URL_COMPANIES;
    // const params = { companytype: Constants.SUPPLIER_COMPANY, companyname: companyname };
    const params = { companytypes: Constants.SUPPLIER_COMPANY, searchkey: companyname, searchlimit: 5 };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body && response.body.records) {
        // If no companies found, show error message
        if (response.body.records.length === 0) {
          event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
          this.setState(prevState => ({
            product: {
              ...prevState.product,
              companyname: prev,
            },
          }));
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Supplier not found.",
          });
        } else if (
          response.body.records.length === 1 ||
          response.body.records.find(company => company.companyname.toLowerCase() === companyname.toLowerCase())
        ) {
          // If only one company came back, select it
          event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
          const company = response.body.records[0];
          const contact = company.contacts[0];
          const companyuuid = company.companyuuid;
          const companyname = company.companyname;
          this.setState(
            prevState => ({
              product: {
                ...prevState.product,
                companyname: companyname,
                companyuuid: companyuuid,
              },
            }),
            () => {
              // If the product is already in the database, then update the companyuuid
              if (this.state.product.productuuid) {
                this.putProduct("text", "companyuuid", companyuuid);
              }
              // If we're on a purchase order, then update the companyuuid in the order, if not already set
              if (this.props.appState.currentView === Constants.PURCHASE && !this.state.order?.company) {
                this.setState(
                  prevState => ({
                    order: {
                      ...prevState.order,
                      company: company,
                      companyuuid: companyuuid,
                      contactname: contact.firstname + " " + contact.lastname,
                      contactuuid: contact.contactuuid,
                    },
                  }),
                  () => {
                    if (this.state.order.orderuuid) {
                      this.putOrder(this.state.order.orderuuid, "companyuuid", companyuuid);
                    }
                  }
                );
              }
            }
          );
          this.props.hideOverlay();
        } else {
          // Run through the list and create "id" and "text" attributes for the overlay dialog
          const companies = response.body.records.map(company => {
            return { id: company.companyuuid, text: company.companyname, company: Helper.deepCopy(company) };
          });
          const overlay = {
            type: Constants.OVERLAY_PICKER,
            text: "The exact name you entered\ndoes not exist.\nDid you mean to enter one of these?",
            callback: this.handlePickSupplier,
            items: companies,
            key: null,
            event: event,
          };
          this.props.showOverlay(overlay);
        }
      } else {
        // Clear the original company name from the DOM and show an error message
        event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
        this.setState({ error: "GET", downloading: false }, () => {
          this.showOverlay({
            text: "There was an error searching for supplier.",
            type: Constants.OVERLAY_MESSAGE,
          });
        });
      }
    });
  };

  // Handle edits to the cost and sku fields (bridge)
  handleBlurBridge = (event, index, type) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);

    // Do not process focus events if a dialog is showing
    if (this.showingDialog) {
      return;
    }
    const fieldname = event.target.id;
    let value = event.target.value;
    const supplier = this.state.product.suppliers[index];
    if (type === "float") {
      event.target.value = numeral(value).format(Constants.CURRENCY);
      value = numeral(value).value();
      this.handleChangeBridge(event, index);
    }
    // If the value was changed, update the database
    if (prev !== supplier[fieldname]) {
      // If the uuid is "new" then this supplier has not been created yet. Bail out.
      if (supplier.supplieruuid === "new") {
        // Notify the user that supplier cost/sku is not saved until a supplier is chosen
        this.props.showOverlay({
          text: "SKU and cost will not be saved until a supplier is chosen.",
          type: Constants.OVERLAY_MESSAGE,
        });
      } else {
        this.putSupplier(supplier.supplieruuid, fieldname, value);
      }
    }
  };

  // Handle edits to the supplier name field (bridge)
  handleBlurCompanyBridge = (event, index) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);

    const companyname = event.target.value;
    const supplier = this.state.product.suppliers[index];

    // Nothing to do if the value wasn't changed
    if (prev === companyname) {
      // Clear the original company name from the DOM
      event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
      return;
    }

    // If the company name field is cleared, then delete the supplier from the database
    if (!companyname) {
      // Clear the original company name from the DOM
      event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
      // TODO: Ask the user to confirm delete
      // Clear out the current supplier in the product
      const suppliers = this.state.product.suppliers.map((sup, supplierindex) => {
        // Only change the specified supplier
        if (index === supplierindex) {
          return Helper.getBlankSupplier(this.state.product.productuuid);
        } else {
          return sup;
        }
      });
      // Update the product in the state with the new "blank" supplier,
      //    then update the database (but only if the supplier is in the database)
      this.setState(
        prevState => ({
          product: {
            ...prevState.product,
            suppliers: suppliers,
          },
        }),
        () => {
          if (supplier.supplieruuid !== "new") {
            // Delete the supplier record from the bridge table
            this.deleteSupplier(supplier);
          }
        }
      );
      return;
    }

    // When the user changes the supplier name, look up the supplier companyuuid
    this.props.showOverlay();
    const url = Constants.URL_COMPANIES;
    const params = { companytype: Constants.SUPPLIER_COMPANY, companyname: companyname };
    Helper.getData(url, params).then(response => {
      // If we find a supplier with the exact name entered, then use its uuid
      if (response.status === 200 && response.body) {
        // Clear the original company name from the DOM
        event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
        const companyuuid = response.body.companyuuid;
        // Create a new supplier list with the updated supplier's companyuuid
        const suppliers = this.state.product.suppliers.map((sup, supplierindex) => {
          // Only change the specified supplier in the list
          if (index === supplierindex) {
            sup.companyuuid = companyuuid;
          }
          return sup;
        });
        // Replace the state with the new supplier list,
        //   then call POST or PUT
        this.setState(
          prevState => ({
            product: {
              ...prevState.product,
              suppliers: suppliers,
            },
          }),
          () => {
            // If this supplier is not already in the database, then POST it
            if (supplier.supplieruuid === "new") {
              this.postSupplier(index);
            } else {
              // Update the supplier with a PUT request
              this.putSupplier(supplier.supplieruuid, "companyuuid", companyuuid);
            }
            // this.props.hideOverlay();
          }
        );
      } else if (response.status === 404) {
        // If the exact supplier name was not found, then look for similar names
        const url = Constants.URL_COMPANIES;
        const params = { companytypes: 1, searchkey: companyname, searchlimit: 5 };
        Helper.getData(url, params).then(response => {
          if (response.status === 200 && response.body && response.body.records) {
            if (response.body.records.length === 0) {
              // Clear the original company name from the DOM
              event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
              const companyname = prev;
              const suppliers = this.state.product.suppliers.map((sup, supplierindex) => {
                // Only change the specified supplier in the list
                if (index === supplierindex) {
                  sup.companyname = companyname;
                }
                return sup;
              });
              // Replace the state with the new supplier list,
              //   then call POST or PUT
              this.setState(prevState => ({
                product: {
                  ...prevState.product,
                  suppliers: suppliers,
                },
              }));
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: "Supplier not found.",
              });
            } else if (response.body.records.length === 1) {
              // If only one company came back, select it
              // Clear the original company name from the DOM
              event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
              const companyuuid = response.body.records[0].companyuuid;
              const companyname = response.body.records[0].companyname;
              const suppliers = this.state.product.suppliers.map((sup, supplierindex) => {
                // Only change the specified supplier in the list
                if (index === supplierindex) {
                  sup.companyuuid = companyuuid;
                  sup.companyname = companyname;
                }
                return sup;
              });
              // Replace the state with the new supplier list,
              //   then call POST or PUT
              this.setState(
                prevState => ({
                  product: {
                    ...prevState.product,
                    suppliers: suppliers,
                  },
                }),
                () => {
                  // If this supplier is not already in the database, then POST it
                  if (supplier.supplieruuid === "new") {
                    this.postSupplier(index);
                  } else {
                    // Update the supplier with a PUT request
                    this.putSupplier(supplier.supplieruuid, "companyuuid", companyuuid);
                  }
                  // this.props.hideOverlay();
                }
              );
              this.props.hideOverlay();
            } else {
              // Run through the list and create "id" and "text" attributes for the overlay dialog
              const companies = response.body.records.map(company => {
                company.id = company.companyuuid;
                company.text = company.companyname;
                return company;
              });
              // TODO: If length of companies is 0 offer to create instead of select.
              this.currentSupplierIndex = index;
              // Do not process focus events if a dialog is showing
              this.showingDialog = true;
              this.props.showOverlay({
                type: Constants.OVERLAY_PICKER,
                text: "The exact name you entered\ndoes not exist.\nDid you mean to enter one of these?",
                callback: this.handlePickSupplierBridge,
                items: companies,
                key: null,
                event: event,
              });
            }
          } else {
            // TODO: Handle failure (not a 200 or 404 looking up matching companies)
            this.props.hideOverlay();
          }
        });
      } else {
        // TODO: Handle failure (not a 200 or 404 looking up the company entered)
        this.props.hideOverlay();
      }
    });
  };

  handleAddContactToCompany = callback => {
    let contacts = this.getContactsContainerRef().contacts;
    if (contacts.find(item => item.contactuuid === "new")) {
      // Show an error
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Please save the new contact before adding another.",
      });
      return;
    }
    contacts.splice(1, 0, Helper.getBlankContact(this.props.appState.salesperson, this.getContactsContainerRef().companytype));
    this.setStateContacts(contacts, callback);
  };

  handleAddSupplier = () => {
    let suppliers = this.state.product.suppliers;
    suppliers.push(Helper.getBlankSupplier(this.state.product.productuuid));
    let product = this.state.product;
    product.suppliers = suppliers;
    this.setState({ product: product });
  };

  handleKeyDown = event => {
    // Handle keypresses not directed at a specific input
    if (event.target === document.body) {
      this.handleUnfocusedKeypress(event);
    }
    if (event.keyCode === Constants.ENTER) {
      this.handleEnterPressed(event);
    }
  };

  handleUnfocusedKeypress = event => {
    const isLetter = event.keyCode >= 65 && event.keyCode <= 90;
    const isNumber = (event.keyCode >= 96 && event.keyCode <= 105) || (event.keyCode >= 48 && event.keyCode <= 57);
    const isBackspace = event.keyCode === 8;
    // Ignore Ctrl+? and Alt+? events
    if (event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }
    if (isLetter || isNumber || isBackspace) {
      // If an overlay is showing, then pass the keypress to it
      if (Constants.OVERLAY_KEY_INPUTS.includes(this.props.appState?.overlay?.type)) {
        // Let the overlay handle the keypress
      } else if (Helper.isOrderView(this.props.appState.currentView) || this.props.appState.currentView === Constants.BILLING) {
        if (this.props.appState.currentView === Constants.PAY) {
          if (this.state.paymentView === Constants.CASH && isNumber) {
            if (this.tenderAmountRef?.current) {
              this.tenderAmountRef.current.focus();
            }
          } else if (this.state.paymentView === Constants.GIFTCARD) {
            if (this.giftCardNumberRef?.current) {
              this.giftCardNumberRef.current.focus();
            }
          } else if (this.state.paymentView === Constants.PAYPAL) {
            if (this.payPalConfRef?.current) {
              this.payPalConfRef.current.focus();
            }
          } else if (this.state.paymentView === Constants.CHECK) {
            if (this.checkNumberRef?.current) {
              this.checkNumberRef.current.focus();
            }
          } else if (this.state.paymentView === Constants.OTHER) {
            if (this.otherDescriptionRef?.current) {
              this.otherDescriptionRef.current.focus();
            }
          }
        } else {
          // Send the keypress to the contact search field and then set focus
          if (!this.state.order?.contactuuid && this.contactSearchInput?.current) {
            let input = this.state.contactSearchKey;
            if (isLetter || isNumber) {
              input += event.key;
            } else if (isBackspace && input.length > 0) {
              input = input.slice(0, input.length - 1);
            }
            this.setState({ contactSearchKey: input }, () => {
              // Set focus in the input field
              this.contactSearchInput.current.focus();
            });
          } else if (this.billingPlanSearchRef?.current) {
            // Send the keypress to the billing plan search field and then set focus
            let input = this.state.billingPlanSearchKey;
            if (isLetter || isNumber) {
              input += event.key;
            } else if (isBackspace && input.length > 0) {
              input = input.slice(0, input.length - 1);
            }
            this.setState({ billingPlanSearchKey: input }, () => {
              // Set focus in the input field
              if (this.billingPlanSearchRef?.current) {
                this.billingPlanSearchRef.current.focus();
              }
            });
          } else {
            // Send the keypress to the product search field and then set focus
            let input = this.state.productSearchKey;
            if (isLetter || isNumber) {
              input += event.key;
            } else if (isBackspace && input.length > 0) {
              input = input.slice(0, input.length - 1);
            }
            this.setState({ productSearchKey: input }, () => {
              // Set focus in the input field
              if (this.productSearchInput?.current) {
                this.productSearchInput.current.focus();
              }
            });
          }
        }
      }
    }
  };

  handleEnterPressed = event => {
    // If a UPC or EAN is entered, followed by the ENTER key, then search for the product in the local UPS list
    // before sending the search to the server
    if (event.keyCode === Constants.ENTER && (Helper.isValidEan(this.state.productSearchKey) || Helper.isValidUpc(this.state.productSearchKey))) {
      // Stop the Enter key press from being processed by the UI (i.e. contaminating the gift card number input prompt)
      event.preventDefault();
      // Grab the searchkey
      const searchkey = this.state.productSearchKey;
      let match = null;
      // Clear the searchkey from state so that the search will not get run twice (input timer)
      this.setState({ productSearchKey: "" }, () => {
        const upcList = localStorage.getItem(Constants.LOCAL_STORAGE_UPC_LIST);
        // Only run the optimization if the order is new and the UPC list is found in local storage
        // Running this code for a saved order has some bad side effects (i.e. an incomplete product is sent to the server in the POST /orderitem request)
        if (this.state.isNew && upcList) {
          const upcData = JSON.parse(upcList);
          const matches = upcData.filter(item => item.upc === searchkey || item.ean === searchkey || item.altupc === searchkey);
          if (matches.length === 1) {
            match = matches[0];

            // **************************************************************************
            // The following logic is included to optimize storage space in local storage
            // **************************************************************************
            // Set the isgiftcard flag to false (needed for certain calculations)
            match.isgiftcard = false;
            // Check for missing fields and fill them in with an empty string
            match.location = match.location || "";
            match.upc = match.upc || "";
            match.ean = match.ean || "";
            // We only expect the taxable flag to be in the object if it is false
            match.taxable = match.taxable || true;

            // If a match was found in local storage, then select the product
            const orderitem = this.selectProductListItem(match);
            // In the background, go get the full product details from the server
            // Increment the number of inflight requests, so we know when all requests are complete
            this.setState(
              prevState => ({
                inflightRequests: prevState.inflightRequests + 1,
              }),
              () => {
                // Send a request for the product's full details
                this.getProductDetails(match.productuuid, product => {
                  // Decrement the number of inflight requests and update the product in the order
                  let diff = false;
                  this.setState(
                    prevState => ({
                      inflightRequests: prevState.inflightRequests - 1,
                      order: {
                        ...prevState.order,
                        orderitems: prevState.order.orderitems.map(item => {
                          if (item.uuid === orderitem.uuid) {
                            diff = Helper.isDifferentProduct(item, product);
                            // Special case for "uuid" which is productuuid in the product object
                            item = { ...item, ...product, uuid: item.orderitemuuid };
                          }
                          return item;
                        }),
                      },
                    }),
                    () => {
                      if (diff) {
                        // Update the product in local storage
                        localStorage.setItem(
                          Constants.LOCAL_STORAGE_UPC_LIST,
                          JSON.stringify(
                            upcData.map(item => {
                              if (item.productuuid === product.productuuid) {
                                item = {
                                  productuuid: product.productuuid,
                                  productname: product.productname,
                                  sellprice: `${product.sellprice}`,
                                  cost: `${product.cost}`,
                                  location: product.location,
                                  taxable: product.taxable,
                                  upc: product.upc,
                                  ean: product.ean,
                                  altupc: product.altupc,
                                };
                              }
                              return item;
                            })
                          )
                        );
                      }
                    }
                  );
                });
              }
            );
          }
        }
        if (!match) {
          // If no match was found in local storage, then search the server
          this.getProductList(searchkey);
        }
      });
    }
  };

  handleEditMode = (uuid, callback = null) => {
    // If this customer is the Walk-in or Stock Order Customer and not on the customer screen, don't allow edit.
    if (this.isProtectedCustomer() && this.props.appState.currentView !== Constants.CUSTOMER) {
      return;
    }

    // If contact is new and we don't have a first and last name, show error.
    if (uuid === "new") {
      const contact = this.getContactsContainerRef().contacts.find(item => item.uuid === uuid);
      if (contact) {
        // If we're adding a new contact to an existing customer, then just remove the "new" contact
        if (!contact.firstname && !contact.lastname && !this.state.isNew) {
          const contacts = this.getContactsContainerRef().contacts.filter(contact => {
            return contact.contactuuid !== uuid;
          });
          this.setStateContacts(contacts);
          return;
        } else if (!contact.firstname || !contact.lastname) {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "First and last name are required.",
          });
          return;
        }
      }
    }

    // Toggle the edit mode between true and false
    const contacts = this.getContactsContainerRef().contacts.map(contact => {
      if (contact.contactuuid === uuid) {
        contact.editmode = !contact.editmode;
      }
      return contact;
    });
    // Callback will allow the contact card to set the focus
    // of the cursor on the firstname field
    this.setStateContacts(contacts, callback);
  };

  // Called when the user selects a create, pick, or cancel in the supplier name picker
  //    The "id" is the uuid of the company/supplier and the "text" is the company name.
  handlePickSupplier = (response, item, event) => {
    // Grab and clear the original company name from the DOM
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);
    if (response === Constants.OVERLAY_RESPONSE_CANCEL) {
      // Put back the previous value
      this.setState(
        prevState => ({
          product: {
            ...prevState.product,
            companyname: prev,
          },
        }),
        () => {
          //this.companyNameInput.current.focus();
        }
      );
    } else if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      const company = item.company;
      const companyuuid = item.id;
      const companyname = item.text;
      // Update the product in the state with the selected company's info
      this.setState(
        prevState => ({
          product: {
            ...prevState.product,
            companyuuid: companyuuid,
            companyname: companyname,
          },
        }),
        () => {
          // Update the database if this product is not "new"
          if (this.state.product.productuuid !== null) {
            // Trigger a database update
            if (this.state.product.productuuid) {
              this.putProduct("text", "companyuuid", companyuuid);
            }
          }
          // If this is a purchase without a company, then update the order with the company info
          if (this.props.appState.currentView === Constants.PURCHASE && !this.state.order?.company) {
            this.setState(
              prevState => ({
                order: {
                  ...prevState.order,
                  company: company,
                  companyuuid: companyuuid,
                  contactname: company.contacts[0].firstname + " " + company.contacts[0].lastname,
                  contactuuid: company.contacts[0].contactuuid,
                },
              }),
              () => {
                if (this.state.order.orderuuid) {
                  this.putOrder(this.state.order.orderuuid, "companyuuid", companyuuid);
                }
              }
            );
          }
        }
      );
    }
  };

  // Called when the user selects a create, pick, or cancel in the supplier name picker
  //    The "id" is the uuid of the company/supplier and the "text" is the company name.
  handlePickSupplierBridge = (response, item, event) => {
    // Grab and clear the original company name from the DOM
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);

    // Okay to process focus events now that the dialog is gone
    this.showingDialog = false;
    const index = this.currentSupplierIndex;
    this.currentSupplierIndex = null;
    if (response === Constants.OVERLAY_RESPONSE_CANCEL) {
      // TODO: Set focus on suppliername field after updating the state
      const suppliers = this.state.product.suppliers.map((supplier, supplierindex) => {
        // Only change the specified supplier
        if (index === supplierindex) {
          supplier.companyname = prev;
        }
        return supplier;
      });
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          suppliers: suppliers,
        },
      }));
    } else if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      const companyuuid = item.id;
      const companyname = item.text;
      const suppliers = this.state.product.suppliers.map((supplier, supplierindex) => {
        // Only change the specified supplier
        if (index === supplierindex) {
          supplier.companyuuid = companyuuid;
          supplier.companyname = companyname;
        }
        return supplier;
      });
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          suppliers: suppliers,
        },
      }));
      if (this.state.product.suppliers[index].supplieruuid === "new") {
        this.postSupplier(index);
      } else {
        // Update the supplier with a PUT request
        this.putSupplier(this.state.product.suppliers[index].supplieruuid, "companyuuid", companyuuid);
      }
      // TODO: Set focus on sku field after updating the state
    }
  };

  handleCreateGenericOrder = (ordertype, menu, ordersubtype = null, override = false) => {
    // TODO: Filter to only allow customer order or purchase for items that have not been already ordered (e.g. not PURCHASE_CREATED, ORDERED, etc.)

    // If items not usually eligible for being added to the new order are selected, then ask for confirmation
    if (!override && this.state.selectedOrderItems?.length > 0) {
      let needsOverride = false;
      let text = "";
      // Purchase overrides
      if (ordertype === Constants.PURCHASE && Helper.inList([Constants.ORDER, Constants.REPAIR, Constants.QUOTE], this.props.appState.currentView)) {
        const normallyIneligible = this.state.selectedOrderItems.filter(item =>
          [Constants.ORDER_STATUS_ORDERED, Constants.ORDER_STATUS_FULLY_RECEIVED].includes(item.lineitemstatus)
        );
        if (normallyIneligible.length > 0) {
          needsOverride = true;
          if (normallyIneligible.length === 1) {
            text =
              "One of the selected items has already been ordered.\nAre you sure you want to add it to a new PO and reset its status to 'PO Generated'?";
          } else {
            text =
              "Some of the selected items have already been ordered.\nAre you sure you want to add them to a new PO and reset their statuses to 'PO Generated'?";
          }
        }
      }
      if (needsOverride) {
        this.props.showOverlay({
          type: Constants.OVERLAY_QUESTION,
          text: text,
          callback: response => {
            if (response === Constants.OVERLAY_RESPONSE_YES) {
              this.handleCreateGenericOrder(ordertype, menu, ordersubtype, true);
            }
          },
        });
        return;
      }
    }

    // Only allow invoicing of un-invoiced line items from other order types
    if (ordertype === Constants.INVOICE && ordersubtype !== Constants.RETURN && Helper.isOrderView(this.props.appState.currentView)) {
      const uninvoiced = this.state.order?.orderitems.filter(item => !Helper.inList(Constants.INVOICE_ORDER_STATUSES, item.lineitemstatus));
      const linkedInvoices = this.state.order?.linkeditems.filter(item => item.ordertype === Constants.INVOICE);
      if (uninvoiced.length === 0 && linkedInvoices.length === 1) {
        // If there are no uninvoiced items and there is a linked invoice, then show the invoice
        this.props.handleEditItem(Constants.INVOICE, Constants.INVOICES, linkedInvoices[0]);
        return;
      } else if (uninvoiced.length === 0 && linkedInvoices.length > 1) {
        // If there are no uninvoiced items and multiple linked invoices, then ask user to select an invoice
        const items = linkedInvoices.map(item => {
          return {
            id: item.orderuuid,
            text:
              "I-" +
              item.ordernumber +
              " - " +
              numeral(item.totalprice).format(Constants.CURRENCY_WITH_SYMBOL) +
              " (" +
              Helper.formatDate(item.creationdatetime) +
              ")",
          };
        });
        this.props.showOverlay({
          type: Constants.OVERLAY_PICKER,
          text: "Select an invoice",
          callback: this.handlePickInvoiceToShow,
          items: items,
          event: null,
          key: null,
        });
        return;
      } else if (uninvoiced.length === 0) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Nothing to invoice.",
        });
        return;
      }
    }

    let order = Helper.getBlankOrder(ordertype, this.props.appState.salesperson, ordersubtype);
    if (this.props.appState.currentView === Constants.PRODUCT) {
      // Format the max discount as a decimal, if it is currently a percent
      let maxdiscount = this.state.product.maxdiscount;
      if (maxdiscount && maxdiscount.includes("%")) {
        maxdiscount = numeral(maxdiscount).value();
      }
      // If the current view is a Product, then put the single product into an order object
      order = {
        ...order,
        orderitems: [
          {
            ...this.state.product,
            totalcost: this.state.product.cost,
            totalprice: this.state.product.sellprice,
            quantity: 1,
            sku: this.state.product.sku,
            quantityreceived: 0,
            discount: "0.00",
            maxdiscount: maxdiscount,
            commission: this.state.product.commission,
            associate: this.props.appState.salesperson,
            ordertype: ordertype,
            lineitemstatus: Constants.ORDER_STATUS_OPEN,
            parentorderitemuuid: null,
            rootorderitemuuid: null,
          },
        ],
      };
    } else if (Helper.inList([Constants.BILLING, Constants.CUSTOMER, Constants.SUPPLIER], this.props.appState.currentView)) {
      // If the current view is a Customer or Supplier, then put the single company/contact into an order object
      order = {
        ...order,
        orderitems: [],
        companyname: this.state.company.companyname,
        company: this.state.company,
        contactuuid: this.state.company.contacts.length > 0 ? this.state.company.contacts[0].contactuuid : null,
        contactname:
          this.state.company.contacts.length > 0 ? this.state.company.contacts[0].firstname + " " + this.state.company.contacts[0].lastname : null,
      };
    } else if (
      ordertype === Constants.PURCHASE &&
      Helper.inList([Constants.ORDER, Constants.REPAIR, Constants.QUOTE], this.props.appState.currentView)
    ) {
      // If we're creating a Purchase-type order and
      // If the current view is an Order-type object, then put the product items into an order object
      // Put the contact info into each of the order items
      const allOrderItems = this.state.order?.orderitems.filter(
        item =>
          ![
            Constants.ORDER_STATUS_ORDERED,
            Constants.ORDER_STATUS_FULLY_RECEIVED,
            Constants.ORDER_STATUS_PARTIALLY_INVOICED,
            Constants.ORDER_STATUS_INVOICED,
            Constants.ORDER_STATUS_PARTIAL_PAYMENT,
            Constants.ORDER_STATUS_PAID_IN_FULL,
            Constants.ORDER_STATUS_REFUNDED,
          ].includes(item.lineitemstatus)
      );
      const orderitems = this.state.selectedOrderItems.length > 0 ? this.state.selectedOrderItems : allOrderItems;
      order = {
        ...order,
        sourceorderuuid: this.state.order?.orderuuid,
        orderitems: orderitems.map(item => {
          item.totalcost = item.cost * item.quantity;
          item.totalprice = (item.sellprice - item.discount) * item.quantity;
          item.quantityreceived = 0;
          item.ordertype = ordertype;
          item.contactuuid = this.state.order?.contactuuid;
          item.contactname = this.state.order?.contactname;
          item.lineitemstatus = Constants.ORDER_STATUS_PURCHASE_CREATED;
          // Reminder: rootorderitemuuid and parentorderitemuuid are set in the parent_data field
          return item;
        }),
      };
    } else if (Helper.inList([Constants.ORDER, Constants.REPAIR, Constants.QUOTE, Constants.INVOICE], this.props.appState.currentView)) {
      // Start with either the entire order item list or the selected order items
      let orderitems = this.state.selectedOrderItems?.length > 0 ? this.state.selectedOrderItems : this.state.order.orderitems;

      // Filter out any invoiced order items when going to an invoice order type that is not a return
      if (ordertype === Constants.INVOICE && ordersubtype !== Constants.RETURN) {
        orderitems = orderitems.filter(item => !Constants.INVOICE_ORDER_STATUSES.includes(item.lineitemstatus));
      } else if (ordertype === Constants.ORDER) {
        orderitems = orderitems.filter(item =>
          [Constants.ORDER_STATUS_OPEN, Constants.ORDER_STATUS_SENT, Constants.ORDER_STATUS_BACKORDERED].includes(item.lineitemstatus)
        );
      }

      // If we're creating an order-type object OTHER THAN a Purchase and
      // If the current view is an Order-type object, then put the product items into an order object
      // And put the single company/contact into the new order object
      const payments = this.state.order?.payments && this.state.order?.payments.length > 0 ? Helper.deepCopy(this.state.order?.payments) : [];
      order = {
        ...order,
        sourceorderuuid: this.state.order?.orderuuid,
        contactuuid: this.state.order?.contactuuid,
        contactname: this.state.order?.contactname,
        ponumber: this.state.order?.ponumber,
        notes: this.state.order?.notes,
        company: this.state.order?.company,
        orderitems: orderitems.map(item => {
          item.ordertype = ordertype;
          let lineitemstatus = item.lineitemstatus;
          // If creating an invoice, the item statuses will be "invoiced"
          if (ordertype === Constants.INVOICE) {
            lineitemstatus = Constants.ORDER_STATUS_INVOICED;
          }
          // The "sent" status does not propagate to other orders
          if (lineitemstatus === Constants.ORDER_STATUS_SENT) {
            lineitemstatus = Constants.ORDER_STATUS_OPEN;
          }
          // If this is an invoice, override the status to "invoiced"
          item.lineitemstatus = lineitemstatus;
          return item;
        }),
        payments: payments,
      };
    }

    // Calculate order totals based on order items
    order = Helper.calculateOrderTotals(order);

    this.props.handleEditItem(ordertype, menu, order);
  };

  handlePickInvoiceToShow = (response, invoice) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      const linkedInvoices = this.state.order?.linkeditems.filter(item => item.orderuuid === invoice.id);
      this.props.handleEditItem(Constants.INVOICE, Constants.INVOICES, linkedInvoices[0]);
    }
  };

  // Delete only the selected customer
  handleDeleteOrderItem = (uuid, prev) => {
    if (!this.state.isNew && this.state.order?.orderitems.length === 1) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Cannot delete last item. Delete " + this.props.appState.currentView.toLowerCase() + " instead.",
      });
    } else {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Are you sure you want to delete?",
        callback: this.maybeDeleteOrderItem,
        key: uuid,
        value: prev,
      });
    }
  };

  // Delete only the selected customer
  handleDeleteContact = uuid => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete?",
      callback: this.maybeDeleteContact,
      key: uuid,
    });
  };

  handleDeleteSupplier = () => {
    if (this.state.product.suppliers.length > 0) {
      // Copy the attributes of the first supplier over the supplier attributes on the product
      const supplier = this.state.product.suppliers[0];
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          companyuuid: supplier.companyuuid,
          companyname: supplier.companyname,
          cost: supplier.cost,
          sku: supplier.sku,
        },
      }));
      // Remove the deleted supplier from the supplier bridge list in the product
      const suppliers = this.state.product.suppliers.filter(sup => sup.supplieruuid !== supplier.supplieruuid);
      this.setState(
        prevState => ({
          product: {
            ...prevState.product,
            suppliers: suppliers,
          },
        }),
        () => {
          // Commit the product changes to the database
          this.putProductSupplier(supplier);
          // Delete the supplier bridge record from the database
          this.deleteSupplier(supplier);
        }
      );
    } else {
      this.setState(
        prevState => ({
          product: {
            ...prevState.product,
            companyuuid: "",
            companyname: "",
            cost: "",
            sku: "",
          },
        }),
        () => {
          // Commit the changes to the database, if the product is not new
          if (this.state.product?.productuuid) {
            this.putProductSupplier({ companyuuid: "", cost: "", sku: "" });
          }
        }
      );
    }
  };

  handleDeleteSupplierBridge = index => {
    const supplier = this.state.product.suppliers[index];
    if (supplier.supplieruuid !== "new") {
      // Remove the deleted supplier from the supplier list
      // In the product in the state...
      const suppliers = this.state.product.suppliers.filter(sup => sup.supplieruuid !== supplier.supplieruuid);
      this.setState(
        prevState => ({
          product: {
            ...prevState.product,
            suppliers: suppliers,
          },
        }),
        () => {
          this.deleteSupplier(supplier);
        }
      );
    } else {
      // Remove the deleted supplier from the supplier lists
      // In the product in the state...
      const suppliers = this.state.product.suppliers.filter((_, sup_index) => sup_index !== index);
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          suppliers: suppliers,
        },
      }));
    }
  };

  handleClickOutside = event => {
    if (this.purchaseItemStatusRef?.current && !this.purchaseItemStatusRef.current.contains(event.target)) {
      this.setState({ showPurchaseItemStatus: false });
    }
    if (this.productSearchWrapperRef?.current && !this.productSearchWrapperRef.current.contains(event.target)) {
      this.handleClearProductSearch();
    }
    if (this.billingPlanSearchWrapperRef?.current && !this.billingPlanSearchWrapperRef.current.contains(event.target)) {
      this.handleClearBillingPlanSearch();
    }
    if (this.contactSearchWrapperRef?.current && !this.contactSearchWrapperRef.current.contains(event.target)) {
      this.handleClearCustomerSearch();
    }
    if (this.baseDetailNameInput?.current && !this.baseDetailNameInput.current.contains(event.target)) {
      this.maybeDisableSupplierNameEdit(this.state.company.companyname, event);
    }
    if (this.state.showSelectStatus && this.orderStatusRef?.current && !this.orderStatusRef.current.contains(event.target)) {
      this.setState({ showSelectStatus: false });
    }
    if (this.state.showSelectStatus && this.prospectStatusRef?.current && !this.prospectStatusRef.current.contains(event.target)) {
      this.setState({ showSelectStatus: false });
    }
    if (this.state.showFilterOptions && this.filterOptionsRef?.current && !this.filterOptionsRef.current.contains(event.target)) {
      this.setState({ showFilterOptions: false });
    }
  };

  handleSelectTechnician = (fullname, index) => {
    const event = { target: { id: "technician", type: "text", value: fullname } };
    const item = this.state.order?.repairitems[index];
    const prev = item.fullname;

    this.handleChange(event, Constants.REPAIR_ITEM, item.uuid, index);
    this.handleBlurRepairItem(event, item.uuid, prev, index);
  };

  handleSelectFamily = (familyname, index) => {
    const event = { target: { id: "family", type: "text", value: familyname } };
    const item = this.state.order?.repairitems[index];
    const prev = item.family;

    this.handleChange(event, Constants.REPAIR_ITEM, item.uuid, index);
    this.handleBlurRepairItem(event, item.uuid, prev, index);
  };

  handleSelectStatus = (statusuuid, index) => {
    if (this.state.order?.ordertype === Constants.REPAIR) {
      const event = { target: { id: "repairitemstatus", type: "text", value: statusuuid } };
      const item = this.state.order?.repairitems[index];
      const prev = item.repairitemstatus;

      this.handleChange(event, Constants.REPAIR_ITEM, item.uuid, index);
      this.handleBlurRepairItem(event, item.uuid, prev, index);
    } else if (this.state.order?.ordertype === Constants.PURCHASE) {
      const event = { target: { id: "lineitemstatus", type: "text", value: statusuuid } };
      const item = this.state.order?.orderitems[index];
      const prev = item.lineitemstatus;
      if (prev !== event.target.value) {
        this.handleChange(event, Constants.ORDER_ITEM, item.uuid, index);
        this.handleBlurOrderItem(event, item.uuid, prev);
      }
      // Check for status change to "Fully Received"
      if (statusuuid === Constants.ORDER_STATUS_FULLY_RECEIVED) {
        if (Helper.isQuantityReceivedLessThanQuantity(item)) {
          const e = { target: { id: "quantityreceived", type: "text", value: item.quantity } };
          const p = item.quantityreceived;
          this.handleChange(e, Constants.ORDER_ITEM, item.uuid, index);
          this.handleBlurOrderItem(e, item.uuid, p);
        }
      }
    }
  };

  handlePickCompany = (response, item, event) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      // Build a temporary contact to send to the order page handler
      const contact = {
        companyuuid: item.id,
        firstname: "",
        lastname: "",
        contactuuid: null,
      };
      this.selectCustomerListItem(contact);
    } else if (response === Constants.OVERLAY_RESPONSE_CANCEL) {
      // Nothing to do
    }
  };

  handlePickSalesperson = (response, item, event) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      // Build a temporary contact to send to the order page handler
      const contact = {
        companyuuid: this.state.order?.company.companyuuid,
        firstname: "",
        lastname: "",
        contactuuid: item.id,
      };
      this.selectCustomerListItem(contact);
    } else if (response === Constants.OVERLAY_RESPONSE_CANCEL) {
      // Nothing to do
    }
  };

  handleRemoveCustomer = () => {
    if (this.state.isNew) {
      this.setState(prevState => ({
        order: {
          ...prevState.order,
          company: null,
          contactuuid: null,
          contactname: "",
          companyname: "",
        },
        contactuuid: null,
      }));
    } else if (this.props.appState.currentView === Constants.PURCHASE) {
      const overlay = {
        type: Constants.OVERLAY_SEARCH_CONTACTS,
        text: "Select a new supplier for the " + this.props.appState.currentView.toLowerCase(),
        searchkey: "",
        searchresults: [],
        buttonText: "New Supplier",
        callback: this.handleChangeSupplier,
        inputFieldRef: this.contactSearchInput,
      };
      this.props.showOverlay(overlay);
    } else {
      const overlay = {
        type: Constants.OVERLAY_SEARCH_CONTACTS,
        text: "Select a new customer for the " + this.props.appState.currentView.toLowerCase(),
        searchkey: "",
        searchresults: [],
        buttonText: "New Customer",
        callback: this.handleChangeCustomer,
        inputFieldRef: this.contactSearchInput,
      };
      this.props.showOverlay(overlay);
    }
  };

  handleAddNewSupplier = () => {
    const contact = Helper.getBlankContact(this.props.appState.salesperson, Constants.SUPPLIER_COMPANY);
    const company = Helper.getBlankCompany(Constants.SUPPLIER_COMPANY, [contact], this.props.appState.salesperson, true);
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        company: company,
      },
    }));
  };

  handleAddNewCustomer = () => {
    const contact = Helper.getBlankContact(this.props.appState.salesperson, Constants.CUSTOMER_FAMILY);
    const company = Helper.getBlankCompany(Constants.CUSTOMER_FAMILY, [contact], this.props.appState.salesperson, true);
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        company: company,
      },
    }));
  };

  handleAddNewProduct = () => {
    const product = Helper.getBlankProduct(this.props.appState.salesperson);
    product.inventory = 0;
    // On Purchases, copy the supplier info to the new product, if it has been chosen already
    if (this.props.appState.currentView === Constants.PURCHASE) {
      product.companyuuid = this.state.order?.company?.companyuuid ?? "";
      product.companyname = this.state.order?.company?.companyname ?? "";
    }
    this.setState(prevState => ({
      product: product,
      productSearchKey: "",
    }));
  };

  handleAddRepairItem = () => {
    let repairitems = this.state.order?.repairitems;
    repairitems.push(Helper.getBlankRepairItem(this.state.order?.orderuuid));
    this.setState(prevState => ({
      order: { ...prevState.order, repairitems: repairitems },
    }));
  };

  handleInputGiftCardNumber = (response, product, giftCardNumber) => {
    if (response === Constants.OVERLAY_RESPONSE_CANCEL) {
      this.props.hideOverlay();
      return;
    }
    // Make sure the gift card number is not already in the order
    const existing = this.state.order?.orderitems?.find(item => item.giftCardNumber === giftCardNumber);
    if (existing) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Gift card number already in order",
        callback: this.handleClearProductSearch,
      });
      return;
    }
    // Make sure the SKU/UPC/EAN was not scanned as the gift card number
    if ([product.upc, product.altupc, product.sku, product.storesku, product.ean].includes(giftCardNumber)) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Gift card number cannot be the same as the SKU/UPC",
        callback: () => {
          this.handleShowGiftCardInput(product);
        },
      });

      return;
    }
    // Check for previously sold gift card number
    if (giftCardNumber && giftCardNumber.trim()) {
      this.props.getPayment(
        giftCardNumber,
        giftcard => {
          // gift card previously sold
          product.gift_card_exists = true;
          this.handleAddGiftCard(giftCardNumber, product);
        },
        () => {
          // gift card not previously sold
          this.handleAddGiftCard(giftCardNumber, product);
        }
      );
    } else {
      this.handleAddGiftCard(giftCardNumber, product);
    }
  };

  handleAddGiftCard = (giftCardNumber, product) => {
    // Gift card was not previously sold
    // Check for OK response and a valid gift card number
    product.giftCardNumber = giftCardNumber;
    this.selectProductListItem(product);
  };

  handleInputGiftCardAmount = (response, product, giftCardAmount) => {
    if (response === Constants.OVERLAY_RESPONSE_CANCEL) {
      this.props.hideOverlay();
      return;
    }
    // Check for OK response and a valid gift card amount
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      product.sellprice = numeral(giftCardAmount).value();
      this.selectProductListItem(product);
    } else {
      // Not entering anything leaves the amount as zero
      this.selectProductListItem(product, true, true);
    }
  };

  handleClearCustomerSearch = () => {
    this.setState({ contactSearchKey: "", contactSearchResults: null });
  };

  handleClearProductSearch = () => {
    this.setState({ productSearchKey: "", productSearchResults: null, productSearchCount: 0 });
  };

  handleClearBillingPlanSearch = () => {
    this.setState({
      billingPlanSearchKey: "",
      billingPlanSearchResults: null,
      billingPlanSearchCount: 0,
    });
  };

  handleLoadMoreProducts = () => {
    // Limit the "load more" to four additional operations
    if (this.state.productSearchLimit < numeral(this.props.appState.displaySettings.FOLDER_ROWS).multiply(Constants.PRODUCT_SEARCH_PAGES).value()) {
      this.setState(prevState => ({
        productSearchLimit: numeral(prevState.productSearchLimit).add(this.props.appState.displaySettings.FOLDER_ROWS).value(),
      }));
    }
  };

  handleLoadMoreBillingPlans = () => {
    if (this.state.billingPlanSearchLimit < 25) {
      this.setState(prevState => ({
        billingPlanSearchLimit: prevState.billingPlanSearchLimit + 5,
      }));
    }
  };

  handlePaySubscriptionInvoice = (products, company) => {
    let order = Helper.getBlankOrder(Constants.INVOICE, this.props.appState.salesperson, null, company, products);
    order = Helper.calculateOrderTotals(order);
    this.props.handleEditItem(Constants.INVOICE, Constants.INVOICES, order);
  };

  handleSendInvoices = (invoices, email) => {
    invoices.forEach(invoice => {
      this.postBillingSendInvoice(invoice, email);
    });
  };

  handleMarkInvoicesPaid = invoices => {
    invoices.forEach(invoice => {
      this.postBillingApplyInvoicePayment(
        invoice.invoice_id,
        invoice.amt_balance,
        invoice.map_payment_type,
        invoice.map_date_payment,
        invoice.map_payment_description,
        () => {
          // Called after the last invoice is marked paid
          this.setState({ editingPayments: false });
        }
      );
    });
  };

  handlePayInvoices = (invoices, company) => {
    // Maast-only customers cannot be used for an in-store payment
    if (this.isMaastOnlyCustomer()) {
      return;
    }
    this.props.getProduct(Constants.ALTUPC_SUBSCRIPTION_PAYMENT, response => {
      if (response.status === 200) {
        // We found the product
        const products = Helper.copyInvoiceDataToProduct(invoices, response.body);
        this.handlePaySubscriptionInvoice(products, company);
      } else {
        // The product does not exist. Create it...
        const product = Helper.getBlankSubscriptionPaymentProduct(this.props.appState.salesperson);
        this.postProduct(product, product => {
          // Product created
          const products = Helper.copyInvoiceDataToProduct(invoices, product);
          this.handlePaySubscriptionInvoice(products, company);
        });
      }
    });
  };

  handleMarkAsPaid = () => {
    // For managers and above, prompt to mark as paid
    if (Helper.authorize(Constants.ACTION_MARK_AS_PAID, this.props.appState.usertype)) {
      this.putOrder(this.state.order?.orderuuid, "orderstatus", Constants.ORDER_STATUS_PAID_IN_FULL);
    } else {
      // Manager is required to mark as paid
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Only a manager or above can mark a zero balance invoice as paid.",
      });
    }
  };

  handlePay = () => {
    // Show the Pay screen for Payments and Deposits. Stay on the current menu option (Invoice, Order, etc.)
    this.props.handleEditItem(Constants.PAY, this.props.appState.currentMenu, this.state.order);
  };

  handleRefund = () => {
    // Show the Pay screen for Refunds
    this.props.handleEditItem(Constants.PAY, Constants.INVOICES, this.state.order);
  };

  handleReturn = () => {
    // Create a new return-type invoice for the selected items, taxes, and payments
    this.handleCreateGenericOrder(Constants.INVOICE, Constants.INVOICES, Constants.RETURN);
  };

  handleWalkInCustomer = () => {
    // Check for walkin customer in local storage
    if (localStorage.getItem(Constants.LOCAL_STORAGE_WALKIN_CUSTOMER)) {
      const walkinCustomer = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_WALKIN_CUSTOMER));
      const contact = walkinCustomer.contacts[0];
      const contactname = contact.firstname + " " + contact.lastname;
      this.setState(prevState => ({
        contactuuid: contact.contactuuid,
        order: {
          ...prevState.order,
          contactname: contactname,
          contactuuid: contact.contactuuid,
          companyname: contact.companyname,
          company: walkinCustomer,
        },
      }));
    } else {
      const walkInUUID = this.state.clientSettings[Constants.SETTINGS_WALKIN_CUSTOMER];
      this.getProtectedCustomer(walkInUUID);
    }
  };

  handleStockOrder = () => {
    // Check for stock order customer in local storage
    if (localStorage.getItem(Constants.LOCAL_STORAGE_STOCK_ORDER_CUSTOMER)) {
      const stockOrderCustomer = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_STOCK_ORDER_CUSTOMER));
      const contact = stockOrderCustomer.contacts[0];
      const contactname = contact.firstname + " " + contact.lastname;
      this.setState(prevState => ({
        contactuuid: contact.contactuuid,
        order: {
          ...prevState.order,
          contactname: contactname,
          contactuuid: contact.contactuuid,
          companyname: contact.companyname,
          company: stockOrderCustomer,
        },
      }));
    } else {
      const stockOrderUUID = this.state.clientSettings[Constants.SETTINGS_STOCK_ORDER_CUSTOMER];
      this.getProtectedCustomer(stockOrderUUID);
    }
  };

  handlePublish = () => {
    let duplicateWarning = false;
    if (this.state.campaign?.additionaldataprompt) {
      const { fields } = Helper.renderAdditionalDataPrompt(
        this.state.campaign?.additionaldataprompt,
        () => {},
        () => {}
      );
      // Look for duplicate field names
      const hasDuplicates = fields.some((field, index) => fields.indexOf(field) !== index);
      if (hasDuplicates) {
        duplicateWarning = true;
      }
    }
    if (duplicateWarning) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Duplicate field names not allowed.\nRemove duplicate field name from the\nCampaign Questions before publishing.",
      });
    } else {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Are you sure you want to PUBLISH this campaign?",
        callback: this.maybePublishCampaign,
        key: this.state.campaign?.campaignuuid,
      });
    }
  };

  handleDraft = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to move this campaign to DRAFT status?",
      callback: this.maybeUnpublishCampaign,
      key: this.state.campaign?.campaignuuid,
    });
  };

  handlePreview = () => {
    // Get the link and open the campaign form in a new tab
    const url = Helper.getCampaignLink(true, this.state.campaign.subdomain, this.state.campaign.campaignuuid);
    window.open(url, "_blank");
  };

  handleDownloadQRCode = id => {
    var svg = document.getElementById(id).children[0];

    // Serialize the SVG XML
    var serializer = new XMLSerializer();
    var svgString = serializer.serializeToString(svg);

    // Create a canvas and context to draw the SVG
    var canvas = document.createElement("canvas");
    // Set canvas dimensions to be 200% of the original SVG dimensions
    canvas.width = svg.width.baseVal.value * 2;
    canvas.height = svg.height.baseVal.value * 2;
    var ctx = canvas.getContext("2d");

    // Scale the context by 200%
    ctx.scale(2, 2);

    // Create an image element to load the SVG data
    var img = new Image();
    const thisState = this.state;
    img.onload = function () {
      // Draw the SVG image onto the canvas
      ctx.drawImage(img, 0, 0);

      // Convert the canvas to a data URL (PNG)
      var pngDataUrl = canvas.toDataURL("image/png");

      // Create a link element
      var link = document.createElement("a");
      link.href = pngDataUrl;
      const filename = Helper.fieldNameToID(thisState.campaign?.campaignname ?? "campaign") + "_qr_code.png";
      link.download = filename;

      // Programmatically click the link to trigger the download
      link.click();
    };

    // Set the source of the image element to the SVG data
    img.src = "data:image/svg+xml;base64," + btoa(svgString);
  };

  handlePrint = () => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      // Build an array of orderitemuuids for open/created order items
      const orderitemuuids = this.state.order?.orderitems
        .filter(item => item.lineitemstatus === Constants.ORDER_STATUS_OPEN || item.lineitemstatus === Constants.ORDER_STATUS_PURCHASE_CREATED)
        .map(item => item.orderitemuuid);

      // If the order is a purchase, set the open/created order items to Ordered
      if (this.state.order?.ordertype === Constants.PURCHASE && orderitemuuids.length > 0) {
        this.putOrderItemStatuses(orderitemuuids, Constants.ORDER_STATUS_ORDERED, () => {
          window.print();
        });
        // If the order is a quote, set the open/created order items to Sent
      } else if (this.state.order?.ordertype === Constants.QUOTE && orderitemuuids.length > 0) {
        this.putOrderItemStatuses(orderitemuuids, Constants.ORDER_STATUS_SENT, () => {
          window.print();
        });
      } else {
        window.print();
      }
    } else {
      window.print();
    }
  };

  handleSMS = () => {
    // Check if feature is setup
    const smsEnabled = this.props.appState?.twilio?.phonenumber?.length > 0;
    if (!smsEnabled) {
      // Show message about setting up and exit
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Text messaging is not setup. Contact tech support for assistance.",
      });
      return;
    }
    // Get a list of opted-in phone numbers for this company
    const companyuuid = this.getContactsContainerRef()?.companyuuid;
    this.getSMSContacts(companyuuid, this.handleSmsPhoneOptions);
  };

  // Get a list of non-opted-out phone numbers for this company
  handleSmsPhoneOptions = response => {
    let contactPhoneNumbers = [];
    response?.body?.records?.forEach(contact => {
      if (!contact.optoutdatetime) {
        const phonenumber = Helper.formatPhoneNumber(contact.phonenumber.slice(2));
        contactPhoneNumbers.push({
          id: contact.phonenumber,
          text: phonenumber + " - " + contact.firstname + " " + contact.lastname,
          uuid: contact.smscontactuuid,
          contactphonenumber: contact.phonenumber,
          smscontactuuid: contact.smscontactuuid,
        });
      }
    });
    // Add in any mobile number from the company's contacts that are not already in the list
    (this.state.company?.contacts ?? this.state.order?.company?.contacts).forEach(contact => {
      if (
        contact.mobilephone &&
        !contactPhoneNumbers.find(item => item.contactphonenumber === Helper.formatPhoneNumberForTwilio(contact.mobilephone))
      ) {
        contactPhoneNumbers.push({
          id: contact.mobilephone,
          text: contact.mobilephone + "* - " + contact.firstname + " " + contact.lastname,
          uuid: contact.contactuuid,
          contactphonenumber: contact.mobilephone,
          smscontactuuid: null,
        });
      }
    });
    // No non-opted-out phone numbers - show message about opting in and exit
    if (contactPhoneNumbers.length === 0) {
      const view = this.props.appState.currentView.toLowerCase();
      // Format phone number for display and remove leading "1"
      const phone = Helper.formatPhoneNumber(this.props.appState.twilio.phonenumber).substring(1);
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: `This ${view} does not have an opted-in phone number.\nAsk them to text the keyword START to ${phone}.`,
      });
      return;
    }

    // If more than one opted-in phone number, show picker
    if (contactPhoneNumbers.length > 1) {
      const overlay = {
        type: Constants.OVERLAY_PICKER,
        text: "Which contact?",
        callback: (response, item, event) => {
          this.handlePickSmsNumber(response, item);
        },
        items: contactPhoneNumbers,
      };
      this.props.showOverlay(overlay);
    } else {
      this.handlePickSmsNumber(Constants.OVERLAY_RESPONSE_SELECT, contactPhoneNumbers[0]);
    }
    //   If cancel, exit
    // Show message prompt for selected phone number
    //   If cancel, exit
    // Send message
  };

  handlePickSmsNumber = (response, item) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      // Check if this contact came from the SMS contacts table or just the Contacts table
      if (item.smscontactuuid) {
        // If the contact is from the SMS contacts table, then send the message
        this.handleEnterSmsMessage(item.smscontactuuid);
      } else {
        // If the contact is from the Contacts table, then prompt for consent
        const conditions =
          '"As part of our service we can send you automated text alerts regarding (describe reason for texting). ' +
          "We may send up to five messages per order (adjust as required). " +
          "Message and data rates may apply, depending on your mobile phone service plan. " +
          "At any time you can get more help by replying HELP to these texts, or you can opt out completely by replying STOP. " +
          "Mobile Terms of Service and our Privacy Statement are available at https://pladdmusic.billinghound.com/. " +
          'Please reply with "yes" or "no" to indicate if you would like this service".';
        this.props.showOverlay({
          type: Constants.OVERLAY_QUESTION,
          title: "Customer consent to text",
          text:
            "Read the following statement to the contact and click their response below.\n-\n" +
            conditions +
            "\n-\nYou can also ask them to text the keyword START to " +
            Helper.formatPhoneNumber(this.props.appState.twilio.phonenumber.slice(2)) +
            ".\n-\nConsent verified by " +
            this.props.appState.salesperson,
          callback: response => {
            if (response === Constants.OVERLAY_RESPONSE_YES) {
              this.props.showOverlay({
                type: Constants.OVERLAY_PROGRESS,
                text: "Sending welcome message...",
              });
              const contactuuid = item.uuid;
              this.postSMS(null, null, contactuuid, smscontactuuid => {
                this.handleEnterSmsMessage(smscontactuuid);
              });
            } else {
              this.props.hideOverlay();
            }
          },
        });
      }
    }
  };

  handleTrackShipment = () => {
    const tracking = this.state.order?.trackingnumber;
    if (tracking) {
      // Split the tracking number into an array of tracking numbers based on line breaks
      const trackingNumbers = tracking.split("\n");
      if (trackingNumbers.length > 1) {
        // If there are multiple tracking numbers, show a picker
        const items = trackingNumbers.map(t => {
          return {
            id: t,
            text: t,
          };
        });
        this.props.showOverlay({
          type: Constants.OVERLAY_PICKER,
          text: "Select a tracking number",
          callback: this.handlePickTrackingNumber,
          items: items,
        });
      } else {
        this.handlePickTrackingNumber(Constants.OVERLAY_RESPONSE_SELECT, { id: tracking });
      }
    }
  };

  handlePickTrackingNumber = (response, item) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      window.open(`https://www.google.com/search?q=track+package+${item.id}`, "_blank");
    }
  };

  handleResendCampaignLinkToProspect = () => {
    // Confirm send
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to resend the campaign link to this prospect?",
      callback: this.maybeResendCampaignLinkToProspect,
    });
  };

  maybeResendCampaignLinkToProspect = response => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.postResendCampaignLinkToProspect(this.state.prospect?.marketingprospectuuid);
    } else {
      this.props.hideOverlay();
    }
  };

  handleEnterSmsMessage(smscontactuuid) {
    this.props.showOverlay({
      type: Constants.OVERLAY_INPUT_BOX,
      text: "Enter message",
      maxLength: 295,
      okButtonLabel: "Send",
      key: smscontactuuid,
      callback: (response, smscontactuuid, message) => {
        if (response === Constants.OVERLAY_RESPONSE_OK) {
          this.props.showOverlay({
            type: Constants.OVERLAY_PROGRESS,
            text: "Sending message...",
          });
          this.postSMS(smscontactuuid, message, null, () => {
            this.props.hideOverlay();
          });
        } else {
          // Cancelled
          this.props.hideOverlay();
        }
      },
    });
  }

  handleEmail = callback => {
    // Display an overlay listing all the possible email addresses (clickable)
    let emailAddresses = [];
    let contacts = [];
    if ([Constants.CUSTOMER, Constants.SUPPLIER, Constants.BILLING].includes(this.props.appState.currentView)) {
      if (this.props.appState.filtertype?.tab === Constants.TAB_PROSPECTS) {
        contacts = [this.state.prospect];
      } else {
        contacts = this.state.company.contacts;
      }
    } else {
      contacts = this.state.order?.company.contacts;
    }

    contacts.forEach(contact => {
      if (contact.email && !contact.donotemail) {
        emailAddresses.push({
          id: contact.email,
          text: contact.firstname + " - " + contact.email,
          uuid: contact.contactuuid ?? contact.marketingprospectuuid,
          email: contact.email,
        });
      }
    });
    const count_do_not_email = contacts.filter(contact => contact.donotemail).length;

    if (emailAddresses.length === 0) {
      let text = "This customer doesn't have an email address";
      if (count_do_not_email > 0) {
        text += "\n(or has 'Do Not Email' checked)";
      }
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: text,
      });
    } else if (emailAddresses.length === 1) {
      if (this.props.appState.clientSettings.FROM_VERIFIED === Constants.TRUE) {
        this.maybeChooseRepyTo(emailAddresses[0].uuid, emailAddresses[0].email, callback);
      } else {
        this.handleOpenEmailClient(emailAddresses[0].id);
      }
    } else {
      const overlay = {
        type: Constants.OVERLAY_PICKER,
        text: "Which Email Address?",
        callback: (response, item, event) => {
          this.handlePickEmail(response, item, event, callback);
        },
        items: emailAddresses,
        key: null,
        event: null,
      };
      this.props.showOverlay(overlay);
    }
  };

  handleOpenEmailClient = address => {
    let subject = "";
    let view = this.props.appState.currentView;
    let returnView = this.state.order?.ordersubtype ? this.state.order?.ordersubtype : " ";
    let subjectOrderNumber = " (" + returnView.charAt(0).trim() + view.charAt(0) + "-" + this.state.order?.ordernumber + ")";
    if (view === Constants.PURCHASE) {
      subject = "Purchase Order from " + this.state.clientSettings.NAME + subjectOrderNumber;
    } else if (view === Constants.QUOTE) {
      subject = "Your Quote from " + this.state.clientSettings.NAME + subjectOrderNumber;
    } else if (view === Constants.ORDER) {
      subject = "Your Order with " + this.state.clientSettings.NAME + subjectOrderNumber;
    } else if (Helper.inList([Constants.INVOICE, Constants.PAY], view) && returnView === Constants.RETURN) {
      subject = "Your " + this.state.clientSettings.NAME + " Refund" + subjectOrderNumber;
    } else if (Helper.inList([Constants.INVOICE, Constants.PAY], view)) {
      subject = "Your " + this.state.clientSettings.NAME + " Receipt" + subjectOrderNumber;
    } else if (view === Constants.REPAIR) {
      subject = "Your Service Estimate from " + this.state.clientSettings.NAME + subjectOrderNumber;
    } else if (view === Constants.CUSTOMER) {
      subject = "A message from " + this.state.clientSettings.NAME;
    } else if (view === Constants.SUPPLIER) {
      subject = "A message from " + this.state.clientSettings.NAME;
    }
    subject = encodeURIComponent(subject);
    this.handleUpdateOpenOrderItemStatuses(() => {
      window.open("mailto:" + address + "?subject=" + subject, "_blank");
    });
  };

  handlePickEmail = (response, item, event, callback) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      if (this.props.appState.clientSettings.FROM_VERIFIED === Constants.TRUE) {
        this.maybeChooseRepyTo(item.uuid, item.email, callback);
      } else {
        this.handleOpenEmailClient(item.uuid);
      }
    }
  };

  maybeChooseRepyTo = (uuid, email, callback) => {
    if (this.props.appState.replyToAllowed) {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Do you want replies to this message to go to " + this.props.appState.username + "?",
        callback: response => {
          let replyTo = null;
          if (response === Constants.OVERLAY_RESPONSE_YES) {
            replyTo = this.props.appState.salesperson + " <" + this.props.appState.username + ">";
          }
          this.handleEmailMessagePrompt(uuid, email, replyTo, callback);
        },
      });
    } else {
      this.handleEmailMessagePrompt(uuid, email, null, callback);
    }
  };

  handleEmailMessagePrompt = (uuid, email, replyTo = null, callback = null) => {
    // This callback is used when another function sends the email to the selected address
    // i.e. - Resend Invoice in billing
    if (callback) {
      callback(email);
      return;
    }
    let viewname = this.props.appState.currentView;
    if (viewname === Constants.PAY) {
      viewname = Constants.INVOICE;
    }
    let text;
    let placeholder = "Optional note...";
    if (Helper.isOrderView(this.props.appState.currentView)) {
      text = "Emailing " + viewname + " to " + email + ".\n\nYou may include a note in the email or leave it blank.";
    } else {
      text = "Emailing " + email + ".\n\nEnter message";
      placeholder = "Message...";
    }
    let checkbox = null;
    let checked = false;
    // Possibly include a pay link if this is an order view
    if (Helper.isOrderView(this.props.appState.currentView)) {
      checkbox = Helper.isPayLinkEligible(this.state.order) ? "Include payment link" : null;
      checked = Helper.isPayLinkEligible(this.state.order) && this.props.appState.currentView === Constants.INVOICE;
    }
    // Possibly include a campaign link if this is a customer view
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_CUSTOMER) {
      checkbox = "Include campaign link";
      checked = false;
    }
    // Possibly include a campaign link if this is a customer view
    else if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      checkbox = "Include campaign response";
      checked = false;
    }
    const overlay = {
      type: Constants.OVERLAY_INPUT_BOX,
      title: "Email Contact",
      text: text,
      placeholder: placeholder,
      maxLength: 1000,
      callback: this.maybeHandleSendEmailMessage,
      key: uuid,
      okButtonLabel: "Send",
      submitOnEnter: false,
      required: !Helper.isOrderView(viewname),
      extra: replyTo,
      checkbox: checkbox,
      checked: checked,
    };
    this.props.showOverlay(overlay);
  };

  maybeHandleSendEmailMessage = (response, key, message, replyTo, checked) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      if (checked && this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_CUSTOMER) {
        // Get the campaign list from the server
        this.props.showOverlay({ type: Constants.OVERLAY_PROGRESS, text: "Retrieving campaign list..." }, () => {
          this.getCampaigns(Constants.CAMPAIGN_STATUS_PUBLISHED, () => {
            this.handlePickCampaignForEmail(key, message, replyTo);
          });
        });
      } else if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
        this.handleSendEmailMessage(response, null, message, replyTo, checked, null, this.state.prospect?.marketingprospectuuid, null);
      } else {
        this.handleSendEmailMessage(response, key, message, replyTo, checked, null, null, null);
      }
    } else {
      this.props.hideOverlay();
    }
  };

  handlePickCampaignForEmail = (key, message, replyTo) => {
    if (this.state.campaigns.length === 0) {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "You have no published campaigns.\nSend email without a campaign link?",
        callback: response => {
          if (response === Constants.OVERLAY_RESPONSE_YES) {
            this.doHandleSendEmailMessage(Constants.OVERLAY_RESPONSE_OK, key, message, replyTo);
          } else {
            this.props.hideOverlay();
            return;
          }
        },
      });
    } else {
      // Prompt for which campaign, then send the email
      this.props.hideOverlay();
      const campaigns = this.state.campaigns.map(campaign => {
        campaign.id = campaign.campaignuuid;
        campaign.text = campaign.campaignname;
        return campaign;
      });
      this.props.showOverlay({
        type: Constants.OVERLAY_PICKER,
        text: "Select a campaign",
        callback: (response, campaign) => {
          if (response === Constants.OVERLAY_RESPONSE_SELECT) {
            this.doHandleSendEmailMessage(response, key, message, replyTo, campaign);
          } else {
            this.props.hideOverlay();
          }
        },
        items: campaigns,
      });
    }
  };

  doHandleSendEmailMessage = (response, key, message, replyTo, campaign) => {
    this.handleSendEmailMessage(response, key, message, replyTo, false, campaign?.campaignuuid, null, () => {
      if (campaign?.campaignuuid) {
        this.getTagSuggestions(Constants.COMPANY, () => {
          this.maybeTagCustomer(
            this.state.company,
            "Email with campaign link sent.\nEnter a tag to apply to this customer for follow-up.\n(Leave blank to skip)",
            this.state.suggestions[Constants.COMPANY] ?? []
          );
        });
      } else {
        this.props.hideOverlay();
      }
    });
  };

  handleSendEmailMessage = (response, contactuuid, message, replyTo, checked, campaignuuid, marketingprospectuuid, callback = null) => {
    if (response === Constants.OVERLAY_RESPONSE_OK || response === Constants.OVERLAY_RESPONSE_SELECT) {
      this.props.showOverlay(
        {
          type: Constants.OVERLAY_PROGRESS,
          text: "Sending email...",
        },
        () => {
          // Ask the web API to send the email
          const intro_paragraph = he.encode(message);
          this.handleUpdateOpenOrderItemStatuses(() => {
            let includePayLink = false;
            let includeCampaignResponse = false;
            if (checked && Helper.isOrderView(this.props.appState.currentView)) {
              includePayLink = true;
            } else if (checked && this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
              includeCampaignResponse = true;
            }
            if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
              this.postMailProspect(marketingprospectuuid, intro_paragraph, replyTo, includeCampaignResponse, callback);
            } else {
              this.postMailContact(contactuuid, intro_paragraph, replyTo, includePayLink, campaignuuid, callback);
            }
          });
        }
      );
    } else {
      this.props.hideOverlay();
    }
  };

  handleToggleCost = event => {
    this.setState(prevState => ({ showCost: !prevState.showCost }));
  };

  handleToggleInventory = event => {
    this.setState(prevState => ({ showInventory: !prevState.showInventory }));
  };

  handleSubscribe = () => {
    const selectedItem = this.props.appState.currentView === Constants.CUSTOMER ? this.state.company : this.state.plan;
    this.props.handleEditItem(Constants.BILLING, Constants.BILLINGS, selectedItem, Constants.TAB_ACTIVE);
  };

  handleArchive = () => {
    if (this.props.appState.currentView === Constants.BILLING) {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Are you sure you want to ARCHIVE this plan?",
        bullets:
          "IMPORTANT! You are about to archive a plan.\n" +
          "All subscriptions to the plan remain active.\n" +
          "You cannot update an archived plan or add new subscribers to it.\n" +
          "You can always access an archived plan's information in the system.",
        callback: this.maybeArchivePlan,
        key: this.state.plan?.plan_code,
      });
    } else if (this.props.appState.currentView === Constants.CAMPAIGN) {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Are you sure you want to ARCHIVE this campaign?",
        callback: this.maybeArchiveCampaign,
        key: this.state.campaign?.campaignuuid,
      });
    }
  };

  handleDeleteStoredPayment = card => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to DELETE this card?",
      callback: this.maybeDeleteStoredPayment,
      key: card,
    });
  };

  handleCancelMaastInvoice = invoice => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to CANCEL this invoice and set the balance owed to ZERO?",
      callback: this.maybehandleCancelMaastInvoice,
      key: invoice,
    });
  };

  handleEditStoredPayment = card => {
    this.props.showOverlay({
      type: Constants.OVERLAY_TOKENIZE,
      text: `Edit payment details for ${card.card_number}`,
      callback: this.handlePutStoredPayment,
      billing_first: card.billing_first_name,
      billing_last: card.billing_last_name,
      billing_zip: card.billing_zip,
      card_id: card.card_id,
      exp_date: card.exp_date.slice(0, 2) + "|" + card.exp_date.slice(2, 4),
      tokenize: false,
      card_number: card.card_number,
    });
  };

  handlePutStoredPayment = (response, billing_first_name, billing_last_name, billing_zip, card_id, exp_date, masked_card_number) => {
    // Make sure the form is ready to submit
    if (!this.props.isReadyToSubmitTokenize()) {
      return;
    }

    if (response === Constants.OVERLAY_RESPONSE_OK) {
      // Add the card to the vault
      const customer_id = this.state.maastCustomer.customer_id;
      this.putStoredPayment(customer_id, billing_first_name, billing_last_name, billing_zip, card_id, exp_date, masked_card_number);
    } else {
      this.props.hideOverlay();
    }
  };

  handleDeleteBillingPlan = () => {
    this.getSubscriptionsByPlanID(
      this.state.plan?.plan_id,
      true, // activeOnly
      0, // Page number
      () => {
        let text = "Are you sure you want to DELETE this plan?";
        const subCount = this.state.maastSubscriptions?.length ?? 0;
        if (subCount > 0) {
          text += "\nThere are " + subCount + " active subscriptions.\n";
        }
        this.props.showOverlay({
          type: Constants.OVERLAY_QUESTION,
          text: text,
          textClasses: "highlight",
          bullets:
            "IMPORTANT! You are about to delete a plan.\n" +
            "All subscriptions related to the plan are cancelled.\n" +
            "You cannot update a canceled plan or add new subscribers to it.\n" +
            "You can always get system information on a deleted plan.",
          callback: this.maybeDeleteBillingPlan,
          key: this.state.plan?.plan_id,
        });
      }
    );
  };

  handleCreateCampaign = () => {
    const overlay = {
      type: Constants.OVERLAY_INPUT_BOX,
      title: "Campaign Name",
      text: "Enter a name for this campaign",
      maxLength: 100,
      callback: this.maybeCreateCampaign,
      okButtonLabel: "Create",
    };
    this.props.showOverlay(overlay);
  };

  maybeCreateCampaign = (response, key, campaignName) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      this.postCampaign(campaignName, () => {
        this.props.hideOverlay();
      });
    } else {
      // Back out of the detail screen
      this.props.hideOverlay();
      this.props.followBreadcrumb();
    }
  };

  handleDelete = (event, applied_deposits_checked = false, override = false) => {
    // Block deletion of orders containing a credit card payment
    const credit_payments = this.state.order?.payments?.filter(
      payment => payment.paymenttype === Constants.CREDIT || payment.paymenttype === Constants.CREDIT_REFUND
    );
    if (credit_payments?.length > 0) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Cannot delete an order with credit card payment(s).",
      });
      return;
    }

    // Check for applied deposits before proceeding
    if (!applied_deposits_checked && this.state.order?.orderuuid) {
      this.selectSpawnedDeposits(this.state.order?.orderuuid, response => {
        if (response.status === 200 && response.body?.count > 0) {
          // error message
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "Cannot delete this order\nbecause it has payments\nthat have already been applied \nas deposit(s) on other orders.",
          });
        } else {
          this.handleDelete(null, true, override);
        }
      });
      return;
    }

    // If this order object is linked to other order objects, then warn the user they are about to break the links
    if (!override && this.state.order?.linkeditems?.length > 0) {
      let label = this.state.order?.ordertype || "order";
      if (this.state.order?.ordertype === Constants.REPAIR) {
        label = "Service Request";
      }
      let text = `Deleting this ${label} will prevent status updates to linked items.`;
      if (this.state.order?.ordertype === Constants.REPAIR) {
        text += "\nConsider marking this Service Request as 'Cancelled' instead.";
      }
      text += "\n\nAre you sure you want to continue?";
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: text,
        callback: response => {
          if (response === Constants.OVERLAY_RESPONSE_YES) {
            this.handleDelete(event, applied_deposits_checked, true);
          }
        },
        key: this.state.order?.orderuuid,
      });
      return;
    }

    // Cannot delete a paid-in-full order without being a manager
    if (Constants.CLOSED_INVOICE_ORDER_STATUSES.includes(this.state.order?.orderstatus) || this.state.order?.payments?.length > 0) {
      // Prompt for manager login
      this.props.showOverlay({
        type: Constants.OVERLAY_AUTH_PROMPT,
        prompt: "Manager authorization required\nto delete this order.",
        user: this.props.appState.username,
        callback: (response, authtoken = null) => {
          if (response === Constants.OVERLAY_RESPONSE_YES) {
            this.deleteOrder(this.state.order?.orderuuid, authtoken);
          }
        },
      });
    } else if (override) {
      // If the override flag is set to true, then we already confirmed the user wants to delete the order
      this.maybeDeleteOrder(Constants.OVERLAY_RESPONSE_YES, this.state.order?.orderuuid);
    } else {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Are you sure you want to delete?",
        callback: this.maybeDeleteOrder,
        key: this.state.order?.orderuuid,
      });
    }
  };

  handleDeleteRepairItem = (uuid, index) => {
    const repairitem = this.state.order?.repairitems[index];
    if (
      repairitem.uuid === "new" &&
      !repairitem.model &&
      !repairitem.serialnumber &&
      !repairitem.description &&
      !repairitem.technician &&
      !repairitem.family
    ) {
      this.maybeDeleteRepairItem(Constants.OVERLAY_RESPONSE_YES, {
        uuid: repairitem.uuid,
        index: index,
      });
    } else {
      this.props.showOverlay({
        type: Constants.OVERLAY_QUESTION,
        text: "Are you sure you want to delete?",
        callback: this.maybeDeleteRepairItem,
        key: { uuid: uuid, index: index },
      });
    }
  };

  handleSelectOrderStatus = statusuuid => {
    const event = { target: { id: "orderstatus", type: "text", value: statusuuid } };
    this.handleChange(event, Constants.ORDER);
    this.handleBlurOrder(event, this.state.order?.orderuuid, this.state.order?.orderstatus);
  };

  handleProspectToCustomer = () => {
    // Confirm the customer creation
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to create a new customer from this prospect?",
      callback: this.maybeProspectToCustomer,
      key: this.state.prospect?.marketingprospectuuid,
    });
  };

  maybeProspectToCustomer = (response, key) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.postContactFromProspect(this.state.prospect?.marketingprospectuuid);
    } else {
      this.props.hideOverlay();
    }
  };

  maybeTagCustomer = (company, text, suggestions, callback = null) => {
    this.setState({ downloading: false }, () => {
      this.props.showOverlay({
        type: Constants.OVERLAY_INPUT_BOX,
        text: text,
        callback: (response, key, input, company) => {
          if (callback) {
            callback();
          }
          this.maybeAssignCustomerTag(response, key, input, company);
        },
        required: false,
        extra: company,
        suggestions: suggestions,
      });
    });
  };

  maybeAssignCustomerTag = (response, key, input, company) => {
    if (response === Constants.OVERLAY_RESPONSE_OK && input.trim()) {
      const tag = {
        tagname: input,
        tagcolor: Constants.TAG_COLOR_DEFAULT,
        reftype: Constants.COMPANY,
        refuuid: company.companyuuid,
        reflabel: company?.contacts[0].firstname + " " + company?.contacts[0].lastname,
      };
      // Show progress overlay
      this.props.showOverlay(
        {
          type: Constants.OVERLAY_PROGRESS,
          text: "Tagging customer...",
        },
        () => {
          this.postTag(tag, -1, () => {
            // TODO: Make the tag show on the screen
            this.props.hideOverlay();
          });
        }
      );
    } else {
      this.props.hideOverlay();
    }
  };

  handleSelectProspectStatus = statusuuid => {
    const event = {
      target: {
        id: "prospectstatus",
        name: "prospectstatus",
        type: "text",
        value: statusuuid,
        getAttribute: () => {
          return null;
        },
        removeAttribute: () => {},
      },
    };
    this.handleChange(event, Constants.CONTACT);
    this.handleBlur(event, this.state.prospect?.marketingprospectuuid, this.state.prospect?.prospectstatus);
  };

  handleChangeOrderDate = event => {
    this.handleChange(event, Constants.ORDER);
    this.handleBlurOrder(event, this.state.order?.orderuuid, this.state.order?.creationdatetime);
  };

  handleEditOrderDate = event => {
    if (Helper.authorize(Constants.ACTION_EDIT_ORDER_DATE, this.props.appState.usertype)) {
      this.setState({ showEditOrderDate: true });
    }
  };

  handleChangeDueDate = event => {
    this.handleChange(event, Constants.ORDER);
    this.handleBlurOrder(event, this.state.order?.orderuuid, this.state.order?.duedatetime);
  };

  handleEditDueDate = event => {
    this.setState({ showEditDueDate: true });
  };

  handleMaybeShowSelectStatus = event => {
    if (
      (Helper.authorize(Constants.ACTION_UPDATE_ORDER_STATUS, this.props.appState.usertype) ||
        this.props.appState.currentView === Constants.REPAIR ||
        Constants.ONLINE_ORDER_STATUSES.includes(this.state.order?.orderstatus)) &&
      this.getPossibleOrderStatuses().length > 0 &&
      !this.state.isNew
    ) {
      this.setState({ showSelectStatus: true });
    }
    // Show the select prospect status dialog if on the prospect view
    else if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      this.setState({ showSelectStatus: true });
    }
  };

  handleAfterAddPhoto = (result, photo) => {
    if (result) {
      this.setState(prevState => ({
        product: { ...prevState.product, photos: [...prevState.product.photos, photo] },
      }));
    }
  };

  handleAfterAddPhotoBinary = (result, photo) => {
    if (result) {
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          photos: [
            ...prevState.product.photos,
            { photouuid: photo.uploaduuid, photourl: photo.uploadurl, name: photo.name, creationdatetime: photo.creationdatetime },
          ],
        },
      }));
    }
  };

  // Maast only uses POST for custom fields
  handleBlurCustomField = (event, companyuuid, subscriptionid, customfieldlabeluuid) => {
    const prev = event.target.getAttribute(Constants.ATTR_DATA_VALUE);
    if (prev === event.target.value) {
      return;
    }
    event.target.removeAttribute(Constants.ATTR_DATA_VALUE);

    this.putCustomField(companyuuid, subscriptionid, customfieldlabeluuid, event.target.value);
  };

  handleChangeCustomField = (event, subscription_id, customfieldlabeluuid) => {
    // Look for the subscription
    const subscription = this.state.maastSubscriptions.find(sub => sub.subscription_id === subscription_id);
    // If we find it, update the custom field
    if (subscription) {
      let custom_fields = subscription.custom_fields || [];
      // Find the custom field
      let custom_field = custom_fields.find(field => field.customfieldlabeluuid === customfieldlabeluuid);
      if (custom_field) {
        custom_field.customfieldvalue = event.target.value;
        this.setState(prevState => ({
          maastSubscriptions: prevState.maastSubscriptions.map(sub => {
            if (sub.subscription_id === subscription_id) {
              sub.custom_fields = custom_fields;
            }
            return sub;
          }),
        }));
      }
    }
  };

  handleChangeTag = (event, index, fieldname) => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      this.setState(prevState => ({
        order: {
          ...prevState.order,
          company: {
            ...prevState.order.company,
            tags: prevState.order.company?.tags?.map((tag, i) => {
              if (i === index) {
                tag[fieldname] = event.target.value;
              }
              return tag;
            }),
          },
        },
      }));
    } else if (this.props.appState.currentView === Constants.PRODUCT) {
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          tags: prevState.product?.tags?.map((tag, i) => {
            if (i === index) {
              tag[fieldname] = event.target.value;
            }
            return tag;
          }),
        },
      }));
    } else {
      this.setState(prevState => ({
        company: {
          ...prevState.company,
          tags: prevState.company?.tags?.map((tag, i) => {
            if (i === index) {
              tag[fieldname] = event.target.value;
            }
            return tag;
          }),
        },
      }));
    }
  };

  handleEditTag = index => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      this.setState(prevState => ({
        order: {
          ...prevState.order,
          company: {
            ...prevState.order.company,
            tags: prevState.order.company?.tags?.map((tag, i) => {
              if (i === index) {
                tag.isEditing = true;
                tag.prevTagName = tag.tagname.trim();
                tag.prevTagColor = tag.tagcolor;
              }
              return tag;
            }),
          },
        },
      }));
    } else if (this.props.appState.currentView === Constants.PRODUCT) {
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          tags: prevState.product?.tags?.map((tag, i) => {
            if (i === index) {
              tag.isEditing = true;
              tag.prevTagName = tag.tagname.trim();
              tag.prevTagColor = tag.tagcolor;
            }
            return tag;
          }),
        },
      }));
    } else {
      this.setState(prevState => ({
        company: {
          ...prevState.company,
          tags: prevState.company?.tags?.map((tag, i) => {
            if (i === index) {
              tag.isEditing = true;
              tag.prevTagName = tag.tagname.trim();
              tag.prevTagColor = tag.tagcolor;
            }
            return tag;
          }),
        },
      }));
    }
  };

  handleDeleteTag = taguuid => {
    this.deleteTag(taguuid);
  };

  handleSaveTag = (tag, index) => {
    if (!tag.tagname) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Tag name is required.",
      });
      return;
    }
    // Trim the tag name
    tag.tagname = tag.tagname.trim();
    if (tag.isNew) {
      this.postTag(tag, index);
    } else {
      let reftype = null;
      if (Helper.isOrderView(this.props.appState.currentView)) {
        reftype = Constants.ORDER;
        this.setState(prevState => ({
          order: {
            ...prevState.order,
            company: {
              ...prevState.order.company,
              tags: prevState.order.company.tags.map((tag, i) => {
                if (i === index) {
                  tag.isEditing = false;
                }
                return tag;
              }),
            },
          },
        }));
      } else if (this.props.appState.currentView === Constants.PRODUCT) {
        reftype = Constants.PRODUCT;
        this.setState(prevState => ({
          product: {
            ...prevState.product,
            tags: prevState.product.tags.map((tag, i) => {
              if (i === index) {
                tag.isEditing = false;
              }
              return tag;
            }),
          },
        }));
      } else {
        reftype = Constants.COMPANY;
        this.setState(prevState => ({
          company: {
            ...prevState.company,
            tags: prevState.company.tags.map((tag, i) => {
              if (i === index) {
                tag.isEditing = false;
              }
              return tag;
            }),
          },
        }));
      }
      this.putTag(tag, index, reftype);
    }
  };

  handleCancelTag = index => {
    let source = this.state.order?.company?.tags || this.state.product?.tags || this.state.company?.tags || [];
    if (source.length > index) {
      const tag = source[index];
      if (tag.isNew) {
        // Remove the tag from the list if it's new
        source.splice(index, 1);
      } else {
        // Turn off editing if it's existing
        source[index].isEditing = false;
      }
    }
    if (Helper.isOrderView(this.props.appState.currentView)) {
      this.setState(prevState => ({
        order: {
          ...prevState.order,
          company: {
            ...prevState.order.company,
            tags: source,
          },
        },
      }));
    } else if (this.props.appState.currentView === Constants.PRODUCT) {
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          tags: source,
        },
      }));
    } else {
      this.setState(prevState => ({
        company: {
          ...prevState.company,
          tags: source,
        },
      }));
    }
  };

  handleDeleteOrderTag = taguuid => {
    this.deleteOrderTag(taguuid);
  };

  handleEditOrderTag = index => {
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        tags: prevState.order?.tags?.map((tag, i) => {
          if (i === index) {
            tag.isEditing = true;
            tag.prevTagName = tag.tagname.trim();
            tag.prevTagColor = tag.tagcolor;
          }
          return tag;
        }),
      },
    }));
  };

  handleSaveOrderTag = (tag, index) => {
    if (!tag.tagname) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Tag name is required.",
      });
      return;
    }
    // Trim the tag name
    tag.tagname = tag.tagname.trim();
    if (tag.isNew) {
      this.postOrderTag(tag, index);
    } else {
      let reftype = null;
      reftype = this.state.order.ordertype;
      this.setState(prevState => ({
        order: {
          ...prevState.order,
          tags: prevState.order.company.tags.map((tag, i) => {
            if (i === index) {
              tag.isEditing = false;
            }
            return tag;
          }),
        },
      }));
      this.putOrderTag(tag, index, reftype);
    }
  };

  handleChangeOrderTag = (event, index, fieldname) => {
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        tags: prevState.order.tags?.map((tag, i) => {
          if (i === index) {
            tag[fieldname] = event.target.value;
          }
          return tag;
        }),
      },
    }));
  };

  handleCancelOrderTag = index => {
    let source = this.state.order?.tags || [];
    if (source.length > index) {
      const tag = source[index];
      if (tag.isNew) {
        // Remove the tag from the list if it's new
        source.splice(index, 1);
      } else {
        // Turn off editing if it's existing
        source[index].isEditing = false;
      }
    }
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        tags: source,
      },
    }));
  };

  handleChangeReverbAttribute = (type, event) => {
    if (type === Constants.REVERB_CHANGE_CONDITION) {
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          reverbListing: {
            ...prevState.product?.reverbListing,
            condition: {
              ...prevState.product?.reverbListing?.condition,
              uuid: event.target.value,
            },
          },
        },
      }));
    } else if (type === Constants.REVERB_CHANGE_SHIPPING) {
      const profileID = event.target.value ? parseInt(event.target.value) : "";
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          reverbListing: {
            ...prevState.product?.reverbListing,
            shipping_profile_id: profileID,
          },
        },
      }));
    } else if (type === Constants.REVERB_CHANGE_CATEGORY) {
      let categories = this.state.product?.reverbListing?.categories ?? [];
      if (categories.length === 0) {
        categories.push({ uuid: event.target.value });
      } else {
        categories[0].uuid = event.target.value;
        // Remove any subcategories
        categories = categories.slice(0, 1);
      }
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          reverbListing: {
            ...prevState.product?.reverbListing,
            categories: categories,
          },
        },
      }));
    } else if (type === Constants.REVERB_CHANGE_SUBCAT1) {
      let categories = this.state.product?.reverbListing?.categories ?? [];
      if (categories.length === 1) {
        categories.push({ uuid: event.target.value });
      } else {
        categories[1].uuid = event.target.value;
      }
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          reverbListing: {
            ...prevState.product?.reverbListing,
            categories: categories,
          },
        },
      }));
    } else if (type === Constants.REVERB_CHANGE_SUBCAT2) {
      let categories = this.state.product?.reverbListing?.categories ?? [];
      if (categories.length === 2) {
        categories.push({ uuid: event.target.value });
      } else {
        categories[2].uuid = event.target.value;
      }
      this.setState(prevState => ({
        product: {
          ...prevState.product,
          reverbListing: {
            ...prevState.product?.reverbListing,
            categories: categories,
          },
        },
      }));
    }
  };

  maybePublishOrPutToReverb = product => {
    // Make sure we have a product

    if (!product) {
      return;
    }
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to publish this product to Reverb?",
      callback: reponse => {
        if (reponse === Constants.OVERLAY_RESPONSE_YES) {
          this.handlePublishOrPutToReverb(product);
        }
      },
    });
  };

  handleDeletePaymentRequestLink = requestuuid => {
    this.props.showOverlay(
      {
        type: Constants.OVERLAY_PROGRESS,
        text: "Deleting request link...",
      },
      () => {
        this.deletePaymentRequestLink(requestuuid);
      }
    );
  };

  handleRetrieveResult = (status, authuuid) => {
    if (status === Constants.AUTH_PENDING) {
      this.props.showOverlay({
        type: Constants.OVERLAY_PROGRESS,
        text: "Retrieving result...",
      });
      this.getPendingAuthorizationStatuses(authuuid);
    }
  };

  handleRequestPaymentMethod = () => {
    // Make sure at least one contact has an email address
    if (!this.state.company?.contacts?.some(contact => contact.email)) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Email address is required to vault a payment method.",
      });
      return;
    }

    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to request a payment method from this customer?\nIf so, a link will be created for you to text or email the customer.\nThe customer will follow the link in order to provide their payment information.",
      callback: this.maybeHandleRequestPaymentMethod,
    });
  };

  maybeHandleRequestPaymentMethod = response => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      // If there are multiple contacts, then we need to select one
      if (this.state.company.contacts.length > 1) {
        const items = this.state.company.contacts.map(contact => {
          return {
            id: contact.contactuuid,
            text: contact.firstname + " " + contact.lastname,
            hasEmail: contact.email ? true : false,
          };
        });
        this.props.showOverlay({
          type: Constants.OVERLAY_PICKER,
          text: "Select a contact to associate with this request",
          callback: this.maybePostCompanyRequestPaymentMethod,
          items: items,
        });
      } else {
        // Send request to create request to server
        this.postCompanyRequestPaymentMethod(this.state.company.companyuuid, this.state.company.contacts[0].contactuuid);
      }
    }
  };

  maybePostCompanyRequestPaymentMethod = (response, contact) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      // Send request to create request to server
      if (!contact.hasEmail) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Email address is required to vault a payment method.",
        });
        return;
      }
      this.postCompanyRequestPaymentMethod(this.state.company.companyuuid, contact.id);
    }
  };

  handleAddPaymentMethod = () => {
    const validCompany =
      this.state.company?.companyuuid ||
      (this.state.company?.contacts?.length > 0 && this.state.company.contacts[0].firstname && this.state.company.contacts[0].lastname);
    if (!validCompany) {
      return;
    }
    this.props.showOverlay({
      type: Constants.OVERLAY_TOKENIZE,
      text: "Enter payment details",
      callback: this.handlePostStoredPayment,
      tokenize: true,
    });
  };

  handlePostStoredPayment = (response, billing_first_name, billing_last_name, billing_zip, card_id, exp_date, card_number) => {
    if (response === Constants.OVERLAY_RESPONSE_OK) {
      // Check for customer not being vaulted already
      if (!this.state.maastCustomer?.vaulted) {
        // Add the payment method to state
        let billing_cards = this.state.maastCustomer?.billing_cards || [];
        let primary = false;
        if (billing_cards.length === 0) {
          primary = true;
        }
        billing_cards.push({
          billing_first_name: billing_first_name,
          billing_last_name: billing_last_name,
          billing_zip: billing_zip,
          card_id: card_id,
          exp_date: exp_date,
          card_number: card_number,
          primary: primary,
        });
        this.setState(
          prevState => ({
            downloading: false,
            maastCustomer: {
              ...prevState.maastCustomer,
              billing_cards: billing_cards,
            },
            maastSubscriptions:
              prevState.maastSubscriptions?.map(subscription => {
                if (!subscription.card_id) {
                  subscription.card_id = card_id;
                }
                return subscription;
              }) ?? [],
          }),
          () => {
            // If this is on the customer screen, then we need to vault the customer
            if (this.props.appState.currentView === Constants.CUSTOMER) {
              this.postMaastCustomer(this.state.company.companyuuid, this.state.company.contacts[0], this.state.maastCustomer.billing_cards);
            } else {
              this.props.hideOverlay();
            }
          }
        );
      } else {
        // Add the card to the vault
        const customer_id = this.state.maastCustomer.customer_id;
        this.postStoredPayment(customer_id, billing_first_name, billing_last_name, billing_zip, card_id, exp_date, card_number);
      }
    }
  };

  handleZeroInventoryCount = response => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      // Set the product inventory to zero
      this.putProduct(null, "inventory", 0);
    } else {
      this.props.hideOverlay();
    }
  };

  handlePublishOrPutToReverb = product => {
    if (this.state.product?.reverbListing?.id) {
      // This SKU already exists on Reverb. Update it.
      this.putReverbListing(product);
    } else {
      // This SKU does not exist on Reverb. Create it.
      this.postReverbListing(product);
    }
  };

  handleAssociateSelection = (event, orderitemuuid) => {
    event.stopPropagation();
    this.getAssociates(associates => {
      const items = associates.map(associate => {
        return {
          id: associate.fullname,
          text: associate.fullname,
        };
      });
      this.props.showOverlay({
        type: Constants.OVERLAY_PICKER,
        text: "Select an associate",
        callback: this.handlePickAssociate,
        items: items,
        event: { orderitemuuid: orderitemuuid },
      });
    });
  };

  handlePickAssociate = (response, associate, event) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      // Change the associate on the order item in state
      // If not a new order, add a flag to the order item to indicate the associate is being changed
      this.setState(
        prevState => ({
          order: {
            ...prevState.order,
            orderitems: prevState.order?.orderitems.map(item => {
              if (item.uuid === event.orderitemuuid) {
                item.prevAssociate = item.associate;
                item.associate = associate.id;
                if (!this.state.isNew) {
                  item.associateUpdating = true;
                }
              }
              return item;
            }),
          },
        }),
        () => {
          // If not a new order, update the order item in the database
          // New orders will send the associate in the POST
          if (!this.state.isNew) {
            this.putOrderItem(this.state.order, event.orderitemuuid, "associate", associate.id, () => {
              // Refresh the order notes
              if (this.refreshFoldersCallback) {
                this.refreshFoldersCallback();
              }
            });
          }
        }
      );
    }
  };

  maybeDeleteOrder = (response, orderuuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteOrder(orderuuid);
    } else {
      this.setState({ downloading: false });
    }
  };

  maybeArchiveCampaign = response => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.putCampaign("campaignstatus", Constants.CAMPAIGN_STATUS_ARCHIVED);
    } else {
      this.setState({ downloading: false });
    }
  };

  maybeArchivePlan = (response, plan_code) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.archivePlan(plan_code);
    } else {
      this.setState({ downloading: false });
    }
  };

  maybePublishCampaign = response => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.putCampaign("campaignstatus", Constants.CAMPAIGN_STATUS_PUBLISHED);
    } else {
      this.setState({ downloading: false });
    }
  };

  maybeUnpublishCampaign = response => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.putCampaign("campaignstatus", Constants.CAMPAIGN_STATUS_DRAFT);
    } else {
      this.setState({ downloading: false });
    }
  };

  maybeDeleteBillingPlan = (response, plan_id) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteBillingPlan(plan_id);
    } else {
      this.setState({ downloading: false });
    }
  };

  maybeDeleteStoredPayment = (response, card) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.props.showOverlay(
        {
          type: Constants.OVERLAY_PROGRESS,
          text: "Deleting payment method...",
        },
        () => {
          this.deleteStoredPayment(card);
        }
      );
    } else {
      this.setState({ downloading: false });
    }
  };

  maybehandleCancelMaastInvoice = (response, invoice) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteMaastInvoice(invoice);
    } else {
      this.setState({ downloading: false });
    }
  };

  maybeDeleteRepairItem = (response, uuid_and_index) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      // If this is a new/unsaved repair item, then throw it away
      if (uuid_and_index.uuid === "new") {
        let order = this.state.order;
        order.repairitems = this.state.order?.repairitems.filter((item, index) => {
          return index !== uuid_and_index.index;
        });
        order = Helper.maybeAddDefaultRepairItem(order);
        this.setState(prevState => ({
          order: {
            ...prevState.order,
            repairitems: order.repairitems,
          },
        }));
      } else {
        this.deleteRepairItem(uuid_and_index.uuid);
      }
    }
  };

  maybeDeleteOrderItem = (response, uuid, prev) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.setState(
        prevState => ({
          order: Helper.newOrderRecalculate({
            ...prevState.order,
            orderitems: this.state.order?.orderitems.filter(item => item.uuid !== uuid),
          }),
          // Remove the deleted item from the list of selected items
          selectedOrderItems: prevState.selectedOrderItems.filter(item => item.uuid !== uuid),
        }),
        () => {
          if (this.state.order?.orderuuid) {
            this.deleteOrderItem(uuid);
          }
        }
      );
    } else {
      if (prev) {
        const event = { target: { id: "quantity", type: "text", value: prev } };
        this.handleChangeOrderItem(event, uuid);
      }
    }
  };

  maybeDeleteContact = (response, uuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES && uuid === "new") {
      // If this is a new contact, then throw it away
      this.setState(prevState => ({
        company: {
          ...prevState.company,
          contacts: this.state.company?.contacts.filter(contact => contact.uuid !== uuid),
        },
      }));
    } else if (response === Constants.OVERLAY_RESPONSE_YES) {
      // If billing is enabled, make sure the contact does not have any active subscriptions
      if (
        this.state.company?.companyuuid &&
        this.props.appState.features?.includes(Constants.FEATURE_BILLING) &&
        this.props.appState.maast.merchant_id
      ) {
        this.getSubscriptions(this.state.company?.companyuuid, true, 0, () => {
          if (
            this.state.maastSubscriptions?.length > 0 &&
            this.state.maastSubscriptions.find(subscription => {
              return subscription?.customer?.reference_id === uuid;
            })
          ) {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Cannot delete customer with active subscriptions.",
            });
          } else {
            this.deleteContact(uuid);
          }
        });
      } else {
        this.deleteContact(uuid);
      }
    }
  };

  maybeDeleteShipAddress = (response, uuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteShipAddress(uuid);
    }
  };

  maybeUpdateProduct = (response, responseObject, callback = null) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      // TODO: If cost is the field being updated and the product has multiple suppliers, ask which supplier cost to update
      const orderitem = responseObject.orderitem;

      const productuuid = orderitem.productuuid;
      const fieldname = responseObject.fieldname;
      const value = responseObject.value;
      const fieldType = fieldname === "productname" ? "text" : "float";
      this.putProductByUUID(productuuid, fieldType, fieldname, value, callback);
    } else {
      if (callback) {
        callback();
      }
    }
  };

  maybeMarkPaidInFull = (response, uuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.putOrder(uuid, "orderstatus", Constants.ORDER_STATUS_PAID_IN_FULL, () => {
        // this.props.followBreadcrumb();
      });
    }
  };

  maybeZeroInventoryCount = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Set inventory count to zero?",
      callback: this.handleZeroInventoryCount,
    });
  };

  maybeUpdateLocalStorageContact = contact => {
    if (contact) {
      const walkinCustomer = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_WALKIN_CUSTOMER) ?? "{}");
      const stockOrderCustomer = JSON.parse(localStorage.getItem(Constants.LOCAL_STORAGE_STOCK_ORDER_CUSTOMER) ?? "{}");
      if (walkinCustomer?.contacts?.length > 0 && walkinCustomer.contacts[0].contactuuid === contact.contactuuid) {
        walkinCustomer.contacts[0] = Helper.deepCopy(contact);
        walkinCustomer.contacts[0].editmode = false;
        localStorage.setItem(Constants.LOCAL_STORAGE_WALKIN_CUSTOMER, JSON.stringify(walkinCustomer));
      } else if (stockOrderCustomer?.contacts?.length > 0 && stockOrderCustomer.contacts[0].contactuuid === contact.contactuuid) {
        stockOrderCustomer.contacts[0] = Helper.deepCopy(contact);
        stockOrderCustomer.contacts[0].editmode = false;
        localStorage.setItem(Constants.LOCAL_STORAGE_STOCK_ORDER_CUSTOMER, JSON.stringify(stockOrderCustomer));
      }
    }
  };

  setRefreshFoldersCallback = callback => {
    this.refreshFoldersCallback = callback;
  };

  // Updates any "open" order items to "ordered" or "sent",
  // then invokes the callback (currently to send an email)
  handleUpdateOpenOrderItemStatuses = callback => {
    if (Helper.isOrderView(this.props.appState.currentView)) {
      // Build an array of orderitemuuids for open/created order items
      const orderitemuuids = this.state.order?.orderitems
        .filter(item => item.lineitemstatus === Constants.ORDER_STATUS_OPEN || item.lineitemstatus === Constants.ORDER_STATUS_PURCHASE_CREATED)
        .map(item => item.orderitemuuid);
      if (this.state.order?.ordertype === Constants.PURCHASE && orderitemuuids.length > 0) {
        this.putOrderItemStatuses(orderitemuuids, Constants.ORDER_STATUS_ORDERED, () => {
          callback();
        });
        // TODO: Switch this logic to check order item statuses before sending PUT
      } else if (this.state.order?.ordertype === Constants.QUOTE && orderitemuuids.length > 0) {
        this.putOrderItemStatuses(orderitemuuids, Constants.ORDER_STATUS_SENT, () => {
          callback();
        });
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  revertToPreviousValue(uuid, fieldname, prev, message = null) {
    const orderitems = this.state.order?.orderitems.map(item => {
      if (item.uuid === uuid) {
        item[fieldname] = prev;
      }
      return item;
    });
    this.setState(
      prevState => ({
        order: {
          ...Helper.newOrderRecalculate({
            ...prevState.order,
            orderitems: orderitems,
          }),
        },
      }),
      () => {
        if (message) {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: message,
          });
        }
      }
    );
  }

  getPossibleProspectStatuses() {
    let statuses = [];
    if (Constants.PROSPECT_STATUS_CANCELLED === this.state.prospect?.prospectstatus) {
      statuses.push({ uuid: Constants.PROSPECT_STATUS_PENDING, statusname: "Pending" });
    }
    if (Constants.PROSPECT_STATUS_NEW === this.state.prospect?.prospectstatus) {
      statuses.push({ uuid: Constants.PROSPECT_STATUS_CANCELLED, statusname: "Cancelled" });
      statuses.push({ uuid: Constants.PROSPECT_STATUS_PENDING, statusname: "Pending" });
    }
    if (Constants.PROSPECT_STATUS_PENDING === this.state.prospect?.prospectstatus) {
      statuses.push({ uuid: Constants.PROSPECT_STATUS_CANCELLED, statusname: "Cancelled" });
    }
    return statuses;
  }

  getPossibleOrderStatuses() {
    let statuses = [];
    // Can mark unshipped/partially shipped as shipped
    if (
      [Constants.ORDER_STATUS_ONLINE_UNSHIPPED, Constants.ORDER_STATUS_ONLINE_PARTIALLY_SHIPPED, Constants.ORDER_STATUS_ONLINE_PICKED_UP].includes(
        this.state.order?.orderstatus
      )
    ) {
      statuses.push({ uuid: Constants.ORDER_STATUS_ONLINE_SHIPPED, statusname: "Shipped" });
    }
    // Can marked shipped/partially as unshipped
    if (
      [Constants.ORDER_STATUS_ONLINE_SHIPPED, Constants.ORDER_STATUS_ONLINE_PARTIALLY_SHIPPED, Constants.ORDER_STATUS_ONLINE_PICKED_UP].includes(
        this.state.order?.orderstatus
      )
    ) {
      statuses.push({ uuid: Constants.ORDER_STATUS_ONLINE_UNSHIPPED, statusname: "Not Shipped" });
    }
    // Can marke unshipped/shipped as partially shipped
    if (
      [Constants.ORDER_STATUS_ONLINE_UNSHIPPED, Constants.ORDER_STATUS_ONLINE_SHIPPED, Constants.ORDER_STATUS_ONLINE_PICKED_UP].includes(
        this.state.order?.orderstatus
      )
    ) {
      statuses.push({ uuid: Constants.ORDER_STATUS_ONLINE_PARTIALLY_SHIPPED, statusname: "Partially Shipped" });
    }
    // If this is an external order that has not been picked up, then it can be marked as picked up
    if (
      Constants.ONLINE_ORDER_STATUSES.includes(this.state.order?.orderstatus) &&
      this.state.order?.orderstatus !== Constants.ORDER_STATUS_ONLINE_PICKED_UP
    ) {
      statuses.push({ uuid: Constants.ORDER_STATUS_ONLINE_PICKED_UP, statusname: "Picked Up" });
    }
    // Can close an open customer order
    if (this.props.appState.currentView === Constants.ORDER && this.state.order?.orderstatus === Constants.ORDER_STATUS_OPEN) {
      statuses.push({ uuid: Constants.ORDER_STATUS_CONTACTED, statusname: "Contacted" });
      statuses.push({ uuid: Constants.ORDER_STATUS_ORDERED, statusname: "Ordered" });
    }

    if (this.props.appState.currentView === Constants.ORDER && this.state.order?.orderstatus === Constants.ORDER_STATUS_CONTACTED) {
      statuses.push({ uuid: Constants.ORDER_STATUS_OPEN, statusname: "Open" });
      statuses.push({ uuid: Constants.ORDER_STATUS_ORDERED, statusname: "Ordered" });
    }

    if (this.props.appState.currentView === Constants.ORDER && this.state.order?.orderstatus === Constants.ORDER_STATUS_ORDERED) {
      statuses.push({ uuid: Constants.ORDER_STATUS_OPEN, statusname: "Open" });
      statuses.push({ uuid: Constants.ORDER_STATUS_CONTACTED, statusname: "Contacted" });
    }
    // Can reopen a SALE invoice if it doesn't contain a gift card
    if (
      this.state.order?.orderstatus !== Constants.ORDER_STATUS_OPEN &&
      this.props.appState.currentView === Constants.INVOICE &&
      !this.isReturn() &&
      !this.containsGiftCard() &&
      !Constants.ONLINE_ORDER_STATUSES.includes(this.state.order?.orderstatus)
    ) {
      statuses.push({ uuid: Constants.ORDER_STATUS_OPEN, statusname: "Open" });
    }

    // Can reopen a REPAIR order
    if (this.state.order?.orderstatus !== Constants.ORDER_STATUS_OPEN && this.props.appState.currentView === Constants.REPAIR) {
      statuses.push({ uuid: Constants.ORDER_STATUS_OPEN, statusname: "Open" });
    }
    if (
      this.state.order?.ordertype === Constants.INVOICE &&
      !this.isReturn() &&
      this.state.order?.orderstatus !== Constants.ORDER_STATUS_PAID_IN_FULL &&
      this.state.order?.balancedue <= 0 &&
      !Constants.ONLINE_ORDER_STATUSES.includes(this.state.order?.orderstatus)
    ) {
      statuses.push({ uuid: Constants.ORDER_STATUS_PAID_IN_FULL, statusname: "Paid" });
    }
    if (this.state.order?.ordertype === Constants.REPAIR) {
      if (this.state.order?.orderstatus !== Constants.ORDER_STATUS_DIAGNOSED) {
        statuses.push({ uuid: Constants.ORDER_STATUS_DIAGNOSED, statusname: "Diagnosed" });
      }
      if (this.state.order?.orderstatus !== Constants.ORDER_STATUS_PARTS_ORDERED) {
        statuses.push({ uuid: Constants.ORDER_STATUS_PARTS_ORDERED, statusname: "Parts Ordered" });
      }
      if (this.state.order?.orderstatus !== Constants.ORDER_STATUS_CONTACTED) {
        statuses.push({ uuid: Constants.ORDER_STATUS_CONTACTED, statusname: "Contacted" });
      }
      if (this.state.order?.orderstatus !== Constants.ORDER_STATUS_ON_BENCH) {
        statuses.push({ uuid: Constants.ORDER_STATUS_ON_BENCH, statusname: "On Bench" });
      }
      if (this.state.order?.orderstatus !== Constants.ORDER_STATUS_ON_HOLD) {
        statuses.push({ uuid: Constants.ORDER_STATUS_ON_HOLD, statusname: "On Hold" });
      }
      if (this.state.order?.orderstatus !== Constants.ORDER_STATUS_CANCELLED) {
        statuses.push({ uuid: Constants.ORDER_STATUS_CANCELLED, statusname: "Cancelled" });
      }
      if (this.state.order?.orderstatus !== Constants.ORDER_STATUS_CANCELLED_RETURN) {
        statuses.push({
          uuid: Constants.ORDER_STATUS_CANCELLED_RETURN,
          statusname: "Cancelled - Return",
        });
      }
      // Can mark a non-invoiced REPAIR order as Invoiced
      if (this.state.order?.orderstatus !== Constants.ORDER_STATUS_INVOICED) {
        statuses.push({ uuid: Constants.ORDER_STATUS_INVOICED, statusname: "Invoiced" });
      }
    }
    return statuses;
  }

  toggleCollapsed = (event, uuid) => {
    event.preventDefault();
    const expandedListItems = this.state.expandedListItems;
    if (!Helper.inList(expandedListItems, uuid)) {
      expandedListItems.push(uuid);
    } else {
      expandedListItems.pop(uuid);
    }
    this.setState({ expandedListItems: expandedListItems });
  };

  isMaastOnlyCustomer = () => {
    return !this.state.isNew && this.state.company?.contacts?.[0]?.uuid === "new" && this.state.maastCustomer;
  };

  isProtectedCustomer = () => {
    // Return false if this is the billing plans view
    if (this.props.appState.currentView === Constants.BILLING && this.props.filtertype?.tab === Constants.TAB_PLANS) {
      return false;
    }
    // If a billing customer is not found in the ClerkHound database,
    // but is found in the Maast database, then mark it as a protected customer
    // to avoid editing something that's not editable.
    // This is a "temporary fix" until the user gets the ClerkHound database in sync with the Maast database.
    if (this.props.appState.currentView === Constants.BILLING && !this.state.isNew && this.state.company.contacts[0].contactuuid === "new") {
      return true;
    }
    if (this.state.displaySettings) {
      let contactuuid;
      // Get the contact UUID from the order
      if (Helper.isOrderView(this.props.appState.currentView)) {
        const contacts = this.state.order?.company?.contacts;
        if (contacts && contacts.length > 0) {
          contactuuid = contacts[0].contactuuid;
        }
        // Get the contact UUID from the company
      } else if (this.props.appState.currentView === Constants.CUSTOMER || this.props.appState.currentView === Constants.SUPPLIER) {
        const contacts = this.state.company?.contacts;
        if (contacts && contacts.length > 0) {
          contactuuid = contacts[0].contactuuid;
        }
      }
      // Compare the contact UUID to the protected customer UUIDs
      if (
        (contactuuid && contactuuid === this.state.clientSettings[Constants.SETTINGS_WALKIN_CUSTOMER]) ||
        contactuuid === this.state.clientSettings[Constants.SETTINGS_STOCK_ORDER_CUSTOMER]
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  isRequiredFieldContacts = id => {
    return Helper.inList(["firstname", "lastname"], id);
  };

  // Products require a product name, sell price, and on hand
  isRequiredFieldProducts = (view, id) => {
    if (view === Constants.PRODUCT) {
      return Helper.inList(["productname", "sellprice"], id);
    } else if (view === Constants.PRODUCT) {
      return Helper.inList(["productname", "cost"], id);
    }
  };

  isReadyToSubmit = () => {
    // If there are inflight requests, then don't allow submission
    if (this.state.inflightRequests > 0) {
      return false;
    }

    // Check if a return invoice is ready to submit
    if (this.isReturn()) {
      // Check for at least one quantity greater than zero
      if (this.state.order?.orderitems.filter(item => numeral(item.quantity).value() > 0).length === 0) {
        return false;
      }
      // Check to make sure we don't exceed qty available to return
      let failed = false;
      this.state.order?.orderitems.forEach(item => {
        if (numeral(item.quantity).value() > numeral(item.quantitySold).subtract(numeral(item.quantityreceived).value()).value()) {
          failed = true;
        }
      });
      if (failed) {
        return false;
      }
    }
    // TODO: Add other checks before returning true
    // Check if creating a new product (in-line) in an order/invoice/etc.
    const creatingproduct = this.state.product && !this.state.product.productuuid;
    const pickedacontact = this.state.order?.company && this.state.order?.company.companyuuid;
    const isvalidnewcontact =
      (this.props.appState.currentView !== Constants.PURCHASE &&
        this.state.order?.company &&
        this.state.order?.company.contacts &&
        this.state.order?.company.contacts.length > 0 &&
        this.state.order?.company.contacts[0].firstname &&
        this.state.order?.company.contacts[0].lastname) ||
      (this.props.appState.currentView === Constants.PURCHASE &&
        this.state.order?.company &&
        this.state.order?.company.companyname &&
        this.state.order?.company.contacts &&
        this.state.order?.company.contacts.length > 0 &&
        (this.state.order?.company.contacts[0].firstname ||
          this.state.order?.company.contacts[0].lastname ||
          this.state.order?.company.contacts[0].email ||
          this.state.order?.company.contacts[0].mobilephone ||
          this.state.order?.company.contacts[0].otherphone ||
          this.state.order?.company.contacts[0].website ||
          this.state.order?.company.contacts[0].title ||
          this.state.order?.company.contacts[0].address1 ||
          this.state.order?.company.contacts[0].address2 ||
          this.state.order?.company.contacts[0].city ||
          this.state.order?.company.contacts[0].state ||
          this.state.order?.company.contacts[0].postalcode));
    const createdacontact =
      this.state.order?.company && !this.state.order?.company.companyuuid && this.state.order?.company.contacts.length > 0 && isvalidnewcontact;
    const hasorderitems = this.state.order?.orderitems.length > 0;
    // Repairs must have a repair item with model & description
    if (this.props.appState.currentView === Constants.REPAIR) {
      const incomplete = this.state.order?.repairitems.filter(item => !item.model || !item.description);
      if (incomplete.length > 0) {
        return false;
      }
    }
    return (pickedacontact || createdacontact) && hasorderitems && !creatingproduct;
  };

  isReadyToSubmitNewProduct = () => {
    if (this.props.appState.currentView === Constants.PURCHASE) {
      return this.state.product && this.state.product.productname && this.state.product.cost;
    } else {
      // Product screen
      return this.state.product && this.state.product.productname && this.state.product.sellprice;
    }
  };

  isReturn = order => {
    return Helper.isReturn(order || this.state.order);
  };

  isTradeIn = order => {
    return Helper.isTradeIn(order || this.state.order);
  };

  containsTradeIn = order => {
    return Helper.containsTradeIn(order || this.state.order);
  };

  containsGiftCard = order => {
    return Helper.containsGiftCard(order || this.state.order);
  };

  isWalkinCustomer = () => {
    return this.state.contactuuid === this.state.clientSettings[Constants.SETTINGS_WALKIN_CUSTOMER];
  };

  saveAddProduct = () => {
    if (this.isReadyToSubmitNewProduct()) {
      this.postProduct(this.state.product, product => {
        this.setState({ product: null, downloading: false }, () => {
          //Hide overlay after database action is complete
          this.props.hideOverlay();
          this.selectProductListItem(product);
        });
      });
    }
  };

  doPostOrder = () => {
    // For purchases, copy the selected supplier info onto the line items
    if (this.state.order?.ordertype === Constants.PURCHASE) {
      this.setState(
        prevState => ({
          order: {
            ...prevState.order,
            orderitems: prevState.order.orderitems.map(item => {
              item.companyuuid = prevState.order.company.companyuuid;
              item.companyname = prevState.order.company.companyname;
              return item;
            }),
          },
        }),
        () => {
          // POST the PURCHASE order
          this.postOrder();
        }
      );
    } else {
      // POST the OTHER orders
      this.postOrder();
    }
  };

  save = () => {
    if (this.isReadyToSubmit()) {
      if (!this.state.order?.orderuuid) {
        // Block duplicate button clicks
        if (this.postingOrder) {
          return;
        }
        this.postingOrder = true;
        // If the contact on the order is new, first POST the contact then the order
        if (!this.state.order?.company.companyuuid) {
          // Grab the first (and only) contact
          const contact = this.state.order?.company.contacts[0];
          this.postContact(contact, this.doPostOrder);
        } else {
          this.doPostOrder();
        }
      }
    }
  };

  sortAndCombineProducts = (orderitems, ordertype, salesperson) => {
    let temporderitems = orderitems ? Helper.deepCopy(orderitems) : [];
    temporderitems.sort((a, b) => {
      if (a.productuuid && b.productuuid) {
        return a.productuuid.localeCompare(b.productuuid);
      } else {
        return a.uuid.localeCompare(b.uuid);
      }
    });
    let result = [];
    let productuuid = null;
    temporderitems.forEach(item => {
      // New product
      if (productuuid !== item.productuuid || productuuid === null) {
        let newitem = Helper.getPurchaseItemFromOrderItem(ordertype, salesperson, item);
        productuuid = newitem.productuuid; // Update control-break field
        result.push(newitem);
      } else {
        // Existing product in list
        result = result.map(i => {
          // Find the item
          if (i.productuuid === item.productuuid) {
            // Update the quantity and associate the ref order item uuid and contact name
            i.quantity = numeral(i.quantity).add(numeral(item.quantity).value()).format(Constants.DECIMAL_VALUE);
            i.parent_data.push({
              orderitemuuid: item.orderitemuuid,
              contactname: item.contactname,
              contactuuid: item.contactuuid,
              customercompanyname: item.customercompanyname,
              mobilephone: item.mobilephone,
              otherphone: item.otherphone,
              parentorderitemuuid: item.orderitemuuid,
              rootorderitemuuid: item.rootorderitemuuid,
            });
            i.orderitemuuid = null;
            i.uuid = item.productuuid;
            i.orderuuid = null;
            //i.contactname = null;
          }
          return i;
        });
      }
      // TODO: Update Total and Tax on order
    });
    return result;
  };

  handleSelectInvoice = invoice => {
    // Do not toggle selection if we're in edit mode
    if (this.state.editingPayments) {
      return;
    }

    if (this.state.selectedInvoices.find(i => i.uuid === invoice.uuid)) {
      // Remove invoice from list
      this.setState(prevState => ({
        selectedInvoices: prevState.selectedInvoices.filter(i => i.uuid !== invoice.uuid),
      }));
    } else {
      // Add invoice to list
      this.setState(prevState => ({
        selectedInvoices: [...prevState.selectedInvoices, invoice],
      }));
    }
  };

  handleEditInvoicePayments = mode => {
    if (mode) {
      this.setState(prevState => ({
        editingPayments: mode,
        maastInvoices: prevState.maastInvoices.map(invoice => {
          invoice.map_date_payment = Helper.dateAsString();
          return invoice;
        }),
      }));
    } else {
      this.setState({ editingPayments: mode });
    }
  };

  // Fires when a customer is selected in the search widget
  selectCustomerListItem = contact => {
    const companyuuid = contact.companyuuid;
    const contactname = contact.firstname + " " + contact.lastname;
    this.setState(
      prevState => ({
        contactuuid: contact.contactuuid,
        // contactSearchKey: "",
        // contactSearchResults: null,
        order: {
          ...prevState.order,
          contactname: contactname,
          contactuuid: contact.contactuuid,
          companyname: contact.companyname,
        },
      }),
      () => {
        this.handleClearCustomerSearch();
        // If order is existing, then PUT order with new contactuuid
        if (this.state.isNew) {
          this.getCompany(companyuuid, this.handleAfterSelectContact);
        } else {
          this.putOrder(this.state.order?.orderuuid, "contactuuid", contact.contactuuid, () => {
            this.getCompany(companyuuid, this.handleAfterSelectContact);
          });
        }
      }
    );
  };

  handleAfterSelectContact = (company = null) => {
    if (this.state.order?.ordertype === Constants.PURCHASE) {
      // Update the line items with the supplier info
      const productuuids = this.state.order?.orderitems.map(item => item.productuuid);
      this.getProductSuppliers(productuuids);
    } else if (Helper.isOrderView(this.props.appState.currentView)) {
      // Check for customer company discount
      if (company?.discount) {
        this.setState(prevState => ({
          order: {
            ...prevState.order,
            orderitems: prevState.order?.orderitems.map(item => {
              item.discount = numeral(company.discount ?? 0)
                .multiply(numeral(item.sellprice).value())
                .format(Constants.CURRENCY);
              return item;
            }),
          },
        }));
      }
    }
  };

  selectBillingPlanListItem = billingplan => {
    let maastSubscriptions = this.state.maastSubscriptions;
    let subscription = Helper.deepCopy(billingplan);
    subscription.isNew = true;
    subscription.editMode = true;
    subscription.plan_duration_unlimited = subscription.plan_duration === -1;
    subscription.date_start = Helper.tomorrow();
    subscription.status = Constants.SUBSCRIPTION_ACTIVE;

    // Grab the primary billing card
    if (this.state.maastCustomer?.billing_cards?.length > 0) {
      const card = this.state.maastCustomer.billing_cards.find(card => card.primary);
      subscription.card_id = card?.card_id ?? "";
    } else {
      subscription.card_id = "";
    }

    // If no billing cards available, then choose the first card from the list
    if (!subscription.card_id && this.state.maastCustomer?.billing_cards?.length > 0) {
      subscription.card_id = this.state.maastCustomer.billing_cards[0].card_id;
    }

    // Format the subscription amount
    subscription.amt_tran = numeral(subscription.amt_tran).format(Constants.CURRENCY);

    maastSubscriptions.push(subscription);
    this.setState({
      maastSubscriptions: maastSubscriptions,
      newSubscriptionDesc: subscription.plan_desc,
    });
    this.handleClearBillingPlanSearch();
  };

  // Fires when a product is selected in the search widget
  selectProductListItem = (product, blankGiftCardNumber = false, zeroGiftCardAmount = false) => {
    this.handleClearProductSearch();

    // Gift cards cannot be sold inside a TRADE-IN invoice
    if (product.isgiftcard && this.containsTradeIn()) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Trade-in invoices cannot include a gift card sale.\n-\nComplete the trade-in invoice first using store credit or cash,\nthen create a new sale invoice for the gift card.",
      });
      return;
    }

    // Trade-in items cannot be sold inside a gift card SALE transaction
    if (product.sellprice < 0 && this.containsGiftCard()) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Gift card invoices cannot include a trade-in item.\n-\nComplete the trade-in invoice first using store credit or cash,\nthen create a new sale invoice for the gift card.",
      });
      return;
    }

    // If the product is a gift card, then prompt for the gift card number
    if (product.isgiftcard && (!product.giftCardNumber || !product.giftCardNumber.trim()) && !blankGiftCardNumber) {
      this.handleShowGiftCardInput(product);
      return;
    }

    // If the product is a zero dollar gift card then prompt for the gift card amount
    if (product.isgiftcard && numeral(product.sellprice).value() === 0 && !zeroGiftCardAmount) {
      let text = "Gift Card Amount";
      if (product.giftCardNumber && product.giftCardNumber.trim()) {
        text += "\n(#" + product.giftCardNumber + ")";
      }
      if (product.gift_card_exists) {
        text += "\n\n~Adding funds to existing gift card~";
        product.productname = "Adding funds to Gift Card";
      }
      const overlay = {
        type: Constants.OVERLAY_INPUT_BOX,
        text: text,
        icon: (
          <div className="icon amount">
            <FontAwesomeIcon icon={faMoneyCheckDollar} />
          </div>
        ),
        submitOnEnter: true,
        placeholder: "Enter gift card amount",
        maxLength: 255,
        key: product,
        callback: this.handleInputGiftCardAmount,
        required: true,
        input_type: "positivenumber",
      };
      this.props.showOverlay(overlay);
      return;
    }

    this.props.hideOverlay();

    // Consolidate PURCHASE line items
    let productinlist = false;
    let orderitemuuid;
    let newquantity = numeral(1);
    if (this.state.order?.ordertype === Constants.PURCHASE) {
      // If product is already in list, increase quantity
      const matchingproducts = this.state.order?.orderitems.filter(p => p.productuuid === product.productuuid);

      productinlist = matchingproducts.length > 0;
      if (productinlist) {
        this.state.order?.orderitems.forEach(item => {
          if (item.productuuid === product.productuuid) {
            newquantity = numeral(item.quantity).add(1);
            orderitemuuid = item.orderitemuuid;
          }
        });
      }
    }

    // Set an appropriate status based on the order type
    if (this.props.appState.currentView === Constants.PURCHASE) {
      product.lineitemstatus = Constants.ORDER_STATUS_PURCHASE_CREATED;
    } else if (this.props.appState.currentView === Constants.INVOICE) {
      product.lineitemstatus = Constants.ORDER_STATUS_INVOICED;
    } else {
      product.lineitemstatus = Constants.ORDER_STATUS_OPEN;
    }

    if (!this.state.isNew) {
      // Let the web service handle updating totals, etc.
      if (productinlist) {
        this.putOrderItem(this.state.order, orderitemuuid, "quantity", newquantity.value());
      } else {
        product.quantity = 1.0;
        // If the customer has a discount and this is a customer order, apply the discount to the product
        if (
          this.state.order?.company?.discount &&
          Helper.isOrderView(this.props.appState.currentView) &&
          this.props.appState.currentView !== Constants.PURCHASE
        ) {
          product.discount = numeral(this.state.order?.company?.discount ?? 0)
            .multiply(product.sellprice)
            .format(Constants.CURRENCY);
        } else {
          product.discount = numeral(0).format(Constants.CURRENCY);
        }
        if (this.props.appState.currentView === Constants.PURCHASE) {
          product.companyuuid = this.state.order?.company.companyuuid;
          product.companyname = this.state.order?.company.companyname;
        }
        // The parent_data is only used for purchases
        product.parent_data = [];
        // The root/parent uuid's are used for non-purchases
        product.rootorderitemuuid = null;
        product.parentorderitemuuid = null;
        this.postOrderItems(this.state.order, [product]);
      }
    } else {
      // If this is a new PURCHASE order, just update the line item quantity
      let orderitems;
      if (this.state.order?.ordertype === Constants.PURCHASE && productinlist) {
        orderitems = this.state.order?.orderitems.map(item => {
          if (item.productuuid === product.productuuid) {
            newquantity = numeral(item.quantity).add(1);
            orderitemuuid = item.orderitemuuid;
            item.quantity = newquantity.format(Constants.DECIMAL_VALUE);
          }
          return item;
        });
      } else {
        // Add the product to the list
        orderitems = this.state.order?.orderitems;
        let tempuuid = Math.round(Math.random() * 1000000);
        const filter_function = item => item.uuid === tempuuid;
        while (orderitems.filter(filter_function).length !== 0) {
          tempuuid = Math.round(Math.random() * 1000000);
        }
        product.uuid = product.orderitemuuid = "" + tempuuid;
        product.quantity = "1";
        product.associate = this.props.appState?.salesperson;

        // If the customer has an automatic discount and this is a customer order, apply the discount to the product
        if (
          this.state.order?.company?.discount &&
          Helper.isOrderView(this.props.appState.currentView) &&
          this.props.appState.currentView !== Constants.PURCHASE
        ) {
          product.discount = numeral(this.state.order?.company?.discount ?? 0)
            .multiply(numeral(product.sellprice).value())
            .format(Constants.CURRENCY);
        } else {
          product.discount = numeral(0).format(Constants.CURRENCY);
        }

        product.sellprice = numeral(product.sellprice).format(Constants.CURRENCY);
        product.cost = numeral(product.cost).format(Constants.CURRENCY);
        product.totalprice = numeral(product.totalprice).format(Constants.CURRENCY);
        product.totalcost = numeral(product.totalcost).format(Constants.CURRENCY);
        // The parent_data is only used for purchases
        product.parent_data = [];
        // The root/parent uuid's are used for non-purchases
        product.rootorderitemuuid = null;
        product.parentorderitemuuid = null;
        orderitems.push(product);

        // If a Purchase order does not have a company, then set it to the product's company
        if (!this.state.order?.company?.companyuuid && product.companyuuid && this.state.order?.ordertype === Constants.PURCHASE) {
          this.selectCustomerListItem({
            companyuuid: product.companyuuid,
            firstname: product.companyname,
            lastname: "",
          });
        }
      }
      this.setState(prevState => ({
        order: Helper.newOrderRecalculate({ ...prevState.order, orderitems: orderitems }),
      }));
    }
    this.productSearchInput.current.focus();
    return product;
  };

  handleShowGiftCardInput = product => {
    const overlay = {
      type: Constants.OVERLAY_INPUT_BOX,
      text: "Gift Card Number",
      icon: (
        <div className="icon gift_card_number">
          <FontAwesomeIcon icon={faBarcode} />
        </div>
      ),
      placeholder: "Scan or enter card number",
      maxLength: 255,
      key: product,
      inputcontainerClass: "giftCardNumberInputContainer",
      callback: this.handleInputGiftCardNumber,
      required: true,
      submitOnEnter: true,
    };
    this.props.showOverlay(overlay);
  };

  handleApplyDiscount = () => {
    if (this.state.order?.orderitems.length > 0 && !Constants.CLOSED_INVOICE_ORDER_STATUSES.includes(this.state.order?.orderstatus)) {
      const overlay = {
        type: Constants.OVERLAY_INPUT_BOX,
        title: "Percent Discount",
        text: `Enter the discount to apply\nto the entire ${this.props.appState.currentView} (e.g. 10%)`,
        placeholder: "10%",
        input_type: "percent",
        maxLength: 6,
        callback: (response, _, input) => {
          this.props.hideOverlay();
          if (response === Constants.OVERLAY_RESPONSE_OK) {
            if (!input.endsWith("%")) {
              input += "%";
            }
            this.state.order.orderitems.forEach(item => {
              this.handleChangeOrderItem({ target: { id: "discount", type: "text", value: input } }, item.uuid);
              this.handleBlurOrderItem({ target: { id: "discount", type: "text", value: input } }, item.uuid, item.discount, "percent");
            });
          }
        },
        okButtonLabel: "Apply",
        submitOnEnter: true,
        required: true,
      };
      this.props.showOverlay(overlay);
    }
  };

  handleReceiveAll = () => {
    // Nothing to do if no order items
    if (this.state.order?.orderitems.length === 0) {
      return;
    }
    // Nothing to do if all items have a received qty equal to or greater than the qty ordered
    if (!Helper.orderContainsUnreceivedItems(this.state.order)) {
      return;
    }
    const overlay = {
      type: Constants.OVERLAY_QUESTION,
      title: "Receive All",
      text: "Mark all items in this purchase order as received?",
      callback: (response, _, input) => {
        this.props.hideOverlay();
        if (response === Constants.OVERLAY_RESPONSE_YES) {
          this.state.order.orderitems.forEach(item => {
            // Only update items that have not been fully received
            if (Helper.isQuantityReceivedLessThanQuantity(item)) {
              this.handleChangeOrderItem({ target: { id: "quantityreceived", type: "text", value: item.quantity } }, item.uuid);
              this.handleBlurOrderItem({ target: { id: "quantityreceived", type: "text", value: item.quantity } }, item.uuid);
            }
          });
        }
      },
      okButtonLabel: "Apply",
      submitOnEnter: true,
      required: true,
    };
    this.props.showOverlay(overlay);
  };

  selectOrderItem = orderItem => {
    // IF the order item is already selected, then remove it from the list
    if (this.state.selectedOrderItems.filter(item => item.uuid === orderItem.uuid).length > 0) {
      this.setState(prevState => ({
        selectedOrderItems: prevState.selectedOrderItems.filter(item => item.uuid !== orderItem.uuid),
      }));
    }
    // OR append the selected order item to the list
    else {
      this.setState(prevState => ({
        selectedOrderItems: [...prevState.selectedOrderItems, orderItem],
      }));
    }
  };

  updateMarketingProspect = (prospect, callback = null) => {
    this.setState(
      prevState => ({
        prospect: prospect,
        company: {
          ...prevState.company,
          contacts: [prospect],
        },
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  updateCompanyState = (id, value) => {
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        company: {
          ...prevState.order.company,
          [id]: value,
        },
      },
    }));
  };

  setStateContacts = (contacts, callback = null) => {
    this.setState(
      prevState => ({
        order: {
          ...prevState.order,
          company: {
            ...prevState.order.company,
            contacts: contacts,
          },
        },
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  getTotalPrice = () => {
    if (this.state.order) {
      return this.state.order?.totalprice;
    } else {
      return null;
    }
  };

  getTotalPayments = () => {
    if (this.state.order) {
      return numeral(this.state.order?.totalpayments ?? 0).value();
    } else {
      return null;
    }
  };

  getContactsContainerRef = () => {
    return this.state.order?.company;
  };

  getDetailObjectRef = () => {
    let ref = null;
    if (this.props.appState.currentView === Constants.CUSTOMER && this.props.filtertype?.tab === Constants.TAB_PROSPECTS) {
      ref = this.state.prospect;
    } else if (this.props.appState.currentView === Constants.CUSTOMER) {
      ref = this.state.company;
    } else if (this.props.appState.currentView === Constants.SUPPLIER) {
      ref = this.state.company;
    } else if (this.props.appState.currentView === Constants.PRODUCT) {
      ref = this.state.product;
    } else if (this.props.appState.currentView === Constants.CAMPAIGN) {
      ref = this.state.campaign;
    } else if (this.props.appState.currentView === Constants.REPORT) {
      ref = {
        uuid: this.state.reporttype,
        creationdatetime: Helper.today(),
        salesperson: this.props.appState.salesperson,
      };
    } else if (this.props.appState.currentView === Constants.BILLING) {
      if (this.props.filtertype?.tab !== Constants.TAB_PLANS) {
        ref = this.state.company;
      } else {
        ref = this.state.plan;
      }
    } else {
      ref = this.state.order;
    }
    if (!ref) {
      console.log(Helper.clTimestamp(), "getDetailObjectRef: detail object ref is null", this.props.appState.currentView);
    }
    return ref;
  };

  getPossibleSuppliers = order => {
    let possibleCompanies = [];
    if (order.company === null && order.orderitems.length > 0) {
      // Build a list of unique companyuuids
      const companyuuids = [];
      order.orderitems.forEach(item => {
        if (item.companyuuid) {
          // If the company uuid is not already in the list, add it
          if (!Helper.inList(companyuuids, item.companyuuid)) {
            companyuuids.push(item.companyuuid);
            possibleCompanies.push({ id: item.companyuuid, text: item.companyname, count: 1 });
          } else {
            // If the company is already in the list, increment the counter
            possibleCompanies = possibleCompanies.map(company => {
              if (company.id === item.companyuuid) {
                company.count++;
              }
              return company;
            });
          }
        }
      });
      if (companyuuids.length > 1) {
        // Sort the supplier list by count
        possibleCompanies.sort((a, b) => b.count - a.count);
      }
    }
    return possibleCompanies;
  };
}
export default BaseDetailViewHandler;

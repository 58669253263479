function render(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="136.959"
      height="123.253"
      viewBox="0 0 136.959 123.253"
    >
      <g id="Layer_1-2" transform="translate(-0.001 -0.008)">
        <path
          id="Path_334"
          data-name="Path 334"
          d="M98.563,68.173a47.05,47.05,0,0,1,6.487,16.8c2.637,14.162.155,27.425-7.826,39.431a51.2,51.2,0,0,1-34.441,22.851c-15.973,2.946-30.486-.8-43.146-10.927A50.561,50.561,0,0,1,.807,104.205c-2.6-15.815,1.07-30.193,11.232-42.658A51.192,51.192,0,0,1,45.015,42.754c14.752-2.214,28.194,1.314,40.18,10.251.326.24.667.46,1,.688a2.951,2.951,0,0,1-.277.354A343.686,343.686,0,0,0,54.607,89.4c-.977,1.27-1.555,1.172-2.727.342C43,83.466,34.06,77.28,25.167,71.025c-.867-.606-1.367-.667-2.153.147-2.466,2.564-5.014,5.05-7.57,7.525-.594.574-.631.948-.122,1.624,2.132,2.849,4.208,5.734,6.279,8.628,10.842,15.168,21.773,30.274,31.466,46.227.159.265.212.692.7.676.159-.26.321-.517.472-.781q14.919-25.633,31.918-49.93c4.009-5.75,8.029-11.493,12.4-16.974Z"
          transform="translate(0 -24.968)"
          fill="#afee8f"
        />
        <path
          id="Path_335"
          data-name="Path 335"
          d="M120.378,43.2c-4.375,5.482-8.4,11.224-12.4,16.974Q91.022,84.5,76.056,110.109c-.155.265-.313.521-.472.781-.488.02-.541-.411-.7-.676C65.19,94.266,54.263,79.155,43.417,63.988c-2.067-2.894-4.147-5.779-6.279-8.628a1.01,1.01,0,0,1,.122-1.624c2.556-2.474,5.1-4.961,7.57-7.525.781-.814,1.286-.753,2.153-.147C55.874,52.32,64.815,58.506,73.7,64.777c1.172.826,1.75.924,2.727-.342a343.261,343.261,0,0,1,31.312-35.349,3.311,3.311,0,0,0,.277-.354c5.942-5.616,11.924-11.183,18.265-16.36,4.92-4.017,9.962-7.858,15.241-11.395a5.406,5.406,0,0,1,3.252-.969c4.546.065,9.1.028,14.008.028-1.184,1.087-2.1,1.925-3.02,2.771C143.6,14,133.051,26.612,122.983,39.651c-.9,1.164-1.734,2.373-2.6,3.557Z"
          transform="translate(-21.815 0)"
          fill="#019045"
        />
      </g>
    </svg>
  );
}
export default render;

import React from "react";

// Style
import "./css/searchbar.css";

function EmployeeSearchBar(props) {
  const employees = props.employees.map(employee => {
    return (
      <option key={employee.uuid} value={employee.uuid}>
        {employee.fullname}
      </option>
    );
  });
  return (
    <div className="search-wrapper">
      <span id="list-search-input-container">
        <select className="employeeSelect" size="1" value={props.employee} onChange={props.handleChange}>
          {employees}
        </select>
      </span>
    </div>
  );
}

export default EmployeeSearchBar;

function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="17 0 31.2 31">
      <g id="Group_217" data-name="Group 217" transform="translate(-97 -10.892)">
        <g id="Group_60" data-name="Group 60" transform="translate(114 10.892)">
          <path
            id="Path_67"
            data-name="Path 67"
            d="M1592.419,49.369h5.53V41.453h1.747l-.027,7.916h16.605v-8l1.785.014v7.984h5.544V40.238c-.464-7.448-8.956-7.089-8.956-7.089h-13.153c-9.423.174-9.089,7.089-9.089,7.089v9.131Zm0,0"
            transform="translate(-1592.404 -18.369)"
          />
          <path
            id="Path_68"
            data-name="Path 68"
            d="M1614.5,24.861a6.49,6.49,0,1,1-6.489-6.492,6.5,6.5,0,0,1,6.489,6.492Zm0,0"
            transform="translate(-1592.404 -18.369)"
          />
        </g>
      </g>
    </svg>
  );
}
export default render;

import React from "react";

// Constants
import * as Constants from "./Constants.jsx";

// Functions
import * as Helper from "./Helper.jsx";

// Style
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudBolt, faLock } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Capo from "./img/Capo.js";
import LogoIcon from "./img/LogoIcon";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.passwordRef = React.createRef();
    this.state = {
      pass: "",
      confirm: "",
      passtype: "password",
      confirmtype: "password",
    };
  }

  componentDidMount() {
    this.passwordRef.current.focus();
  }

  render() {
    const buttontext = this.props.waiting ? <FontAwesomeIcon icon={faCloudBolt} /> : "Reset Password";
    const buttonclass = this.isReadyToSubmit() && !this.props.waiting ? "action-button" : "action-button save-disabled";

    let eyeball = <FontAwesomeIcon icon={faEyeSlash} />;
    if (this.state.overlay?.passtype === "password") {
      eyeball = <FontAwesomeIcon icon={faEye} />;
    }
    return (
      <div className="app-body">
        <div className="login">
          <div className="login-logo">
            <Capo />
          </div>
          <div className="login-wrapper gridCenter">
            <h3>Reset Password</h3>
            <div className="login-container">
              <div>Enter your new password and in each of the boxes below.</div>
              <div className="input-container svgGridCenter">
                <FontAwesomeIcon icon={faLock} />
                <input
                  type={this.state.passtype}
                  name="pass"
                  id="pass"
                  placeholder="password"
                  maxLength={50}
                  autoComplete="off"
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                  required
                  value={this.state.pass}
                  ref={this.passwordRef}
                />
                <span className="eyeball gridCenter" onClick={this.handleTogglePassword}>
                  {eyeball}
                </span>
              </div>
              <div className="input-container svgGridCenter">
                <FontAwesomeIcon icon={faLock} />
                <input
                  type={this.state.confirmtype}
                  name="confirm"
                  id="confirm"
                  placeholder="confirm"
                  maxLength={50}
                  autoComplete="off"
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyDown}
                  required
                  value={this.state.confirm}
                />
                <span className="eyeball gridCenter" onClick={this.handleToggleConfirm}>
                  {eyeball}
                </span>
              </div>
              <div className="specialdesc">
                Password must be 10 to 50 characters in length and should not be a commonly used password. The best passwords are short phrases,
                rather than hard to remember random characters.
              </div>
              <span id="login-button" className={buttonclass} onClick={this.handleSubmit}>
                {buttontext}
              </span>
            </div>
          </div>
          <div className="login-title">
            <h1 className="title gridCenter">
              <LogoIcon />
            </h1>
          </div>
        </div>
      </div>
    );
  }

  handleTogglePassword = () => {
    this.setState(prevState => ({
      passtype: prevState.passtype === "password" ? "text" : "password",
    }));
  };

  handleToggleConfirm = () => {
    this.setState(prevState => ({
      confirmtype: prevState.confirmtype === "password" ? "text" : "password",
    }));
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: Helper.getTargetValue(event),
    });
  };

  handleSubmit = () => {
    if (this.isReadyToSubmit() && !this.props.waiting) {
      this.props.handlePasswordChange(this.props.resetuuid, this.state.pass, () => {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Password changed successfully. Please log in with your new password.",
          callback: () => {
            // Redirect to home/login page
            window.location.href = "/";
          },
        });
      });
    }
  };

  handleKeyDown = event => {
    if (event.keyCode === 13) {
      this.handleSubmit();
    }
  };

  isReadyToSubmit = () => {
    return this.state.pass.trim().length > 9 && this.state.confirm.trim().length > 9 && this.state.pass === this.state.confirm;
  };
}

export default ChangePassword;

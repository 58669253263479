import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faPaperPlane, faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Helper from "./Helper";

function TextsPanel(props) {
  let {
    selectedListItems,
    downloadingTexts,
    newMessage,
    textPanelRef,
    handleGetOlderTexts,
    handleGetNewTexts,
    handleChangeNewMessage,
    handleSendNewMessage,
    showNewTextMessage,
  } = props;
  if (selectedListItems?.length > 0) {
    const selectedItem = selectedListItems[0];
    const messages = selectedItem.messages?.records;

    if (!messages) {
      return (
        <div key={"loading_texts"}>
          Loading texts... <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      );
    } else {
      const no_message_text = <span className="italic">No message text</span>;
      let loadOlderMessagesElement = <div>&nbsp;</div>;
      if (selectedItem.messages.count > selectedItem.messages.records.length) {
        loadOlderMessagesElement = (
          <span onClick={handleGetOlderTexts} className="loadOlderMessages">
            Load older messages
          </span>
        );
      }
      const messageElements = messages.map(text => {
        let classNames = "sms_card " + (text.inbound ? "incoming" : "outgoing");
        if (text.easeIn) {
          classNames += " easeIn";
        }
        if (text.easeInActive) {
          classNames += " easeInActive";
        }
        let containerClassNames = "sms_card_container " + (text.inbound ? "incoming" : "outgoing");
        return (
          <React.Fragment key={text.uuid}>
            <div className={containerClassNames}>
              <div className={classNames}>
                <div className="subject">{text.subject}</div>
                <div>{text.message || no_message_text}</div>
                <div className="textDateTime">{Helper.formatDateTime(text.messagedatetime)}</div>
              </div>
            </div>
          </React.Fragment>
        );
      });
      const disabled = newMessage.length === 0 || !selectedItem.smsoptedin;
      const classnames = "action-button green-button" + (disabled ? " save-disabled " : "");
      const spinGetNew = downloadingTexts ? true : false;
      const newTextPanel = showNewTextMessage ? (
        <div className="newTextPanel">
          <FontAwesomeIcon icon={faArrowRotateRight} className="getNewTexts" spin={spinGetNew} onClick={handleGetNewTexts} />
          <input
            type="text"
            name="newMessage"
            id="newMessage"
            disabled={!selectedItem.smsoptedin}
            placeholder={selectedItem.smsoptedin ? "Text message" : "Contact not opted-in to receive texts"}
            maxLength={160}
            className=""
            autoComplete="off"
            value={newMessage}
            onChange={handleChangeNewMessage}
          />
          <span onClick={handleSendNewMessage} className={classnames}>
            <FontAwesomeIcon icon={faPaperPlane} /> Send
          </span>
        </div>
      ) : (
        ""
      );
      return (
        <React.Fragment>
          <div className="textPanel" ref={textPanelRef}>
            {loadOlderMessagesElement}
            {messageElements}
          </div>
          {newTextPanel}
        </React.Fragment>
      );
    }
  } else {
    return <div></div>;
  }
}

export default TextsPanel;

function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 324.12 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 55">
            <path data-name="Path 49" d="M125.64,139.49,246.41,69.76,189.61,37,68.85,106.7Z" />
            <path data-name="Path 50" d="M121,147.93,0,78.2V217.66l121,69.73Z" />
            <rect data-name="Rectangle 35" x="228.12" y="219.33" width="13.22" height="13.22" />
            <path
              data-name="Path 51"
              d="M130.75,147.93V287.4l66.07-38.21V148.56a20.9,20.9,0,0,1,20.8-21h17.32a17.06,17.06,0,0,1,16.28-12.52h-.14V78.28Z"
            />
            <rect data-name="Rectangle 36" x="244.12" y="124.74" width="42.43" height="14.6" rx="7.06" />
            <path data-name="Path 52" d="M179.87,31.31,125.68,0,4.92,69.73,59.1,101Z" />
            <path
              data-name="Path 53"
              d="M312.92,137.26H295.4a16.67,16.67,0,0,1-16.1,11.82H251.24a16.67,16.67,0,0,1-16.09-11.82H217.63a11.13,11.13,0,0,0-11,11.22h0V289.07A10.87,10.87,0,0,0,217.37,300h95.56a11,11,0,0,0,11.19-10.78h0V148.51A11.24,11.24,0,0,0,312.92,137.26ZM218.39,170a4.85,4.85,0,0,1,4.78-4.9h23a4.93,4.93,0,0,1,4.92,4.9v3.27a4.89,4.89,0,0,1-4.89,4.84,4.69,4.69,0,0,1-4.63-3.29H228.14v13.27h.2a4.87,4.87,0,0,1,0,9.74h-5.1A4.84,4.84,0,0,1,218.4,193V170Zm0,44.51a4.84,4.84,0,0,1,4.84-4.84h23a4.84,4.84,0,0,1,4.84,4.84v22.93a4.84,4.84,0,0,1-4.84,4.84h-23a4.84,4.84,0,0,1-4.84-4.84Zm9.93,72.28h-5.1a4.7,4.7,0,0,1-4.84-4.56v-23a5,5,0,0,1,4.84-5.07h22.93a5.09,5.09,0,0,1,4.93,5.07v4.69a4.9,4.9,0,0,1-9.8,0H228.12v13.24h.2a4.87,4.87,0,0,1,0,9.74Zm35.92-19-17.69,17.7a4.83,4.83,0,0,1-6.84.05.12.12,0,0,1-.05-.05h0l-7.74-7.74a4.87,4.87,0,0,1,6.89-6.89h0l4.33,4.34L257.39,261a4.87,4.87,0,0,1,6.89,6.89h0Zm0-89.9-17.69,17.71a4.83,4.83,0,0,1-6.84,0l-.05,0h0l-7.74-7.74a4.87,4.87,0,0,1,6.89-6.89l4.33,4.34,14.25-14.26a4.87,4.87,0,1,1,6.89,6.88h0Zm43.07,97.73H276.73a4.87,4.87,0,1,1,0-9.74h30.58a4.87,4.87,0,1,1,0,9.74Zm0-44.51H276.73a4.87,4.87,0,1,1,0-9.73h30.58a4.87,4.87,0,1,1,0,9.73Zm0-44.51H276.73a4.87,4.87,0,1,1,0-9.73h30.58a4.87,4.87,0,1,1,0,9.73Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

import React from "react";

// Constants
import * as Constants from "./Constants";

// Components
import BaseDetailViewComponent from "./BaseDetailViewComponent";

// Functions
import * as Helper from "./Helper";
import numeral from "numeral";
import JsBarcode from "jsbarcode";

// View for editing products.
class Product extends BaseDetailViewComponent {
  constructor(props) {
    super(props);

    // creates cursor focus
    this.companyNameInput = React.createRef();
    this.skuInput = React.createRef();

    const product = props.product === null ? Helper.getBlankProduct(props.appState.salesperson) : props.product;

    // Convert database NULLs to empty string to keep React happy
    product.companyuuid = Helper.formatString(product.companyuuid);
    product.companyname = Helper.formatString(product.companyname);
    product.cost = numeral(product.cost).format(Constants.CURRENCY);
    if (product.maxdiscount !== null) {
      product.maxdiscount = Helper.formatPercent(product.maxdiscount);
    }

    this.state = {
      ...this.state,
      product: product, // This object contains the selected uuid from the product list
      viewName: Constants.PRODUCT,
    };
  }

  // Once component is loaded, fire GET request
  componentDidMount() {
    super.componentDidMount();
    if (this.state.product.productuuid) {
      this.setState({ isNew: false });
      this.getProduct(this.state.product.productuuid);
    }
  }

  handleDelete = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete?",
      callback: this.maybeDeleteProduct,
      key: this.state.product.productuuid,
    });
  };

  maybeDeleteProduct = (response, productuuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteProduct(productuuid);
    }
  };

  // Required fields include product name, sell price, and on-hand
  isReadyToSubmit = () => {
    if (!this.state.product.companyuuid && (this.state.product.sku || numeral(this.state.product.cost).value() !== 0)) {
      return false;
    }
    return this.state.product.productname && this.state.product.sellprice !== "";
  };

  save = () => {
    if (this.isReadyToSubmit()) {
      if (!this.state.product.productuuid) {
        this.postProduct(this.state.product, product => {
          this.setState({ product: product, downloading: false, isNew: false }, () => {
            this.props.updateBreadcrumb({ selectedItem: product });
            if (product.upc || product.altupc) {
              const upc = product.upc && Helper.isValidUpc(product.upc) ? product.upc : product.altupc;
              try {
                JsBarcode("#barcodeUPC", upc, {
                  format: "upc",
                  width: 1.5,
                  height: 25,
                  displayValue: true,
                  font: "Poppins - Regular",
                  textMargin: 1,
                  fontSize: 10,
                });
              } catch (error) {
                console.log(Helper.clTimestamp(), error);
              }
            }
            this.props.hideOverlay();
          });
        });
      }
    }
  };

  formatProduct(product) {
    product.sellprice = numeral(product.sellprice).format(Constants.CURRENCY);
    product.cost = numeral(product.cost).format(Constants.CURRENCY);
    product.inventory = numeral(product.inventory).format(Constants.DECIMAL_VALUE);
    product.suppliers = product.suppliers.map(supplier => {
      supplier.cost = numeral(supplier.cost).format(Constants.CURRENCY);
      return supplier;
    });
    return product;
  }

  getTotalPrice = () => {
    return null;
  };

  getProduct = productuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    const params = { productuuid: productuuid };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Format the numeric fields in the product and suppliers
        const product = this.formatProduct(response.body);
        // Format the max discount as a percentage
        if (product.maxdiscount !== null) {
          product.maxdiscount = Helper.formatPercent(product.maxdiscount);
        }
        //Update state with response, turn off downloading, execute deep copy
        this.setState(
          {
            product: product,
            error: null,
            downloading: false,
            isNew: false,
          },
          () => {
            if (this.state.product.upc || this.state.product.altupc) {
              const upc = this.state.product.upc && Helper.isValidUpc(this.state.product.upc) ? this.state.product.upc : this.state.product.altupc;
              try {
                JsBarcode("#barcodeUPC", upc, {
                  format: "upc",
                  width: 1.5,
                  height: 25,
                  displayValue: true,
                  font: "Poppins - Regular",
                  textMargin: 1,
                  fontSize: 10,
                });
              } catch (error) {
                console.log(Helper.clTimestamp(), error);
              }
            }
            //Hide overlay after database action is complete
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 404) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the product.",
          });
        });
      }
    });
  };

  // Create a new supplier record in the database
  postSupplier = index => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = "/suppliers";
    let supplier = this.state.product.suppliers[index];
    supplier.cost = numeral(supplier.cost ?? 0).value() ?? 0;
    Helper.postData(url, supplier).then(response => {
      if (response.status === 200 && response.body) {
        // Replace the new supplier record (blank one) with the response from the web service
        //  In the state.product...
        const newsupplier = response.body;
        newsupplier.cost = numeral(newsupplier.cost ?? 0).format(Constants.CURRENCY);
        const suppliers = this.state.product.suppliers.map((sup, sup_index) => {
          if (sup_index === index) {
            return newsupplier;
          } else {
            return sup;
          }
        });
        //Update state with response, turn off downloading
        this.setState(prevState => ({
          product: {
            ...prevState.product,
            suppliers: suppliers,
          },
          downloading: false,
        }));
        //Hide overlay after database action is complete
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the supplier.",
          });
        });
      }
    });
  };

  putProduct = (type, field, value, prev = "", callback = null) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //format numbers before executing the PUT
    if (type === "float" || type === "percent") {
      value = numeral(value ?? 0).value();
    }

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    let data = {
      productuuid: this.state.product.productuuid,
    };
    data[field] = value;
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Format the numeric fields in the product and suppliers
        const product = this.formatProduct(response.body);
        // Format the max discount as a percentage
        if (product.maxdiscount !== null) {
          product.maxdiscount = Helper.formatPercent(product.maxdiscount);
        }
        // Format the product dimensions
        if (product.length) {
          product.length = numeral(product.length).format(Constants.DECIMAL_VALUE);
        }
        if (product.width) {
          product.width = numeral(product.width).format(Constants.DECIMAL_VALUE);
        }
        if (product.height) {
          product.height = numeral(product.height).format(Constants.DECIMAL_VALUE);
        }
        if (product.weight) {
          product.weight = numeral(product.weight).format(Constants.DECIMAL_VALUE);
        }

        //Update state with response, turn off downloading
        this.setState(
          prevState => ({
            downloading: false,
            product: { ...prevState.product, ...product },
          }),
          () => {
            if (this.state.product.upc || this.state.product.altupc) {
              const upc = this.state.product.upc && Helper.isValidUpc(this.state.product.upc) ? this.state.product.upc : this.state.product.altupc;
              try {
                JsBarcode("#barcodeUPC", upc, {
                  format: "upc",
                  width: 1.5,
                  height: 25,
                  displayValue: true,
                  font: "Poppins - Regular",
                  textMargin: 1,
                  fontSize: 10,
                });
              } catch (error) {
                console.log(Helper.clTimestamp(), error);
              }
            }
            //Hide overlay after database action is complete
            this.props.hideOverlay();
            if (callback) {
              callback();
            }
          }
        );
      } else if (response.status === 409) {
        // Duplicate UPC, revert to previous value
        this.setState(
          prevState => ({
            product: { ...prevState.product, upc: prev },
            error: null,
            downloading: false,
          }),
          () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Duplicate UPC value.",
            });
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the product.",
          });
        });
      }
    });
  };

  // Update the "default supplier" attributes of a product in the single PUT
  putProductSupplier = supplier => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    let data = {
      productuuid: this.state.product.productuuid,
      companyuuid: supplier.companyuuid,
      cost: numeral(supplier.cost).value(),
      sku: supplier.sku,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200) {
        //Update state to turn off downloading
        this.setState({ downloading: false });

        //Hide overlay after database action is complete
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the supplier.",
          });
        });
      }
    });
  };

  // Update the SKU, cost, and/or companyuuid
  putSupplier = (supplieruuid, field, value) => {
    // If the uuid is "new" then this supplier has not been created yet. Bail out.
    if (supplieruuid === "new") {
      return;
    }
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = "/suppliers";
    const data = {
      supplieruuid: supplieruuid,
    };
    if (field === "cost") {
      value = numeral(value).value();
    }
    data[field] = value;
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Replace the existing supplier record in the state with the data from the web service
        //    so it will be consistent with what is in the database
        const supplier = response.body;
        supplier.cost = numeral(supplier.cost).format(Constants.CURRENCY);
        const suppliers = this.state.product.suppliers.map(sup => {
          if (sup.supplieruuid === supplieruuid) {
            return supplier;
          } else {
            return sup;
          }
        });
        //Update state with response, turn off downloading
        this.setState(prevState => ({
          product: {
            ...prevState.product,
            suppliers: suppliers,
          },
          downloading: false,
        }));
        //Hide overlay after database action is complete
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the supplier.",
          });
        });
      }
    });
  };

  deleteProduct = productuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_PRODUCTS;
    const params = { productuuid: productuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        //Update state to turn off downloading
        this.setState({ downloading: false, error: null });
        //Hide overlay after database action is complete
        this.props.hideOverlay();
        //Return to the List screen
        this.props.followBreadcrumb();
      } else if (response.status === 202) {
        // Failure to delete product. Marked inactive instead.
        this.setState(
          prevState => ({
            error: null,
            downloading: false,
            product: { ...prevState.product, active: false },
          }),
          () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: response.body.message,
            });
          }
        );
      } else if (response.status === 409) {
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the product.",
          });
        });
      }
    });
  };

  deleteSupplier = supplier => {
    const supplieruuid = supplier.supplieruuid;
    this.setState({ downloading: true });
    const url = "/suppliers";
    const params = { supplieruuid: supplieruuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200) {
        //Update state to turn off downloading
        this.setState({ downloading: false, error: null });
        //Hide overlay after database action is complete
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting the supplier.",
          });
        });
      }
    });
  };
}
export default Product;

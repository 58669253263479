function render(props) {
  return (
    <svg
      id="Layer_1-2"
      xmlns="http://www.w3.org/2000/svg"
      width="132.314"
      height="132.314"
      viewBox="0 0 132.314 132.314"
    >
      <circle
        id="Ellipse_7"
        data-name="Ellipse 7"
        cx="66.157"
        cy="66.157"
        r="66.157"
        transform="translate(0 0)"
        fill="#f34336"
      />
      <path
        id="Path_336"
        data-name="Path 336"
        d="M158.622,165.523a7.655,7.655,0,0,1-5.71-2.493q-9.6-9.64-19.231-19.244a2.008,2.008,0,0,0-3.213.056q-9.561,9.56-19.111,19.128a7.413,7.413,0,0,1-5.886,2.54,7.057,7.057,0,0,1-4.8-11.868c1.61-1.746,3.343-3.379,5.023-5.062,5.082-5.082,10.141-10.188,15.27-15.227.92-.9.949-1.394.01-2.32q-10.034-9.909-19.954-19.928a7.08,7.08,0,1,1,10.072-9.936q8.181,8.121,16.313,16.3c1.212,1.212,2.493,2.364,3.612,3.655.85.983,1.354.827,2.2-.03,6.533-6.6,13.129-13.146,19.675-19.735a7.36,7.36,0,0,1,5.733-2.5A7.055,7.055,0,0,1,163.8,110.4c-.8.979-1.749,1.846-2.646,2.742-5.979,5.985-11.944,11.984-17.966,17.926-.87.856-.926,1.308-.023,2.2q10.034,9.909,19.951,19.928c3.718,3.758,2.8,9.541-1.746,11.662a7.358,7.358,0,0,1-2.755.654Z"
        transform="translate(-65.941 -66.017)"
        fill="#f9f7f7"
      />
    </svg>
  );
}
export default render;

import React, { Component } from "react";
import * as Helper from "./Helper";
import * as Constants from "./Constants";

class Autocomplete extends Component {
  constructor(props) {
    super(props);

    // Refs for the entire component and the text input element
    this.component = React.createRef();
    this.input = React.createRef();

    this.state = {
      activeSuggestion: -1,
      filteredSuggestions: [],
      showSuggestions: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    // Ignore clicks on the component itself
    if (this.component.current && this.component.current.contains(event.target)) {
      return;
    }
    // If clicking outside the component and the value has been modified, save it
    if (
      this.input.current.getAttribute(Constants.ATTR_DATA_VALUE) &&
      this.input.current.value !== this.input.current.getAttribute(Constants.ATTR_DATA_VALUE) &&
      this.props.handleBlur
    ) {
      this.props.handleBlur(this.getTarget(this.input.current.value));
    }

    // Clicking outside the component should hide the suggestions
    this.setState({
      showSuggestions: false,
    });
  };

  handleChange = event => {
    // Call the parent component's handleChange method
    if (this.props.handleChange) {
      this.props.handleChange(event);
    }

    // Filter our suggestions that don't contain the user's input and update the state
    const value = Helper.getTargetValue(event);
    const filteredSuggestions = this.props.suggestions?.filter(suggestion => suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1);

    this.setState({
      activeSuggestion: -1,
      filteredSuggestions,
      showSuggestions: true,
    });
  };

  // Click on a suggestion
  handleClick = event => {
    const selectedValue = event.currentTarget.innerText;
    this.props.handleChange({ target: { id: this.props.id, value: selectedValue } });
    if (this.props.handleBlur) {
      this.props.handleBlur(this.getTarget(selectedValue));
    }
    this.input.current.setAttribute(Constants.ATTR_DATA_VALUE, this.state.filteredSuggestions[this.state.activeSuggestion]);
    this.setState({
      activeSuggestion: -1,
      filteredSuggestions: [],
      showSuggestions: false,
    });
  };

  // Handle Enter, TAB, ArrowUp, and ArrowDown keys as special events
  handleKeyDown = event => {
    // User pressed the enter key
    if (event.code === "Enter" || event.code === "Tab") {
      if (this.state.activeSuggestion > -1) {
        this.props.handleChange({ target: { id: this.props.id, value: this.state.filteredSuggestions[this.state.activeSuggestion] } });
        if (this.props.handleBlur) {
          this.props.handleBlur(this.getTarget(this.state.filteredSuggestions[this.state.activeSuggestion]));
        }
        this.input.current.setAttribute(Constants.ATTR_DATA_VALUE, this.state.filteredSuggestions[this.state.activeSuggestion]);
      }
      this.setState({
        activeSuggestion: -1,
        showSuggestions: false,
      });
      if (this.input.current.getAttribute(Constants.ATTR_DATA_VALUE) !== event.target.value) {
        if (this.props.handleBlur) {
          this.props.handleBlur(this.getTarget(event.target.value));
        }
        // Move focus to the next/previous element (the normal behavior of the TAB key is not happening due to our event handler, for some reason)
        const activeElement = document.activeElement;
        const focusableElements = Array.from(document.querySelectorAll("input, select, textarea, button, a[href], [tabindex]"));
        const currentIndex = focusableElements.indexOf(activeElement);
        const offset = event.shiftKey ? -1 : 1;
        const nextIndex = (currentIndex + offset) % focusableElements.length;
        focusableElements[nextIndex].focus();
      }
    }
    // User pressed the up arrow
    else if (event.code === "ArrowUp") {
      this.setState(
        prevState => ({ activeSuggestion: Math.max(prevState.activeSuggestion - 1, 0) }),
        () => {
          this.props.handleChange({ target: { id: this.props.id, value: this.state.filteredSuggestions[this.state.activeSuggestion] } });
        }
      );
    }
    // User pressed the down arrow
    else if (event.code === "ArrowDown") {
      this.setState(
        prevState => ({ activeSuggestion: Math.min(prevState.activeSuggestion + 1, this.state.filteredSuggestions.length - 1) }),
        () => {
          this.props.handleChange({ target: { id: this.props.id, value: this.state.filteredSuggestions[this.state.activeSuggestion] } });
        }
      );
    }
  };

  // Construct a mock event object to pass to the parent component's handleBlur method
  // We need this because we call the handleBlur method from the handleClick method, and we need to pass an event object to it
  getTarget = selectedValue => {
    return {
      target: {
        id: this.props.id,
        value: selectedValue,
        getAttribute: key => {
          return this.input.current.getAttribute(key);
        },
        removeAttribute: key => {
          this.input.current.removeAttribute(key);
        },
      },
    };
  };

  render() {
    let suggestionsListComponent;

    // Show the suggestions if the user has typed something and there are suggestions to show
    if (this.state.showSuggestions && this.props.value && this.state.filteredSuggestions?.length) {
      suggestionsListComponent = (
        <ul className="suggestions">
          {this.state.filteredSuggestions.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === this.state.activeSuggestion) {
              className = "suggestionActive";
            }

            return (
              <li className={className} key={suggestion} onClick={this.handleClick}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
    // If there are no suggestions to show, add a hidden suggestions list to the DOM
    else {
      suggestionsListComponent = <ul className="suggestions hidden"></ul>;
    }

    return (
      <div className="suggestionsParent" ref={this.component}>
        <input
          type={this.props.type}
          name={this.props.name}
          id={this.props.id}
          ref={this.input}
          data-testid={this.props.datatestid}
          placeholder={this.props.placeholder}
          autoComplete="off"
          maxLength={this.props.maxLength}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={event => {
            Helper.handleFocus(event);
          }}
          onKeyDown={this.handleKeyDown}
          value={this.props.value}
        />
        {suggestionsListComponent}
      </div>
    );
  }
}

export default Autocomplete;

import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

// Functions
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";

class CampaignList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      hideFilter: true,
    };
  }

  getHeaderRowItems = () => {
    return [
      {
        classes: "header firstLeft",
        columnheading: "Campaign",
      },
      {
        classes: "header desktop",
        columnheading: "Description",
      },
      {
        classes: "header centerAligned",
        columnheading: "Link",
      },
      {
        classes: "header desktop centerAligned",
        columnheading: "Status",
      },
    ];
  };

  renderItemToColumns = item => {
    const campaignname = <span>{item.campaignname}</span>;
    const description = (
      <span>
        {item.description.slice(0, 125)}
        {item.description.length > 125 ? "..." : ""}
      </span>
    );
    const campaignstatus = <span>{Helper.renderStatus(item.campaignstatus)}</span>;
    const url = Helper.getCampaignLink(item.campaignstatus !== Constants.CAMPAIGN_STATUS_PUBLISHED, item.subdomain, item.campaignuuid);
    const icon = item.copied ? (
      <FontAwesomeIcon
        icon={faCheck}
        onClick={event => {
          event.stopPropagation();

          this.setState(
            prevState => ({
              listItems: prevState.listItems.map(i => {
                if (i.uuid === item.uuid) {
                  i.copied = true;
                }
                return i;
              }),
            }),
            () => {
              setTimeout(() => {
                this.setState(prevState => ({
                  listItems: prevState.listItems.map(i => {
                    if (i.uuid === item.uuid) {
                      i.copied = false;
                    }
                    return i;
                  }),
                }));
              }, 1500);
            }
          );
          Helper.copyToClipboard(url);
        }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faCopy}
        onClick={event => {
          event.stopPropagation();
          this.setState(
            prevState => ({
              listItems: prevState.listItems.map(i => {
                if (i.uuid === item.uuid) {
                  i.copied = true;
                }
                return i;
              }),
            }),
            () => {
              setTimeout(() => {
                this.setState(prevState => ({
                  listItems: prevState.listItems.map(i => {
                    if (i.uuid === item.uuid) {
                      i.copied = false;
                    }
                    return i;
                  }),
                }));
              }, 1500);
            }
          );
          Helper.copyToClipboard(url);
        }}
      />
    );
    return [
      { rowvalue: campaignname },
      { rowvalue: description, classes: "desktop" },
      { rowvalue: icon, classes: "centerAligned" },
      { rowvalue: campaignstatus, classes: "desktop" },
    ];
  };

  renderTopControlButtons = () => {
    let activeTabClassName = "action-tab";
    let inactiveTabClassName = "action-tab";
    if (this.props.filtertype.tab === Constants.TAB_ACTIVE) {
      activeTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.TAB_INACTIVE) {
      inactiveTabClassName += " selected ";
    }

    return (
      <React.Fragment>
        <div className="orderlisttopcontrols">
          <span
            className={activeTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TAB_ACTIVE });
              this.handleFilterList({ tab: Constants.TAB_ACTIVE });
            }}
          >
            Active
          </span>
          <span
            className={inactiveTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TAB_INACTIVE });
              this.handleFilterList({ tab: Constants.TAB_INACTIVE });
            }}
          >
            Archive
          </span>
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance"></span>
      </React.Fragment>
    );
  };

  getListGridClassName = () => {
    if (this.props.filtertype.tab === Constants.TAB_ACTIVE) {
      return "campaignlist active";
    } else if (this.props.filtertype.tab === Constants.TAB_INACTIVE) {
      return "campaignlist inactive";
    }
  };
}
export default CampaignList;

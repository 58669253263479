import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import BaseListViewComponent from "./BaseListViewComponent";
import TagList from "./TagList";
import Tooltip from "./Tooltip";

class SupplierList extends BaseListViewComponent {
  getHeaderRowItems = () => {
    return [
      {
        classes: "header list-header1 firstLeft",
        sortable: true,
        columnheading: "Name",
        sortkey: "companies.companyname",
      },
      {
        classes: "header desktop expander",
        sortable: false,
        columnheading: "",
      },
      {
        classes: "header ",
        sortable: true,
        columnheading: "Contact",
        sortkey: "contacts.firstname,contacts.lastname",
      },
      {
        classes: "header desktop",
        sortable: true,
        columnheading: "Email",
        sortkey: "contacts.email",
      },
      {
        classes: "header desktop",
        sortable: true,
        columnheading: "Primary",
        sortkey: "contacts.mobilephone",
      },
      {
        classes: "header desktop lastRight",
        sortable: true,
        columnheading: "Address",
        sortkey: "contacts.address1,contacts.address2",
      },
    ];
  };

  renderItemToColumns = item => {
    let recordnumber = "";
    let expander = false;
    let fullname = "-";
    let email = "-";
    let contactphone = "-";
    let contactaddress = "-";
    let fulladdress = "";
    let companyname = "";
    if (item.type === Constants.COMPANY) {
      recordnumber = item.recordnumber;
      companyname = item.companyname;
      if (item.tags.length > 0) {
        const icon = <FontAwesomeIcon icon={faTag} />;
        let tagListElement = <TagList tags={item.tags} readonly={true} />;
        let tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
        companyname = (
          <span>
            {companyname} {tooltip}
          </span>
        );
      }
      if (item.contacts.length > 0) {
        const contact = item.contacts[0];
        fullname = contact.firstname + " " + contact.lastname;
        email = contact.email;
        contactphone = contact.mobilephone ? contact.mobilephone : contact.otherphone;
        contactaddress = Helper.renderShortAddress(contact);
        fulladdress = Helper.renderFullAddress(contact);
      }
      if (item.contacts.length > 1) {
        expander = true;
      }
    } else {
      recordnumber = "";
      fullname = item.firstname + " " + item.lastname;
      email = item.email;
      contactphone = item.mobilephone ? item.mobilephone : item.otherphone;
      contactaddress = item.address1;
      fulladdress = Helper.renderFullAddress(item);
    }
    return [
      { rowvalue: recordnumber, classes: "right-aligned recordnumber" },
      { rowvalue: companyname },
      { rowvalue: "", classes: "desktop expander", expander: expander },
      { rowvalue: fullname },
      { rowvalue: email, classes: "desktop", isEmail: true },
      { rowvalue: contactphone, classes: "desktop" },
      { rowvalue: contactaddress, classes: "desktop tooltip", tooltipvalue: fulladdress },
    ];
  };

  maybeExpandRecords = records => {
    const newrecords = [];
    records.forEach(record => {
      record.collapsed = false;
      newrecords.push(record);
      if (record.contacts && record.contacts.length > 1) {
        record.contacts.forEach((contact, index) => {
          if (index > 0) {
            contact.type = Constants.CONTACT;
            contact.parentuuid = record.uuid;
            contact.collapsed = true;
            newrecords.push(contact);
          }
        });
      }
    });
    return newrecords;
  };

  getListGridClassName = () => {
    return "supplierlist";
  };
}
export default SupplierList;

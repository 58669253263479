import React from "react";
import * as Constants from "./Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

class TagList extends React.Component {
  render = () => {
    if (!this.props.tags) {
      return "";
    }

    const tagElements = this.props.tags.map((tag, index) => {
      const myStyle = {
        borderColor: tag.tagcolor,
      };
      const key = index + "-" + tag.taguuid;
      if (tag.isEditing) {
        return (
          <span className="tag editing" style={myStyle} key={key} data-key={key}>
            <input
              type="text"
              value={tag.tagname}
              placeholder="Tag Name"
              autoFocus={true}
              onChange={event => {
                this.props.handleChangeTag(event, index, "tagname");
              }}
              onKeyDown={event => {
                if (event.key === "Enter") {
                  this.props.handleSaveTag(tag, index);
                } else if (event.key === "Escape") {
                  this.props.handleCancelTag(index);
                }
              }}
            />
            <span className="tagEditControls">
              <input
                type="color"
                value={tag.tagcolor}
                onChange={event => {
                  this.props.handleChangeTag(event, index, "tagcolor");
                }}
              />
              <span className="tagSave" data-testid="Save Tag">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  onClick={event => {
                    this.props.handleSaveTag(tag, index);
                  }}
                />
              </span>
              <span className="tagCancel">
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  onClick={() => {
                    this.props.handleCancelTag(index);
                  }}
                />
              </span>
            </span>
          </span>
        );
      } else {
        const handleEditTag = this.props.readonly
          ? null
          : () => {
              this.props.handleEditTag(index);
            };
        const handleTouchStart = this.props.readonly
          ? null
          : event => {
              this.handleTouchStart(event, index);
            };
        const handleTouchEnd = this.props.readonly
          ? null
          : event => {
              this.handleTouchEnd(event);
            };
        const deleteIcon = this.props.readonly ? (
          ""
        ) : (
          <span className="tagDelete">
            <FontAwesomeIcon
              icon={faTrashCan}
              onClick={() => {
                this.props.handleDeleteTag(tag.taguuid);
              }}
            />
          </span>
        );
        return (
          <span className="tag" style={myStyle} key={key} data-key={key} onDoubleClick={handleEditTag}>
            <span className="tagText" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
              {tag.tagname}
            </span>
            {deleteIcon}
          </span>
        );
      }
    });

    return (
      <div className="tagContainer">
        <div className="tagElementContainer">{tagElements}</div>
      </div>
    );
  };

  handleTouchStart = (event, index) => {
    const touchEvent = {
      time: Date.now(),
      index: index,
      event: event,
    };
    this.touchEvent = touchEvent;
    setTimeout(() => {
      this.maybeEditItem(event, index);
    }, Constants.EDIT_DELAY);
  };

  maybeEditItem = (event, index) => {
    // If this touch event was handled by the "touch up" event, then ignore the timer event
    if (!this.touchEvent) {
      return;
    }

    // Grab the current touch event and clear it out from this
    const touchEvent = this.touchEvent;
    this.touchEvent = null;

    // If the touch moved a significant distance, then ignore it
    const start = touchEvent.event.changedTouches[0];
    const end = event.changedTouches[0];
    const distance = Math.sqrt(start.clientX - end.clientX + (start.clientY - end.clientY));
    if (isNaN(distance) || distance > 20) {
      return;
    }

    // Edit the item
    if (touchEvent.index === index) {
      this.props.handleEditTag(index);
    }
  };

  handleTouchEnd = event => {
    if (event.cancelable) {
      event.preventDefault();
    }
    this.touchEvent = null;
  };
}

export default TagList;

function render(props) {
  return (
    <div className="circle-button" onClick={props.handleClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <g id="Group_683" data-name="Group 683" transform="translate(-381.676 -215.176)">
          <g
            id="Component_236_1"
            data-name="Component 236 - 1"
            transform="translate(381.676 215.176)"
          >
            <circle
              id="Ellipse_4"
              data-name="Ellipse 4"
              cx="16"
              cy="16"
              r="16"
              transform="translate(1.324 1.824)"
              strokeWidth="0"
            />
          </g>
          <line
            id="Line_4"
            data-name="Line 4"
            x1="8.7"
            y2="8.7"
            transform="translate(395 228.1)"
            fill="none"
            strokeWidth="2.1"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            x1="8.7"
            y2="8.7"
            transform="translate(403.7 228.1) rotate(90)"
            fill="none"
            strokeWidth="2.1"
          />
        </g>
      </svg>
    </div>
  );
}
export default render;

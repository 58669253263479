import React from "react";

import { animated, useSpring } from "@react-spring/web";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Capo from "../img/Capo.js";

function DemoWidget(props) {
  const [expanded, setExpanded] = React.useState(false);
  const openAnimation = useSpring({
    from: { maxHeight: "6em" },
    to: { maxHeight: expanded ? "100em" : "6em" },
    config: { duration: "500" },
  });
  const title = "Welcome Guest!";
  const expanderIcon = expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />;
  const expander = (
    <div className="dbwExpander" onClick={() => setExpanded(!expanded)}>
      {expanderIcon}
    </div>
  );
  return (
    <animated.div className="dashboardWidget dbwDemo" onClick={() => setExpanded(!expanded)} style={openAnimation}>
      <div className="dbwTitle">
        <span className="newsLogo">
          <Capo />
        </span>
        <span>{title}</span>
      </div>
      <hr />
      <div>
        We're excited to have you on board and we've activated most of our features for you to explore. From managing your inventory to processing
        sales and repairs, our app is designed to streamline your business operations and help you succeed.
      </div>
      <div>
        Please note that while you have access to most of our features, some functionality requires a merchant account. But don't worry, there's still
        plenty you can do!
      </div>
      <div>
        If you're interested in unlocking the full range of features, you can easily sign up for a full subscription. Our subscription plans are
        designed to offer you additional benefits and expanded capabilities to help your business grow even further.
      </div>
      <div>
        If you have any questions or need assistance, our support team is here to help. Simply reach out to us as (912) 764-3230 and we'll be happy to
        assist you.
      </div>
      <div>
        One last important note: As a security measure, please be aware that at the end of your demo period, all of the data you've entered into the
        application will be erased. This is to ensure the privacy and protection of your information. However, if you decide to sign up for a full
        subscription, rest assured that your data will be securely stored and protected. We value your trust and are committed to keeping your data
        safe throughout your journey with us.
      </div>
      {expander}
    </animated.div>
  );
}
export default DemoWidget;

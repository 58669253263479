import React from "react";

import * as Constants from "./Constants";

// Style
import "./css/wheelmenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

class WheelMenu extends React.Component {
  constructor(props) {
    super(props);

    // Define the menu items and their attributes
    this.state = {
      centerMenuItem: props.currentMenu,
      rotation: 0,
    };
  }

  componentDidMount() {}

  render = () => {
    return (
      <div id="wheel-clipper" className="">
        <div id="wheel-wrapper" className="">
          <div id="wheel-spinner" onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd}>
            <div id="wheel-menu">{this.renderMenuIcons()}</div>
            <div data-testid="Gear" id="menu-gear" onClick={() => this.props.handleMenuClick("Gear")}>
              <FontAwesomeIcon icon={faGear} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMenuIcons = () => {
    // Find the "center" menu item and display at clock position 7
    const centerMenuItem = this.props.menuItems.filter(item => item.menu === this.state.centerMenuItem)[0];
    const menuItemCount = this.props.menuItems.length;
    let index = this.props.menuItems.indexOf(centerMenuItem);
    //
    let offset = Constants.WHEEL_ROTATION_OFFSET - index;
    if (offset < 0) {
      offset += menuItemCount;
    }
    const itemsRight = this.props.menuItems.slice(0, this.props.menuItems.length - offset);
    const itemsLeft = this.props.menuItems.slice(this.props.menuItems.length - offset);
    const menuItems = itemsLeft.concat(itemsRight);
    const output = menuItems.map((item, index) => {
      let position = index + this.state.rotation;
      while (position < 0) {
        position += menuItemCount;
      }
      while (position > menuItemCount - 1) {
        position -= menuItemCount;
      }
      // The classnames m0, m1, m2, m3, m4, m5, m6, m7, m8, m9, m10, and m11
      // are dymanically generated, so I'm listing them here to show that the are "in use"
      let className = "m" + position;
      if (item.menu === this.state.centerMenuItem) {
        className += " selected-menu";
      }
      return (
        <span
          key={item.key}
          data-testid={item.menu}
          className={className}
          onClick={() => {
            this.props.hideOverlay();
            this.props.handleMenuClick(item.menu);
          }}
        >
          {item.svg}
        </span>
      );
    });
    return output;
  };

  handleTouchStart = event => {
    if (event.target.id !== "wheel-spinner") {
      return;
    }
    this.touchX = event.touches[0].screenX;
    this.touchY = event.touches[0].screenY;
  };

  handleTouchMove = event => {
    if (!this.touchX || !this.touchY) {
      return;
    }

    // Calculate how far the touch has moved from the starting point
    const diffX = this.touchX - event.touches[0].screenX;
    const diffY = this.touchY - event.touches[0].screenY;
    const distance = Math.sqrt(diffX * diffX + diffY * diffY);

    // If the distance moved exceeds the width of an menu icon, then rotate wheel
    if (distance > Constants.WHEEL_SPINNER_THRESHOLD) {
      // Get the current mouse location
      this.touchX = event.touches[0].screenX;
      this.touchY = event.touches[0].screenY;

      // Determine touch/drag direction based on positive/negative sign of the
      //    largest movement along an axis (x or y)
      const majorAxis = Math.max(Math.abs(diffX), Math.abs(diffY));
      let majorValue;
      if (majorAxis === diffX || majorAxis === Math.abs(diffX)) {
        // X is the major axis
        majorValue = diffX;
      } else {
        // Y is the major axis
        majorValue = diffY;
      }

      // If the touch moved in a positive direction rotate clockwise
      if (majorValue > 0) {
        const rot = this.state.rotation + 1;
        this.setState({ rotation: rot });
        // If the touch moved in a negative direction rotate counter-clockwise
      } else {
        const rot = this.state.rotation - 1;
        this.setState({ rotation: rot });
      }
    }
  };

  handleTouchEnd = event => {
    this.touchX = null;
    this.touchY = null;
  };

  unhideIcons = () => {
    this.m0ref.current.classList.remove("hide");
    this.m1ref.current.classList.remove("hide");
    this.m2ref.current.classList.remove("hide");
    this.m3ref.current.classList.remove("hide");
    this.m4ref.current.classList.remove("hide");
  };

  hideIcons = () => {
    this.m0ref.current.classList.add("hide");
    this.m1ref.current.classList.add("hide");
    this.m2ref.current.classList.add("hide");
    this.m3ref.current.classList.add("hide");
    this.m4ref.current.classList.add("hide");
  };
}
export default WheelMenu;

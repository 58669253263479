function render(props) {
  return (
    <svg width="46" height="40" viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9393 27.0607C22.5251 27.6464 23.4749 27.6464 24.0607 27.0607L33.6066 17.5147C34.1924 16.9289 34.1924 15.9792 33.6066 15.3934C33.0208 14.8076 32.0711 14.8076 31.4853 15.3934L23 23.8787L14.5147 15.3934C13.9289 14.8076 12.9792 14.8076 12.3934 15.3934C11.8076 15.9792 11.8076 16.9289 12.3934 17.5147L21.9393 27.0607ZM21.5 1V26H24.5V1L21.5 1Z" />
      <rect x="3" y="36" width="40" height="3" />
      <rect x="41" y="25" width="3" height="14" />
      <rect x="1" y="25" width="3" height="14" />
    </svg>
  );
}
export default render;

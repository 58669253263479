import React from "react";

import { faPencil, faSpinner, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import CircleXmarkIcon from "./img/CircleXmarkIcon";
import FlexInput from "./FlexInput";
import ListHeaderView from "./ListHeaderView";
import ListItemView from "./ListItemView";
import numeral from "numeral";
import TagList from "./TagList";
import Tooltip from "./Tooltip";

// View for editing a Order.
class Order extends BaseDetailViewComponent {
  renderContactSearchResultsToColumns = item => {
    let fullname = "";
    let mobileviewphone = "";
    let companyname = "";
    fullname = item.firstname + " " + item.lastname;
    // Display mobilephone, then otherphone (if no mobile), then "-" if neither
    mobileviewphone = item.mobilephone ? item.mobilephone : item.otherphone ? item.otherphone : "";
    mobileviewphone = mobileviewphone ? mobileviewphone : item.email;
    companyname = item.companyname ? item.companyname : "";
    fullname = <span data-testid={"Customer Search Results: " + fullname}>{fullname}</span>;
    return [{ rowvalue: fullname }, { rowvalue: mobileviewphone }, { rowvalue: companyname, classes: "desktop" }];
  };

  renderProductList = () => {
    const headers = [
      { classes: "header", columnheading: "Product" },
      { classes: "header desktop noPrint", columnheading: "Associate" },
      {
        classes: "header right-aligned",
        columnheading: this.state.showInventory ? "Qty / Inv" : "Qty",
        eyeball: true,
        eyeballSlashed: this.state.showInventory,
        handleToggleEyeball: this.handleToggleInventory,
      },
      {
        classes: "header right-aligned desktop",
        columnheading: this.state.showCost ? "Cost" : "Price",
      },
      { classes: "header right-aligned desktop", columnheading: this.renderDiscountColumnHeaderLabel() },
      {
        classes: "header right-aligned",
        columnheading: "Total",
        eyeball: true,
        eyeballSlashed: this.state.showCost,
        handleToggleEyeball: this.handleToggleCost,
      },
      { classes: "header centerAligned desktop", columnheading: "Status" },
      { classes: "headerlast", columnheading: "" },
    ];
    return (
      <div className="areaProductList">
        <div className="orderproductlist">
          <ListHeaderView headerRowItems={headers} />
          <hr className="headerRule6" />
          <ListItemView
            listitems={this.state.order.orderitems}
            expandedListItems={null}
            selectedListItems={this.state.selectedOrderItems}
            renderItemToColumns={this.renderSelectedProductToColumns}
            toggleCollapsed={() => {}}
            selectListItem={this.selectOrderItem}
            handleEditItem={() => {}}
            handleTouchStart={this.handleTouchStart}
            handleTouchEnd={this.handleTouchEnd}
          />
        </div>
      </div>
    );
  };

  renderSelectedProductToColumns = item => {
    const lineitemstatus = <span data-testid="Line Item Status">{Helper.renderStatus(item.lineitemstatus)}</span>;
    let productSellPrice;
    if (!this.state.showCost) {
      productSellPrice = (
        <div className="areaInputItem">
          <FlexInput
            type="text"
            inputMode="numeric"
            name="sellprice"
            id="sellprice"
            datatestid="Line Item Sell Price"
            autoComplete="off"
            maxLength={13}
            onChange={event =>
              this.handleChange(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid
              )
            }
            onFocus={Helper.handleFocus}
            onBlur={event =>
              this.handleBlur(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid,
                null,
                "float" // datatype
              )
            }
            value={item.sellprice}
          />
        </div>
      );
    } else {
      productSellPrice = (
        <div className="areaInputItem">
          <FlexInput
            type="text"
            inputMode="numeric"
            name="cost"
            id="cost"
            datatestid="Line Item Cost"
            autoComplete="off"
            maxLength={13}
            onChange={event =>
              this.handleChange(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid
              )
            }
            onFocus={Helper.handleFocus}
            onBlur={event =>
              this.handleBlur(
                event,
                Constants.ORDER_ITEM,
                // Pass either the orderitemuuid (existing) or productuuid (new order)
                item.orderitemuuid ? item.orderitemuuid : item.uuid,
                null,
                "float" // datatype
              )
            }
            value={item.cost}
          />
        </div>
      );
    }
    let renderedValue = item.productname;
    if (item.tags?.length > 0) {
      const icon = <FontAwesomeIcon icon={faTag} />;
      let tagListElement = <TagList tags={item.tags} readonly={true} />;
      let tooltip = <Tooltip text={icon} tooltip={tagListElement} wrapperClasses="tagListParent" />;
      renderedValue = (
        <span>
          {item.productname} {tooltip}
        </span>
      );
    }
    let productQuantity = (
      <div className="areaInputItem">
        <FlexInput
          type="text"
          inputMode="numeric"
          name="quantity"
          id="quantity"
          datatestid="Line Item Quantity"
          autoComplete="off"
          maxLength={13}
          onChange={event =>
            this.handleChange(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid
            )
          }
          onFocus={Helper.handleFocus}
          onBlur={event =>
            this.handleBlur(
              event,
              Constants.ORDER_ITEM,
              // Pass either the orderitemuuid (existing) or productuuid (new order)
              item.orderitemuuid ? item.orderitemuuid : item.uuid,
              null,
              "float" // datatype
            )
          }
          value={item.quantity}
        />
      </div>
    );
    if (this.state.showInventory) {
      if (item.affectinventory) {
        productQuantity = item.quantity + " / " + item.inventory;
      } else {
        productQuantity = item.quantity + " / -";
      }
    }
    const associateSpinner = item.associateUpdating ? (
      <span className="noOpacity">
        <FontAwesomeIcon icon={faSpinner} spin />
      </span>
    ) : (
      ""
    );
    const associate = (
      <span
        data-testid="Line Item Associate"
        className="associatePicker nowrap"
        onClick={event => {
          this.handleAssociateSelection(event, item.uuid);
        }}
      >
        {associateSpinner} {item.associate} <FontAwesomeIcon icon={faPencil} />
      </span>
    );

    return [
      {
        rowvalue: (
          <div className="areaInputItem" data-testid="Order Order Item">
            <FlexInput
              pencilEdit={true}
              type="text"
              textarea={true}
              name="productname"
              id="productname"
              datatestid="Line Item Product Name"
              autoComplete="off"
              rows="4"
              maxLength={255}
              onChange={event =>
                this.handleChange(
                  event,
                  Constants.ORDER_ITEM,
                  // Pass either the orderitemuuid (existing) or productuuid (new order)
                  item.orderitemuuid ? item.orderitemuuid : item.uuid
                )
              }
              onFocus={Helper.handleFocus}
              onBlur={event =>
                this.handleBlur(
                  event,
                  Constants.ORDER_ITEM,
                  // Pass either the orderitemuuid (existing) or productuuid (new order)
                  item.orderitemuuid ? item.orderitemuuid : item.uuid,
                  null,
                  "text" // datatype
                )
              }
              value={item.productname}
              renderedValue={renderedValue}
            />
          </div>
        ),
      },
      { rowvalue: associate, classes: "desktop noPrint" },
      {
        rowvalue: productQuantity,
        classes: "right-aligned",
      },
      {
        rowvalue: productSellPrice,
        classes: "desktop right-aligned",
      },
      {
        rowvalue: (
          <div className="areaInputItem">
            <FlexInput
              type="text"
              inputMode="numeric"
              name="discount"
              id="discount"
              datatestid="Line Item Discount"
              autoComplete="off"
              maxLength={13}
              onChange={event =>
                this.handleChange(
                  event,
                  Constants.ORDER_ITEM,
                  // Pass either the orderitemuuid (existing) or productuuid (new order)
                  item.orderitemuuid ? item.orderitemuuid : item.uuid
                )
              }
              onFocus={Helper.handleFocus}
              onBlur={event =>
                this.handleBlur(
                  event,
                  Constants.ORDER_ITEM,
                  // Pass either the orderitemuuid (existing) or productuuid (new order)
                  item.orderitemuuid ? item.orderitemuuid : item.uuid,
                  null,
                  "float" // datatype
                )
              }
              value={item.discount}
            />
          </div>
        ),
        classes: "desktop right-aligned",
      },
      {
        rowvalue: (
          <span data-testid={this.state.showCost ? "Line Item Total Cost" : "Line Item Total Price"}>
            {numeral(this.state.showCost ? item.totalcost : item.totalprice).format(Constants.CURRENCY)}
          </span>
        ),
        classes: "right-aligned",
      },
      {
        rowvalue: lineitemstatus,
        classes: "desktop right-aligned",
      },
      {
        rowvalue: (
          <span
            data-testid="Line Item Delete Button"
            onClick={event => {
              if (event && event.stopPropagation) {
                event.stopPropagation();
              }
              this.handleDeleteOrderItem(item.orderitemuuid ?? item.uuid);
            }}
          >
            <CircleXmarkIcon />
          </span>
        ),
        classes: "right-aligned circlebuttoncontainer",
      },
    ];
  };

  renderProductListNarrow = () => {
    const headers = [
      { classes: "header", columnheading: "Product" },
      { classes: "header right-aligned", columnheading: "Qty" },
      { classes: "header right-aligned", columnheading: "Each" },
      { classes: "header right-aligned", columnheading: "Total" },
    ];
    return (
      <div className="areaProductListNarrow">
        <div className="productListGrid orderproductlist">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={this.state.order.orderitems}
            expandedListItems={null}
            selectedListItems={this.state.selectedOrderItems}
            renderItemToColumns={this.renderSelectedProductToColumnsNarrow}
            toggleCollapsed={() => {}}
            selectListItem={this.selectOrderItem}
            handleEditItem={() => {}}
            handleTouchStart={this.handleTouchStart}
            handleTouchEnd={this.handleTouchEnd}
          />
        </div>
      </div>
    );
  };

  renderSelectedProductToColumnsNarrow = item => {
    const each = item.sellprice - item.discount;
    return [
      { rowvalue: item.productname },
      {
        rowvalue: numeral(item.quantity).format(Constants.DECIMAL_VALUE),
        classes: "right-aligned",
      },
      { rowvalue: numeral(each).format(Constants.CURRENCY), classes: "desktop right-aligned" },
      { rowvalue: numeral(item.totalprice).format(Constants.CURRENCY), classes: "right-aligned" },
    ];
  };

  handleChangeCustomer = (response, contact = null) => {
    if (response === Constants.OVERLAY_RESPONSE_CREATE) {
      // Show the "Create a New Contact" overlay
    } else if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      this.selectCustomerListItem(contact);
    }
  };

  getOrderType = () => {
    return Constants.ORDER;
  };
}
export default Order;

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudBolt } from "@fortawesome/free-solid-svg-icons";

function CloudIcon(props) {
  if (props.downloading) {
    return (
      <span className="cloud-icon" data-testid="Cloud Icon">
        <FontAwesomeIcon icon={faCloudBolt} />
      </span>
    );
  } else {
    return "";
  }
}

export default CloudIcon;

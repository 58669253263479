// Components
import BaseListViewComponent from "./BaseListViewComponent";

class ReportList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      hideFilter: true,
    };
  }
  getHeaderRowItems = () => {
    return [
      {
        classes: "header firstLeft",
        columnheading: "Report Name",
      },
      {
        classes: "header lastRight",
        columnheading: "Description",
      },
    ];
  };

  renderItemToColumns = item => {
    return [
      // Mobile date view:
      { rowvalue: item.reportname },
      { rowvalue: item.reportdescription },
    ];
  };

  renderTopControlButtons = () => {
    return "";
  };

  getListGridClassName = () => {
    return "reportlist";
  };
}
export default ReportList;

function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 393.85 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 68">
            <path data-name="Path 75" d="M370.75,276.92H312.29V300h81.56V219.22H370.77Z" />
            <path data-name="Path 76" d="M0,300H81.56V276.92H23.1v-57.7H0Z" />
            <path data-name="Path 77" d="M81.56,0H0V80.77H23.08V23.07H81.54Z" />
            <path data-name="Path 78" d="M312.29,23.07h58.46v57.7h23.08V0H312.27Z" />
            <rect data-name="Rectangle 38" x="46.9" y="46.15" width="23.08" height="207.69" />
            <rect data-name="Rectangle 39" x="93.06" y="46.15" width="23.08" height="161.54" />
            <rect data-name="Rectangle 40" x="139.21" y="46.15" width="23.08" height="161.54" />
            <rect data-name="Rectangle 41" x="185.36" y="46.15" width="23.08" height="207.69" />
            <rect data-name="Rectangle 42" x="231.53" y="46.15" width="23.08" height="161.54" />
            <rect data-name="Rectangle 43" x="277.67" y="46.15" width="23.08" height="161.54" />
            <rect data-name="Rectangle 44" x="323.83" y="46.15" width="23.08" height="207.69" />
            <rect data-name="Rectangle 45" x="93.06" y="230.77" width="23.08" height="23.08" />
            <rect data-name="Rectangle 46" x="139.21" y="230.77" width="23.08" height="23.08" />
            <rect data-name="Rectangle 47" x="231.53" y="230.77" width="23.08" height="23.08" />
            <rect data-name="Rectangle 48" x="277.67" y="230.77" width="23.08" height="23.08" />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

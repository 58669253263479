import React from "react";

import Capo from "./img/Capo.js";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true }, () => {
      this.props.hideOverlay();
    });
    // TODO: Log the error. Notify someone.
  }

  render = () => {
    if (this.state.hasError) {
      // TODO: Render custom error message
      return (
        <div className="errorBoundary">
          <div className="gridCenter">
            <h1 className="centerAligned">Uh oh! Something happened and we can't render the page.</h1>
          </div>
          <div className="gridCenter">
            <span id="control-cancel" className="action-button red-button" onClick={this.handleReset}>
              Click here to reset.
            </span>
          </div>
          <div className="errorPup gridCenter">
            <Capo />
          </div>
        </div>
      );
    }
    return this.props.children;
  };

  handleReset = () => {
    this.setState({ hasError: false });
    this.props.resetState();
  };
}
export default ErrorBoundary;

import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseWidget from "./BaseWidget";
import * as Helper from "../Helper";
import TextareaAutosize from "react-textarea-autosize";

class MessageWidget extends BaseWidget {
  renderWidgetView = () => {
    const loading =
      this.props.widget?.data === null ? (
        <span>
          Loading... <FontAwesomeIcon icon={faSpinner} spin />
        </span>
      ) : (
        ""
      );
    const body = this.props.widget?.widgetconfig?.message || loading;
    return <div>{body}</div>;
  };

  renderWidgetEdit = () => {
    return (
      <React.Fragment>
        <div className="areaInputItem">
          <TextareaAutosize
            id="message"
            name="message"
            placeholder="Enter message here . . ."
            rows={3}
            maxLength={255}
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChange(event, this.state.widget, true);
            }}
            onBlur={() => {}}
            value={this.state.widget.widgetconfig.message}
          />
        </div>
      </React.Fragment>
    );
  };
}
export default MessageWidget;

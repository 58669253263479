function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="4.67 0 33.67 38.13">
      <g id="Group_222" data-name="Group 222" transform="translate(-503 -9.559)">
        <g id="Group_71" data-name="Group 71" transform="translate(507.665 9.559)">
          <path
            id="Path_83"
            data-name="Path 83"
            d="M2015.729,14v7.75h7.75Z"
            transform="translate(-2000.229 -13.996)"
          />
          <path
            id="Path_84"
            data-name="Path 84"
            d="M2015.729,23.684a1.941,1.941,0,0,1-1.938-1.938V14h-11.625a1.94,1.94,0,0,0-1.937,1.938V43.059A1.939,1.939,0,0,0,2002.166,45h10.125V41.121h-7.219a.969.969,0,0,1,0-1.937h7.219V37.246h-7.219a.969.969,0,0,1,0-1.937h7.219v-.746a3.762,3.762,0,0,1,3.757-3.758h7.431V23.684Zm-6.782,7.63V32.4a.969.969,0,0,1-1.937,0V31.312a3.981,3.981,0,0,1-1.639-.773.969.969,0,0,1,1.222-1.5,2.187,2.187,0,0,0,1.386.461c1.05,0,1.937-.664,1.937-1.453s-.887-1.453-1.937-1.453a3.66,3.66,0,0,1-3.875-3.39,3.508,3.508,0,0,1,2.906-3.271V18.84a.969.969,0,1,1,1.937,0v1.091a3.982,3.982,0,0,1,1.639.773.968.968,0,0,1-1.222,1.5,2.195,2.195,0,0,0-1.385-.46c-1.051,0-1.938.665-1.938,1.454s.887,1.453,1.938,1.453a3.661,3.661,0,0,1,3.875,3.39A3.509,3.509,0,0,1,2008.947,31.314Zm9.688-1.818h-3.875a.969.969,0,1,1,0-1.937h3.875a.969.969,0,1,1,0,1.937Z"
            transform="translate(-2000.229 -13.996)"
          />
          <g id="Group_70" data-name="Group 70" transform="translate(13.972 18.435)">
            <path
              id="Path_85"
              data-name="Path 85"
              d="M2016.458,32.431a2.257,2.257,0,0,0-2.257,2.257v7.181h9.439V32.431Zm3.694,5.54h-.821v.821a.616.616,0,0,1-1.231,0v-.821h-.821a.615.615,0,0,1,0-1.231h.821v-.821a.616.616,0,1,1,1.231,0v.821h.821a.615.615,0,0,1,0,1.231Z"
              transform="translate(-2014.201 -32.431)"
            />
            <path
              id="Path_86"
              data-name="Path 86"
              d="M2014.2,42.69v7.182a2.258,2.258,0,0,0,2.257,2.257h7.182V42.69Zm6.181,5.31a.615.615,0,0,1-.435,1.051.608.608,0,0,1-.435-.181l-.591-.591-.591.591a.615.615,0,0,1-.87-.87l.591-.591-.591-.591a.616.616,0,1,1,.871-.871l.591.591.59-.591a.616.616,0,1,1,.871.871l-.591.591Z"
              transform="translate(-2014.201 -32.431)"
            />
            <path
              id="Path_87"
              data-name="Path 87"
              d="M2031.642,32.431h-7.181v9.438h9.438V34.688A2.256,2.256,0,0,0,2031.642,32.431Zm-.41,5.335h-3.693a.615.615,0,1,1,0-1.231h3.693a.615.615,0,0,1,0,1.231Z"
              transform="translate(-2014.201 -32.431)"
            />
            <path
              id="Path_88"
              data-name="Path 88"
              d="M2024.461,42.69v9.439h7.181a2.257,2.257,0,0,0,2.257-2.257V42.69Zm6.566,6.566h-3.694a.615.615,0,0,1,0-1.231h3.694a.615.615,0,0,1,0,1.231Zm0-2.462h-3.694a.615.615,0,0,1,0-1.231h3.694a.615.615,0,0,1,0,1.231Z"
              transform="translate(-2014.201 -32.431)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

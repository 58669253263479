import React from "react";

import ActionButtons from "./ActionButton";
import SaveCancelButtons from "./SaveCancelButtons";

function ButtonControls(props) {
  if (props.showSaveCancel) {
    let saveButtonClass = "action-button green-button";
    if (!props.isReadyToSubmit()) {
      saveButtonClass += " save-disabled";
    }
    // Show save and cancel if new
    return (
      <SaveCancelButtons
        handleCancel={props.followBreadcrumb}
        handleSave={props.save}
        saveButtonClass={saveButtonClass}
        datatestidcancel={props.datatestidcancel}
        datatestidsave={props.datatestidsave}
      />
    );
  } else {
    return (
      <ActionButtons
        appState={props.appState}
        campaign={props.campaign}
        enableActionButtons={props.enableActionButtons}
        filtertype={props.filtertype}
        handleArchive={props.handleArchive}
        handleCreateGenericOrder={props.handleCreateGenericOrder}
        handleDelete={props.handleDelete}
        handleDeleteBillingPlan={props.handleDeleteBillingPlan}
        handleDraft={props.handleDraft}
        handleEmail={props.handleEmail}
        handleEmailMessagePrompt={props.handleEmailMessagePrompt}
        handleMarkAsPaid={props.handleMarkAsPaid}
        handlePay={props.handlePay}
        handlePreview={props.handlePreview}
        handlePrint={props.handlePrint}
        handleProspectToCustomer={props.handleProspectToCustomer}
        handlePublish={props.handlePublish}
        handleRefund={props.handleRefund}
        handleResendCampaignLinkToProspect={props.handleResendCampaignLinkToProspect}
        handleReturn={props.handleReturn}
        handleSMS={props.handleSMS}
        isProtectedCustomer={props.isProtectedCustomer}
        //TODO Refactor orderstatus and type because we added order object.
        order={props.order}
        product={props.product}
        prospect={props.prospect}
        orderstatus={props.orderstatus}
        ordertype={props.ordertype}
        showOverlay={props.showOverlay}
        totalprice={props.totalprice}
        totalpayments={props.totalpayments}
      />
    );
  }
}

export default ButtonControls;

function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 324.23 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 59">
            <path
              data-name="Path 62"
              d="M284.25,75.1l1.8,73.54A28.44,28.44,0,0,1,271.82,174l-135.7,78.37,80.52,46.34a9.49,9.49,0,0,0,13-3.49l89-154.52a9.47,9.47,0,0,0-.12-9.67Z"
            />
            <path
              data-name="Path 63"
              d="M98.7,154.37a17,17,0,0,0,9.37,5.5,12.74,12.74,0,0,0,9.26-1.79,10.58,10.58,0,0,0,5.28-7.24,26.76,26.76,0,0,0-1-10.89Z"
            />
            <path
              data-name="Path 64"
              d="M137.27,107.84a9.52,9.52,0,0,0-4.84,6.24,24.77,24.77,0,0,0,.56,9.43l20.06-12.58a21.66,21.66,0,0,0-7.74-4.69A9.65,9.65,0,0,0,137.27,107.84Z"
            />
            <path
              data-name="Path 65"
              d="M267.06,149.12l-2.25-91.57a9.5,9.5,0,0,0-4.54-7.88l-8.61-5.24a19.94,19.94,0,0,0-3.46,21.95,28.5,28.5,0,1,1-51.7,24A77.53,77.53,0,0,1,189.34,58a76.48,76.48,0,0,1,13.44-43.4L182.08,1.94a9.45,9.45,0,0,0-9.67-.11L4.75,98.59a9.5,9.5,0,0,0-3.48,13L81.71,250.89a9.49,9.49,0,0,0,13,3.48h0l167.66-96.76A9.51,9.51,0,0,0,267.06,149.12Zm-93.92-41.55-5.66,3.56a39,39,0,0,1,3.73,11.06,22.3,22.3,0,0,1-.84,10.29,26.13,26.13,0,0,1-3.21,7.1,14.82,14.82,0,0,1-4.38,4.54,7.34,7.34,0,0,1-5.74,1,7.44,7.44,0,0,1-4.83-3.44,7.73,7.73,0,0,1,1.37-10.14,14,14,0,0,0,3.46-13.8l-21.79,13.71q2.3,7.62,3.26,12.38a25,25,0,0,1,.08,9.56,23.23,23.23,0,0,1-3.74,9.33,25.58,25.58,0,0,1-7.67,7.27,27.33,27.33,0,0,1-13,4.22,26,26,0,0,1-13.69-2.9,34.67,34.67,0,0,1-12.07-10.47l-13,8.2a14.47,14.47,0,0,1-4.89,2.23,3,3,0,0,1-3.07-1.76,3.5,3.5,0,0,1-.52-3.37,7.89,7.89,0,0,1,3.09-3l14.15-8.89a38.78,38.78,0,0,1-3.87-12.52A29.81,29.81,0,0,1,81,130.61a26.94,26.94,0,0,1,4-8.81A21.22,21.22,0,0,1,91,116a7.52,7.52,0,0,1,5.8-.89,7.74,7.74,0,0,1,5.1,3.63,8.27,8.27,0,0,1,1.41,4.67,6.72,6.72,0,0,1-1.68,4.2,80.16,80.16,0,0,0-5,6.59A15,15,0,0,0,94.39,140a16.75,16.75,0,0,0,.66,7.44l24.36-15.32a75.75,75.75,0,0,1-3.06-14.44,23.36,23.36,0,0,1,2-11.92,26.5,26.5,0,0,1,27.85-13.59,30.34,30.34,0,0,1,17.12,12.36l5.56-3.49q4.39-2.76,6.47.54T173.14,107.57Z"
            />
            <path
              data-name="Path 66"
              d="M222.36,87.88A9.49,9.49,0,0,0,231,74.34a38.92,38.92,0,1,1,72.44-4.51L315.18,89a57.94,57.94,0,1,0-101.44-6.7A9.48,9.48,0,0,0,222.36,87.88Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

function render(props) {
  return (
    <svg className="envelopeSlash" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 318.7 187.22">
      <g>
        <g>
          <path d="M295.7,0H23C10.32,0,0,10.32,0,23V164.22c0,12.68,10.32,23,23,23H295.7c12.68,0,23-10.32,23-23V23c0-12.68-10.32-23-23-23ZM10,164.22V23c0-7.17,5.83-13,13-13H214.94l-42.17,54.11H89.38v14.49h72.09l-6.75,8.67H89.38v14.49h54.05l-6.75,8.67h-47.3v14.49h36l-40.77,52.31H23c-7.17,0-13-5.83-13-13Zm298.7,0c0,7.17-5.83,13-13,13H104.9l40.77-52.31h57.55v-14.49h-46.26l6.75-8.67h39.5v-14.49h-28.21l6.75-8.67h34.97v-14.49h-23.68L235.22,10h60.48c7.17,0,13,5.83,13,13V164.22Z" />
          <rect x="247.2" y="30.7" width="40.65" height="40.65" />
        </g>
      </g>
    </svg>
  );
}
export default render;

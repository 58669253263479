import React from "react";

// Constants
import * as Constants from "./Constants";

// Components
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfinity } from "@fortawesome/free-solid-svg-icons";

// Functions
import * as Helper from "./Helper";
import numeral from "numeral";

// View for editing billing plans
class BillingPlan extends BaseDetailViewComponent {
  constructor(props) {
    super(props);

    // creates cursor focus
    this.planNameInput = React.createRef();

    const plan = props.selectedItem ?? Helper.getBlankBillingPlan();

    plan.plan_duration_unlimited = plan.plan_duration === -1 ? true : false;

    this.state = {
      ...this.state,
      plan: plan,
      viewName: Constants.BILLING,
      isNew: props.selectedItem?.plan_id ? false : true,
      showInfoPlanType: false,
    };
  }

  // Once component is loaded, fire GET request
  componentDidMount() {
    super.componentDidMount();
    this.setState({ loading: false });
    if (this.state.plan.plan_id) {
      this.setState({ isNew: false });
      this.getBillingPlan(this.state.plan.plan_id);
    }
  }

  renderDetailBody = classes => {
    return (
      <div className={classes}>
        <div className="areaIdPanel">{this.renderAreaCloseButton(true)}</div>
        {this.renderHeader()}
        {this.renderPlanDetails()}
        {this.renderAreaButtonControls()}
        {this.renderAreaSubscribe()}
      </div>
    );
  };

  renderHeader = () => {
    return <h1 className="component-title areaBillingHeader">Billing Plan Templates</h1>;
  };

  handleChangeUnlimitedDuration = event => {
    let duration = event.target.checked ? -1 : 1;
    this.setState(
      {
        plan: {
          ...this.state.plan,
          plan_duration: duration,
          plan_duration_unlimited: event.target.checked,
        },
      },
      () => {
        const durationEvent = {
          target: {
            name: "plan_duration",
            id: "plan_duration",
            value: this.state.plan.plan_duration,
            getAttribute: () => {
              return null;
            },
            removeAttribute: () => {},
          },
        };
        this.handleBlur(durationEvent, Constants.BILLING_PLAN);
      }
    );
  };

  handleDelete = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete?",
      callback: this.maybeDeleteBillingPlan,
      key: this.state.plan.plan_id,
    });
  };

  maybeDeleteBillingPlan = (response, plan_id) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteBillingPlan(plan_id);
    }
  };

  // Required fields include plan name, plan description, and plan price
  isReadyToSubmit = () => {
    return (
      this.state.plan.plan_desc &&
      this.state.plan.plan_frequency &&
      this.state.plan.plan_duration &&
      this.state.plan.amt_tran &&
      numeral(this.state.plan.amt_tran).value() > 0
    );
  };

  save = () => {
    // TODO: Run data validation on the input fields
    if (this.isReadyToSubmit()) {
      if (!this.state.plan.plan_id) {
        this.postBillingPlan(Constants.MAAST_RETRIES);
      }
    }
  };

  // 9/18/2023 - Removed "plan_name" and use "plan_desc" as the name of the plan instead
  // because individual and group plans only have plan description in common
  // because individual plans do not have a plan name
  renderPlanDetails = () => {
    return (
      <div className="areaPlanSettings">
        <div className="planInputItem">
          <label className="inputLabel" htmlFor="plan_desc">
            Name
          </label>
          <input
            type="text"
            name="plan_desc"
            id="plan_desc"
            maxLength={255}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChange(event, Constants.BILLING_PLAN);
            }}
            onBlur={event => {
              this.handleBlur(event, Constants.BILLING_PLAN);
            }}
            value={this.state.plan.plan_desc}
          />
          <span className="printOnly">{this.state.plan.plan_desc}</span>
        </div>
        <div className="planInputItem planAmount">
          <label className="inputLabel" htmlFor="amt_tran">
            Amount
          </label>
          <input
            type="text"
            inputMode="numeric"
            name="amt_tran"
            id="amt_tran"
            maxLength={15}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChange(event, Constants.BILLING_PLAN);
            }}
            onBlur={event => {
              this.handleBlur(event, Constants.BILLING_PLAN, null, null, "float");
            }}
            value={this.state.plan.amt_tran}
          />
          <span className="printOnly">{this.state.plan.amt_tran}</span>
        </div>
        <div className="planInputItem">
          <label className="inputLabel" htmlFor="plan_frequency">
            Frequency
          </label>
          <select
            name="plan_frequency"
            id="plan_frequency"
            className="planFrequency"
            onChange={event => {
              this.handleChange(event, Constants.BILLING_PLAN);
            }}
            value={this.state.plan.plan_frequency}
          >
            <option value="0">Weekly</option>
            <option value="1">Bi-weekly</option>
            <option value="3">Monthly</option>
            <option value="4">Quarterly</option>
            <option value="5">Bi-annually</option>
            <option value="6">Annually</option>
            <option value="7">Daily</option>
          </select>
          <div className="printOnly">{Helper.renderMaastFrequency(this.state.plan.plan_frequency)}</div>
        </div>
        <div className="planInputItem planDuration">
          <label className="inputLabel" htmlFor="plan_duration">
            Duration
          </label>
          <input
            type="text"
            name="plan_duration"
            id="plan_duration"
            maxLength={4}
            autoComplete="off"
            onFocus={Helper.handleFocus}
            onChange={event => {
              this.handleChange(event, Constants.BILLING_PLAN);
            }}
            onBlur={event => {
              this.handleBlur(event, Constants.BILLING_PLAN);
            }}
            value={this.state.plan.plan_duration_unlimited ? "Until cancelled" : this.state.plan.plan_duration}
          />
          <span className="printOnly">{this.state.plan.plan_duration_unlimited ? "Until cancelled" : this.state.plan.plan_duration}</span>

          <label className="checkboxContainer" htmlFor="plan_duration_unlimited">
            <FontAwesomeIcon icon={faInfinity} />
            <input
              type="checkbox"
              name="plan_duration_unlimited"
              id="plan_duration_unlimited"
              onChange={this.handleChangeUnlimitedDuration}
              checked={this.state.plan.plan_duration_unlimited}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="planInputItem">
          <label className="inputLabel" htmlFor="plan_type">
            Plan Type
          </label>
          {this.renderPlanType()}
        </div>
      </div>
    );
  };

  renderPlanType = () => {
    if (this.state.isNew) {
      return (
        <React.Fragment>
          <select
            name="plan_type"
            id="plan_type"
            onChange={event => {
              this.handleChange(event, Constants.BILLING_PLAN);
            }}
            value={this.state.plan.plan_type}
          >
            <option value={Constants.PLAN_TYPE_GROUP}>Group</option>
            <option value={Constants.PLAN_TYPE_INDIVIDUAL}>Individual</option>
          </select>
          <div>
            <b>Individual plans</b> are used as a template for creating a unique subscription for each customer. Changes to subscriptions based on an
            Individual plan must be made directly on each subscription.
            <b>Group plans</b> will propagate their changes to all active subscribers.{" "}
          </div>
        </React.Fragment>
      );
    } else {
      return <span>{this.state.plan.is_individual ? "Individual" : "Group"}</span>;
    }
  };
}
export default BillingPlan;

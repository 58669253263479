function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284.57 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 57">
            <g data-name="Group 56">
              <path
                data-name="Path 55"
                d="M215.54,148.63h-70V84.54a5.92,5.92,0,0,0-11.83,0v70a5.81,5.81,0,0,0,4.84,5.69,2,2,0,0,0,1,.23h75.92a5.92,5.92,0,1,0,.48-11.83h-.46Z"
              />
              <path
                data-name="Path 56"
                d="M284.57,144.26a141.46,141.46,0,0,0-19.65-71.8,16.6,16.6,0,0,0,7.25-3.87c8.91-8.94,5.25-27.45-7.8-43.78l1.49-1.48a9.2,9.2,0,0,0-13-13l-1.6,1.59c-16.07-12.43-34-15.76-42.74-7a17.54,17.54,0,0,0-4.67,11.23c-.3-.16-.63-.26-.92-.4A140.08,140.08,0,0,0,95.43,10.06c-2,.69-4,1.42-5.92,2.18-1.53.61-3.05,1.23-4.55,1.93s-3.22,1.25-4.77,2A17.51,17.51,0,0,0,75.51,4.94c-8.7-8.71-26.67-5.4-42.75,7l-1.6-1.6a9.2,9.2,0,0,0-13,13l1.49,1.48c-13,16.32-16.7,34.84-7.75,43.78a16.53,16.53,0,0,0,7.36,3.93c-.69,1.23-1.22,2.54-1.87,3.79-.33.59-.6,1.22-.91,1.83-1.32,2.64-2.71,5.25-3.87,8A141.51,141.51,0,0,0,55.22,256.61L33.94,296.06,44.35,300l30.47-30.47a141.91,141.91,0,0,0,134.68.17L239.77,300,250.19,296,229,256.88A141.88,141.88,0,0,0,284.57,144.26Zm-259.17,0A116.89,116.89,0,1,1,142.29,261.14h0A117,117,0,0,1,25.4,144.26Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

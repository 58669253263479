function CreditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52.534"
      height="33.817"
      viewBox="0 0 52.534 33.817"
    >
      <g id="Group_675" data-name="Group 675" transform="translate(-663.137 -200.469)">
        <path
          id="Path_10"
          data-name="Path 10"
          d="M618.162,422.458h43.047a3.744,3.744,0,0,1,3.743,3.744v24.331a3.743,3.743,0,0,1-3.743,3.743H618.162a3.744,3.744,0,0,1-3.744-3.743V426.2A3.744,3.744,0,0,1,618.162,422.458Z"
          transform="translate(49.719 -220.989)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
        <rect
          id="Rectangle_1"
          data-name="Rectangle 1"
          width="9.358"
          height="7.486"
          transform="translate(668.879 205.174)"
          fill="#fff"
        />
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          width="5.615"
          height="1.872"
          transform="translate(667.943 217.615)"
          fill="#fff"
        />
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="5.615"
          height="1.872"
          transform="translate(677.301 217.615)"
          fill="#fff"
        />
        <rect
          id="Rectangle_6"
          data-name="Rectangle 6"
          width="5.615"
          height="1.872"
          transform="translate(686.659 217.615)"
          fill="#fff"
        />
        <rect
          id="Rectangle_348"
          data-name="Rectangle 348"
          width="5.615"
          height="5.34"
          transform="translate(704.439 213.211)"
          fill="#fff"
        />
        <rect
          id="Rectangle_349"
          data-name="Rectangle 349"
          width="51"
          height="6"
          transform="translate(664 223)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default CreditIcon;

function render(props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 554.3 416.9">
      <path d="M333.2,0c20.9,0,37.9,6.8,46.5,26.9,8.5,20,2.1,37-12.4,52.3-29.2,30.7-58.3,61.5-86.3,93.2-5.8,6.6-9.9,17-10.1,25.8-1.1,38.3-.8,76.6-.3,114.9.2,21-7.1,36.8-25.4,48.1-23.6,14.7-46.7,30.3-69.8,45.8-12.7,8.5-25.5,14.2-40.4,5.8-15.2-8.5-18.5-22.2-18.4-38.4.3-58,.6-116.1-.4-174.1-.2-9.8-4.8-21.5-11.3-28.9-27.5-31.4-56.3-61.8-85.1-92C5.2,64.2-1.9,47.6,6.8,27.4,15.7,6.5,33.2-.1,54.7,0c45.8.3,91.7,0,137.5,0s94,.1,141,0ZM324.1,52.3H64.6c4.3,5.5,7,9.6,10.2,13.2,25.1,27.3,50,54.8,75.8,81.5,10.8,11.2,15.7,22.9,15.5,38.7-.8,48.1-.3,96.3-.3,144.4v23.5c19.5-13.1,36-23.4,51.3-35.1,3.4-2.6,3.7-10.5,3.7-16,.3-40-.7-80.1.8-120,.4-11,5.4-23.9,12.6-32.2,28.1-32.4,57.8-63.3,89.9-97.9h0Z" />
      <path d="M441.7,0h87c13.5,0,24.4,10.9,24.4,24.4h0c0,13.5-10.9,24.4-24.4,24.4h-87c-13.5,0-24.4-10.9-24.4-24.4h0c0-13.5,10.9-24.4,24.4-24.4Z" />
      <rect x="347.6" y="184" width="205.5" height="48.8" rx="24.4" ry="24.4" />
      <path d="M304.6,368.1h224.1c13.5,0,24.4,10.9,24.4,24.4h0c0,13.5-10.9,24.4-24.4,24.4h-224.1c-13.5,0-24.4-10.9-24.4-24.4h0c0-13.5,10.9-24.4,24.4-24.4Z" />
    </svg>
  );
}
export default render;

function render(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60.863"
      height="36.373"
      viewBox="0 0 60.863 36.373"
    >
      <path
        id="Subtraction_1"
        data-name="Subtraction 1"
        d="M57.141,35.373H2.721A2.724,2.724,0,0,1,0,32.652V2.721A2.724,2.724,0,0,1,2.721,0h54.42a2.724,2.724,0,0,1,2.722,2.721V32.652A2.724,2.724,0,0,1,57.141,35.373ZM8.163,2.721a5.4,5.4,0,0,1-1.6,3.843,5.4,5.4,0,0,1-3.843,1.6V27.209a5.4,5.4,0,0,1,3.843,1.6,5.4,5.4,0,0,1,1.6,3.843H51.7a5.458,5.458,0,0,1,5.441-5.442V8.162a5.4,5.4,0,0,1-3.843-1.6,5.4,5.4,0,0,1-1.6-3.843Z"
        transform="translate(0.5 0.5)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        id="Path_333"
        data-name="Path 333"
        d="M526.795,360.037c1.753.592,2.511,1.161,2.511,2.061,0,.948-.924,1.468-2.274,1.468a8.366,8.366,0,0,1-3.932-1.042l-.712,2.771a9.542,9.542,0,0,0,4.028,1.019v2.25h2.321v-2.416c2.724-.474,4.217-2.274,4.217-4.382,0-2.132-1.138-3.435-3.956-4.43-2.014-.758-2.843-1.255-2.843-2.037,0-.664.5-1.326,2.037-1.326a7.843,7.843,0,0,1,3.411.806l.687-2.677a8.539,8.539,0,0,0-3.435-.782v-2.084h-2.32v2.25c-2.534.5-4,2.132-4,4.217C522.531,358,524.261,359.184,526.795,360.037Z"
        transform="translate(-497.24 -340.714)"
        fill="#fff"
        stroke="#fff"
        strokeWidth="1"
      />
    </svg>
  );
}
export default render;

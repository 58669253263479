function render(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97.434"
      height="29.311"
      viewBox="0 0 97.434 29.311"
    >
      <g id="Group_683" data-name="Group 683" transform="translate(-1151.918 -402.845)">
        <g id="Group_33" data-name="Group 33" transform="translate(1179.587 408.644)">
          <path
            id="Path_48"
            data-name="Path 48"
            d="M468.469,572.758h-5.379a1.052,1.052,0,0,0-.791.633l-2.215,13.921a.419.419,0,0,0,.475.475h2.531a1.052,1.052,0,0,0,.791-.633l.633-3.8a.724.724,0,0,1,.791-.633h1.74q5.457,0,6.169-5.22a4.146,4.146,0,0,0-.633-3.48,6.079,6.079,0,0,0-4.113-1.265m.633,5.22c-.316,1.9-1.74,1.9-3.164,1.9h-.79l.633-3.638c0-.158.158-.317.475-.317h.316c.949,0,1.9,0,2.373.633a1.7,1.7,0,0,1,.158,1.424"
            transform="translate(-460.085 -572.758)"
            fill="#fff"
          />
          <path
            id="Path_49"
            data-name="Path 49"
            d="M490.985,580.2h-2.531c-.158,0-.475.159-.475.317l-.158.79-.158-.316a3.722,3.722,0,0,0-3.006-1.107,6.079,6.079,0,0,0-5.853,5.221,4.715,4.715,0,0,0,.949,3.954,3.985,3.985,0,0,0,3.322,1.266,5.055,5.055,0,0,0,3.638-1.424l-.158.79a.419.419,0,0,0,.474.475H489.4a1.051,1.051,0,0,0,.791-.633l1.424-8.859c-.158-.158-.474-.475-.633-.475m-3.638,5.062a3.061,3.061,0,0,1-3.006,2.531,2.531,2.531,0,0,1-1.741-.633,3.375,3.375,0,0,1-.475-1.9,2.822,2.822,0,0,1,4.588-1.9,2.762,2.762,0,0,1,.633,1.9"
            transform="translate(-466.307 -575.14)"
            fill="#fff"
          />
        </g>
        <g id="Group_682" data-name="Group 682">
          <path
            id="Path_47"
            data-name="Path 47"
            d="M526.677,572.758H521.3a1.052,1.052,0,0,0-.791.633l-2.215,13.921a.42.42,0,0,0,.475.475h2.847a.419.419,0,0,0,.475-.475l.633-3.954a.724.724,0,0,1,.791-.633h1.741q5.457,0,6.17-5.221a4.146,4.146,0,0,0-.633-3.48,6.379,6.379,0,0,0-4.113-1.265m.633,5.22c-.316,1.9-1.74,1.9-3.163,1.9H523.2l.633-3.638c0-.158.158-.317.474-.317h.316c.949,0,1.9,0,2.373.633.316.158.316.633.316,1.424"
            transform="translate(700.052 -164.114)"
            fill="#fff"
          />
          <path
            id="Path_50"
            data-name="Path 50"
            d="M548.956,580.2h-2.532c-.158,0-.474.159-.474.317l-.158.79-.158-.316a3.72,3.72,0,0,0-3.006-1.107,6.078,6.078,0,0,0-5.853,5.221,4.714,4.714,0,0,0,.949,3.954,3.985,3.985,0,0,0,3.322,1.266,5.058,5.058,0,0,0,3.639-1.424l-.158.79a.42.42,0,0,0,.475.475h2.373a1.052,1.052,0,0,0,.791-.633l1.424-8.859c-.158-.158-.316-.475-.633-.475m-3.639,5.062a3.059,3.059,0,0,1-3.006,2.531,2.529,2.529,0,0,1-1.74-.633,3.375,3.375,0,0,1-.475-1.9,2.822,2.822,0,0,1,4.588-1.9,1.816,1.816,0,0,1,.633,1.9"
            transform="translate(693.909 -166.495)"
            fill="#fff"
          />
          <path
            id="Path_51"
            data-name="Path 51"
            d="M511.7,580.36h-2.689a.821.821,0,0,0-.633.317l-3.48,5.378-1.582-5.062a.731.731,0,0,0-.791-.475H500c-.317,0-.475.316-.475.633l2.847,8.384-2.689,3.8c-.158.317,0,.791.317.791h2.531a.82.82,0,0,0,.633-.316l8.7-12.5c.475-.475.159-.95-.158-.95"
            transform="translate(706.324 -166.654)"
            fill="#fff"
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M558.522,573.28l-2.215,14.238a.419.419,0,0,0,.474.475H559a1.049,1.049,0,0,0,.79-.633L562,573.439a.42.42,0,0,0-.475-.475H559c-.158-.158-.317,0-.475.316"
            transform="translate(687.349 -164.162)"
            fill="#fff"
          />
          <path
            id="Exclusion_1"
            data-name="Exclusion 1"
            d="M-1562.884,26.312H-1567a.581.581,0,0,1-.633-.634l1.582-9.649a1.359,1.359,0,0,1,1.107-.95h2.373a10,10,0,0,0,5.892-1.542,8.487,8.487,0,0,0,3.282-5.577V7.486a1.634,1.634,0,0,1,.95.791c1.094,1.277,1.034,3.286.79,4.746a7.235,7.235,0,0,1-2.768,4.944,8.706,8.706,0,0,1-5.142,1.384h-.633a.887.887,0,0,0-.949.791v.316l-.79,4.9v.158A.887.887,0,0,1-1562.884,26.312Zm-6.579-5.848h-4.9a.723.723,0,0,1-.633-.791l3.164-20.723A1.357,1.357,0,0,1-1570.729-2h7.909c2.887,0,4.909.639,6.012,1.9a5.514,5.514,0,0,1,1.266,4.587v.475a8.676,8.676,0,0,1-3.282,5.517,9.984,9.984,0,0,1-5.893,1.6h-2.373a1.361,1.361,0,0,0-1.107.95v-.316l-1.265,7.75Z"
            transform="translate(2727.417 405.344)"
            fill="#fff"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
}
export default render;

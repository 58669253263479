import React from "react";

// Components
import BaseDetailViewComponent from "./BaseDetailViewComponent";
import * as Helper from "./Helper";
import ListHeaderView from "./ListHeaderView";
import ListItemView from "./ListItemView";
import SaveCancelButtons from "./SaveCancelButtons";
import BillingCustomFields from "./BillingCustomFields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Functions
import * as Constants from "./Constants";
import { faInfinity, faPencil, faTrash, faChevronDown, faChevronUp, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import numeral from "numeral";
import Switch from "./Switch";

// View for editing a Billing Subscription.
class BillingSubscription extends BaseDetailViewComponent {
  constructor(props) {
    super(props);

    let selectedSubscription = null;
    let company = null;
    let contactuuid = null;
    let plan = null;

    if (props.selectedItem?.type === Constants.COMPANY) {
      // New subscription for a customer company
      company = Helper.deepCopy(props.selectedItem);
      contactuuid = company.contacts[0].contactuuid;
      selectedSubscription = {
        customer_id: company.companyuuid,
      };
    } else if (props.selectedItem?.type === Constants.CONTACT) {
      // New subscription for a customer
      company = Helper.deepCopy(props.selectedItem.company);
      contactuuid = props.selectedItem.contactuuid;
      // Copy the contact's taxable flag to the company (selectedItem)
      company.taxable = props.selectedItem.taxable;
    } else if (props.selectedItem?.type === Constants.BILLING_PLAN) {
      // New subscription for a plan
      plan = Helper.deepCopy(props.selectedItem);
      plan.status = Constants.SUBSCRIPTION_ACTIVE;
      plan.editMode = true;
      plan.isNew = true;
    } else if (props.selectedItem?.type === Constants.BILLING_SUBSCRIPTION) {
      // Opening an existing subscription
      selectedSubscription = props.selectedItem;
      plan = Helper.deepCopy(selectedSubscription);
      // Create a skeletal company and contact until it can be loaded from our database
      let contact = Helper.getBlankContact(props.appState.salesperson, Constants.CUSTOMER_FAMILY);
      contact.firstname = selectedSubscription.customer_first_name;
      contact.lastname = selectedSubscription.customer_last_name;
      contact.editmode = false;
      company = Helper.getBlankCompany(Constants.CUSTOMER_FAMILY, [contact], props.appState.salesperson, true);
      company.companyuuid = selectedSubscription.customer_id;
      company.uuid = selectedSubscription.customer_id;
    }

    // Move the selected contact to front of list in company, if necessary
    //    to prevent the brief display of the "natural order"
    company = Helper.moveContactToFront(contactuuid, company);

    // If we have a plan, then we are creating a new subscription
    // and the only subscription is based on the plan passed in
    const maastSubscriptions = plan ? [plan] : [];

    // Determine if we need to show inactive subscriptions by default
    // If the selected item is inactive, then show inactive by default
    let hideInactiveSubscriptions = true;
    if (selectedSubscription && !Constants.SUBSCRIPTION_ACTIVE_STATUSES.includes(selectedSubscription.status)) {
      hideInactiveSubscriptions = false;
    }

    this.state = {
      ...this.state,
      company: company, // Full company and contact list - Used for most everything
      contactSearchKey: "",
      contactuuid: contactuuid, // Selected contactuuid from customer list
      maastSubscriptions: maastSubscriptions,
      selectedSubscription: selectedSubscription,
      viewName: Constants.BILLING,
      maastInvoicesPagination: {
        totalPages: 0,
        pageNumber: 0,
        totalRecords: 0,
      },
      hideInactiveSubscriptions: hideInactiveSubscriptions,
    };
  }

  // Once component is loaded, fire GET request
  componentDidMount() {
    super.componentDidMount();
    if (this.state.selectedSubscription && this.state.selectedSubscription.customer_id) {
      this.getCompany(
        this.state.company.companyuuid,
        () => {
          // Get subscriptions and check if customer is vaulted
          this.getSubscriptions(this.state.company.companyuuid, false, 0, () => {
            // If we're hiding active subscriptions and there are no active subscriptions, then show inactive subscriptions by default
            let hideInactiveSubscriptions = this.state.hideInactiveSubscriptions;
            if (this.state.hideInactiveSubscriptions) {
              const activeSubscriptions = this.state.maastSubscriptions.filter(sub => Constants.SUBSCRIPTION_ACTIVE_STATUSES.includes(sub.status));
              if (activeSubscriptions.length === 0) {
                hideInactiveSubscriptions = false;
              }
            }

            this.setState({
              isNew: this.state.maastSubscriptions?.length === 0,
              loading: false,
              hideInactiveSubscriptions: hideInactiveSubscriptions,
            });
            this.getTransactionsForCustomer(this.state.company.companyuuid);
            this.getMaastInvoices(this.state.company.companyuuid);
          });
          this.getCustomerFromVault(this.state.company.companyuuid);
        },
        false // Do not show message of 404 error
      );
    } else {
      this.setState({ maastCustomer: { vaulted: false }, loading: false }, () => {
        this.props.hideOverlay();
      });
    }
    this.getBillingPlans();
  }

  renderContactSearchResultsToColumns = item => {
    let fullname = "";
    let mobileviewphone = "";
    let companyname = "";
    fullname = item.firstname + " " + item.lastname;
    // Display mobilephone, then otherphone (if no mobile), then "-" if neither
    mobileviewphone = item.mobilephone ? item.mobilephone : item.otherphone ? item.otherphone : "";
    mobileviewphone = mobileviewphone ? mobileviewphone : item.email;
    companyname = item.companyname ? item.companyname : "";
    fullname = <span data-testid={"Customer Search Results: " + fullname}>{fullname}</span>;
    return [{ rowvalue: fullname }, { rowvalue: mobileviewphone }, { rowvalue: companyname, classes: "desktop" }];
  };

  renderAreaSubscriptionStatus = () => {
    if (this.props.appState.currentView === Constants.BILLING) {
      // TODO: Add additional status possibilities (cancelled, paused, completed)
      const active =
        this.state.maastSubscriptions?.find(item => item.status === Constants.SUBSCRIPTION_ACTIVE) ||
        this.props.selectedItem?.status === Constants.SUBSCRIPTION_ACTIVE;
      const suspended =
        this.state.maastSubscriptions?.find(item => item.status === Constants.SUBSCRIPTION_SUSPENDED) ||
        this.props.selectedItem?.status === Constants.SUBSCRIPTION_SUSPENDED;
      const paused =
        this.state.maastSubscriptions?.find(item => item.status === Constants.SUBSCRIPTION_PAUSED) ||
        this.props.selectedItem?.status === Constants.SUBSCRIPTION_PAUSED;
      const cancelled =
        this.state.maastSubscriptions?.find(item => item.status === Constants.SUBSCRIPTION_CANCELLED) ||
        this.props.selectedItem?.status === Constants.SUBSCRIPTION_SUSPENDED;
      const vaulted = this.state.maastCustomer?.vaulted;
      let customerStatus = "";
      if (this.isMaastOnlyCustomer()) {
        customerStatus = " / Maast-only Customer";
      }
      let status = Helper.renderStatus(this.props.selectedItem?.status || Constants.SUBSCRIPTION_LOADING, null, customerStatus);
      if (this.state.isNew) {
        status = Helper.renderStatus(Constants.SUBSCRIPTION_NEW, null, customerStatus);
      } else if (suspended) {
        status = Helper.renderStatus(Constants.SUBSCRIPTION_SUSPENDED, null, customerStatus);
      } else if (active) {
        status = Helper.renderStatus(Constants.SUBSCRIPTION_ACTIVE, null, customerStatus);
      } else if (paused) {
        status = Helper.renderStatus(Constants.SUBSCRIPTION_PAUSED, null, customerStatus);
      } else if (cancelled) {
        status = Helper.renderStatus(Constants.SUBSCRIPTION_CANCELLED, null, customerStatus);
      } else if (vaulted) {
        status = Helper.renderStatus(Constants.SUBSCRIPTION_VAULTED, null, customerStatus);
      } else {
        status = Helper.renderStatus(Constants.SUBSCRIPTION_NEW, null, customerStatus);
      }

      return <div className="areaSubscriptionStatus">{status}</div>;
    } else {
      return "";
    }
  };

  renderAreaStoredPayments = () => {
    const headers = [
      { classes: "header firstLeft", columnheading: "Name" },
      { classes: "header desktop", columnheading: "Card Number" },
      { classes: "header mobile", columnheading: "Card" },
      { classes: "header", columnheading: "Exp" },
      { classes: "header noPrint", columnheading: "" },
      { classes: "header noPrint lastRight", columnheading: "" },
    ];
    const validCompany =
      this.state.company?.companyuuid ||
      (this.state.company?.contacts?.length > 0 && this.state.company.contacts[0].firstname && this.state.company.contacts[0].lastname);
    const addButtonClasses = "action-button green-button " + (!validCompany ? "save-disabled" : "");
    return (
      <React.Fragment>
        <div className="areaStoredPaymentsHeader">
          <span>Customer Payment Methods:</span>
          <span className={addButtonClasses} onClick={this.handleAddPaymentMethod}>
            Add
          </span>
        </div>
        <div className="areaStoredPayments">
          <ListHeaderView headerRowItems={headers} />
          <ListItemView
            listitems={this.state.maastCustomer?.billing_cards ?? []}
            expandedListItems={null}
            selectedListItems={[]}
            renderItemToColumns={this.renderStoredPaymentsToColumns}
            toggleCollapsed={() => {}}
            selectListItem={() => {}}
            handleEditItem={() => {}}
            handleTouchStart={() => {}}
            handleTouchEnd={() => {}}
          />
        </div>
      </React.Fragment>
    );
  };

  renderStoredPaymentsToColumns = card => {
    const name = (
      <span title="Cardholder Name" data-testid="Cardholder Name">
        {card?.billing_first_name + " " + card?.billing_last_name}
      </span>
    );

    let expirationDate = card?.exp_date ?? "N/A";
    if (expirationDate.length === 4) {
      expirationDate = expirationDate.slice(0, 2) + "/" + expirationDate.slice(2);
    }
    const expirationDateElement = (
      <span title="Expiration Date" data-testid="Expiration Date">
        {expirationDate}
      </span>
    );

    const subsForCard = this.state.maastSubscriptions?.filter(
      sub => sub.card_id === card?.card_id && Constants.SUBSCRIPTION_ACTIVE_STATUSES.includes(sub.status)
    );

    const trashcan =
      !this.state.maastSubscriptions || subsForCard.length > 0 ? (
        <span title="Disabled Delete Payment Method" data-testid="Disabled Card Delete Button" className="save-disabled paymentMethodDelete">
          <FontAwesomeIcon icon={faTrash} />
        </span>
      ) : (
        <span
          title="Delete Payment Method"
          data-testid="Card Delete Button"
          className="paymentMethodDelete"
          onClick={() => {
            this.handleDeleteStoredPayment(card);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      );
    const cardNumber = (
      <span data-testid="Card Number" title="Card Number">
        {card?.card_number}
      </span>
    );
    let mobileCardNumber = card?.card_number ?? "Unknown";
    if (mobileCardNumber.length > 4 && mobileCardNumber !== "Unknown") {
      mobileCardNumber = mobileCardNumber.slice(-5);
    }
    const mobileCardNumberElement = (
      <span data-testid="Mobile Card Number" title="Card Number">
        {mobileCardNumber}
      </span>
    );
    const editButton = (
      <span
        data-testid="Edit Card Button"
        title="Edit Card Details"
        onClick={() => {
          this.handleEditStoredPayment(card);
        }}
      >
        <FontAwesomeIcon icon={faPencil} />
      </span>
    );
    return [
      { rowvalue: name },
      { rowvalue: cardNumber, classes: "desktop" },
      { rowvalue: mobileCardNumberElement, classes: "mobile" },
      { rowvalue: expirationDateElement },
      { rowvalue: editButton, classes: "noPrint" },
      { rowvalue: trashcan, classes: "noPrint" },
    ];
  };

  renderAreaSubscriptions = () => {
    let nextOrDuration = "Next Bill";
    if (this.state.isNew) {
      nextOrDuration = "Duration";
    }

    const headers = [
      { classes: "header desktop firstLeft", columnheading: "Plan Name" },
      { classes: "header desktop", columnheading: "Type" },
      { classes: "header desktop", columnheading: "Frequency" },
      { classes: "header desktop right-aligned", columnheading: "Amount" },
      { classes: "header desktop centerAligned", columnheading: "Start Date" },
      { classes: "header desktop centerAligned", columnheading: nextOrDuration },
      { classes: "header desktop centerAligned", columnheading: "Expires" },
      {
        classes: "header desktop centerAligned",
        columnheading: "Status",
        eyeball: true,
        handleToggleEyeball: this.handleToggleHideInactive,
        eyeballSlashed: this.state.hideInactiveSubscriptions,
        eyeballTitle: "Click to " + (this.state.hideInactiveSubscriptions ? "show" : "hide") + " inactive subscriptions",
      },
      { classes: "header desktop", columnheading: "Payment" },
      { classes: "header desktop lastRight", columnheading: "" },
    ];
    return (
      <div className="areaSubscriptions desktop-grid">
        <ListHeaderView headerRowItems={headers} />
        <hr className="headerRule10 printOnly" />
        <ListItemView
          listitems={this.state.maastSubscriptions}
          expandedListItems={null}
          selectedListItems={[]}
          renderItemToColumns={this.renderSubscriptionToColumns}
          toggleCollapsed={() => {}}
          selectListItem={() => {}}
          handleEditItem={() => {}}
          handleTouchStart={() => {}}
          handleTouchEnd={() => {}}
        />
      </div>
    );
  };

  renderSubscriptionToColumns = (subscription, index) => {
    if (this.state.hideInactiveSubscriptions && [Constants.SUBSCRIPTION_CANCELLED, Constants.SUBSCRIPTION_COMPLETE].includes(subscription.status)) {
      return [];
    }
    const dateNextClasses = "centerAligned " + (subscription.isNew ? "duration " : " dateNext ");
    const planAmtClasses = "right-aligned recurAmount";
    const customFieldsRow = subscription.expanded ? this.renderCustomFields(subscription, "") : "";
    const customFieldsRowPrint = this.renderCustomFieldsPrint(subscription, "printOnly");
    let columns = [
      { rowvalue: this.renderPlanDescription(subscription, index), classes: "planDescription firstLeft" },
      { rowvalue: this.renderPlanType(subscription), classes: "planType" },
      { rowvalue: this.renderPlanFrequency(subscription, index) },
      { rowvalue: this.renderPlanAmtTranOrRecurAmt(subscription, index), classes: planAmtClasses },
      { rowvalue: this.renderStartDate(subscription, index), classes: "centerAligned startDate" },
      { rowvalue: this.renderDateNextOrDuration(subscription, index), classes: dateNextClasses },
      { rowvalue: this.renderDateEndOrCheckbox(subscription, index), classes: "centerAligned" },
      { rowvalue: this.renderStatusSelect(subscription, index), classes: "centerAligned" },
      { rowvalue: this.renderPaymentSelectElement(subscription) },
      { rowvalue: this.renderSubscriptionButtons(subscription), classes: "lastRight" },
    ];
    if (customFieldsRow !== "") {
      columns.push({ rowvalue: customFieldsRow, classes: "span10 noPrint customFieldsContainer" });
    }
    columns.push({ rowvalue: customFieldsRowPrint, classes: "span10 printOnly leftMarginSmall customFieldsContainerPrint" });
    return columns;
  };

  renderAreaSubscriptionsNarrow = () => {
    const subs = this.state.maastSubscriptions?.map((subscription, index) => {
      // Hide inactive subscriptions, if requested
      if (this.state.hideInactiveSubscriptions && [Constants.SUBSCRIPTION_CANCELLED, Constants.SUBSCRIPTION_COMPLETE].includes(subscription.status)) {
        return "";
      }
      let editButton = "";
      if (!this.state.isNew && subscription.editMode) {
        editButton = (
          <SaveCancelButtons
            containerClasses="control-buttons-center no-top-padding"
            handleCancel={this.handleCancelEditSubscription}
            handleSave={event => {
              this.handleSaveSubscriptionChanges(event, subscription);
            }}
            saveButtonClass="action-button green-button"
            datatestidcancel="Subscription Cancel"
            datatestidsave="Subscription Save"
          />
        );
      }

      const plan_type_label = subscription.is_individual ? "Individual" : "Group";
      const dateNextClasses = "subscriptionCardItem " + (subscription.isNew ? "duration " : " dateNext ");
      let nextOrDuration = "Next Bill";
      if (this.state.isNew) {
        nextOrDuration = "Duration";
      }
      const customFieldsRow = subscription.expanded ? this.renderCustomFields(subscription, "span2") : "";
      const customFieldsRowPrint = this.renderCustomFieldsPrint(subscription, " span2 printOnly leftMarginSmall marginTophalfem");

      return (
        <div className="subscriptionCard" key={"Narrow-" + subscription.subscription_id}>
          <div>&nbsp;</div>
          <div className="subscriptionCardHeader">{this.renderSubscriptionButtons(subscription)}</div>
          <label htmlFor="description">Plan Name</label>
          <div className="subscriptionCardItem planDescription">{this.renderPlanDescription(subscription, index)}</div>
          <label htmlFor="type">Type</label>
          <div className="subscriptionCardItem planType">{plan_type_label}</div>
          <label htmlFor="frequency">Frequency</label>
          <div className="subscriptionCardItem">{this.renderPlanFrequency(subscription)}</div>
          <label htmlFor="amount">Amount</label>
          <div className="subscriptionCardItem recurAmount">{this.renderPlanAmtTranOrRecurAmt(subscription, index)}</div>
          <label htmlFor="date_start">Start Date</label>
          <div className="subscriptionCardItem startDate">{this.renderStartDate(subscription, index)}</div>
          <label htmlFor="date_next">{nextOrDuration}</label>
          <div className={dateNextClasses}>{this.renderDateNextOrDuration(subscription, index)}</div>
          <label htmlFor="date_last">Expires</label>
          <div className="subscriptionCardItem">{this.renderDateEndOrCheckbox(subscription, index)}</div>
          <label htmlFor="status">Status</label>
          <div className="subscriptionCardItem">{this.renderStatusSelect(subscription, index)}</div>
          <label htmlFor="payment">Payment</label>
          <div className="subscriptionCardItem">{this.renderPaymentSelectElement(subscription)}</div>
          {editButton}
          {customFieldsRow}
          {customFieldsRowPrint}
        </div>
      );
    });
    let inactiveSwitch = "";
    if (this.state.maastSubscriptions?.find(sub => [Constants.SUBSCRIPTION_CANCELLED, Constants.SUBSCRIPTION_COMPLETE].includes(sub.status))) {
      inactiveSwitch = (
        <span className="noPrint">
          <Switch
            label="Show inactive subscriptions?"
            fieldname="showInactiveSubscriptions"
            handleChange={event => {
              this.setState(prevState => ({
                hideInactiveSubscriptions: !prevState.hideInactiveSubscriptions,
              }));
            }}
            checked={!this.state.hideInactiveSubscriptions}
            elementid="switch-inactive-subscriptions"
          />
        </span>
      );
    }
    return (
      <div className="areaSubscriptionsNarrow mobile-grid">
        {subs}
        {inactiveSwitch}
      </div>
    );
  };

  renderAreaOverdueTotal = () => {
    if (numeral(this.state.overdueTotalAmount).value() === 0) {
      return "";
    }

    return (
      <div className="areaOverdueTotal overdueTotalAmount">
        Total Overdue Amount: &nbsp;&nbsp; {numeral(this.state.overdueTotalAmount).format(Constants.CURRENCY_WITH_SYMBOL)}
      </div>
    );
  };

  renderPlanDescription = (subscription, index) => {
    if (
      // Description is editable on new and existing INDIVIDUAL plans
      (subscription.is_individual && (subscription.isNew || subscription.editMode)) ||
      // Description is editable on existing GROUP plans
      (!subscription.is_individual && !subscription.isNew && subscription.editMode)
    ) {
      const subscriptionDesc = subscription.isNew ? subscription.plan_desc : this.state.newSubscriptionDesc;
      return (
        <input
          type="text"
          name="plan_desc"
          id="plan_desc"
          data-testid="Edit Plan Description"
          value={subscriptionDesc}
          onChange={event => {
            this.handleChangePlanDescription(index, event);
          }}
        />
      );
    } else if (!subscription.is_individual && subscription.isNew && subscription.editMode) {
      return (
        <div
          data-testid="Subscription Plan Description"
          className="subscriptionLabel"
          title="Plan name will be editable after creating the subscription"
        >
          {subscription.plan_desc}
        </div>
      );
    } else {
      return (
        <div data-testid="Subscription Plan Description" className="subscriptionLabel" title={subscription.plan_desc}>
          {subscription.plan_desc}
        </div>
      );
    }
  };

  renderPlanType = subscription => {
    return (
      <div data-testid="Subscription Plan Type" className="subscriptionLabel">
        {" "}
        {subscription.is_individual ? "Individual" : "Group"}{" "}
      </div>
    );
  };

  renderPlanFrequency = (subscription, index) => {
    if (subscription.isNew && subscription.is_individual) {
      return (
        <select
          name="plan_frequency"
          id="plan_frequency"
          data-testid="Edit Plan Frequency"
          value={subscription.plan_frequency}
          onChange={event => {
            this.handleChangePlanFrequency(index, event);
          }}
        >
          <option value="0">Weekly</option>
          <option value="1">Bi-weekly</option>
          <option value="3">Monthly</option>
          <option value="4">Quarterly</option>
          <option value="5">Bi-annually</option>
          <option value="6">Annually</option>
          <option value="7">Daily</option>
        </select>
      );
    } else {
      return (
        <div data-testid="Subscription Frequency" className="subscriptionLabel">
          {Helper.renderMaastFrequency(subscription.plan_frequency)}{" "}
        </div>
      );
    }
  };

  renderPlanAmtTranOrRecurAmt = (subscription, index) => {
    // On a new subscription, we send the amt_tran, but get back the recur_amt
    if (subscription.isNew && subscription.is_individual) {
      return (
        <input
          type="text"
          name="amt_tran"
          id="amt_tran"
          data-testid="Edit Subscription Amount"
          maxLength={7}
          value={subscription.amt_tran}
          onChange={event => {
            this.handleChangeAmtTran(index, event);
          }}
        />
      );
    } else {
      return (
        <div data-testid="Subscription Amount" className="subscriptionLabel">
          {numeral(subscription.amt_tran).format(Constants.CURRENCY)}
        </div>
      );
    }
  };

  renderStartDate = (subscription, index) => {
    if (subscription.isNew) {
      return (
        <input
          type="date"
          name="date_start"
          id="date_start"
          data-testid="Edit Start Date"
          value={subscription.date_start}
          onChange={event => {
            this.handleChangeStartDate(index, event);
          }}
        />
      );
    } else {
      return (
        <div data-testid="Subscription Start Date" className="subscriptionLabel">
          {Helper.formatDate(subscription.date_start)}
        </div>
      );
    }
  };

  renderDateNextOrDuration = (subscription, index) => {
    let plan_duration = subscription.plan_duration === -1 ? "Until cancelled" : subscription.plan_duration;
    if (subscription.isNew && subscription.is_individual) {
      return (
        <input
          type="text"
          name="plan_duration"
          id="plan_duration"
          data-testid="Edit Subscription Duration or Next Date"
          placeholder="Duration"
          maxLength={4}
          disabled={subscription.plan_duration === -1}
          value={plan_duration}
          onChange={event => {
            this.handleChangeDuration(index, event);
          }}
        />
      );
    } else if (subscription.isNew && subscription.plan_duration !== -1) {
      return (
        <div data-testid="Subscription Duration or Next Date" className="subscriptionLabel">
          {subscription.plan_duration}
        </div>
      );
    } else if (subscription.isNew && subscription.plan_duration === -1) {
      return (
        <div data-testid="Subscription Until Cancelled" className="subscriptionLabel">
          Until cancelled
        </div>
      );
    }
    if (subscription.editMode && !subscription.isNew) {
      return (
        <input
          type="date"
          name="date_next"
          id="date_next"
          data-testid="Edit Subscription Next Bill"
          value={this.state.newSubscriptionDateNext}
          onChange={event => {
            this.handleChangeDateNext(index, event);
          }}
        />
      );
    }
    plan_duration = Helper.formatDate(subscription.date_next) || <React.Fragment>&nbsp;</React.Fragment>;
    return (
      <div data-testid="Subscription Duration" className="subscriptionLabel">
        {plan_duration}
      </div>
    );
  };

  renderDateEndOrCheckbox = (subscription, index) => {
    if (subscription.isNew && subscription.is_individual) {
      return (
        <div className="subscriptionLabel">
          <label className="checkboxContainer" htmlFor="plan_duration_unlimited">
            <input
              type="checkbox"
              name="plan_duration_unlimited"
              id="plan_duration_unlimited"
              data-testid="Edit Unlimited Duration"
              onChange={event => {
                this.handleChangeUnlimitedDuration(index, event);
              }}
              checked={subscription.plan_duration_unlimited}
            />
            <span className="checkmark"></span>
          </label>
          <FontAwesomeIcon icon={faInfinity} />
        </div>
      );
    }
    if (subscription.isNew) {
      return <div className="subscriptionLabel">&nbsp;</div>;
    }
    let date_end = "";
    if (!subscription.isNew && subscription.plan_duration !== -1) {
      date_end = <div data-testid="Subscription End Date">{Helper.formatDate(subscription.date_end)}</div>;
    } else if (!subscription.isNew) {
      date_end = (
        <div data-testid="Infinity Symbol">
          <FontAwesomeIcon icon={faInfinity} />
        </div>
      );
    }
    return (
      <div data-testid="Subscription End Date" className="subscriptionLabel">
        {date_end}
      </div>
    );
  };

  renderStatusSelect = (subscription, index) => {
    if (subscription.isNew) {
      return (
        <div data-testid="Subscription New" className="subscriptionLabel">
          New
        </div>
      );
    }
    if (
      subscription.status === Constants.SUBSCRIPTION_COMPLETE ||
      subscription.status === Constants.SUBSCRIPTION_CANCELLED ||
      !subscription.editMode
    ) {
      // Status badge
      return (
        <div data-testid="Subscription Status" className="subscriptionLabel">
          {Helper.renderStatus(subscription.status)}
        </div>
      );
    }
    // Determine which card to pre-select
    return (
      <select
        name="status"
        id="status"
        data-testid="Edit Subscription Status"
        onChange={event => {
          this.handleChangeSubscriptionStatus(event, subscription.subscription_id);
        }}
        value={this.state.newSubscriptionStatus}
      >
        {this.renderStatusSelectOptions(subscription)}
      </select>
    );
  };

  renderPaymentSelectElement = subscription => {
    const cards = this.state.maastCustomer?.billing_cards ?? [];
    if (
      subscription.status === Constants.SUBSCRIPTION_COMPLETE ||
      subscription.status === Constants.SUBSCRIPTION_CANCELLED ||
      (!subscription.editMode && !subscription.isNew)
    ) {
      if (!subscription.card_id) {
        // No card_id on subscription means use default (primary=true)
        const card = cards.find(card => card.primary);
        if (card) {
          const name = card.billing_first_name + " " + card.billing_last_name;
          const desc = name + " " + card.card_number.slice(-6);
          return (
            <div data-testid="Card number" className="subscriptionLabel">
              {desc}
            </div>
          );
        } else {
          return (
            <div data-testid="No card on file" className="subscriptionLabel">
              No card on file
            </div>
          );
        }
      } else {
        const card = cards.find(card => card.card_id === subscription.card_id);
        if (card) {
          const name = card.billing_first_name + " " + card.billing_last_name;
          const desc = name + " " + card.card_number.slice(-6);
          return (
            <div data-testid="Card number" className="subscriptionLabel">
              {desc}
            </div>
          );
        } else {
          return (
            <div data-testid="Card not found" className="subscriptionLabel">
              Card not found
            </div>
          );
        }
      }
    }

    // Determine which card to pre-select
    let card_id = subscription.card_id;
    if (!card_id) {
      // No card_id on subscription means use default (primary=true)
      const card = cards.find(card => card.primary);
      if (card) {
        card_id = card.card_id;
      }
    }

    // Check for no cards on file and new subscription
    if (cards.length === 0 && subscription.isNew) {
      return (
        <div data-testid="No card on file" className="subscriptionLabel">
          No card on file
        </div>
      );
    }

    // The currently selected card is determined by whether or not the subscription is new
    // New subscriptions are stored directly in the array in state
    // Existing subscription changes are stored in new* variables in state
    let newPaymentOption = subscription.isNew ? subscription.card_id : this.state.newPaymentOption;

    // Render different <select>'s for MOBILE and DESKTOP
    return (
      <React.Fragment>
        <select
          name="storedPayment"
          id="storedPayment"
          data-testid="Edit Desktop Stored Payment"
          className="desktop"
          onFocus={Helper.handleFocus}
          onChange={event => this.handleChangePaymentOption(event, subscription.subscription_id)}
          value={newPaymentOption}
        >
          {this.renderPaymentSelectOptions(subscription)}
        </select>
        <select
          name="storedPayment"
          id="storedPayment"
          data-testid="Edit Mobile Stored Payment"
          className="mobile"
          onFocus={Helper.handleFocus}
          onChange={event => this.handleChangePaymentOption(event, subscription.subscription_id)}
          value={newPaymentOption}
        >
          {this.renderPaymentSelectOptionsNarrow(subscription)}
        </select>
      </React.Fragment>
    );
  };

  renderPaymentSelectOptions = subscription => {
    const cards = this.state.maastCustomer?.billing_cards ?? [];
    let cardsElements = cards.map(card => {
      const key = card.card_id;
      const name = card.billing_first_name + " " + card.billing_last_name;
      const desc = name + " " + card.card_number.slice(-6);
      return (
        <option key={key} value={card.card_id}>
          {desc}
        </option>
      );
    });
    return cardsElements;
  };

  renderSubscriptionButtons = subscription => {
    let leftButton = <span>&nbsp; &nbsp;</span>;
    let rightButton = this.renderSubscriptionExpandButton(subscription);

    if (
      subscription.status === Constants.SUBSCRIPTION_ACTIVE ||
      subscription.status === Constants.SUBSCRIPTION_SUSPENDED ||
      subscription.status === Constants.SUBSCRIPTION_PAUSED
    ) {
      // Show Save/Cancel when editing
      if (subscription.editMode && (!subscription.isNew || this.state.maastSubscriptions.length > 1)) {
        let saveButtonClasses = "saveIcon ";
        if (!this.isReadyToSubmitSubscription(subscription)) {
          saveButtonClasses += "save-disabled";
        }
        leftButton = (
          <span
            data-testid="Save Subscription Changes"
            className={saveButtonClasses}
            onClick={event => {
              this.handleSaveSubscriptionChanges(event, subscription);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </span>
        );
        rightButton = (
          <span data-testid="Cancel or Edit Subscription" className="cancelIcon" onClick={this.handleCancelEditSubscription}>
            <FontAwesomeIcon icon={faXmark} />
          </span>
        );
      } else if (subscription.expanded) {
        leftButton = <span>&nbsp; &nbsp;</span>;
      } else if (this.state.maastSubscriptions.filter(sub => sub.editMode).length > 0) {
        // If any subscription is in edit mode, don't show edit button
        leftButton = <span>&nbsp; &nbsp;</span>;
      } else {
        leftButton = (
          <span
            data-testid="Edit Subscription Button"
            className="noPrint"
            title="Edit Subscription"
            onClick={() => {
              this.handleToggleEditMode(subscription);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </span>
        );
      }
    }

    return (
      <div className="subscriptionLabel">
        {leftButton} &nbsp; &nbsp; {rightButton}
      </div>
    );
  };

  renderPaymentSelectOptionsNarrow = subscription => {
    const cards = this.state.maastCustomer?.billing_cards ?? [];
    let cardsElements = cards.map(card => {
      const key = card.card_id;
      return (
        <option key={key} value={card.card_id}>
          {card.card_number}
        </option>
      );
    });
    return cardsElements;
  };

  renderStatusSelectOptions = subscription => {
    if (subscription.status === Constants.SUBSCRIPTION_ACTIVE) {
      return (
        <React.Fragment>
          <option value={Constants.SUBSCRIPTION_ACTIVE} key={Constants.SUBSCRIPTION_ACTIVE}>
            Active
          </option>
          <option value={Constants.SUBSCRIPTION_CANCELLED} key={Constants.SUBSCRIPTION_CANCELLED}>
            Cancel
          </option>
          <option value={Constants.SUBSCRIPTION_PAUSED} key={Constants.SUBSCRIPTION_PAUSED}>
            Pause
          </option>
        </React.Fragment>
      );
    } else if (subscription.status === Constants.SUBSCRIPTION_SUSPENDED) {
      return (
        <React.Fragment>
          <option value={Constants.SUBSCRIPTION_ACTIVE} key={Constants.SUBSCRIPTION_ACTIVE}>
            Active
          </option>
          <option value={Constants.SUBSCRIPTION_CANCELLED} key={Constants.SUBSCRIPTION_CANCELLED}>
            Cancel
          </option>
          <option value={Constants.SUBSCRIPTION_SUSPENDED} key={Constants.SUBSCRIPTION_SUSPENDED}>
            Suspend
          </option>
        </React.Fragment>
      );
    } else if (subscription.status === Constants.SUBSCRIPTION_PAUSED) {
      return (
        <React.Fragment>
          <option value={Constants.SUBSCRIPTION_ACTIVE} key={Constants.SUBSCRIPTION_ACTIVE}>
            Active
          </option>
          <option value={Constants.SUBSCRIPTION_CANCELLED} key={Constants.SUBSCRIPTION_CANCELLED}>
            Cancel
          </option>
          <option value={Constants.SUBSCRIPTION_PAUSED} key={Constants.SUBSCRIPTION_PAUSED}>
            Pause
          </option>
        </React.Fragment>
      );
    }
  };

  renderCustomFields = (subscription, className) => {
    return (
      <BillingCustomFields
        subscription={subscription}
        appState={this.props.appState}
        handleChangeCustomField={this.handleChangeCustomField}
        handleBlurCustomField={this.handleBlurCustomField}
        className={className}
      />
    );
  };
  renderCustomFieldsPrint = (subscription, className) => {
    if (subscription?.custom_fields?.length > 0) {
      // return a list of printable custom fields values
      return subscription.custom_fields.map((field, index) => {
        // cross reference field.name with appState.customFields to get the label
        const customField = this.props.appState.customFields.find(cf => cf.uuid === field.name)?.description;
        const key = "customField" + index;
        if (customField) {
          return (
            <div key={key} className={className}>
              <span className="customFieldLabel">{customField + ":  "}</span>
              <span className="customFieldValue italic">{field.value}</span>
            </div>
          );
        } else return "";
      });
    } else {
      return "";
    }
  };

  renderSubscriptionExpandButton = subscription => {
    // Cannot expand a new subscription
    if (subscription.isNew) {
      return <span>&nbsp; &nbsp;</span>;
    }

    // If there are no custom fields defined in settings and no orphaned custom field values, then disable the expand button
    let expandIcon = subscription.expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />;
    const noCustomFields =
      (this.props.appState?.customFields?.length === 0 && subscription.custom_fields?.length === 0) || this.isMaastOnlyCustomer();
    let className = noCustomFields ? "save-disabled" : "";
    let title = noCustomFields ? "No custom field templates defined" : "View Custom Fields";
    title = this.isMaastOnlyCustomer() ? "Custom fields are not available for Maast-only customers" : title;
    let handleClick = noCustomFields
      ? () => {}
      : () => {
          this.handleExpandSubscription(subscription);
        };

    return (
      <span data-testid="Expand Icon" className={"noPrint " + className} title={title} onClick={handleClick}>
        {expandIcon}
      </span>
    );
  };

  handleExpandSubscription = subscription => {
    this.setState(prevState => ({
      maastSubscriptions: prevState.maastSubscriptions.map(sub => {
        if (sub.subscription_id === subscription.subscription_id) {
          sub.expanded = !sub.expanded;
        }
        return sub;
      }),
    }));
  };

  handleToggleHideInactive = () => {
    this.setState(prevState => ({
      hideInactiveSubscriptions: !prevState.hideInactiveSubscriptions,
    }));
  };

  handleChangeUnlimitedDuration = (index, event) => {
    const checked = event.target.checked;
    this.setState(prevState => ({
      maastSubscriptions: prevState.maastSubscriptions.map((subscription, i) => {
        if (i === index) {
          if (checked) {
            subscription.plan_duration = -1;
          } else {
            subscription.plan_duration = "";
          }
          subscription.plan_duration_unlimited = checked;
        }
        return subscription;
      }),
    }));
  };

  handleChangeDuration = (index, event) => {
    const plan_duration = event.target.value;
    this.setState(prevState => ({
      maastSubscriptions: prevState.maastSubscriptions.map((subscription, i) => {
        if (i === index) {
          subscription.plan_duration = plan_duration;
        }
        return subscription;
      }),
    }));
  };

  handleChangeStartDate = (index, event) => {
    const date_start = event.target.value;
    this.setState(prevState => ({
      maastSubscriptions: prevState.maastSubscriptions.map((subscription, i) => {
        if (i === index) {
          subscription.date_start = date_start;
        }
        return subscription;
      }),
    }));
  };

  handleChangeDateNext = (index, event) => {
    const date_next = event.target.value;
    this.setState({ newSubscriptionDateNext: date_next });
  };

  handleChangePlanDescription = (index, event) => {
    const plan_desc = event.target.value;
    // If the billing subscription overall is new or the specific subscription is new,
    // then update the plan_desc in the subscription array in state
    const planBeingEdited = this.state.maastSubscriptions?.length >= index ? this.state.maastSubscriptions[index] : {};
    if (this.state.isNew || planBeingEdited?.isNew) {
      this.setState(prevState => ({
        maastSubscriptions: prevState.maastSubscriptions.map((subscription, i) => {
          if (i === index) {
            subscription.plan_desc = plan_desc;
          }
          return subscription;
        }),
      }));
    } else {
      // If an existing plan is being edited, then update the plan_desc in temp storage in state
      this.setState({ newSubscriptionDesc: plan_desc });
    }
  };

  handleChangePlanFrequency = (index, event) => {
    const plan_frequency = event.target.value;
    this.setState(prevState => ({
      maastSubscriptions: prevState.maastSubscriptions.map((subscription, i) => {
        if (i === index) {
          subscription.plan_frequency = plan_frequency;
        }
        return subscription;
      }),
    }));
  };
  handleChangeAmtTran = (index, event) => {
    const amt_tran = event.target.value;
    this.setState(prevState => ({
      maastSubscriptions: prevState.maastSubscriptions.map((subscription, i) => {
        if (i === index) {
          subscription.amt_tran = amt_tran;
        }
        return subscription;
      }),
    }));
  };

  handleChangePaymentOption = event => {
    this.setState({ newPaymentOption: event.target.value }, () => {
      // For new subscriptions the payment option must be updated directly in the subscription object
      if (this.state.isNew) {
        this.setState(prevState => ({
          maastSubscriptions: prevState.maastSubscriptions.map(subscription => {
            subscription.card_id = this.state.newPaymentOption;
            return subscription;
          }),
        }));
      } else {
        // If a new subscription is being added to an existing billing customer,
        // then the payment option must be updated directly in the subscription object
        this.setState(prevState => ({
          maastSubscriptions: prevState.maastSubscriptions.map(subscription => {
            if (subscription.isNew) {
              subscription.card_id = this.state.newPaymentOption;
            }
            return subscription;
          }),
        }));
      }
    });
  };

  handleChangeSubscriptionStatus = event => {
    this.setState({ newSubscriptionStatus: event.target.value });
  };

  handleSaveSubscriptionChanges = (event, subscription) => {
    if (!this.isReadyToSubmitSubscription(subscription)) {
      return;
    }
    if (subscription.isNew) {
      const companyuuid = this.state.company?.companyuuid;
      this.postSubscription(subscription, false, companyuuid);
    } else {
      // Check to see if which values changed
      const paymentChanged = this.state.newPaymentOption !== subscription.card_id;
      let statusChanged = this.state.newSubscriptionStatus !== subscription.status;
      const descChanged = this.state.newSubscriptionDesc !== subscription.plan_desc;
      const dateNextChanged = this.state.newSubscriptionDateNext !== subscription.date_next;
      let prevPayment = null;
      let prevDesc = null;
      let prevNextDate = null;
      let prevStatus = null;
      this.setState(
        prevState => ({
          maastSubscriptions: prevState.maastSubscriptions.map(sub => {
            sub.editMode = false;
            // Go ahead and update the state, even though the change has not been saved to the server
            if (sub.subscription_id === subscription.subscription_id) {
              if (paymentChanged) {
                prevPayment = sub.card_id;
                sub.card_id = this.state.newPaymentOption;
              }
              if (statusChanged) {
                prevStatus = sub.status;
                sub.status = this.state.newSubscriptionStatus;
              }
              if (descChanged) {
                prevDesc = sub.plan_desc;
                sub.plan_desc = this.state.newSubscriptionDesc;
              }
              if (dateNextChanged) {
                prevNextDate = sub.date_next;
                sub.date_next = this.state.newSubscriptionDateNext;
              }
            }
            return sub;
          }),
        }),
        () => {
          // Declare a function to post the subscription changes
          const postSubscriptionChanges = () => {
            // If payment method, description, or date next changed, then build a function to post the changes
            let postDetailChanges = () => {
              this.props.hideOverlay();
            };
            if (paymentChanged || descChanged || dateNextChanged) {
              postDetailChanges = () => {
                this.putSubscription(
                  subscription.subscription_id,
                  paymentChanged ? this.state.newPaymentOption : null,
                  descChanged ? this.state.newSubscriptionDesc : null,
                  dateNextChanged ? this.state.newSubscriptionDateNext : null,
                  null,
                  () => {
                    // Hide the overlay after all the updates have run
                    this.props.hideOverlay();
                  }
                );
              };
            }
            // If the status changed, then post the status change first, then post the other changes
            if (statusChanged) {
              this.postSubscriptionStatus(subscription, this.state.newSubscriptionStatus, postDetailChanges);
            } else {
              postDetailChanges();
            }
          };
          // If the user tried to change date_next or payment method BUT the status is not active,
          // then ask the user to confirm making the subscription active
          if ((paymentChanged || dateNextChanged) && this.state.newSubscriptionStatus !== Constants.SUBSCRIPTION_ACTIVE) {
            // Show a confirmation dialog for changing the status to active
            this.props.showOverlay({
              type: Constants.OVERLAY_QUESTION,
              text: "Changes require the subscription status to be active. Set status to active?",
              callback: response => {
                if (response === Constants.OVERLAY_RESPONSE_YES) {
                  statusChanged = true;
                  this.setState({ newSubscriptionStatus: Constants.SUBSCRIPTION_ACTIVE }, () => {
                    postSubscriptionChanges();
                  });
                } else {
                  // Reset the values in state for this subscription
                  this.setState(prevState => ({
                    maastSubscriptions: prevState.maastSubscriptions.map(sub => {
                      // Go ahead and update the state, even though the change has not been saved to the server
                      if (sub.subscription_id === subscription.subscription_id) {
                        sub.editMode = true;
                        if (paymentChanged) {
                          sub.card_id = prevPayment;
                        }
                        if (statusChanged) {
                          sub.status = prevStatus;
                        }
                        if (descChanged) {
                          sub.plan_desc = prevDesc;
                        }
                        if (dateNextChanged) {
                          sub.date_next = prevNextDate;
                        }
                      }
                      return sub;
                    }),
                  }));
                }
              },
              key: null,
            });
          } else {
            postSubscriptionChanges();
          }
        }
      );
    }
  };

  handleCancelEditSubscription = () => {
    this.setState(prevState => ({
      maastSubscriptions: prevState.maastSubscriptions
        .map(sub => {
          sub.editMode = false;
          return sub;
        })
        .filter(sub => !sub.isNew),
    }));
  };

  handleToggleEditMode = subscription => {
    if (subscription.status === Constants.SUBSCRIPTION_COMPLETE || subscription.status === Constants.SUBSCRIPTION_CANCELLED) {
      return;
    }
    // Toggle edit mode flag
    const maastSubscriptions = this.state.maastSubscriptions.map(sub => {
      if (sub.subscription_id === subscription.subscription_id) {
        sub.editMode = !sub.editMode;
      }
      return sub;
    });
    this.setState({
      maastSubscriptions: maastSubscriptions,
      newPaymentOption: subscription.card_id,
      newSubscriptionStatus: subscription.status,
      newSubscriptionDesc: subscription.plan_desc,
      newSubscriptionDateNext: subscription.date_next,
    });
  };

  handleAddNewCustomer = () => {
    const contact = Helper.getBlankContact(this.props.appState.salesperson, Constants.CUSTOMER_FAMILY);
    const company = Helper.getBlankCompany(Constants.CUSTOMER_FAMILY, [contact], this.props.appState.salesperson, true);
    this.setState({ company: company });
  };

  // Fires when a customer is selected in the search widget
  selectCustomerListItem = contact => {
    // Do not allow selection of protected customers
    if (Helper.isProtectedCustomer(contact, this.props.appState)) {
      this.props.showOverlay({
        type: Constants.OVERLAY_MESSAGE,
        text: "Walk-in/stock order customers cannot be enrolled in recurring billing",
      });
      return;
    }
    let subs = [];
    if (this.state.maastSubscriptions.length > 0) {
      subs = Helper.deepCopy(this.state.maastSubscriptions);
    }
    const companyuuid = contact.companyuuid;
    this.setState({ contactuuid: contact.contactuuid }, () => {
      this.handleClearCustomerSearch();
      if (this.state.isNew) {
        this.getCompany(companyuuid, () => {
          this.getCustomerFromVault(companyuuid, foundCustomer => {
            if (foundCustomer) {
              this.getSubscriptions(companyuuid, false, 0, () => {
                // If the customer has at least one card on file, then use it as the new subscription's payment option
                if (this.state.maastCustomer.billing_cards.length > 0) {
                  subs = subs.map(sub => {
                    sub.card_id = this.state.maastCustomer.billing_cards[0].card_id;
                    return sub;
                  });
                }
                // Add any NEW subscription to the list of subscriptions
                this.setState(
                  prevState => ({
                    isNew: this.state.maastSubscriptions?.length === 0,
                    loading: false,
                    maastSubscriptions: [...prevState.maastSubscriptions, ...subs],
                  }),
                  () => {
                    this.props.hideOverlay();
                  }
                );
              });
            } else {
              this.setState(
                prevState => ({
                  downloading: false,
                  maastCustomer: { ...prevState.maastCustomer, vaulted: false },
                }),
                () => {
                  this.props.hideOverlay();
                }
              );
            }
          });
        });
      }
    });
  };

  isReadyToSubmitSubscription = subscription => {
    let hasDesc = false;
    // Customer's first subscription is being created
    if (this.state.isNew && subscription?.plan_desc) {
      hasDesc = true;
    }
    // Customer with existing subscriptions is adding a new one
    else if (!this.state.isNew && subscription.isNew && subscription?.plan_desc) {
      hasDesc = true;
    }
    // Editing a customer's existing subscription
    else if (!this.state.isNew && !subscription.isNew && this.state.newSubscriptionDesc) {
      hasDesc = true;
    }
    return subscription && hasDesc && subscription.card_id && subscription.amt_tran && subscription.date_start;
  };

  isReadyToSubmit = () => {
    // TODO: Need more data validation here
    return (
      this.state.company &&
      this.state.maastSubscriptions &&
      this.state.maastSubscriptions.length > 0 &&
      this.state.maastSubscriptions[0].plan_desc &&
      this.state.maastSubscriptions[0].amt_tran &&
      numeral(this.state.maastSubscriptions[0].amt_tran).value() > 0 &&
      this.state.maastSubscriptions[0].date_start &&
      this.state.maastSubscriptions[0].card_id
    );
  };

  getContactsContainerRef = () => {
    return this.state.company;
  };

  setStateContacts = (contacts, callback = null) => {
    this.setState(
      prevState => ({
        company: {
          ...prevState.company,
          contacts: contacts,
        },
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  save = () => {
    if (this.isReadyToSubmit()) {
      // Verify the customer has an email address
      if (!this.state.company.contacts[0].email) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "Customer must have an email address to create a subscription.",
        });
        return;
      }

      // If the customer is new, create the customer in ClerkHound before posting data to Maast
      let subscription = Helper.formatSubscriptionForWebService(this.state.maastSubscriptions[0]);
      subscription.recur_amt = subscription.amt_tran;
      // Individual plan, clear the plan code/ID
      if (subscription.is_individual) {
        delete subscription.plan_code;
        delete subscription.plan_id;
      }
      const billing_cards = Helper.deepCopy(this.state.maastCustomer.billing_cards);
      if (!this.state.company?.companyuuid) {
        let company = this.state.company;
        let contact = this.state.company?.contacts[0];
        // Copy the company info onto the contact
        contact.companyname = this.state.company?.companyname;
        contact.companyuuid = "";
        contact.contactuuid = "";
        // Save to database
        this.postContact(contact, () => {
          company = this.state.company;
          contact = this.state.company?.contacts[0];
          this.postSubscription(subscription, true, company.companyuuid, contact, billing_cards);
        });
      } else {
        // If the customer is not new, determine if they are already in the vault
        const company = this.state.company;
        const contact = this.state.company?.contacts[0];

        this.postSubscription(subscription, !this.state.maastCustomer.vaulted, company.companyuuid, contact, billing_cards);
      }
    }
  };

  // TODO: DRY the following functions. They are in Customer.jsx as well.
  postContact = (contact, callback) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CONTACTS;
    const companyuuid = this.state.company?.companyuuid ? this.state.company.companyuuid : "";
    const newcompany = companyuuid === "";
    const companytype = contact.companyname ? Constants.CUSTOMER_COMPANY : Constants.CUSTOMER_FAMILY;
    if (this.state.isNew) {
      contact.taxable = this.state.company?.taxable;
    }
    contact = { ...contact, companyuuid: companyuuid, companytype: companytype };
    Helper.postData(url, contact).then(response => {
      if (response.status === 200 && response.body) {
        const companyuuid = response.body.companyuuid;
        if (newcompany) {
          this.getCompany(companyuuid, () => {
            if (callback) {
              callback();
            }
          });
        } else {
          // Set the edit mode to keep the new contact's card open
          const contacts = this.state.company.contacts.map(contact => {
            if (contact.contactuuid === "new") {
              response.body.editmode = true;
              return response.body;
            }
            return contact;
          });
          // Update state with response, turn off downloading and hide the overlay
          this.setStateContacts(contacts, () => {
            this.setState({ downloading: false });
            this.props.hideOverlay();
            if (callback) {
              callback();
            }
          });
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the contact.",
          });
        });
      }
    });
  };

  postShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = "/addresses";
    const address = {
      ...Helper.getContactByUUID(this.state.company, contactuuid).shippingaddress,
      contactuuid: contactuuid,
    };
    Helper.postData(url, address).then(response => {
      if (response.status === 200 && response.body) {
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "POST" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the shipping address.",
          });
        });
      }
    });
  };

  putCompany = (companyuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    let data = {
      companyuuid: companyuuid,
      [fieldname]: value,
    };
    // If the company name was added, changed, or deleted, then update the company type, as appropriate.
    if (fieldname === "companyname") {
      // Having a company name value means the company type is "2" (e.g. "corporate").
      // No company name value means a company type of Constants.CUSTOMER_FAMILY.
      data["companytype"] = value ? Constants.CUSTOMER_COMPANY : Constants.CUSTOMER_FAMILY;
    }
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        //Update state to turn off downloading
        this.setState({ downloading: false });
        //Hide overlay after database action is complete
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the customer.",
          });
        });
      }
    });
  };

  putShipAddress = (addressuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });
    //set up to make database call
    const url = "/addresses";
    const data = {
      addressuuid: addressuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Replace contact with data from web service but keep editing flag
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.shippingaddress.addressuuid === addressuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "PUT" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the shipping address.",
          });
        });
      }
    });
  };

  deleteContact = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // If new contact, just remove from list
    if (contactuuid === "new") {
      const contacts = this.state.company.contacts.filter(contact => contact.contactuuid !== "new");
      this.setStateContacts(contacts, () => {
        this.setState({ downloading: false });
        this.props.hideOverlay();
      });
    } else {
      //set up to make database call
      const url = Constants.URL_CONTACTS;
      const params = { contactuuid: contactuuid };
      Helper.deleteData(url, params).then(response => {
        if (response.status === 200 && response.body) {
          // Remove the deleted contact from the list and redisplay
          const contacts = this.state.company.contacts.filter(contact => contact.contactuuid !== contactuuid);
          // If this was the last contact, then the webservice will delete the company
          if (contacts.length === 0) {
            //Update state to turn off downloading
            this.setState({ downloading: false, error: null });
            //Hide overlay after database action is complete
            this.props.hideOverlay();
            //Return to the List screen
            this.props.followBreadcrumb();
          } else {
            // Update state with response, turn off downloading and hide the overlay
            this.setStateContacts(contacts, () => {
              this.setState({ downloading: false });
              this.props.hideOverlay();
            });
          }
        } else if (response.status === 409 || response.status === 202) {
          // A 202 indicates the contact was made inactive, not deleted
          if (response.status === 202) {
            // Update the contact to be inactive
            const contacts = this.state.company.contacts.map(contact => {
              if (contact.contactuuid === contactuuid) {
                contact.active = false;
              }
              return contact;
            });
            // Update state with updated contact(s), turn off downloading and hide the overlay
            this.setStateContacts(contacts, () => {
              this.setState({ error: null, downloading: false }, () => {
                this.props.showOverlay({
                  type: Constants.OVERLAY_MESSAGE,
                  text: response.body.message,
                });
              });
            });
          } else {
            // A 409 indicates the contact was not deleted because it is a protected contact
            // For example, the "walk-in" customer or "stock order" contact
            this.setState({ error: null, downloading: false }, () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: response.body.message,
              });
            });
          }
        } else if (response.status === 503) {
          this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
          this.props.hideOverlay();
        } else {
          this.setState({ error: "DELETE", downloading: false }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "There was an error deleting the contact.",
            });
          });
        }
      });
    }
  };

  deleteCompany = companyuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const params = { companyuuid: companyuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Update state to turn off downloading, hide the overlay, and follow breadcrumbs
        this.setState({ downloading: false, error: null }, () => {
          this.props.hideOverlay();
          this.props.followBreadcrumb();
        });
      } else if (response.status === 409 || response.status === 202) {
        // A 409 indicates the company was not deleted because one or more contacts have existing orders
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting.",
          });
        });
      }
    });
  };

  deleteShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    const contact = Helper.getContactByUUID(this.state.company, contactuuid);
    const addressuuid = contact.shippingaddress.addressuuid;
    //set up to make database call
    const url = "/addresses";
    const params = { addressuuid: addressuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Turn off the shipping address switch
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.hasshippingaddress = false;
            contact.shippingaddress = Helper.getBlankShippingAddress();
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "DELETE" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting.",
          });
        });
      }
    });
  };

  getOrderType = () => {
    return Constants.BILLING;
  };
}
export default BillingSubscription;

function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 381.73 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 53">
            <g data-name="Group 44">
              <g data-name="Group 43">
                <path
                  data-name="Path 43"
                  d="M381.72,229.09V165A62.12,62.12,0,0,0,375,137.72L334.14,68.18a26.39,26.39,0,0,0-23.22-13.64H249.55a18,18,0,0,0-17.73,17.73V218.18H218.19V27.27A27.36,27.36,0,0,0,190.91,0H0V245.46H40.91a55.63,55.63,0,0,1,53.23-40.91,54.4,54.4,0,0,1,53.22,40.91h99.56a55.61,55.61,0,0,1,53.18-40.91,51.71,51.71,0,0,1,47.73,30,21.11,21.11,0,0,0,17.62,10.91,15.71,15.71,0,0,0,16.27-15.13C381.74,229.92,381.74,229.51,381.72,229.09ZM259.1,150V81.82h54.55L353.19,150Z"
                />
              </g>
            </g>
            <g data-name="Group 46">
              <g data-name="Group 45">
                <path
                  data-name="Path 44"
                  d="M300.05,218.18A40.91,40.91,0,1,0,341,259.09a40.19,40.19,0,0,0-39.44-40.91Zm0,60a19.09,19.09,0,1,1,19.08-19.1A18.64,18.64,0,0,1,301,278.19Z"
                />
              </g>
            </g>
            <g data-name="Group 48">
              <g data-name="Group 47">
                <path
                  data-name="Path 45"
                  d="M95.5,218.18a40.91,40.91,0,1,0,40.91,40.91A40.19,40.19,0,0,0,97,218.18Zm0,60a19.09,19.09,0,1,1,19.09-19.1,18.66,18.66,0,0,1-18.18,19.1Z"
                />
              </g>
            </g>
            <g data-name="Group 50">
              <g data-name="Group 49">
                <circle data-name="Ellipse 1" cx="95.5" cy="259.1" r="6.82" />
              </g>
            </g>
            <g data-name="Group 52">
              <g data-name="Group 51">
                <circle data-name="Ellipse 2" cx="300.05" cy="259.1" r="6.82" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

function ErrorIcon(props) {
  if (props.error) {
    return (
      <span className="cloud-icon">
        <FontAwesomeIcon icon={faTriangleExclamation} />
      </span>
    );
  } else {
    return "";
  }
}

export default ErrorIcon;

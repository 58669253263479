import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function CountdownSpinner(props) {
  return (
    <div className="spinner-container">
      <div className="spinner-icon">
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
      <div className="countdown-label">{props.count}</div>
    </div>
  );
}

export default CountdownSpinner;

// Constants
import * as Constants from "./Constants";

// Components
import BaseDetailViewComponent from "./BaseDetailViewComponent";

// Functions
import * as Helper from "./Helper";
import numeral from "numeral";

// View for editing a contact
class Customer extends BaseDetailViewComponent {
  constructor(props) {
    super(props);

    let company;
    let contactuuid = null;
    if (!props.selectedItem) {
      const contact = Helper.getBlankContact(props.appState.salesperson, Constants.CUSTOMER_FAMILY);
      company = Helper.getBlankCompany(Constants.CUSTOMER_FAMILY, [contact], props.appState.salesperson, true);
      contactuuid = contact.contactuuid;
    } else if (props.selectedItem.type === Constants.COMPANY) {
      company = Helper.deepCopy(props.selectedItem);
      contactuuid = company.contacts[0].contactuuid;
    } else if (props.selectedItem.type === Constants.CONTACT) {
      company = Helper.deepCopy(props.selectedItem.company);
      contactuuid = props.selectedItem.contactuuid;
      // Copy the contact's taxable flag to the company (selectedItem)
      company.taxable = props.selectedItem.taxable;
    }

    // Move the selected contact to front of list in company, if necessary
    //    to prevent the brief display of the "natural order"
    company = Helper.moveContactToFront(contactuuid, company);

    this.state = {
      ...this.state,
      company: company, // Full company and contact list - Used for most everything
      contactuuid: contactuuid, // Selected contactuuid from customer list
      viewName: Constants.CUSTOMER,
    };
  }

  // Once component is loaded, fire GET request
  componentDidMount() {
    super.componentDidMount();
    if (this.state.company?.companyuuid) {
      this.setState({ isNew: false });
      this.getCompany(this.state.company.companyuuid);
    }
  }

  // Delete all of the contacts and the company.
  handleDelete = () => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete?",
      callback: this.maybeDeleteCompany,
      key: this.state.company.companyuuid,
    });
  };

  maybeDeleteCompany = (response, companyuuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      // If billing is enabled, make sure the company does not have any active subscriptions
      if (
        this.state.company?.companyuuid &&
        this.props.appState.features?.includes(Constants.FEATURE_BILLING) &&
        this.props.appState.maast.merchant_id
      ) {
        this.getSubscriptions(this.state.company?.companyuuid, true, 0, () => {
          if (this.state.maastSubscriptions?.length > 0) {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "Cannot delete customer with active subscriptions.",
            });
          } else {
            this.deleteCompany(companyuuid);
          }
        });
      } else {
        this.deleteCompany(companyuuid);
      }
    }
  };

  isReadyToSubmit = () => {
    // If we have a company name, we must also have a companyuuid,
    return this.state.company?.contacts[0].firstname && this.state.company?.contacts[0].lastname;
  };

  save = () => {
    if (this.isReadyToSubmit()) {
      // Grab the contact being saved
      let contact = this.state.company?.contacts[0];
      // Copy the company info onto the contact
      contact.companyname = this.state.company?.companyname;
      contact.companyuuid = "";
      contact.contactuuid = "";
      // Save to database
      this.postContact(contact);
    }
  };

  setStateContacts = (contacts, callback = null) => {
    this.setState(
      prevState => ({
        company: {
          ...prevState.company,
          contacts: contacts,
        },
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  updateCompanyState = (id, value) => {
    this.setState(prevState => ({
      company: {
        ...prevState.company,
        [id]: value,
      },
    }));
  };

  getTotalPrice = () => {
    return null;
  };

  getContactsContainerRef = () => {
    return this.state.company;
  };

  getCompany = companyuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const params = { companyuuid: companyuuid };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        let company = response.body;

        // Convert the discount from a decimal to a percentage
        company.discount = Helper.formatPercent(company.discount);

        // Move the selected contact to front of list in company, if necessary
        company = Helper.moveContactToFront(this.state.contactuuid, company);

        //Update state with response, turn off downloading
        this.setState(
          {
            company: { ...company, type: Constants.COMPANY },
            error: null,
            downloading: false,
            isNew: false,
          },
          () => {
            this.props.updateBreadcrumb({ selectedItem: this.state.company });
            // If the customer is vaulted, then fire async request to get the vaulted payment methods
            if (this.state.company?.vaulted) {
              this.getCustomerFromVault(this.state.company?.companyuuid, foundCustomer => {
                if (foundCustomer && this.state.maastCustomer?.vaulted) {
                  this.getSubscriptions(this.state.company?.companyuuid, true);
                }
              });
            }
            // Hide overlay after database action is complete
            this.props.hideOverlay();
          }
        );
      } else if (response.status === 404) {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "The selected item is no longer available. It may have been deleted.",
          callback: this.props.badBreadcrumb,
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "GET", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error loading the data.",
          });
        });
      }
    });
  };

  // Create a new contact in the database. If a company does not exist for the contact,
  //    a new one will be created by the web service.
  postContact = contact => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_CONTACTS;
    const companyuuid = this.state.company?.companyuuid ? this.state.company.companyuuid : "";
    const newcompany = companyuuid === "";
    const companytype = contact.companyname ? Constants.CUSTOMER_COMPANY : Constants.CUSTOMER_FAMILY;
    if (this.state.isNew) {
      contact.taxable = this.state.company?.taxable;
      contact.discount = numeral(this.state.company?.discount ?? 0).value();
    }
    contact = { ...contact, companyuuid: companyuuid, companytype: companytype };
    Helper.postData(url, contact).then(response => {
      if (response.status === 200 && response.body) {
        const companyuuid = response.body.companyuuid;
        if (newcompany) {
          this.getCompany(companyuuid);
        } else {
          // Set the edit mode to keep the new contact's card open
          const contacts = this.state.company.contacts.map(contact => {
            if (contact.contactuuid === "new") {
              response.body.editmode = true;
              return response.body;
            }
            return contact;
          });
          // Update state with response, turn off downloading and hide the overlay
          this.setStateContacts(contacts, () => {
            this.setState({ downloading: false });
            this.props.hideOverlay();
          });
        }
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the contact.",
          });
        });
      }
    });
  };

  postShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = "/addresses";
    const address = {
      ...Helper.getContactByUUID(this.state.company, contactuuid).shippingaddress,
      contactuuid: contactuuid,
    };
    Helper.postData(url, address).then(response => {
      if (response.status === 200 && response.body) {
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "POST" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error creating the shipping address.",
          });
        });
      }
    });
  };

  putCompany = (companyuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    let data = {
      companyuuid: companyuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        //Update state with company attributes and to turn off downloading
        let company = response.body;

        // Convert the discount from a decimal to a percentage
        company.discount = Helper.formatPercent(company.discount);

        this.setState(prevState => ({ downloading: false, company: { ...prevState.company, ...Helper.copyPrimitives(company) } }));
        this.props.hideOverlay();
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the customer.",
          });
        });
      }
    });
  };

  putShipAddress = (addressuuid, fieldname, value) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });
    //set up to make database call
    const url = "/addresses";
    const data = {
      addressuuid: addressuuid,
      [fieldname]: value,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Replace contact with data from web service but keep editing flag
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.shippingaddress.addressuuid === addressuuid) {
            contact.shippingaddress = response.body;
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "PUT" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error updating the shipping address.",
          });
        });
      }
    });
  };

  deleteContact = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    // If new contact, just remove from list
    if (contactuuid === "new") {
      const contacts = this.state.company.contacts.filter(contact => contact.contactuuid !== "new");
      this.setStateContacts(contacts, () => {
        this.setState({ downloading: false });
        this.props.hideOverlay();
      });
    } else {
      //set up to make database call
      const url = Constants.URL_CONTACTS;
      const params = { contactuuid: contactuuid };
      Helper.deleteData(url, params).then(response => {
        if (response.status === 200 && response.body) {
          // Remove the deleted contact from the list and redisplay
          const contacts = this.state.company.contacts.filter(contact => contact.contactuuid !== contactuuid);
          // If this was the last contact, then the webservice will delete the company
          if (contacts.length === 0) {
            //Update state to turn off downloading
            this.setState({ downloading: false, error: null });
            //Hide overlay after database action is complete
            this.props.hideOverlay();
            //Return to the List screen
            this.props.followBreadcrumb();
          } else {
            // Update state with response, turn off downloading and hide the overlay
            this.setStateContacts(contacts, () => {
              this.setState({ downloading: false });
              this.props.hideOverlay();
            });
          }
        } else if (response.status === 409 || response.status === 202) {
          // A 202 indicates the contact was made inactive, not deleted
          if (response.status === 202) {
            // Update the contact to be inactive
            const contacts = this.state.company.contacts.map(contact => {
              if (contact.contactuuid === contactuuid) {
                contact.active = false;
              }
              return contact;
            });
            // Update state with updated contact(s), turn off downloading and hide the overlay
            this.setStateContacts(contacts, () => {
              this.setState({ error: null, downloading: false }, () => {
                this.props.showOverlay({
                  type: Constants.OVERLAY_MESSAGE,
                  text: response.body.message,
                });
              });
            });
          } else {
            // A 409 indicates the contact was not deleted because it is a protected contact
            // For example, the "walk-in" customer or "stock order" contact
            this.setState({ error: null, downloading: false }, () => {
              this.props.showOverlay({
                type: Constants.OVERLAY_MESSAGE,
                text: response.body.message,
              });
            });
          }
        } else if (response.status === 503) {
          this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
          this.props.hideOverlay();
        } else {
          this.setState({ error: "DELETE", downloading: false }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: "There was an error deleting the contact.",
            });
          });
        }
      });
    }
  };

  deleteCompany = companyuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_COMPANIES;
    const params = { companyuuid: companyuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Update state to turn off downloading, hide the overlay, and follow breadcrumbs
        this.setState({ downloading: false, error: null }, () => {
          this.props.hideOverlay();
          this.props.followBreadcrumb();
        });
      } else if (response.status === 202) {
        // A 202 indicates the company was made inactive, not deleted
        // Update all contacts to be inactive
        const contacts = this.state.company.contacts.map(contact => {
          contact.active = false;
          return contact;
        });
        // Update state with updated contact(s), turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ error: null, downloading: false }, () => {
            this.props.showOverlay({
              type: Constants.OVERLAY_MESSAGE,
              text: response.body.message,
            });
          });
        });
      } else if (response.status === 409) {
        // A 409 indicates the company was not deleted because one or more contacts have existing orders
        this.setState({ error: null, downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body.message,
          });
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "DELETE", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting.",
          });
        });
      }
    });
  };

  deleteShipAddress = contactuuid => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    const contact = Helper.getContactByUUID(this.state.company, contactuuid);
    const addressuuid = contact.shippingaddress.addressuuid;
    //set up to make database call
    const url = "/addresses";
    const params = { addressuuid: addressuuid };
    Helper.deleteData(url, params).then(response => {
      if (response.status === 200 && response.body) {
        // Turn off the shipping address switch
        const contacts = this.state.company.contacts.map(contact => {
          if (contact.contactuuid === contactuuid) {
            contact.hasshippingaddress = false;
            contact.shippingaddress = Helper.getBlankShippingAddress();
          }
          return contact;
        });
        // Update state with response, turn off downloading and hide the overlay
        this.setStateContacts(contacts, () => {
          this.setState({ downloading: false });
          this.props.hideOverlay();
        });
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ downloading: false });
        this.setState({ error: "DELETE" }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: "There was an error deleting.",
          });
        });
      }
    });
  };
}
export default Customer;

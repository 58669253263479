function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="32" viewBox="0 0 51 32">
      <g id="Group_677" data-name="Group 677" transform="translate(-758 -453)">
        <path
          id="Path_57"
          data-name="Path 57"
          d="M492.908,330.2a3.732,3.732,0,0,0-1.606.012,6.026,6.026,0,0,0-3.311,2.107,2.376,2.376,0,0,1,.492,1.073l1.142-.486a.392.392,0,1,1,.307.722l-1.412.6v.846l1.412.6a.393.393,0,0,1-.154.754.388.388,0,0,1-.154-.032l-1.245-.53a2.392,2.392,0,0,1-.35.642c2.428,2.76,4.855,2.08,4.88,2.072a2.835,2.835,0,0,0,1.712-2.48,10.46,10.46,0,0,0-.129-4.059A2.528,2.528,0,0,0,492.908,330.2Z"
          transform="translate(287.342 128.698)"
          fill="none"
        />
        <path
          id="Path_58"
          data-name="Path 58"
          d="M486.63,346.052l1.334,3.128,1.262-1.4a.392.392,0,0,1,.33-.128l1.826.181-1.083-2.539a6.806,6.806,0,0,1-3.616-2.278,2.46,2.46,0,0,1-.366.2h.313Z"
          transform="translate(288.117 122.729)"
          fill="none"
        />
        <path
          id="Path_59"
          data-name="Path 59"
          d="M482.033,334.02H480.72a1.6,1.6,0,0,0-1.6,1.6v1.246a1.6,1.6,0,0,0,1.6,1.6h1.313a1.6,1.6,0,0,0,1.6-1.6v-1.246A1.6,1.6,0,0,0,482.033,334.02Z"
          transform="translate(291.447 126.893)"
          fill="none"
        />
        <path
          id="Path_61"
          data-name="Path 61"
          d="M471.23,332.9l1.117.475a2.38,2.38,0,0,1,.5-1.078c-2.437-2.794-4.88-2.109-4.905-2.1a2.528,2.528,0,0,0-1.582,1.843,10.448,10.448,0,0,0-.129,4.059,2.836,2.836,0,0,0,1.714,2.48,3.707,3.707,0,0,0,1.594-.009,5.984,5.984,0,0,0,3.269-2.047,2.387,2.387,0,0,1-.359-.648l-1.221.519a.388.388,0,0,1-.154.032.392.392,0,0,1-.154-.754l1.385-.59v-.87l-1.385-.59a.392.392,0,1,1,.307-.722Z"
          transform="translate(297.477 128.699)"
          fill="none"
        />
        <path
          id="Path_63"
          data-name="Path 63"
          d="M472.025,345.468l-1.083,2.539,1.826-.181a.392.392,0,0,1,.33.128l1.262,1.4,1.088-2.55v-3.412A6.721,6.721,0,0,1,472.025,345.468Z"
          transform="translate(295.234 122.555)"
          fill="none"
        />
        <path
          id="Path_67"
          data-name="Path 67"
          d="M482.437,328.771a4.381,4.381,0,0,0-1.937-.016,6.771,6.771,0,0,0-3.792,2.364h-4.065v-.26a6.663,6.663,0,0,0-3.543-2.1,4.388,4.388,0,0,0-1.942.016,3.22,3.22,0,0,0-2.114,2.386,7.719,7.719,0,0,0-.162.763h.258v4.065h-.159a3.305,3.305,0,0,0,2.179,2.673,3.66,3.66,0,0,0,.963.111c.062,0,.127,0,.194,0l-1.162,2.724a.393.393,0,0,0,.4.545l2.288-.227,1.541,1.709a.393.393,0,0,0,.292.129.37.37,0,0,0,.064-.005.393.393,0,0,0,.3-.233l.607-1.423v-2l-1.088,2.55-1.262-1.4a.392.392,0,0,0-.33-.128l-1.826.181,1.083-2.539a6.72,6.72,0,0,0,3.422-2.076v-.006h.006c.059-.063.118-.118.177-.184a2.412,2.412,0,0,0,.347.184h3.222a2.462,2.462,0,0,0,.366-.2,6.806,6.806,0,0,0,3.616,2.278l1.083,2.539-1.826-.181a.392.392,0,0,0-.33.128l-1.262,1.4-1.334-3.128v2l.854,2a.393.393,0,0,0,.3.233.37.37,0,0,0,.064.005.393.393,0,0,0,.292-.129l1.54-1.709,2.288.227a.393.393,0,0,0,.4-.545l-1.162-2.724c.067,0,.131,0,.193,0a3.664,3.664,0,0,0,.966-.112,3.446,3.446,0,0,0,2.25-3.054h-.273v-4.065h.225c-.028-.127-.054-.255-.088-.381A3.222,3.222,0,0,0,482.437,328.771Zm-12.076,4.179,1.385.59v.87l-1.385.59a.392.392,0,0,0,.154.754.388.388,0,0,0,.154-.032l1.221-.519a2.386,2.386,0,0,0,.359.648,5.984,5.984,0,0,1-3.269,2.047,3.708,3.708,0,0,1-1.594.009,2.836,2.836,0,0,1-1.714-2.48,10.449,10.449,0,0,1,.129-4.059,2.528,2.528,0,0,1,1.582-1.843c.025-.008,2.469-.692,4.905,2.1a2.38,2.38,0,0,0-.5,1.078l-1.117-.475a.392.392,0,1,0-.307.722Zm6.678,1.435a1.6,1.6,0,0,1-1.6,1.6h-1.313a1.6,1.6,0,0,1-1.6-1.6v-1.246a1.6,1.6,0,0,1,1.6-1.6h1.313a1.6,1.6,0,0,1,1.6,1.6Zm6.886,1.041a2.835,2.835,0,0,1-1.712,2.48c-.025.008-2.451.688-4.88-2.072a2.394,2.394,0,0,0,.35-.642l1.245.53a.388.388,0,0,0,.154.032.392.392,0,0,0,.154-.754l-1.412-.6v-.846l1.412-.6a.392.392,0,1,0-.307-.722l-1.142.486a2.376,2.376,0,0,0-.492-1.073,6.026,6.026,0,0,1,3.311-2.107,3.732,3.732,0,0,1,1.606-.012,2.528,2.528,0,0,1,1.584,1.843A10.459,10.459,0,0,1,483.925,335.426Z"
          transform="translate(298.039 129.375)"
          fill="#fff"
        />
        <path
          id="Path_70"
          data-name="Path 70"
          d="M481.85,344.062l-2.519,5.293v13.677h4.75V348.75Z"
          transform="translate(290.996 121.879)"
          fill="#fff"
        />
        <path
          id="Path_71"
          data-name="Path 71"
          d="M482.269,343.58a2.362,2.362,0,0,0,.814-.2h-3.222a2.363,2.363,0,0,0,.842.2l-1.372,3.217v2l2.155-5.053,1.909,4.475v-2Z"
          transform="translate(291.351 122.561)"
          fill="#fff"
        />
        <path
          id="Path_72"
          data-name="Path 72"
          d="M479.331,343.38v.006l.006-.006Z"
          transform="translate(291.351 122.561)"
          fill="#fff"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M479.568,328.165a2.186,2.186,0,0,1,1.249-.4h1.313a2.188,2.188,0,0,1,1.266.408V320.6h-4.065v7.283C479.41,327.98,479.489,328.065,479.568,328.165Z"
          transform="translate(291.351 132.614)"
          fill="#fff"
        />
        <path
          id="Path_74"
          data-name="Path 74"
          d="M483.4,332.924a2.365,2.365,0,0,0-1.266-.365h-1.313a2.364,2.364,0,0,0-1.249.354c-.079-.09-.158-.166-.237-.25v.26Z"
          transform="translate(291.351 127.57)"
          fill="#fff"
        />
        <path
          id="Path_75"
          data-name="Path 75"
          d="M456.748,338.347a19.692,19.692,0,0,1-.008-3.616h-4.051V338.8H456.8C456.781,338.649,456.761,338.5,456.748,338.347Z"
          transform="translate(306.377 126.564)"
          fill="#fff"
        />
        <path
          id="Path_76"
          data-name="Path 76"
          d="M465.028,334.731h-.258a12.222,12.222,0,0,0,.012,3.616c.022.156.054.3.086.449h.159Z"
          transform="translate(298.151 126.564)"
          fill="#fff"
        />
        <path
          id="Path_77"
          data-name="Path 77"
          d="M501.669,334.02a11.328,11.328,0,0,1,.064,4c0,.023-.009.044-.013.067h25.545V334.02Z"
          transform="translate(280.614 126.893)"
          fill="#fff"
        />
        <path
          id="Path_78"
          data-name="Path 78"
          d="M501.251,338.085h.273c0-.023.01-.044.013-.067a11.737,11.737,0,0,0-.062-4h-.225Z"
          transform="translate(281.204 126.893)"
          fill="#fff"
        />
        <g
          id="Ellipse_6"
          data-name="Ellipse 6"
          transform="translate(770 460)"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
        >
          <circle cx="3" cy="3" r="3" stroke="none" />
          <circle cx="3" cy="3" r="2.5" fill="none" />
        </g>
        <g
          id="Rectangle_350"
          data-name="Rectangle 350"
          transform="translate(758 453)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        >
          <rect width="51" height="32" rx="6" stroke="none" />
          <rect x="1" y="1" width="49" height="30" rx="5" fill="none" />
        </g>
      </g>
    </svg>
  );
}
export default render;

function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 184">
            <g data-name="Group 69">
              <path
                data-name="Path 79"
                d="M115.63,0H21.88A21.89,21.89,0,0,0,0,21.87V78.12A21.9,21.9,0,0,0,21.88,100h93.75a21.9,21.9,0,0,0,21.88-21.88V21.87A21.9,21.9,0,0,0,115.63,0Z"
              />
              <path
                data-name="Path 80"
                d="M115.63,125H21.88A21.9,21.9,0,0,0,0,146.87V278.12A21.9,21.9,0,0,0,21.88,300h93.75a21.9,21.9,0,0,0,21.88-21.88V146.87A21.9,21.9,0,0,0,115.63,125Z"
              />
              <path
                data-name="Path 81"
                d="M278.13,200H184.37a21.91,21.91,0,0,0-21.87,21.88v56.25A21.9,21.9,0,0,0,184.37,300h93.76A21.9,21.9,0,0,0,300,278.12V221.91A21.9,21.9,0,0,0,278.13,200Z"
              />
              <path
                data-name="Path 82"
                d="M278.13,0H184.37A21.89,21.89,0,0,0,162.5,21.87V153.12A21.91,21.91,0,0,0,184.37,175h93.76A21.9,21.9,0,0,0,300,153.12V21.87A21.89,21.89,0,0,0,278.13,0Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";

// Functions
import * as Helper from "./Helper";
import * as Constants from "./Constants";

class InvoiceList extends BaseListViewComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      hideFilter: true,
      online_count: "",
    };
  }

  getHeaderRowItems = () => {
    if (this.props.filtertype.tab !== Constants.ORDER_STATUS_ONLINE_UNSHIPPED) {
      return this.getHeaderRowItemsCommon();
    } else {
      return this.getHeaderRowItemsOnline();
    }
  };

  getHeaderRowItemsCommon = () => {
    return [
      { classes: "header firstLeft", sortable: true, columnheading: "Date", sortkey: "orders.creationdatetime" },
      { classes: "header desktop", sortable: true, columnheading: "Invoice", sortkey: "orders.ordernumber" },
      { classes: "header", sortable: true, columnheading: "Customer", sortkey: "orders.contactname" },
      { classes: "header desktop", sortable: true, columnheading: "Product", sortkey: "first_item.productname" },
      { classes: "header right-aligned", sortable: true, columnheading: "Total", sortkey: "totalpriceplustax" },
      { classes: "header desktop centerAligned lastRight", sortable: true, columnheading: "Status", sortkey: "orders.orderstatus" },
    ];
  };

  getHeaderRowItemsOnline = () => {
    return [
      { classes: "header firstLeft", sortable: true, columnheading: "Date", sortkey: "orders.creationdatetime" },
      { classes: "header desktop", sortable: true, columnheading: "Invoice", sortkey: "orders.ordernumber" },
      { classes: "header desktop", sortable: true, columnheading: "Source", sortkey: "orders.contactname" },
      { classes: "header desktop", sortable: true, columnheading: "Order ID", sortkey: "orders.externalid" },
      { classes: "header", sortable: true, columnheading: "Customer", sortkey: "orders.contactname" },
      { classes: "header desktop ", sortable: true, columnheading: "Product", sortkey: "first_item.productname" },
      { classes: "header right-aligned", sortable: true, columnheading: "Total", sortkey: "totalpriceplustax" },
      { classes: "header desktop centerAligned lastRight", sortable: true, columnheading: "Status", sortkey: "orders.orderstatus" },
    ];
  };

  getOrderType = () => {
    return Constants.INVOICE;
  };

  renderItemToColumns = item => {
    // Each tab has the same columns to display, except for the online tab and it has two additional columns.
    const mobileCreationDate = <span data-testid="Mobile Creation Date"> {Helper.formatDate(item.creationdatetime, true)}</span>;
    const desktopCreationDate = <span data-testid="Desktop Creation Date"> {Helper.formatDate(item.creationdatetime, false)}</span>;
    const orderNumber = Helper.renderOrderNumber(item?.ordertype, item?.ordersubtype, item?.ordernumber, item?.externalid, this.props.filtertype);
    const salesperson = <span data-testid="Sales Person">{item.salesperson}</span>;
    const externalid = <span data-testid="External ID">{item.externalid}</span>;
    const customername = Helper.renderCustomerName(item.contactname, item.companyname);
    const productname = Helper.renderProductName(item.productname, item.itemcount);
    const totalPricePlusTax = Helper.renderTotalPricePlusTax(item.totalpriceplustax);
    const orderstatus = Helper.renderStatus(item.orderstatus, item.ordersubtype);

    // If filter type is not online, don't show salesperson and externalid
    if (this.props.filtertype.tab !== Constants.ORDER_STATUS_ONLINE_UNSHIPPED) {
      return [
        { rowvalue: mobileCreationDate, classes: "mobile" },
        { rowvalue: desktopCreationDate, classes: "desktop" },
        { rowvalue: orderNumber, classes: "desktop" },
        { rowvalue: customername },
        { rowvalue: productname, classes: "desktop" },
        { rowvalue: totalPricePlusTax, classes: "right-aligned" },
        { rowvalue: orderstatus, classes: "desktop right-aligned" },
      ];
    } else {
      return [
        { rowvalue: mobileCreationDate, classes: "mobile" },
        { rowvalue: desktopCreationDate, classes: "desktop" },
        { rowvalue: orderNumber, classes: "desktop" },
        { rowvalue: salesperson, classes: "desktop" },
        { rowvalue: externalid, classes: "desktop" },
        { rowvalue: customername },
        { rowvalue: productname, classes: "desktop" },
        { rowvalue: totalPricePlusTax, classes: "right-aligned" },
        { rowvalue: orderstatus, classes: "desktop right-aligned" },
      ];
    }
  };

  renderTopControlButtons = () => {
    let openTabClassName = "action-tab";
    let processedTabClassName = "action-tab";
    let todayTabClassName = "action-tab";
    let allTabClassName = "action-tab";
    let unshippedTabClassName = "action-tab desktop";

    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      openTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      processedTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_TODAY) {
      todayTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      allTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ONLINE_UNSHIPPED) {
      unshippedTabClassName += " selected ";
    }
    let online = "";
    let online_count = "";
    if (this.state.online_count > 0 && this.state.online_count <= 9) {
      online_count = <span className="menuItemNotification">{this.state.online_count}</span>;
    } else if (this.state.online_count > 9) {
      online_count = <span className="menuItemNotification">9+</span>;
    }
    if (this.props.appState?.thirdparty?.reverbapikey || this.props.appState?.thirdparty?.cartloomsellerid) {
      online = (
        <span
          className={unshippedTabClassName}
          title="Open invoices from online sources that have not been shipped"
          onClick={() => {
            this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_ONLINE_UNSHIPPED });
            this.handleFilterList({ tab: Constants.ORDER_STATUS_ONLINE_UNSHIPPED });
          }}
        >
          Online {online_count}
        </span>
      );
    }

    return (
      <React.Fragment>
        <div className="orderlisttopcontrols">
          <span
            className={todayTabClassName}
            title="Invoices created today"
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_TODAY });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_TODAY });
            }}
          >
            Today
          </span>
          <span
            className={openTabClassName}
            title="Invoice that have not been paid"
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_OPEN });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_OPEN });
            }}
          >
            Open
          </span>
          <span
            className={processedTabClassName}
            title="Paid and/or shipped invoices"
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_PROCESSED });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_PROCESSED });
            }}
          >
            Processed
          </span>
          <span
            className={allTabClassName}
            title="All invoices. Like, every single one!"
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.ORDER_STATUS_ALL });
              this.handleFilterList({ tab: Constants.ORDER_STATUS_ALL });
            }}
          >
            All
          </span>
          {online}
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance">
          <span></span>
          {this.renderCalendarFilter()}
        </span>
      </React.Fragment>
    );
  };

  getListGridClassName = () => {
    if (this.props.filtertype.tab === Constants.ORDER_STATUS_OPEN) {
      return "invoicelist open";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_PROCESSED) {
      return "invoicelist processed";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_TODAY) {
      return "invoicelist processed";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ALL) {
      return "invoicelist all";
    } else if (this.props.filtertype.tab === Constants.ORDER_STATUS_ONLINE_UNSHIPPED) {
      return "invoicelist onlineunshipped";
    }
  };
}
export default InvoiceList;

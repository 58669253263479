function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 326.21 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 67">
            <g data-name="Group 63">
              <g data-name="Group 62">
                <path
                  data-name="Path 71"
                  d="M255.37,73,218.53,86.48l13.41,36.84-36.85,13.41L181.63,99.88l-36.84,13.47a9.78,9.78,0,0,0-5.86,12.54v0l26.87,73.81a48.25,48.25,0,0,1,12.39,5.77l110.66-40.28a9.79,9.79,0,0,0,5.86-12.54v0L267.88,79A9.8,9.8,0,0,0,255.37,73Z"
                />
              </g>
            </g>
            <g data-name="Group 65">
              <g data-name="Group 64">
                <path
                  data-name="Path 72"
                  d="M191.72,12.74,164.08,22.8l6.73,18.42L152.39,48l-6.73-18.43L118,39.59a9.81,9.81,0,0,0-5.86,12.57l16.76,46.05L221,64.68,204.28,18.62A9.81,9.81,0,0,0,191.72,12.74Z"
                />
              </g>
            </g>
            <g data-name="Group 66">
              <path
                data-name="Path 73"
                d="M325.28,193.93a14.86,14.86,0,0,0-19-8.89L176.14,232.39a40.16,40.16,0,0,0-29.56-13.6L79,33.19a14.79,14.79,0,0,0-7.69-8.39L21.09,1.37A14.86,14.86,0,0,0,8.52,28.3L53.2,49.16l65.4,179.57a40.69,40.69,0,1,0,67.5,31.67L316.43,213a14.88,14.88,0,0,0,8.88-19.05ZM164.5,259.32a19,19,0,1,1-19-19,18.54,18.54,0,0,1,19,18.07c0,.33,0,.65,0,1Z"
              />
              <path data-name="Path 74" d="M138.75,259.39a6.72,6.72,0,1,0,0-.05Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

import React from "react";

// Components
import BaseListViewComponent from "./BaseListViewComponent";
import TextsPanel from "./TextsPanel";

// Functions
import { faArrowUpRightFromSquare, faChain, faSpinner, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "./Constants";
import * as Helper from "./Helper";
import he from "he";

class MessageList extends BaseListViewComponent {
  constructor(props) {
    super(props);
    this.textPanelRef = React.createRef();
    this.contactSearchRef = React.createRef();
    const twilio_unread_count = props.appState?.twilio?.unread_count ? Math.max(0, props.appState.twilio.unread_count) : 0;

    this.state = {
      ...this.state,
      hideFilter: true,
      newMessage: "",
      downloadingTexts: false,
      twilio_unread_count: twilio_unread_count,
      showsearch: true,
    };
  }

  componentDidUpdate(prevProps) {
    // If the unread count changes, update state
    if (prevProps.appState?.twilio?.unread_count !== this.props.appState?.twilio?.unread_count) {
      const twilio_unread_count = this.props.appState?.twilio?.unread_count ? Math.max(0, this.props.appState.twilio.unread_count) : 0;
      this.setState({ twilio_unread_count: twilio_unread_count });
    }
  }

  getHeaderRowItems = () => {
    return null;
  };

  getOrderType = () => {
    return Constants.MESSAGE;
  };

  renderItemToColumns = item => {
    if (this.props.filtertype.tab === Constants.EMAIL) {
      return this.renderItemToColumnsMail(item);
    } else if (this.props.filtertype.tab === Constants.TEXTS) {
      return this.renderItemToColumnsText(item);
    }
  };

  renderItemToColumnsMail = item => {
    let fullname = (item.companyname ? item.companyname : item.firstname + " " + item.lastname) || item.email;
    if (!fullname.trim()) {
      fullname = item.email;
    }
    fullname = (
      <span data-testid="Email Name" title={item.email}>
        {fullname}
      </span>
    );
    const creationDate = <span data-testid="Email Date">{Helper.formatDate(item.creationdatetime, false)}</span>;
    let rows = [
      // Mobile date view will not be shown on mobile due to space issues
      // { rowvalue: Helper.formatDate(item.creationdatetime, true), classes: "mobile" },
      // Desktop date view:
      { rowvalue: creationDate, classes: "desktop" },
      { rowvalue: fullname },
    ];
    return rows;
  };

  renderItemToColumnsText = item => {
    // const fullname = item.companyname ? item.companyname : item.firstname + " " + item.lastname;
    const unread =
      item.unread_count > 0 ? (
        <span data-testid="SMS Unread Count" className="unreadCount">
          {item.unread_count}
        </span>
      ) : (
        ""
      );
    const fullname = (
      <span>
        <span data-testid="SMS Contact Name" className="unreadCountLabel">
          {Helper.renderSmsContactName(item)}
        </span>{" "}
        {unread} <FontAwesomeIcon icon={faChain} onClick={this.handleAssociateCustomerSMS} className="ghost" />
        <br />
        <span data-testid="SMS Contact Number" className="ghost">
          {item.phonenumber}
        </span>
      </span>
    );
    const lastMessageDate = <span data-testid="Last Message Date">{Helper.formatDate(item.lastmessagedatetime, false)}</span>;
    let rows = [
      // TODO: Mobile date view will not be shown on mobile due to space issues
      // { rowvalue: Helper.formatDate(item.lastmessagedatetime, true), classes: "mobile" },
      // Desktop date view:
      { rowvalue: lastMessageDate, classes: "desktop" },
      { rowvalue: fullname },
    ];
    return rows;
  };

  renderTopControlButtons = () => {
    let textTabClassName = "action-tab";
    let mailTabClassName = "action-tab";

    if (this.props.filtertype.tab === Constants.EMAIL) {
      mailTabClassName += " selected ";
    } else if (this.props.filtertype.tab === Constants.TEXTS) {
      textTabClassName += " selected ";
    }
    let textLabel = "Texts";
    if (this.state.twilio_unread_count) {
      textLabel += " (" + this.state.twilio_unread_count + ")";
    }
    let textTab = "";
    // If texting is not part of your plan, then display an upgrade message
    if (this.props.appState.features.includes(Constants.FEATURE_TEXT)) {
      if (this.props.appState.twilio?.phonenumber?.length > 0) {
        textTab = (
          <span
            className={textTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.TEXTS });
              this.handleFilterList({ tab: Constants.TEXTS });
            }}
          >
            {textLabel}
          </span>
        );
      } else {
        textTab = (
          <span
            className={textTabClassName + " save-disabled"}
            onClick={() => {}}
            title="Text messaging is not setup. Contact tech support for assistance."
          >
            {textLabel}
          </span>
        );
      }
    } else {
      textTab = (
        <span
          className={textTabClassName + " save-disabled"}
          onClick={() => {}}
          title="Text messaging is not part of your plan. Contact your sales represenative to request an upgrade."
        >
          {textLabel}
        </span>
      );
    }

    return (
      <React.Fragment>
        <div className="orderlisttopcontrols">
          <span
            className={mailTabClassName}
            onClick={() => {
              this.props.handleChangeTabView({ tab: Constants.EMAIL });
              this.handleFilterList({ tab: Constants.EMAIL });
            }}
          >
            Email
          </span>
          {textTab}
        </div>
        <span className="desktop"></span>
        <span className="action-tab-balance"></span>
      </React.Fragment>
    );
  };

  renderMobileDetailSelector = () => {
    let uuid = "";
    if (this.state.selectedListItems?.length > 0) {
      uuid = this.state.selectedListItems[0].uuid;
    }
    return (
      <div className="mobile settingCategory">
        Select: &nbsp;
        <select
          name="settingCategory"
          onChange={event => {
            this.setState(
              {
                selectedListItems: this.state.listItems.filter(item => item.uuid === event.target.value),
              },
              () => {
                if (this.props.filtertype.tab === Constants.EMAIL) {
                  this.handleAfterSelectEmailContact(this.state.selectedListItems[0]);
                } else if (this.props.filtertype.tab === Constants.TEXTS) {
                  this.handleAfterSelectSmsContact(this.state.selectedListItems[0]);
                }
              }
            );
          }}
          value={uuid}
        >
          {this.renderSettingCategoryOptions()}
        </select>
      </div>
    );
  };

  renderSettingCategoryOptions = () => {
    return this.state.listItems.map(option => {
      return (
        <option key={option.email} value={option.uuid} id={option.uuid}>
          {option.firstname} {option.lastname}
        </option>
      );
    });
  };

  renderDetailPanel = () => {
    if (this.props.filtertype.tab === Constants.EMAIL) {
      return this.renderDetailPanelMessages();
    } else if (this.props.filtertype.tab === Constants.TEXTS) {
      return this.renderDetailPanelTexts();
    }
  };

  renderDetailPanelTexts = () => {
    return (
      <TextsPanel
        selectedListItems={this.state.selectedListItems}
        downloadingTexts={this.state.downloadingTexts}
        newMessage={this.state.newMessage}
        textPanelRef={this.textPanelRef}
        handleGetOlderTexts={this.handleGetOlderTexts}
        handleGetNewTexts={this.handleGetNewTexts}
        handleChangeNewMessage={this.handleChangeNewMessage}
        handleSendNewMessage={this.handleSendNewMessage}
        showNewTextMessage={true}
      />
    );
  };

  renderDetailPanelMessages = () => {
    if (this.state.selectedListItems?.length > 0) {
      const selectedListItem = this.state.selectedListItems[0];
      const messages = selectedListItem.messages?.records;
      if (!messages) {
        return (
          <div key={"loading_messages"}>
            Loading messages... <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        );
      } else {
        const no_message_text = <span className="italic">No message text</span>;
        return messages.map(message => {
          let message_text = message.message_text;
          // Fix for HTML entities, unicode characters, etc.
          if (message_text) {
            message_text = he.decode(message_text);
          }
          let companyname = "";
          if (this.state.selectedListItems?.length > 0) {
            companyname =
              selectedListItem.firstname +
              " " +
              selectedListItem.lastname +
              (selectedListItem.companyname ? ", " : "") +
              selectedListItem.companyname;
          }
          const status =
            message.sendstatus !== Constants.MESSAGE_SENT ? (
              <span className="sendResult sendFailure">
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faTriangleExclamation} /> Send failure
              </span>
            ) : (
              ""
            );
          return (
            <React.Fragment key={message.uuid}>
              <div className="printOnly printMessageCompany ">{companyname}</div>
              <div className="email_card">
                <div className="subject">
                  {message.subject}{" "}
                  <span
                    className="displayEmailIcon"
                    onClick={() => {
                      Helper.handleViewMessage(message);
                    }}
                    data-testid="View Message"
                  >
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </span>
                </div>
                <div>
                  <span className="messageDateTime">{Helper.formatDateTime(message.creationdatetime)}</span>
                  {status}
                </div>
                <div>{message_text || no_message_text}</div>
              </div>
            </React.Fragment>
          );
        });
      }
    } else {
      return <div></div>;
    }
  };

  handleChangeNewMessage = event => {
    this.setState({ newMessage: event.target.value });
  };

  handleSendNewMessage = () => {
    if (this.state.newMessage.length > 0) {
      const selectedListItem = this.state.selectedListItems[0];
      this.postSMS(selectedListItem.smscontactuuid, this.state.newMessage);
    }
  };

  handleGetOlderTexts = () => {
    this.setState({ downloadingTexts: true }, () => {
      let item = Helper.deepCopy(this.state.selectedListItems[0]);
      const messages = this.state.selectedListItems[0]?.messages?.records;
      item.oldmessageuuid = messages[0]?.smsmessageuuid;
      this.handleAfterSelectSmsContact(item);
    });
  };

  handleGetNewTexts = () => {
    this.setState({ downloadingTexts: true }, () => {
      let item = Helper.deepCopy(this.state.selectedListItems[0]);
      const messages = this.state.selectedListItems[0]?.messages?.records;
      item.newmessageuuid = messages[messages.length - 1]?.smsmessageuuid;
      this.handleAfterSelectSmsContact(item);
    });
  };

  handleAfterSelectEmailContact = item => {
    this.props.getEmail(item.email, null, this.props.url, response => {
      if (response.status === 200) {
        this.setState(prevState => ({
          downloading: false,
          selectedListItems: prevState.selectedListItems.map(listItem => {
            if (listItem.uuid === item.uuid) {
              item.messages = response.body;
            }
            return listItem;
          }),
        }));
      } else {
        this.setState({ downloading: false });
      }
    });
  };

  handleAfterSelectSmsContact = item => {
    this.props.getText(item, true, this.props.url, 5, response => {
      if (response.status === 200) {
        // Reverse the records in the response so that the newest message is on the bottom
        response.body.records.reverse();
        this.setState(
          prevState => ({
            downloadingTexts: false,
            selectedListItems: prevState.selectedListItems.map(listItem => {
              if (listItem.uuid === item.uuid) {
                // If this is a request for new messages, then add the new messages to the end of the list and increment the count
                if (item.newmessageuuid) {
                  listItem.messages.records = [...listItem.messages.records, ...response.body.records];
                  listItem.messages.count += response.body.count;
                } else if (item.oldmessageuuid) {
                  // Add a flag to the newly loaded, older messages so that they animate in and remove the flag from the existing messages
                  listItem.messages.records = [
                    ...response.body.records.map(rec => {
                      rec.easeIn = true;
                      rec.easeInActive = false;
                      return rec;
                    }),
                    ...listItem.messages.records.map(rec => {
                      rec.easeIn = false;
                      rec.easeInActive = false;
                      return rec;
                    }),
                  ];
                } else {
                  listItem.messages = response.body;
                }
                // Update the unread message count
                const new_unread_count = response.body.unread_count ?? 0;
                if (new_unread_count !== 0) {
                  // Update the unread count in the app state. Make number negative to increase (add to) the count.
                  this.props.handleReduceTwilioUnreadCount(-new_unread_count);
                }
                listItem.unread_count = response.body?.unread_count ?? listItem.unread_count;
              }
              return listItem;
            }),
          }),
          () => {
            // Scroll to the bottom with smooth animation, if not loading older messages
            if (!item.oldmessageuuid) {
              this.textPanelRef.current.scrollTo({
                top: this.textPanelRef.current.scrollHeight,
                behavior: "smooth",
              });
            }
            // Clear unread count after 3 seconds
            setTimeout(() => {
              this.handleClearUnreadMessages(this.state.selectedListItems[0].uuid);
            }, Constants.SMS_UNREAD_CLEAR_DELAY);
            // This is a hack to get the animation to work. The animation will not work if the state is set immediately after the setState call above.
            setTimeout(() => {
              this.setState(prevState => ({
                selectedListItems: prevState.selectedListItems.map(listItem => {
                  if (listItem.uuid === item.uuid) {
                    listItem.messages.records = listItem.messages.records.map(rec => {
                      rec.easeIn = true;
                      rec.easeInActive = true;
                      return rec;
                    });
                  }
                  return listItem;
                }),
              }));
            }, 300);
          }
        );
      } else {
        this.setState({ downloadingTexts: false });
      }
    });
  };

  handleAssociateCustomerSMS = () => {
    const overlay = {
      type: Constants.OVERLAY_SEARCH_CONTACTS,
      text: "Select a new contact for this phone number.",
      searchkey: "",
      searchresults: [],
      buttonText: "Select Customer",
      callback: this.handleChangeCustomer,
      inputFieldRef: this.contactSearchRef,
    };
    this.props.showOverlay(overlay);
  };

  handleChangeCustomer = (response, contact = null) => {
    if (response === Constants.OVERLAY_RESPONSE_SELECT) {
      if (contact.contactuuid !== this.state.selectedListItems[0].contactuuid) {
        this.putSmsContact(this.state.selectedListItems[0].smscontactuuid, contact);
      }
    }
    this.props.hideOverlay();
  };

  getListGridClassName = () => {
    if (this.props.filtertype.tab === Constants.EMAIL) {
      return "messagelist";
    } else if (this.props.filtertype.tab === Constants.TEXTS) {
      return "textlist";
    }
  };

  putSmsContact = (smscontactuuid, contact) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_SMS;
    const data = {
      smscontactuuid: smscontactuuid,
      contactuuid: contact.contactuuid,
    };
    Helper.putData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        // Success means we get back an updated smscontact
        this.setState(
          prevState => ({
            listItems: prevState.listItems.map(item => {
              if (item.uuid === smscontactuuid) {
                item.companyname = response.body.companyname;
                item.firstname = response.body.firstname;
                item.lastname = response.body.lastname;
                item.contactuuid = response.body.contactuuid;
              }
              return item;
            }),
            selectedListItems: prevState.selectedListItems.map(item => {
              if (item.uuid === smscontactuuid) {
                item.companyname = response.body.companyname;
                item.firstname = response.body.firstname;
                item.lastname = response.body.lastname;
                item.contactuuid = response.body.contactuuid;
              }
              return item;
            }),
          }),
          () => {
            // Scroll to the bottom with smooth animation
            this.textPanelRef.current.scrollTo({
              top: this.textPanelRef.current.scrollHeight,
              behavior: "smooth",
            });
            this.setState({ downloading: false }, () => {
              this.props.hideOverlay();
            });
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "PUT", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body?.message ?? "There was an error updating the contact.",
          });
        });
      }
    });
  };

  // Not DRY. Also in BaseDetailViewAPI
  postSMS = (smscontactuuid, message) => {
    //Protect screen during downloading data
    this.props.showOverlay();
    this.setState({ downloading: true });

    //set up to make database call
    const url = Constants.URL_SMS;
    const data = {
      smscontactuuid: smscontactuuid,
      message: message,
    };
    Helper.postData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(
          prevState => ({
            newMessage: "",
            selectedListItems: prevState.selectedListItems.map(item => {
              if (item.uuid === smscontactuuid) {
                item.messages.count = item.messages.count + 1;
                item.messages.records = [...item.messages.records, response.body];
              }
              return item;
            }),
          }),
          () => {
            // Scroll to the bottom with smooth animation
            this.textPanelRef.current.scrollTo({
              top: this.textPanelRef.current.scrollHeight,
              behavior: "smooth",
            });
            this.setState({ downloading: false }, () => {
              this.props.hideOverlay();
            });
          }
        );
      } else if (response.status === 503) {
        this.setState({ error: Constants.ERROR_API_NETWORK, downloading: false });
        this.props.hideOverlay();
      } else {
        this.setState({ error: "POST", downloading: false }, () => {
          this.props.showOverlay({
            type: Constants.OVERLAY_MESSAGE,
            text: response.body?.message ?? "There was an error sending the message.",
          });
        });
      }
    });
  };
}
export default MessageList;

import React, { useState, useRef } from "react";
import { useSpring, animated } from "react-spring";

function FaqAnswer(props) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  // Calculate height of the text
  const textHeight = ref.current ? ref.current.clientHeight : 0;

  // Animation spring for expanding and collapsing
  const { height } = useSpring({
    from: { height: 0 },
    to: { height: isOpen ? textHeight : 0 },
  });

  return (
    <div
      className="faqQAContainer"
      onClick={event => {
        setIsOpen(!isOpen);
      }}
    >
      <div className="faqQuestion bold">{props.question}</div>
      <animated.div className="faqAnswer" style={{ overflow: "hidden", height }}>
        <div ref={ref}>
          {props.answer} <div>&nbsp;</div>
        </div>
      </animated.div>
    </div>
  );
}

export default FaqAnswer;

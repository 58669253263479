import React from "react";

import * as Helper from "./Helper";
import * as Constants from "./Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import AddWidget from "./dashboard_widgets/AddWidget";
import ClockWidget from "./dashboard_widgets/ClockWidget";
import DemoWidget from "./dashboard_widgets/DemoWidget";
import NewsWidget from "./dashboard_widgets/NewsWidget";
import MessageWidget from "./dashboard_widgets/MessageWidget";
import TagListWidget from "./dashboard_widgets/TagListWidget";
import WelcomeWidget from "./dashboard_widgets/WelcomeWidget";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.props.addBreadcrumb();
    this.props.checkLogin();

    this.state = {
      widgets: [],
      tagOptions: [],
    };
  }

  componentDidMount() {
    // Load the widgets from the database
    this.getWidgets();
  }

  render = () => {
    const classes = "areaBaseDetailContainer areaDetail" + this.props.appState?.currentView;

    const widget =
      this.props.appState.clientSettings?.PLAN_CODE === Constants.PLAN_CODE_DEMO ? <DemoWidget /> : <NewsWidget appState={this.props.appState} />;
    const widgets = this.state.widgets.map((widget, index) => {
      const key = index + "-" + widget.uuid;
      if (widget.widgettype === Constants.WIDGET_TYPE_MESSAGE) {
        return (
          <MessageWidget
            handleCancel={this.handleCancel}
            handleDelete={this.handleDelete}
            handleEdit={this.handleEdit}
            getWidgetData={this.getWidgetData}
            key={key}
            postWidget={this.postWidget}
            putWidget={this.putWidget}
            widget={widget}
          />
        );
      } else if (widget.widgettype === Constants.WIDGET_TYPE_ADD) {
        return (
          <AddWidget
            getWidgetData={this.getWidgetData}
            handleCancel={this.handleCancel}
            handleDelete={this.handleDelete}
            handleEdit={this.handleEdit}
            handleSetWidgetType={this.handleSetWidgetType}
            key={key}
            widget={widget}
          />
        );
      } else if (widget.widgettype === Constants.WIDGET_TYPE_DEMO) {
        return (
          <DemoWidget
            handleCancel={this.handleCancel}
            handleDelete={this.handleDelete}
            handleEdit={this.handleEdit}
            getWidgetData={this.getWidgetData}
            key={key}
            postWidget={this.postWidget}
            putWidget={this.putWidget}
            widget={widget}
          />
        );
      } else if (widget.widgettype === Constants.WIDGET_TYPE_NEWS) {
        return (
          <NewsWidget
            handleCancel={this.handleCancel}
            handleDelete={this.handleDelete}
            handleEdit={this.handleEdit}
            getWidgetData={this.getWidgetData}
            key={key}
            postWidget={this.postWidget}
            putWidget={this.putWidget}
            widget={widget}
          />
        );
      } else if (widget.widgettype === Constants.WIDGET_TYPE_TAG_LIST) {
        return (
          <TagListWidget
            appState={this.props.appState}
            handleAddTagRef={this.handleAddTagRef}
            handleCancel={this.handleCancel}
            handleDelete={this.handleDelete}
            handleEdit={this.handleEdit}
            getWidgetData={this.getWidgetData}
            handleShowList={this.props.handleShowList}
            handleViewTagRef={this.handleViewTagRef}
            key={key}
            postWidget={this.postWidget}
            putWidget={this.putWidget}
            showOverlay={this.props.showOverlay}
            tagOptions={this.state.tagOptions}
            widget={widget}
          />
        );
      } else if (widget.widgettype === Constants.WIDGET_TYPE_WELCOME) {
        return (
          <WelcomeWidget
            appState={this.props.appState}
            handleCancel={this.handleCancel}
            handleDelete={this.handleDelete}
            handleEdit={this.handleEdit}
            getWidgetData={this.getWidgetData}
            key={key}
            postWidget={this.postWidget}
            putWidget={this.putWidget}
            widget={widget}
          />
        );
      } else if (widget.widgettype === Constants.WIDGET_TYPE_CLOCK) {
        return (
          <ClockWidget
            appState={this.props.appState}
            handleCancel={this.handleCancel}
            handleDelete={this.handleDelete}
            handleEdit={this.handleEdit}
            getWidgetData={this.getWidgetData}
            key={key}
            postWidget={this.postWidget}
            putWidget={this.putWidget}
            widget={widget}
          />
        );
      } else {
        console.log("Dashboard.render.widgets.map", "Unknown widget type", widget.widgettype);
        return "";
      }
    });
    return (
      <React.Fragment>
        <div className={classes}>
          {widget}
          {widgets}
          <div key="add" data-key="add" className="addWidget" data-testid="Add Widget">
            {this.renderAddButton()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderAddButton = () => {
    let classes = "";
    let handler = this.handleAddWidget;
    if (this.state.widgets?.filter(widget => widget.uuid === "new").length > 0) {
      classes = "save-disabled";
      handler = () => {};
    }
    return <FontAwesomeIcon icon={faSquarePlus} onClick={handler} className={classes} />;
  };

  handleEdit = (widget, callback = null) => {
    this.setState(
      prevState => ({
        widgets: prevState.widgets.map(w => {
          if (w.uuid === widget.uuid) {
            w.editing = true;
          }
          return w;
        }),
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  handleDelete = widget => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to delete this widget?",
      callback: this.maybeDeleteWidget,
      key: widget,
    });
  };

  maybeDeleteWidget = (response, widget) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.deleteWidget(widget);
    }
  };

  handleCancel = widget => {
    if (widget.uuid === "new" && widget.widgettype === Constants.WIDGET_TYPE_ADD) {
      this.setState(prevState => ({
        widgets: prevState.widgets.filter(w => w.widgettype !== Constants.WIDGET_TYPE_ADD),
      }));
    } else if (widget.uuid === "new") {
      this.setState(prevState => ({
        widgets: prevState.widgets.map(w => {
          if (w.uuid === widget.uuid) {
            return Helper.getBlankWidget(Constants.WIDGET_TYPE_ADD);
          }
          return w;
        }),
      }));
    } else {
      this.setState(prevState => ({
        widgets: prevState.widgets.map(w => {
          if (w.uuid === widget.uuid) {
            w.editing = false;
          }
          return w;
        }),
      }));
    }
  };

  handleAddWidget = () => {
    let widget = Helper.getBlankWidget(Constants.WIDGET_TYPE_ADD);
    let index = 0;
    this.state.widgets.forEach(w => {
      if (w.widgetorder > index) {
        index = w.widgetorder;
      }
    });
    widget.widgetorder = index + 1;
    this.setState({
      widgets: [...this.state.widgets, widget],
    });
  };

  handleSetWidgetType = (widget, widgetType) => {
    let newWidget = Helper.getBlankWidget(widgetType);
    newWidget.editing = true;
    newWidget.widgetorder = widget.widgetorder;
    this.setState(prevState => ({
      widgets: prevState.widgets.map(w => {
        if (w.uuid === widget.uuid) {
          return newWidget;
        }
        return w;
      }),
    }));
  };

  handleAddTagRef = (widget, tag) => {
    this.setState(prevState => ({
      widgets: prevState.widgets.map(w => {
        if (w.uuid === widget.uuid) {
          w.widgetconfig.tags.push(tag);
        }
        return w;
      }),
    }));
  };

  handleViewTagRef = item => {
    if (item.reftype === Constants.COMPANY || item.reftype === Constants.BILLING) {
      item.company.type = Constants.COMPANY;
      if (item.reftype === Constants.BILLING) {
        this.props.handleEditItem(Constants.BILLING, Constants.BILLINGS, item.company);
      } else {
        if (item.company.companytype === Constants.SUPPLIER_COMPANY) {
          this.props.handleEditItem(Constants.SUPPLIER, Constants.SUPPLIERS, item.company);
        } else {
          this.props.handleEditItem(Constants.CUSTOMER, Constants.CUSTOMERS, item.company, Constants.TAB_CUSTOMER);
        }
      }
    } else if (item.reftype === Constants.PRODUCT) {
      this.props.handleEditItem(Constants.PRODUCT, Constants.PRODUCTS, item.product);
    } else if (Constants.ORDER_TYPES.includes(item.reftype)) {
      this.props.handleEditItem(item.reftype, item.reftype + "s", item.order);
    }
  };

  getWidgets = () => {
    const url = Constants.URL_WIDGETS;
    const params = {};
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        const widgets = response.body.records.map(widget => {
          return { ...widget, editing: false };
        });
        this.setState({ widgets: widgets }, () => {
          this.state.widgets.forEach(widget => {
            this.getWidgetData(widget);
          });
        });
      } else {
        this.setState({
          widgets: [
            {
              uuid: "012345",
              widgetorder: 1,
              widgettype: Constants.WIDGET_TYPE_MESSAGE,
              widgettitle: "Error",
              widgetconfig: { message: "Unable to load dashboard widgets" },
              editing: false,
            },
          ],
        });
      }
    });
  };

  getWidgetData = widget => {
    if (widget.widgettype === Constants.WIDGET_TYPE_TAG_LIST) {
      this.getTagList(widget);
    }
  };

  getTagList = widget => {
    const url = Constants.URL_TAGS;
    const params = {
      reftype: widget.widgetconfig.reftype,
      tagnames: [widget.widgetconfig.tag],
      includereferenceobject: true,
      sort: widget.widgetconfig.sortorder || Constants.SORT_OLDEST_FIRST,
    };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        const tags = response.body.records;
        this.setState(prevState => ({
          widgets: prevState.widgets.map(w => {
            if (w.uuid === widget.uuid) {
              w.data = tags;
              w.count = response.body.count;
            }
            return w;
          }),
        }));
      } else {
        widget.widgetconfig.tags = [];
        this.setState({ widgets: [...this.state.widgets] });
      }
    });
  };

  postWidget = (widget, callback = null) => {
    const url = Constants.URL_WIDGETS;
    Helper.postData(url, widget).then(response => {
      if (response.status === 200) {
        const newWidget = response.body;
        this.setState(
          prevState => ({
            widgets: prevState.widgets.map((w, i) => {
              if (w.uuid === widget.uuid) {
                return { ...w, ...newWidget, editing: false };
              }
              return w;
            }),
          }),
          () => {
            if (callback) {
              callback();
            }
            // Load the widget data
            this.getWidgetData(newWidget);
          }
        );
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error saving the widget.",
        });
      }
    });
  };

  putWidget = (widget, callback = null) => {
    const url = Constants.URL_WIDGETS;
    Helper.putData(url, widget).then(response => {
      if (response.status === 200) {
        const updatedWidget = response.body;
        this.setState(
          prevState => ({
            widgets: prevState.widgets.map((w, i) => {
              if (w.uuid === widget.uuid) {
                return { ...w, ...updatedWidget, editing: false };
              }
              return w;
            }),
          }),
          () => {
            if (callback) {
              callback();
            }
            // Load the widget data
            this.getWidgetData(updatedWidget);
          }
        );
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error saving the widget.",
        });
      }
    });
  };

  deleteWidget = widget => {
    const url = Constants.URL_WIDGETS;
    Helper.deleteData(url, widget).then(response => {
      if (response.status === 200) {
        this.setState(prevState => ({
          widgets: prevState.widgets.filter(w => w.uuid !== widget.uuid),
        }));
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error deleting the widget.",
        });
      }
    });
  };
}

export default Dashboard;

import React from "react";

import BaseWidget from "./BaseWidget";

class WelcomeWidget extends BaseWidget {
  renderWidgetEdit = () => {
    return "";
  };

  renderWidgetView = () => {
    const welcome = "Welcome " + this.props.appState?.clientSettings?.NAME + "!";
    return <div className="dbwTitle">{welcome}</div>;
  };
}
export default WelcomeWidget;

import { faArrowUpRightFromSquare, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "../Constants";
import * as Helper from "../Helper";
import BaseWidget from "./BaseWidget";
import React from "react";
import numeral from "numeral";

class TagListWidget extends BaseWidget {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      tag: "",
      doubleWidth: Constants.ORDER_TYPES.includes(props.widget.widgetconfig.reftype),
    };
  }

  componentDidMount = () => {
    // If the products feature is disable for this subscription plans (e.g. BillingHound),
    // then default to company tags and load the tag options immediately
    // This is also true to order-type objects
    if (!(this.props.appState?.features ?? []).includes(Constants.FEATURE_PRODUCTS)) {
      this.setState(
        prevState => ({
          widget: { ...prevState.widget, widgetconfig: { ...prevState.widget.widgetconfig, reftype: Constants.COMPANY } },
        }),
        () => {
          this.getTagOptions(this.state.widget);
        }
      );
    }
  };

  renderWidgetEdit = () => {
    const tagNamesDisabled =
      this.state.widget?.widgetconfig?.reftype === "" ||
      this.state.widget?.widgetconfig?.reftype === null ||
      this.state.widget?.widgetconfig?.reftype === undefined;

    let tags = this.state.tagOptions?.map(item => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    });
    if (tags) {
      if (tags.length > 0) {
        tags = [<option key="select">Select a tag</option>, ...tags];
      } else {
        if (tagNamesDisabled) {
          tags = [<option key="select"></option>];
        } else {
          tags = [<option key="select">No tags found</option>];
        }
      }
    } else {
      tags = [];
    }
    if (tagNamesDisabled && tags.length === 0) {
      tags = <option>Select a type</option>;
    } else if (tags.length === 0) {
      tags = <option>No tags found</option>;
    }

    const reftype = this.props.appState.features.includes(Constants.FEATURE_PRODUCTS) ? (
      <div className="areaInputItem">
        <select
          id="reftype"
          name="reftype"
          data-testid="Reference Type"
          value={this.state.widget.widgetconfig.reftype}
          onChange={event => {
            this.handleChange(event, this.state.widget, true, () => {
              this.getTagOptions(this.state.widget);
            });
          }}
        >
          <option value="">Please select</option>
          <option value={Constants.COMPANY}>Customer/Supplier</option>
          <option value={Constants.PRODUCT}>Product</option>
          <option value={Constants.INVOICE}>Invoice</option>
          <option value={Constants.QUOTE}>Quote</option>
          <option value={Constants.ORDER}>Order</option>
          <option value={Constants.REPAIR}>Service</option>
          <option value={Constants.PURCHASE}>Purchase</option>
        </select>
      </div>
    ) : (
      ""
    );

    const sortOrder = (
      <div className="areaInputItem">
        <select
          id="sortorder"
          name="sortorder"
          value={this.state.widget.widgetconfig.sortorder}
          onChange={event => {
            this.handleChange(event, this.state.widget, true, () => {
              this.setState(prevState => ({
                widget: { ...prevState.widget, widgetconfig: { ...prevState.widget.widgetconfig, sortorder: event.target.value } },
              }));
            });
          }}
        >
          <option value={Constants.SORT_OLDEST_FIRST}>Oldest first</option>
          <option value={Constants.SORT_NEWEST_FIRST}>Newest first</option>
        </select>
      </div>
    );

    return (
      <React.Fragment>
        {reftype}
        <div className="areaInputItem">
          <select
            id="tag"
            name="tag"
            data-testid="Tag Name"
            value={this.state.widget.widgetconfig.tag}
            onChange={event => {
              this.handleChange(event, this.state.widget, true, () => {
                // If the title is blank, then use the tag name as the title
                if (this.state.widget.widgettitle === "" || this.state.tagOptions.includes(this.state.widget.widgettitle)) {
                  this.setState(prevState => ({
                    widget: { ...prevState.widget, widgettitle: this.state.widget.widgetconfig.tag },
                  }));
                }
              });
            }}
            disabled={tagNamesDisabled || this.state.tagOptions?.length === 0}
          >
            {tags}
          </select>
        </div>
        {sortOrder}
      </React.Fragment>
    );
  };

  renderRefLabel = item => {
    if (item.order?.contactname && item.order?.ordernumber && item.order?.ordertype && item.order?.balancedue !== undefined) {
      const name = item.order.contactname;
      const amount = numeral(item.order.balancedue ?? 0).format(Constants.CURRENCY_WITH_SYMBOL);
      const total = numeral(item.order.totalwithtax).format(Constants.CURRENCY_WITH_SYMBOL);
      const title = `Balance due of ${amount} for order total of ${total}`;
      const ordernumber = `${item.order.ordersubtype?.slice(0, 1)}${item.order.ordertype?.slice(0, 1)}-${item.order.ordernumber}`;

      return (
        <div className="orderTagGrid">
          <div className="overflowHidden" title={ordernumber}>
            {name}
          </div>
          <div className="right-aligned" title={title}>
            {amount}
          </div>
          <div className="desktop">{Helper.renderStatus(item.order.orderstatus)}</div>
        </div>
      );
    } else if (Constants.ORDER_TYPES.includes(item.reftype) && item.reflabel && item.reflabel.split(" ").length > 1) {
      const parts = item.reflabel.split(" ");
      return (
        <div className="orderTagGrid">
          <div className="overflowHidden">{parts[0]}</div>
          <div className="right-aligned">&nbsp;</div>
          {Helper.renderStatus(parts[1])}
        </div>
      );
    }
    return item.reflabel;
  };

  renderWidgetView = () => {
    let body = this.props.widget?.data?.map((item, index) => {
      let classes = "tagGrid";
      if (Constants.ORDER_TYPES.includes(item.reftype)) {
        classes += " orderTypeTag";
      }
      return (
        <div
          key={index + "-" + item.refuuid}
          onClick={() => {
            this.props.handleViewTagRef(item);
          }}
          className={classes}
        >
          <div className="tagLabel">{this.renderRefLabel(item)}</div>
          <div className="superScript">
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </div>
        </div>
      );
    });
    if (this.props.widget?.data?.length < this.props.widget?.count) {
      body = [
        ...body,
        <div key="more" className="italic white topMarginSmall fontSizeSmall hoverPointer" onClick={this.handleShowList}>
          Show All
        </div>,
      ];
    }
    const loading =
      this.props.widget?.data === null ? (
        <span>
          Loading... <FontAwesomeIcon icon={faSpinner} spin />
        </span>
      ) : (
        ""
      );
    return (
      <React.Fragment>
        {loading}
        {body}
      </React.Fragment>
    );
  };

  handleAddTag = (widget, tag) => {
    if (!this.props.widget?.widgetconfig?.tags?.includes(tag)) {
      this.setState({ tag: "" }, () => {
        this.props.handleAddTagRef(widget, tag);
      });
    }
  };

  handleShowList = () => {
    let view = Constants.PRODUCTS;
    let tab = Constants.TAB_PRODUCTS;
    if (this.state.widget.widgetconfig?.reftype === Constants.COMPANY) {
      view = Constants.CUSTOMERS;
      tab = Constants.TAB_CUSTOMER;
    } else if (this.state.widget.widgetconfig?.reftype === Constants.INVOICE) {
      view = Constants.INVOICES;
      tab = Constants.ALL;
    } else if (this.state.widget.widgetconfig?.reftype === Constants.QUOTE) {
      view = Constants.QUOTES;
      tab = Constants.ALL;
    } else if (this.state.widget.widgetconfig?.reftype === Constants.ORDER) {
      view = Constants.ORDERS;
      tab = Constants.ALL;
    } else if (this.state.widget.widgetconfig?.reftype === Constants.REPAIR) {
      view = Constants.REPAIRS;
      tab = Constants.ALL;
    } else if (this.state.widget.widgetconfig?.reftype === Constants.PURCHASE) {
      view = Constants.PURCHASES;
      tab = Constants.ALL;
    }
    this.props.handleShowList(view, view, {
      searchkey: 'tag:"' + this.props.widget?.widgetconfig?.tag + '"',
      filtertype: { active: Constants.FILTER_ACTIVE, tab: tab },
    });
  };

  // Override the handleEdit to get the tag options afterwards
  handleEdit = widget => {
    this.setState({ widget: { ...Helper.deepCopy(widget), editing: true } }, () => {
      this.getTagOptions(widget);
    });
  };

  getTagOptions = widget => {
    if (!widget.widgetconfig.reftype) {
      this.setState({ tagOptions: [] });
      return;
    }
    const url = Constants.URL_TAGS;
    const params = {
      reftype: widget.widgetconfig.reftype,
    };
    Helper.getData(url, params).then(response => {
      if (response.status === 200 && response.body.records) {
        let tags = response.body.records.map(tag => {
          return tag.tagname;
        });

        // Union the tags with system-generated tags (no duplicates)
        if (widget.widgetconfig.reftype === Constants.COMPANY) {
          tags = [...new Set(tags.concat(Constants.SYSTEM_TAGS_COMPANY))];
        }
        // Store the tag list on the widget
        this.setState({ tagOptions: tags }, () => {
          // If we get back tags, clear the selected tag from the widget
          if (tags.length === 0) {
            this.setState(prevState => ({
              widget: { ...prevState.widget, tag: "" },
            }));
          }
        });
      } else {
        this.props.showOverlay({
          type: Constants.OVERLAY_MESSAGE,
          text: "There was an error loading the tag list.",
        });
      }
    });
  };
}
export default TagListWidget;

import React from "react";

function SaveCancelButtons(props) {
  const containerClasses = "control-buttons controls-two-buttons gridCenter " + (props.containerClasses ?? "");
  return (
    <div className={containerClasses}>
      <span id="control-cancel" className="action-button red-button" onClick={props.handleCancel} data-testid={props.datatestidcancel}>
        Cancel
      </span>
      <span id="control-save" className={props.saveButtonClass} onClick={props.handleSave} data-testid={props.datatestidsave}>
        Save
      </span>
    </div>
  );
}

export default SaveCancelButtons;

function render(props) {
  return (
    <div className="circle-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="-1 -1 35 35">
        <g id="Component_195_2" data-name="Component 195 - 2">
          <circle id="Ellipse_3" data-name="Ellipse 3" cx="16" cy="16" r="16" transform="translate(0 1)" strokeWidth="0" />

          <line id="Line_1" data-name="Line 1" y2="12.5" transform="translate(16.2 10)" fill="none" strokeWidth="2" />
          <line id="Line_3" data-name="Line 3" y2="12.5" transform="translate(22.45 16.25) rotate(90)" fill="none" strokeWidth="2" />
        </g>
      </svg>
    </div>
  );
}
export default render;

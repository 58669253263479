import React from "react";

// Component
import * as Constants from "./Constants";
import ContactCard from "./ContactCard";

class ContactCardDeck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showContactCards: false,
    };
  }

  render = () => {
    let taxable = "";
    // We do not want the taxable message to show anywhere but the customer view (e.g. receipts)
    if (this.props.company?.taxable === false && this.props.appState.currentView === Constants.CUSTOMER) {
      taxable = <div className="printOnly marginBottom1em">*** Customer not subject to sales tax ***</div>;
    }
    const output = this.props.company.contacts.map((contact, index) => {
      // Only display the first if it's the first or we've toggle open the cards
      if (index === 0 || this.state.showContactCards) {
        // Render the related contacts
        return (
          <React.Fragment key={"index" + index}>
            <ContactCard
              appState={this.props.appState}
              handleChange={this.props.handleChange}
              handleBlur={this.props.handleBlur}
              handleEditMode={this.props.handleEditMode}
              handleUnchainContact={this.props.handleUnchainContact}
              handleDeleteContact={() => this.props.handleDeleteContact(contact.contactuuid)}
              handleCaretClick={() => this.setState({ showContactCards: !this.state.showContactCards })}
              handleAddContact={this.handleAddContact}
              contact={contact}
              company={this.props.company}
              isPrimaryContact={index === 0}
              isNewContact={contact.contactuuid === "new"}
              hasAdditionalContacts={this.props.company.contacts.length > 1}
              hasNewUnsavedContact={this.props.company.contacts.filter(contact => contact.contactuuid === "new").length >= 1}
              isProtectedCustomer={this.props.isProtectedCustomer}
              additionalContactsVisible={this.state.showContactCards}
              showOverlay={this.props.showOverlay}
              setDownloading={this.props.setDownloading}
            />
          </React.Fragment>
        );
      } else {
        return "";
      }
    });
    return (
      <div className="areaContactCardContainer">
        {output}
        {taxable}
        {this.props.additionalControls}
      </div>
    );
  };

  // this is only when the user hits the plus button
  handleAddContact = () => {
    this.props.handleAddContactToCompany(() => {
      this.setState({ showContactCards: true });
    });
  };
}

export default ContactCardDeck;

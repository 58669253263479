function render(props) {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
      <path d="M485.54,339.68c0,18.3.61,36.63-.14,54.9-1.28,31.2-16.82,39.71-42.58,22.87-57.5-37.58-121.06-60.07-188.45-67.44-57.91-6.33-116.72-4.09-175.05-7.33-17.49-.97-37.29-3.48-51.44-12.49C.18,312.56-10.21,228.86,12.17,204.93c11-11.76,28.84-20.17,44.97-23.6,98.3-20.92,192.04-52.45,273.37-113.42,21.53-16.14,40.66-35.66,59.94-54.6,20.99-20.62,37.44-17.46,46.21,10.79,31.89,102.76,46.21,208.32,48.89,315.58ZM445.33,366.42c.48-103.47-13.46-204.71-40.64-308.03-61,59.3-131.04,97.4-206.62,126.47-4.09,1.58-7.76,11.55-7.08,17.04,3.52,28.26,8.81,56.29,12.88,84.49,1.72,11.95,6.91,16.2,19.58,18.1,38.21,5.75,76.72,11.56,113.82,21.94,36.19,10.13,70.79,25.93,108.06,39.98Z" />
      <path d="M101.29,379.29c25.89,0,49.87-.74,73.74.57,5.38.3,12.62,6.7,15.13,12.1,10.15,21.83,19.36,44.15,27.82,66.7,8.98,23.94-.44,45.93-22.05,54.44-20.4,8.03-41.57-1.43-51.46-24.23-14.25-32.82-27.71-65.99-41.41-99.04-.79-1.9-.74-4.16-1.77-10.54Z" />
      <path d="M509.99,278c-6.49-49.45-12.69-96.72-18.91-144.15,29.68,1.04,54.63,24.63,62.12,57.76,7.82,34.55-8.6,68.78-43.21,86.4Z" />
    </svg>
  );
}
export default render;

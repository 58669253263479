function render(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.71"
      height="26.095"
      viewBox="0 0 29.71 26.095"
    >
      <g id="Layer_1-2" transform="translate(0 0)">
        <path
          id="Path_334"
          data-name="Path 334"
          d="M0,100.59H3.376a1.732,1.732,0,0,1-1.547,2.089,1.73,1.73,0,0,1-1.718-.944c-.032-.056-.074-.105-.111-.158v-.986Z"
          transform="translate(0 -92.807)"
          fill="#fff"
        />
        <path
          id="Path_335"
          data-name="Path 335"
          d="M343.249,102.268a1.852,1.852,0,0,1-1.292,1.1,1.758,1.758,0,0,1-2.108-2.028h3.4Z"
          transform="translate(-313.539 -93.499)"
          fill="#fff"
        />
        <path
          id="Path_336"
          data-name="Path 336"
          d="M15.882,140.686c0-.642-.029-1.28.009-1.913a.919.919,0,0,1,.949-.84c.153-.005.307,0,.5,0v-.385q0-6.033-.006-12.066a.509.509,0,0,1,.28-.5,10.254,10.254,0,0,0,.93-.754,2.616,2.616,0,0,0,2.156,1.11,2.661,2.661,0,0,0,2.2-1.14,2.641,2.641,0,0,0,2.2,1.175,2.606,2.606,0,0,0,2.193-1.156,2.691,2.691,0,0,0,4.4.024,2.688,2.688,0,0,0,4.4-.026,2.631,2.631,0,0,0,2.193,1.157,2.594,2.594,0,0,0,2.148-1.1c.376.285.736.554,1.089.833a.391.391,0,0,1,.121.2,1.077,1.077,0,0,1,.005.26v12.358c.179,0,.33,0,.481,0a.951.951,0,0,1,.986.933c.02.6,0,1.2,0,1.82H15.882Zm11.609-2.77c.008-.08.019-.145.019-.21q0-4.278,0-8.556A1.075,1.075,0,0,0,26.345,128q-2.393,0-4.785,0a1.087,1.087,0,0,0-1.236,1.249v8.672h7.168Zm7.027-3.459c.956,0,1.913,0,2.87,0a.7.7,0,0,0,.794-.781q.009-2.551,0-5.1a.713.713,0,0,0-.808-.8q-2.812,0-5.624,0a.712.712,0,0,0-.818.815q0,2.522,0,5.044c0,.584.244.825.831.827.918,0,1.836,0,2.754,0Z"
          transform="translate(-14.644 -114.591)"
          fill="#fff"
        />
        <path
          id="Path_337"
          data-name="Path 337"
          d="M59.083,2.7H34.832C34.513,1.068,35.359,0,36.8,0Q46.937.023,57.073.007A1.84,1.84,0,0,1,59.083,2c0,.231,0,.461,0,.7Z"
          transform="translate(-32.075 0)"
          fill="#fff"
        />
        <path
          id="Path_338"
          data-name="Path 338"
          d="M173.118,50.119H169.66c.075-1.108.149-2.2.224-3.309h3.01C172.968,47.9,173.042,49,173.118,50.119Z"
          transform="translate(-156.533 -43.188)"
          fill="#fff"
        />
        <path
          id="Path_339"
          data-name="Path 339"
          d="M226.455,50.09h-3.447c-.076-1.1-.152-2.2-.228-3.31h3C226,47.87,226.228,48.969,226.455,50.09Z"
          transform="translate(-205.544 -43.161)"
          fill="#fff"
        />
        <path
          id="Path_340"
          data-name="Path 340"
          d="M117.226,50.091H113.78c.224-1.113.446-2.205.668-3.311h3c-.075,1.112-.149,2.205-.224,3.311Z"
          transform="translate(-104.977 -43.161)"
          fill="#fff"
        />
        <path
          id="Path_341"
          data-name="Path 341"
          d="M273.13,46.271c.723,0,1.427,0,2.131,0,.279,0,.629-.084.816.052s.212.5.3.758c.279.822.554,1.644.843,2.5-.108.011-.19.026-.27.026q-1.45,0-2.9,0c-.126,0-.249.018-.284-.162-.207-1.051-.42-2.1-.637-3.181Z"
          transform="translate(-251.998 -42.669)"
          fill="#fff"
        />
        <path
          id="Path_342"
          data-name="Path 342"
          d="M323.87,46.566c.829,0,1.63-.009,2.431,0a.766.766,0,0,1,.694.453c.454.936.894,1.88,1.351,2.845a1.252,1.252,0,0,1-.2.035c-.985,0-1.97,0-2.955,0a.255.255,0,0,1-.292-.2c-.328-1-.667-1.992-1-2.989a1.25,1.25,0,0,1-.025-.151Z"
          transform="translate(-298.812 -42.961)"
          fill="#fff"
        />
        <path
          id="Path_343"
          data-name="Path 343"
          d="M6.724,46.156c-.076.228-.135.409-.2.59-.279.832-.562,1.662-.833,2.5-.059.179-.131.259-.34.257-1.031-.013-2.063-.006-3.135-.006.127-.273.238-.516.352-.757.316-.672.636-1.342.95-2.015a.841.841,0,0,1,.747-.559c.8-.019,1.6-.005,2.454-.005Z"
          transform="translate(-2.048 -42.581)"
          fill="#fff"
        />
        <path
          id="Path_344"
          data-name="Path 344"
          d="M58.07,49.86c.091-.271.171-.506.25-.741.264-.787.523-1.575.8-2.359.029-.084.142-.2.217-.2.927-.014,1.855-.009,2.82-.009-.116.585-.227,1.147-.339,1.708-.093.463-.183.928-.289,1.39-.019.079-.111.2-.17.2C60.274,49.863,59.194,49.86,58.07,49.86Z"
          transform="translate(-53.577 -42.949)"
          fill="#fff"
        />
        <path
          id="Path_345"
          data-name="Path 345"
          d="M229.555,101.38a1.762,1.762,0,0,1-.854,1.817,1.654,1.654,0,0,1-1.86-.046,1.794,1.794,0,0,1-.786-1.771Z"
          transform="translate(-208.557 -93.536)"
          fill="#fff"
        />
        <path
          id="Path_346"
          data-name="Path 346"
          d="M282.956,101.26h3.484a1.816,1.816,0,0,1-.716,1.737,1.735,1.735,0,0,1-1.937.09,1.776,1.776,0,0,1-.832-1.827Z"
          transform="translate(-261.043 -93.426)"
          fill="#fff"
        />
        <path
          id="Path_347"
          data-name="Path 347"
          d="M116.015,101.37a1.8,1.8,0,0,1-.785,1.773,1.653,1.653,0,0,1-1.883.028,1.768,1.768,0,0,1-.83-1.8Z"
          transform="translate(-103.802 -93.527)"
          fill="#fff"
        />
        <path
          id="Path_348"
          data-name="Path 348"
          d="M169.334,101.21h3.406a1.683,1.683,0,0,1-.7,1.766,1.742,1.742,0,0,1-2.053-.038A1.66,1.66,0,0,1,169.334,101.21Z"
          transform="translate(-156.18 -93.379)"
          fill="#fff"
        />
        <path
          id="Path_349"
          data-name="Path 349"
          d="M55.657,101.31h3.51a1.766,1.766,0,0,1-.747,1.72,1.654,1.654,0,0,1-1.858.094A1.788,1.788,0,0,1,55.657,101.31Z"
          transform="translate(-51.347 -93.472)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
export default render;

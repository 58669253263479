import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { inList } from "./Helper";
import * as Helper from "./Helper.jsx";

function ListItemView(props) {
  const output = props.listitems?.map((item, rowindex) => {
    const inExpandedItemList = props.expandedListItems === null || inList(props.expandedListItems, item.uuid);
    const isParentInExpandedItemList = props.expandedListItems === null || inList(props.expandedListItems, item.parentuuid);
    if (isParentInExpandedItemList || !item.collapsed) {
      const selected = props.selectedListItems.filter(i => i.uuid === item.uuid).length === 0 ? "" : " selected ";
      const columns = props.renderItemToColumns(item, rowindex);
      const row = columns?.map((column, colindex) => {
        let rowvalue = column.rowvalue;
        if (column.isEmail && rowvalue.indexOf("@") > 0) {
          let icon = item.copied ? (
            <FontAwesomeIcon
              icon={faCheck}
              onClick={() => {
                Helper.copyToClipboard(column.rowvalue);
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faCopy}
              onClick={() => {
                item.copied = true;
                setTimeout(() => {
                  item.copied = false;
                }, 1500);
                Helper.copyToClipboard(column.rowvalue);
              }}
            />
          );
          rowvalue = (
            <span className="copyToClipboard">
              {rowvalue} {icon}
            </span>
          );
        }
        // TODO: Refactor two blocks below into one code block
        if (column.expander) {
          const caret = inExpandedItemList ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretRight} />;
          rowvalue = <span onClick={event => props.toggleCollapsed(event, item.uuid)}>{caret}</span>;
          return (
            <div
              key={item.uuid + "-" + rowindex + "-" + colindex}
              data-key={item.uuid + "-" + rowindex + "-" + colindex}
              className={"list-item " + (column.classes ? column.classes : "") + (selected ? " selected" : "")}
            >
              <span className="minaddress"> {rowvalue}</span>
            </div>
          );
        } else {
          const tooltip = column.tooltipvalue ? <span className="tooltiptext">{column.tooltipvalue}</span> : "";
          return (
            <div
              key={item.uuid + "-" + rowindex + "-" + colindex}
              data-key={item.uuid + "-" + rowindex + "-" + colindex}
              className={"list-item " + (column.classes ? column.classes : "") + (selected ? " selected" : "")}
              onClick={() => props.selectListItem(item)}
              onDoubleClick={() => {
                if (!props.disableDetailView) {
                  props.handleEditItem(item);
                }
              }}
              onTouchStart={event => props.handleTouchStart(event, item)}
              onTouchEnd={event => props.handleTouchEnd(event)}
            >
              {rowvalue}
              {tooltip}
            </div>
          );
        }
      });
      return <React.Fragment key={item.uuid + "-" + rowindex}>{row}</React.Fragment>;
    } else {
      return "";
    }
  });
  return output;
}

export default ListItemView;

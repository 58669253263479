function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 385.68 300">
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Group 73">
            <g data-name="Group 72">
              <path
                data-name="Path 89"
                d="M355.46,136.24v4.44A73.4,73.4,0,0,1,282.14,214H175.63v9.15a35,35,0,0,0,35,35h89L340,297.84a7.53,7.53,0,0,0,12.8-5.37V258.1a35,35,0,0,0,32.9-34.94V170.9A35,35,0,0,0,355.46,136.24Z"
              />
              <path
                data-name="Path 90"
                d="M335.22,139.22V55.86A55.88,55.88,0,0,0,279.35,0H55.86A55.86,55.86,0,0,0,0,55.86v83.36A55.85,55.85,0,0,0,52.52,195V249.8A12,12,0,0,0,73,258.37l64.33-63.29H279.35A55.88,55.88,0,0,0,335.22,139.22Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default render;

import React from "react";

import * as Constants from "./Constants";
import * as Helper from "./Helper";

class Monitoring extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientuuid: null,
      interval: Constants.TIMEFRAME_MONTH,
    };
  }

  componentDidMount() {
    // Get the client list
    this.props.getClientList(clients => {
      this.setState({ clients: clients }, () => {
        // Get the metrics for all clients
        Constants.METRICS.forEach(metric => {
          this.getMetric(metric, this.state.interval);
        });
      });
    });
  }

  componentWillUnmount() {
    // Clean up
  }

  render() {
    return (
      <div className="app-body">
        <div className="Monitoring">
          <div className="MonitoringWrapper">
            <div className="MonitoringHeader">{this.renderClientSelect()}</div>
            {this.renderMetrics()}
          </div>
        </div>
      </div>
    );
  }

  renderClientSelect = () => {
    if (this.state.clients) {
      const all = { clientuuid: "-1", name: "All" };
      const clients = [all, ...this.state.clients];
      return (
        <select className="client-select" onChange={this.handleClientChange} value={this.state.clientuuid}>
          {clients.map(client => (
            <option key={client.clientuuid} value={client.clientuuid}>
              {client.name}
            </option>
          ))}
        </select>
      );
    }
  };

  renderMetrics = () => {};

  getMetric = (metric, interval) => {
    const data = {
      action: Constants.ACTION_GET_METRICS,
      q: metric,
      t: interval,
    };
    const url = Constants.URL_CLIENTS;
    Helper.getData(url, data).then(response => {
      if (response.status === 200 && response.body) {
        this.setState(prevState => ({
          metrics: {
            ...prevState.metrics,
            [metric]: response.body,
          },
        }));
      } else {
        this.setState(prevState => ({
          metrics: {
            ...prevState.metrics,
            [metric]: null,
          },
        }));
      }
    });
  };
}

export default Monitoring;

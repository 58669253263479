function render(props) {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412.85 85.98">
      <g id="Layer_1-2">
        <g>
          <path d="M48.82,22.91c-.52,.21-9.08,.62-9.91,.62-1.55,0-2.89-1.65-2.89-3.92,0-4.64,.41-13.31-5.26-13.31-11.97,0-15.48,31.58-15.48,40.67,0,7.22,4.95,25.29,14.66,25.29,7.02,0,11.66-9.08,14.14-12.38,1.86-2.37,4.13-.62,3.51,1.03-3.72,11.04-11.97,20.75-23.02,20.75C10.94,81.64,0,65.33,0,40.25,0,24.05,12.59,1.03,31.69,1.03c10.32,0,18.47,10.12,18.47,19.2,0,1.03-.31,2.17-1.34,2.68Z" />
          <path d="M64.51,80.3c-9.5,0-10.84-14.86-10.84-22.4,0-16.62,3.41-37.67,5.06-54.81,.21-1.86,.93-2.68,2.89-2.68,1.24,0,1.96,.21,4.54,.21,1.86,0,2.89,1.13,2.79,2.68-.52,17.13-5.47,33.75-5.47,50.99,0,8.57,1.03,17.03,4.34,16.82,6.4-.31,7.84-10.94,10.12-16.41,.31-.72,1.24-.93,1.86-.93,.82,0,1.55,.83,1.55,3.2v.21c0,7.23-8.36,23.12-16.82,23.12Z" />
          <path d="M127.57,80.61c-6.91,0-9.5-7.33-9.5-13.21s2.06-16.41,2.06-22.29c0-2.17,.62-4.34-2.17-4.34-1.34,0-1.86,1.44-1.96,1.86-1.44,7.12-3.3,17.34-6.19,23.95-2.68,5.99-10.32,13-19.92,13s-14.76-10.01-14.76-18.48c0-11.04,4.23-31.27,18.17-31.27,6.71,0,10.84,8.67,10.84,14.55,0,6.61-5.16,15.48-12.8,15.48-1.75,0-3.82-.1-5.57-.31-.41,5.47,1.44,14.45,6.81,14.45,4.75,0,9.91-3.51,13.52-11.04,3.61-7.43,4.03-14.45,5.26-20.13,1.14-5.47-3.41-4.64-3.41-8.88,0-1.65,.93-2.68,2.58-2.68,2.68,0,5.88,2.99,10.73,2.99,2.89,0,5.57-1.75,8.36-1.75,2.48,0,5.06,3.51,5.06,5.78,0,1.96-1.34,3.92-2.17,5.68-1.86,4.65-4.13,16.21-4.13,21.06,0,3.41,.21,11.25,3.1,11.25,3.41,0,6.71-7.12,6.71-13,0-2.37-3.2-4.34-3.2-6.71,0-1.14,2.79-1.75,4.95-1.75,1.24,0,2.17,.21,2.37,.62,.52,.93,1.13,5.06,1.13,6.3,0,8.88-6.3,18.89-15.9,18.89Zm-34.78-45.72c-3.51,0-5.37,11.35-6.3,18.78,1.44,.1,4.34,.31,5.78,.31,2.37,0,6.3-4.13,6.3-8.15,0-2.99-.93-10.94-5.78-10.94Z" />
          <path d="M179.49,76.38c-8.36,0-11.04-3.51-15.48-18.89-.21-.52-.41-2.37-.62-3.82l-.52-4.44c-.1-.93,.62-1.44,1.24-1.75,4.65-1.55,10.73-4.44,10.73-8.46,0-1.24-1.65-2.68-2.58-2.68-3.1,0-10.53,5.88-12.08,8.36-1.14,10.12-1.86,24.56-1.86,34.47,0,.41-.41,1.24-.83,1.24-1.96,0-1.96,.31-3.82,.31-1.75,0-5.16,.52-5.16-1.86,0-25.39,2.37-51.3,3.92-76.58,.1-1.65,2.58-2.27,5.26-2.27,3.1,0,4.64,.93,4.54,2.17-.1,12.49-1.44,24.77-1.75,37.26,2.89-3.72,9.29-7.33,14.14-7.33s7.12,3.61,7.12,6.3c0,5.26-8.26,9.5-12.49,11.35,0,7.84,6.3,20.44,10.12,20.44,7.22,0,10.01-14.14,11.66-16.82,.31-.62,.83-.83,1.34-.83,.93,0,1.75,.83,1.75,2.48,0,6.19-7.22,21.37-14.66,21.37Z" />
          <path d="M234.29,57.18c-.52,5.99-.62,16-.62,21.99,0,.93,.72,1.86,.52,2.79-.31,1.34-3.92,4.03-5.47,4.03-3.1,0-4.13-3.41-5.16-4.95-4.75-7.64-11.15-16-21.26-19.2-.62,9.08,.72,17.03-.1,20.44-.62,2.48-6.92,3.72-8.46,3.72-4.54,0-2.58-17.65-2.58-21.16,0-17.75-.1-38.91,.62-56.56,.1-2.79,.62-3.72,2.48-4.33,1.34-.52,5.37-1.14,8.57-1.03,2.68,.1,2.06,2.06,3.1,4.23-1.86,13.11-3.51,33.34-3.2,46.65,7.12,.21,13.42-1.13,20.54-1.03-.52-11.66,.52-26.73,.52-38.29s4.44-9.81,12.08-11.35c2.48,.83,2.06,1.96,3.1,4.23-1.44,9.6-4.33,35.3-4.23,45.31,4.13-.31,6.91-.82,11.25-.82,2.27,0,2.79,4.64,0,4.64-3.51,0-8.26,.62-11.66,.72Zm-10.94,.83s-10.01,.1-14.55,.82c5.47,2.27,12.39,8.26,14.76,14.04,0-4.13-.21-10.74-.21-14.86Z" />
          <path d="M298.7,50.06c-1.86,5.99-8.15,17.44-15.38,17.44-1.44,0-2.89-.41-4.44-.72-4.44,6.81-9.7,12.49-18.37,12.49-11.56,0-17.86-11.87-17.86-22.6,0-14.24,10.01-25.8,24.57-25.8,4.33,0,12.49,4.23,12.49,9.39,0,.41-.93,.93-1.24,.93-2.99,0-2.99-4.75-10.01-4.75-10.43,0-13.62,10.01-13.62,18.79,0,4.64,2.48,17.03,9.19,17.03,4.65,0,8.77-2.58,10.84-6.61-3.41-2.27-8.05-7.33-8.05-11.77,0-5.68,3.41-9.81,9.29-9.81,4.44,0,7.54,3.61,7.54,7.95,0,2.79-1.14,8.16-2.68,10.53,.93,.52,2.06,.83,3.2,.83,7.33,0,9.91-16.21,12.8-16.21,1.13,0,2.06,.83,2.06,1.86,0,.31-.21,.83-.31,1.03Zm-21.98-1.44c-2.06,0-2.58,3.1-2.58,4.54,0,2.37,1.24,6.4,3.3,7.64,.72-1.55,1.86-5.47,1.86-7.22,0-1.55-.62-4.95-2.58-4.95Z" />
          <path d="M321.72,82.57c-3.61,0-4.13-5.99-5.37-9.19-3.82,5.68-5.68,8.15-11.77,8.15-11.25,0-14.35-12.08-14.35-24.15,0-7.53,1.24-15.07,2.37-19.61,1.03-3.92,2.48-4.54,9.7-4.75,1.86,0,2.06,.41,2.06,2.17,0,.93-1.96,8.05-2.48,9.7-.72,2.68-1.86,10.32-1.86,16.51,0,6.81,2.17,13.11,6.81,13.11,4.95,0,7.74-5.88,8.26-10.53,.31-2.89,.31-25.29,.31-26.84,0-3.71,.62-6.3,5.16-6.3,1.03,0,1.65-.72,2.79-.72,1.44,0,2.58,.41,2.58,2.06,0,3.82-.82,7.74-.82,12.28,0,3.41-2.99,19.71-2.99,25.49,0,3.3,.31,5.78,1.24,5.78,6.09,0,10.22-19.92,11.25-24.36,.21-.93,1.13-1.65,2.06-1.65,.72,0,1.44,.72,1.44,2.48,0,7.64-6.3,30.35-16.41,30.35Z" />
          <path d="M366.82,83.71c-1.86,.1-5.57-1.03-6.81-1.75-1.86-.93-5.88-3.71-5.88-12.08,0-7.74,2.37-20.02,2.37-27.66,0-.93-.51-2.37-1.34-2.37-4.02,0-12.38,14.66-12.38,18.17,0,1.34-.83,18.17-1.03,19.61-.31,1.96-.62,1.86-2.27,1.86-1.24,0-3.2,.31-4.34-.1-1.03-.31-1.34-.93-1.34-2.48,0-13.73,2.27-31.79,2.27-45.52,0-1.34,.41-1.96,3.82-1.96,5.16,0,5.26,.21,5.06,3.2-.21,2.99-1.14,11.77-1.45,14.76,2.58-4.95,10.53-16.31,16.1-16.31,4.33,0,5.99,3.82,5.99,7.33,0,7.84-1.65,18.68-1.96,26.53-.41,12.18,1.65,15.27,4.44,17.13,1.03,.62-.1,1.55-1.24,1.65Z" />
          <path d="M400.47,82.47c-4.44,0-6.09-5.57-6.81-8.98-1.34,2.58-4.75,8.46-11.15,8.46-7.53,0-11.97-13.83-11.97-20.23,0-11.25,1.65-27.77,13.73-27.77,2.58,0,6.4,2.58,6.4,2.58,.31-9.39-1.65-18.79-1.65-28.18,0-1.44,.83-6.3,5.88-6.3,1.44,0,6.19-.31,6.19,1.55,0,19.2-2.37,38.6-2.37,57.9,0,4.13,1.03,11.56,2.99,11.56,4.95,0,6.3-11.35,8.36-16.62,.31-.72,.93-1.14,1.34-1.14,.82,0,1.44,.52,1.44,2.89,0,5.26-6.19,24.25-12.38,24.25Zm-8.98-29.52c0-3.41-.62-14.45-5.26-14.45-3.51,0-5.68,10.32-5.68,17.55,0,5.37,2.37,18.89,6.19,18.89,1.75,0,4.95-5.78,5.06-7.95,.21-4.02-.31-10.01-.31-14.04Z" />
        </g>
      </g>
    </svg>
  );
}
export default render;

import React from "react";

// Constants
import * as Constants from "./Constants";

// Components
import * as Helper from "./Helper.jsx";
import Switch from "./Switch.jsx";

// Style
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faLinkSlash,
  faPencil,
  faPlus,
  faTrash,
  faUserLargeSlash,
  faCircleMinus,
  faCopy,
  faCheck,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import EnvelopeSlashIcon from "./img/EnvelopeSlashIcon";

// Displays and edits contact information.
// Also allows company name to be edited for Customers,
// but not Suppliers (Constants.SUPPLIER_COMPANY).
class ContactCard extends React.Component {
  constructor(props) {
    super(props);
    // creates cursor focus on first name
    this.contactNameInput = React.createRef();
    this.state = {
      copied: false,
    };
  }

  render = () => {
    if (this.props.contact.editmode) {
      return this.renderEditContact();
    } else {
      return this.renderViewContact();
    }
  };

  renderViewContact = () => {
    let website = "";
    let address = "";
    let citystatezip = "";
    if ((!this.props.contact.firstname && !this.props.contact.lastname) || this.props.company.companytype === Constants.CUSTOMER_EXTERNAL) {
      address = this.renderAddress(true);
      website = this.renderWebsite();
      citystatezip = this.renderCityStateZip(false, true);
    }
    let printAddress = "";
    if (this.renderAddress() || this.renderCityStateZip()) {
      printAddress = (
        <div className="printAddress">
          {this.renderAddress()}
          {this.renderCityStateZip()}
        </div>
      );
    }
    let printShipAddress = "";
    if (this.renderViewShippingAddress()) {
      printShipAddress = <div className="printShipAddress">{this.renderViewShippingAddress()}</div>;
    }
    return (
      <div className="contact-card">
        <div className="view-contact-details">
          {this.renderTopRowView()}
          {this.renderPrintCompany()}
          {this.renderFullName()}
          {printAddress}
          {this.renderEmailAddr()}
          {this.renderMobilePhone()}
          {this.renderOtherPhone()}
          {website}
          {address}
          {citystatezip}
          {printShipAddress}
        </div>

        {this.renderMobileControls()}
      </div>
    );
  };

  renderMobileControls = () => {
    // Renders the add and expand icons for mobile at the bottom of the contact card
    // as opposed to the top side in desktop.
    return (
      <div className="action-icon-container mobile">
        {this.renderAddContact()}
        {this.renderCaretIcon()}
      </div>
    );
  };

  renderTopRowView = () => {
    // If a protected customer and not on the customer view, then do not display the customer tools
    if (this.props.isProtectedCustomer && this.props.appState.currentView !== Constants.CUSTOMER) {
      return <div>&nbsp;</div>;
    }
    return (
      <div className="contact-view-top-row ">
        {this.renderDoNotContact()}
        {this.renderCustomerInactive()}
        {this.renderEditIcon()}
        <span className="desktop">{this.renderAddContact()}</span>
        <span className="desktop">{this.renderCaretIcon()}</span>
      </div>
    );
  };

  renderEditContact = () => {
    let fielddisabled = "";
    let disabledClass = "";
    if (this.props.isNewContact && !this.props.isPrimaryContact) {
      fielddisabled = "disabled";
      disabledClass = "disabledInput";
    }

    return (
      <div className="contact-card">
        <div className="contact-details">
          {this.renderTopRowEdit()}
          <div className="column1">
            <div className="contact-item first">
              <input
                type="text"
                name="firstname"
                id="firstname"
                data-testid="Edit First Name"
                placeholder="First name"
                maxLength={50}
                autoComplete="off"
                ref={this.contactNameInput}
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.firstname}
              />
            </div>
            <div className="contact-item lastname">
              <input
                type="text"
                name="lastname"
                id="lastname"
                data-testid="Edit Last Name"
                placeholder="Last name"
                maxLength={50}
                autoComplete="off"
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.lastname}
              />
            </div>
            <div className="contact-item emailaddr">
              <input
                type="text"
                name="email"
                id="email"
                data-testid="Edit Email"
                className={disabledClass}
                placeholder="Email"
                maxLength={255}
                autoComplete="off"
                disabled={fielddisabled}
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.email}
              />
            </div>
            <div className="contact-item mobilephone">
              <input
                type="tel"
                name="mobilephone"
                id="mobilephone"
                data-testid="Edit Mobile phone"
                className={disabledClass}
                placeholder="Mobile phone"
                maxLength={21}
                autoComplete="off"
                disabled={fielddisabled}
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.mobilephone}
              />
            </div>
            <div className="contact-item otherphone">
              <input
                type="tel"
                name="otherphone"
                id="otherphone"
                data-testid="Edit Other phone"
                className={disabledClass}
                disabled={fielddisabled}
                placeholder="Other phone"
                maxLength={21}
                autoComplete="off"
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.otherphone}
              />
            </div>
            {/* Website doesn't show for prospects */}
            {this.renderEditWebsite(disabledClass, fielddisabled)}
            {/* Company name only shows on the primary(1st) contact */}
            {this.renderCompanyName(disabledClass, fielddisabled)}
            {/* Title doesn't show for prospects */}
            {this.renderEditTitle(disabledClass, fielddisabled)}
          </div>
          <div className="column2">
            <div className="contact-item">&nbsp;</div>

            <div className="contact-item addr1">
              <input
                type="text"
                name="address1"
                id="address1"
                data-testid="Edit Address 1"
                className={disabledClass}
                placeholder="Address 1"
                maxLength={50}
                autoComplete="off"
                disabled={fielddisabled}
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.address1}
              />
            </div>
            <div className="contact-item addr2">
              <input
                type="text"
                name="address2"
                id="address2"
                data-testid="Edit Address 2"
                className={disabledClass}
                placeholder="Address 2"
                maxLength={50}
                autoComplete="off"
                disabled={fielddisabled}
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.address2}
              />
            </div>
            <div className="contact-item city">
              <input
                type="text"
                name="city"
                id="city"
                data-testid="Edit City"
                className={disabledClass}
                placeholder="City"
                maxLength={50}
                autoComplete="off"
                disabled={fielddisabled}
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.city}
              />
            </div>
            <div className="contact-item state">
              <input
                type="text"
                name="state"
                id="state"
                data-testid="Edit State Province"
                className={disabledClass}
                placeholder="State/Province"
                maxLength={3}
                autoComplete="off"
                disabled={fielddisabled}
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.state}
              />
            </div>
            <div className="contact-item zip">
              <input
                type="text"
                name="postalcode"
                id="postalcode"
                data-testid="Edit Postal code"
                className={disabledClass}
                placeholder="Postal code"
                maxLength={12}
                autoComplete="off"
                disabled={fielddisabled}
                onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
                onFocus={Helper.handleFocus}
                onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
                value={this.props.contact.postalcode}
              />
            </div>
            <div className="contact-item">&nbsp;</div>
            {this.renderShippingSwitch()}
          </div>
          {this.renderShippingAddress()}
        </div>
      </div>
    );
  };

  renderTopRowEdit = () => {
    return (
      <div className="toprow">
        {this.renderDoNotEmailSwitch()}
        {this.renderActiveSwitch()}
        {this.renderUnchainContact()}
        {this.renderTrashCan()}
        {/* commented out until we are ready to implement */}
        {/* {this.renderSaveIcon()} */}
        {this.renderCloseContact()}
      </div>
    );
  };

  renderShippingSwitch = () => {
    // If this is a protected customer or prospect, don't show the switch
    if (this.props.isProtectedCustomer || this.props.contact?.type === Constants.PROSPECT) {
      return "";
    }
    if (!this.props.isNewContact) {
      return (
        <Switch
          label="Separate shipping address?"
          fieldname="hasshippingaddress"
          handleChange={event => this.props.handleChange(event, Constants.SHIPPING_SWITCH, this.props.contact.contactuuid)}
          checked={this.props.contact.hasshippingaddress}
          elementid="switch-shipping"
        />
      );
    } else {
      return "";
    }
  };

  renderShippingAddress = () => {
    if (this.props.contact.hasshippingaddress) {
      return (
        <div className="column3">
          <div>&nbsp;</div>
          <div className="contact-item addr1">
            <input
              type="text"
              name="address1"
              id="address1"
              data-testid="Edit Shipping address 1"
              className={this.props.disabledClass}
              placeholder="Shipping address 1"
              maxLength={50}
              autoComplete="off"
              disabled={this.props.fielddisabled}
              onChange={event => this.props.handleChange(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              onFocus={Helper.handleFocus}
              onBlur={event => this.props.handleBlur(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              value={this.props.contact.shippingaddress.address1}
            />
          </div>
          <div className="contact-item addr2">
            <input
              type="text"
              name="address2"
              id="address2"
              data-testid="Edit Shipping address 2"
              className={this.props.disabledClass}
              placeholder="Shipping address 2"
              maxLength={50}
              autoComplete="off"
              disabled={this.props.fielddisabled}
              onChange={event => this.props.handleChange(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              onFocus={Helper.handleFocus}
              onBlur={event => this.props.handleBlur(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              value={this.props.contact.shippingaddress.address2}
            />
          </div>
          <div className="contact-item city">
            <input
              type="text"
              name="city"
              id="city"
              data-testid="Edit Shipping city"
              className={this.props.disabledClass}
              placeholder="Shipping city"
              maxLength={50}
              autoComplete="off"
              disabled={this.props.fielddisabled}
              onChange={event => this.props.handleChange(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              onFocus={Helper.handleFocus}
              onBlur={event => this.props.handleBlur(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              value={this.props.contact.shippingaddress.city}
            />
          </div>
          <div className="contact-item state">
            <input
              type="text"
              name="state"
              id="state"
              data-testid="Edit Shipping state province"
              className={this.props.disabledClass}
              placeholder="Shipping state/province"
              maxLength={3}
              autoComplete="off"
              disabled={this.props.fielddisabled}
              onChange={event => this.props.handleChange(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              onFocus={Helper.handleFocus}
              onBlur={event => this.props.handleBlur(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              value={this.props.contact.shippingaddress.state}
            />
          </div>
          <div className="contact-item zip">
            <input
              type="text"
              name="postalcode"
              id="postalcode"
              data-testid="Edit Shipping postal code"
              className={this.props.disabledClass}
              placeholder="Shipping postal code"
              maxLength={12}
              autoComplete="off"
              disabled={this.props.fielddisabled}
              onChange={event => this.props.handleChange(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              onFocus={Helper.handleFocus}
              onBlur={event => this.props.handleBlur(event, Constants.SHIPPING, this.props.contact.contactuuid)}
              value={this.props.contact.shippingaddress.postalcode}
            />
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  renderDoNotContact = () => {
    if (this.props.contact.donotemail) {
      return <EnvelopeSlashIcon />;
    }
    return "";
  };

  renderCustomerInactive = () => {
    if (this.props.contact.active || this.props.contact.type === Constants.PROSPECT) {
      return "";
    }
    return <FontAwesomeIcon icon={faUserLargeSlash} />;
  };

  renderActiveSwitch = () => {
    // If this is a protected customer, new customer, order view, or prospect,
    // then don't show the active switch
    if (
      this.props.isProtectedCustomer ||
      this.props.isNewContact ||
      Helper.isOrderView(this.props.appState.currentView) ||
      this.props.contact?.type === Constants.PROSPECT
    ) {
      return "";
    }
    // return (
    //   <Switch
    //     fieldname="active"
    //     handleChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
    //     checked={this.props.contact.active}
    //     elementid="active"
    //     yes="Active"
    //     no="Inactive"
    //   />
    // );
    return (
      <span className="toggle-switch contact-active-switch">
        <label className="switch">
          <input
            type={Constants.HTML_INPUT_TYPE_CHECKBOX}
            checked={this.props.contact.active}
            name={"active"}
            id={"active"}
            onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
          />
          <span className="slider round">
            <span className={Constants.OVERLAY_RESPONSE_YES}>Active</span>
            <span className={Constants.OVERLAY_RESPONSE_NO}>Inactive</span>
          </span>
        </label>
      </span>
    );
  };

  renderDoNotEmailSwitch = () => {
    // If this is a protected customer, new customer, or order view,
    // then don't show the donotemail switch
    if (this.props.isProtectedCustomer || this.props.isNewContact || Helper.isOrderView(this.props.appState.currentView)) {
      return "";
    }
    let handler = event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid);
    // Disable the switch if this is a prospect
    let disabled = false;
    if (this.props.contact?.type === Constants.PROSPECT) {
      // disabled = true;
      handler = event => this.props.handleChange(event, Constants.PROSPECT, this.props.contact.contactuuid);
    }
    return (
      <span className="toggle-switch contactDonotemailSwitch desktop">
        <label className="switch">
          <input
            type={Constants.HTML_INPUT_TYPE_CHECKBOX}
            checked={!this.props.contact.donotemail}
            name={"donotemail"}
            id={"donotemail"}
            disabled={disabled}
            onChange={handler}
          />
          <span className="slider round">
            <span className={Constants.OVERLAY_RESPONSE_YES}>Allow Email</span>
            <span className={Constants.OVERLAY_RESPONSE_NO}>Block Email</span>
          </span>
        </label>
      </span>
    );
  };

  renderUnchainContact = () => {
    // No unchain button if not on the Customer view,
    // or if this is a new contact, or if this is the only contact
    if (this.props.appState.currentView !== Constants.CUSTOMER || this.props.isNewContact || !this.props.hasAdditionalContacts) {
      return "";
    }
    // Onclick will remove the company name from the contact.
    return (
      <span
        title="Remove contact from this customer group"
        className="editcontact"
        onClick={() => this.maybeUnchainContact(this.props.contact.contactuuid)}
      >
        <FontAwesomeIcon icon={faLinkSlash} />
      </span>
    );
  };

  renderTrashCan = () => {
    // If this is a new primary contact, protected customer, or prospect, don't show the trash can
    if (
      (this.props.isNewContact && this.props.isPrimaryContact) ||
      this.props.isProtectedCustomer ||
      Helper.isOrderView(this.props.appState.currentView) ||
      this.props.contact?.type === Constants.PROSPECT
    ) {
      return "";
    }
    return (
      <span title="Delete Contact" className="editcontact" onClick={() => this.props.handleDeleteContact(this.props.contactuuid)}>
        <FontAwesomeIcon icon={faTrash} />
      </span>
    );
  };

  renderSaveIcon = () => {
    // Don't show if this contact is not new
    if (!this.props.isNewContact) {
      return "";
    }
    return (
      <span className="editcontact" onClick={() => {}}>
        <FontAwesomeIcon icon={faFloppyDisk} />
      </span>
    );
  };

  renderCloseContact = () => {
    return (
      <span className="editcontact" onClick={() => this.props.handleEditMode(this.props.contact.contactuuid)} data-testid="Close Contact Card">
        <FontAwesomeIcon icon={faCircleMinus} />
      </span>
    );
  };

  renderEditTitle = (disabledClass, fielddisabled) => {
    if (this.props.contact?.type === Constants.PROSPECT) {
      return "";
    }
    return (
      <div className="contact-item contact-title">
        <input
          type="text"
          name="title"
          id="title"
          data-testid="Edit Title"
          className={disabledClass}
          placeholder="Title"
          maxLength={50}
          autoComplete="off"
          disabled={fielddisabled}
          onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
          onFocus={Helper.handleFocus}
          onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
          value={this.props.contact.title}
        />
      </div>
    );
  };

  renderEditWebsite = (disabledClass, fielddisabled) => {
    if (this.props.contact?.type === Constants.PROSPECT) {
      return "";
    }
    return (
      <div className="contact-item website">
        <input
          type="text"
          name="website"
          id="website"
          data-testid="Edit Website"
          className={disabledClass}
          placeholder="Website"
          maxLength={255}
          autoComplete="off"
          disabled={fielddisabled}
          onChange={event => this.props.handleChange(event, Constants.CONTACT, this.props.contact.contactuuid)}
          onFocus={Helper.handleFocus}
          onBlur={event => this.props.handleBlur(event, Constants.CONTACT, this.props.contact.contactuuid)}
          value={this.props.contact.website}
        />
      </div>
    );
  };

  renderCompanyName = (disabledClass, fielddisabled) => {
    // For customer company types: Company Name only displays for the primary customer.
    // For supplier company type: Company Name only displays for the primary customer in the order view.
    if (
      (Constants.CUSTOMER_COMPANY_TYPES.includes(this.props.company?.companytype) && this.props.isPrimaryContact) ||
      (Helper.isOrderView(this.props.appState.currentView) &&
        this.props.isPrimaryContact &&
        this.props.contact?.companytype === Constants.SUPPLIER_COMPANY)
    ) {
      return (
        <div className="contact-item companyname">
          <input
            type="text"
            name="companyname"
            id="companyname"
            className={disabledClass}
            disabled={fielddisabled}
            autoComplete="off"
            placeholder="Org/Company name"
            maxLength={50}
            value={this.props.company.companyname}
            onChange={event => this.props.handleChange(event, Constants.COMPANY)}
            onFocus={Helper.handleFocus}
            onBlur={event => this.props.handleBlur(event, Constants.COMPANY)}
          />
        </div>
      );
    } else {
      return "";
    }
  };

  renderPrintCompany = () => {
    if (this.props.company.companyname) {
      return <div className="printCompany">{this.props.company.companyname}</div>;
    } else {
      return "";
    }
  };

  renderFullName = () => {
    const fullName =
      this.props.contact.firstname + " " + this.props.contact.lastname + (this.props.contact.title ? " (" + this.props.contact.title + ")" : "");

    if (fullName.trim()) {
      return (
        <div
          className="contact-item view-name"
          data-testid="Customer Full Name"
          onDoubleClick={() => {
            this.props.handleEditMode(this.props.contact.contactuuid, () => {
              this.contactNameInput.current?.focus();
            });
          }}
        >
          {fullName}
        </div>
      );
    } else {
      return "";
    }
  };

  renderEmailAddr = () => {
    if (this.props.contact.email) {
      let copy = this.state.copied ? (
        <span className="">
          <FontAwesomeIcon
            icon={faCheck}
            onClick={() => {
              Helper.copyToClipboard(this.props.contact.email);
            }}
          />
        </span>
      ) : (
        <span className="copyToClipboard">
          <FontAwesomeIcon
            icon={faCopy}
            onClick={() => {
              this.setState({ copied: true }, () => {
                setTimeout(() => {
                  this.setState({ copied: false });
                }, 1500);
              });
              Helper.copyToClipboard(this.props.contact.email);
            }}
          />
        </span>
      );

      return (
        <div
          className="contact-item view-emailaddr"
          data-testid="View Customer Email Address"
          onDoubleClick={() => {
            this.props.handleEditMode(this.props.contact.contactuuid, () => {
              this.contactNameInput.current?.focus();
            });
          }}
        >
          <span>{this.props.contact.email}</span> {copy}
        </div>
      );
    } else {
      return "";
    }
  };

  renderMobilePhone = () => {
    if (this.props.contact.mobilephone) {
      const isvalid =
        Helper.stripNonNumerics(this.props.contact.mobilephone).length === 10 ? (
          ""
        ) : (
          <FontAwesomeIcon icon={faTriangleExclamation} className="highlight" title="Not a valid mobile number" />
        );
      return (
        <div
          data-testid="View Customer Mobile Phone"
          className="contact-item view-mobilephone"
          onDoubleClick={() => {
            this.props.handleEditMode(this.props.contact.contactuuid, () => {
              this.contactNameInput.current?.focus();
            });
          }}
        >
          {Helper.renderPhoneLink(this.props.contact.mobilephone)}
          &nbsp;
          {isvalid}
        </div>
      );
    } else {
      return "";
    }
  };

  renderOtherPhone = () => {
    if (this.props.contact.otherphone) {
      return (
        <div
          data-testid="View Customer Other Phone"
          className="contact-item view-otherphone"
          onDoubleClick={() => {
            this.props.handleEditMode(this.props.contact.contactuuid, () => {
              this.contactNameInput.current?.focus();
            });
          }}
        >
          {Helper.renderPhoneLink(this.props.contact.otherphone)}
        </div>
      );
    } else {
      return "";
    }
  };

  renderAddress = (noPrint = false) => {
    let address = "";
    if (this.props.contact.address1 && this.props.contact.address2) {
      address = this.props.contact.address1 + " " + this.props.contact.address2;
    } else if (this.props.contact.address1) {
      address = this.props.contact.address1;
    } else if (this.props.contact.address2) {
      address = this.props.contact.address2;
    }
    if (address) {
      let copy = this.state.addressCopied ? (
        <span className="">
          <FontAwesomeIcon
            icon={faCheck}
            onClick={() => {
              Helper.copyToClipboard(address + ", " + this.renderCityStateZip(true));
            }}
          />
        </span>
      ) : (
        <span className="copyToClipboard">
          <FontAwesomeIcon
            icon={faCopy}
            onClick={() => {
              this.setState({ addressCopied: true }, () => {
                setTimeout(() => {
                  this.setState({ addressCopied: false });
                }, 1500);
              });
              Helper.copyToClipboard(address + ", " + this.renderCityStateZip(true));
            }}
          />
        </span>
      );
      let classes = "contact-item view-address";
      if (noPrint) {
        classes += " noPrint";
      }
      return (
        <div
          className={classes}
          onDoubleClick={() => {
            this.props.handleEditMode(this.props.contact.contactuuid, () => {
              this.contactNameInput.current?.focus();
            });
          }}
        >
          {address} {copy}
        </div>
      );
    } else {
      return "";
    }
  };

  renderCityStateZip = (asString = false, noPrint = false) => {
    let cityStateZip = "";
    if (this.props.contact.city && this.props.contact.state && this.props.contact.postalcode) {
      cityStateZip = this.props.contact.city + ", " + this.props.contact.state + " " + this.props.contact.postalcode;
    } else if (this.props.contact.city && !this.props.contact.state && !this.props.contact.postalcode) {
      cityStateZip = this.props.contact.city;
    } else if (this.props.contact.city && this.props.contact.state && !this.props.contact.postalcode) {
      cityStateZip = this.props.contact.city + ", " + this.props.contact.state;
    } else if (this.props.contact.city && !this.props.contact.state && this.props.contact.postalcode) {
      cityStateZip = this.props.contact.city + " " + this.props.contact.postalcode;
    } else if (!this.props.contact.city && !this.props.contact.state && this.props.contact.postalcode) {
      cityStateZip = this.props.contact.postalcode;
    } else if (!this.props.contact.city && this.props.contact.state && !this.props.contact.postalcode) {
      cityStateZip = this.props.contact.state;
    } else if (!this.props.contact.city && this.props.contact.state && this.props.contact.postalcode) {
      cityStateZip = this.props.contact.state + " " + this.props.contact.postalcode;
    }

    if (cityStateZip && asString) {
      return cityStateZip;
    }
    if (cityStateZip) {
      let classes = "contact-item view-citystatezip";
      if (noPrint) {
        classes += " noPrint";
      }
      return (
        <div
          className={classes}
          onDoubleClick={() => {
            this.props.handleEditMode(this.props.contact.contactuuid, () => {
              this.contactNameInput.current?.focus();
            });
          }}
        >
          {cityStateZip}
        </div>
      );
    } else {
      return "";
    }
  };

  renderViewShippingAddress = () => {
    let shipaddress = "";
    let shipCityStateZip = "";
    // If all elements of the shipping address are present, then render the shipping address for printing
    if (
      this.props.contact.shippingaddress?.address1 &&
      this.props.contact.shippingaddress?.city &&
      this.props.contact.shippingaddress?.state &&
      this.props.contact.shippingaddress?.postalcode
    ) {
      if (this.props.contact.shippingaddress?.address1 && this.props.contact.shippingaddress?.address2) {
        shipaddress = this.props.contact.shippingaddress.address1 + " " + this.props.contact.shippingaddress.address2;
      } else if (this.props.contact.shippingaddress?.address1) {
        shipaddress = this.props.contact.shippingaddress.address1;
      } else if (this.props.contact.shippingaddress?.address2) {
        shipaddress = this.props.contact.shippingaddress.address2;
      }
      shipCityStateZip =
        this.props.contact.shippingaddress.city +
        " " +
        this.props.contact.shippingaddress.state +
        " " +
        this.props.contact.shippingaddress.postalcode;

      return (
        <div className="printShipAddress">
          Ship to:
          <div className="shipAddress"> {shipaddress}</div>
          <div className="shipCityStateZip">{shipCityStateZip}</div>
        </div>
      );
    } else {
      return "";
    }
  };

  renderWebsite = () => {
    if (this.props.contact.website) {
      return (
        <div
          className="contact-item view-website"
          onDoubleClick={() => {
            this.props.handleEditMode(this.props.contact.contactuuid, () => {
              this.contactNameInput.current?.focus();
            });
          }}
        >
          {this.props.contact.website}
        </div>
      );
    } else {
      return "";
    }
  };

  renderCaretIcon = () => {
    let caretIcon = <FontAwesomeIcon icon={faAngleRight} />;
    if (this.props.additionalContactsVisible) {
      caretIcon = <FontAwesomeIcon icon={faAngleLeft} />;
    }
    let caretIconMobile = <FontAwesomeIcon icon={faAngleDown} />;
    if (this.props.additionalContactsVisible) {
      caretIconMobile = <FontAwesomeIcon icon={faAngleUp} />;
    }

    if (!this.props.isPrimaryContact || this.props.isNewContact || !this.props.hasAdditionalContacts) {
      return (
        <span className="action-icons expand-contacts mobile">
          <span className=" caret ">&nbsp;</span>
        </span>
      );
    }

    return (
      <React.Fragment>
        <span className="action-icons expand-contacts mobile">
          <span className=" caret " onClick={this.props.handleCaretClick}>
            {caretIconMobile}
          </span>
        </span>
        <span className="action-icons expand-contacts desktop">
          <span className=" caret " onClick={this.props.handleCaretClick}>
            {caretIcon}
          </span>
        </span>
      </React.Fragment>
    );
  };

  renderAddContact = () => {
    // If this is a protected, secondary, or a new contact, then don't show the add contact icon
    if (
      this.props.isProtectedCustomer ||
      !this.props.isPrimaryContact ||
      this.props.isNewContact ||
      this.props.contact?.type === Constants.PROSPECT
    ) {
      return "";
    }
    return (
      <span className="action-icons add-contact">
        <span className="plus" onClick={this.props.handleAddContact} data-testid="Add Contact">
          <FontAwesomeIcon icon={faPlus} />
        </span>
      </span>
    );
  };

  renderEditIcon = () => {
    return (
      <span
        className="edit"
        data-testid="Edit Contact"
        onClick={() => {
          this.props.handleEditMode(this.props.contact.contactuuid, () => {
            this.contactNameInput.current?.focus();
          });
        }}
      >
        <FontAwesomeIcon icon={faPencil} />
      </span>
    );
  };

  maybeUnchainContact = contactuuid => {
    this.props.showOverlay({
      type: Constants.OVERLAY_QUESTION,
      text: "Are you sure you want to remove this customer from the group?",
      callback: this.handleUnchainContactResponse,
      key: contactuuid,
    });
  };

  handleUnchainContactResponse = (response, contactuuid) => {
    if (response === Constants.OVERLAY_RESPONSE_YES) {
      this.props.handleChange(null, Constants.UNCHAIN_CONTACT, contactuuid);
    }
  };
}

export default ContactCard;

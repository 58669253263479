function render(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.729 31.718">
      <g id="Group_690" data-name="Group 690" transform="translate(-118.25 -207.889)">
        <path
          id="Path_327"
          data-name="Path 327"
          d="M295.269,228.632a11.492,11.492,0,0,0,1.522-2.25,11.764,11.764,0,0,0-2.209-13.812,10.81,10.81,0,0,0-7.086-3.051.74.74,0,0,1-.706-.393.7.7,0,0,1,.137-.768.586.586,0,0,1,.314-.188,13.443,13.443,0,0,1,11.026,2.48,14.425,14.425,0,0,1,5.881,10.515,14.226,14.226,0,0,1-2.072,8.882c-.611,1.02-1.35,1.963-2.031,2.942-.06.086-.118.172-.186.272.747.767,1.484,1.523,2.22,2.28a3.813,3.813,0,0,1,.343.373.66.66,0,0,1-.458,1.053,2.355,2.355,0,0,1-.285.006h-9.129c-.687,0-.888-.2-.888-.888,0-3.139,0-6.278.008-9.416a1.068,1.068,0,0,1,.2-.634c.276-.335.673-.294,1.017.056q1.1,1.12,2.189,2.249A1.977,1.977,0,0,1,295.269,228.632Z"
          transform="translate(-154.237 0)"
        />
        <path
          id="Path_328"
          data-name="Path 328"
          d="M122.618,243.587c-.811-.805-1.616-1.6-2.409-2.4a1.13,1.13,0,0,1-.287-.475.624.624,0,0,1,.592-.819,1.148,1.148,0,0,1,.127,0q4.706,0,9.412,0a.687.687,0,0,1,.752.749q.006,4.834,0,9.668a.688.688,0,0,1-.457.69.645.645,0,0,1-.764-.218q-1.083-1.113-2.166-2.226c-.08-.083-.151-.174-.288-.333-.438.654-.878,1.233-1.235,1.86a11.744,11.744,0,0,0,1.977,14.2,10.819,10.819,0,0,0,7.014,3.065,1.845,1.845,0,0,1,.375.048.564.564,0,0,1,.466.591.689.689,0,0,1-.553.646,13.462,13.462,0,0,1-11.729-3.088,14.4,14.4,0,0,1-5.126-9.861,14.655,14.655,0,0,1,2.414-9.514c.508-.8,1.122-1.53,1.686-2.293C122.483,243.786,122.54,243.7,122.618,243.587Z"
          transform="translate(0 -29.294)"
        />
      </g>
    </svg>
  );
}
export default render;
